import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import ArrowDown from "../../../../assets/images/icons/down.svg";
import "../listing.scss";
import CustomSelect, {
  Option,
} from "../../../../components/core-components/inputs/Select/CustomSelect";
import { useTranslation } from "react-i18next";
import CustomModal from "../../../../components/shared-components/CustomModal";
import ListingStatus from "../detail/components/modals/ListingStatus";

interface InputSectionProps {
  showTextField?: boolean;
  showCategoriesSelect?: boolean;
  showSalesChannelsSelect?: boolean;
  showGoogleVRSelect?: boolean;
  showMoreFilterSelect?: boolean;
  showListingStatusButton?: boolean;
  showSortByButton?: boolean;
  showListingSelect?: boolean;
  showBookingReferenceSelect?: boolean;
  showGuestNameSelect?: boolean;
  showBookingStatusSelect?: boolean;
}

const InputSection: React.FC<InputSectionProps> = ({
  showCategoriesSelect = true,
  showSalesChannelsSelect = true,
  showGoogleVRSelect = true,
  showMoreFilterSelect = true,
  showListingStatusButton = true,
  showSortByButton = true,
  showListingSelect = true,
  showBookingReferenceSelect = true,
  showGuestNameSelect = true,
  showBookingStatusSelect = true,
}) => {
  const [age] = useState<string | number>("");
  const { t } = useTranslation();
  const handleChange = (_: React.ChangeEvent<{ value: unknown }>) => { };
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const categoriesOptions: Option[] = [
    { value: 10, label: t("reservationpage.ten") },
    { value: 20, label: t("reservationpage.twenty") },
    { value: 30, label: t("reservationpage.thirty") },
  ];

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "20px",
          flexWrap: "wrap",
        }}
      >
        {showListingSelect && (
          <CustomSelect
            value={age}
            pagetype="listing"
            onChange={handleChange}
            label={t("reservationpage.listing")}
            options={categoriesOptions}
          />
        )}
        {showCategoriesSelect && (
          <CustomSelect
            value={age}
            onChange={handleChange}
            label={t("reservationpage.categories")}
            pagetype="listing"
            options={categoriesOptions}
          />
        )}
        {showSalesChannelsSelect && (
          <CustomSelect
            value={age}
            onChange={handleChange}
            pagetype="listing"
            label={t("reservationpage.saleschannels")}
            options={categoriesOptions}
          />
        )}
        {showGoogleVRSelect && (
          <CustomSelect
            value={age}
            onChange={handleChange}
            pagetype="listing"
            label={t("reservationpage.googlevr")}
            options={categoriesOptions}
          />
        )}
        {showBookingReferenceSelect && (
          <CustomSelect
            value={age}
            onChange={handleChange}
            pagetype="listing"
            label={t("reservationpage.bookingreference")}
            options={categoriesOptions}
          />
        )}
        {showGuestNameSelect && (
          <CustomSelect
            value={age}
            onChange={handleChange}
            label={t("reservationpage.guestname")}
            pagetype="listing"
            options={categoriesOptions}
          />
        )}
        {showBookingStatusSelect && (
          <CustomSelect
            value={age}
            onChange={handleChange}
            label={t("reservationpage.bookingstatus")}
            pagetype="listing"
            options={categoriesOptions}
          />
        )}
        {showMoreFilterSelect && (
          <CustomSelect
            value={age}
            onChange={handleChange}
            pagetype="listing"
            label={t("reservationpage.morefilter")}
            options={categoriesOptions}
          />
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          gap: "24px",
          marginLeft: "auto",
        }}
      >
        {showListingStatusButton && (
          <Button
            onClick={handleOpen}
            sx={{
              color: "var(--Gray-Gray-800, #3F4254)",
              fontFamily: "Inter",
              fontSize: "13px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "14px" /* 107.692% */,
              textTransform: "capitalize",
            }}
          >
            {t("reservationpage.listingstatus")}
            <img
              src={ArrowDown}
              alt="Arrow Down"
              style={{ marginLeft: "8px" }}
            />
          </Button>
        )}
        {showSortByButton && (
          <Button
            sx={{
              color: "var(--Gray-Gray-800, #3F4254)",
              fontFamily: "Inter",
              fontSize: "13px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "14px" /* 107.692% */,
              textTransform: "capitalize",
            }}
          >
            {t("reservationpage.sortby")}
            <img
              src={ArrowDown}
              alt="Arrow Down"
              style={{ marginLeft: "8px" }}
            />
          </Button>
        )}
      </Box>
      <CustomModal
        open={open}
        handleClose={handleClose}
        handleSave={handleClose}
        title={t("listingpage.listingStatus")}
      >
        <ListingStatus showTitle={false} />
      </CustomModal>
    </>
  );
};

export default InputSection;
