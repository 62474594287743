import React from "react";
import { Stack } from "@mui/material";

interface CardProps {
  imgSrc: string;
  altText: string;
  title: string;
  description: string;
}

const Card = ({ imgSrc, altText, title, description }: CardProps) => (
  <Stack className="preview-listing-card">
    <img src={imgSrc} alt={altText} className="preview-listing-card-img" />
    <p className="B-16-16-600 preview-listing-card-text">{title}</p>
    <p className="B-16 preview-listing-card-text">{description}</p>
  </Stack>
);

export default Card;
