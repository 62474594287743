export const inputHeaderStyles = {
  textField: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #E1E3EA",
    },
    "& .MuiOutlinedInput-root": {
      display: "flex",
      borderRadius: "20px",
      height: "40px",
      padding: "8px 32px",
      gap: "12px",
      alignItems: "center",
      fontSize: "12px",
      fontWeight: 600,
      fontFamily: "Inter !important",
    },
  },
  settingsMenu: {
    "& .MuiContainer-root": {
      padding: "0",
      margin: "0",
      width: "100px",
    },
    "& .MuiTypography-root": {
      padding: "0",
      margin: "0",
      fontFamily: "Inter",
      width: "100px",
      color: "#181C32",
      fontWeight: "500",
    },
    "& .MuiList-root-MuiMenu-list": {
      padding: "0",
      margin: "0",
      width: "100px",
    },
    "& .MuiMenu-paper": {
      maxWidth: "189px",
      padding: "24px",
      margin: "0 !important",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
      borderRadius: "12px",
      overflow: "hidden",
      maxHeight: "134px",
    },
  },
  iconMenu: {
    "& .MuiContainer-root": {
      padding: "0",
      margin: "0",
    },
    "& .MuiTypography-root": {
      padding: "0",
      margin: "0",
      fontFamily: "Inter",
      width: "100px",
      color: "#181C32",
      fontWeight: "500",
    },
    "& .MuiList-root-MuiMenu-list": {
      padding: "0",
      margin: "0",
      width: "100px",
    },
    "& .MuiMenu-paper": {
      maxWidth: "189px",
      padding: "24px",
      margin: "0 !important",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
      borderRadius: "12px",
      overflow: "hidden",
      maxHeight: "134px",
    },
  },
  stack: {
    direction: "row",
    justifyContent: "space-between",
    alignItems: "center",
    spacing: 2,
  },
  languageMenu: {
    "& .MuiContainer-root": {
      // padding: "40px 32px !important",
      margin: "0",
    },
    "& .MuiTypography-root": {
      padding: "6px 12px !important",
      margin: "0",
      fontFamily: "Inter",
      minWidth: "120px",
      color: "#181C32",

      fontWeight: "500",
      // padding: "10px 0px",
    },
    "& .MuiList-root-MuiMenu-list": {
      padding: "40px 32px !important",

      margin: "0",
      width: "100px",
    },
    "& .MuiMenu-paper": {
      // top: "65px !important",
      left: "10px",
      maxWidth: "209px",
      padding: "23px 40px",
      margin: "0 !important",
      boxShadow: "none",
      borderRadius: "12px",
      minHeight: "150px",
    },
  },
};
