import React from "react";
import { TextField, Typography, InputAdornment } from "@mui/material";
import { InputStyles } from "./InputStyles";

interface MainTextFieldProps {
  placeholder: string;
  title?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  value: any;
  isMandatory?: boolean;
  multiline?: boolean;
  row?: number;
  startAdornment?: string;
  endAdornment?: React.ReactNode;
  maxLength?: number;
  textareaWidth?: string;
  name?: string;
  type?: string;
  sx?: any;
  inputRef?: any;
  errorMessage?: string | null;
  disabled?: boolean;
}

function MainTextField({
  placeholder,
  title,
  onChange,
  onBlur,
  sx,
  isMandatory = false,
  value,
  multiline = false,
  row,
  startAdornment,
  endAdornment,
  maxLength,
  textareaWidth,
  name,
  type = "text",
  inputRef,
  disabled,
  errorMessage = null,
  ...props
}: MainTextFieldProps) {
  const [error, setError] = React.useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (maxLength && event.target.value.length > maxLength) {
      setError(true);
      return;
    }
    setError(false);
    onChange(event);
  };

  /*   const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (numericOnly && !/^\d*\.?\d*$/.test(event.target.value)) {
      // Ignore invalid input
      return;
    }
    onChange(event);
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (numericOnly && !/[0-9.]/.test(event.key)) {
      event.preventDefault();
    }
  }; */

  return (
    <>
      {title && (
        <Typography variant="body2" sx={InputStyles.label}>
          {title}
          {isMandatory && <sub className="mandatory">*</sub>}
        </Typography>
      )}
      <TextField
        {...props}
        onChange={handleChange}
        onBlur={onBlur}
        value={value}
        placeholder={placeholder}
        variant="outlined"
        fullWidth
        type={type}
        inputMode={type === "number" ? "numeric" : "text"}
        sx={sx ? sx : InputStyles.textField}
        multiline={multiline || false}
        minRows={row || 8}
        error={error || !!errorMessage}
        helperText={
          errorMessage && <span className="error-message">{errorMessage}</span>
        }
        name={name}
        inputRef={inputRef}
        InputProps={{
          startAdornment: startAdornment ? (
            <InputAdornment position="start">{startAdornment}</InputAdornment>
          ) : null,
          endAdornment: endAdornment ? (
            <InputAdornment position="end">{endAdornment}</InputAdornment>
          ) : null,

          inputProps: {
            maxLength,
            style: { width: textareaWidth || "100%" },
            pattern: type === "number" ? "[0-9]*" : undefined,
          },
        }}
      />
    </>
  );
}

export default MainTextField;
