import { useState } from "react";
import QuickSelectCounterButtons from "../../../../../../components/core-components/inputs/RadioButton/QuickSelectCounterButtons";
import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import ListingDetailsEditingBox from "../../../../../../components/shared-components/ListingDetailsEditingBox";
import MainSelectSelection from "../../../../../../components/core-components/inputs/Select/MainSelectSelection";

function Kitchens() {
  const [kitchenGroups, setKitchenGroups] = useState<number[]>([1]);
  const { t } = useTranslation();

  const handleMinusClick = () => {
    setKitchenGroups((prevGroups) => {
      if (prevGroups.length > 1) {
        const newGroups = [...prevGroups];
        newGroups.pop();
        return newGroups;
      }
      return prevGroups;
    });
  };

  const handlePlusClick = () => {
    setKitchenGroups((prevGroups) => {
      return [...prevGroups, prevGroups.length + 1];
    });
  };

  const handleRemoveGroup = (id: number) => {
    if (kitchenGroups.length > 1) {
      setKitchenGroups((prevGroups) =>
        prevGroups.filter((group) => group !== id)
      );
    }
  };

  return (
    <>
      <Stack mt={5} spacing={2}>
        <Stack
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <p>{t("listingDetails.kitchens")} </p>
          <QuickSelectCounterButtons
            itemValue={kitchenGroups.length}
            handlePlusClick={handlePlusClick}
            handleMinusClick={handleMinusClick}
          />
        </Stack>
        <ListingDetailsEditingBox
          footer={false}
          onClose={() => { }}
          border={false}
        >
          <p className="listing-details-room-title">
            {t("listingDetails.kitchendetails")}
          </p>
          {kitchenGroups.map((group, index) => (
            <Stack
              key={group}
              sx={{
                paddingBottom: "1rem",
                gap: "1rem",
                ...(index !== kitchenGroups.length - 1 && {
                  borderBottom: "1px solid var(--stroke-colors, #EBEBEB)",
                }),
              }}
              mt={2}
              spacing={1}
            >
              <p className="listing-details-room-count-title">
                {t("listingDetails.kitchen")} {group}
              </p>
              <Stack
                alignItems={"flex-end"}
                justifyContent={"space-between"}
                flexDirection={"row"}
                sx={{ maxWidth: "412px", gap: "1rem" }}
              >
                <Stack sx={{ flex: 3 }}>
                  <MainSelectSelection
                    value=""
                    onChange={() => { }}
                    label={"Seperate Kitchen"}
                    options={[
                      {
                        value: "Seperate Kitchen",
                        label: `Seperate Kitchen`,
                      },
                      {
                        value: "No",
                        label: `No`,
                      },
                    ]}
                  />
                </Stack>
              </Stack>

              <button
                className="remove-listing-room-button"
                onClick={() => handleRemoveGroup(group)}
              >
                {t("listingDetails.remove")}
              </button>
            </Stack>
          ))}
        </ListingDetailsEditingBox>
      </Stack>
    </>
  );
}

export default Kitchens;
