import axios, { AxiosResponse } from "axios";

const createApiInstance = (baseURL: string | undefined) => {
  const apiInstance = axios.create({
    baseURL,
  });

  apiInstance.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    async (error) => {
      return Promise.reject(error);
    }
  );

  return apiInstance;
};

export default createApiInstance;