import { useTranslation } from "react-i18next";
import DetailsInputArea from "../../../../../../../../components/shared-components/DetailsInputArea";
import PhotosList from "../../../../components/inputs/PhotosList";

interface Props {
  setSubMenu: React.Dispatch<React.SetStateAction<string>>;
}

function Photos(props: Props) {
  const { setSubMenu } = props;

  const { t } = useTranslation();
  const data = [
    {
      title: t("listingDetails.photos"),
      subtitle: "",
      component: <PhotosList setSubMenu={setSubMenu} />,
      showComponent: true,
      isSelect: false,
      subMenu: true,
      onClick: () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        setSubMenu("photos");
      },
    },
  ];

  return (
    <>
      <DetailsInputArea
        title={""}
        data={data}
        onClick={() => {}}
        id={"photos"}
      />
    </>
  );
}

export default Photos;
