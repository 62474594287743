import { Box, Modal, Stack, useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";
import CloseModalButton from "../../../../../components/shared-components/CloseModalButton";
import CustomModalMobile from "../../../../../components/shared-components/CustomModalMobile";
import CheckIcon from "../../../../../assets/images/icons/ic_round-check.svg";

interface ApproveModalProps {
  open: boolean;
  handleClose: () => void;
  handleSave: () => void;
  bookingId: string;
  onStatusUpdate: (status: string) => void;
}

const ApproveModal: React.FC<ApproveModalProps> = ({
  open,
  handleClose,
  handleSave,
  bookingId,
  onStatusUpdate,
}) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"));
  const { getAccessTokenSilently } = useAuth0();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const API_BASE_URL = process.env.REACT_APP_API_URL;
  const handleApproval = async () => {
    try {
      setIsSubmitting(true);
      const accessToken = await getAccessTokenSilently();

      const response = await fetch(`${API_BASE_URL}/Reservation/Approval`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ id: bookingId }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error("Approval process failed");
      }

      if (data.status === true) {
        toast.success("Reservation approved successfully");

        onStatusUpdate("confirmed");

        handleSave();
      } else {
        throw new Error(data.error || "Approval process failed");
      }
    } catch (error) {
      console.error("Approval error:", error);
      toast.error(
        error instanceof Error
          ? error.message
          : "An error occurred while approving the reservation"
      );
    } finally {
      setIsSubmitting(false);
      handleClose();
    }
  };

  const modalContent = (
    <Stack
      spacing={4}
      pb={2}
      sx={{
        alignItems: "center",
        alignSelf: "stretch",
        minWidth: "100%",
      }}
    >
      <Stack spacing={2} justifyContent="center" alignItems="center">
        <Stack
          sx={{
            width: "64px",
            height: "64px",
            padding: "16px",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "100px",
            background: "#61C478",
          }}
        >
          <img
            src={CheckIcon}
            alt="check-icon"
            style={{ width: "32px", height: "32px" }}
          />
        </Stack>

        <span className="H-24">Do you want to approve?</span>
        <span
          className="B-14-20-500"
          style={{ color: "var(--Gray-500, #667085)" }}
        >
          After approval, they can be your guest
        </span>
      </Stack>

      <Stack
        direction={isMobile ? "column" : "row"}
        spacing={2}
        sx={{
          justifyContent: "space-between",
          alignItems: "flex-start",
          alignSelf: "stretch",
          height: "48px",
        }}
      >
        <button
          onClick={handleClose}
          className="decline-button"
          style={{
            display: "flex",
            height: "48px",
            width: "100%",
            padding: "16px 20px",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50px",
            border: "1px solid #667085",
            background: "transparent",
            color: "#1D2939",
          }}
        >
          Cancel
        </button>

        <button
          onClick={handleApproval}
          disabled={isSubmitting}
          className="approve-button"
          style={{
            display: "flex",
            height: "48px",
            width: "100%",
            padding: "16px 20px",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50px",
            border: "none",
            background: "#0065E0",
            color: "#FFF",
            opacity: isSubmitting ? 0.7 : 1,
          }}
        >
          {isSubmitting ? "Approving..." : "Approve"}
        </button>
      </Stack>
    </Stack>
  );

  return isMobile ? (
    <CustomModalMobile
      open={open}
      handleClose={handleClose}
      handleSave={handleSave}
      title=""
      showFooter={false}
    >
      {modalContent}
    </CustomModalMobile>
  ) : (
    <Modal open={open} onClose={handleClose}>
      <Box
        onClick={handleClose}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          right: 0,
          bottom: 0,
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          onClick={(e) => e.stopPropagation()}
          sx={{
            display: "inline-flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "32px",
            gap: "16px",
            background: "#FFF",
            boxShadow: "0px 16px 32px 0px rgba(0, 0, 0, 0.12)",
            borderRadius: "12px",
            minWidth: "560px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
            }}
          >
            <CloseModalButton show={true} onClick={handleClose} />
          </Box>
          {modalContent}
        </Box>
      </Box>
    </Modal>
  );
};

export default ApproveModal;
