import {
  FormControlLabel,
  Radio,
  Stack,
} from "@mui/material";
import { MainStylesSheet } from "../../../../views/private/listing/detail/views/main/MainStylesSheet";

const UncheckedIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
  >
    <circle cx="13" cy="13" r="13" fill="#E1E3EA" />
  </svg>
);

const CheckedIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
  >
    <circle cx="13" cy="13" r="13" fill="#0065E0" />
    <circle cx="12.9997" cy="13" r="6.93333" fill="white" />
  </svg>
);

const CustomRadioButton = ({
  value,
  label,
  subtitle,
}: {
  value: string;
  label: string | JSX.Element;
  subtitle?: string;
}) => (
  <FormControlLabel
    sx={
      subtitle
        ? MainStylesSheet.muiFormgroup
        : MainStylesSheet.muiFormgroupWithoutIcon
    }
    value={value}
    control={
      <Radio
        sx={
          subtitle
            ? MainStylesSheet.radioExtraStyles
            : MainStylesSheet.radioOrdinary
        }
        icon={UncheckedIcon}
        checkedIcon={CheckedIcon}
      />
    }
    label={
      <>
        <Stack spacing={1}>
          <p className="title-box-h3-box-title B-14">{label}</p>
          {subtitle && (
            <p className="title-box-h3-box-subtitle B-14-20-400">{subtitle}</p>
          )}
        </Stack>
      </>
    }
  />
);
export default CustomRadioButton;
