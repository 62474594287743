import { useTranslation } from "react-i18next";
import DetailsInputArea from "../../../../../../../../components/shared-components/DetailsInputArea";
import ListingStatus from "../../../../components/modals/ListingStatus";
import OtherRooms from "../../../../components/modals/OtherRooms";
import Bathrooms from "../../../../components/modals/Bathrooms";
import Kitchens from "../../../../components/modals/Kitchens";
import Bedroom from "../../../../components/modals/Bedroom";

function Rooms() {
  const { t } = useTranslation();
  const data = [
    {
      title: t("listingDetails.bedrooms"),
      subtitle: t("listingDetails.notset"),
      component: <Bedroom />,
      isSelect: false,
    },
    {
      title: t("listingDetails.bathrooms"),
      subtitle: t("listingDetails.notset"),
      component: <Bathrooms />,
      isSelect: false,
    },
    {
      title: t("listingDetails.entrance"),
      subtitle: t("listingDetails.notset"),
      component: <ListingStatus showTitle={true} />,
      isSelect: true,
    },
    {
      title: t("listingDetails.kitchen"),
      subtitle: t("listingDetails.notset"),
      component: <Kitchens />,
      isSelect: false,
    },
    {
      title: t("listingDetails.otherrooms"),
      subtitle: t("listingDetails.notset"),
      component: <OtherRooms />,
      isSelect: true,
    },
  ];
  return (
    <>
      <DetailsInputArea
        id="rooms"
        title={t("listingDetails.rooms")}
        data={data}
        onClick={() => { }}
      />
    </>
  );
}

export default Rooms;
