import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Button, Stack, useMediaQuery, useTheme } from "@mui/material";
import HeaderTitle from "../components/HeaderTitle";
import SecondaryRadioButton from "../../../../components/core-components/inputs/RadioButton/SecondaryRadioButton";
import MainTextField from "../../../../components/core-components/inputs/TextField/MainTextField";
import AssignerSelect from "../../inbox/messages/components/Autocomplate";
import CustomSelect from "../../../../components/core-components/inputs/Select/CustomSelect";
import PercentageIcon from "../../../../assets/images/icons/percantage-icon.svg";
import CustomButton from "../../../../components/shared-components/CustomButton";
import MessageCreator from "../components/MessageCreator";
import CustomModal from "../../../../components/shared-components/CustomModal";
import ListingModal from "../../onboardingTwo/step2/ListingModal";
import MobileHeader from "../../../../components/shared-components/MobileHeader";
import { useNavigate } from "react-router-dom";
import CustomModalMobile from "../../../../components/shared-components/CustomModalMobile";
import CustomMobileSelectMenu from "../../../../components/core-components/inputs/Select/CustomMobileSelectMenu";

const BookingBooster = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [openModal, setOpenModal] = useState(false);
  const [openMobileModal, setOpenMobileModal] = useState(false);
  const [openNightsOfferModal, setOpenNightsOfferModal] = useState(false);
  const [openChannelMarkupsModal, setOpenChannelMarkupsModal] = useState(false);
  const [openWhenSendOffersModal, setOpenWhenSendOffersModal] = useState(false);
  const [airbnbMarkup, setAirbnbMarkup] = useState<string | number | null>(
    null
  );
  const [bookingMarkup, setBookingMarkup] = useState<string | number | null>(
    null
  );
  const [expediaMarkup, setExpediaMarkup] = useState<string | number | null>(
    null
  );
  const [openSetTemplateMessagesModal, setOpenSetTemplateMessagesModal] =
    useState(false);
  const [isSwitchChecked, setIsSwitchChecked] = useState(true);
  const [isAllListingChecked, setIsAllListingChecked] = useState(true);
  const [isSelectListingChecked, setIsSelectListingChecked] = useState(false);
  const [isBookedNightsChecked, setIsBookedNightsChecked] = useState(true);
  const [isOrphanNightsChecked, setIsOrphanNightsChecked] = useState(false);
  const [addDays, setAddDays] = useState<string | number | null>(null);
  const [addNights, setAddNights] = useState<any>([]);
  const [nightlyDiscount, setNightlyDiscount] = useState<
    string | number | null
  >(null);
  const [addDaysError, setAddDaysError] = useState<string | null>(null);
  const [addNightsError, setAddNightsError] = useState<string | null>(null);
  const [nightlyDiscountError, setNightlyDiscountError] = useState<
    string | null
  >(null);
  const [sendOfferTime, setSendOfferTime] = useState<string | number | null>(
    null
  );
  const [dontSendOfferTime, setDontSendOfferTime] = useState<
    string | number | null
  >(null);
  const [dontSendOfferLessThan, setDontSendOfferLessThan] = useState<
    string | number | null
  >(null);
  const [sendOfferMessageInput, setSendOfferMessageInput] = useState<
    string | number | null
  >(null);
  const [dontSendOfferMessageInput, setDontSendOfferMessageInput] = useState<
    string | number | null
  >(null);
  const [dontSendOfferLessThanInput, setDontSendOfferLessThanInput] = useState<
    string | number | null
  >(null);
  const [channelMarkups, setChannelMarkups] = useState<string | number | null>(
    null
  );
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [open, setOpen] = useState(false);

  const handleAllListingChecked = () => {
    setIsAllListingChecked((prevState) => !prevState);
    setIsSelectListingChecked(false);
  };

  const handleSelectListingChecked = () => {
    setIsSelectListingChecked((prevState) => !prevState);
    setIsAllListingChecked(false);
  };

  const handleBookedNightsChange = () => {
    setIsBookedNightsChecked((prevState) => !prevState);
    setIsOrphanNightsChecked(false);
  };

  const handleOrphanNightsChange = () => {
    setIsOrphanNightsChecked((prevState) => !prevState);
    setIsBookedNightsChecked(false);
  };

  useEffect(() => {
    if (isSelectListingChecked && !isMobile) {
      setOpenModal(true);
    }
    if (isSelectListingChecked && isMobile) {
      setOpenMobileModal(true);
    }
  }, [isSelectListingChecked, isMobile, setOpenModal, setOpenMobileModal]);

  const [selectedPropertyId, setSelectedPropertyId] = useState("");
  const [selectedListings, setSelectedListings] = useState<number[]>([]);
  const [addedProperties, setAddedProperties] = useState<any[]>([]);
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate("/menu");
  };
  const [selectedNights, setSelectedNights] = useState<string[]>([]);
  const nightsOptions = [
    { label: "Monday", value: "Monday" },
    { label: "Tuesday", value: "Tuesday" },
    { label: "Wednesday", value: "Wednesday" },
    { label: "Thursday", value: "Thursday" },
    { label: "Friday", value: "Friday" },
    { label: "Saturday", value: "Saturday" },
    { label: "Sunday", value: "Sunday" },
  ];

  const updateProperty = (id: string, updatedListings: number[]) => {
    console.log(id, updatedListings);
    console.log(addedProperties);

    setAddedProperties((prevProperties) =>
      prevProperties.map((property) =>
        property.id === id
          ? { ...property, listingIds: updatedListings }
          : property
      )
    );
    setSelectedPropertyId("");
    setSelectedListings([]);
  };

  const handleAddProperty = () => {
    if (selectedListings.length === 0) {
      // Remove the property if no listings are selected
      setAddedProperties((prevProperties) =>
        prevProperties.filter((property) => property.id !== selectedPropertyId)
      );
    } else {
      if (selectedPropertyId) {
        updateProperty(selectedPropertyId, selectedListings);
      } else {
        const newProperty = {
          id: Date.now(),
          listingIds: selectedListings,
        };
        setAddedProperties((prevProperties) => [
          ...prevProperties,
          newProperty,
        ]);
      }
    }

    setSelectedListings([]);
    setOpenModal(false);
    setOpenMobileModal(false);
    setSelectedPropertyId("");
  };

  const validateFields = React.useCallback(() => {
    let hasError = false;

    // Add days validation
    if (!addDays) {
      setAddDaysError("Add days is required.");
      hasError = true;
    } else if (isNaN(Number(addDays)) || Number(addDays) <= 0) {
      setAddDaysError("Please enter a valid number of days.");
      hasError = true;
    } else {
      setAddDaysError(null);
    }

    // Nightly discount validation
    if (!nightlyDiscount) {
      setNightlyDiscountError("Nightly discount is required.");
      hasError = true;
    } else if (isNaN(Number(nightlyDiscount)) || Number(nightlyDiscount) <= 0) {
      setNightlyDiscountError(
        "Please enter a valid nightly discount percentage."
      );
      hasError = true;
    } else {
      setNightlyDiscountError(null);
    }

    // Save button status update
    setIsSaveDisabled(hasError);
  }, [addDays, nightlyDiscount, setAddDaysError, setNightlyDiscountError, setIsSaveDisabled]);

  useEffect(() => {
    validateFields();
  }, [addDays, addNights, nightlyDiscount, validateFields]);

  const chipLabels = [
    "{guest_first_name}",
    "{guest_surname}",
    "{host_first_name}",
    "{host_surname}",
    "{listing_name}",
    "{listing_address}",
    "{listing_location}",
    "{length_of_stay}",
    "{check_in_time}",
    "{check_in_date}",
    "{check_out_time}",
    "{check_out_date}",
    "{guest_booking_link}",
    "{guest_email}",
    "{guest_phone}",
    "{guest_phone_last_4_digits}",
    "{guest_phone_last_5_digits}",
    "{guest_phone_last_6_digits}",
    "{number_of_guests}",
    "{booking_site_name}",
    "{wifi_name}",
    "{wifi_password}",
    "{arrival_time}",
    "{departure_time}",
    "{todays_date}",
    "{direct_booking_website}",
    "{multi_unit_name}",
    "{lock_code}",
  ];

  const [messages, setMessages] = useState([
    {
      title: "1 night availability before stay",
      messageText: `
      Hi {guest_first_name},
      <br />
      <br /> Just wanted to let you know we have some extra availability
      in case you wanted to {checkin_or_extend_stay} at a
      {discount_percentage} discount. Here are the details: <br />
      <br />
      {offer_details} <br />
      <br />
      Let me know if you’re interested and look forward to hosting you!
      `,
    },
    {
      title: "Multi-night availability before stay",
      messageText: ` Hi {guest_first_name},
      <br />
      <br /> Just wanted to let you know we have some extra availability
      in case you wanted to {checkin_or_extend_stay} at a
      {discount_percentage} discount. Here are the details: <br />
      <br />
      {offer_details} <br />
      <br />
      Let me know if you’re interested and look forward to hosting you!`,
    },
    {
      title: "1 night availability after stay",
      messageText: ` Hi {guest_first_name},
      <br />
      <br /> Just wanted to let you know we have some extra availability
      in case you wanted to {checkin_or_extend_stay} at a
      {discount_percentage} discount. Here are the details: <br />
      <br />
      {offer_details} <br />
      <br />
      Let me know if you’re interested and look forward to hosting you!`,
    },
    {
      title: "Multi-night availability after stay",
      messageText: ` Hi {guest_first_name},
      <br />
      <br /> Just wanted to let you know we have some extra availability
      in case you wanted to {checkin_or_extend_stay} at a
      {discount_percentage} discount. Here are the details: <br />
      <br />
      {offer_details} <br />
      <br />
      Let me know if you’re interested and look forward to hosting you!`,
    },
    {
      title: "1 night availability before and after stay",
      messageText: ` Hi {guest_first_name},
      <br />
      <br /> Just wanted to let you know we have some extra availability
      in case you wanted to {checkin_or_extend_stay} at a
      {discount_percentage} discount. Here are the details: <br />
      <br />
      {offer_details} <br />
      <br />
      Let me know if you’re interested and look forward to hosting you!`,
    },
    {
      title: "Multi-night availability before and after stay",
      messageText: ` Hi {guest_first_name},
      <br />
      <br /> Just wanted to let you know we have some extra availability
      in case you wanted to {checkin_or_extend_stay} at a
      {discount_percentage} discount. Here are the details: <br />
      <br />
      {offer_details} <br />
      <br />
      Let me know if you’re interested and look forward to hosting you!`,
    },
  ]);

  const handleSaveMessages = () => {
    setOpenSetTemplateMessagesModal(false);
  };

  return (
    <Stack
      width={"100%"}
      className={
        !isMobile
          ? "acc-users-box-multiple-title"
          : "acc-users-box-mobile-multiple-title"
      }
      pb={20}
      gap={3}
    >
      {isMobile && (
        <MobileHeader
          title="Booking booster"
          description="Botel can automatically reach out to guests to extend their stays"
          handleBackClick={handleBackClick}
          isSwitchShown={true}
          switchChecked={isSwitchChecked}
          onSwitchChange={() => {
            setIsSwitchChecked(!isSwitchChecked);
          }}
        />
      )}
      {!isMobile && (
        <Stack
          justifyContent="flex-start"
          alignSelf={"flex-start"}
          alignItems="center"
          width={"100%"}
          maxWidth={isMobile ? "100%" : "812px"}
        >
          <HeaderTitle
            title="Booking booster"
            subtitle="Botel can automatically reach out to guests to extend their stays"
            isSwitchShown={true}
            switchChecked={isSwitchChecked}
            onSwitchChange={() => {
              setIsSwitchChecked(!isSwitchChecked);
            }}
          />
        </Stack>
      )}
      {/* == */}
      <Box alignSelf={"flex-start"} pt={isMobile ? 0 : 3}>
        <Stack
          spacing={3}
          paddingBottom={5}
          borderBottom={"1px solid #EBEBEB"}
          maxWidth={isMobile ? "100%" : "858px"}
        >
          <p
            className="B-18-18-600"
            style={{ color: "#181C32", lineHeight: "20px" }}
          >
            Run booking booster on:
          </p>
          <Stack direction="row" spacing={1}>
            <SecondaryRadioButton
              label={""}
              value={""}
              checked={isAllListingChecked}
              onChange={handleAllListingChecked}
            />
            <Stack spacing={"4px"} alignItems={"flex-start"}>
              <p className="B-14-20-500">All listings (23 property)</p>
              <p className="B-14-20-500" style={{ color: "#5E6278" }}>
                Choose all properties
              </p>
            </Stack>
          </Stack>
          <Stack direction="row" spacing={1}>
            <SecondaryRadioButton
              label={""}
              value={""}
              checked={isSelectListingChecked}
              onChange={handleSelectListingChecked}
            />
            <Stack spacing={"4px"} alignItems={"flex-start"}>
              {addedProperties.length > 0 ? (
                addedProperties.map((property) => (
                  <Box
                    key={property.id}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "8px",
                      alignItems: "center",
                    }}
                  >
                    <p className="B-14-20-500">
                      Select listings ( {property.listingIds.length} listings )
                    </p>
                    <Button
                      onClick={() => {
                        setSelectedListings(property.listingIds);
                        setSelectedPropertyId(property.id);
                        isMobile
                          ? setOpenMobileModal(true)
                          : setOpenModal(true);
                      }}
                      sx={{ padding: "0px" }}
                    >
                      Edit
                    </Button>
                  </Box>
                ))
              ) : (
                <p className="B-14-20-500">Select listings</p>
              )}
              <p className="B-14-20-500" style={{ color: "#5E6278" }}>
                Choose special property
              </p>
            </Stack>
          </Stack>
        </Stack>
        {/* == */}
        <Stack
          spacing={3}
          paddingY={5}
          borderBottom={"1px solid #EBEBEB"}
          maxWidth={isMobile ? "100%" : "858px"}
        >
          <p
            className="B-18-18-600"
            style={{ color: "#181C32", lineHeight: "20px" }}
          >
            Gap mode
          </p>
          <Stack direction="row" spacing={1}>
            <SecondaryRadioButton
              label={""}
              value={""}
              checked={isBookedNightsChecked}
              onChange={handleBookedNightsChange}
            />
            <Stack spacing={"4px"} alignItems={"flex-start"}>
              <p className="B-14-20-500">Booked nights(recommended):</p>
              <p className="B-14-20-500" style={{ color: "#5E6278" }}>
                Message each guest individually. Offer a discount to extend
                their stay on any available nights on either end of their trip.
              </p>
            </Stack>
          </Stack>
          <Stack direction="row" spacing={1}>
            <SecondaryRadioButton
              label={""}
              value={""}
              checked={isOrphanNightsChecked}
              onChange={handleOrphanNightsChange}
            />
            <Stack spacing={"4px"} alignItems={"flex-start"}>
              <p className="B-14-20-500">Orphan nights:</p>
              <p className="B-14-20-500" style={{ color: "#5E6278" }}>
                Message both guests with a discounted offer whenever there is a
                gap between their bookings. (This is less effective because it
                requires a gap between two bookings, as opposed to taking
                advantage of unused nights on either end of a booking. Note that
                if you select 'bookend' above, it will do everything that
                'Orphan Nights' does, plus more.)
              </p>
            </Stack>
          </Stack>
        </Stack>
        {/* == */}
        <Stack
          spacing={3}
          paddingY={isMobile ? 3 : 5}
          borderBottom={"1px solid #EBEBEB"}
          maxWidth={isMobile ? "100%" : "858px"}
        >
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            width={"100%"}
            alignContent={"center"}
            alignItems={"center"}
          >
            <p
              className="B-18-18-600"
              style={{ color: "#181C32", lineHeight: "20px" }}
            >
              Nights offer
            </p>
            {isMobile && (
              <CustomButton
                backgroundColor="transparent"
                color="#181C32"
                onClick={() => setOpenNightsOfferModal(true)}
                buttonText="Edit"
                marginTop={0}
                textDecoration="underline"
              />
            )}
          </Stack>
          {!isMobile && (
            <>
              <Stack spacing={"12px"} width={"100%"}>
                <Stack spacing={"4px"} alignItems={"flex-start"}>
                  <p className="B-14-20-500">Maximum nights to offer</p>
                  <p className="B-14-20-500" style={{ color: "#5E6278" }}>
                    For example, if you enter 2, Botel will offer to extend the
                    guest's stay by 1 or 2 nights, both before and after their
                    trip, only using available nights.
                  </p>
                </Stack>
                <MainTextField
                  title=""
                  placeholder="Add days number to offer"
                  type="number"
                  value={addDays || ""}
                  onChange={(event) => setAddDays(event.target.value)}
                  onBlur={() => {
                    if (!addDays) {
                      setAddDaysError("Add days is required.");
                    } else if (isNaN(Number(addDays)) || Number(addDays) <= 0) {
                      setAddDaysError("Please enter a valid number of days.");
                    } else {
                      setAddDaysError(null);
                    }
                  }}
                  errorMessage={addDaysError}
                />
              </Stack>
              <Stack spacing={"12px"} width={"100%"}>
                <p className="B-14-20-500">Which nights?</p>
                <AssignerSelect
                  isSelectedItemsChip={true}
                  multiple={true}
                  options={[
                    {
                      label: "Monday",
                      value: "Monday",
                    },
                    {
                      label: "Tuesday",
                      value: "Tuesday",
                    },
                    {
                      label: "Wednesday",
                      value: "Wednesday",
                    },
                    {
                      label: "Thursday",
                      value: "Thursday",
                    },
                    {
                      label: "Friday",
                      value: "Friday",
                    },
                    {
                      label: "Saturday",
                      value: "Saturday",
                    },
                    {
                      label: "Sunday",
                      value: "Sunday",
                    },
                  ]}
                  value={addNights}
                  onChange={(_, newValue) => {
                    if (Array.isArray(newValue)) {
                      setAddNights(newValue);
                      setAddNightsError(null);
                    }
                  }}
                  placeholder={"All nights"}
                  errorMessage={addNightsError}
                />
              </Stack>
              <Stack spacing={"12px"} width={"100%"}>
                <p className="B-14-20-500">Nightly discount (%)</p>
                <MainTextField
                  title=""
                  placeholder="20"
                  type="number"
                  value={nightlyDiscount || ""}
                  onChange={(event) => setNightlyDiscount(event.target.value)}
                  onBlur={() => {
                    if (!nightlyDiscount) {
                      setNightlyDiscountError("Nightly discount is required.");
                    } else if (
                      isNaN(Number(nightlyDiscount)) ||
                      Number(nightlyDiscount) <= 0
                    ) {
                      setNightlyDiscountError(
                        "Please enter a valid nightly discount percentage."
                      );
                    } else {
                      setNightlyDiscountError(null);
                    }
                  }}
                  endAdornment={
                    <img src={PercentageIcon} alt="Percentage Icon" />
                  }
                  errorMessage={nightlyDiscountError}
                />
              </Stack>
            </>
          )}
        </Stack>
        {/* == */}
        <Stack
          spacing={3}
          paddingY={isMobile ? 3 : 5}
          borderBottom={"1px solid #EBEBEB"}
          maxWidth={isMobile ? "100%" : "858px"}
        >
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            width={"100%"}
            alignContent={"center"}
            alignItems={"center"}
          >
            <p
              className="B-18-18-600"
              style={{ color: "#181C32", lineHeight: "20px" }}
            >
              When to send offers (optional):
            </p>
            {isMobile && (
              <CustomButton
                backgroundColor="transparent"
                color="#181C32"
                onClick={() => setOpenWhenSendOffersModal(true)}
                buttonText="Edit"
                marginTop={0}
                textDecoration="underline"
              />
            )}
          </Stack>
          {!isMobile && (
            <Stack
              spacing={1}
              width={"100%"}
              direction={"row"}
              alignItems={"center"}
              flexWrap={"wrap"}
            >
              <p
                className="B-14-20-500"
                style={{ color: "#5E6278", lineHeight: "20px" }}
              >
                Send the offer message
              </p>
              <Stack width={"120px"} p={1}>
                <MainTextField
                  title={""}
                  placeholder={"100"}
                  type={"number"}
                  value={sendOfferMessageInput || ""}
                  onChange={(event) =>
                    setSendOfferMessageInput(event.target.value)
                  }
                  sx={{
                    "& .MuiInputBase-input": {
                      height: "0.45em",
                    },
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#A1A5B7",
                    },
                  }}
                />
              </Stack>
              <Stack width={"120px"} p={"8px 8px 8px 0px"}>
                <CustomSelect
                  value={sendOfferTime || ""}
                  pagetype="selectWithParagraphHeight"
                  onChange={(event: any) =>
                    setSendOfferTime(event.target.value)
                  }
                  label="Hours"
                  options={[
                    {
                      value: "days",
                      label: "Days",
                    },
                    {
                      value: "weeks",
                      label: "Weeks",
                    },
                    {
                      value: "months",
                      label: "Months",
                    },
                  ]}
                />
              </Stack>
              <p
                className="B-14-20-500"
                style={{ color: "#5E6278", lineHeight: "20px" }}
              >
                after the gap is detected.
              </p>

              <p
                className="B-14-20-500"
                style={{ color: "#5E6278", lineHeight: "20px" }}
              >
                Don't send the offer message until
              </p>
              <Stack width={"120px"} p={1}>
                <MainTextField
                  title={""}
                  placeholder={"100"}
                  type={"number"}
                  value={dontSendOfferMessageInput || ""}
                  onChange={(event) =>
                    setDontSendOfferMessageInput(event.target.value)
                  }
                  sx={{
                    "& .MuiInputBase-input": {
                      height: "0.45em",
                    },
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#A1A5B7",
                    },
                  }}
                />
              </Stack>
              <Stack width={"120px"} p={"8px 8px 8px 0px"}>
                <CustomSelect
                  value={dontSendOfferTime || ""}
                  pagetype="selectWithParagraphHeight"
                  onChange={(event: any) =>
                    setDontSendOfferTime(event.target.value)
                  }
                  label="Hours"
                  options={[
                    {
                      value: "days",
                      label: "Days",
                    },
                    {
                      value: "weeks",
                      label: "Weeks",
                    },
                    {
                      value: "months",
                      label: "Months",
                    },
                  ]}
                />
              </Stack>
              <p
                className="B-14-20-500"
                style={{ color: "#5E6278", lineHeight: "20px" }}
              >
                before the start of the first booking. (Set to 0 to disable.)
              </p>

              <p
                className="B-14-20-500"
                style={{ color: "#5E6278", lineHeight: "20px" }}
              >
                Don't send the offer message if it the booking starts less than
              </p>
              <Stack width={"120px"} p={1}>
                <MainTextField
                  title={""}
                  placeholder={"100"}
                  type={"number"}
                  value={dontSendOfferLessThanInput || ""}
                  onChange={(event) =>
                    setDontSendOfferLessThanInput(event.target.value)
                  }
                  sx={{
                    "& .MuiInputBase-input": {
                      height: "0.45em",
                    },
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#A1A5B7",
                    },
                  }}
                />
              </Stack>
              <Stack width={"120px"} p={"8px 8px 8px 0px"}>
                <CustomSelect
                  value={dontSendOfferLessThan || ""}
                  pagetype="selectWithParagraphHeight"
                  onChange={(event: any) =>
                    setDontSendOfferLessThan(event.target.value)
                  }
                  label="Hours"
                  options={[
                    {
                      value: "days",
                      label: "Days",
                    },
                    {
                      value: "weeks",
                      label: "Weeks",
                    },
                    {
                      value: "months",
                      label: "Months",
                    },
                  ]}
                />
              </Stack>
              <p
                className="B-14-20-500"
                style={{ color: "#5E6278", lineHeight: "20px" }}
              >
                out. (Set to 0 to disable.)
              </p>
            </Stack>
          )}
        </Stack>
        {/* == */}
        <Stack
          paddingY={isMobile ? 3 : 5}
          borderBottom={"1px solid #EBEBEB"}
          maxWidth={isMobile ? "100%" : "858px"}
        >
          <Stack
            direction={isMobile ? "row" : "column"}
            justifyContent={isMobile ? "space-between" : "flex-start"}
            width={"100%"}
            alignContent={"center"}
            alignItems={isMobile ? "center" : "flex-start"}
            spacing={isMobile ? 0 : 1}
          >
            <p
              className="B-18-18-600"
              style={{ color: "#181C32", lineHeight: "20px" }}
            >
              Channel markups (highly recommended):
            </p>
            {!isMobile && (
              <p className="B-14-20-400">
                Botel will use the latest rates from your calendar to apply a
                discount to. Optionally, you can set a per-channel markup on top
                of the calendar rate.
              </p>
            )}
            {isMobile && (
              <CustomButton
                backgroundColor="transparent"
                color="#181C32"
                onClick={() => setOpenChannelMarkupsModal(true)}
                buttonText="Edit"
                marginTop={0}
                textDecoration="underline"
              />
            )}

            {!isMobile && (
              <Stack pt={"12px"} width={"100%"}>
                <CustomSelect
                  value={channelMarkups || ""}
                  pagetype="selectWithTextFieldHeight"
                  onChange={(event: any) => {
                    setChannelMarkups(event.target.value);
                    if (event.target.value === "setPerChannelsMarkups") {
                      setOpen(true);
                    } else {
                      setOpen(false);
                    }
                  }}
                  label="No per-channels markups"
                  options={[
                    {
                      value: "setPerChannelsMarkups",
                      label: "Set per-channels markups",
                    },
                  ]}
                />
              </Stack>
            )}
          </Stack>
          {/* markups modal */}
          {open && (
            <Stack mt={5}>
              <Stack
                spacing={3}
                pt={3}
                px={3}
                alignItems={"flex-start"}
                borderRadius={2}
                border={"1px solid var(--Gray-Gray-400, #D8D8E5)"}
              >
                <Stack
                  direction={"row"}
                  alignItems={"flex-start"}
                  justifyContent={"space-between"}
                  width={"100%"}
                >
                  <p
                    className="B-14-22-500"
                    style={{ color: "#7E8299", maxWidth: "720px" }}
                  >
                    Set per-channel markups here. For example, if a calendar
                    night is listed at $100, and you want the Airbnb price to be
                    $130, enter 30% next to Airbnb.
                  </p>
                </Stack>
                <Stack spacing={"12px"} minWidth={"400px"} pb={5}>
                  <p className="B-14-24-500" style={{ color: "#181C32" }}>
                    Airbnb
                  </p>
                  <MainTextField
                    title={""}
                    placeholder={"100"}
                    type={"number"}
                    value={airbnbMarkup || ""}
                    onChange={(event: any) =>
                      setAirbnbMarkup(event.target.value)
                    }
                    endAdornment={
                      <img src={PercentageIcon} alt="Percentage Icon" />
                    }
                  />
                  <p className="B-14-24-500" style={{ color: "#181C32" }}>
                    Booking.com
                  </p>
                  <MainTextField
                    title={""}
                    placeholder={"100"}
                    type={"number"}
                    value={bookingMarkup || ""}
                    onChange={(event: any) =>
                      setBookingMarkup(event.target.value)
                    }
                    endAdornment={
                      <img src={PercentageIcon} alt="Percentage Icon" />
                    }
                  />
                  <p className="B-14-24-500" style={{ color: "#181C32" }}>
                    Expedia
                  </p>
                  <MainTextField
                    title={""}
                    placeholder={"100"}
                    type={"number"}
                    value={expediaMarkup || ""}
                    onChange={(event: any) =>
                      setExpediaMarkup(event.target.value)
                    }
                    endAdornment={
                      <img src={PercentageIcon} alt="Percentage Icon" />
                    }
                  />
                </Stack>
              </Stack>
            </Stack>
          )}
        </Stack>
        {/* == */}
        <Stack
          paddingY={isMobile ? 3 : 5}
          maxWidth={isMobile ? "100%" : "858px"}
        >
          <Stack
            direction={isMobile ? "row" : "column"}
            justifyContent={isMobile ? "space-between" : "flex-start"}
            width={"100%"}
            alignContent={"center"}
            alignItems={isMobile ? "center" : "flex-start"}
            spacing={isMobile ? 0 : 1}
          >
            <p
              className="B-18-18-600"
              style={{ color: "#181C32", lineHeight: "20px" }}
            >
              Set Template Messages
            </p>
            {isMobile && (
              <CustomButton
                backgroundColor="transparent"
                color="#181C32"
                onClick={() => setOpenSetTemplateMessagesModal(true)}
                buttonText="Edit"
                marginTop={0}
                textDecoration="underline"
              />
            )}
            {!isMobile && (
              <p className="B-14-20-400">
                This feature allows you to send personalized offers to guests
              </p>
            )}
          </Stack>
          {!isMobile && (
            <Stack pt={3}>
              <MessageCreator
                chipLabels={chipLabels}
                messages={messages}
                setMessages={setMessages}
              />
            </Stack>
          )}
        </Stack>
        {/* == */}
        {!isMobile && (
          <Stack justifyContent={"flex-end"} width={"100%"}>
            <CustomButton
              backgroundColor="#0065E0"
              color="#FFFFFF"
              buttonText="Save All"
              marginTop={0}
              onClick={() => { }}
              disable={isSaveDisabled}
            />
          </Stack>
        )}
      </Box>
      <CustomModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        handleSave={() => { }}
        title="Choose listings"
        showFooter={false}
      >
        <ListingModal
          handleAddProperty={handleAddProperty}
          selectedListings={selectedListings}
        />
      </CustomModal>
      <CustomModalMobile
        open={openMobileModal}
        handleClose={() => setOpenMobileModal(false)}
        title="Select Listings"
        showFooter={false}
        handleSave={() => {
          handleAddProperty();
          setOpenMobileModal(false);
        }}
        height="100%"
      >
        <ListingModal
          handleAddProperty={handleAddProperty}
          selectedListings={selectedListings}
        />
      </CustomModalMobile>
      <CustomModalMobile
        open={openNightsOfferModal}
        handleClose={() => setOpenNightsOfferModal(false)}
        title="Nights offer"
        showFooter={false}
        handleSave={() => { }}
      >
        <>
          <Stack spacing={"12px"} width={"100%"}>
            <Stack spacing={"4px"} alignItems={"flex-start"}>
              <p className="B-14-20-500">Maximum nights to offer</p>
              <p className="B-14-20-500" style={{ color: "#5E6278" }}>
                For example, if you enter 2, Botel will offer to extend the
                guest's stay by 1 or 2 nights, both before and after their trip,
                only using available nights.
              </p>
            </Stack>
            <MainTextField
              title={""}
              placeholder={"Add days number to offer"}
              type={"number"}
              value={addDays || ""}
              onChange={(event: any) => setAddDays(event.target.value)}
              onBlur={() => {
                if (!addDays) {
                  setAddDaysError("Add days is required.");
                } else if (isNaN(Number(addDays)) || Number(addDays) <= 0) {
                  setAddDaysError("Please enter a valid number of days.");
                } else {
                  setAddDaysError(null);
                }
              }}
              errorMessage={addDaysError}
            />
          </Stack>
          <Stack spacing={"12px"} width={"100%"} pt={2}>
            <p className="B-14-20-500">Which nights?</p>
            <CustomMobileSelectMenu
              title="Which nights?"
              options={nightsOptions}
              multiple={true}
              selectedValues={selectedNights}
              setSelectedValues={(newValues) => {
                setSelectedNights(newValues);
                setAddNightsError(
                  newValues.length === 0 ? "Add nights is required." : null
                );
              }}
              errorMessage={addNightsError}
            />
          </Stack>

          <Stack spacing={"12px"} width={"100%"} pt={2}>
            <p className="B-14-20-500">Nightly discount (%)</p>
            <MainTextField
              title={""}
              placeholder={"20"}
              type={"number"}
              value={nightlyDiscount || ""}
              onChange={(event: any) => setNightlyDiscount(event.target.value)}
              onBlur={() => {
                if (!nightlyDiscount) {
                  setNightlyDiscountError("Nightly discount is required.");
                } else if (
                  isNaN(Number(nightlyDiscount)) ||
                  Number(nightlyDiscount) <= 0
                ) {
                  setNightlyDiscountError(
                    "Please enter a valid nightly discount percentage."
                  );
                } else {
                  setNightlyDiscountError(null);
                }
              }}
              endAdornment={<img src={PercentageIcon} alt="Percentage Icon" />}
              errorMessage={nightlyDiscountError}
            />
          </Stack>
          <Stack width={"100%"} pt={4}>
            <CustomButton
              backgroundColor="#0065E0"
              color="#FFFFFF"
              buttonText="Save"
              marginTop={0}
              onClick={() => setOpenNightsOfferModal(false)}
              buttonWidth="100%"
              disable={isSaveDisabled}
            />
          </Stack>
        </>
      </CustomModalMobile>
      <CustomModalMobile
        open={openWhenSendOffersModal}
        handleClose={() => setOpenWhenSendOffersModal(false)}
        title="When to send offers (optional)"
        showFooter={false}
        handleSave={() => { }}
      >
        <Stack
          spacing={"12px"}
          width={"100%"}
          alignItems="flex-start"
          alignSelf="stretch"
        >
          <p
            className="B-14-20-500"
            style={{ color: "#5E6278", lineHeight: "20px" }}
          >
            Send the offer message
          </p>
          <Stack width={"100%"} direction={"row"} spacing={1}>
            <Stack flex={2}>
              <MainTextField
                title={""}
                placeholder={"100"}
                type={"number"}
                value={sendOfferMessageInput || ""}
                onChange={(event) =>
                  setSendOfferMessageInput(event.target.value)
                }
                sx={{
                  "& .MuiInputBase-input": {
                    height: "0.45em",
                  },
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#A1A5B7",
                  },
                }}
              />
            </Stack>
            <Stack flex={1}>
              <CustomSelect
                value={sendOfferTime || ""}
                pagetype="selectWithParagraphHeight"
                onChange={(event: any) => setSendOfferTime(event.target.value)}
                label="Hours"
                options={[
                  {
                    value: "days",
                    label: "Days",
                  },
                  {
                    value: "weeks",
                    label: "Weeks",
                  },
                  {
                    value: "months",
                    label: "Months",
                  },
                ]}
              />
            </Stack>
          </Stack>
          <p
            className="B-14-20-500"
            style={{ color: "#5E6278", lineHeight: "20px" }}
          >
            after the gap is detected.
          </p>
          <p
            className="B-14-20-500"
            style={{ color: "#5E6278", lineHeight: "20px", paddingTop: "20px" }}
          >
            Don't send the offer message until
          </p>
          <Stack width={"100%"} direction={"row"} spacing={1}>
            <Stack flex={2}>
              <MainTextField
                title={""}
                placeholder={"100"}
                type={"number"}
                value={dontSendOfferMessageInput || ""}
                onChange={(event) =>
                  setDontSendOfferMessageInput(event.target.value)
                }
                sx={{
                  "& .MuiInputBase-input": {
                    height: "0.45em",
                  },
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#A1A5B7",
                  },
                }}
              />
            </Stack>
            <Stack flex={1}>
              <CustomSelect
                value={dontSendOfferTime || ""}
                pagetype="selectWithParagraphHeight"
                onChange={(event: any) =>
                  setDontSendOfferTime(event.target.value)
                }
                label="Hours"
                options={[
                  {
                    value: "days",
                    label: "Days",
                  },
                  {
                    value: "weeks",
                    label: "Weeks",
                  },
                  {
                    value: "months",
                    label: "Months",
                  },
                ]}
              />
            </Stack>
          </Stack>
          <p
            className="B-14-20-500"
            style={{ color: "#5E6278", lineHeight: "20px" }}
          >
            before the start of the first booking. (Set to 0 to disable.)
          </p>

          <p
            className="B-14-20-500"
            style={{ color: "#5E6278", lineHeight: "20px", paddingTop: "20px" }}
          >
            Don't send the offer message if it the booking starts less than
          </p>
          <Stack width={"100%"} direction={"row"} spacing={1}>
            <Stack flex={2}>
              <MainTextField
                title={""}
                placeholder={"100"}
                type={"number"}
                value={dontSendOfferLessThanInput || ""}
                onChange={(event: any) =>
                  setDontSendOfferLessThanInput(event.target.value)
                }
                sx={{
                  "& .MuiInputBase-input": {
                    height: "0.45em",
                  },
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#A1A5B7",
                  },
                }}
              />
            </Stack>
            <Stack flex={1}>
              <CustomSelect
                value={dontSendOfferLessThan || ""}
                pagetype="selectWithParagraphHeight"
                onChange={(event: any) =>
                  setDontSendOfferLessThan(event.target.value)
                }
                label="Hours"
                options={[
                  {
                    value: "days",
                    label: "Days",
                  },
                  {
                    value: "weeks",
                    label: "Weeks",
                  },
                  {
                    value: "months",
                    label: "Months",
                  },
                ]}
              />
            </Stack>
          </Stack>
          <p
            className="B-14-20-500"
            style={{ color: "#5E6278", lineHeight: "20px" }}
          >
            out. (Set to 0 to disable.)
          </p>
          <Stack width={"100%"} pt={4}>
            <CustomButton
              backgroundColor="#0065E0"
              color="#FFFFFF"
              buttonText="Save"
              marginTop={0}
              onClick={() => {
                setSendOfferTime(sendOfferTime);
                setDontSendOfferTime(dontSendOfferTime);
                setDontSendOfferLessThan(dontSendOfferLessThan);
                setSendOfferMessageInput(sendOfferTime);
                setDontSendOfferMessageInput(dontSendOfferTime);
                setDontSendOfferLessThanInput(dontSendOfferLessThan);
                setOpenWhenSendOffersModal(false);
              }}
              buttonWidth="100%"
            />
          </Stack>
        </Stack>
      </CustomModalMobile>
      <CustomModalMobile
        open={openChannelMarkupsModal}
        handleClose={() => setOpenChannelMarkupsModal(false)}
        title="Channel markups (highly recommended):"
        showFooter={false}
        handleSave={() => { }}
      >
        <Stack spacing={3} width={"100%"}>
          <p className="B-14-20-400">
            Set per-channel markups here. For example, if a calendar night is
            listed at $100, and you want the Airbnb price to be $130, enter 30%
            next to Airbnb.
          </p>

          <Stack width={"100%"} spacing={3} alignItems={"flex-start"}>
            <Stack spacing={"12px"} width={"100%"}>
              <p className="B-14-24-500" style={{ color: "#181C32" }}>
                Airbnb
              </p>
              <MainTextField
                title={""}
                placeholder={"100"}
                type={"number"}
                value={airbnbMarkup || ""}
                onChange={(event: any) => setAirbnbMarkup(event.target.value)}
                endAdornment={
                  <img src={PercentageIcon} alt="Percentage Icon" />
                }
              />
              <p className="B-14-24-500" style={{ color: "#181C32" }}>
                Booking.com
              </p>
              <MainTextField
                title={""}
                placeholder={"100"}
                type={"number"}
                value={bookingMarkup || ""}
                onChange={(event: any) => setBookingMarkup(event.target.value)}
                endAdornment={
                  <img src={PercentageIcon} alt="Percentage Icon" />
                }
              />
              <p className="B-14-24-500" style={{ color: "#181C32" }}>
                Expedia
              </p>
              <MainTextField
                title={""}
                placeholder={"100"}
                type={"number"}
                value={expediaMarkup || ""}
                onChange={(event: any) => setExpediaMarkup(event.target.value)}
                endAdornment={
                  <img src={PercentageIcon} alt="Percentage Icon" />
                }
              />
              <p className="B-14-22-500" style={{ color: "#7E8299" }}>
                Botel will use the latest rates from your calendar to apply a
                discount to. Optionally, you can set a per-channel markup on top
                of the calendar rate.
              </p>
            </Stack>
          </Stack>
          <Stack width={"100%"}>
            <CustomButton
              backgroundColor="#0065E0"
              color="#FFFFFF"
              buttonText="Save"
              marginTop={0}
              onClick={() => {
                setChannelMarkups(channelMarkups);
                setOpenChannelMarkupsModal(false);
              }}
              buttonWidth="100%"
            />
          </Stack>
        </Stack>
      </CustomModalMobile>
      <CustomModalMobile
        open={openSetTemplateMessagesModal}
        handleClose={() => setOpenSetTemplateMessagesModal(false)}
        title="Set Template Messages"
        showFooter={false}
        handleSave={() => { }}
      >
        <Stack spacing={3} width={"100%"}>
          <p className="B-14-20-400">
            This feature allows you to send personalized offers to guests
          </p>
          <MessageCreator
            chipLabels={chipLabels}
            messages={messages}
            setMessages={setMessages}
          />
          <Stack width={"100%"}>
            <CustomButton
              backgroundColor="#0065E0"
              color="#FFFFFF"
              buttonText="Save"
              marginTop={0}
              onClick={() => handleSaveMessages()}
              buttonWidth="100%"
            />
          </Stack>
        </Stack>
      </CustomModalMobile>
    </Stack>
  );
};

export default BookingBooster;
