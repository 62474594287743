import React, { useState } from "react";
import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import MainTextField from "../../../../components/core-components/inputs/TextField/MainTextField";
import AiIcon from "../../../../assets/images/icons/technologies.svg";
import { useTranslation } from "react-i18next";
import UploadPhotoArea from "./UploadPhotoArea";
import CustomModalMobile from "../../../../components/shared-components/CustomModalMobile";
import CustomModal from "../../../../components/shared-components/CustomModal";

interface HeroContentModalProps {
  open: boolean;
  onClose: () => void;
}

function HeroContentModal({ open, onClose }: HeroContentModalProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [headline, setHeadline] = useState("");
  const [subheadline, setSubheadline] = useState("");

  const handleHeadlineChange = (e: any) => {
    if (e.target.value.length > 30) return;
    setHeadline(e.target.value);
  };

  const handleSubheadlineChange = (e: any) => {
    if (e.target.value.length > 106) return;
    setSubheadline(e.target.value);
  };

  const modalContent = (
    <Stack
      spacing={3}
      sx={{ padding: isMobile ? "0px 0px 24px 0px" : "0px 24px" }}
    >
      <UploadPhotoArea />
      <Stack spacing={1}>
        <p className="B-14" style={{ color: "#3F4254" }}>
          Headline
        </p>
        <Box
          mt={3}
          sx={{
            position: "relative",
          }}
        >
          <MainTextField
            title={""}
            placeholder={t("detailsPages.details.writeYourHeadline")}
            value={headline}
            onChange={handleHeadlineChange}
            isMandatory={false}
            multiline={true}
            row={4}
            maxLength={30}
            textareaWidth="90%"
          />
          <button className="onboarding-step3-second-textfield-button">
            <img
              src={AiIcon}
              alt="AI"
              className="onboarding-step3-textfield-button-icon"
            />
            <p className="onboarding-step3-textfield-button-text">AI</p>
          </button>
        </Box>
        <p className="character-counter">{`${headline.length}/30`}</p>
      </Stack>
      <Stack spacing={1}>
        <p className="B-14" style={{ color: "#3F4254" }}>
          Sub-Headline
        </p>
        <Box
          mt={3}
          sx={{
            position: "relative",
          }}
        >
          <MainTextField
            title={""}
            placeholder={t("detailsPages.details.writeYourSubHeadline")}
            value={subheadline}
            onChange={handleSubheadlineChange}
            isMandatory={false}
            multiline={true}
            row={4}
            maxLength={106}
            textareaWidth="90%"
          />
          <button className="onboarding-step3-second-textfield-button">
            <img
              src={AiIcon}
              alt="AI"
              className="onboarding-step3-textfield-button-icon"
            />
            <p className="onboarding-step3-textfield-button-text">AI</p>
          </button>
        </Box>
        <p className="character-counter">{`${subheadline.length}/106`}</p>
      </Stack>
    </Stack>
  );

  return isMobile ? (
    <CustomModalMobile
      open={open}
      handleClose={onClose}
      title="Hero Content"
      description="Set your business apart with eye-catching content at the top of your landing page"
      showFooter={true}
      handleSave={onClose}
    >
      {modalContent}
    </CustomModalMobile>
  ) : (
    <CustomModal
      open={open}
      handleClose={onClose}
      title="Hero Content"
      description="Set your business apart with eye-catching content at the top of your landing page"
      showFooter={true}
      handleSave={onClose}
      maxHeight={"calc(100vh - 300px)"}
    >
      {modalContent}
    </CustomModal>
  );
}

export default HeroContentModal;
