import { Button, Stack, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import MainTextField from "../../../../components/core-components/inputs/TextField/MainTextField";
import ArrowDown from "../../../../assets/images/icons/arrowdown.svg";
import SelectRoleModal from "./SelectRoleModal";
import CustomModal from "../../../../components/shared-components/CustomModal";
import ListingModal from "../step2/ListingModal";
import CustomModalMobile from "../../../../components/shared-components/CustomModalMobile";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

interface AddTeamMemberModalProps {
  isFormValid: boolean;
  onSave: (isFormValid: boolean) => void;
  handleSave: (formData: TeamMemberFormData) => void;
}

interface TeamMemberFormData {
  name: string;
  email: string;
  phone: string;
  role: string | null;
  roleDisplayName: string | null;
  listingIds: number[];
}

interface Role {
  id: string;
  name: string;
  description: string;
  providerCode: string;
}

function AddTeamMemberModal({
  isFormValid,
  onSave,
  handleSave,
}: AddTeamMemberModalProps) {
  const { getAccessTokenSilently } = useAuth0();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // State
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [selectedRole, setSelectedRole] = useState<string | null>(null);
  const [selectedRoleName, setSelectedRoleName] = useState<string | null>(null);
  const [roles, setRoles] = useState<Role[]>([]);
  const [selectedPropertyId, setSelectedPropertyId] = useState("");
  const [selectedListings, setSelectedListings] = useState<number[]>([]);

  const [nameError, setNameError] = useState<string | null>(null);
  const [emailError, setEmailError] = useState<string | null>(null);
  const [phoneError, setPhoneError] = useState<string | null>(null);

  const [openListingModal, setOpenListingModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isMobileRoleModalOpen, setIsMobileRoleModalOpen] = useState(false);
  const [isMobileListingModalOpen, setIsMobileListingModalOpen] =
    useState(false);
  const API_BASE_URL = process.env.REACT_APP_API_URL;
  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        const response = await axios.get(`${API_BASE_URL}/Role/List`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setRoles(response.data);
      } catch (error) {
        console.error("Roles API error:", error);
      }
    };

    fetchRoles();
  }, [getAccessTokenSilently]);

  const validatePhone = (phone: string) => {
    const re = /^\+?\d{1,3}?\s?\d{3}\s?\d{3}\s?\d{2}\s?\d{2}$/;
    return re.test(phone);
  };

  const validateName = (name: string) => name.trim() !== "";

  const validateEmail = (email: string) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleNameBlur = () => {
    setNameError(validateName(name) ? null : "Name is required");
  };

  const handleEmailBlur = () => {
    setEmailError(validateEmail(email) ? null : "Invalid email address");
  };

  const handlePhoneBlur = () => {
    setPhoneError(validatePhone(phone) ? null : "Invalid phone number");
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(e.target.value);
  };

  const handleAddProperty = () => {
    if (selectedListings.length === 0) {

    } else {
      if (selectedPropertyId) {
        updateProperty();
      }
    }
    setSelectedListings([]);
    setOpenListingModal(false);
    setSelectedPropertyId("");
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleSaveModal = () => setOpenModal(false);
  const handleOpenListingModal = () => setOpenListingModal(true);
  const handleCloseListingModal = () => setOpenListingModal(false);

  const handleRoleSelection = (name: string) => {
    const selectedRole = roles.find((role) => role.name === name);
    if (selectedRole) {
      setSelectedRole(selectedRole.id);
      setSelectedRoleName(name);
    }
  };

  const updateProperty = () => {
    setSelectedPropertyId("");
    setSelectedListings([]);
  };

  const prepareFormSubmission = () => {
    const formData: TeamMemberFormData = {
      name,
      email,
      phone,
      role: selectedRole,
      roleDisplayName: selectedRoleName,
      listingIds: selectedListings,
    };
    handleSave(formData);
  };

  useEffect(() => {
    if (isFormValid) {
      prepareFormSubmission();
    }
  }, [isFormValid]);

  useEffect(() => {
    const isFormFilled =
      name.trim() !== "" &&
      email.trim() !== "" &&
      phone.trim() !== "" &&
      selectedRole !== null;
    onSave(isFormFilled);
  }, [name, email, phone, selectedRole, onSave]);

  const displayedOptions = roles.map((role) => role.name);

  return (
    <Stack
      spacing={2}
      alignItems={"flex-start"}
      width={"100%"}
      minWidth={isMobile ? "100%" : "480px"}
      pb={isMobile ? 3 : undefined}
    >
      <Stack spacing={1} width={"100%"} alignItems={"flex-start"}>
        <span className="B-14-22-500">Full Name</span>
        <MainTextField
          title={""}
          onChange={handleNameChange}
          onBlur={handleNameBlur}
          value={name}
          placeholder={"John Doe"}
          errorMessage={nameError}
        />
      </Stack>
      <Stack spacing={1} width={"100%"} alignItems={"flex-start"}>
        <span className="B-14-22-500">E-mail Address</span>
        <MainTextField
          title={""}
          onChange={handleEmailChange}
          onBlur={handleEmailBlur}
          value={email}
          type="email"
          placeholder={"john_doe@gmail.com"}
          errorMessage={emailError}
        />
      </Stack>
      <Stack spacing={1} width={"100%"} alignItems={"flex-start"}>
        <span className="B-14-22-500">Phone Number</span>
        <MainTextField
          title={""}
          onChange={handlePhoneChange}
          onBlur={handlePhoneBlur}
          value={phone}
          type="tel"
          placeholder={"+90 555 123 45 67"}
          errorMessage={phoneError}
        />
      </Stack>
      <Stack spacing={1} width={"100%"} alignItems={"flex-start"}>
        <span className="B-14-22-500">Select Role</span>
        <Button
          onClick={
            isMobile ? () => setIsMobileRoleModalOpen(true) : handleOpenModal
          }
          sx={{
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: "20px",
            padding: "7px 10px",
            borderRadius: "6px",
            border: "1px solid #a1a5b7",
            color: "var(--Gray-500, #667085)",
            minWidth: "100px",
            width: "100%",
            height: "50px",
            justifyContent: "space-between",
          }}
          endIcon={<img src={ArrowDown} alt="arrow-down" />}
        >
          {selectedRoleName || "Select role"}
        </Button>
      </Stack>
      <Stack spacing={1} width={"100%"} alignItems={"flex-start"}>
        <span className="B-14-22-500">Add Listing</span>
        <p
          onClick={
            isMobile
              ? () => setIsMobileListingModalOpen(true)
              : handleOpenListingModal
          }
          className="B-12-12-700"
          style={{ color: "#3E97FF", cursor: "pointer" }}
        >
          Add/Remove
        </p>
      </Stack>

      <CustomModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        handleSave={handleSaveModal}
        title="Select role"
      >
        <SelectRoleModal
          displayedOptions={displayedOptions}
          selectedRole={selectedRoleName}
          setSelectedRole={handleRoleSelection}
          roles={roles}
        />
      </CustomModal>

      <CustomModal
        open={openListingModal}
        handleClose={handleCloseListingModal}
        handleSave={() => { }}
        title="Listing Responsibility"
        showFooter={false}
      >
        <ListingModal
          handleAddProperty={handleAddProperty}
          selectedListings={selectedListings}
        />
      </CustomModal>

      <CustomModalMobile
        open={isMobileRoleModalOpen}
        handleClose={() => setIsMobileRoleModalOpen(false)}
        title="Role"
        showFooter={true}
        handleSave={() => {
          handleSaveModal();
          setIsMobileRoleModalOpen(false);
        }}
      >
        <SelectRoleModal
          displayedOptions={displayedOptions}
          selectedRole={selectedRoleName}
          setSelectedRole={handleRoleSelection}
          roles={roles}
        />
      </CustomModalMobile>

      <CustomModalMobile
        open={isMobileListingModalOpen}
        handleClose={() => setIsMobileListingModalOpen(false)}
        title="Listing Responsibility"
        showFooter={false}
        handleSave={() => {
          setIsMobileListingModalOpen(false);
        }}
        height="100%"
      >
        <ListingModal
          handleAddProperty={handleAddProperty}
          selectedListings={selectedListings}
        />
      </CustomModalMobile>
    </Stack>
  );
}

export default AddTeamMemberModal;
