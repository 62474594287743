import axios from "axios";
import { BASE_API_URL } from ".";

export interface GetNewRefreshTokenParams {
  accessToken: string;
  refreshToken: string;
}

export const getNewRefreshTokenApi = async ({
  accessToken,
  refreshToken,
}: GetNewRefreshTokenParams) => {
  try {
    const response = await axios({
      method: "post",
      url: `${BASE_API_URL}/User/RefreshToken`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      data: {
        refreshToken: refreshToken,
      },
    });
    return response
  } catch (error: any) {
    throw new Error(
      error.response?.data?.message || "Failed to Get new Token"
    );
  };
}



