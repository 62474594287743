import React, { useEffect, useState } from "react";
import locationPin from "../../../assets/images/locationPin.svg"
import placeholder from "../../../assets/images/placeholder.png";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { autopilotFetchAllProperties, handleSelectedListing, } from "../../../store/slices/autopilotSlice";
import { useSelector } from "react-redux";
import { Modal, Text, Title, TextInput, Checkbox, Flex, Button } from "@mantine/core";
import "./ListPropertyModal.scss"
import { IconX } from "@tabler/icons-react";
import { PropertyLite } from "../../../types";



interface ListPropertyModalParams {
  open: boolean,
  handleClose: () => void
}

const ListPropertyModal = ({
  open,
  handleClose
}: ListPropertyModalParams) => {
  const dispatch = useDispatch<AppDispatch>()

  const { properties, selectedListings } = useSelector((state: RootState) => state.autopilot)

  const [filteredListings, setFilteredListings] = useState<any>([]);
  const [search, setSearch] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const onClose = async () => {
    dispatch(handleSelectedListing(selectedListings))
    handleClose()
  }


  const _handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.currentTarget;
    if (target.scrollHeight - target.scrollTop === target.clientHeight) {
      console.log("Next page")
    }
  };


  const _handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);

    const searchTerm = e.target.value.toLowerCase();
    const filtered: any[] = properties.filter(
      (listing: any) =>
        listing.internalName?.toLowerCase().includes(searchTerm) ||
        listing.name.toLowerCase().includes(searchTerm) ||
        listing.city.toLowerCase().includes(searchTerm)
    );

    setFilteredListings(filtered.map(f => f.id));
  };

  const _handleToggle = (listPro: PropertyLite) => {
    const includes = selectedListings.find(pro => pro.id === listPro.id)
    if (includes) {
      dispatch(handleSelectedListing(
        selectedListings.filter((pro: PropertyLite) => pro.id !== listPro.id)
      ))
    } else {
      dispatch(handleSelectedListing([...selectedListings, listPro]))
    }
  };


  const _handleSelectAll = () => {
    if (!selectAll) {
      dispatch(handleSelectedListing(properties.map((listing: any) => listing.Id)))
      setSelectAll(true)
    } else {
      dispatch(handleSelectedListing([]))
      setSelectAll(false)
    }
  };


  useEffect(() => {
    const fetchListings = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        dispatch(autopilotFetchAllProperties({ accessToken, PageSize: "20", PageIndex: "1" }))
      } catch (error) {
        console.error("API call error:", error);
      }
    };

    fetchListings();
  }, [getAccessTokenSilently]);

  return (
    <Modal
      className="list-property-modal"
      title={<Title order={3}>Add Listing</Title>}
      opened={open}
      onClose={onClose}
      centered
      size="80%"
    >
      <div className="property-list-main-box" >
        <div className="property-list-body" >
          <div className="property-list-inner">
            <div className="property-list-header">
              <Title order={4}>
                All Listings
              </Title>
              <TextInput
                value={search}
                onChange={_handleSearch}
                size="md"
                placeholder="Search"
              />
              <Checkbox
                label="Selecte all"
                onChange={_handleSelectAll}
              />
            </div>
            <div className="property-list-scroll-area" onScroll={_handleScroll}>
              {properties.length > 0 ? (properties.map((pro: PropertyLite) => {

                if (search.length > 0) {
                  if (!filteredListings.includes(pro.id)) return ''
                }

                const checked = selectedListings.findIndex(listPro => listPro.id === pro.id) !== -1

                return (
                  <div className={`list-item ${checked && "active"}`} key={pro.id} onClick={() => _handleToggle(pro)} >
                    <Checkbox
                      onChange={() => _handleToggle(pro)}
                      checked={checked}
                    />
                    <div className="details">
                      <img
                        style={{ width: "48px", height: "48px" }}
                        src={pro.image || placeholder}
                        alt="listing"
                      />
                      <div className="text-flex">
                        <Text fw={600} size="md"> {pro.internalName || pro.name}</Text>
                        <Text size="sm">
                          <img
                            style={{
                              width: "12px",
                              paddingTop: "1px",
                              height: "12px",
                              marginRight: "4px",
                            }}
                            src={locationPin}
                            alt="location"
                          />
                          {pro.state + ", " + pro.city}
                        </Text>
                      </div>
                    </div>
                  </div>
                )
              }))
                : (
                  <Flex align="center" w="100%" h="100%" justify="center">
                    <p className="listingTitle"> No listings selected...</p>
                  </Flex>

                )}
            </div>

          </div>
          <div className="property-list-inner">
            <div className="property-list-header">
              <Title order={4}>
                Selected Listings ({selectedListings.length})
              </Title>
            </div>
            <div className="property-list-scroll-area">
              {selectedListings.length > 0 ? (selectedListings.map((listing: any) => (

                <div className="list-item"
                  key={listing.Id}
                  onChange={() => _handleToggle(listing.Id)}
                >
                  <div className="details">
                    <img
                      style={{ width: "48px", height: "48px" }}
                      src={listing.image || locationPin}
                      alt="listing"
                    />
                    <div className="text-flex">
                      <Text fw={600} size="md"> {listing.internalName || listing.name}</Text>
                      <Text size="sm">
                        <img
                          style={{
                            width: "12px",
                            paddingTop: "1px",
                            height: "12px",
                            marginRight: "4px",
                          }}
                          src={locationPin}
                          alt="location"
                        />
                        {listing.city}
                      </Text>
                    </div>
                    <IconX
                      className="close-icon"
                      color='gray'
                      onClick={(e) => {
                        e.stopPropagation();
                        _handleToggle(listing);
                      }}
                    />
                  </div>
                </div>
              ))
              ) : selectedListings.length > 0 && (
                <Flex align="center" w="100%" h="100%" justify="center">
                  <p className="listingTitle"> No listings selected...</p>
                </Flex>
              )}
            </div>
          </div>
        </div>
        <Flex justify="flex-end">
          <Button
            mt={10}
            bg="blue.5"
            size="lg"
            onClick={() => { onClose() }}
          >
            <Text fw={600} size="md">{`Apply for ${selectedListings.length || "all"} properties`}</Text>
          </Button>
        </Flex>
      </div>
    </Modal >
  );
}

export default ListPropertyModal;
