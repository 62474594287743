
export const listingAreaContainerStyles = {
  mainContainer: {
    display: "flex",
    padding: "24px 0px",
    justifyContent: "space-between",
    alignItems: "flex-start",
    alignSelf: "stretch",
    borderTop: "1px solid var(--stroke-colors, #ebebeb)",
    borderBottom: "1px solid var(--stroke-colors, #ebebeb)",
  },
  innerMainContainer: {
    display: "flex",
    padding: "24px 0px",
    justifyContent: "space-between",
    alignItems: "flex-start",
    alignSelf: "stretch",
  },
  innerContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    maxWidth: "812px",
    width: "100%",
    minHeight: "240px",
    borderRadius: "16px",
    border: "1px solid var(--Gray-Gray-400, #d8d8e5)",
  },
  modalBottomPart: {
    display: "flex",
    maxWidth: "812px",
    width: "100%",
    padding: "14px 24px",
    justifyContent: "space-between",
    alignItems: "flex-start",
    borderTop: "1px solid var(--Gray-Gray-400, #d8d8e5)",
  },
};
