import React from "react";
import { mobileDataGridStyles } from "../../views/private/listing/DatagridStyles";
import { DataGrid } from "@mui/x-data-grid";

interface MobileGridDataProps {
  rows: any;
  columns: any;
  checkboxSelection: boolean;
  setPage?: (value: number) => void;
  total?: number;
  page?: number;
  rowHeight?: number;
}

const MobileGridData: React.FC<MobileGridDataProps> = ({
  rows,
  columns,
  setPage,
  checkboxSelection,
  total = 0,
  page = 1,
  rowHeight,
}) => {
  const handlePaginationModelChange = (newModel: {
    page: number;
    pageSize: number;
  }) => {
    if (setPage) {
      setPage(newModel.page + 1);
    }
  };

  const pageSize = 3;

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      autoHeight
      rowHeight={rowHeight}
      sx={{
        ...mobileDataGridStyles.root,
      }}
      paginationMode="server"
      rowCount={total}
      pageSizeOptions={[pageSize]}
      getRowId={(row) => row.Id}
      checkboxSelection={checkboxSelection}
      disableRowSelectionOnClick
      onPaginationModelChange={handlePaginationModelChange}
      paginationModel={{
        page: page - 1,
        pageSize: pageSize,
      }}
      hideFooterPagination
    />
  );
};

export default MobileGridData;
