import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import CloseModalButton from "../../../../../../components/shared-components/CloseModalButton";
import { t } from "i18next";
import { MainStylesSheet } from "../../views/main/MainStylesSheet";
import { MobileStylesSheet } from "../../views/main/MobileStylesSheet";
import { inputSectionStyles } from "../../../DatagridStyles";

interface Modalprops {
  open: boolean;
  setOpen: (open: boolean) => void;
  children: React.ReactNode;
  width?: string;
}
function ModalContainer({
  open,
  setOpen,
  children,
  width = "812",
}: Modalprops) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Dialog
      sx={[
        isMobile ? MobileStylesSheet.muiPaper : MainStylesSheet.muiPaper,
        {
          " .MuiDialog-paper": {
            maxWidth: `${width}px`,
          },
        },
      ]}
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle
        sx={inputSectionStyles.detailsMainModal}
        id="responsive-dialog-title"
      >
        {" "}
        <span
          style={{
            position: "absolute",
            top: "0",
          }}
        >
          {" "}
          <CloseModalButton show={true} onClick={() => setOpen(false)} />
        </span>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions
        sx={{ ...MainStylesSheet.modalFooter, justifyContent: "space-between" }}
      >
        <button
          onClick={() => setOpen(false)}
          className="listing-details-is-editing-box-bottom-button-cancel"
        >
          {t("customModal.cancel")}
        </button>
        <button onClick={() => setOpen(false)} className="applyButton">
          {t("customModal.save")}
        </button>
      </DialogActions>
    </Dialog>
  );
}

export default ModalContainer;
