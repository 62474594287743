import { IconButton, Stack } from "@mui/material";
import React from "react";
import BackIcon from "../../assets/images/icons/BackIcon.svg";
import CustomSwitch from "../../components/shared-components/CustomSwitch";

interface MobileHeaderProps {
  handleBackClick: () => void;
  title: string;
  description?: any;
  isSwitchShown?: boolean;
  switchChecked?: boolean;
  onSwitchChange?: () => void;
  isBackIconShown?: boolean;
  position?: "relative" | "sticky" | "fixed";
  zIndex?: number;
  top?: number | string;
}

const MobileHeader: React.FC<MobileHeaderProps> = ({
  handleBackClick,
  title,
  description,
  isSwitchShown = false,
  switchChecked = true,
  onSwitchChange,
  isBackIconShown = true,
  position = "sticky",
  zIndex = 1000,
  top = 0,
}) => {
  return (
    <Stack
      alignItems="flex-start"
      width={"100%"}
      py={"12px"}
      sx={{
        borderBottom: "1px solid #EBEBEB",
        position: position,
        top: top,
        zIndex: zIndex,
        backgroundColor: "#fff",
      }}
    >
      <Stack
        direction={"row"}
        alignItems="center"
        width={"100%"}
        justifyContent={"space-between"}
      >
        <Stack direction={"row"} alignItems="center" width={"100%"}>
          {isBackIconShown && (
            <IconButton
              onClick={handleBackClick}
              sx={{
                alignContent: "center",
                alignSelf: "center",
                pl: 0,
              }}
            >
              <img src={BackIcon} alt="back-icon" />
            </IconButton>
          )}
          <p className="H-24">{title}</p>
        </Stack>
        {isSwitchShown && (
          <CustomSwitch
            checked={switchChecked}
            onChange={
              onSwitchChange as (
                event: React.ChangeEvent<HTMLInputElement>
              ) => void
            }
          />
        )}
      </Stack>

      {description && (
        <p className="B-14-20-400" style={{ maxWidth: "100%" }}>
          {description}
        </p>
      )}
    </Stack>
  );
};

export default MobileHeader;
