import React, { useState } from "react";
import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import HeaderTitle from "../components/HeaderTitle";
import BookingSettings from "../components/BookingSettings";
import Botel from "../../../../assets/images/icons/botel.png";
import Copy from "../../../../assets/images/icons/copy.svg";
import CustomSwitch from "../../../../components/shared-components/CustomSwitch";
import { useTranslation } from "react-i18next";
import { SnackbarCloseReason } from "@mui/material/Snackbar";
import Snackbar from "@mui/material/Snackbar";
import MobileHeader from "../../../../components/shared-components/MobileHeader";
import { useNavigate } from "react-router-dom";

function Payments() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const [fiatPaymentSwitch1, setFiatPaymentSwitch1] = useState(true);
  const [cryptoPaymentSwitch1, setCryptoPaymentSwitch1] = useState(true);
  const [cryptoPaymentSwitch2, setCryptoPaymentSwitch2] = useState(true);
  const [cryptoPaymentSwitch3, setCryptoPaymentSwitch3] = useState(true);
  const [copySuccess, setCopySuccess] = useState(false);
  const subtitle = "0x9f8a8a6f.5242624";
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate("/menu");
  };

  const handleFiatPaymentSwitch1 = () => {
    setFiatPaymentSwitch1((prevState) => !prevState);
  };

  const handleCryptoPaymentSwitch1 = () => {
    setCryptoPaymentSwitch1((prevState) => !prevState);
  };

  const handleCryptoPaymentSwitch2 = () => {
    setCryptoPaymentSwitch2((prevState) => !prevState);
  };

  const handleCryptoPaymentSwitch3 = () => {
    setCryptoPaymentSwitch3((prevState) => !prevState);
  };

  const handleCopy = () => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(subtitle)
        .then(() => setCopySuccess(true))
        .catch((err) => console.error("Could not copy text: ", err));
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = subtitle;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        //const successful = document.execCommand("copy");
        //setCopySuccess(successful);
      } catch (err) {
        console.error("Fallback: Oops, unable to copy", err);
      }
      document.body.removeChild(textArea);
    }
  };

  const handleClose = (
    _: React.SyntheticEvent<any, Event> | Event,
    reason: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setCopySuccess(false);
  };

  const cryptoPaymentMethods = [
    {
      title: "Stripe",
      subtitle:
        "Accept all major credit/debit cards and fiat currencies. Includes 3-4% transaction fees.",
      img: Botel,
      alt: "botel",
      switchChecked: cryptoPaymentSwitch1,
      switchHandler: handleCryptoPaymentSwitch1,
    },
    {
      title: "Stripe",
      subtitle:
        "Accept all major credit/debit cards and fiat currencies. Includes 3-4% transaction fees.",
      img: Botel,
      alt: "botel",
      switchChecked: cryptoPaymentSwitch2,
      switchHandler: handleCryptoPaymentSwitch2,
    },
    {
      title: "Stripe",
      subtitle:
        "Accept all major credit/debit cards and fiat currencies. Includes 3-4% transaction fees.",
      img: Botel,
      alt: "botel",
      switchChecked: cryptoPaymentSwitch3,
      switchHandler: handleCryptoPaymentSwitch3,
    },
  ];

  return (
    <Stack
      width={"100%"}
      className={
        !isMobile
          ? "acc-users-box-multiple-title"
          : "acc-users-box-mobile-multiple-title"
      }
      pb={20}
      gap={isMobile ? 0 : 3}
    >
      {isMobile && (
        <MobileHeader
          title={t("settingspage.paymentTitle")}
          description={t("settingspage.paymentSubtitle")}
          handleBackClick={handleBackClick}
          isSwitchShown={false}
          switchChecked={false}
          onSwitchChange={() => { }}
        />
      )}
      {!isMobile && (
        <Stack
          justifyContent="flex-start"
          alignSelf={"flex-start"}
          alignItems="center"
          width={"100%"}
          maxWidth={isMobile ? "100%" : "812px"}
        >
          <HeaderTitle
            title={t("settingspage.paymentTitle")}
            subtitle={t("settingspage.paymentSubtitle")}
          />
        </Stack>
      )}

      <Stack alignSelf={"flex-start"} width={"100%"}>
        <Stack spacing={3} paddingBottom={5} width={"100%"}>
          <Box className="booking-settings-box">
            <Box className="pms-body-box-top-logo">
              <img src={Botel} alt="botel" />
            </Box>
            <BookingSettings
              title={"Stripe"}
              subtitle={t("settingspage.stripeSubtitle")}
            />
            <button className="pms-body-box-top-btn B-13-13-600">
              {t("settingspage.stripeButton")}
            </button>
          </Box>
          <Box className="booking-settings-box">
            <Box className="pms-body-box-top-logo">
              <img src={Botel} alt="botel" />
            </Box>
            <BookingSettings
              title={t("settingspage.walletTitle")}
              subtitle={subtitle}
            />
            <button
              onClick={handleCopy}
              className="pms-body-box-top-btn B-13-13-600"
            >
              <img
                src={Copy}
                alt="vector"
                style={{
                  display: "flex",
                  width: "17.917px",
                  height: "17.917px",
                  padding: "1.042px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              />
              {t("settingspage.copyInfoButton")}
            </button>
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              open={copySuccess}
              autoHideDuration={1000}
              onClose={handleClose}
              message={t("settingspage.bookingsite.copiedMessage")}
            />
          </Box>

          <Box alignSelf={"flex-start"} sx={{ marginTop: "64px" }}>
            <Box
              width={"100%"}
              maxWidth={isMobile ? "100%" : "812px"}
              className="acc-users-box-header"
            >
              <HeaderTitle
                title={t("settingspage.paymentMethodsTitle")}
                subtitle={t("settingspage.paymentMethodsSubtitle")}
              />
            </Box>
          </Box>
          <Box className="payment-methods-box">
            <p className="payment-methods-box-title B-18-18-600">
              {t("settingspage.fiatPaymentTitle")}
            </p>
            <Box className="booking-settings-box">
              <Box className="pms-body-box-top-logo">
                <img src={Botel} alt="botel" />
              </Box>
              <BookingSettings
                title={"Stripe"}
                subtitle={t("settingspage.stripeSubtitle")}
              />
              <CustomSwitch
                checked={fiatPaymentSwitch1}
                onChange={handleFiatPaymentSwitch1}
              />
            </Box>
            <Box className="payment-methods-box">
              <p className="payment-methods-box-title B-18-18-600">
                {t("settingspage.cryptoPaymentTitle")}
              </p>
              {cryptoPaymentMethods.map((method, index) => (
                <Box key={index} className="booking-settings-box">
                  <Box className="pms-body-box-top-logo">
                    <img src={method.img} alt={method.alt} />
                  </Box>
                  <BookingSettings
                    title={method.title}
                    subtitle={method.subtitle}
                  />
                  <CustomSwitch
                    checked={method.switchChecked}
                    onChange={method.switchHandler}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default Payments;
