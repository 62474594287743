import {
  Stack,
  IconButton,
  InputAdornment,
  Link,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import MainTextField from "../../../../components/core-components/inputs/TextField/MainTextField";
import CustomButton from "../../../../components/shared-components/CustomButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CustomModalMobile from "../../../../components/shared-components/CustomModalMobile";
import CustomModal from "../../../../components/shared-components/CustomModal";

interface PasswordModalProps {
  onSave: (newPassword: string) => void;
  onClose: () => void;
}

function PasswordModal({ onSave, onClose }: PasswordModalProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [showCurrentPassword, setShowCurrentPassword] =
    useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<string | null>(null);
  const [isForgotPasswordModalOpen, setIsForgotPasswordModalOpen] =
    useState<boolean>(false);
  const handleForgotPassword = () => {
    setIsForgotPasswordModalOpen(true);
  };
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const handleToggleShowCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };
  const handleToggleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };
  const handleToggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const handleSave = () => {
    if (newPassword !== confirmPassword) {
      setPasswordError("New password and confirm password must match");
    } else {
      setPasswordError(null);
      onSave(newPassword);
      onClose();
    }
  };
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (!emailRegex.test(e.target.value)) {
      setEmailError("Invalid email address");
    } else {
      setEmailError(null);
    }
  };

  const modalContent = (
    <>
      <Stack spacing={2} pb={4}>
        <p className="B-14-24-400" style={{ color: "#7E8299" }}>
          Enter the email address associated with your account, and we’ll email
          you a link to reset your password.
        </p>
        <Stack spacing={"12px"}>
          <p className="B-14-20-500">Email adress</p>
          <MainTextField
            title=""
            onChange={handleEmailChange}
            value={email}
            placeholder="Enter your email"
            type="email"
            errorMessage={emailError}
          />
        </Stack>
      </Stack>
      <CustomButton
        backgroundColor="#0065E0"
        color="#FFFFFF"
        onClick={() => setIsForgotPasswordModalOpen(false)}
        buttonText="Send"
        marginTop={0}
        buttonWidth={"100%"}
      />
    </>
  );

  return (
    <>
      <Stack spacing={3} pb={4}>
        {/* Current Password Field */}
        <Stack spacing={"12px"}>
          <p className="B-14-20-500">Current password</p>
          <MainTextField
            title=""
            onChange={(e) => setCurrentPassword(e.target.value)}
            value={currentPassword}
            placeholder="Enter current password"
            type={showCurrentPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={handleToggleShowCurrentPassword}
                  edge="end"
                >
                  {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          <Link
            href="#"
            underline="hover"
            color="primary"
            onClick={handleForgotPassword}
            sx={{ fontSize: "12px" }}
          >
            Forget password?
          </Link>
        </Stack>

        {/* New Password Field */}
        <Stack spacing={"12px"}>
          <p className="B-14-20-500">New Password</p>
          <MainTextField
            title=""
            onChange={(e) => setNewPassword(e.target.value)}
            value={newPassword}
            placeholder="Enter new password"
            type={showNewPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={handleToggleShowNewPassword} edge="end">
                  {showNewPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </Stack>

        {/* Confirm Password Field */}
        <Stack spacing={"12px"}>
          <p className="B-14-20-500">Confirm new password</p>
          <MainTextField
            title=""
            onChange={(e) => setConfirmPassword(e.target.value)}
            value={confirmPassword}
            placeholder="Re-enter new password"
            type={showConfirmPassword ? "text" : "password"}
            errorMessage={passwordError}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={handleToggleShowConfirmPassword}
                  edge="end"
                >
                  {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </Stack>
      </Stack>

      {/* Save Button */}
      <CustomButton
        backgroundColor="#0065E0"
        color="#FFFFFF"
        onClick={handleSave}
        buttonText="Save"
        marginTop={0}
        buttonWidth={"100%"}
      />
      {isMobile ? (
        <CustomModalMobile
          open={isForgotPasswordModalOpen}
          handleClose={() => setIsForgotPasswordModalOpen(false)}
          title="Reset Password"
          showFooter={false}
          handleSave={() => setIsForgotPasswordModalOpen(false)}
        >
          {modalContent}
        </CustomModalMobile>
      ) : (
        <CustomModal
          open={isForgotPasswordModalOpen}
          handleClose={() => setIsForgotPasswordModalOpen(false)}
          title="Reset Password"
          showFooter={false}
          handleSave={() => setIsForgotPasswordModalOpen(false)}
        >
          {modalContent}
        </CustomModal>
      )}
    </>
  );
}

export default PasswordModal;
