import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import React from "react";
import ArrowDown from "../../../../assets/images/icons/down.svg";
import { ReactComponent as DateIcon } from "../../../../assets/images/icons/date-picker-calendar.svg";
import { inputCoreStyles } from "../../listing/DatagridStyles";
import CustomCheckbox from "../../../../components/core-components/inputs/Checkbox/CustomCheckbox";
import { useTranslation } from "react-i18next";

function AddPromoCode() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedDate, setSelectedDate] = React.useState(dayjs());
  const { t } = useTranslation();
  const [isCheckboxChecked, setIsCheckboxChecked] = React.useState(false);
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsCheckboxChecked(event.target.checked);
  };
  return (
    <Stack spacing={3} sx={{ width: "100%" }}>
      <Stack
        sx={{ width: "100%" }}
        direction={isMobile ? "column" : "row"}
        spacing={2}
      >
        <Box flexGrow={1}>
          <p
            style={{
              marginBottom: "8px",
            }}
            className="add-promo-modal-name-label"
          >
            {t("settingspage.code")}
            <sup>*</sup>
          </p>
          <TextField
            fullWidth
            id="outlined-basic"
            variant="outlined"
            sx={inputCoreStyles.textField}
            placeholder={t("settingspage.enterCode")}
          />
        </Box>
        <Box flexGrow={1}>
          <p
            style={{
              marginBottom: "8px",
            }}
            className="add-promo-modal-name-label"
          >
            {t("settingspage.name")}({t("settingspage.optional")})
          </p>
          <TextField
            fullWidth
            id="outlined-basic"
            variant="outlined"
            sx={inputCoreStyles.textField}
            placeholder={t("settingspage.enterName")}
          />
        </Box>
      </Stack>
      <FormControl fullWidth sx={{ minWidth: 120, width: "100%" }}>
        <p
          style={{
            marginBottom: "8px",
          }}
          className="add-promo-modal-name-label"
        >
          {t("settingspage.discount")}
          <sup>*</sup>
        </p>
        <Select
          value={""}
          sx={inputCoreStyles.select}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          IconComponent={() => (
            <img
              style={{
                cursor: "pointer",
                marginLeft: "10px",
              }}
              src={ArrowDown}
              alt="arrow-down"
            />
          )}
        >
          <MenuItem value="">
            <em style={{ fontWeight: "500" }}>{t("detailsPages.select")}</em>
          </MenuItem>
          <MenuItem value="percent">{t("settingspage.percent")}</MenuItem>
          <MenuItem value="amount">{t("settingspage.amount")}</MenuItem>
        </Select>
      </FormControl>
      <Stack sx={{ width: "100%" }} direction="row" spacing={2}>
        <Box flexGrow={1} sx={{ width: "100%" }}>
          <p
            style={{
              marginBottom: "8px",
            }}
            className="add-promo-modal-name-label"
          >
            {t("settingspage.startDate")}
          </p>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={selectedDate}
              onChange={(newValue) => {
                setSelectedDate(newValue || dayjs());
              }}
              className="performance-text-area-right"
              format="DD MMM YYYY"
              slots={{
                openPickerIcon: DateIcon,
              }}
              sx={{ ...inputCoreStyles.datePicker, width: "100%" }}
            />
          </LocalizationProvider>
        </Box>
        <Box flexGrow={1} sx={{ width: "100%" }}>
          <p
            style={{
              marginBottom: "8px",
            }}
            className="add-promo-modal-name-label"
          >
            {t("settingspage.finishedDate")}
          </p>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={selectedDate}
              onChange={(newValue) => {
                setSelectedDate(newValue || dayjs());
              }}
              className="performance-text-area-right"
              format="DD MMM YYYY"
              slots={{
                openPickerIcon: DateIcon,
              }}
              sx={{ ...inputCoreStyles.datePicker, width: "100%" }}
            />
          </LocalizationProvider>
        </Box>
      </Stack>
      <Stack
        sx={{ width: "100%" }}
        direction="row"
        alignItems={"center"}
        spacing={2}
      >
        <CustomCheckbox
          position="left"
          label={t("settingspage.limitNumber")}
          inputName="limitDiscount"
          onChange={handleCheckboxChange}
        />
      </Stack>
      {isCheckboxChecked && (
        <Stack sx={{ width: "100%" }} direction="row" spacing={2}>
          <Box flexGrow={1}>
            <p
              style={{
                marginBottom: "8px",
              }}
              className="add-promo-modal-name-label"
            >
              {t("settingspage.maxDiscount")}
            </p>
            <TextField
              fullWidth
              id="outlined-basic"
              variant="outlined"
              sx={inputCoreStyles.textField}
              placeholder={t("settingspage.enterNumber")}
            />
          </Box>
        </Stack>
      )}
    </Stack>
  );
}

export default AddPromoCode;
