import { Add } from "@mui/icons-material";
import {
  Box,
  Modal,
  Stack,
} from "@mui/material";
import React from "react";
import illustation from "../../../../assets/images/icons/to add.svg";
import CloseModalButton from "../../../../components/shared-components/CloseModalButton";


import { useTranslation } from "react-i18next";
import AddPromoCode from "./AddPromoCode";

function PromoAddBanner() {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSave = () => {
    setOpen(false);
  };

  return (
    <>
      <Stack
        sx={{
          background: "#3F4254",
          border: "5px solid #3E97FF",
          borderRadius: "12px",
          height: "200px",
          maxWidth: "812px",
          width: "100%",
        }}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        {" "}
        <Stack
          alignItems={"flex-start"}
          justifyContent={"space-between"}
          className="promo-box-title"
          spacing={1}
        >
          <p className="H-24">Promo Codes</p>
          <p
            style={{
              maxWidth: "329px",
              lineHeight: "24px",
            }}
            className="B-16-20-400 promo-box-subtitle"
          >
            {t("settingspage.connectPayment")}
          </p>
          <button
            onClick={handleOpen}
            className="buttonLg"
            aria-label="add listing"
          >
            <Add />
            <span className="buttonText B-16-16-600">
              {t("settingspage.addPromo")}
            </span>
          </button>
        </Stack>
        <Box>
          <img
            className="promoCodeIllustration"
            src={illustation}
            alt="illustation"
          />
        </Box>
      </Stack>
      <Modal open={open} onClose={handleClose}>
        <div onClick={handleClose}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                padding: "40px 32px",
                gap: "24px",
                backgroundColor: "background.paper",
                background: "#FFF",
                boxShadow: "0px 16px 32px 0px rgba(0, 0, 0, 0.12)",
                borderRadius: "12px",
              }}
              onClick={(event) => event.stopPropagation()}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "495px",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  alignSelf: "stretch",
                }}
              >
                <p
                  style={{
                    color: "var(--Gray-Gray-900, #181C32)",
                    fontFamily: "Inter",
                    fontSize: "24px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "24px",
                    letterSpacing: "-0.24px",
                  }}
                >
                  {t("settingspage.addPromoCode")}
                </p>
                <CloseModalButton show={true} onClick={handleClose} />
              </Box>
              <AddPromoCode />
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  padding: "8px 0px",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <button
                  onClick={handleSave}
                  style={{
                    display: "flex",
                    width: "76px",
                    padding: "12px 16px",
                    justifyContent: "center",
                    gap: "10px",
                    alignItems: "center",
                    cursor: "pointer",
                    border: "none",
                    borderRadius: "50px",
                    background: "var(--Blue-light, #0065E0)",
                    fontFamily: "Inter",
                    fontSize: "13px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    lineHeight: "14px",
                    color: "var(--white-dark-white, #FFF)",
                  }}
                >
                  {t("settingspage.confirm")}
                </button>
              </Box>
            </Box>
          </Box>
        </div>
      </Modal>
    </>
  );
}

export default PromoAddBanner;
