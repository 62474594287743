import { useMutation } from "react-query";
import axios from "axios";
import { useAuth0, GetTokenSilentlyOptions } from "@auth0/auth0-react";
import { CONNECTION_API_URL } from "./_apiConstants";

const refreshAccessToken = async (
  refreshToken: string,
  getAccessTokenSilently: (options?: GetTokenSilentlyOptions) => Promise<string>
) => {
  try {
    const authToken = await getAccessTokenSilently();
    console.log("Access Token:", authToken);
    console.log("Refresh Token:", refreshToken);

    const response = await axios.post(
      CONNECTION_API_URL,
      { refreshToken },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Refresh Token API error:", error);
    throw error;
  }
};

const useRefreshToken = () => {
  const { getAccessTokenSilently } = useAuth0();

  return useMutation((refreshToken: string) =>
    refreshAccessToken(refreshToken, getAccessTokenSilently)
  );
};

export default useRefreshToken;
