import * as signalR from "@microsoft/signalr";

interface Connection {
  state: signalR.HubConnectionState;
  hubConnection: signalR.HubConnection;
}

class SignalRService {
  private connection: signalR.HubConnection | null;
  private hubUrl: string | null;
  private connectionPromise: Promise<void> | null;
  private isConnecting: boolean;
  private activeConnections: Map<string, Connection>;
  private reconnectTimeoutId: NodeJS.Timeout | null;

  constructor() {
    this.connection = null;
    this.hubUrl = null;
    this.connectionPromise = null;
    this.isConnecting = false;
    this.activeConnections = new Map();
    this.reconnectTimeoutId = null;
  }

  private createConnection = (hubUrl: string): signalR.HubConnection => {
    return new signalR.HubConnectionBuilder()
      .withUrl(hubUrl, {
        withCredentials: false,
      })
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: (retryContext) => {
          const maxDelay = 30000;
          const baseDelay = 1000;
          return Math.min(
            baseDelay * Math.pow(2, retryContext.previousRetryCount),
            maxDelay
          );
        },
      })
      .build();
  };

  startConnection = async (hubUrl: string): Promise<void> => {
    if (this.connection?.state === signalR.HubConnectionState.Connected) {
      return Promise.resolve();
    }

    if (this.isConnecting && this.connectionPromise) {
      return this.connectionPromise;
    }

    this.hubUrl = hubUrl;
    this.isConnecting = true;

    try {
      this.connection = this.createConnection(hubUrl);
      this.setupConnectionEventListeners();

      this.connectionPromise = this.connection
        .start()
        .then(() => {
          this.isConnecting = false;
          this.addActiveConnection(hubUrl);
        })
        .catch((error) => {
          console.error("SignalR bağlantı hatası:", error);
          this.isConnecting = false;
          this.handleConnectionError(error);
          throw error;
        });

      return this.connectionPromise;
    } catch (error) {
      this.isConnecting = false;
      const typedError = error as Error;
      console.error("SignalR bağlantısı oluşturulurken hata:", typedError);
      return Promise.reject(typedError);
    }
  };

  private setupConnectionEventListeners = () => {
    if (!this.connection) return;

    this.connection.onclose((error) => {
      this.handleConnectionClosed(error);
    });

    this.connection.onreconnecting((error) => {
    });

    this.connection.onreconnected((connectionId) => {
      this.handleReconnected(connectionId);
    });
  };

  private handleConnectionError = (error: Error) => {
    this.scheduleReconnect();
  };

  private handleConnectionClosed = (error?: Error) => {
    if (error) {
    }
    this.scheduleReconnect();
  };

  private handleReconnected = (connectionId: string | undefined) => {
    if (this.reconnectTimeoutId) {
      clearTimeout(this.reconnectTimeoutId);
      this.reconnectTimeoutId = null;
    }
  };

  private scheduleReconnect = () => {
    if (this.reconnectTimeoutId) {
      clearTimeout(this.reconnectTimeoutId);
    }

    this.reconnectTimeoutId = setTimeout(() => {
      this.reconnect();
    }, 5000);
  };

  private reconnect = async () => {
    if (this.hubUrl && !this.isConnecting) {
      try {
        await this.startConnection(this.hubUrl);
      } catch (error) {
        this.scheduleReconnect();
      }
    }
  };

  private addActiveConnection = (hubUrl: string) => {
    if (this.connection) {
      this.activeConnections.set(hubUrl, {
        state: this.connection.state,
        hubConnection: this.connection,
      });
    }
  };

  private isConnectionValid = (): boolean => {
    return (
      this.connection !== null &&
      this.connection.state === signalR.HubConnectionState.Connected
    );
  };

  sendMessage = async (methodName: string, ...args: any[]): Promise<void> => {
    if (!this.connection) {
      return Promise.reject(new Error("Bağlantı bulunamadı"));
    }

    try {
      if (this.isConnectionValid()) {
        await this.connection.invoke(methodName, ...args);
      } else {
        await this.reconnect();

        if (this.isConnectionValid()) {
          await this.connection.invoke(methodName, ...args);
        } else {
          throw new Error("Bağlantı kurulamadı");
        }
      }
    } catch (error) {
      console.error(`Mesaj gönderme hatası (${methodName}):`, error);
      throw error;
    }
  };

  onReceiveMessage = (
    methodName: string,
    callback: (...args: any[]) => void
  ): void => {
    if (!this.connection) {
      console.error("Bağlantı yok. Mesaj dinleyici eklenemedi.");
      return;
    }

    try {
      this.connection.on(methodName, callback);
    } catch (error) {
      console.error(`Mesaj dinleyici ekleme hatası (${methodName}):`, error);
    }
  };

  offReceiveMessage = (methodName: string): void => {
    if (!this.connection) {
      return;
    }

    try {
      this.connection.off(methodName);
    } catch (error) {
      console.error(`Mesaj dinleyici kaldırma hatası (${methodName}):`, error);
    }
  };

  stopConnection = async (): Promise<void> => {
    if (!this.connection) {
      return Promise.resolve();
    }

    try {
      await this.connection.stop();
      this.connection = null;
      this.hubUrl = null;
      this.connectionPromise = null;
      this.isConnecting = false;
    } catch (error) {
      console.error("Bağlantı durdurma hatası:", error);
      throw error;
    }
  };

  getConnectionState = (): signalR.HubConnectionState | null => {
    return this.connection?.state || null;
  };
}

const signalRService = new SignalRService();
export default signalRService;
