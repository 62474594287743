import { Stack } from "@mui/material";
import DetailsContentTitle from "../../../../../../../../components/shared-components/DetailsContentTitle";
import MainTextField from "../../../../../../../../components/core-components/inputs/TextField/MainTextField";
import { useTranslation } from "react-i18next";

function WifiDetails() {
  const { t } = useTranslation();
  return (
    <>
      <Stack
        sx={{ width: "100%", maxWidth: "382px" }}
        component={"form"}
        spacing={2}
      >
        <DetailsContentTitle title={t("detailsPages.policies.wifiDetails")} />
        <MainTextField
          title={t("detailsPages.policies.wifiNetworkName")}
          placeholder={t("detailsPages.policies.networkName")}
          value=""
          onChange={() => {}}
          isMandatory={false}
        />
        <MainTextField
          title={t("detailsPages.policies.wifiPassword")}
          placeholder={t("detailsPages.policies.password")}
          value=""
          onChange={() => {}}
          isMandatory={false}
        />
      </Stack>
    </>
  );
}

export default WifiDetails;
