import React from "react";
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

function Router() {
  const navigate = useNavigate();

  const domain = process.env.REACT_APP_AUTH0_DOMAIN || "";
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID || "";
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE || "";

  if (!domain || !clientId) {
    throw new Error(
      "Please setup your .env file with REACT_APP_AUTH0_DOMAIN, REACT_APP_AUTH0_CLIENT_ID, and REACT_APP_AUTH0_AUDIENCE"
    );
  }

  const onRedirectCallback = (appState: any) => {
    console.log("appState", appState);
    navigate(appState?.returnTo || "/inbox");
  };
  //TODO: MOVE SIGNALR PROVIDER TO CLASS

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: window.location.origin + "/",
        scope: "openid profile email update:users offline_access",
        audience: audience,
      }}
      useRefreshTokens={true}
      cacheLocation="localstorage"
      onRedirectCallback={onRedirectCallback}
    >
      <App />
    </Auth0Provider>
  );
}

export default Router;
