import { Box, Button, Stack, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ReactComponent as DateIcon } from "../../../../../assets/images/icons/time.svg";
import React, { Dispatch, SetStateAction, useState } from "react";
import { rowsData } from "./data";
import dayjs, { Dayjs } from "dayjs";
import HiddenRowsModal from "./components/hidden-rows-modal";
import bulk from "../../../../../assets/images/icons/bulk.svg";
import filter from "../../../../../assets/images/icons/filter.svg";
import eye from "../../../../../assets/images/icons/eye.svg";
import BulkUpdateModal from "./components/bulk-update-modal";
import { keys } from "../../data";
import { useTranslation } from "react-i18next";

interface Props {
  startDate: Dayjs;
  setStartDate: Dispatch<SetStateAction<Dayjs>>;
  hiddenRows: string[];
  setHiddenRows: Dispatch<SetStateAction<string[]>>;
}

function Header(props: Props) {
  const { t } = useTranslation();
  const { startDate, setStartDate, hiddenRows, setHiddenRows } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [bulkUpdateAnchorEl, setBulkUpdateAnchorEl] =
    useState<HTMLButtonElement | null>(null);

  // const [createBookingModal, setCreateBookingModal] =
  //   useState<HTMLButtonElement | null>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleBulkUpdateClose = () => {
    setBulkUpdateAnchorEl(null);
  };

  // const handleCreateBookingClose = () => {
  //   setCreateBookingModal(null);
  // };

  const calendarModals = [
    {
      title: `${keys.length - hiddenRows.length} ${t(
        "calendarpage.hiddenRows"
      )}`,
      icon: eye,
      onClick: (e: any) => setAnchorEl(e.currentTarget),
      minWidth: "105px",
    },
    {
      title: t("calendarpage.bulkUpdate"),
      icon: bulk,
      onClick: (e: any) => setBulkUpdateAnchorEl(e.currentTarget),
    },
    {
      title: t("calendarpage.filterByListingAndTags"),
      icon: filter,
      onClick: () => { },
    },
  ];

  return (
    <>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        padding={"0px 24px"}
      >
        <Stack direction={"row"} alignItems={"center"} gap={"8px"}>
          <Button variant="outlined" onClick={() => setStartDate(dayjs())}>
            {t("calendarpage.today")}
          </Button>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format="MMM DD, YYYY"
              value={startDate}
              onChange={(date) => setStartDate(date as Dayjs)}
              slots={{
                openPickerIcon: DateIcon,
              }}
              slotProps={{
                textField: {
                  size: "small",
                  InputProps: {
                    style: {
                      borderRadius: "50px",
                      width: "152px",
                      paddingRight: "16px",
                      fontSize: "14px",
                    },
                  },
                },
              }}
            />
          </LocalizationProvider>
          <Stack direction={"row"}>
            {React.Children.toArray(
              rowsData.map((item, index) => (
                <Box
                  sx={{
                    width: "38px",
                    height: "38px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid #EBEBEB",
                    borderRadius:
                      index === 0
                        ? "6px 0px 0px 6px"
                        : index === rowsData.length - 1
                          ? "0px 6px 6px 0px"
                          : "0px",
                    cursor: "pointer",
                    transition: "0.3s ease all",
                    "&:hover": {
                      borderColor: (theme) => theme.palette.primary.main,
                    },
                  }}
                  onClick={() => {
                    setStartDate((prev) => prev.add(item.day, "day"));
                  }}
                >
                  <Box component={"img"} src={item.title} />
                </Box>
              ))
            )}
          </Stack>
        </Stack>
        <Stack direction={"row"} alignItems={"center"} gap={"24px"}>
          {React.Children.toArray(
            calendarModals.map((item) => (
              <Stack
                gap={"4px"}
                alignItems={"center"}
                direction={"row"}
                sx={{
                  cursor: "pointer",
                  minWidth: item.minWidth,
                }}
                onClick={(e) => item.onClick(e)}
              >
                <Box component={"img"} src={item.icon} />
                <Typography
                  sx={{ fontSize: "13px", color: "#3F4254", fontWeight: "600" }}
                >
                  {item.title}
                </Typography>
              </Stack>
            ))
          )}
        </Stack>
      </Stack>

      {anchorEl && (
        <HiddenRowsModal
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          handleClose={handleClose}
          hiddenRows={hiddenRows}
          setHiddenRows={setHiddenRows}
        />
      )}

      {/* {createBookingModal && (
        <CreateBookingModal
          open={Boolean(createBookingModal)}
          anchorEl={createBookingModal}
          handleClose={handleCreateBookingClose}
        />
      )} */}

      {bulkUpdateAnchorEl && (
        <BulkUpdateModal
          open={Boolean(bulkUpdateAnchorEl)}
          anchorEl={bulkUpdateAnchorEl}
          handleClose={handleBulkUpdateClose}
        />
      )}
    </>
  );
}

export default Header;
