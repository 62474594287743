import React from "react";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import MainTextField from "../../../../../../../../components/core-components/inputs/TextField/MainTextField";

function Stays() {
  const { t } = useTranslation();
  return (
    <Stack sx={{ width: "100%" }} spacing={4}>
      <p className="B-14-22-700">{t("detailsPages.pricing.minAndMaxStay")}</p>
      <Stack sx={{ maxWidth: "560px" }} spacing={2}>
        <Stack sx={{ width: "100%" }} spacing={1}>
          <MainTextField
            onChange={() => {}}
            title={t("detailsPages.pricing.minStay")}
            value="1"
            placeholder="1"
            type="number"
          />
        </Stack>
        <Stack sx={{ width: "100%" }} spacing={1}>
          <MainTextField
            onChange={() => {}}
            value="365"
            title={t("detailsPages.pricing.maxStay")}
            placeholder="365"
            type="number"
          />
        </Stack>
      </Stack>
    </Stack>
  );
}

export default Stays;
