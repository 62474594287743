import { Flex } from "@mantine/core";
import Logo from "../src/assets/images/Logo.png";

const LoadingFallback = () => {
  return (
    <Flex direction="column" w="100%" h="100vh" justify="center" align="center" gap={20}>
      <img
        width={200}
        src={Logo}
        alt="Botel Logo"
      />
      <div className="loading-container">
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <style>
          {`
          .loading-container {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
          }

          .dot {
            width: 15px;
            height: 15px;
            background-color: var(--mantine-color-blue-5); /* Adjust color as needed */
            border-radius: 50%;
            animation: loading-dots 1.2s infinite ease-in-out;
          }

          .dot:nth-child(1) {
            animation-delay: 0s;
          }

          .dot:nth-child(2) {
            animation-delay: 0.2s;
          }

          .dot:nth-child(3) {
            animation-delay: 0.4s;
          }

          @keyframes loading-dots {
            0%, 80%, 100% {
              transform: scale(0);
              opacity: 0.5;
            }
            40% {
              transform: scale(1);
              opacity: 1;
            }
          }
        `}
        </style>
      </div>
    </Flex>

  )

}

export default LoadingFallback;
