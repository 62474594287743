import React, { useState } from "react";
import { Stack } from "@mui/material";
import MainTextField from "../../../../../../../../components/core-components/inputs/TextField/MainTextField";
import CustomDatePicker from "../../../../../../../../components/core-components/inputs/DatePicker/CustomDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useTranslation } from "react-i18next";

function SeasonalRate() {
  const { t } = useTranslation();
  const [seasonName, setSeasonName] = useState("");
  const [basicNightPrice, setBasicNightPrice] = useState("");
  const [weekendNightPrice, setWeekendNightPrice] = useState("");
  const [minStay, setMinStay] = useState("");
  const [maxStay, setMaxStay] = useState("");

  const handleSeasonNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSeasonName(event.target.value);
  };

  const handleBasicNightPriceChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    if (!isNaN(Number(value))) {
      setBasicNightPrice(value);
    }
  };

  const handleWeekendNightPriceChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    if (!isNaN(Number(value))) {
      setWeekendNightPrice(value);
    }
  };

  const handleMinStayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (!isNaN(Number(value))) {
      setMinStay(value);
    }
  };

  const handleMaxStayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (!isNaN(Number(value))) {
      setMaxStay(value);
    }
  };

  return (
    <Stack sx={{ width: "100%" }} spacing={4}>
      <p className="B-14-22-700">{t("detailsPages.pricing.seasonalRate")}</p>
      <Stack sx={{ maxWidth: "560px" }} spacing={2}>
        <Stack sx={{ width: "100%" }} spacing={1}>
          <MainTextField
            title={t("detailsPages.pricing.seasonName")}
            placeholder="Give it name"
            value={seasonName}
            onChange={handleSeasonNameChange}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <Stack sx={{ width: "100%" }} spacing={1}>
            <MainTextField
              title={t("detailsPages.pricing.basicNightPrice")}
              type="number"
              placeholder="$265"
              value={basicNightPrice}
              onChange={handleBasicNightPriceChange}
            />
          </Stack>
          <Stack sx={{ width: "100%" }} spacing={1}>
            <MainTextField
              title={t("detailsPages.pricing.weekendNightPrice")}
              placeholder="$265"
              type="price"
              value={weekendNightPrice}
              onChange={handleWeekendNightPriceChange}
            />
          </Stack>
        </Stack>
        <Stack direction="row" spacing={2}>
          <Stack sx={{ width: "100%" }} spacing={1}>
            <p className="input-area-title B-14-24-500">
              {t("detailsPages.pricing.startDate")}
            </p>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <CustomDatePicker label={t("detailsPages.pricing.startDate")} />
            </LocalizationProvider>
          </Stack>
          <Stack sx={{ width: "100%" }} spacing={1}>
            <p className="input-area-title B-14-24-500">
              {t("detailsPages.pricing.endDate")}
            </p>
            <CustomDatePicker label={t("detailsPages.pricing.endDate")} />
          </Stack>
        </Stack>
        <Stack direction="row" spacing={2}>
          <Stack sx={{ width: "100%" }} spacing={1}>
            <MainTextField
              title={t("detailsPages.pricing.minStay")}
              placeholder="1"
              type="number"
              value={minStay}
              onChange={handleMinStayChange}
            />
          </Stack>
          <Stack sx={{ width: "100%" }} spacing={1}>
            <MainTextField
              title={t("detailsPages.pricing.maxStay")}
              placeholder="365"
              type="number"
              value={maxStay}
              onChange={handleMaxStayChange}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default SeasonalRate;
