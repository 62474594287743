export const chatBoxStyles = {
  aiOptionButtons: {
    display: "flex",
    padding: "var(--Spacing-space-6, 10px) 12px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    flex: "1 0 0",
    alignSelf: "stretch",
    borderRadius: "20px",
    border: "1px solid var(--Gray-500, #667085)",
    background: "var(--Schemes-On-Secondary, #FFF)",
    color: "#181C32",
    textAlign: "center",
    fontFamily: "var(--Label-Large-Font, Inter)",
    fontSize: "var(--Label-Large-Size, 14px)",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "var(--Label-Large-Line-Height, 20px)",
    letterSpacing: "var(--Label-Large-Tracking, 0.1px)",
  },
  aiOptionButtonsApprove: {
    fontWeight: 600,

    backgroundImage: "linear-gradient(0deg, #0065e0 0%, #0065e0 100%)",
    color: "var(--Schemes-On-Secondary, #fff)",
  },
  chatBox: {
    maxWidth: "71%",
    minWidth: "150px",
    padding: "12px",
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    borderRadius: "16px",
  },
  chatBoxProperty: {
    backgroundColor: "#F2F7FD",
    alignSelf: "flex-end",
  },
  chatBoxUser: {
    backgroundColor: "#F3F3F3",
    alignSelf: "flex-start",
    // borderRadius: "10px",
    // padding: "10px",
    // maxWidth: "250px",
    // display: "flex",
  },
  chatBoxInternalNote: {
    backgroundColor: "var(--Warning-50, #FFFAEB)",
    alignSelf: "flex-end",
  },
  chatBoxDivider: {},
  chatBoxSelectDivider: {
    borderRadius: "16px",
    border: "1px solid #F1F1F2",
    display: "flex",
    height: "32px",
    padding: "6px 12px",
    justifyContent: "center",
    alignItems: "center",
    gap: "6px",
    color: "var(--Gray-Gray-700, #5E6278)",
    cursor: "pointer",
    mx: 0,
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "20px",
  },
  applyButton: {
    width: "80px !important",
    borderRadius: "100px !important",
    background: "var(--Main-blue, #0161E0) !important",

    padding: "10px",

    color: "var(--Schemes-On-Secondary, #fff) !important",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "20px",
  },
  cancelButton: {
    width: "80px !important",
    padding: "10px !important",
    border: "1px solid var(--Gray-500, #667085) !important",

    color: "var(--Gray-800, #1D2939) !important",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "20px",
  },

  aiModal: {
    display: "flex",
    padding: "12px",
    alignItems: "flex-start",
    gap: "12px",
    alignSelf: "stretch",
    borderRadius: "16px",
    border: "1px solid #EBEBEB",
    background: "#FAFAFA",
    marginBottom: "12px",
  },
  aiImage: {
    display: "flex",
    width: "40px",
    height: "40px",
    padding: "20px 18px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    flexShrink: 0,
    borderRadius: "100px",
    background: "#C643FE",
  },
  aiModalButtons: {
    color: "var(--Gray-900, #101828)",
    fontWeight: 600,
    width: "300px",
    display: "flex",
    gap: "8px",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    flex: "1 0 0",
    alignSelf: "stretch",
    borderRadius: "20px",
    border: "1px solid #79747E",
    background: "var(--Schemes-On-Secondary, #FFF)",
  },
  aiModalActionButtons: {
    color: "var(--Gray-900, #101828)",
    fontWeight: 500,
    display: "flex",
    padding: "0 12px",
    gap: "8px",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    // flex: "1 0 0",
    alignSelf: "stretch",
    borderRadius: "20px",
    border: "1px solid #79747E",
    background: "var(--Schemes-On-Secondary, #FFF)",
  },
  aiCirclebuttons: {
    display: "flex",
    padding: "10px 8px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    flex: "1 0 0",
    alignSelf: "stretch",
    maxWidth: "40px",
    maxHeight: "40px",
    border: "1px solid #79747E",
    borderRadius: "100px",
  },
  popOver: {
    // MuiPaper-root

    "& .MuiPopover-paper, & .MuiPaper-root": {
      display: "flex",
      padding: "0px 12px",
      flexDirection: "column",
      alignItems: "flex-start",
      alignSelf: "stretch",
      borderradius: "12px",
      background: "linear-gradient(0deg, #FFF 0%, #FFF 100%), #F1F1F2",
      boxShadow:
        "0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)",
    },
  },
};

export const chatCardStyles = {
  select: {
    minWidth: "120px",
    padding: "6px 12px",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    height: "32px",
    borderRadius: "16px",
    color: "var(--Gray-Gray-700, #5E6278)",
    textAlign: "center",
    fontFamily: "Inter !important",
    fontSize: "14px !important",
    fontStyle: "normal !important",
    fontWeight: "500 !important",
    lineHeight: "20px !important",
  },
  menuItem: {
    color: "var(--Gray-Gray-700, #5E6278)",
    textAlign: "center",
    fontFamily: "Inter !important",
    fontSize: "14px !important",
    fontStyle: "normal !important",
    fontWeight: "500 !important",
    lineHeight: "20px !important",
  },
  icon: {
    color: "#181C32",
  },
  chip: {
    display: "flex",
    height: "32px",
    padding: "6px 12px",
    justifyContent: "center",
    alignItems: "center",
    gap: "6px",
  },
  button: {
    color: "var(--Gray-Gray-600, #7E8299)",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "20px",
    textDecorationLine: "underline",
    ":hover": {
      backgroundColor: "initial",
      textDecorationLine: "underline",
    },
  },
};
