import QuickSelectRadioButtons from "../../../../../../../../components/core-components/inputs/RadioButton/QuickSelectRadioButtons";

function EventsAllowed() {
  return (
    <>
      <QuickSelectRadioButtons />
    </>
  );
}

export default EventsAllowed;
