export const decodeJWT = async (token: string) => {
    try {
        const base64Url = token.split(".")[1]; // Get the payload part
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/"); // Handle URL-safe base64
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split("")
                .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
                .join("")
        );
        return JSON.parse(jsonPayload);
    } catch (error) {
        console.error("Error decoding JWT:", error);
        return null;
    }
}


export const debounce = (func: (...args: any[]) => void, delay: number) => {
    let timer: ReturnType<typeof setTimeout>;
    const debounced = (...args: any[]) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func(...args);
        }, delay);
    };

    debounced.cancel = () => {
        clearTimeout(timer);
    };

    return debounced;
}


export const h_formatUTCtoLocaleTime = (time: string) => {
    const utcDate = new Date(time); // Parse the UTC time from  ISO string
    const localDate = new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000); // Adjust to local time

    return localDate.toLocaleTimeString(navigator.language, {
        hour: "2-digit",
        minute: "2-digit",
    });
}
