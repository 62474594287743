import { useState } from "react";
import QuickSelectCounterButtons from "../../../../../../components/core-components/inputs/RadioButton/QuickSelectCounterButtons";
import { Stack, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import ListingDetailsEditingBox from "../../../../../../components/shared-components/ListingDetailsEditingBox";
import MainSelectSelection from "../../../../../../components/core-components/inputs/Select/MainSelectSelection";
import deleteIcon from "../../../../../../assets/images/icons/general-2.svg";

function Bedroom() {
  const [bedroomGroups, setBedroomGroups] = useState<
    { id: number; beds: number[] }[]
  >([{ id: 1, beds: [1] }]);
  const { t } = useTranslation();

  const handleMinusClick = () => {
    setBedroomGroups((prevGroups) => {
      if (prevGroups.length > 1) {
        const newGroups = [...prevGroups];
        newGroups.pop();
        return newGroups;
      }
      return prevGroups;
    });
  };

  const handlePlusClick = () => {
    setBedroomGroups((prevGroups) => {
      return [...prevGroups, { id: prevGroups.length + 1, beds: [1] }];
    });
  };

  const handleAddBed = (bedroomId: number) => {
    setBedroomGroups((prevGroups) =>
      prevGroups.map((group) =>
        group.id === bedroomId
          ? { ...group, beds: [...group.beds, group.beds.length + 1] }
          : group
      )
    );
  };

  const handleRemoveBed = (bedroomId: number, bedId: number) => {
    setBedroomGroups((prevGroups) =>
      prevGroups.map((group) =>
        group.id === bedroomId
          ? { ...group, beds: group.beds.filter((bed) => bed !== bedId) }
          : group
      )
    );
  };

  return (
    <>
      <Stack mt={5} spacing={2}>
        <Stack
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <p>{t("listingDetails.bedrooms")} </p>
          <QuickSelectCounterButtons
            itemValue={bedroomGroups.length}
            handlePlusClick={handlePlusClick}
            handleMinusClick={handleMinusClick}
          />
        </Stack>
        <ListingDetailsEditingBox
          footer={false}
          onClose={() => { }}
          border={false}
        >
          <p className="listing-details-room-title">
            {t("listingDetails.bedroomdetails")}
          </p>
          {bedroomGroups.map((group, index) => (
            <Stack
              key={group.id}
              sx={{
                paddingBottom: "1rem",
                gap: "1rem",
                ...(index !== bedroomGroups.length - 1 && {
                  borderBottom: "1px solid var(--stroke-colors, #EBEBEB)",
                }),
              }}
              mt={2}
              spacing={1}
            >
              <Stack
                flexDirection="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <p className="listing-details-room-count-title">
                  {t("listingDetails.bedroom")} {group.id}
                </p>
              </Stack>
              {group.beds.map((bed, bedIndex) => (
                <Stack
                  key={bed}
                  flexDirection="row"
                  alignItems="center"
                  sx={{ gap: "1rem", maxWidth: "464px" }}
                >
                  {" "}
                  <IconButton
                    disabled={group.beds.length === 1}
                    sx={{
                      display: "flex",
                      padding: "8px",
                      alignItems: "flex-start",
                      gap: "10px",
                      borderRadius: "50px",
                      border: "1px solid var(--Gray-Gray-500, #A1A5B7)",
                      background: "#FFF",
                    }}
                    onClick={() => handleRemoveBed(group.id, bed)}
                  >
                    <img
                      style={{ width: "20px", height: "20px" }}
                      src={deleteIcon}
                      alt="Delete Bed"
                    />
                  </IconButton>
                  <p
                    style={{
                      flex: 1,
                      fontSize: "14px",
                      fontWeight: 500,
                    }}
                  >
                    {t("listingDetails.bed")} {bedIndex + 1}
                  </p>
                  <Stack sx={{ flex: 3 }}>
                    <MainSelectSelection
                      value=""
                      onChange={() => { }}
                      label={t("listingDetails.bedtype")}
                      options={[
                        {
                          value: "King Bed",
                          label: `King Bed`,
                        },
                        {
                          value: "Twin Bed",
                          label: `Twin Bed`,
                        },
                        {
                          value: "Queen Bed",
                          label: `Queen Bed`,
                        },
                      ]}
                    />
                  </Stack>
                </Stack>
              ))}
              <button
                onClick={() => handleAddBed(group.id)}
                className="remove-listing-room-button"
              >
                {t("listingDetails.addabed")}
              </button>
            </Stack>
          ))}
        </ListingDetailsEditingBox>
      </Stack>
    </>
  );
}

export default Bedroom;
