import { Stack } from "@mui/material";
import React from "react";
import DetailsContentTitle from "../../../../../../../../components/shared-components/DetailsContentTitle";
import MainTextField from "../../../../../../../../components/core-components/inputs/TextField/MainTextField";
import HelperText from "../../../../../../../../components/shared-components/HelperText";
import { useTranslation } from "react-i18next";

function AdditionalRules() {
  const { t } = useTranslation();
  return (
    <Stack
      sx={{ width: "100%", maxWidth: "560px" }}
      component={"form"}
      spacing={2}
    >
      <DetailsContentTitle
        title={t("detailsPages.policies.additionalRules")}
        info={t("detailsPages.policies.highlightWhatMakesYourPlaceSpecial")}
      />
      <Stack gap={"8px"}>
        <MainTextField
          title={""}
          placeholder={t("detailsPages.policies.rulesAboutNosesEtc")}
          value=""
          onChange={() => {}}
          isMandatory={false}
          multiline={true}
          maxLength={4000}
        />
        <HelperText
          content={t("detailsPages.policies.4000charactersAllowed")}
        />
      </Stack>
    </Stack>
  );
}

export default AdditionalRules;
