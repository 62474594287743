import DetailsInputArea from "../../../../../../../../components/shared-components/DetailsInputArea";
import Address from "../forms/Address";
import CheckInAfterBooking from "../forms/CheckInAfterBooking";
import Directions from "../forms/Directions";
import HouseManual from "../forms/HouseManual";
import WifiDetails from "../forms/WifiDetails";
import { useTranslation } from "react-i18next";

function AfterBooking() {
  const { t } = useTranslation();
  const data = [
    {
      title: t("detailsPages.policies.address"),
      subtitle: t("detailsPages.pricing.notSet"),
      component: <Address />,
      isSelect: false,
    },
    {
      title: t("detailsPages.policies.directions"),
      subtitle: t("detailsPages.pricing.notSet"),
      component: <Directions />,
      isSelect: false,
    },
    {
      title: t("detailsPages.policies.wifiDetails"),
      subtitle: t("detailsPages.pricing.notSet"),
      component: <WifiDetails />,
      isSelect: false,
    },
    {
      title: t("detailsPages.policies.houseManual"),
      subtitle: t("detailsPages.pricing.notSet"),
      component: <HouseManual />,
      isSelect: false,
    },
    {
      title: t("detailsPages.policies.checkinMethodAfterBooking"),
      subtitle: t("detailsPages.pricing.notSet"),
      component: <CheckInAfterBooking />,
      isSelect: true,
      width: "595",
    },
  ];
  return (
    <>
      <DetailsInputArea
        title={t("detailsPages.policies.afterBooking")}
        data={data}
        onClick={() => { }}
        id={"after-booking"}
      />
    </>
  );
}

export default AfterBooking;
