import React, { useState, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import EditIcon from "../../../../../../../../assets/images/icons/edit-icon.svg";
import InfoIcon from "../../../../../../../../assets/images/icons/info-icon.svg";
import { useTranslation } from "react-i18next";
import MainSelectSelection from "../../../../../../../../components/core-components/inputs/Select/MainSelectSelection";

type PriceOfNightsProps = {
  title: string;
  subtitle?: string;
  showInfoBox?: boolean;
};

type Currency = "USD" | "EUR" | "TRY";

const currencySymbols: Record<Currency, string> = {
  USD: "$",
  EUR: "€",
  TRY: "₺",
};

function PriceOfNights({ title, subtitle, showInfoBox }: PriceOfNightsProps) {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const [currency, setCurrency] = useState<Currency>("USD");
  const [price, setPrice] = useState(77);
  const [isEditingPrice, setIsEditingPrice] = useState(false);

  const handleChangeCurrency = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setCurrency(event.target.value as Currency);
  };

  useEffect(() => {
    if (isEditingPrice) {
      inputRef.current?.focus();
    }
  }, [isEditingPrice]);

  const handleEditPrice = () => {
    setIsEditingPrice(!isEditingPrice);
  };

  const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrice(Number(event.target.value));
  };

  const handlePriceBlur = () => {
    setIsEditingPrice(false);
  };

  return (
    <Box className="price-of-night-box-center-box">
      <Box className="price-of-night-box-title-box">
        <p className="price-of-night-box-title-box-title H-24-24-600">
          {title}
        </p>
        <p className="price-of-night-box-title-box-subtitle">{subtitle}</p>
      </Box>
      <Box className="price-of-night-box">
        <Box className="price-of-night-box-price-area">
          {isEditingPrice ? (
            <input
              ref={inputRef}
              type="numeric"
              value={price}
              onChange={handlePriceChange}
              onBlur={handlePriceBlur}
              className="price-input"
            />
          ) : (
            <p className="price-of-night-box-price-area-price">
              {currencySymbols[currency] || "$"}
              {price}
            </p>
          )}
          <button
            className="price-of-night-box-price-area-edit-button"
            onClick={handleEditPrice}
          >
            <img
              src={EditIcon}
              alt="Edit Icon"
              style={{ width: "13.333px", height: "13.333px" }}
            />
          </button>
        </Box>
        <MainSelectSelection
          value={currency}
          onChange={handleChangeCurrency}
          label={"Select"}
          options={[
            {
              value: "USD",
              label: `USD`,
            },
            {
              value: "EUR",
              label: `EUR`,
            },
            {
              value: "TRY",
              label: `TRY`,
            },
          ]}
        />
        {showInfoBox && (
          <Box className="price-of-night-box-info-box">
            <p className="price-of-night-box-info-box-text B-18-30-500">
              {t("detailsPages.pricing.guestsPay")}
              <span className="price-of-night-box-info-box-text-price">
                62.51 {currency}
              </span>{" "}
              {t("detailsPages.pricing.perNight")}
            </p>
            <img src={InfoIcon} alt="Info Icon" />
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default PriceOfNights;
