export const sxFormRadioOption = {
  margin: "0",
  gap: "10px",
  padding: "16px 0px",
  "& .MuiButtonBase-root.MuiRadio-root": {
    padding: "0",

    "& svg": {
      width: "26px",
      height: "26px",
      background: "#E1E3EA",
      borderRadius: "50%",
      fill: "#E1E3EA",
    },
    "& svg:last-child": {
      display: "none",
    },
    "&.Mui-checked svg": {
      background: "transparent",
      borderRadius: "50%",
      fill: "#0065E0",
      stroke: "#0065E0",
      strokeWidth: "3px",
    },
  },
};

export const sxAccordion = {
  boxShadow: "none",
  margin: "0 !important",
  background: "transparent",
  "::before": {
    display: "none",
  },
};

export const sxAccordionSummary = {
  minHeight: "auto !important",
  padding:"0px",
  "& .MuiAccordionSummary-content": {
    margin: "0 !important",
    height:"100%"
  },
  "& .MuiAccordionSummary-content.Mui-expanded .arrow-down-accordion":{
    transform:"rotate(0deg)"
  },
  "& .MuiAccordionSummary-content .arrow-down-accordion":{
    transform:"rotate(-90deg)",
    transition:"0.3s ease all"
  }
};

export const sxTextOverflow = {
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
};

export const sxAccordionDetails={
  padding:"0px"
}
