import React, { useState, useEffect } from "react";
import { Autocomplete, Box, CircularProgress, TextField } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { fetchPropertyTags } from "../../store/slices/onboardSlice";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { useSelector } from "react-redux";


interface AutocompleteTagComponentProps {
  initialTags?: string[];
  onTagsChange: (selectedTags: string[]) => void;
  relation: string;
  freeSolo?: boolean;
}

const AutocompleteTagComponent: React.FC<AutocompleteTagComponentProps> = ({
  onTagsChange,
  initialTags = [],
  relation,
  freeSolo = true,
}) => {
  const [tags, setTags] = useState<string[]>(initialTags);
  const [inputTagValue, setInputTagValue] = useState<string>("");
  const [loadingTags, setLoadingTags] = useState<boolean>(false);
  const propertyTags = useSelector((state: RootState) => state.onboard.propertyTags)
  const dispatch: AppDispatch = useDispatch()

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchTags = async (searchText: string) => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (searchText.length > 0) {
          dispatch(fetchPropertyTags({ accessToken, searchText }))
          setLoadingTags(true);
        } else {
          dispatch(fetchPropertyTags({ accessToken }))
        }
      } catch (error) {
        console.error("Tags error:", error);
      } finally {
        setLoadingTags(false);
      }
    };
    fetchTags(inputTagValue);
  }, [inputTagValue, relation, getAccessTokenSilently]);

  return (
    <Box>
      <Autocomplete
        multiple
        limitTags={4}
        id="multiple-limit-tags"
        freeSolo={freeSolo}
        options={propertyTags}
        getOptionLabel={(option) => {
          if (typeof option === "string") return option;
          return option.tag;
        }}
        value={tags}
        onInputChange={(_, newInputValue) =>
          setInputTagValue(newInputValue)
        }
        onChange={(_, newValue) => {
          const stringValues = newValue.map((val) =>
            typeof val === "string" ? val : val.tag
          );
          setTags(stringValues);
          onTagsChange(stringValues);
        }}
        loading={loadingTags}
        size="small"
        renderInput={(params) => (
          <TextField
            {...params}
            label=""
            placeholder="Type to search tags"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loadingTags ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        sx={{
          width: "500px",
          height: "100%",
          maxHeight: "150px",
          overflowY: "auto",
        }}
      />
    </Box>
  );
};

export default AutocompleteTagComponent;
