import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../store";
const API_BASE_URL = process.env.REACT_APP_API_URL;

interface TeamMember {
  email: string;
  Id: number;
  userId: string;
  action: string;
  id: string;
  name: string;
  role: string;
  isActive: boolean;
}

interface TeamRole {
  id: string;
  name: string;
  description: string;
  providerCode: string;
}

interface TeamTags {
  id: string;
  name: string;
}

interface TeamMembersState {
  members: TeamMember[];
  roles: TeamRole[];
  tags: TeamTags[];
  loading: boolean;
  error: string | null;
}

const initialState: TeamMembersState = {
  members: [],
  roles: [],
  tags: [],
  loading: false,
  error: null,
};

// Async thunk to fetch team members from an API
export const fetchTeamMembers = createAsyncThunk(
  "teamMembers/fetchTeamMembers",
  async (
    {
      accessToken,
    }: {
      accessToken: string;
    },
    { rejectWithValue, getState }
  ) => {
    try {

      const state = getState() as RootState;
      const customerId = state.global.userDetails?.customerId

      const response = await axios.get(`${API_BASE_URL}/User/Get`, {
        params: {
          CustomerId: customerId,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const apiData = response.data.map((item: any, index: number) => ({
        Id: index + 1,
        userId: item.id,
        name: item.firstName + " " + item.lastName,
        email: item.email,
        tags: item.tags.length ? item.tags : ["No Tags"],
        role: item.roles.length ? item.roles[0].roleName : "Not assigned",
        listingResponsibleIds: item.properties.map(
          (property: any) => property.propertyId
        ),
        status: item.isActive ? "Active" : "Inactive",
        action: item.isActive ? "Undo" : "Invite",
      }));

      return apiData;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchTeamRoles = createAsyncThunk(
  "teamMembers/fetchTeamRoles",
  async (
    {
      accessToken,
    }: {
      accessToken: string;
    },
    { rejectWithValue, getState }
  ) => {
    try {

      const state = getState() as RootState;
      const customerId = state.global.userDetails?.customerId
      const response = await axios.get(`${API_BASE_URL}/Role/List`, {
        params: {
          CustomerId: customerId,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateRole = createAsyncThunk(
  "teamMembers/updateRole",
  async (
    {
      userId,
      roleId,
      accessToken,
    }: {
      userId: string;
      roleId: string;
      accessToken: string;
    },
    { rejectWithValue, getState }
  ) => {
    try {

      const state = getState() as RootState;
      const customerId = state.global.userDetails?.customerId
      await axios.post(
        `${API_BASE_URL}/Role/SetUserRoles`,
        {
          userId: userId,
          roleIds: [roleId],
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const response = await axios.get(`${API_BASE_URL}/User/Get`, {
        params: {
          CustomerId: customerId,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return {
        userId,
        roleName:
          response.data.find((user: any) => user.id === userId)?.roles[0]
            ?.roleName || "Not assigned",
      };
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchTeamTags = createAsyncThunk(
  "teamMembers/fetchTeamTags",
  async (
    {
      accessToken,
    }: {
      accessToken: string;
    },
    { rejectWithValue, getState }
  ) => {
    try {
      const state = getState() as RootState;
      const customerId = state.global.userDetails?.customerId

      const response = await axios.get(`${API_BASE_URL}Tag/Get?Relation=user`, {
        params: {
          CustomerId: customerId,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const teamMembersSlice = createSlice({
  name: "teamMembers",
  initialState,
  reducers: {
    addMember: (state, action: PayloadAction<TeamMember>) => {
      state.members.push(action.payload);
    },
    removeMember: (state, action: PayloadAction<string>) => {
      state.members = state.members.filter(
        (member) => member.id !== action.payload
      );
    },
    updateMember: (state, action: PayloadAction<TeamMember>) => {
      const index = state.members.findIndex(
        (member) => member.id === action.payload.id
      );
      if (index !== -1) {
        state.members[index] = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTeamMembers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchTeamMembers.fulfilled,
        (state, action: PayloadAction<TeamMember[]>) => {
          state.loading = false;
          state.members = action.payload;
        }
      )
      .addCase(
        fetchTeamMembers.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      );
    builder
      .addCase(fetchTeamRoles.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchTeamRoles.fulfilled,
        (state, action: PayloadAction<TeamRole[]>) => {
          state.loading = false;
          state.roles = action.payload;
        }
      )
      .addCase(fetchTeamRoles.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
    builder
      .addCase(fetchTeamTags.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchTeamTags.fulfilled,
        (state, action: PayloadAction<TeamTags[]>) => {
          state.loading = false;
          state.tags = action.payload;
        }
      )
      .addCase(fetchTeamTags.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
    builder
      .addCase(updateRole.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateRole.fulfilled, (state, action) => {
        state.loading = false;
        const memberIndex = state.members.findIndex(
          (member) => member.userId === action.payload.userId
        );
        if (memberIndex !== -1) {
          state.members[memberIndex].role = action.payload.roleName;
        }
      })
      .addCase(updateRole.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { addMember, removeMember, updateMember } =
  teamMembersSlice.actions;

export default teamMembersSlice.reducer;
