import { useState } from "react";
import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomSwitchForm from "../../../../../../../../../components/shared-components/CustomSwitchForm";

interface Props {
  title: string;
  description?: string;
}

function DaysSwitchArea(props: Props) {
  const { t } = useTranslation();
  const { title, description } = props;

  const days = [
    t("calendarpage.Sunday"),
    t("calendarpage.Monday"),
    t("calendarpage.Tuesday"),
    t("calendarpage.Wednesday"),
    t("calendarpage.Thursday"),
    t("calendarpage.Friday"),
    t("calendarpage.Saturday"),
  ];

  const [switchStates] = useState(days.map(() => false));

  return (
    <Stack
      spacing={2}
      mt={2}
      sx={{
        paddingBottom: "24px",
        borderBottom: "1px solid var(--stroke-colors, #EBEBEB)",
      }}
    >
      <Stack sx={{ width: "100%" }}>
        <p className="B-14" style={{ color: "#181C32" }}>
          {title}
        </p>
        <p className="B-12-16-400">{description}</p>
      </Stack>
      <Stack direction={"row"} spacing={3} sx={{ width: "100%" }}>
        {days.map((day, index) => (
          <Stack key={index} spacing={1} sx={{ width: "100%" }}>
            <p className="B-14-24-500" style={{ color: "#181C32" }}>
              {day}
            </p>
            <CustomSwitchForm
              name={"day" + index}
              checked={switchStates[index]}
            />
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
}

export default DaysSwitchArea;
