import React, { useState } from "react";
import Box from "@mui/material/Box";
import "../../assets/sass/components/_reservation-details-modal.scss";
import avatar from "../../assets/images/avatar2.jpeg";
import CloseModalButton from "../shared-components/CloseModalButton";
import { useTranslation } from "react-i18next";
import { useMediaQuery, useTheme } from "@mui/material";
import { Stack } from "@mui/system";

interface ReservationDetailsModalProps {
  onClose: () => void;
}

const ReservationModalBody = ({
  confirm,
  fullName,
  details,
}: {
  confirm: string;
  fullName: string;
  details: string[];
}) => {

  return (
    <Box className="reservation-modal-body">
      <Box className="reservation-modal-body-left">
        <Box className="reservation-confirm B-16-16-600">{confirm}</Box>
        <Box className="reservation-fullName B-20-20-600">{fullName}</Box>
        <Box className="reservation-details">
          {details.map((detail, index) => (
            <p key={index} className="reservation-detail B-16">
              {detail}
            </p>
          ))}
        </Box>
      </Box>
      <Box className="reservation-modal-body-right">
        <Box className="reservation-detail-modal-avatar">
          <img src={avatar} alt="avatar" />
        </Box>
      </Box>
    </Box>
  );
};

const SourcesDetail = ({ detail, date }: { detail: string; date: string }) => {

  return (
    <Box className="reservation-modal-sources-detail">
      <p className="reservation-modal-sources-detail-text B-18">{detail}</p>
      <p className="reservation-modal-sources-detail-date B-16">{date}</p>
    </Box>
  );
};

const BookingDetailsListItem = ({
  item1,
  item2,
  index,
  onShowAll,
  showAll,
}: {
  item1: string;
  item2: string;
  index: number;
  onShowAll: () => void;
  showAll: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <Box className="reservation-modal-booking-details-list">
      <Box className="reservation-modal-booking-details-list-items">
        <Box className="reservation-modal-booking-details-list-item-1 B-18">
          {item1}
        </Box>
        <Box className="reservation-modal-booking-details-list-item-2 B-16">
          {item2}
        </Box>
      </Box>
      {index === 0 && (
        <button
          onClick={onShowAll}
          style={{
            cursor: "pointer",
            border: "none",
            background: "none",
            display: "flex",
            padding: "9px 12px",
            alignItems: "center",
            gap: "5px",
            color: "#181C32",
            fontFamily: "Inter",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "16px" /* 114.286% */,
            textDecorationLine: "underline",
          }}
        >
          {showAll ? t("inboxpage.view") : t("inboxpage.view")}
        </button>
      )}
    </Box>
  );
};

const GuestPaidTableItem = ({
  item,
  price,
}: {
  item: string;
  price: string;
}) => {
  return (
    <Box className="reservation-modal-guest-paid-table-item B-18">
      {item}
      <p className="reservation-modal-guest-paid-table-price B-18">{price}</p>
    </Box>
  );
};

const TotalPrice = ({
  currency,
  total,
}: {
  currency: string;
  total: number;
}) => {
  return (
    <p className="reservation-modal-guest-paid-table-item B-18-18-600">
      {currency}
      <p className="reservation-modal-guest-paid-table-price B-18-18-600">
        {`€ ${new Intl.NumberFormat("en-US", {
          minimumFractionDigits: 2,
        }).format(total)}`}
      </p>
    </p>
  );
};

const ReservationDetailsModal: React.FC<ReservationDetailsModalProps> = ({
  onClose,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const [showAll, setShowAll] = useState(false);
  const toggleShowAll = () => {
    setShowAll((prevShowAll) => !prevShowAll);
  };

  const reservationBodyData = {
    confirm: "Confirmed",
    fullName: "Xian Zhou",
    details: [
      "Forest Haven: Villa With Pool",
      "Jul 29 – Aug 4 (6 nights)",
      "9 guests, 1 pet · € 3,234.00",
    ],
  };

  const sourcesDetails = [{ detail: "Airbnb", date: "Mon, Jul 29, 2024" }];

  const bookingDetails = [
    { item1: t("inboxpage.guests"), item2: "9 adults, 1 pet" },
    { item1: t("inboxpage.checkin"), item2: "Mon, Jul 29, 2024" },
    { item1: t("inboxpage.checkout"), item2: "Mon, Jul 29, 2024" },
    { item1: t("inboxpage.bookingdate"), item2: "Mon, Jul 29, 2024" },
    { item1: t("inboxpage.rateplan"), item2: "Standart rate" },
    { item1: t("inboxpage.confirmationcode"), item2: "HMFWNNCWTN" },
    { item1: t("inboxpage.cancelletionpolicy"), item2: "Firm" },
  ];

  const guestPaidTableItems = [
    { item: "€ 600.00 x 6 " + t("inboxpage.nights"), price: "€ 3,600.00" },
    { item: t("inboxpage.cleaningfee"), price: "€ 100.00" },
    { item: t("inboxpage.servicefee"), price: "€ 0.00" },
    { item: t("inboxpage.petfee"), price: "€ 200.00" },
  ];

  const totalPrice = guestPaidTableItems.reduce((total, item) => {
    const price = parseFloat(item.price.replace(/[^\d.-]/g, ""));
    return total + price;
  }, 0);

  return (
    <Stack
      spacing={isMobile ? 4 : 7}
      width={isMobile ? "100%" : "580px"}
      padding="56px 32px"
      alignItems="flex-start"
      className="reservation-details-modal"
    >
      <Box className="reservation-modal-header">
        <h1
          className={
            isMobile
              ? "reservation-modal-header-title H-24"
              : "reservation-modal-header-title H-30-30-600"
          }
        >
          {t("inboxpage.reservationdetails")}
        </h1>
        <CloseModalButton show={true} onClick={onClose} />
      </Box>
      <ReservationModalBody {...reservationBodyData} />
      <Box className="reservation-modal-sources">
        <p className="reservation-modal-sources-title H-20-20-600">
          {t("inboxpage.bookingsource")}
        </p>
        <Box className="reservation-modal-sources-details">
          {sourcesDetails.map((detail, index) => (
            <SourcesDetail key={index} {...detail} />
          ))}
        </Box>
      </Box>
      <Box className="reservation-modal-buttons">
        <button className="reservation-modal-top-button">
          <p className="reservation-modal-button-text B-16-16-600">
            {t("reservationpage.sendorrequestmoney")}
          </p>
        </button>
        <Box className="reservation-modal-bottom-buttons">
          <button className="reservation-modal-bottom-button">
            <p className="reservation-modal-button-text B-16-16-600">
              {t("reservationpage.message")}
            </p>
          </button>
          <button className="reservation-modal-bottom-button">
            <p className="reservation-modal-button-text B-16-16-600">
              {t("reservationpage.call")}
            </p>
          </button>
        </Box>
      </Box>
      <Box className="reservation-modal-booking-details">
        <p className="reservation-modal-booking-details-title H-20-20-600">
          {t("inboxpage.bookingdetails")}
        </p>
        {bookingDetails.map(
          (detail, index) =>
            (showAll || index === 0) && (
              <BookingDetailsListItem
                key={index}
                {...detail}
                index={index}
                onShowAll={toggleShowAll}
                showAll={showAll}
              />
            )
        )}
      </Box>
      <Box className="reservation-modal-guest-paid">
        <p className="reservation-modal-guest-paid-title H-20-20-600">
          {t("inboxpage.guestpaid")}
        </p>
        <Box className="reservation-modal-guest-paid-table">
          {guestPaidTableItems.map((item, index) => (
            <GuestPaidTableItem key={index} {...item} />
          ))}
          <TotalPrice
            currency={`${t("inboxpage.total")} (EUR)`}
            total={totalPrice}
          />
        </Box>
      </Box>
    </Stack>
  );
};

export default ReservationDetailsModal;
