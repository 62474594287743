import React from "react";
import Box from "@mui/material/Box";
import ArrowsRight from "../../../assets/images/icons/arrows-right.svg";
import { useNavigate } from "react-router-dom";
import Error from "../../../assets/images/error.svg";
import { useMediaQuery, useTheme } from "@mui/material";
import { Stack } from "@mui/system";
import { useTranslation } from "react-i18next";

const NotFoundPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      className={!isMobile ? "container" : ""}
      sx={{
        width: { xs: "100%", sm: "auto" },
        margin: "auto",
      }}
      padding={isMobile ? 2 : 0}
      pb={isMobile ? 10 : 0}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        spacing={isMobile ? 4 : 7}
        my={isMobile ? 4 : 5}
        width={isMobile ? "100%" : "auto"}
      >
        <Stack alignItems="center" spacing={2}>
          <h1
            style={{ color: "var(--Gray-Gray-900, #181c32)" }}
            className="H-50-54-700"
          >
            {t("errorPages.error404")}
          </h1>
          <p
            style={{
              color: "var(--Gray-Gray-900, #181c32)",
              textAlign: "center",
              maxWidth: "412px",
            }}
            className="B-14-24-500"
          >
            {t("errorPages.somethingWentWrong")}
          </p>
        </Stack>
        <img
          src={Error}
          alt="error"
          style={{ width: "100%", maxWidth: "700px" }}
        ></img>
        <button
          className="buttonLg"
          aria-label="add listing"
          onClick={() => navigate("/")}
        >
          <Box className="button-icon">
            <img src={ArrowsRight} alt="arrows" />
          </Box>
          <p className="buttonText B-16-16-600">{t("errorPages.goHomePage")}</p>
        </button>
      </Stack>
    </Box>
  );
};

export default NotFoundPage;
