import { Popover, Stack } from "@mui/material";
import CloseModalButton from "../../../../../../../../../components/shared-components/CloseModalButton";
import DaysArea from "../days-area";
import ListingArea from "../listings-area";
import DateRange from "../date-range-area";
import DaysSwitchArea from "../days-switch-area";
import CustomButton from "../../../../../../../../../components/shared-components/CustomButton";
import { useTranslation } from "react-i18next";

interface Props {
  open: boolean;
  anchorEl: HTMLButtonElement;
  handleClose: () => void;
  modalTitle: string;
  buttonText: string;
}
function BulkClosedDepartureModal(props: Props) {
  const { t } = useTranslation();
  const { open, anchorEl, handleClose, modalTitle, buttonText } = props;
  return (
    <>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          ".MuiPaper-root": {
            gap: "24px",
            borderRadius: "16px",
            background: "#FFF",
            padding: "24px",
            boxShadow: "0px 8px 14px 0px rgba(15, 42, 81, 0.04)",
            width: "728px",
          },
          background: "rgba(53, 48, 48, 0.21)",
        }}
      >
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <p className="B-16-16-600">{modalTitle}</p>
          <CloseModalButton show={true} onClick={handleClose} />
        </Stack>
        <DateRange />
        <DaysSwitchArea
          title={t("calendarpage.closedForDeparture")}
          description="Select the days you want to close for departure"
        />
        <DaysArea
          title={t("calendarpage.rate")}
          description={t("calendarpage.enterRatesForOneOrMoreDays")}
        />
        <ListingArea />
        <Stack
          direction="row"
          justifyContent={"flex-end"}
          alignItems={"center"}
        >
          <Stack>
            <CustomButton
              backgroundColor="#0065E0"
              color="#FFFFFF"
              onClick={handleClose}
              buttonText={buttonText}
            />
          </Stack>
        </Stack>
      </Popover>
    </>
  );
}

export default BulkClosedDepartureModal;
