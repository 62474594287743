import React, { ReactNode } from "react";
import { Box } from "@mui/material";

interface FixedHeaderDataGridProps {
  children: ReactNode;
  isMobile?: boolean;
}

const FixedHeaderDataGrid: React.FC<FixedHeaderDataGridProps> = ({
  children,
  isMobile = false,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: isMobile ? "calc(100vh - 260px)" : "calc(100vh - 300px)",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        "& .MuiDataGrid-root": {
          display: "flex",
          flexDirection: "column",
          border: "none",
          height: "100%",
        },
        "& .MuiDataGrid-columnHeaders": {
          position: "sticky",
          top: 0,
          zIndex: 100,
          backgroundColor: "#fff",
          boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
          borderBottom: "1px solid #EBEBEB",
        },
        "& .MuiDataGrid-virtualScroller": {
          overflow: "auto !important",
          flex: "1 1 auto",
          height: "auto !important",
          minHeight: "200px",
          marginTop: "0 !important",
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#f1f1f1",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#888",
            borderRadius: "4px",
          },
        },
        "& .MuiDataGrid-main": {
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          overflow: "hidden",
        },
        "& .MuiDataGrid-row": {
          minHeight: "56px !important",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "1px solid #EBEBEB",
          padding: "8px 16px",
        },
        "& .MuiDataGrid-columnHeader": {
          height: "56px !important",
          minHeight: "56px !important",
          maxHeight: "56px !important",
          lineHeight: "56px !important",
          backgroundColor: "#fff",
        },
        "& .MuiDataGrid-footerContainer": {
          display: "none",
        },
      }}
    >
      {children}
    </Box>
  );
};

export default FixedHeaderDataGrid;
