import DetailsInputArea from "../../../../../../../../components/shared-components/DetailsInputArea";
import CleaningAndTaxes from "../modals/CleaningAndTaxes";
import OptionalExtras from "../modals/OptionalExtras";
import PriceOfNights from "../modals/PriceOfNights";
import { useTranslation } from "react-i18next";

function Extras() {
  const { t } = useTranslation();
  const data = [
    {
      title: t("detailsPages.pricing.cleaning"),
      subtitle: t("detailsPages.pricing.notSet"),
      component: (
        <CleaningAndTaxes
          title={t("detailsPages.pricing.cleaning")}
          inputTitle={t("detailsPages.pricing.cleaningBasis")}
          showCleaningBasis={true}
          showPrice={true}
        />
      ),
      isSelect: false,
    },
    {
      title: t("detailsPages.pricing.securityDeposit"),
      subtitle: t("detailsPages.pricing.notSet"),
      component: (
        <PriceOfNights title={t("detailsPages.pricing.securityDeposit")} />
      ),
      isSelect: false,
    },
    {
      title: t("detailsPages.pricing.taxes"),
      subtitle: t("detailsPages.pricing.notSet"),
      component: (
        <CleaningAndTaxes
          title={t("detailsPages.pricing.taxes")}
          inputTitle={t("detailsPages.pricing.taxTypeName")}
          showTaxType={true}
          showFeeBasis={true}
          showPrice={true}
        />
      ),
      isSelect: false,
    },
    {
      title: t("detailsPages.pricing.optionalExtrasAndServices"),
      subtitle: t("detailsPages.pricing.notSet"),
      component: <OptionalExtras />,
      isSelect: false,
    },
  ];

  return (
    <>
      <DetailsInputArea
        title={t("detailsPages.pricing.extras")}
        data={data}
        onClick={() => { }}
        id={"extras"}
      />
    </>
  );
}

export default Extras;
