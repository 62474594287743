import { FormControlLabel, FormGroup, Popover, Stack } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import CloseModalButton from "../../../../../../../components/shared-components/CloseModalButton";
import { keys } from "../../../../data";
import CustomSecondCheckbox from "../../../../../../../components/core-components/inputs/Checkbox/CustomSecondCheckbox";

interface Props {
  open: boolean;
  anchorEl: HTMLButtonElement;
  handleClose: () => void;
  hiddenRows: string[];
  setHiddenRows: Dispatch<SetStateAction<string[]>>;
}

function HiddenRowsModal(props: Props) {
  const { open, anchorEl, handleClose, hiddenRows, setHiddenRows } = props;
  return (
    <>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          " .MuiPaper-root": {
            borderRadius: "12px",
            background: "#FFF",
            padding: " 16px 24px",
            boxShadow: "0px 8px 14px 0px rgba(15, 42, 81, 0.04)",
            width: "342px",
          },
          background: "rgba(53, 48, 48, 0.21)",
        }}
      >
        <Stack
          direction="row"
          justifyContent={"flex-end"}
          alignItems={"center"}
        >
          <CloseModalButton show={true} onClick={handleClose} />
        </Stack>
        <FormGroup>
          {React.Children.toArray(
            keys.map((key) => (
              <FormControlLabel
                control={
                  <CustomSecondCheckbox
                    id={key}
                    checked={hiddenRows.includes(key)}
                    onChange={() => {
                      setHiddenRows((prev) =>
                        prev.includes(key)
                          ? prev.filter((item) => item !== key)
                          : [...prev, key]
                      );
                    }}
                    label={key}
                    inputName={key}
                  />
                }
                label={key}
                sx={{
                  "& .MuiFormControlLabel-label": {
                    ml: 1,
                  },
                  mb: 2,
                }}
              />
            ))
          )}
        </FormGroup>
      </Popover>
    </>
  );
}

export default HiddenRowsModal;
