import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import PlusIcon from "../../../../assets/images/icons/plus-icon.svg";
import { GridColDef } from "@mui/x-data-grid";
import {
  Button,
  Chip,
  Stack,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ArrowDown from "../../../../assets/images/icons/arrow-down-messages.svg";
import CustomModal from "../../../../components/shared-components/CustomModal";
import HeaderTitle from "../components/HeaderTitle";
import MobileGridData from "../../../../components/shared-components/MobileGridData";
import CustomButton from "../../../../components/shared-components/CustomButton";
import SyncIcon from "../../../../assets/images/icons/sync-icon.svg";
import GridData from "../../../../components/shared-components/GridData";
import SelectRoleModal from "../../onboardingTwo/step3/SelectRoleModal";
import ListingModal from "../../onboardingTwo/step2/ListingModal";
import AddTeamMemberModal from "../../onboardingTwo/step3/AddTeamMemberModal";
import MobileHeader from "../../../../components/shared-components/MobileHeader";
import { useNavigate } from "react-router-dom";
import CustomModalMobile from "../../../../components/shared-components/CustomModalMobile";
import MobileDetailsModal from "../components/MobileDetailsModal";
import { useAuth0 } from "@auth0/auth0-react";
import { AppDispatch, RootState } from "../../../../store/store";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  fetchTeamMembers,
  fetchTeamRoles,
  fetchTeamTags,
  updateRole,
} from "../../../../store/slices/teamMembersSlice";

const AccountUsers = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isMobileDetailsModalOpen, setIsMobileDetailsModalOpen] =
    useState(false);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate("/menu");
  };
  const [isFormValid, setIsFormValid] = useState(false);

  const handleSaveTeamMember = (isFormValid: boolean) => {
    setIsFormValid(isFormValid);
  };
  const { getAccessTokenSilently } = useAuth0();
  const dispatch: AppDispatch = useDispatch();
  const { members, roles } = useSelector(
    (state: RootState) => state.teamMembers
  );

  useEffect(() => {
    const fetchData = async () => {
      const accessToken = await getAccessTokenSilently();
      dispatch(fetchTeamMembers({ accessToken }));
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      const accessToken = await getAccessTokenSilently();
      dispatch(fetchTeamRoles({ accessToken }));
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      const accessToken = await getAccessTokenSilently();
      dispatch(fetchTeamTags({ accessToken }));
    };
    fetchData();
  }, [dispatch]);

  const getColumns = (): GridColDef<any[number]>[] => [
    {
      field: "name",
      headerName: "Name",
      disableColumnMenu: true,
      flex: 2,
      align: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <Stack spacing={1} alignItems={"flex-start"} pt={3}>
            <Tooltip title={params.row.name} placement="top">
              <p
                className="TextSmMedium"
                style={{
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                  overflow: "hidden",
                  display: "-webkit-box",
                  WebkitLineClamp: 1,
                  WebkitBoxOrient: "vertical",
                  maxWidth: "200px",
                  wordBreak: "break-all",
                  textAlign: "left",
                  color: "#3F4254",
                }}
              >
                {params.row.name}
              </p>
            </Tooltip>
            <Tooltip title={params.row.email} placement="top">
              <p
                className="TextSmMedium"
                style={{
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                  overflow: "hidden",
                  display: "-webkit-box",
                  WebkitLineClamp: 1,
                  WebkitBoxOrient: "vertical",
                  maxWidth: "200px",
                  wordBreak: "break-all",
                  textAlign: "left",
                  color: "#7E8299",
                }}
              >
                {params.row.email}
              </p>
            </Tooltip>
          </Stack>
        );
      },
    },
    {
      field: "tags",
      headerName: "Tags",
      disableColumnMenu: true,
      flex: 3,
      align: "left",
      sortable: false,
      renderCell: (params) => (
        <Stack
          sx={{
            display: "flex",
            alignItems: !params.row.tags[0] ? "flex-start" : "center",
            width: "100%",
            height: "100%",
            padding: "12px var(--Spacing-space-10, 24px) 12px 12px",
            alignContent: "center",
            gap: "4px",
            alignSelf: "stretch",
            flexWrap: "wrap",
          }}
          direction={"row"}
          spacing={"4px"}
        >
          {params.row.tags.map((tag: string) => (
            <Chip
              label={tag}
              className="tag"
              style={{
                color: "#FFFFFF",
                backgroundColor: "#3E97FF",
              }}
            />
          ))}
        </Stack>
      ),
    },
    {
      field: "role",
      headerName: "Role",
      disableColumnMenu: true,
      flex: 1.5,
      align: "center",
      sortable: false,
      renderCell: (params) => (
        <Stack spacing={1} alignItems={"flex-start"} pt={3.5}>
          <Button
            onClick={() => handleOpenModal(params.row.Id)}
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: "20px",
              padding: "7px 10px",
              borderRadius: "6px",
              border: "1px solid #E1E3EA",
              color: "var(--Gray-500, #667085)",
              minWidth: "100px",
              width: "100%",
              height: "38px",
              justifyContent: "space-between",
            }}
            endIcon={<img src={ArrowDown} alt="arrow-down" />}
          >
            {members.find((row) => row.Id === params.row.Id)?.role ||
              "Select role"}
          </Button>
        </Stack>
      ),
    },
    {
      field: "listingResponsibleIds",
      headerName: "Listing Responsible",
      disableColumnMenu: true,
      flex: 1.3,
      align: "center",
      sortable: false,
      renderCell: (params) => (
        <Stack alignItems={"flex-start"} pt={4}>
          <p className="listingSubtitle" style={{ color: "#3F4254" }}>
            {params.row.listingResponsibleIds.length} Listings
          </p>
          <p
            onClick={handleOpenListingModal}
            className="B-12-12-700"
            style={{ color: "#3E97FF", cursor: "pointer" }}
          >
            Add/ Remove
          </p>
        </Stack>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      disableColumnMenu: true,
      flex: 1.2,
      align: "center",
      sortable: false,
      renderCell: (params) => (
        <Stack spacing={1} alignItems={"flex-start"} pt={5}>
          <Chip
            label={params.row.status}
            className="chip"
            style={{
              backgroundColor:
                params.row.status === "Invited" ? "#E8FFF3" : "#FFF5F8",
              color: params.row.status === "Invited" ? "#47BE7D" : "#F1416C",
            }}
          />
        </Stack>
      ),
    },
    /**    {
      field: "action",
      headerName: "Action",
      disableColumnMenu: true,
      align: "left",
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Stack
          direction={"row"}
          spacing={1}
          alignItems={params.row.tags.length > 1 ? "center" : "flex-start"}
          pt={4}
        >
          <CustomButton
            backgroundColor="transparent"
            color="#3F4254"
            border="1px solid #A1A5B7"
            onClick={() => handleButtonClick(params.row.Id)} 
            buttonText={params.row.action} 
            marginTop={0}
            fontWeight={400}
            fontSize="12px"
          />
        </Stack>
      ),
    }, */
  ];
  const mobileColumns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      disableColumnMenu: true,
      flex: 2,
      align: "center",
      sortable: false,
      renderCell: (params) => (
        <Stack spacing={1} alignItems={"flex-start"}>
          <p
            className="TextSmMedium"
            style={{
              wordWrap: "break-word",
              whiteSpace: "normal",
              overflow: "hidden",
              display: "-webkit-box",
              WebkitLineClamp: 1,
              WebkitBoxOrient: "vertical",
              maxWidth: "200px",
              wordBreak: "break-all",
              textAlign: "left",
              color: "#3F4254",
            }}
          >
            {params.row.name}
          </p>
          <p
            className="TextSmMedium"
            style={{
              wordWrap: "break-word",
              whiteSpace: "normal",
              overflow: "hidden",
              display: "-webkit-box",
              WebkitLineClamp: 1,
              WebkitBoxOrient: "vertical",
              maxWidth: "200px",
              wordBreak: "break-all",
              textAlign: "left",
              color: "#7E8299",
            }}
          >
            {params.row.email}
          </p>
        </Stack>
      ),
    },
    {
      field: "details",
      headerName: "",
      disableColumnMenu: true,
      align: "left",
      hideSortIcons: true,
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <Box
          height={"100%"}
          display={"flex"}
          alignItems="center"
          justifyContent="center"
        >
          <button
            style={{
              fontFamily: "Inter",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "auto",
            }}
            className="edit-button B-12-12-600"
            onClick={() => handleDetailsClick(params.row)}
          >
            Details
          </button>
        </Box>
      ),
    },
  ];
  //test
  const dummyListings = [
    { id: 1, name: "Didem Apartment Istanbul 1" },
    { id: 2, name: "Didem Apartment Istanbul 2" },
    { id: 3, name: "Didem Apartment Istanbul 3" },
    { id: 4, name: "Didem Apartment Istanbul 4" },
    { id: 5, name: "Didem Apartment Istanbul 5" },
    { id: 6, name: "Didem Apartment Istanbul 6" },
    { id: 7, name: "Didem Apartment Istanbul 7" },
    { id: 8, name: "Didem Apartment Istanbul 8" },
    { id: 9, name: "Didem Apartment Istanbul 9" },
    { id: 10, name: "Didem Apartment Istanbul 10" },
  ];

  const displayedOptions = [
    "Owner",
    "Admin",
    "Guest Relations",
    "Operations",
    "Accountant",
  ];

  const [openModal, setOpenModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState<string | null>(null);
  const [selectedRowId, setSelectedRowId] = useState<number | null>(null);
  const [selectedPropertyId, setSelectedPropertyId] = useState("");
  const [openListingModal, setOpenListingModal] = useState(false);
  const [selectedListings, setSelectedListings] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [search, setSearch] = useState<string>(""); // State for search input
  const handleOpenListingModal = () => setOpenListingModal(true);
  const handleCloseListingModal = () => setOpenListingModal(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [isMobileAddTeamMemberModalOpen, setIsMobileAddTeamMemberModalOpen] =
    React.useState(false);

  const handleOpenModal = (rowId: number) => {
    const currentRole = members?.find((row) => row.Id === rowId)?.role || null;
    setSelectedRole(currentRole);
    setSelectedRowId(rowId);
    setOpenModal(true);
  };

  const handleSaveModal = async () => {
    if (selectedRowId !== null && selectedRole !== null) {
      const selectedUser = members?.find((row) => row.Id === selectedRowId);
      const selectedRoleObj = roles.find((role) => role.name === selectedRole);

      if (selectedUser && selectedRoleObj) {
        try {
          const accessToken = await getAccessTokenSilently();

          await dispatch(
            updateRole({
              userId: selectedUser.userId,
              roleId: selectedRoleObj.id,
              accessToken,
            })
          ).unwrap();

          setOpenModal(false);
          setSelectedRole(null);
          setSelectedRowId(null);
        } catch (error) {
          console.error("Rol atama hatası:", error);
        }
      }
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };
  const updateProperty = (_: string, __: number[]) => {
    // setAddedProperties((prevProperties) =>
    //   prevProperties.map((property) =>
    //     property.id === id
    //       ? { ...property, listingIds: updatedListings }
    //       : property
    //   )
    // );
    setSelectedPropertyId("");
    setSelectedListings([]);
  };

  const handleAddProperty = () => {
    if (selectedListings.length === 0) {
      // Remove the property if no listings are selected
      // setAddedProperties((prevProperties) =>
      //   prevProperties.filter((property) => property.id !== selectedPropertyId)
      // );
    } else {
      if (selectedPropertyId) {
        updateProperty(selectedPropertyId, selectedListings);
      } else {
        // const newProperty = {
        //   id: Date.now(),
        //   listingIds: selectedListings,
        // };
        // setAddedProperties((prevProperties) => [
        //   ...prevProperties,
        //   newProperty,
        // ]);
      }
    }
    setSelectedListings([]);
    setOpenListingModal(false);
    setSelectedPropertyId("");
  };

  const handleToggle = (id: number) => {
    setSelectedListings((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedListings([]);
    } else {
      const allIds = dummyListings.map((listing) => listing.id);
      setSelectedListings(allIds);
    }
    setSelectAll(!selectAll);
  };

  const handleRemove = (id: number) => {
    setSelectedListings((prev) => prev.filter((item) => item !== id));
  };

  const filteredListings = dummyListings.filter((listing) =>
    listing.name.toLowerCase().includes(search.toLowerCase())
  );

  // const handleButtonClick = (rowId: number) => {
  //   /**setRowsData((prevRows) =>
  //     prevRows.map((row) => {
  //       if (row.Id === rowId) {
  //         // Eğer status "Invited" ise "Not invited" yap ve action'ı "Invite" yap, aksi durumda tersi olsun
  //         const newStatus =
  //           row.status === "Invited" ? "Not invited" : "Invited";
  //         const newAction = row.action === "Undo" ? "Invite" : "Undo";
  //         return { ...row, status: newStatus, action: newAction };
  //       }
  //       return row;
  //     })
  //   ); */
  // };

  const handleAddModal = () => {
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
  };

  const handleSaveAddModal = () => {
    setOpenAddModal(false);
  };

  const updateUserRole = (_: number, __: string | null) => {
    /**setRowsData((prevRows) =>
      prevRows.map((row) =>
        row.Id === userId ? { ...row, role: newRole || "" } : row
      )
    ); */
  };

  const handleDetailsClick = (row: any) => {
    setSelectedUser(row);
    setSelectedRole(row.role);
    setIsMobileDetailsModalOpen(true);
  };

  const handleStatusChange = () => {
    if (selectedUser) {
      /**setRowsData((prevRows) =>
        prevRows.map((row) => {
          if (row.Id === selectedUser.Id) {
            const newStatus =
              row.status === "Invited" ? "Not invited" : "Invited";
            const newAction = row.status === "Invited" ? "Invite" : "Undo";
            return { ...row, status: newStatus, action: newAction };
          }
          return row;
        })
      ); */
      setIsMobileDetailsModalOpen(false);
    }
  };

  return (
    <Stack
      width={"100%"}
      className={!isMobile ? "acc-users-box" : "acc-users-box-mobile"}
      pb={isMobile ? 20 : 0}
      height={isMobile ? "100%" : "calc(100vh - 80px)"}
      gap={isMobile ? 4 : 3}
    >
      <Box
        className="acc-users-box-header"
        sx={{ flexDirection: isMobile ? "column" : "row" }}
      >
        {isMobile && (
          <MobileHeader
            title="User Management"
            description={`${members?.length} Members`}
            handleBackClick={handleBackClick}
          />
        )}

        {!isMobile && (
          <Stack
            direction={"row"}
            spacing={1}
            alignItems="flex-start"
            width={"100%"}
          >
            <HeaderTitle
              title="User Management"
              subtitle={`${members?.length} Members`}
            />
          </Stack>
        )}
        <Stack
          spacing={2}
          direction="row"
          width={"100%"}
          justifyContent={isMobile ? "space-between" : "flex-end"}
          pt={isMobile ? 2 : 0}
        >
          <CustomButton
            icon={SyncIcon}
            backgroundColor="#0065E0"
            color="#FFFFFF"
            onClick={() => { }}
            buttonText="Sync new member"
            marginTop={0}
            buttonWidth={isMobile ? "100%" : "auto"}
          />
          <CustomButton
            icon={PlusIcon}
            backgroundColor="#0065E0"
            color="#FFFFFF"
            onClick={
              isMobile
                ? () => setIsMobileAddTeamMemberModalOpen(true)
                : handleAddModal
            }
            buttonText="Add"
            marginTop={0}
            buttonWidth={isMobile ? "100%" : "auto"}
          />
        </Stack>
      </Box>

      {isMobile ? (
        <Box className="acc-users-box-content">
          <MobileGridData
            checkboxSelection={true}
            rows={members}
            columns={mobileColumns}
            setPage={() => { }}
            total={members.length}
            page={1}
            rowHeight={80}
          />
        </Box>
      ) : (
        <Box className="acc-users-box-content">
          <GridData
            checkboxSelection={true}
            rows={members}
            columns={getColumns()}
            hideFooter={true}
          />
        </Box>
      )}
      <CustomModalMobile
        open={isMobileDetailsModalOpen}
        handleClose={() => setIsMobileDetailsModalOpen(false)}
        title="User Details"
        showFooter={false}
        handleSave={() => {
          if (selectedUser && selectedRole) {
            updateUserRole(selectedUser.Id, selectedRole);
          }
          setIsMobileDetailsModalOpen(false);
        }}
      >
        {selectedUser && (
          <MobileDetailsModal
            name={selectedUser.name}
            email={selectedUser.email}
            status={selectedUser.status}
            role={selectedUser.role || "Select role"}
            displayedOptions={displayedOptions}
            selectedRole={selectedRole}
            setSelectedRole={setSelectedRole}
            handleSave={() => {
              if (selectedUser && selectedRole) {
                updateUserRole(selectedUser.Id, selectedRole);
              }
            }}
            tags={selectedUser.tags}
            listingResponsibleIds={selectedUser.listingResponsibleIds || []}
            handleStatusChange={handleStatusChange}
            handleAddProperty={handleAddProperty}
            search={search}
            handleSearch={handleSearch}
            selectAll={selectAll}
            handleSelectAll={handleSelectAll}
            filteredListings={filteredListings}
            handleToggle={handleToggle}
            selectedListings={selectedListings}
            handleRemove={handleRemove}
            dummyListings={dummyListings}
          />
        )}
      </CustomModalMobile>
      <CustomModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        handleSave={handleSaveModal}
        title="Select role"
      >
        <SelectRoleModal
          displayedOptions={displayedOptions}
          selectedRole={selectedRole}
          setSelectedRole={setSelectedRole}
          roles={roles}
        />
      </CustomModal>
      <CustomModal
        open={openListingModal}
        handleClose={handleCloseListingModal}
        handleSave={() => { }}
        title="Listing Responsibility"
        showFooter={false}
      >
        <ListingModal
          handleAddProperty={handleAddProperty}
          selectedListings={selectedListings}
        />
      </CustomModal>
      <CustomModal
        open={openAddModal}
        handleClose={handleCloseAddModal}
        handleSave={handleSaveAddModal}
        title="Invite team member"
        description="Invite team member so they can perform actions regarding these listings"
        blueButtonText="Invite"
        disabled={!isFormValid}
        maxHeight={"calc(100vh - 300px)"}
      >
        <AddTeamMemberModal
          isFormValid={isFormValid}
          onSave={handleSaveTeamMember}
          handleSave={() => { }}
        />
      </CustomModal>
      <CustomModalMobile
        open={isMobileAddTeamMemberModalOpen}
        handleClose={() => setIsMobileAddTeamMemberModalOpen(false)}
        title="Invite team member"
        description="Invite team member so they can perform actions regarding these listings"
        showFooter={true}
        handleSave={() => {
          setIsMobileAddTeamMemberModalOpen(false);
        }}
        disabled={!isFormValid}
      >
        <AddTeamMemberModal
          isFormValid={isFormValid}
          onSave={handleSaveTeamMember}
          handleSave={() => { }}
        />
      </CustomModalMobile>
    </Stack>
  );
};

export default AccountUsers;
