import React from "react";
import { FormControl, MenuItem, Select } from "@mui/material";
import ArrowDown from "../../../../assets/images/icons/down.svg";
import { inputSectionStyles, } from "../../../../views/private/listing/DatagridStyles";

export interface Option {
  value: string | number;
  label: string;
}

const CustomSelect: React.FC<{
  value: string | number;
  onChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  label: string;
  pagetype?: string;
  options: Option[];
}> = ({ value, onChange, label, options, pagetype }) => {
  let selectedStyle;
  switch (pagetype) {
    case "home":
      selectedStyle = inputSectionStyles.homePageSelect;
      break;
    case "listing":
      selectedStyle = inputSectionStyles.select;
      break;
    case "dateSelect":
      selectedStyle = inputSectionStyles.dateSelect;
      break;
    case "chatbot":
      selectedStyle = inputSectionStyles.chatBot;
      break;
    case "selectWithTextFieldHeight":
      selectedStyle = inputSectionStyles.selectWithTextFieldHeight;
      break;
    case "selectWithParagraphHeight":
      selectedStyle = inputSectionStyles.selectWithParagraphHeight;
      break;
    default:
      selectedStyle = inputSectionStyles.homePageSelect;
      break;
  }

  return (
    <FormControl>
      <Select
        value={value}
        onChange={(event) =>
          onChange(event as React.ChangeEvent<{ value: unknown }>)
        }
        sx={selectedStyle} // Apply the selected style
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        IconComponent={(props) => (
          <img
            {...props}
            // style={{
            //   cursor: "pointer",
            //   marginLeft: "10px",
            // }}
            src={ArrowDown}
            alt="arrow-down"
          />
        )}
      >
        <MenuItem value="">
          <em>{label}</em>
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomSelect;
