import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Logo from "../../assets/images/Logo.png";
import { Link, useNavigate } from "react-router-dom";
import { InputBase, Stack } from "@mui/material";
import arrowBack from "../../assets/images/icons/arrow-left.svg";
import { ReactComponent as SearchIcon } from "../../assets/images/icons/searchColored.svg";
import { alpha } from "@mui/material/styles";

interface HeaderMobileProps {
  isOnboarding: boolean;
  setIsOnBoarding: (isOnboarding: boolean) => void;
}

const HeaderMobile = ({ isOnboarding, setIsOnBoarding }: HeaderMobileProps) => {
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const navigate = useNavigate();

  const handleOpenSearch = () => {
    setIsSearchVisible((prevIsSearchVisible) => !prevIsSearchVisible);
  };

  return (
    <AppBar
      position="fixed"
      className="appBar"
      sx={{ height: 70, padding: "0px 20px" }}
    >
      <Toolbar disableGutters className="toolbar-mobile">
        <Link to="/">
          <img
            style={{
              cursor: "pointer",
            }}
            src={Logo}
            alt="Botel Logo"
            className="logo-mobile"
          />
        </Link>
        {isOnboarding ? (
          <button
            onClick={() => navigate("/listings")}
            className="button-navigate"
          >
            <img src={arrowBack} alt="arrow-back" className="button-icon" />
            <span className="buttonText B-16-16-600">Exit</span>
          </button>
        ) : (
          <Stack direction="row" justifyContent={"end"} spacing={1}>
            <Stack className="userBox" direction={"row"}>
              {!isSearchVisible ? (
                <IconButton onClick={handleOpenSearch}>
                  <SearchIcon style={{ width: "20px", height: "20px" }} />
                </IconButton>
              ) : (
                <InputBase
                  startAdornment={
                    <IconButton onClick={handleOpenSearch}>
                      <SearchIcon />
                    </IconButton>
                  }
                  placeholder="Search..."
                  sx={{
                    color: "#000",
                    "& .MuiSvgIcon-root": {
                      marginRight: "12px",
                    },
                    "&.Mui-focused": {
                      backgroundColor: alpha("#fff", 0.25),
                    },
                    width: "100%",
                    transition: "width 0.3s",
                    borderBottom: "1px solid #E1E3EA",
                  }}
                />
              )}
            </Stack>
          </Stack>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default HeaderMobile;
