import { Stack } from "@mui/material";
import moment from "moment";
import React from "react";
import Airbnb from "../../../../assets/images/icons/Group 1171274844.svg";
import { ReservationDetails } from "../../../../types";

interface CalendarReservationDetailsModalProps {
  reservationData: ReservationDetails
}

interface ReservationDetailProps {
  label?: string;
  desc?: string;
  date?: string;
  time?: string;
  timeColor?: string;
  borderBottom: boolean;
  img?: string;
  descClassName?: string;
  descColor?: string;
}

function getCurrencySymbol(currencyCode: string): string {
  switch (currencyCode) {
    case "USD":
      return "$";
    case "EUR":
      return "€";
    case "GBP":
      return "£";
    case "TRY":
      return "₺";
    default:
      return currencyCode;
  }
}

const ReservationDetail: React.FC<ReservationDetailProps> = ({
  label,
  desc,
  date,
  time,
  timeColor,
  borderBottom,
  img,
  descClassName,
  descColor,
}) => {
  return (
    <Stack
      sx={{
        padding: "var(--Spacing-space-5, 8px) 0px",
        alignItems: "flex-start",
        alignSelf: "stretch",
        borderBottom: borderBottom
          ? "1px solid var(--stroke-colors, #EBEBEB)"
          : "none",
      }}
    >
      <Stack
        sx={{
          justifyContent: "center",
          alignItems: "flex-start",
          gap: "4px",
        }}
      >
        <span
          className="B-14-20-500"
          style={{ color: "var(--Gray-900, #101828)" }}
        >
          {label}
        </span>
        {date && time ? (
          <span
            className="B-14-20-500"
            style={{ color: "var(--Gray-500, #667085)" }}
          >
            {moment(date).format("ddd, MMM D, YYYY · HH:mm")} ·{" "}
            <span style={{ color: timeColor }}>
              {moment(time).format("HH:mm")}
            </span>
          </span>
        ) : (
          <span
            className={descClassName ? descClassName : "B-14-20-500"}
            style={{
              color: descColor ? descColor : "var(--Gray-500, #667085)",
              display: "flex",
              alignItems: "center",
            }}
          >
            {img && (
              <img
                src={img}
                alt=""
                style={{
                  marginRight: "5px",
                  width: "16.717px",
                  height: "18px",
                }}
              />
            )}
            {desc}
          </span>
        )}
      </Stack>
    </Stack>
  );
};

const CalendarReservationDetailsModal: React.FC<
  CalendarReservationDetailsModalProps
> = ({ reservationData }) => {
  return (
    <Stack
      sx={{
        width: "100%",
        alignItems: "flex-start",
        gap: "8px",
      }}
    >
      <Stack
        direction={"row"}
        sx={{
          alignItems: "center",
          gap: "4px",
        }}
      >
        <span className="B-18-28-600">Reservation</span>
        <span className="B-15-15-500">·</span>
        <span className="B-14-20-500" style={{ color: "#475467" }}>
          {moment(reservationData.checkOutDate).isBefore(moment())
            ? "Past Guest"
            : "Future Guest"}
        </span>
      </Stack>
      <Stack
        sx={{
          padding: "0px 12px",
          alignItems: "flex-start",
          alignSelf: "stretch",
          borderRadius: "10px",
          background: "#FFF",
        }}
      >
        <ReservationDetail
          label="Check-in"
          date={reservationData.checkInDate}
          time={reservationData.checkInDate}
          timeColor="var(--Success-500, #12B76A)"
          borderBottom={true}
        />
        <ReservationDetail
          label="Check-out"
          date={reservationData.checkOutDate}
          time={reservationData.checkOutDate}
          timeColor="#FF455B"
          borderBottom={false}
        />
      </Stack>
      <Stack
        sx={{
          padding: "0px 12px",
          alignItems: "flex-start",
          alignSelf: "stretch",
          borderRadius: "10px",
          background: "#FFF",
        }}
      >
        <ReservationDetail
          label="Booking date"
          desc={`${reservationData.days} Nights`}
          borderBottom={false}
        />
      </Stack>

      <Stack
        sx={{
          padding: "0px 12px",
          alignItems: "flex-start",
          alignSelf: "stretch",
          borderRadius: "10px",
          background: "#FFF",
        }}
      >
        <ReservationDetail
          label="Booking Source"
          img={Airbnb}
          desc="Airbnb"
          borderBottom={true}
        />
        <ReservationDetail
          label="Guests"
          desc={
            (reservationData.adults ? `${reservationData.adults} adults` : "") +
            (reservationData.children
              ? `, ${reservationData.children} children`
              : "") +
            (reservationData.infants
              ? `, ${reservationData.infants} infants`
              : "") + ""
            //(reservationData.pets ? `, ${reservationData.pets} pets` : "")
          }
          borderBottom={true}
        />
        <ReservationDetail
          label="Note"
          desc="" // API'den gelecek
          borderBottom={true}
        />
        <ReservationDetail
          label="Guest Paid"
          desc={`${getCurrencySymbol(
            reservationData.currency
          )} ${reservationData.amount.toFixed(2)}`}
          descClassName="B-18-20-600"
          descColor="#1D2939"
          borderBottom={false}
        />
      </Stack>
      <Stack
        sx={{
          alignItems: "flex-start",
          alignSelf: "stretch",
          background: "transparent",
        }}
      >
        <ReservationDetail
          desc={`Created at: ${moment(reservationData.createdAt).format(
            "MMM DD, YYYY hh:mm A"
          )}`}
          borderBottom={false}
        />
      </Stack>
    </Stack>
  );
};

export default CalendarReservationDetailsModal;
