import {
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MainTextField from "../../../../../../components/core-components/inputs/TextField/MainTextField";
import MainSelectSelection from "../../../../../../components/core-components/inputs/Select/MainSelectSelection";
import { InputStyles } from "../../../../../../components/core-components/inputs/TextField/InputStyles";
import { useTranslation } from "react-i18next";

const Address = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const renderTextField = (
    title: string,
    placeholder: string,
    isMandatory: boolean
  ) => (
    <Stack spacing={1} sx={{ width: "100%" }}>
      <MainTextField
        title={title}
        placeholder={placeholder}
        value=""
        onChange={() => { }}
        isMandatory={isMandatory}
      />
    </Stack>
  );

  return (
    <Stack sx={{ width: "100%" }} mb={isMobile ? 4 : 0} spacing={2}>
      <p
        className="listing-details-layout-box-content-title"
        style={{ marginBottom: "16px" }}
      >
        {t("onboardingpages.step1.confirmTheAddress")}
      </p>
      <Stack spacing={1} direction={isMobile ? "column" : "row"}>
        <Stack spacing={1} sx={{ width: "100%" }}>
          <Typography variant="body2" sx={InputStyles.label}>
            {t("onboardingpages.step1.country")}
            <sub className="mandatory">*</sub>
          </Typography>
          <MainSelectSelection
            value=""
            onChange={() => { }}
            label={`${t("onboardingpages.step1.country")}`}
            options={[
              {
                value: "Azerbaijan",
                label: `Azerbaijan`,
              },
              {
                value: "Turkey",
                label: `Turkey`,
              },
            ]}
          />
        </Stack>
        {renderTextField(
          `${t("onboardingpages.step1.city")}`,
          `${t("onboardingpages.step1.city")}`,
          true
        )}
      </Stack>
      {renderTextField(
        `${t("onboardingpages.step1.address")}`,
        `${t("onboardingpages.step1.addAddress")}`,
        true
      )}
      {renderTextField(
        `${t("onboardingpages.step1.apartmentFloorBuilding")}`,
        `${t("onboardingpages.step1.addApartmentFloorBuilding")}`,
        false
      )}
      <Stack spacing={1} direction="row" justifyContent="space-between">
        {renderTextField(
          `${t("onboardingpages.step1.stateOrRegion")}`,
          `${t("onboardingpages.step1.addStateOrRegion")}`,
          true
        )}
        {renderTextField(
          `${t("onboardingpages.step1.postalCode")}`,
          `${t("onboardingpages.step1.addPostalCode")}`,
          true
        )}
      </Stack>
    </Stack>
  );
};

export default Address;
