import React from "react";
import { TextField } from "@mui/material";
import SearchIcon from "../../../../assets/images/icons/searchColored.svg";
import { useTranslation } from "react-i18next";

interface Props {
  showEndAdornment?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  height?: string;
}

function MessagesSearchTextfield({
  value,
  onChange,
  height = "48px",
}: Props) {
  const { t } = useTranslation();
  return (
    <TextField
      placeholder={t("reservationpage.search")}
      fullWidth
      value={value}
      onChange={onChange}
      sx={{
        "& .MuiInputBase-input::placeholder": {
          color: "var(--Gray-500, #667085)",
          fontFamily: "Inter !important",
          fontSize: "14px !important",
          fontStyle: "normal !important",
          fontWeight: 400,
          lineHeight: "20px",
        },
        "& .MuiOutlinedInput-root": {
          display: "flex",
          height: height,
          gap: "12px",
          alignItems: "center",
          padding: "8px 20px",
          borderRadius: "20px",
          border: "none",
          background: "#FFF",
          fontFamily: "Inter !important",
          fontSize: "14px !important",
          fontStyle: "normal !important",
          fontWeight: 400,
          lineHeight: "20px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "1px solid #E1E3EA",
        },
      }}
      InputProps={{
        startAdornment: (
          <img
            src={SearchIcon}
            alt="search-icon"
            style={{
              width: "16.61px",
              height: "16.701px",
              flexShrink: 0,
              marginRight: "10px",
            }}
          />
        ),
      }}
    />
  );
}

export default MessagesSearchTextfield;
