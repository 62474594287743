import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { GridColDef } from "@mui/x-data-grid";
import {
  Chip,
  IconButton,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import "./reservation.scss";
import ReservationImage from "../../../assets/images/reservation-img-1.jpeg";
import { Add } from "@mui/icons-material";
import InputSection from "../listing/InputSection/InputSection";
import ArrowDown from "../../../assets/images/icons/down.svg";
import ReservationDetailsModal from "../../../components/shared-components/ReservationDetailsModal";
import GridData from "../../../components/shared-components/GridData";
import moment from "moment";
import { useTranslation } from "react-i18next";
import EmptyList from "../../../components/shared-components/EmptyList";
import DatagridPageLoading from "../../../components/shared-components/loadings/DatagridPageLoading";
import { useQuery } from "react-query";
import CloseModalButton from "../../../components/shared-components/CloseModalButton";
import NewBooking from "../../../components/shared-components/new-booking";
import FilterListIcon from "@mui/icons-material/FilterList";
import CustomModalMobile from "../../../components/shared-components/CustomModalMobile";
import CustomSecondCheckbox from "../../../components/core-components/inputs/Checkbox/CustomSecondCheckbox";
import MessagesSearchTextfield from "../../../components/core-components/inputs/TextField/MessagesSearchTextfield";
import MobileGridData from "../../../components/shared-components/MobileGridData";
import NotFoundPage from "../error/NotFoundPage";
import axios from "axios";
import { BASE_API_URL } from "../../../api";

export default function Reservation() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [openCreateBooking, setOpenCreateBooking] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleOpenCreateBooking = () => setOpenCreateBooking(true);
  const handleClose = () => setOpen(false);
  const handleCloseCreateBooking = () => setOpenCreateBooking(false);
  const [page, setPage] = useState<number>(1);
  const [status, setStatus] = useState<boolean>(false);
  const [isMobileModalOpen, setIsMobileModalOpen] = useState(false);

  const handleOpenMobileModal = () => {
    setIsMobileModalOpen(true);
  };

  const handleCloseMobileModal = () => {
    setIsMobileModalOpen(false);
  };

  const handleMobileModalSave = () => {
    setIsMobileModalOpen(false);
  };

  const {
    data: newrows,
    isLoading,
    error,
  } = useQuery(
    ["bookings", page],
    async () => {
      const res = await axios.post(`${BASE_API_URL}/rateplan/restriction/list`, {
        Limit: 3,
        Page: page,
      });
      return res?.data;
    },
    {
      onSuccess: () => setStatus(true),
    }
  );
  if (error) {
    return <NotFoundPage />;
  }
  const columns: GridColDef<any>[] = [
    {
      field: "ID",
      headerName: "ID",
      disableColumnMenu: true,
      flex: 0.1,
      align: "left",
      renderCell: (params) => (
        <div className="containerId">
          <span className="locationText B-14-14-500">{params.row?.Id}</span>
        </div>
      ),
    },
    // TODO - Pet sayısı apide yok
    {
      field: "CustomerNameSurname",
      headerName: `${t("reservationpage.guests")}`,
      disableColumnMenu: true,
      flex: 1.5,
      align: "left",
      renderCell: (params) => {
        const helperText = `${params.row.Adults > 0
          ? `${params.row.Adults} ${t("reservationpage.adults")} `
          : ""
          }${params.row.Children > 0
            ? `${params.row.Children} ${t("reservationpage.children")} `
            : ""
          }${params.row.Infants > 0
            ? `${params.row.Infants} ${t("reservationpage.infants")} `
            : ""
          }${params.row.Pets > 0
            ? `${params.row.Pets} ${t("reservationpage.pets")}`
            : ""
          }`;

        const displayText =
          helperText.length > 20 ? `${helperText.slice(0, 20)}...` : helperText;

        return (
          <div className="containerGuests">
            <span className="guestText B-14-14-500">
              {params.row.CustomerNameSurname}
            </span>
            <span className="helperText B-12-12-600" title={helperText}>
              {displayText}
            </span>
          </div>
        );
      },
    },
    {
      field: "newdata",
      headerName: `${t("reservationpage.property")}`,
      disableColumnMenu: true,
      flex: 2,
      align: "left",
      renderCell: (params) => (
        <div className="containerGuests">
          <span className="infoText B-14-14-600">
            {params.row.PropertyName}
          </span>
          <span className="helperText B-12-12-500">{params.row.RoomName}</span>
        </div>
      ),
    },
    {
      field: "chechIn",
      headerName: `${t("reservationpage.checkin")}`,
      disableColumnMenu: true,
      align: "left",
      flex: 1.2,
      renderCell: (params) => (
        <span
          style={{
            display: "flex",
            height: "100px",
            alignItems: "center",
            alignSelf: "stretch",
          }}
          className="B-14-14-600"
        >
          {moment(params.row.CheckInDate).format("MMM D, YYYY")}
        </span>
      ),
    },
    {
      field: "chechOut",
      headerName: `${t("reservationpage.checkout")}`,
      disableColumnMenu: true,
      align: "left",
      flex: 1.2,
      renderCell: (params) => (
        <span
          style={{
            display: "flex",
            height: "100px",
            alignItems: "center",
            alignSelf: "stretch",
          }}
          className="B-14-14-600"
        >
          {moment(params.row.CheckOutDate).format("MMM D, YYYY")}
        </span>
      ),
    },
    {
      field: "totalNights",
      headerName: `${t("reservationpage.totalnights")}`,
      disableColumnMenu: true,
      align: "left",
      flex: 1.2,
      renderCell: (params) => (
        <span
          style={{
            display: "flex",
            height: "100px",
            alignItems: "center",
            alignSelf: "stretch",
          }}
          className="B-14-14-600"
        >
          {params.row.Days === 1 ? "1 night" : `${params.row.Days} nights`}
        </span>
      ),
    },
    {
      field: "payout",
      headerName: `${t("reservationpage.payout")}`,
      disableColumnMenu: true,
      align: "left",
      flex: 1,
      renderCell: (params) => (
        <span
          style={{
            display: "flex",
            height: "100px",
            alignItems: "center",
            alignSelf: "stretch",
          }}
          className="B-14-14-600"
        >
          {params.row.Currency === "USD"
            ? "$"
            : params.row.Currency === "EUR"
              ? "€"
              : params.row.Currency === "GBP"
                ? "£"
                : params.row.Currency}
          {new Intl.NumberFormat("tr-TR", {
            minimumFractionDigits: 2,
          }).format(params.row.Amount)}
        </span>
      ),
    },
    {
      field: "bookingSource",
      headerName: `${t("reservationpage.bookingSource")}`,
      disableColumnMenu: true,
      align: "center",
      flex: 1.3,
      renderCell: (params) => (
        <span
          style={{
            display: "flex",
            height: "100px",
            alignItems: "center",
            alignSelf: "stretch",
          }}
          className="B-14-14-600"
        >
          {params.row.OtaName}
        </span>
      ),
    },
    {
      field: "Status",
      headerName: `${t("reservationpage.status")}`,
      flex: 1.5,
      disableColumnMenu: true,
      renderCell: (params) => {
        const { color, backgroundColor } = getStatusColor(params.value);
        return (
          <Chip
            label={params.value}
            sx={{
              color,
              fontWeight: "600",
              backgroundColor,
              borderRadius: "10px",
              fontFamily: "Inter",
              padding: "3px",
            }}
          />
        );
      },
    },
    {
      field: "details",
      headerName: `${t("reservationpage.details")}`,
      disableColumnMenu: true,
      align: "left",
      hideSortIcons: true,
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <button
          style={{
            fontFamily: "Inter",
          }}
          className="details-button B-12-12-600"
          onClick={handleOpen}
        >
          {t("reservationpage.details")}
        </button>
      ),
    },
  ];

  const getStatusColor = (statusName: string) => {
    switch (statusName) {
      case "NEW":
        return { color: "#50CD89", backgroundColor: "#E8FFF3" };
      case "CANCELLED":
        return { color: "#D9214E", backgroundColor: "#FFF5F8" };
      case "PENDING":
        return { color: "#E9B500", backgroundColor: "#FFF8DD" };
      case "CONFIRMED":
        return { color: "#008000", backgroundColor: "#E8FFF3" };
      default:
        return { color: "#000", backgroundColor: "#FFF" }; // Default values
    }
  };

  const mobileColumns: GridColDef[] = [
    {
      field: "newData",
      headerName: "Reservation name",
      flex: 4.5,
      disableColumnMenu: true,
      sortable: false,
      align: "center",
      renderHeader: (params) => (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <span
            className="B-16-16-600"
            style={{ color: "#3F4254", lineHeight: "20px" }}
          >
            Reservation name
          </span>
        </Box>
      ),
      renderCell: (params) => {
        const { color, backgroundColor } = getStatusColor(params.row.Status); // Fetch the status color for mobile view
        return (
          <Box
            display="flex"
            width="100%"
            justifyContent="space-between"
            alignItems="center"
            height={"100%"}
          >
            <Box display="flex" alignItems="center" width={"100%"}>
              <img
                /*  src={params.row.ReservationImage} */
                src={ReservationImage}
                alt="reservation"
                style={{
                  width: "60px",
                  height: "40px",
                  objectFit: "cover",
                  marginRight: "8px",
                  borderRadius: "4px",
                }}
              />
              <Box
                display="flex"
                justifyContent={"flex-start"}
                flexDirection="column"
              >
                <Chip
                  label={params.row.Status}
                  sx={{
                    color,
                    fontWeight: "600",
                    height: "26px",
                    maxWidth: "100px",
                    backgroundColor,
                    borderRadius: "4px",
                    fontFamily: "Inter",
                    padding: "7px",
                    marginBottom: "8px",
                    fontSize: "10px",
                    lineHeight: "10px",
                    letterSpacing: "-0.3px",
                  }}
                />
                <span
                  className="B-12-12-600"
                  style={{
                    color: "var(--Gray-Gray-800, #3F4254)",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    marginBottom: "8px",
                    maxWidth: "160px",
                  }}
                >
                  {params.row.PropertyName}
                </span>
                <span
                  className="B-12-12-500"
                  style={{
                    color: "var(--Gray-Gray-500, #A1A5B7)",
                    textAlign: "left",
                  }}
                >
                  {moment(params.row.CheckInDate).format("MMM D, YYYY")}
                </span>
              </Box>
            </Box>
          </Box>
        );
      },
    },
    {
      field: "edit",
      headerName: "",
      disableColumnMenu: true,
      align: "center",
      headerAlign: "center",
      hideSortIcons: true,
      sortable: false,
      flex: 1.5,
      renderHeader: (params) => (
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <IconButton
            sx={{
              width: "100%",
            }}
            onClick={handleOpenMobileModal}
          >
            <FilterListIcon
              sx={{ width: "20px", height: "20px", cursor: "pointer" }}
            />
          </IconButton>
          <CustomModalMobile
            open={isMobileModalOpen}
            handleClose={handleCloseMobileModal}
            handleSave={handleMobileModalSave}
            title="Filter listing"
            showFooter={true}
          >
            <Stack sx={{ width: "100%", maxHeight: "800px" }}>
              <Stack
                spacing={2}
                pt={2}
                pb={2}
                sx={{
                  borderBottom: "1px solid var(--stroke-colors, #EBEBEB)",
                }}
              >
                <span className="B-16-16-600" style={{ color: "#3F4254" }}>
                  Reservation name
                </span>
                <Stack direction="row" spacing={1} alignItems="center">
                  <CustomSecondCheckbox
                    id="listingNameId"
                    checked={false}
                    onChange={() => { }}
                  />
                  <span className="B-14-24-500" style={{ color: "#3F4254" }}>
                    Tekgul apartment
                  </span>
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                  <CustomSecondCheckbox
                    id="listingNameId"
                    checked={false}
                    onChange={() => { }}
                  />
                  <span className="B-14-24-500" style={{ color: "#3F4254" }}>
                    Baku Villa
                  </span>
                </Stack>
              </Stack>
              <Stack
                spacing={2}
                pt={2}
                pb={2}
                sx={{
                  borderBottom: "1px solid var(--stroke-colors, #EBEBEB)",
                }}
              >
                <span className="B-16-16-600" style={{ color: "#3F4254" }}>
                  Status
                </span>
                <Stack direction="row" spacing={1} alignItems="center">
                  <CustomSecondCheckbox
                    id="listed"
                    checked={false}
                    onChange={() => { }}
                  />
                  <span className="B-14-24-500" style={{ color: "#3F4254" }}>
                    Listed
                  </span>
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                  <CustomSecondCheckbox
                    id="unlisted"
                    checked={false}
                    onChange={() => { }}
                  />
                  <span className="B-14-24-500" style={{ color: "#3F4254" }}>
                    Unlisted
                  </span>
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                  <CustomSecondCheckbox
                    id="draft"
                    checked={false}
                    onChange={() => { }}
                  />
                  <span className="B-14-24-500" style={{ color: "#3F4254" }}>
                    Draft
                  </span>
                </Stack>
              </Stack>
              <Stack
                spacing={2}
                pt={2}
                pb={2}
                sx={{
                  borderBottom: "1px solid var(--stroke-colors, #EBEBEB)",
                }}
              >
                <span className="B-16-16-600" style={{ color: "#3F4254" }}>
                  Source
                </span>
                <Stack direction="row" spacing={1} alignItems="center">
                  <CustomSecondCheckbox
                    id="botel"
                    checked={false}
                    onChange={() => { }}
                  />
                  <span className="B-14-24-500" style={{ color: "#3F4254" }}>
                    Botel
                  </span>
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                  <CustomSecondCheckbox
                    id="booking"
                    checked={false}
                    onChange={() => { }}
                  />
                  <span className="B-14-24-500" style={{ color: "#3F4254" }}>
                    Booking
                  </span>
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                  <CustomSecondCheckbox
                    id="airbnb"
                    checked={false}
                    onChange={() => { }}
                  />
                  <span className="B-14-24-500" style={{ color: "#3F4254" }}>
                    Airbnb
                  </span>
                </Stack>
              </Stack>
            </Stack>
          </CustomModalMobile>
        </Box>
      ),
      renderCell: (params) => (
        <button
          style={{
            fontFamily: "Inter",
          }}
          className="details-button B-12-12-600"
          onClick={handleOpen}
        >
          {t("reservationpage.details")}
        </button>
      ),
    },
  ];

  return isLoading && !status ? (
    <DatagridPageLoading status={status} />
  ) : (
    <Box
      className={!isMobile ? "container-datagrid-pages" : ""}
      sx={{
        width: { xs: "100%", sm: "auto" },
        margin: "auto",
      }}
      padding={isMobile ? 2 : "0px 40px"}
      pb={isMobile ? 20 : 0}
      mb={isMobile ? 20 : 0}
    >
      <Box my={isMobile ? 0 : 4}>
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems={"center"}
          spacing={2}
        >
          <h1 className={isMobile ? "B-18-18-600" : "H-24"}>
            {t("reservationpage.reservations")}
          </h1>
          <button
            className="buttonLg"
            aria-label="add listing"
            onClick={handleOpenCreateBooking}
          >
            <Add />
            <p
              className={
                isMobile ? "buttonText B-13-13-600" : "buttonText B-16-16-600"
              }
              style={{ color: "#fff" }}
            >
              {t("reservationpage.createreservation")}
            </p>
          </button>
        </Stack>
      </Box>
      {GridData.length > 0 && !isMobile && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            marginTop: "32px",
          }}
        >
          <InputSection
            showTextField={false}
            showGoogleVRSelect={false}
            showListingStatusButton={false}
            showSortByButton={false}
          />
        </Box>
      )}
      {isMobile && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "32px",
          }}
        >
          <MessagesSearchTextfield showEndAdornment={false} height="40px" />
        </Box>
      )}
      <Box className="reservation-table" sx={{ alignItems: "flex-start" }}>
        {!isMobile && (
          <Button
            sx={{
              color: "var(--Gray-Gray-800, #3F4254)",
              fontSize: "13px",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "14px" /* 107.692% */,
              textTransform: "capitalize",
            }}
          >
            {t("reservationpage.sortby")}
            <img
              src={ArrowDown}
              alt="Arrow Down"
              style={{ marginLeft: "8px" }}
            />
          </Button>
        )}

        {isLoading ? (
          <DatagridPageLoading status={status} />
        ) : isMobile ? (
          <Box sx={{ width: "100%" }}>
            {newrows?.Body?.length > 0 ? (
              <MobileGridData
                checkboxSelection={true}
                rows={newrows?.Body}
                columns={mobileColumns}
                setPage={setPage}
                total={newrows?.Total}
                page={page}
                rowHeight={110}
              />
            ) : newrows?.Body?.length === 0 ? (
              <EmptyList message="listingpage.emptylist" />
            ) : (
              <EmptyList message="listingpage.emptylist" />
            )}
          </Box>
        ) : (
          <Box
            sx={{
              height: 400,
              width: "100%",
              marginTop: "20px",
              marginBottom: "122px",
            }}
          >
            {newrows?.Body?.length > 0 ? (
              <GridData
                rows={newrows.Body}
                columns={columns}
                checkboxSelection={true}
                setPage={setPage}
                total={newrows.Total}
                page={page}
              />
            ) : newrows?.Body?.length === 0 ? (
              <EmptyList message="reservationpage.emptylist" />
            ) : (
              <EmptyList message="reservationpage.emptylist" />
            )}
          </Box>
        )}
      </Box>

      <Modal open={open} onClose={handleClose}>
        <div onClick={handleClose}>
          <Box
            sx={{
              display: "flex",
              alignItems: isMobile ? "flex-end" : "center",
              justifyContent: "center",
              height: isMobile ? "100%" : "100%",
              width: "100%",
              position: "fixed",
              top: 0,
              left: 0,
            }}
          >
            <Box
              sx={{
                height: isMobile ? "auto" : "100%",
                backgroundColor: "background.paper",
                boxShadow: 24,
                minWidth: isMobile ? "100%" : "200px",
                maxWidth: isMobile ? "100%" : "45%",
                width: isMobile ? "100%" : "auto",
                maxHeight: isMobile ? "80%" : "95%",
                overflowY: "auto",
                borderRadius: isMobile ? "12px 12px 0 0" : "12px",
              }}
              onClick={(event) => event.stopPropagation()}
            >
              <ReservationDetailsModal onClose={handleClose} />
            </Box>
          </Box>
        </div>
      </Modal>

      <Modal open={openCreateBooking} onClose={handleCloseCreateBooking}>
        <div onClick={handleCloseCreateBooking}>
          <Box
            sx={{
              display: "flex",
              alignItems: isMobile ? "flex-end" : "center",
              justifyContent: "center",
              height: "100%",
              width: "100%",
              position: "fixed",
              top: 0,
              left: 0,
              ...(isMobile && { bottom: 0 }),
            }}
          >
            <Box
              sx={{
                height: isMobile ? "80%" : "100%",
                backgroundColor: "background.paper",
                boxShadow: 24,
                minWidth: isMobile ? "100%" : "500px",
                maxWidth: isMobile ? "100%" : "728px",
                width: isMobile ? "100%" : "728px",
                maxHeight: isMobile ? "100%" : "95%",
                overflowY: "auto",
                borderRadius: "12px",
                padding: "24px",
              }}
              onClick={(event) => event.stopPropagation()}
            >
              <Stack spacing={4}>
                <Stack justifyContent={"flex-end"} alignItems={"flex-end"}>
                  <CloseModalButton
                    show={true}
                    onClick={handleCloseCreateBooking}
                  />
                </Stack>
                <NewBooking />
              </Stack>
            </Box>
          </Box>
        </div>
      </Modal>
    </Box>
  );
}
