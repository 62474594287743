import { Button } from "@mui/material";

interface CustomButtonProps {
  backgroundColor: string;
  color: string;
  onClick?: () => void;
  buttonText?: string;
  border?: string;
  type?: "submit" | "reset" | "button" | undefined;
  disable?: boolean;
  buttonWidth?: string;
  height?: string;
  marginTop?: number;
  textDecoration?: string;
  icon?: string;
  fontWeight?: number;
  fontSize?: string;
}

const CustomButton = ({
  backgroundColor,
  color,
  onClick,
  buttonText,
  border,
  type,
  disable,
  buttonWidth = "auto",
  height = "48px",
  marginTop = 40,
  textDecoration,
  icon,
  fontWeight = 600,
  fontSize = "16px",
}: CustomButtonProps) => {

  return (
    <Button
      style={{
        backgroundColor: backgroundColor,
        color: color,
        border: border,
        padding: textDecoration === "underline" ? "0px" : "16px 24px",
        height: height,
        borderRadius: "50px",
        alignItems: "center",
        marginTop: marginTop,
        justifyContent: "center",
        alignSelf: "flex-end",
        width: buttonWidth,
        textDecoration: textDecoration,
        gap: "8px",
        fontWeight: fontWeight,
        fontFamily: "Inter !important",
        fontSize: fontSize,
        fontStyle: "normal",
        lineHeight: "16px",
      }}
      disabled={disable}
      type={type || "button"}
      onClick={type !== "submit" ? onClick : undefined}
    >
      {icon && <img src={icon} alt="icon" />}
      {buttonText}
    </Button>
  );
};

export default CustomButton;
