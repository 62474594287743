import React, { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import MainTextField from "../../../../../../../../components/core-components/inputs/TextField/MainTextField";
import MainSelectSelection from "../../../../../../../../components/core-components/inputs/Select/MainSelectSelection";
import { useTranslation } from "react-i18next";

type CleaningAndTaxesProps = {
  title: string;
  inputTitle: string;
  showCleaningBasis?: boolean;
  showTaxType?: boolean;
  showFeeBasis?: boolean;
  showPrice?: boolean;
  showSpecialDiscount?: boolean;
};

function CleaningAndTaxes({
  title,
  inputTitle,
  showCleaningBasis,
  showTaxType,
  showFeeBasis,
  showSpecialDiscount,
}: CleaningAndTaxesProps) {
  const { t } = useTranslation();
  const [price, setPrice] = useState("");
  const [cleaningBasis, setCleaningBasis] = useState("");
  const [taxType, setTaxType] = useState("");
  const [feeBasis, setFeeBasis] = useState("");
  const [currency, setCurrency] = useState("");
  const [showPrice, setShowPrice] = useState(false);
  const [specialDiscount, setSpecialDiscount] = useState("");

  const handleInputChange =
    (setValue: React.Dispatch<React.SetStateAction<string>>) =>
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        if (!isNaN(Number(value))) {
          setValue(value);
        }
      };

  // CleaningBasis ve Price için olan component
  useEffect(() => {
    if (cleaningBasis) {
      setShowPrice(true);
    } else {
      setShowPrice(false);
    }
  }, [cleaningBasis]);

  // TaxType, FeeBasis ve Price için olan component
  useEffect(() => {
    if (taxType && feeBasis) {
      setShowPrice(true);
    } else {
      setShowPrice(false);
    }
  }, [taxType, feeBasis]);

  return (
    <Stack sx={{ width: "100%" }} spacing={3}>
      <p className="B-14-22-700">{title}</p>
      <Stack sx={{ maxWidth: "560px" }} spacing={2}>
        {showCleaningBasis && (
          <Stack spacing={1}>
            <p className="input-area-title B-14">{inputTitle}</p>
            <MainSelectSelection
              value={cleaningBasis}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                setCleaningBasis(event.target.value as string)
              }
              label={t("detailsPages.select")}
              options={[
                {
                  value: "Mandatory-included",
                  label: `Mandatory-included`,
                },
                {
                  value: "No",
                  label: `No`,
                },
              ]}
            />
          </Stack>
        )}
        {showTaxType && (
          <Stack spacing={1}>
            <p className="input-area-title B-14">{inputTitle}</p>
            <MainSelectSelection
              value={taxType}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                setTaxType(event.target.value as string)
              }
              label={t("detailsPages.select")}
              options={[
                {
                  value: "City tax",
                  label: `City tax`,
                },
                {
                  value: "No",
                  label: `No`,
                },
              ]}
            />
          </Stack>
        )}
        {showFeeBasis && (
          <Stack spacing={1}>
            <p className="input-area-title B-14">
              {t("detailsPages.pricing.feeBasis")}
            </p>
            <MainSelectSelection
              value={feeBasis}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                setFeeBasis(event.target.value as string)
              }
              label={t("detailsPages.select")}
              options={[
                {
                  value: "Per adult/day",
                  label: `Per adult/day`,
                },
                {
                  value: "No",
                  label: `No`,
                },
              ]}
            />
          </Stack>
        )}
        {showSpecialDiscount && (
          <Stack spacing={1}>
            <p className="input-area-title B-14">{inputTitle}</p>
            <MainTextField
              placeholder={t("detailsPages.pricing.putAName")}
              value={specialDiscount}
              onChange={handleInputChange(setSpecialDiscount)}
            />
            <p className="input-area-title B-14">
              {t("detailsPages.pricing.price")}
            </p>
            <Stack spacing={1} direction="row">
              <MainTextField
                placeholder="100"
                type="number"
                value={price}
                onChange={handleInputChange(setPrice)}
              // numericOnly
              />
              <MainSelectSelection
                value={currency}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                  setCurrency(event.target.value as string)
                }
                label={"Usd"}
                options={[
                  {
                    value: "Eur",
                    label: `Eur`,
                  },
                  {
                    value: "Try",
                    label: `Try`,
                  },
                ]}
              />
            </Stack>
          </Stack>
        )}
        {showPrice && (
          <Stack spacing={1}>
            <p className="input-area-title B-14">
              {t("detailsPages.pricing.price")}
            </p>
            <Stack spacing={1} direction="row">
              <MainTextField
                placeholder="100"
                type="number"
                value={price}
                onChange={handleInputChange(setPrice)}
              // numericOnly
              />
              <MainSelectSelection
                value={currency}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                  setCurrency(event.target.value as string)
                }
                label={"Usd"}
                options={[
                  {
                    value: "Eur",
                    label: `Eur`,
                  },
                  {
                    value: "Try",
                    label: `Try`,
                  },
                ]}
              />
            </Stack>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}

export default CleaningAndTaxes;
