import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { getTheme } from "./assets/themes";
import Router from "./Router";
import { ToastProvider } from "./contexts/ToastContext";
import { Provider } from "react-redux";
import { store } from "./store/store";
import * as Sentry from "@sentry/react";
import { MantineProvider } from "@mantine/core";
import { getMantineTheme } from "./assets/themes/mantineTheme";
import "@mantine/core/styles.css";
import { QueryClient, QueryClientProvider } from "react-query";
import TagManager from 'react-gtm-module';


Sentry.init({
  dsn: "https://9b9b93a5281e4944a06d317a8dd62b5d@o4506195347439616.ingest.us.sentry.io/4508331672731648",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["lively-ground-0aabb8d0f.5.azurestaticapps.net"],
  enabled: window.location.hostname !== "localhost",
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    },
  },
});
const theme = getTheme();
const mantineTheme = getMantineTheme()

const tagManagerArgs = {
  gtmId: 'GTM-WBNCGZMM',
};

TagManager.initialize(tagManagerArgs)

root.render(
  <>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <MantineProvider theme={mantineTheme}>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <ToastProvider>
                <Router />
              </ToastProvider>
            </BrowserRouter>
          </ThemeProvider>
        </MantineProvider>
      </QueryClientProvider>
    </Provider>
  </>
);
