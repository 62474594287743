import Location from "./components/location";
import Photos from "./components/photos";
import BasicDetails from "./components/basic-details";
import Rooms from "./components/rooms";
import Description from "./components/description";
import Facilities from "./components/facilities";
import OtherEssentials from "../others";
import PhotosPage from "../photos";

interface Props {
  subMenu: string;
  setSubMenu: React.Dispatch<React.SetStateAction<string>>;
}

function Main(props: Props) {
  const { subMenu, setSubMenu } = props;

  return (
    <>
      {subMenu === "otherEssentials" ? (
        <OtherEssentials />
      ) : subMenu === "photos" ? (
        <PhotosPage />
      ) : (
        <>
          <Location />
          <Photos setSubMenu={setSubMenu} />
          <BasicDetails />
          <Rooms />
          <Description />
          <Facilities setSubMenu={setSubMenu} />
        </>
      )}
    </>
  );
}

export default Main;
