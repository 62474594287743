import React, { useEffect, useRef, useState } from "react";
import { Stack, Grid, Chip } from "@mui/material";
import ReactQuill from "react-quill";
import GrayInfoIcon from "../../../../assets/images/icons/gray-info.svg";

interface Message {
  title: string;
  messageText: string;
  isDiscountCheckboxChecked?: boolean;
}

interface MessageCreatorProps {
  chipLabels: string[];
  messages: Message[];
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
}

const MessageCreator: React.FC<MessageCreatorProps> = ({
  chipLabels,
  messages,
  setMessages,
}) => {
  const [selectedMessageIndex, setSelectedMessageIndex] = useState<number>(0);
  const [message, setMessage] = useState<string>(messages[0].messageText);

  useEffect(() => {
    if (selectedMessageIndex !== null) {
      setMessage(messages[selectedMessageIndex].messageText);
    }
  }, [selectedMessageIndex]);

  useEffect(() => {
    if (selectedMessageIndex !== null) {
      const updatedMessages = [...messages];
      updatedMessages[selectedMessageIndex].messageText = message;
      setMessages(updatedMessages);
    }
  }, [message]);

  const quillRef = useRef<ReactQuill>(null);

  const handleChipClick = (label: string) => {
    const quill = quillRef.current?.getEditor();
    if (!quill) return;
    const range = quill.getSelection();
    if (range) {
      const cursorPosition = range.index;
      quill.insertText(cursorPosition, label);
      quill.setSelection({
        index: cursorPosition + label.length,
        length: 0,
      });
    }
  };

  return (
    <Stack spacing={3}>
      <Stack spacing={3}>
        <Stack spacing={"12px"}>
          <Stack direction={"row"} spacing={"4px"} alignItems={"center"}>
            <p className="B-14-20-500">Create Message</p>
            <img src={GrayInfoIcon} alt="Info Icon" />
          </Stack>
          <div id="message-creator-id">
            <ReactQuill
              ref={quillRef}
              placeholder={
                "Hey {guest_name}! Just noticed the pre-approval expired. Let me know if you’re still interested in booking for these dates :)"
              }
              value={message}
              onChange={setMessage}
              modules={{
                toolbar: false,
              }}
              style={{
                height: "169px",
                border: "1px solid #A1A5B7",
                borderRadius: "6px",
                boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
              }}
            />
          </div>
        </Stack>
      </Stack>
      <Stack spacing={3}>
        <Stack spacing={"12px"}>
          <Stack direction={"row"} spacing={"4px"} alignItems={"center"}>
            <p className="B-14-20-500">Message tags</p>
            <img src={GrayInfoIcon} alt="Info Icon" />
          </Stack>
          <Grid container spacing={1}>
            {chipLabels.map((label, index) => (
              <Grid item key={index}>
                <Chip
                  label={label}
                  className="B-12-18-500"
                  sx={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: "6px",
                    border: "1px solid #D0D5DD",
                    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                    width: "fit-content",
                    color: "#3F4254",
                  }}
                  onClick={() => handleChipClick(label)}
                />
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Stack>
      <Stack spacing={3} pb={5} borderBottom={"1px solid #EBEBEB"}>
        <Stack spacing={"12px"}>
          <Stack direction={"row"} spacing={"4px"} alignItems={"center"}>
            <p className="B-14-20-500">Info</p>
            <img src={GrayInfoIcon} alt="Info Icon" />
          </Stack>
          <p className="B-14-20-400">
            Botel will custom-generate {"{ai_icebreaker}"} by reading through
            the past conversation history with the guest and writing a short,
            natural-sounding sentence referencing their past stay. For example:
            "We hope you had a good time with your family over Thanksgiving last
            year.
          </p>
        </Stack>
      </Stack>
      <Stack spacing={3} pt={2}>
        <Stack spacing={1}>
          <p
            className="B-18-18-600"
            style={{ color: "#181C32", lineHeight: "20px" }}
          >
            Preview template
          </p>
          <p className="B-14-20-400">
            This feature allows you to send personalized offers to guests
          </p>
        </Stack>
        {messages.map((message, index) => (
          <Stack
            key={index}
            spacing={3}
            p={2}
            alignItems={"flex-start"}
            borderRadius={"12px"}
            sx={{
              backgroundColor:
                selectedMessageIndex === index ? "#EEF6FF" : "#F9F9F9",
              border: "1px solid #EBEBEB",
              cursor: "pointer",
            }}
            onClick={() => setSelectedMessageIndex(index)}
          >
            {message?.title && <p className="B-16-20-600">{message?.title}</p>}
            <p
              className="B-14-20-400"
              dangerouslySetInnerHTML={{ __html: message.messageText }}
            />
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export default MessageCreator;
