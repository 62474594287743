import React, { useState } from "react";
import { Chip, FormControl, MenuItem, Select } from "@mui/material";
import ArrowDown from "../../../../assets/images/icons/down.svg";
import { InputStyles } from "../TextField/InputStyles";

export interface Option {
  value: string | number;
  label: string;
}

const MainSelectSelection: React.FC<{
  pageType?: string;
  value: any;
  onChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  label?: string;
  isFilter?: boolean;
  placeholder?: string;
  options: Option[];
  minWidth?: string | number;
  height?: string | number;
  multiple?: boolean;
  error?: boolean;
}> = ({
  value,
  onChange,
  onBlur,
  label,
  options,
  minWidth,
  isFilter = false,
  pageType,
  height = "45px",
  multiple = false,
  error = false,
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const toggleMenu = () => {
    setOpen((prev) => !prev);
  };

  const renderValue = (selected: any) => {
    if (multiple) {
      const selectedArray = selected as string[];
      if (selectedArray.length === 0) {
        return "Select";
      }
      const displayArray =
        selectedArray.length > 2 ? selectedArray.slice(0, 2) : selectedArray;
      const extraCount = selectedArray.length - 2;

      return (
        <div>
          {displayArray.map((value) => (
            <Chip key={value} label={value} />
          ))}
          {extraCount > 0 && <Chip label={`+${extraCount} more`} />}
        </div>
      );
    } else {
      return selected || "Select";
    }
  };

  return (
    <FormControl sx={{ minWidth: minWidth || 120 }}>
      <Select
        multiple={multiple}
        value={value}
        onChange={(event) =>
          onChange(event as React.ChangeEvent<{ value: unknown }>)
        }
        onBlur={onBlur}
        renderValue={multiple === true ? renderValue : undefined}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        sx={{
          ...(isFilter ? InputStyles.selectFilter : InputStyles.select),
          ...(pageType
            ? {
                "&.MuiDataGrid-root": {
                  background: "white",
                },
                backgroundColor: "white",
                height: height,
              }
            : {}),
          "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
            borderColor: "var(--Gray-Gray-500, #a1a5b7)",
          },
          "&:hover .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
            borderColor: "var(--Gray-Gray-500, #a1a5b7)",
          },
        }}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        IconComponent={() => (
          <img
            onClick={toggleMenu}
            style={{
              cursor: "pointer",
              marginLeft: "10px",
              transform: open ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 0.3s ease",
            }}
            src={ArrowDown}
            alt="arrow-down"
          />
        )}
      >
        {multiple === false && (
          <MenuItem value="">
            <em>{label}</em>
          </MenuItem>
        )}
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MainSelectSelection;
