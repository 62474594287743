import { createTheme } from "@mui/material";

export const getTheme = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#0065E0",
      },
    },
    typography: {
      allVariants: {
        color: "#181B1D",
        fontSize: "14px",
        lineHeight: "24px",
        wordBreak: "break-word",
        fontFamily: ["Inter", "sans-serif"].join(","),
      },

      fontFamily: ["Inter", "sans-serif"].join(","),
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            transition: "0.3s ease all",
            borderRadius: "50px",
            textTransform: "none",
            "&.MuiButton-outlined": {
              background: "transparent",
              color: "#3F4254",
              borderColor: "#3F4254",
              fontSize: "13px",
              height:"38px",
              "&:hover": {
                background: "transparent",
                color: "#0065E0",
                borderColor: "#0065E0",
              },
            },
            "&.Mui-disabled": {
              opacity: "0.3",
            },
          },
        },
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },
    },
  });
  return theme;
};
