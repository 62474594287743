import { useTranslation } from "react-i18next";
import DetailsInputArea from "../../../../../../../../components/shared-components/DetailsInputArea";
import Headline from "../../../../components/inputs/Headline";
import ShortDescription from "../../../../components/inputs/ShortDescription";
import DetailInformation from "../../../../components/inputs/DetailInformation";

function Description() {
  const { t } = useTranslation();
  const data = [
    {
      title: t("listingDetails.headline"),
      subtitle: t("listingDetails.notset"),
      component: <Headline />,
      isSelect: false,
    },
    {
      title: t("listingDetails.shortdescription"),
      subtitle: t("listingDetails.notset"),
      component: <ShortDescription />,
      isSelect: false,
    },
    {
      title: t("listingDetails.detaileddesription"),
      subtitle: t("listingDetails.notset"),
      component: <DetailInformation />,
      isSelect: false,
    },
  ];
  return (
    <>
      <DetailsInputArea
        title={t("listingDetails.description")}
        data={data}
        onClick={() => { }}
        id={"description"}
      />
    </>
  );
}

export default Description;
