const config = {
  api: {
    host: process.env.REACT_APP_API_URL || 'http://localhost:3000/api',
    timeout: 20000,
  },
  auth0: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  }
};

export const BASE_API_URL = process.env.REACT_APP_API_URL
export const AUDIENCE_API_URL = process.env.REACT_APP_AUDIENCE_API_URL
export const CHANNEL_API_URL = process.env.REACT_APP_CHANNEL_API_URL
export const BASE_SIGNAL_API = "https://signalr.botel.ai/booklyhub"
export const INTEGRATION_API_URL = process.env.REACT_APP_INTEGRATION_API_URL
export const BASE_API_URL2 = process.env.REACT_APP_API_URL2 || config.api.host;
export const BASE_API_URL3 = process.env.REACT_APP_API_URL3 || config.api.host;
export const BASE_API_URL4 = process.env.REACT_APP_API_URL4 || config.api.host;
export const BASE_API_URL5 = process.env.REACT_APP_API_URL5 || config.api.host;
export default config;

