import React from "react";
import { Box } from "@mui/material";
import MinusIcon from "../../assets/images/icons/minus.svg";
import PlusIcon from "../../assets/images/icons/plus.svg";

interface CounterBoxProps {
  itemValue: number;
  handleMinusClick: () => void;
  handlePlusClick: () => void;
}

const CounterBox: React.FC<CounterBoxProps> = ({
  itemValue,
  handleMinusClick,
  handlePlusClick,
}) => {
  return (
    <Box className="place-basics-item-value-box">
      <button
        type="button"
        className="place-basics-item-value-button"
        onClick={handleMinusClick}
      >
        <img src={MinusIcon} alt="minus" />
      </button>
      <p className="place-basics-item-value-text B-20-20-600">{itemValue}</p>
      <button
        type="button"
        className="place-basics-item-value-button"
        onClick={handlePlusClick}
      >
        <img src={PlusIcon} alt="plus" />
      </button>
    </Box>
  );
};

export default CounterBox;
