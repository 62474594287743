import { Stack } from "@mui/material";
import React, { useState } from "react";
import MainTextField from "../../../../components/core-components/inputs/TextField/MainTextField";
import CustomButton from "../../../../components/shared-components/CustomButton";

interface NameModalProps {
  initialFirstName: string;
  initialLastName: string;
  onSave: (firstName: string, lastName: string) => void;
}

function NameModal({
  initialFirstName,
  initialLastName,
  onSave,
}: NameModalProps) {
  const [firstName, setFirstName] = useState<string>(initialFirstName);
  const [lastName, setLastName] = useState<string>(initialLastName);

  const handleSave = () => {
    onSave(firstName, lastName);
  };

  return (
    <>
      <Stack spacing={3} pb={4}>
        <Stack spacing={"12px"}>
          <p className="B-14-20-500">First Name</p>
          <MainTextField
            title=""
            onChange={(e) => setFirstName(e.target.value)}
            value={firstName}
            placeholder="John"
          />
        </Stack>
        <Stack spacing={"12px"}>
          <p className="B-14-20-500">Last Name</p>
          <MainTextField
            title=""
            onChange={(e) => setLastName(e.target.value)}
            value={lastName}
            placeholder="Doe"
          />
        </Stack>
      </Stack>
      <CustomButton
        backgroundColor="#0065E0"
        color="#FFFFFF"
        onClick={handleSave}
        buttonText="Save"
        marginTop={0}
        buttonWidth={"100%"}
      />
    </>
  );
}

export default NameModal;
