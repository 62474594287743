import { Box, Stack } from "@mui/material";
import AutocompleteTagComponent from "../../../components/shared-components/AutocompleteTagComponent";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

interface Tag {
  id: string;
  tag: string;
}

interface TagManagementModalProps {
  existingTags: Tag[];
  onSave: (tags: Tag[]) => void;
}

const TagManagementModal: React.FC<TagManagementModalProps> = ({
  existingTags = [],
  onSave,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  const validTags = existingTags.filter(
    (tag) =>
      tag.tag &&
      tag.tag.trim() !== "" &&
      tag.tag.toLowerCase() !== "no tags" &&
      tag.id !== "no-tags"
  );

  const initialTags = validTags.map((tag) => tag.tag);
  const API_BASE_URL = process.env.REACT_APP_API_URL;
  const addTagToBackend = async (tagName: string) => {
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await axios.post(
        `${API_BASE_URL}/Tag/Add`,
        {
          tag: tagName,
          relation: "Property",
          isActive: true,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log("Tag ekleme response:", response.data);
      return response.data;
    } catch (error) {
      console.error("Tag ekleme hatası:", error);
      throw error;
    }
  };

  const deleteTagFromBackend = async (tagId: string) => {
    try {
      const accessToken = await getAccessTokenSilently();
      console.log("Silinecek Tag ID:", tagId);

      const response = await axios.delete(
        `${API_BASE_URL}/Tag/Delete?id=${tagId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log("Tag silme response:", response.data);
      return response.data;
    } catch (error) {
      console.error("Tag silme hatası:", error);
      throw error;
    }
  };

  const handleTagsChange = async (newTagNames: string[]) => {
    try {
      const filteredNewTags = newTagNames.filter(
        (tag) => tag && tag.trim() !== ""
      );

      const tagsToDelete = validTags.filter(
        (existingTag) => !filteredNewTags.includes(existingTag.tag)
      );

      console.log("Silinecek tag'ler:", tagsToDelete);

      const tagsToAdd = filteredNewTags.filter(
        (newTag) => !validTags.some((existingTag) => existingTag.tag === newTag)
      );

      console.log("Eklenecek tag'ler:", tagsToAdd);

      for (const tag of tagsToDelete) {
        if (tag.id && tag.id !== "no-tags") {
          await deleteTagFromBackend(tag.id);
        }
      }

      const remainingTags = validTags.filter((existingTag) =>
        filteredNewTags.includes(existingTag.tag)
      );

      const addedTags = await Promise.all(
        tagsToAdd.map(async (tagName) => {
          try {
            const response = await addTagToBackend(tagName);
            return {
              id: response.id,
              tag: tagName,
            };
          } catch (error) {
            console.error(`Error adding tag ${tagName}:`, error);
            return {
              id: `temp-${Date.now()}`,
              tag: tagName,
            };
          }
        })
      );

      console.log("Kalan tag'ler:", remainingTags);
      console.log("Eklenen tag'ler:", addedTags);

      const finalTags = [...remainingTags, ...addedTags];
      onSave(
        finalTags.length > 0 ? finalTags : [{ id: "no-tags", tag: "No tags" }]
      );
    } catch (error) {
      console.error("Tag güncelleme hatası:", error);
    }
  };

  return (
    <Box className="p-4">
      <Stack spacing={3}>
        <div>
          <AutocompleteTagComponent
            initialTags={initialTags}
            onTagsChange={handleTagsChange}
            relation="Property"
            freeSolo={true}
          />
        </div>
      </Stack>
    </Box>
  );
};

export default TagManagementModal;
