import { Box, Popover, Stack } from "@mui/material";
import React, { useState } from "react";
import CloseModalButton from "../../../../../../../components/shared-components/CloseModalButton";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CustomDatePicker from "../../../../../../../components/core-components/inputs/DatePicker/CustomDatePicker";
import MainTextField from "../../../../../../../components/core-components/inputs/TextField/MainTextField";
import MainSelectSelection from "../../../../../../../components/core-components/inputs/Select/MainSelectSelection";
import CustomButton from "../../../../../../../components/shared-components/CustomButton";
import { useTranslation } from "react-i18next";
import { UpdateRestriction } from "../../../../../../../network/calendar/UpdateRestriction";
import { useMutation } from "react-query";
import dayjs, { Dayjs } from "dayjs";

interface Props {
  open: boolean;
  anchorEl: HTMLButtonElement;
  handleClose: () => void;
  modalTitle?: string;
  titleFirst?: string;
  descriptionFirst?: string;
  titleSecond?: string;
  descriptionSecond?: string;
  buttonText?: string;
  showCurrency?: boolean;
  ratePlanId?: string;
  propertyId?: string;
  start?: Dayjs;
  refetch?: () => void;
  fieldName?: string;
  value: any;
}

type ExtraCharge = {
  name: string;
  amount: string;
  currency: string;
};

function NumberModal(props: Props) {
  const { t } = useTranslation();
  const {
    open,
    anchorEl,
    handleClose,
    modalTitle,
    titleFirst,
    descriptionFirst,
    titleSecond,
    descriptionSecond,
    buttonText,
    showCurrency = false,
    ratePlanId,
    propertyId,
    start,
    fieldName,
    refetch,
    value,
  } = props;

  const [rateCurrency, setRateCurrency] = useState<ExtraCharge[]>([
    { name: "", amount: "", currency: "" },
  ]);

  const updateRestriction = new UpdateRestriction();
  const { mutate, isLoading } = useMutation(
    (postData: any) => updateRestriction.add(postData),
    {
      onSuccess: (res) => {
        handleClose();
        refetch && refetch();
      },
      onError: (error) => {
        console.error("Error submitting form", error);
      },
    }
  );

  const handleRateCurrency = (
    event: React.ChangeEvent<{ value: unknown }>,
    index: number,
    key: keyof ExtraCharge
  ) => {
    const newRateCurrency = [...rateCurrency];
    newRateCurrency[index][key] = event.target.value as string;
    setRateCurrency(newRateCurrency);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    const formData: any = {
      PropertyId: propertyId,
      RatePlanId: ratePlanId,
      DateBeg: dayjs(e.target.check_in.value, "DD.MM.YYYY").format(
        "YYYY-MM-DD"
      ),
      DateEnd: dayjs(e.target.check_out.value, "DD.MM.YYYY").format(
        "YYYY-MM-DD"
      ),
    };

    if (fieldName) {
      formData[fieldName] = e.target.input.value;
    }

    mutate(formData);
  };

  return (
    <>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          ".MuiPaper-root": {
            gap: "24px",
            borderRadius: "16px",
            background: "#FFF",
            padding: "24px",
            boxShadow: "0px 8px 14px 0px rgba(15, 42, 81, 0.04)",
            width: "680px",
          },
          background: "rgba(53, 48, 48, 0.21)",
        }}
      >
        <Box component={"form"} onSubmit={handleSubmit}>
          <Stack
            direction="row"
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <p className="B-16-16-600">{modalTitle}</p>
            <CloseModalButton show={true} onClick={handleClose} />
          </Stack>
          <Stack spacing={2} mt={2}>
            <Stack maxWidth={463}>
              <p className="B-14" style={{ color: "#181C32" }}>
                {titleFirst}
              </p>
              <p className="B-12-16-400">{descriptionFirst}</p>
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              alignItems={"center"}
              sx={{ width: "100%" }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <CustomDatePicker
                  label="Check-in"
                  name={"check_in"}
                  date={start}
                />
              </LocalizationProvider>
              <p className="B-14-24-500">{t("calendarpage.to")}</p>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <CustomDatePicker
                  label="Check-out"
                  name={"check_out"}
                  date={start}
                />
              </LocalizationProvider>
            </Stack>

            <Stack maxWidth={463}>
              <p className="B-14" style={{ color: "#181C32" }}>
                {titleSecond}
              </p>
              <p className="B-12-16-400">{descriptionSecond}</p>
            </Stack>
            <Stack direction="row" spacing={2}>
              <MainTextField
                title=""
                type="number"
                onChange={() => ""}
                value={value}
                placeholder="4"
                name="input"
              />
              {showCurrency &&
                rateCurrency.map((rate, index) => (
                  <MainSelectSelection
                    key={index}
                    value={rate.currency}
                    onChange={(e) => handleRateCurrency(e, index, "currency")}
                    label="Currency"
                    options={[
                      { value: "USD", label: "USD" },
                      { value: "EUR", label: "EUR" },
                      { value: "GBP", label: "GBP" },
                      { value: "TRY", label: "TRY" },
                    ]}
                  />
                ))}
            </Stack>
          </Stack>
          <Stack
            direction="row"
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            <Stack
              direction="row"
              justifyContent={"flex-end"}
              alignItems={"center"}
            >
              <Stack>
                <CustomButton
                  backgroundColor="#0065E0"
                  color="#FFFFFF"
                  disable={isLoading}
                  // onClick={() => {}}
                  buttonText={buttonText}
                  type="submit"
                />
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Popover>
    </>
  );
}

export default NumberModal;
