import { Box } from "@mui/material";
import React from "react";
import { useMediaQuery, useTheme } from "@mui/material";

interface BookingSettingsProps {
  title: string;
  subtitle: string;
}

const BookingSettings: React.FC<BookingSettingsProps> = ({
  title,
  subtitle,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <Box className="booking-settings-box-left">
        <p
          className={
            isMobile
              ? "booking-settings-box-title B-14-24-500"
              : "booking-settings-box-title B-16"
          }
        >
          {title}
        </p>
        <p
          className={
            isMobile
              ? "booking-settings-box-subtitle B-12-16-400"
              : "booking-settings-box-subtitle B-14-20-400"
          }
        >
          {subtitle}
        </p>
      </Box>
    </>
  );
};

export default BookingSettings;
