import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from "react";
import {
  Box,
  Button,
  Chip,
  IconButton,
  Stack,
  Tooltip,
  useMediaQuery,
  useTheme,
  CircularProgress,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import moment from "moment";
import { useTranslation } from "react-i18next";
import ChatTextfield from "./components/ChatTextfield";
import { MessageItem } from "../../../../types";
import Airbnb from "../../../../assets/images/icons/Group 1171274844.svg";
import Guesty from "../../../../assets/images/icons/guesty.svg";
import Hostaway from "../../../../assets/images/icons/hostaway.svg";
import Chat from "./components/Chat";
import DateDivider from "./components/DateDivider";
import AiModal from "./components/AiModal";
import SimpleSnackbar from "../../../../components/shared-components/Snackbar";
import CustomSecondSelect from "../../../../components/core-components/inputs/Select/CustomSecondSelect";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store/store";
import { fetchSingleMessage, putAssignUser, selectMessageItemsPageInfo, setMobileOpen, updateMessageHeader } from "../../../../store/slices/chatSlice";
import "./ChatCard.scss"
import { fetchAssigneesApi } from "../../../../api/messageApi";
import LoadingDots from "../../../../components/shared-components/loadings/LoadingDots";
import AiCard from "./components/AiCard";
import { Flex, Text } from "@mantine/core";
import { IconMessage } from "@tabler/icons-react";

interface User {
  id: string;
  name: string;
  firstName: string;
  lastName: string;
}

const ChatCard = React.memo(() => {

  const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"));
  const { t } = useTranslation();

  const [showEdit, setShowEdit] = useState(false);
  const [openSnackbar, setOpen] = useState(false);
  const [title, setTitle] = useState("");

  const chatMessagesAreaRef = useRef<HTMLDivElement | null>(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const PAGE_SIZE = 10;
  const [assignAnchorEl, setAssignAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const [openAssignPopover, setOpenAssignPopover] = useState(false);
  const [assignees, setAssignees] = useState<User[]>([]);
  const { getAccessTokenSilently } = useAuth0();

  const currentPage = useRef(0)

  const dispatch = useDispatch<AppDispatch>();
  // TODO: CHECK THIS

  const {
    messageId,
    singleMessageLoading,
    bookingId,
    mobileMessageOpen,
    selectedMessage
  } = useSelector((state: RootState) => state.chat);

  const currentMessage = useSelector((state: RootState) => state.chat.messagesCache[messageId])
  const currentReservation = useSelector((state: RootState) => state.chat.reservationCache[bookingId] ?? null)

  let currentMessageItems: MessageItem[] = []

  if (currentMessage) {
    currentMessageItems = currentMessage.chatMessages
  }

  const { hasMore } = useSelector(
    (state: RootState) => selectMessageItemsPageInfo(state, messageId)
  );


  const handleScroll = useCallback(
    async (event: React.UIEvent<HTMLDivElement>) => {
      const element = event.currentTarget;
      const scrollThreshold = 50;

      if (isLoadingMore || singleMessageLoading || !hasMore) return;

      console.log(element.scrollTop, scrollThreshold)
      if (element.scrollTop <= scrollThreshold) {
        const previousScrollHeight = element.scrollHeight;
        try {
          setIsLoadingMore(true);

          const accessToken = await getAccessTokenSilently();
          currentPage.current = currentPage.current + 1;
          const params = {
            messageId,
            page: currentPage.current,
            limit: PAGE_SIZE,
            accessToken,
          }

          await dispatch(fetchSingleMessage(params)).unwrap();

        } catch (error) {
          console.error("Mesajlar yüklenirken hata oluştu:", error);
        } finally {
          setTimeout(() => {
            const newScrollHeight = element.scrollHeight;
            const additionalHeight = newScrollHeight - previousScrollHeight;
            element.scrollTop = additionalHeight;
            setIsLoadingMore(false);
          }, 0);
        }
      }
    },
    [
      isLoadingMore,
      hasMore,
      messageId,
      dispatch,
      getAccessTokenSilently,
    ]
  );

  useEffect(() => {
    const fetchData = async () => {
      //  if (chatMessagesMap[messageId]) {
      if (currentMessageItems) {
        const accessToken = await getAccessTokenSilently();
        dispatch(
          fetchSingleMessage({
            messageId,
            page: 1,
            limit: PAGE_SIZE,
            accessToken,
          })
        );
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    currentPage.current = 0
  }, [messageId]);

  useEffect(() => {
    if (!isLoadingMore) {
      scrollToBottom()
    }
  }, [currentMessageItems, selectedMessage?.messageNeedPost])

  const messageGroups = useMemo(() => {
    if (!currentMessageItems || currentMessageItems.length === 0) {
      return {};
    }

    return currentMessageItems.reduce((acc, message) => {
      const date = new Date(message.createdAt).toISOString().split("T")[0];
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(message);
      return acc;
    }, {} as { [key: string]: MessageItem[] });
  }, [currentMessageItems]);

  const handleTimeRangeChange = () => {
    // setTimeRange(value);
  };

  const handleSelectChange = async (selectedId: string) => {
    try {
      //const userId = nameToIdMap[selectedName];
      const user = assignees.find(user => user.id === selectedId)
      if (!user) {
        console.error("Selected user ID not found");
        return;
      }

      const accessToken = await getAccessTokenSilently();
      const assignUserResponse: any = dispatch(putAssignUser({
        messageId: messageId,
        userId: user.id,
        accessToken
      }))

      if (assignUserResponse && assignUserResponse.status === 200) {
        await dispatch(
          fetchSingleMessage({
            messageId,
            page: 1,
            limit: PAGE_SIZE,
            accessToken,
          })
        );
        setOpen(true);
        setTitle("Agent assigned successfully");
        handleClose();
      }
    } catch (error) {
      console.error("Error in handleSelectChange:", error);
      setOpen(true);
      setTitle("Failed to assign agent");
    }
  };


  const scrollToBottom = () => {
    if (chatMessagesAreaRef.current) {
      chatMessagesAreaRef.current?.scrollTo({
        top: chatMessagesAreaRef.current.scrollHeight,
        behavior: "auto"
      });
    }
  };

  useEffect(() => {
    const fetchAssignees = async () => {
      // TODO: MOVE TO STATE
      try {
        const accessToken = await getAccessTokenSilently();
        const response = await fetchAssigneesApi({ accessToken })

        const users: User[] = response.data.map((user) => {
          const data = {
            id: user.id,
            name: user.firstName,
            firstName: user.firstName,
            lastName: user.lastName,
          }
          if (user.lastName) {
            data.name = `${user.firstName} ${user.lastName}`
          }
          return data
        });


        setAssignees(users);
      } catch (error) {
        console.error("Error fetching assignees:", error);
      }
    };
    fetchAssignees();
  }, []);


  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAssignAnchorEl(event.currentTarget as HTMLButtonElement);
    setOpenAssignPopover(true);
  };

  const handleClose = () => {
    setOpenAssignPopover(false);
  };
  //  const timeOptions = [
  //    "1 hour",
  //    "3 hours",
  //    "6 hours",
  //    "12 hours",
  //    "24 hours",
  //  ];


  const handleMarkUnread = async () => {
    //TODO: HANDLE MARK UNREAD
    const accessToken = await getAccessTokenSilently();
    await dispatch(
      updateMessageHeader({ accessToken, updateData: { id: messageId, isRead: false } })
    ).unwrap();
  };

  const handleArchive = async () => {
    //TODO: HANDLE ARCHIVE
    const accessToken = await getAccessTokenSilently();
    await dispatch(
      updateMessageHeader({ accessToken, updateData: { id: messageId, isClosed: true } })
    ).unwrap();
  }

  // const handleMute = async (duration: number) => {
  // try {
  //   const accessToken = await getAccessTokenSilently();
  //   // const success = await updateMessageHeaderApi({
  //   //   accessToken: accessToken,
  //   //   updateData: {
  //   //     id: messageId,
  //   //     mute: duration,
  //   //   }
  //   // });
  //   const success = null

  //   if (success) {
  //     setMuteStatus(duration);
  //     setOpen(true);
  //     setTitle(
  //       duration === 0
  //         ? "Mesaj sessizden çıkarıldı"
  //         : "Mesaj sessize alındı"
  //     );
  //     handlePopoverClose();
  //   }
  // } catch (error) {
  //   console.error("Mute işlemi sırasında hata:", error);
  //   setOpen(true);
  //   setTitle("İşlem başarısız oldu");
  // }
  // };

  const HandleReservation = () => {
    const source = currentReservation?.otaName?.toLowerCase();
    const provider = selectedMessage?.provider

    const textLogoStyle = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "24px",
      height: "24px",
      borderRadius: "50%",
      backgroundColor: "#F1F1F1",
      fontSize: "10px",
      fontWeight: "600",
      color: "#666",
      textTransform: "uppercase",
      opacity: "0.5",
    };

    switch (source) {
      case "guesty":
        return (
          <img
            src={Guesty}
            alt="guesty-platform"
            style={{
              width: "24px",
              height: "24px",
              flexShrink: 0,
              opacity: "0.5",
            }}
          />
        );
      case "airbnb":
      case "airbnb2":
        return (
          <img
            src={Airbnb}
            alt="airbnb-platform"
            style={{
              width: "24px",
              height: "24px",
              flexShrink: 0,
              opacity: "0.5",
            }}
          />
        );
      case "hostaway":
        return (
          <img
            src={Hostaway}
            alt="hostaway-platform"
            style={{
              width: "24px",
              height: "24px",
              flexShrink: 0,
              opacity: "0.5",
            }}
          />
        );
      case "lodgify":
        return (
          <div
            style={{
              ...textLogoStyle,
              textTransform: undefined,
            }}
          >
            L
          </div>
        );
      case "manual":
        return (
          <span
            style={{
              fontSize: "12px",
              color: "#667085",
              opacity: "0.5",
            }}
          >
            {provider || "Manual"}
          </span>
        );
      default:
        return (
          <span
            style={{
              fontSize: "12px",
              color: "#667085",
              opacity: "0.5",
            }}
          >
            {source
              ? source.charAt(0).toUpperCase() + source.slice(1)
              : "Unknown"}
          </span>
        );
    }
  }

  const handleBackClick = () => {
    dispatch(setMobileOpen(false))
    const url = new URL(window.location.href);
    url.search = ''; // Remove the query string
    window.history.replaceState({}, '', url.toString());
  }


  const sm = selectedMessage
  const shouldShowAiNeedOrSuggestion = sm && sm.messageNeedPost && ((sm.isNeedAttention && sm.messageNeedPost.messageType === "actionneeded") ||
    (sm.messageNeedPost.messageType === "semiauto"))


  if (!selectedMessage) return (
    <Box className="chat-card">
      <Box
        height="100%"
        display="flex"
        justifyItems="center"
        alignItems="center"
        color="var(--Gray-600, #475467)"
      >
        <Flex direction="column" align="center" gap={10}>
          <IconMessage color="gray" size={60} stroke="0.9" />
          <Text c="dimmed" fw={600} size="lg">Please Select Message </Text>
        </Flex>
      </Box>
    </Box >
  )

  return (
    <Box className={`chat-card ${mobileMessageOpen ? 'mobile' : ''}`}>
      {singleMessageLoading && currentPage.current === 0 ? (
        <LoadingDots />
      ) : (
        <>
          <Box className="chat-header-box" >
            <Box className="header-left">
              <Stack direction={"row"} spacing={1} alignItems="center">
                {isMobile && (
                  <IconButton
                    sx={{
                      margin: 0,
                      paddingLeft: 0,
                      paddingRight: 0,
                    }}
                    onClick={handleBackClick}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                )}
                {!isMobile && (
                  <Box className="chat-customer-image-box">
                    {selectedMessage?.title && selectedMessage.title[0]}
                  </Box>
                )}
                <p className="chat-card-customer-name B-20-20-600">
                  {selectedMessage?.title}
                </p>
                <HandleReservation />
              </Stack>
            </Box>
            <Stack className="header-right" >
              <CustomSecondSelect
                dataList={assignees.map(({ name, id }) => ({ name, id }))}
                value={selectedMessage.assignedUser}
                placeholder="Assign agent"
                openPopover={openAssignPopover}
                anchorEl={assignAnchorEl}
                handleClick={handleClick}
                handleClose={handleClose}
                onChange={handleSelectChange}
              />

              <AiModal
                showEdit={showEdit}
                setShowEdit={setShowEdit}
              // message={getCurrentMessage(
              //   messages.find((msg) => msg.id === messageId)
              // )}
              />

              <Stack direction={"row"}>
                <Tooltip title="Mark as unread">
                  <IconButton
                    onClick={handleMarkUnread}
                    style={{ color: "#181C32" }}
                  >
                    <MailOutlineIcon />
                  </IconButton>
                </Tooltip>

                {/*<Tooltip title={muteStatus === 0 ? "Mute" : "Unmute"}>
                    <IconButton
                      style={{ color: "#181C32" }}
                      aria-describedby={
                        muteStatus === 0 ? popoverId : undefined
                      }
                      onClick={(event) => {
                        if (muteStatus === 0) {
                          handleScheduleClick(event);
                        } else {
                          handleMute(0);
                        }
                      }}
                    >
                      {muteStatus === 0 ? (
                        <ScheduleIcon />
                      ) : (
                        <NotificationsOffIcon />
                      )}
                    </IconButton>
                  </Tooltip>

                  <Popover
                    sx={{
                      "& .MuiPopover-paper": {
                        borderRadius: "12px !important",
                      },
                      "& .MuiPaper-root": {
                        borderRadius: "12px !important",
                      },
                    }}
                    id={popoverId}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handlePopoverClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <Box className="time-options-box">
                      {timeOptions.map((option) => {
                        const hours = parseInt(option.split(" ")[0]);
                        return (
                          <MenuItem
                            key={option}
                            onClick={() => {
                              handleMute(hours);
                              handlePopoverClose();
                            }}
                            className="B-14-20-400 time-options-menu-item"
                          >
                            {option}
                          </MenuItem>
                        );
                      })}
                    </Box>
                  </Popover>*/}

                <Tooltip title="Move to archive">
                  <IconButton
                    onClick={handleArchive}
                    style={{ color: "#181C32" }}
                  >
                    <ArchiveOutlinedIcon
                      style={{
                        //   transform: archived?.includes(messageId)
                        //    ? "rotate(180deg)"
                        //   : "none",
                        transition: "transform 0.3s ease",
                      }}
                    />{" "}
                  </IconButton>
                </Tooltip>

                <SimpleSnackbar
                  setOpen={setOpen}
                  open={openSnackbar}
                  title={title}
                />

                {isMobile && (
                  <a href="tel:+1234567890">
                    <IconButton style={{ color: "#181C32" }}>
                      <CallOutlinedIcon />
                    </IconButton>
                  </a>
                )}
              </Stack>
            </Stack>
          </Box>

          <Box
            ref={chatMessagesAreaRef}
            className="chat-card-messages-area"
            onScroll={handleScroll}
          >
            {isLoadingMore && (
              <Box display="flex" width="100%" justifyContent="center" padding="10px">
                <CircularProgress size={20} />
              </Box>
            )}

            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              width={"100%"}
              pt={"20px"}
            >
              <Chip
                className="last-message-chip"
                label={moment(selectedMessage?.lastMessageReceivedAt).format("DD MMM YYYY")}
              />
            </Stack>
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              textAlign={"center"}
              width={"100%"}
            >
              <span className="B-12-18-500">
                We analyze messages for safety, support, product enhancement,
                or other purposes.
                <Button className="chat-learn-more-button" >
                  <span>Learn more</span>
                </Button>
              </span>
            </Stack>
            {/*TODO: MESSAGES HERE*/}
            {currentMessageItems && currentMessageItems.length > 0 ? (
              Object.keys(messageGroups)
                .sort()
                .map((day, index) => (
                  <React.Fragment key={`${day}-${index}`}>
                    <DateDivider
                      date={moment(day).format("YYYY-MM-DD")}
                      onTimeRangeChange={handleTimeRangeChange}
                      chatMessagesAreaRef={chatMessagesAreaRef}
                      messageItemInComponent={currentMessageItems}
                    />
                    {messageGroups[day]
                      .sort((a, b) =>
                        new Date(a.createdAt).getTime() -
                        new Date(b.createdAt).getTime()
                      )
                      .map((message) => {
                        return (
                          <Chat
                            message={message}
                            key={`${message.id}-${message.createdAt}`}
                            showTime={true}
                          />
                        );
                      })}
                  </React.Fragment>
                ))
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
                width="100%"
              >
                <p className="B-14-14-600">
                  {t("inboxpage.letstartchatting")}
                </p>
              </Box>
            )}
            {shouldShowAiNeedOrSuggestion && <AiCard />}
          </Box>
          <ChatTextfield />
        </>
      )}
    </Box>
  );
}
);

export default ChatCard;
