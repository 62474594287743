import {
  Box,
  InputAdornment,
  Modal,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import CloseModalButton from "../../../../../components/shared-components/CloseModalButton";
import InfoIcon from "../../../../../assets/images/icons/support.svg";
import SearchIcon from "../../../../../assets/images/icons/searchColored.svg";
import ListingRow from "../../../calendar/components/header/components/bulk-update-modal/components/listing-row";
import ListingImage from "../../../../../assets/images/image31.png";
import CustomModalMobile from "../../../../../components/shared-components/CustomModalMobile";

interface SuggestModalProps {
  open: boolean;
  handleClose: () => void;
  handleSave: () => void;
}

const SuggestModal: React.FC<SuggestModalProps> = ({
  open,
  handleClose,
  handleSave,
}) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"));

  const listings = [
    { image: ListingImage, title: "Tekgul Apart Baku near" },
    { image: ListingImage, title: "Tekgul Apart Baku near" },
    { image: ListingImage, title: "Tekgul Apart Baku near" },
    { image: ListingImage, title: "Tekgul Apart Baku near" },
    { image: ListingImage, title: "Tekgul Apart Baku near" },
    { image: ListingImage, title: "Tekgul Apart Baku near" },
  ];

  const modalContent = (
    <>
      <Stack
        spacing={2}
        direction={"row"}
        sx={{
          padding: "16px 14px",
          alignItems: "center",
          alignSelf: "stretch",
          borderRadius: "7px",
          border: "var(--none, 1px) solid var(--Primary-Primary, #3E97FF)",
          background: "var(--Primary-Primary-Light, #EEF6FF)",
        }}
      >
        <Stack
          sx={{
            width: "35px",
            height: "35px",
            padding: "2.917px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={InfoIcon}
            alt="info"
            style={{ width: "29.167px", height: "29.167px" }}
          />
        </Stack>
        <span className="B-13-13-500" style={{ color: "#3E97FF" }}>
          You can suggest to client other listings
        </span>
      </Stack>
      <Stack sx={{ alignItems: "flex-start", gap: "4px" }}>
        <span
          className="B-14-20-400"
          style={{ color: "var(--Gray-900, #101828)" }}
        >
          Listing
        </span>
        <span
          className="B-12-18-500"
          style={{ color: "var(--Gray-500, #667085)" }}
        >
          Select one or more listings to update rates
        </span>
      </Stack>

      <TextField
        variant="outlined"
        placeholder="Search"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <img src={SearchIcon} alt="Search" />
            </InputAdornment>
          ),
          sx: {
            height: "48px",
            padding: "17px 15px",
            alignItems: "center",
            gap: "var(--Spacing-space-6, 10px)",
            alignSelf: "stretch",
            borderRadius: "var(--radius-sm, 6px)",
            background: "var(--white-dark-white, #FFF)",
            "& .MuiOutlinedInput-root": {
              borderRadius: "var(--radius-sm, 6px)",
            },
          },
        }}
        fullWidth
      />
      <Stack spacing={2} alignItems={"center"} sx={{ width: "100%" }}>
        {[...Array(3)].map((_, rowIndex) => (
          <Stack
            key={rowIndex}
            direction="row"
            spacing={2}
            sx={{ width: "100%" }}
          >
            {[...Array(2)].map((_, colIndex) => {
              const listingIndex = rowIndex * 2 + colIndex;
              if (listingIndex >= listings.length) return null;
              return (
                <ListingRow key={colIndex} listing={listings[listingIndex]} />
              );
            })}
          </Stack>
        ))}
      </Stack>
    </>
  );

  return isMobile ? (
    <CustomModalMobile
      open={open}
      handleClose={handleClose}
      handleSave={handleSave}
      title="Suggest new listing"
    >
      <Stack spacing={2} sx={{ width: "100%" }} pb={3}>
        {modalContent}
      </Stack>
    </CustomModalMobile>
  ) : (
    <Modal open={open} onClose={handleClose}>
      <div onClick={handleClose}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            right: 0,
            bottom: 0,
            width: "100%",
            height: "100%",
          }}
        >
          <Box
            sx={{
              display: "inline-flex",
              flexDirection: "column",
              alignItems: "flex-start",
              padding: "32px",
              gap: "16px",
              backgroundColor: "background.paper",
              background: "#FFF",
              boxShadow: "0px 16px 32px 0px rgba(0, 0, 0, 0.12)",
              borderRadius: "12px",
              minWidth: "744px",
            }}
            onClick={(event) => event.stopPropagation()}
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "flex-start",
                alignSelf: "stretch",
              }}
            >
              <p
                style={{
                  color: "var(--Gray-Gray-900, #181C32)",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "24px",
                }}
              >
                Suggest new listing
              </p>
              <CloseModalButton show={true} onClick={handleClose} />
            </Box>
            {modalContent}
            <Box
              sx={{
                display: "flex",
                padding: "8px 0px",
                justifyContent: "flex-end",
                alignItems: "flex-start",
                alignSelf: "stretch",
                height: "48px",
              }}
            >
              <button
                onClick={handleSave}
                style={{
                  display: "flex",
                  height: "48px",
                  padding: "12px 16px",
                  justifyContent: "center",
                  gap: "8px",
                  alignItems: "center",
                  cursor: "pointer",
                  border: "none",
                  borderRadius: "50px",
                  background: "var(--Blue-light, #0065E0)",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "20px" /* 107.692% */,
                  color: "var(--white-dark-white, #FFF)",
                }}
              >
                Suggest new listing
              </button>
            </Box>
          </Box>
        </Box>
      </div>
    </Modal>
  );
};

export default SuggestModal;
