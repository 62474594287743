export const MobileStylesSheet = {
  modalFooter: {
    display: "flex",
    alignContent: "space-between",
  },
  radioExtraStyles: { paddingTop: "0", alignContent: "center" },
  radioOrdinary: {
    alignContent: "space-between",
  },
  muiFormgroup: {
    borderBottom: "1px solid #E1E3EA",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    padding: "24px 12px",
    width: "100%",
  },
  muiFormgroupWithoutIcon: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "10px 4px",
    width: "100%",
  },
  muiPaper: {
    " .MuiDialog-paper": {
      borderRadius: "12px",
      width: "100%",
      background: "#FFF",
      padding: " 12px 16px",
      boxShadow: "0px 8px 14px 0px rgba(15, 42, 81, 0.04)",
      maxHeight: "85%",
      position: "fixed",
      bottom: 0,
      marginBottom: 0,
    },
  },
};
