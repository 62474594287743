import React from "react";

const MessageFormatter = ({ text }: { text: string }) => {
  const decodeHTML = (html: string) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  };

  const formatMessage = (message: string) => {
    let formattedText = decodeHTML(message);
    //css stylerını temzile
    formattedText = formattedText.replace(/^a\s*{[^}]*};?/i, "");

    formattedText = formattedText
      .replace(/\s+style="[^"]*"/g, "")
      .replace(/\s+class="[^"]*"/g, "")
      .replace(/\s+dir="[^"]*"/g, "");

    formattedText = formattedText
      .replace(/<head>.*?<\/head>/gis, "")
      .replace(/<style>.*?<\/style>/gis, "")
      .replace(/<script>.*?<\/script>/gis, "")
      .replace(/<meta[^>]*>/gi, "")
      .replace(/<!DOCTYPE[^>]*>/gi, "")
      .replace(/<div[^>]*>/gi, "")
      .replace(/<\/div>/gi, "\n")
      .replace(/<p[^>]*>/gi, "")
      .replace(/<\/p>/gi, "\n")
      .replace(/<br[^>]*>/gi, "\n")
      .replace(/<span[^>]*>/gi, "")
      .replace(/<\/span>/gi, "")
      .replace(/<table[^>]*>/gi, "")
      .replace(/<\/table>/gi, "\n")
      .replace(/<tr[^>]*>/gi, "")
      .replace(/<\/tr>/gi, "\n")
      .replace(/<td[^>]*>/gi, " ")
      .replace(/<\/td>/gi, " ")
      .replace(/<[^>]+>/g, "");

    // HTML özel karakterlerini dönüştür
    formattedText = formattedText
      .replace(/&nbsp;/g, " ")
      .replace(/&amp;/g, "&")
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">")
      .replace(/&quot;/g, '"')
      .replace(/&#39;/g, "'")
      .replace(/&mdash;/g, "—")
      .replace(/&ndash;/g, "–")
      .replace(/&hellip;/g, "...")
      .replace(/\\n/g, "\n")
      .replace(/\r\n/g, "\n")
      .replace(/\r/g, "\n");

    // Fazla boşlukları ve satır sonlarını temizle
    formattedText = formattedText
      .replace(/\n\s*\n\s*\n/g, "\n\n")
      .replace(/[ \t]+/g, " ")
      .trim();

    // URL'leri işle ve bağlantıya çevir
    const urlPattern = /(https?:\/\/[^\s]+)/g;

    // Kalın yazı desteği ekle
    const boldPattern = /\*\*(.+?)\*\*/g;

    const parts = formattedText.split(urlPattern);

    return parts.map((part, index) => {
      if (part.match(urlPattern)) {
        return (
          <a
            key={index}
            href={part}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 hover:text-blue-700 underline"
          >
            {part}
          </a>
        );
      }

      const boldFormatted = part.split(boldPattern).map((segment, i) => {
        if (i % 2 === 1) {
          // Bold text
          return (
            <strong key={`bold-${i}`} className="font-bold">
              {segment}
            </strong>
          );
        }
        // Normal text
        return (
          <span key={`text-${i}`}>
            {segment.split("\n").map((line, j) => (
              <React.Fragment key={j}>
                {j > 0 && <br />}
                {line}
              </React.Fragment>
            ))}
          </span>
        );
      });

      return <React.Fragment key={index}>{boldFormatted}</React.Fragment>;
    });
  };

  return (
    <div className="whitespace-pre-wrap break-words">{formatMessage(text)}</div>
  );
};

export default MessageFormatter;
