import DetailsInputArea from "../../../../../../../../components/shared-components/DetailsInputArea";
import { useTranslation } from "react-i18next";
import Address from "../../../../components/modals/Address";

function Location() {
  const { t } = useTranslation();
  const data = [
    {
      title: t("listingDetails.address"),
      subtitle: "Hisaronu Mah. Degirmenyani Cayirova, Marmaris, Mugla, Turkey",
      component: <Address />,
      isSelect: true,
      width: "624",
    },
  ];
  return (
    <>
      <DetailsInputArea
        title={t("listingDetails.location")}
        data={data}
        onClick={() => { }}
        id={"location"}
      />
    </>
  );
}

export default Location;
