import { Stack, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import Coverphoto from "../../../../../../assets/images/cover-photo.jpeg";

interface Props {
  setSubMenu: React.Dispatch<React.SetStateAction<string>>;
}

function PhotosList(props: Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { setSubMenu } = props;
  const photos = Array(isMobile ? 2 : 4).fill(Coverphoto);

  return (
    <Stack direction="row" spacing={1}>
      {photos.map((photo, index) => (
        <div
          key={index}
          style={{
            width: "194px",
            height: "140px",
            borderRadius: "6px",
            backgroundImage: `url(${photo})`,
            backgroundSize: "cover",
            position: "relative",
          }}
        >
          {(isMobile ? index === 1 : index === 3) && (
            <>
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background:
                    "linear-gradient(85deg, rgba(255, 255, 255, 0.34) 5.65%, #FFF 95.76%)",
                }}
              />
              <button
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  padding: "9px 12px",
                  background: "transparent",
                  border: "none",
                  cursor: "pointer",
                  alignItems: "center",
                  gap: "5px",
                  color: "var(--Gray-Gray-900, #181C32)",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "16px" /* 114.286% */,
                  textDecorationLine: "underline",
                  marginTop: "25%",
                }}
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  setSubMenu("photos");
                }}
              >
                12 photos
              </button>
            </>
          )}
        </div>
      ))}
    </Stack>
  );
}

export default PhotosList;
