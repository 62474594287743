import React, { SetStateAction, useEffect, useState } from "react";
import HeaderTitle from "../components/HeaderTitle";
import styles from "../../onboardingTwo/onboardingTwoStyles";
import { Box, Button, Stack, useMediaQuery, useTheme } from "@mui/material";
import iconbutton from "../../../../assets/images/IconButton.svg";
import { Option } from "../../../../components/core-components/inputs/Select/CustomSelect";
import ListingModal from "../../onboardingTwo/step2/ListingModal";
import MainSelectSelection from "../../../../components/core-components/inputs/Select/MainSelectSelection";
import CustomModal from "../../../../components/shared-components/CustomModal";
import GrayInfoIcon from "../../../../assets/images/icons/gray-info.svg";
import BlueInfoIcon from "../../../../assets/images/icons/info-icon.svg";
import MainTextField from "../../../../components/core-components/inputs/TextField/MainTextField";
import CustomSwitch from "../../../../components/shared-components/CustomSwitch";
import MobileHeader from "../../../../components/shared-components/MobileHeader";
import { useNavigate } from "react-router-dom";
import CustomMobileSelectMenu from "../../../../components/core-components/inputs/Select/CustomMobileSelectMenu";
import CustomModalMobile from "../../../../components/shared-components/CustomModalMobile";
import CustomButton from "../../../../components/shared-components/CustomButton";

const EarlyCheckin = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isSwitchChecked, setIsSwitchChecked] = useState(true);
  const [isSameSwitchChecked, setIsSameSwitchChecked] = useState(false);
  const [maxFlexibilityWindow, setMaxFlexibilityWindow] = useState("");
  const [cleaningTurnaround, setCleaningTurnaround] = useState("");
  const [paymentUrl, setPaymentUrl] = useState("");
  const [discountValue, setDiscountValue] = useState("");
  const [valueSelect, setValueSelect] = useState("");
  const [freeHours, setFreeHours] = useState("");
  const [copilotSelect, setCopilotSelect] = useState("");
  const [selectedPropertyId, setSelectedPropertyId] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openMobileModal, setOpenMobileModal] = useState(false);
  const [selectedListings, setSelectedListings] = useState<number[]>([]);
  const [addedProperties, setAddedProperties] = useState<any[]>([]);
  const [maxFlexibilityWindowError, setMaxFlexibilityWindowError] = useState<
    string | null
  >(null);
  const [cleaningTurnaroundError, setCleaningTurnaroundError] = useState<
    string | null
  >(null);
  const [paymentUrlError, setPaymentUrlError] = useState<string | null>(null);
  const [discountValueError, setDiscountValueError] = useState<string | null>(
    null
  );
  const [freeHoursError, setFreeHoursError] = useState<string | null>(null);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [valueSelectError, setValueSelectError] = useState<string | null>(null);
  const [copilotSelectError, setCopilotSelectError] = useState<string | null>(
    null
  );
  const [isMaxFlexibilityTouched, setIsMaxFlexibilityTouched] = useState(false);
  const [isCleaningTurnaroundTouched, setIsCleaningTurnaroundTouched] =
    useState(false);
  const [isPaymentUrlTouched, setIsPaymentUrlTouched] = useState(false);
  const [isDiscountValueTouched, setIsDiscountValueTouched] = useState(false);
  const [isFreeHoursTouched, setIsFreeHoursTouched] = useState(false);
  const handleBlur = (field: string) => {
    switch (field) {
      case "maxFlexibility":
        setIsMaxFlexibilityTouched(true);
        break;
      case "cleaningTurnaround":
        setIsCleaningTurnaroundTouched(true);
        break;
      case "paymentUrl":
        setIsPaymentUrlTouched(true);
        break;
      case "discountValue":
        setIsDiscountValueTouched(true);
        break;
      case "freeHours":
        setIsFreeHoursTouched(true);
        break;
      //   case "valueSelect":
      //     setIsValueSelectTouched(true);
      //     break;
      //   case "copilotSelect":
      //     setIsCopilotSelectTouched(true);
      //    break;
      default:
        break;
    }
    validateFields();
  };
  const handleOpenModal = () => {
    //setIsInitialLoad(false);
    const hasError = validateFields();
    if (!hasError) {
      setOpenModal(true);
    }
  };
  const handleOpenMobileModal = () => {
    //setIsInitialLoad(false);
    const hasError = validateFields();
    if (!hasError) {
      setOpenMobileModal(true);
    }
  };
  const handleCloseModal = () => setOpenModal(false);
  const handleCloseMobileModal = () => setOpenMobileModal(false);
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate("/menu");
  };



  const validateFields = React.useCallback((skipSaveAllValidation = false): boolean => {
    if (skipSaveAllValidation && addedProperties.length > 0) {
      setIsSaveDisabled(false);
      return false;
    }

    let hasError = false;

    if (
      !maxFlexibilityWindow ||
      isNaN(Number(maxFlexibilityWindow)) ||
      Number(maxFlexibilityWindow) <= 0
    ) {
      setMaxFlexibilityWindowError(
        "Max flexibility window is required and must be a valid number."
      );
      hasError = true;
    } else {
      setMaxFlexibilityWindowError(null);
    }

    if (
      isSameSwitchChecked &&
      (!cleaningTurnaround ||
        isNaN(Number(cleaningTurnaround)) ||
        Number(cleaningTurnaround) <= 0)
    ) {
      setCleaningTurnaroundError(
        "Cleaning turnaround is required and must be a valid number."
      );
      hasError = true;
    } else {
      setCleaningTurnaroundError(null);
    }

    if (!paymentUrl) {
      setPaymentUrlError("Payment URL is required.");
      hasError = true;
    } else {
      setPaymentUrlError(null);
    }

    if (
      !discountValue ||
      isNaN(Number(discountValue)) ||
      Number(discountValue) <= 0
    ) {
      setDiscountValueError(
        "Discount value is required and must be a valid number."
      );
      hasError = true;
    } else {
      setDiscountValueError(null);
    }

    if (!freeHours || isNaN(Number(freeHours)) || Number(freeHours) <= 0) {
      setFreeHoursError("Free hours are required and must be a valid number.");
      hasError = true;
    } else {
      setFreeHoursError(null);
    }

    if (!valueSelect) {
      setValueSelectError("Value select is required.");
      hasError = true;
    } else {
      setValueSelectError(null);
    }

    if (!copilotSelect) {
      setCopilotSelectError("Copilot select is required.");
      hasError = true;
    } else {
      setCopilotSelectError(null);
    }

    setIsSaveDisabled(
      hasError || (addedProperties.length === 0 && skipSaveAllValidation)
    );
    return hasError;
  }, [
    addedProperties.length,
    isSameSwitchChecked,
    maxFlexibilityWindow,
    cleaningTurnaround,
    paymentUrl,
    discountValue,
    freeHours,
    valueSelect,
    copilotSelect,
    setMaxFlexibilityWindowError,
    setCleaningTurnaroundError,
    setPaymentUrlError,
    setDiscountValueError,
    setFreeHoursError,
    setValueSelectError,
    setCopilotSelectError,
    setIsSaveDisabled
  ]);

  const handleAddProperty = () => {
    if (selectedPropertyId) {
      updateProperty(selectedPropertyId, selectedListings);
      resetFormAndCloseModal();
      return;
    }

    const hasError = validateFields();
    if (hasError) {
      return;
    }

    const newProperty = {
      id: Date.now(),
      listingIds: selectedListings,
      maxFlexibilityWindow,
      cleaningTurnaround,
      paymentUrl,
      discountValueTag:
        valueSelect === "percentage"
          ? `${discountValue}% value`
          : `${discountValue} fixed price`,
      valueSelect,
      freeHours,
      copilotSelect,
    };
    setAddedProperties((prevProperties) => [...prevProperties, newProperty]);

    resetFormAndCloseModal();
  };

  const updateProperty = (id: string, updatedListings: number[]) => {
    setAddedProperties((prevProperties) =>
      prevProperties.map((property) =>
        property.id === id
          ? {
            ...property,
            listingIds: updatedListings,
          }
          : property
      )
    );

    setSelectedPropertyId("");
    setSelectedListings([]);
  };

  const resetFormAndCloseModal = () => {
    setMaxFlexibilityWindow("");
    setCleaningTurnaround("");
    setPaymentUrl("");
    setDiscountValue("");
    setValueSelect("");
    setFreeHours("");
    setCopilotSelect("");
    setSelectedListings([]);
    setOpenModal(false);
    setOpenMobileModal(false);
    setSelectedPropertyId("");
  };

  useEffect(() => {
    const hasError = validateFields(true);
    setIsSaveDisabled(hasError);
  }, [
    maxFlexibilityWindow,
    cleaningTurnaround,
    paymentUrl,
    discountValue,
    freeHours,
    isSameSwitchChecked,
    valueSelect,
    copilotSelect,
    addedProperties,
    validateFields
  ]);

  const copilotOptions: Option[] = [
    { value: "Manual", label: "Manual" },
    { value: "Semi-auto", label: "Semi-auto" },
    { value: "Automatic", label: "Automatic" },
  ];
  const stringCopilotOptions = copilotOptions.map((option) => ({
    ...option,
    value: String(option.value),
  }));
  const valueOptions: any[] = [
    { value: "percentage", label: "Percentage of Nightly Price" },
    { value: "fixed", label: "Fixed Amount" },
  ];

  const getLabel = () => {
    switch (valueSelect) {
      case "percentage":
        return "Percentage Value (%)";
      case "fixed":
        return "Fixed Price Value";
      default:
        return "Percentage Value (%)";
    }
  };

  return (
    <Stack
      width={"100%"}
      className={
        !isMobile
          ? "acc-users-box-multiple-title"
          : "acc-users-box-mobile-multiple-title"
      }
      pb={20}
      gap={3}
    >
      {isMobile && (
        <MobileHeader
          title="Early Check-in setting"
          description={
            "Customize your early check-in offers for guests based on availability. Use these settings to automatically notify guests about early check-in options, whether there is a same-day checkout or not. Adjust the triggers and messages to suit your property's needs."
          }
          handleBackClick={handleBackClick}
          isSwitchShown={true}
          switchChecked={isSwitchChecked}
          onSwitchChange={() => {
            setIsSwitchChecked(!isSwitchChecked);
          }}
        />
      )}
      {!isMobile && (
        <Stack
          justifyContent="flex-start"
          alignSelf={"flex-start"}
          alignItems="center"
          width={"100%"}
          maxWidth={isMobile ? "100%" : "812px"}
        >
          <HeaderTitle
            title="Early Check-in setting"
            subtitle="Customize your early check-in offers for guests based on availability. Use these settings to automatically notify guests about early check-in options, whether there is a same-day checkout or not. Adjust the triggers and messages to suit your property's needs."
            isSwitchShown={true}
            switchChecked={isSwitchChecked}
            onSwitchChange={() => {
              setIsSwitchChecked(!isSwitchChecked);
            }}
          />
        </Stack>
      )}

      <Stack alignSelf={"flex-start"}>
        <Box
          sx={styles.communicationContainer}
          maxWidth={isMobile ? "100%" : "858px"}
        >
          <Stack sx={{ width: "100%" }} gap={"12px"}>
            <Stack direction={isMobile ? "column" : "row"} spacing={"12px"}>
              <Stack spacing={"12px"} width={"100%"}>
                <Stack direction={"row"} spacing={"4px"}>
                  <p className="labelSelect">Max Flexibility Window</p>
                  <img src={GrayInfoIcon} alt="info" />
                </Stack>
                <MainTextField
                  title={""}
                  value={maxFlexibilityWindow}
                  placeholder={"Enter a number"}
                  onBlur={() => handleBlur("maxFlexibility")}
                  onChange={(e) => {
                    setMaxFlexibilityWindow(e.target.value);
                  }}
                  type="number"
                  errorMessage={
                    isMaxFlexibilityTouched ? maxFlexibilityWindowError : null
                  }
                />
              </Stack>
              {isSameSwitchChecked && (
                <Stack spacing={"12px"} width={"100%"}>
                  <Stack direction={"row"} spacing={"4px"}>
                    <p className="labelSelect">Cleaning Turnaround</p>
                    <img src={GrayInfoIcon} alt="info" />
                  </Stack>
                  <MainTextField
                    title={""}
                    value={cleaningTurnaround}
                    onBlur={() => handleBlur("cleaningTurnaround")}
                    placeholder={"Enter a number"}
                    onChange={(e) => {
                      setCleaningTurnaround(e.target.value);
                    }}
                    type="number"
                    errorMessage={
                      isCleaningTurnaroundTouched
                        ? cleaningTurnaroundError
                        : null
                    }
                  />
                </Stack>
              )}
            </Stack>
            <Stack
              direction={"row"}
              spacing={2}
              padding={"12px 14px"}
              sx={{
                border: "1px solid #3E97FF",
                borderRadius: "8px",
                backgroundColor: "#EEF6FF",
              }}
              alignItems={"center"}
            >
              <img
                src={BlueInfoIcon}
                alt="info"
                style={{ width: "35px", height: "35px" }}
              />
              <p className="B-14-20-500" style={{ color: "#3E97FF" }}>
                If your check-out time is 11:00, then we will offer late
                check-out until{" "}
                {isSameSwitchChecked
                  ? new Date(
                    new Date().setHours(15 - Number(cleaningTurnaround), 0)
                  ).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: false,
                  })
                  : "15:00"}
                . We know your default check-out time according to each listing.
              </p>
            </Stack>
          </Stack>

          <Stack sx={{ width: "100%" }} gap={"12px"} pt={isMobile ? 0 : 2}>
            <Stack direction={"row"} spacing={"4px"}>
              <p className="labelSelect">Payment URL</p>
              <img src={GrayInfoIcon} alt="info" />
            </Stack>
            <MainTextField
              title={""}
              value={paymentUrl}
              onBlur={() => handleBlur("paymentUrl")}
              placeholder={"Enter an URL"}
              onChange={(e) => setPaymentUrl(e.target.value)}
              type="text"
              errorMessage={isPaymentUrlTouched ? paymentUrlError : null}
            />
          </Stack>

          <Stack sx={{ width: "100%" }} gap={"12px"} pt={isMobile ? 0 : 2}>
            <Stack direction={"row"} spacing={"4px"}>
              <p className="labelSelect">{getLabel()}</p>
              <img src={GrayInfoIcon} alt="info" />
            </Stack>
            <Stack
              direction={isMobile ? "column" : "row"}
              spacing={"12px"}
              width={"100%"}
            >
              <Stack sx={{ width: "100%" }}>
                <MainTextField
                  title={""}
                  value={discountValue}
                  onBlur={() => handleBlur("discountValue")}
                  placeholder={"Enter a number"}
                  onChange={(e) => setDiscountValue(e.target.value)}
                  type="number"
                  errorMessage={
                    isDiscountValueTouched ? discountValueError : null
                  }
                />
              </Stack>
              <Stack sx={{ width: "100%" }}>
                {isMobile ? (
                  <CustomMobileSelectMenu
                    title="Percentage or fixed?"
                    options={valueOptions}
                    multiple={false}
                    selectedValues={[valueSelect]}
                    onBlur={() => handleBlur("valueSelect")}
                    setSelectedValues={(
                      values: string[] | SetStateAction<string[]>
                    ) => {
                      if (Array.isArray(values)) {
                        setValueSelect(values[0]);
                      }
                    }}
                  />
                ) : (
                  <MainSelectSelection
                    options={valueOptions}
                    isFilter={true}
                    pageType="chatbot"
                    onChange={(e: any) => {
                      setValueSelect(e.target.value);
                      setValueSelectError(null);
                    }}
                    onBlur={() => handleBlur("valueSelect")}
                    value={valueSelect}
                    label={"Select"}
                    height={"50px"}
                    error={!!valueSelectError}
                  />
                )}
                {valueSelectError && (
                  <p
                    className="error-message"
                    style={{
                      margin: "3px 14px 0px",
                      height: "25px",
                      alignContent: "center",
                    }}
                  >
                    {valueSelectError}
                  </p>
                )}
              </Stack>
            </Stack>
          </Stack>

          <Stack sx={{ width: "100%" }} gap={"12px"}>
            <Stack
              direction={isMobile ? "column" : "row"}
              width={"100%"}
              gap={"12px"}
            >
              <Stack spacing={"12px"} width={"100%"}>
                <Stack direction={"row"} spacing={"4px"}>
                  <p className="labelSelect">Free Hour(s)</p>
                  <img src={GrayInfoIcon} alt="info" />
                </Stack>
                <MainTextField
                  title={""}
                  value={freeHours}
                  onBlur={() => handleBlur("freeHours")}
                  placeholder={"Enter a number"}
                  type="number"
                  onChange={(e) => setFreeHours(e.target.value)}
                  errorMessage={isFreeHoursTouched ? freeHoursError : null}
                />
              </Stack>
              <Stack spacing={"12px"} width={"100%"}>
                <Stack direction={"row"} spacing={"4px"}>
                  <p className="labelSelect">Copilot</p>
                  <img src={GrayInfoIcon} alt="info" />
                </Stack>
                {isMobile ? (
                  <CustomMobileSelectMenu
                    title="Copilot"
                    options={stringCopilotOptions}
                    multiple={false}
                    onBlur={() => handleBlur("copilotSelect")}
                    selectedValues={[copilotSelect]}
                    setSelectedValues={(
                      values: string[] | SetStateAction<string[]>
                    ) => {
                      if (Array.isArray(values)) {
                        setCopilotSelect(values[0]);
                      }
                    }}
                  />
                ) : (
                  <MainSelectSelection
                    options={copilotOptions}
                    isFilter={true}
                    pageType="chatbot"
                    onChange={(e: any) => setCopilotSelect(e.target.value)}
                    value={copilotSelect}
                    label={"Select "}
                    height={"50px"}
                    error={!!copilotSelectError}
                  />
                )}
                {copilotSelectError && (
                  <p
                    className="error-message"
                    style={{
                      margin: "3px 14px 0px",
                      height: "25px",
                      alignContent: "center",
                    }}
                  >
                    {copilotSelectError}
                  </p>
                )}
              </Stack>
            </Stack>
            <Stack
              direction={"row"}
              spacing={2}
              padding={"12px 14px"}
              sx={{
                border: "1px solid #3E97FF",
                borderRadius: "8px",
                backgroundColor: "#EEF6FF",
              }}
              alignItems={"center"}
            >
              <img
                src={BlueInfoIcon}
                alt="info"
                style={{ width: "35px", height: "35px" }}
              />
              <p className="B-14-20-500" style={{ color: "#3E97FF" }}>
                You can choose 5 Listing. <br /> If your check-out time is
                11:00, then we will offer late check-out until 15:00. We know
                your default check-out time according to each listing. <br />
                Learn More
              </p>
            </Stack>
          </Stack>

          <Stack
            direction={"row"}
            spacing={1}
            pt={isMobile ? 0 : 2}
            alignItems={"center"}
          >
            <p className="B-16-16-600">
              Offer Late Check-out for Same-Day Check-ins
            </p>
            <CustomSwitch
              checked={isSameSwitchChecked}
              onChange={() => {
                setIsSameSwitchChecked(!isSameSwitchChecked);
              }}
            />
          </Stack>

          <Stack
            width={"100%"}
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            mt={isMobile ? 0 : 2}
            p={"8px 12px"}
            sx={{
              borderRadius: "6px",
              border: "1px solid  #E1E3EA",
              background: "var(--white-dark-white, #FFF)",
            }}
          >
            <p className="labelSelect">Choose listing responsible</p>
            <p
              onClick={isMobile ? handleOpenMobileModal : handleOpenModal}
              className="add-listing"
            >
              <span style={{ fontSize: "20px" }}>+</span> Add Listing
            </p>
          </Stack>

          {addedProperties.length > 0 && (
            <Stack width={"100%"} pt={"24px"} borderTop={"1px solid #E1E3EA"}>
              <p className="properties-title">Properties</p>
            </Stack>
          )}
          <CustomModal
            open={openModal}
            handleClose={handleCloseModal}
            handleSave={() => { }}
            title="Choose listings"
            showFooter={false}
          >
            <ListingModal
              handleAddProperty={handleAddProperty}
              selectedListings={selectedListings}
            />
          </CustomModal>
          <CustomModalMobile
            open={openMobileModal}
            handleClose={handleCloseMobileModal}
            title="Add Listing"
            showFooter={false}
            handleSave={() => { }}
          >
            <ListingModal
              handleAddProperty={handleAddProperty}
              selectedListings={selectedListings}
            />
          </CustomModalMobile>
          {addedProperties.map((property) => (
            <Box sx={styles.innerPropertiesContainer} key={property.id}>
              <Stack
                flexDirection={"row"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                gap={"16px"}
              >
                <Stack
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  flexDirection={"row"}
                >
                  <img
                    src={iconbutton}
                    style={{
                      marginRight: "8px",
                    }}
                    alt="icon"
                  />
                  <p>{property.listingIds.length} listings </p>
                </Stack>
                <Stack gap={"8px"} flexDirection={"row"} alignItems={"center"}>
                  <p className="property-tag">{property.discountValueTag}</p>
                  <p className="property-tag">
                    Max. {property.freeHours} hour(s)
                  </p>
                  <p className="property-tag">{property.copilotSelect}</p>
                </Stack>
              </Stack>
              <Box>
                <Button
                  onClick={() => {
                    setSelectedListings(property.listingIds);
                    setSelectedPropertyId(property.id);
                    isMobile ? setOpenMobileModal(true) : setOpenModal(true);
                  }}
                >
                  Remove / Add
                </Button>
              </Box>
            </Box>
          ))}
        </Box>
        <Stack justifyContent={"flex-end"} width={"100%"} py={5}>
          <CustomButton
            backgroundColor="#0065E0"
            color="#FFFFFF"
            buttonText="Save All"
            marginTop={0}
            onClick={() => { }}
            disable={isSaveDisabled}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default EarlyCheckin;
