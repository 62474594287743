import React from 'react'
import { axiosInterceptor, axiosInterceptorResponse } from '../utils/axiosInterceptor';

type Props = React.PropsWithChildren<{}>;

const BooklyAxiosContainer = (props: Props) => {

    React.useEffect(() => {
        axiosInterceptor()
        axiosInterceptorResponse()
    }, [])

    return (
        <div>
            {props.children}
        </div>
    )
}

export default BooklyAxiosContainer
