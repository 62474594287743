import React from "react";

interface Props {
  loading: boolean;
  component: React.ReactNode;
  children: React.ReactNode;
}

function LoadingWrapper(props: Props) {
  const { loading, children, component } = props;
  return <>{loading ? component : children}</>;
}

export default LoadingWrapper;
