import { Flex, Title, Text, Box } from "@mantine/core";
import { useDispatch } from "react-redux";
import ArrowsRight from "../../../../assets/images/icons/arrows-right.svg";
import OnboardingImage from "../../../../assets/images/Mobile login-pana 1.svg";
import { setCurrentStep } from "../../../../store/slices/onboardSlice";

const WelcomePage = () => {
  const dispatch = useDispatch()
  return (
    <Flex direction="column" justify="center" gap={20} align="center" h="calc(100vh - (87px + 97px))">
      <Flex direction="column" align="center">
        <Title order={1} fw={600} >Connect Your Property Listings</Title>
        <Text size="lg" fw={500} c="dimmed">
          Seamlessly integrate with your existing property management systems.
        </Text>
      </Flex>
      <img alt="onboard" className="onboardingImage" src={OnboardingImage} />
      <button
        className="buttonLg"
        aria-label="add listing"
        onClick={() => { dispatch(setCurrentStep(2)) }}
      >
        <Box className="button-icon">
          <img src={ArrowsRight} alt="arrows" />
        </Box>
        <p className="buttonText B-16-16-600">Start Now</p>
      </button>
    </Flex>
  );
}

export default WelcomePage;
