import { Route, Routes } from "react-router-dom";
import Listing from "../views/private/listing/Listing";
import Detail from "../views/private/listing/detail";
import PreviewListing from "../views/private/listing/detail/components/preview-listing/PreviewListing";

function ListingLayout() {
  return (
    <Routes>
      <Route path="/" element={<Listing />} />
      <Route path={"/preview/:id"} element={<PreviewListing />} />
      <Route path="/:id/*" element={<Detail />} />
    </Routes>
  );
}

export default ListingLayout;
