import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Image31 from "../../../../../../assets/images/image31.png";
import ArrowDownSvg from "../../../../../../assets/images/icons/linear-arrow-down.svg";
import { menuDatas } from "./data";
import { Link, useLocation } from "react-router-dom";

interface Props {
  subMenu: string;
  setSubMenu: React.Dispatch<React.SetStateAction<string>>;
}
function Menu(props: Props) {
  const { subMenu, setSubMenu } = props;
  const location = useLocation();

  const [activeSection, setActiveSection] = useState<string>("");

  const sectionsRef = useRef<{ [key: string]: Element }>({});

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5,
      }
    );

    menuDatas(subMenu).forEach((menuData) => {
      menuData.list.forEach((item) => {
        const id = item.title
          .toLowerCase()
          .split(/[\s/]+/)
          .join("-");
        const element = document.getElementById(id);
        if (element) {
          sectionsRef.current[id] = element;
          observer.observe(element);
        }
      });
    });

    return () => {
      if (sectionsRef.current) {
        Object.values(sectionsRef.current).forEach((element) => {
          observer.unobserve(element);
        });
      }
    };
  }, [location?.pathname, subMenu]);

  return (
    <>
      <Box className="listing-details-route-box">
        <Box className="selected-listing-box">
          <Box className="selected-listing-box-details">
            <img src={Image31} alt="listing" />
            <Box className="selected-listing-box-details-text">
              <p className="selected-listing-box-details-text-title B-16-16-600">
                Tekgul Apart
              </p>
              <p className="selected-listing-box-details-text-subtitle B-14-14-500">
                Size: 87KB
              </p>
            </Box>
          </Box>
          <button className="selected-listing-box-icon">
            <img src={ArrowDownSvg} alt="listing" />
          </button>
        </Box>
        <Box className="listing-details-links-box">
          {React.Children.toArray(
            menuDatas(subMenu).map((menuData) => (
              <>
                <Stack
                  gap={"12px"}
                  direction={"row"}
                  alignItems={"center"}
                  onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                    setSubMenu("");
                  }}
                >
                  {subMenu && (
                    <img
                      src={ArrowDownSvg}
                      alt=""
                      style={{
                        width: "20px",
                        height: "20px",
                        transition: "transform 0.3s",

                        transform: "rotate(90deg)",
                      }}
                    />
                  )}
                  <Typography
                    component={Link}
                    to={menuData.url}
                    onClick={() => {
                      window.scrollTo({ top: 0, behavior: "smooth" });
                    }}
                    className="listing-details-links-titles"
                  >
                    <p className="listing-details-links-title B-16-16-600">
                      {menuData.title}
                    </p>
                  </Typography>
                </Stack>

                {location.pathname.includes(menuData.url) && (
                  <Box className="listing-details-subitems-box">
                    {React.Children.toArray(
                      menuData.list.map((item) => (
                        <Typography
                          className={`listing-details-subitem-item ${activeSection ===
                            item.title
                              .toLowerCase()
                              .split(/[\s/]+/)
                              .join("-")
                            ? "active"
                            : ""
                            }`}
                          component={"a"}
                          href={`#${item.url}`}
                          sx={{
                            "&.active": {
                              background: "#EBF5FF",
                            },
                            "&.active span": {
                              width: "6px",
                              height: "100%",
                              background: "var(--blue)",
                              position: "absolute",
                              left: "-3px",
                              borderRadius: "8px",
                            },
                          }}
                        >
                          <p className="listing-details-subitem-item-title">
                            {item.title}
                          </p>
                          <Typography component="span" />
                        </Typography>
                      ))
                    )}
                  </Box>
                )}
              </>
            ))
          )}
        </Box>
      </Box>
    </>
  );
}

export default Menu;
