import GuestSafety from "./guest";
import Kitchens from "./kitchens";
import LivingDiningRoom from "./living-dining-room";
import Miscellaneous from "./miscellaneous";
import Outdoor from "./outdoor";

function OtherEssentials() {
  return (
    <>
      <GuestSafety />
      <Kitchens />
      <LivingDiningRoom />
      <Outdoor />
      <Miscellaneous />
    </>
  );
}

export default OtherEssentials;
