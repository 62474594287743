import { FormControl, RadioGroup, Stack } from "@mui/material";
import React from "react";
import CustomRadioButton from "../../../../../../../../components/core-components/inputs/RadioButton/CustomRadioButton";
import DetailsContentTitle from "../../../../../../../../components/shared-components/DetailsContentTitle";

interface dataTypes {
  label: string | JSX.Element;
  value: string;
}

function WheelChairAccess() {
  const data: dataTypes[] = [
    {
      label: "Full access for wheelchairs",
      value: "yes",
    },
    {
      label: "No access for wheelchairs",
      value: "no",
    },
  ];
  const [value, setValue] = React.useState("no");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <Stack sx={{ width: "100%" }} spacing={2}>
      <DetailsContentTitle
          title={"Wheelchair access"}
        />
      <FormControl fullWidth component="fieldset">
        <RadioGroup
          aria-label="listing-status"
          name="listing-status"
          value={value}
          onChange={handleChange}
        >
          {data.map((item) => (
            <CustomRadioButton label={item.label} value={item.value} />
          ))}
        </RadioGroup>
      </FormControl>
    </Stack>
  );
}

export default WheelChairAccess;
