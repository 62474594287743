import { Stack } from "@mui/material";
import React from "react";
import DetailsContentTitle from "../../../../../../../../components/shared-components/DetailsContentTitle";
import MainSelectSelection from "../../../../../../../../components/core-components/inputs/Select/MainSelectSelection";
import { useTranslation } from "react-i18next";

function CheckInContactPerson() {
  const { t } = useTranslation();
  return (
    <>
      <Stack
        sx={{ width: "100%", maxWidth: "463px" }}
        component={"form"}
        spacing={2}
      >
        <DetailsContentTitle
          title={t("detailsPages.policies.checkInContactPerson")}
        />
        <Stack spacing={1} sx={{ width: "100%" }}>
          <MainSelectSelection
            value=""
            onChange={() => {}}
            label={"Options"}
            options={[
              {
                value: "1",
                label: `1`,
              },
              {
                value: "2",
                label: `2`,
              },
            ]}
          />
        </Stack>
      </Stack>
    </>
  );
}

export default CheckInContactPerson;
