import DetailsInputArea from "../../../../../../../../components/shared-components/DetailsInputArea";
import SeasonalRate from "../modals/SeasonalRate";
import { useTranslation } from "react-i18next";

function SeasonalRates() {
  const { t } = useTranslation();
  const data = [
    {
      title: t("detailsPages.pricing.seasonalRates"),
      subtitle: t("detailsPages.pricing.notSet"),
      component: <SeasonalRate />,
      isSelect: false,
    },
  ];

  return (
    <>
      <DetailsInputArea
        title={t("detailsPages.pricing.seasonalRates")}
        data={data}
        onClick={() => {}}
        id={"seasonal-rates"}
      />
    </>
  );
}

export default SeasonalRates;
