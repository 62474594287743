import { useEffect, useState } from "react";
import "./inbox.scss";
import ReservationDetailsCard from "./reservationDetails/ReservationDetailsCard";
import ChatCard from "./chat/ChatCard";
import MessagesCard from "./messages/MessagesCard";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "../../../store/store";
import { useSelector } from "react-redux";
import LoadingDots from "../../../components/shared-components/loadings/LoadingDots";


function Inbox() {
  //const [isOpen, setIsOpen] = useState(true);
  //const [currentQueryParameters, setSearchParams] = useSearchParams();
  // const queryParams = new URLSearchParams(currentQueryParameters);
  const { user } = useAuth0()
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const userDetails = useSelector((state: RootState) => state.global.userDetails)

  const {
    reservationLoading,
    bookingId
  } = useSelector((state: RootState) => state.chat)

  const currentBooking = useSelector((state: RootState) => state.chat.reservationCache[bookingId] ?? null)

  useEffect(() => {
    if (user && user.email) {
      if (!localStorage.getItem("email")) {
        localStorage.setItem("email", user.email)
      }
    }
  }, [user])


  useEffect(() => {
    if (userDetails && !userDetails.customerId) {
      navigate("/onboarding")
    }
    setLoading(false)
  }, [userDetails])
  // const handleBackClick = () => {
  //   queryParams.delete("inboxId");
  //   setSearchParams(queryParams);
  //   setIsOpen(true);
  // };

  //return status ? (
  //<InboxPageLoading />
  //) : (

  // {isMobile ? (
  //          isOpen ? (
  //            <MessagesCard />
  //          ) : (
  //            <>
  //              <ChatCard handleBackClick={handleBackClick} handleOpenModal={handleOpenModal} />
  //              <CustomModalMobile
  //                key={`modal-${currentBooking?.id ?? "default"}`}
  //                showFooter={false}
  //                handleClose={handleCloseModal}
  //                handleSave={handleCloseModal}
  //                title="Reservation Details"
  //                open={isModalOpen}
  //              >
  //                {currentBooking && isOpen && (
  //                  <ReservationDetailsCard
  //                    key={`reservation-desktop-${currentBooking.id}`}
  //                    isOpen={isOpen}
  //                    setIsOpen={setIsOpen}
  //                    reservationData={currentBooking}
  //                  />
  //                )}
  //              </CustomModalMobile>
  //            </>
  //          )
  //        ) : (
  //
  if (loading) return <div>Loading...</div>

  return (
    <Box className="inbox-main-box" >
      <Stack direction={"row"} width={"100%"} height={"100%"}>
        <MessagesCard />
        <ChatCard />
        {reservationLoading ?
          <div style={{ minWidth: "340px" }}>
            <LoadingDots />
          </div>
          :
          currentBooking && (
            <ReservationDetailsCard
              key={`reservation-desktop-${currentBooking.id}`}
              //isOpen={isOpen}
              //setIsOpen={setIsOpen}
              reservationData={currentBooking}
            />
          )
        }
      </Stack>
    </Box>
  );
}

export default Inbox;
