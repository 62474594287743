import React from "react";
import { Route, Routes } from "react-router-dom";
import Main from "../views/private/listing/detail/views/main";
import Policies from "../views/private/listing/detail/views/policies";
import Pricing from "../views/private/listing/detail/views/pricing";

interface Props {
  subMenu: string;
  setSubMenu: React.Dispatch<React.SetStateAction<string>>;
}

function ListingDetailLayout(props: Props) {
  const { setSubMenu, subMenu } = props;
  return (
    <Routes>
      <Route
        path="/"
        element={<Main setSubMenu={setSubMenu} subMenu={subMenu} />}
      />
      <Route
        path="/details"
        element={<Main setSubMenu={setSubMenu} subMenu={subMenu} />}
      />
      <Route
        path="/policies"
        element={<Policies setSubMenu={setSubMenu} subMenu={subMenu} />}
      />
      <Route path="/pricing" element={<Pricing />} />
    </Routes>
  );
}

export default ListingDetailLayout;
