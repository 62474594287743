import { Stack } from "@mui/material";
import React, { useState } from "react";
import AllPhotos from "./all-photos";
import CoverPhoto from "./cover-photo";
import Coverphoto from "../../../../../../assets/images/cover-photo.jpeg";

function PhotosPage() {
  const [coverPhoto, setCoverPhoto] = useState<string>(Coverphoto);

  return (
    <Stack spacing={6} mb={20}>
      <CoverPhoto coverPhoto={coverPhoto} setCoverPhoto={setCoverPhoto} />
      <AllPhotos setCoverPhoto={setCoverPhoto} />
    </Stack>
  );
}

export default PhotosPage;
