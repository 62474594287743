import {
  FormControl,
  RadioGroup,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import DetailsContentTitle from "../../../../../../../../components/shared-components/DetailsContentTitle";
import CustomRadioButton from "../../../../../../../../components/core-components/inputs/RadioButton/CustomRadioButton";
import { useTranslation } from "react-i18next";

interface dataTypes {
  label: string | JSX.Element;
  value: string;
  subtitle: string;
}

function CheckInAfterBooking() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const data: dataTypes[] = [
    {
      label: "Smart lock",
      subtitle: "Guests will use a code or app to open a wifi-connected lock.",
      value: "1",
    },
    {
      label: "Keypad",
      subtitle: "Guests will use a code or app to open a wifi-connected lock.",
      value: "2",
    },
    {
      label: "Lockbox",
      subtitle: "Guests will use a code or app to open a wifi-connected lock.",
      value: "3",
    },
    {
      label: "Building staff",
      subtitle: "Guests will use a code or app to open a wifi-connected lock.",
      value: "4",
    },
    {
      label: "In-person greeting",
      subtitle: "Guests will use a code or app to open a wifi-connected lock.",
      value: "5",
    },
  ];
  const [value, setValue] = React.useState("1");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <Stack sx={{ width: "100%" }} spacing={2} pb={isMobile ? 4 : 0}>
      <DetailsContentTitle
        title={t("detailsPages.policies.checkinAfterBooking")}
      />
      <FormControl fullWidth component="fieldset">
        <RadioGroup
          aria-label="listing-status"
          name="listing-status"
          value={value}
          onChange={handleChange}
        >
          {data.map((item) => (
            <CustomRadioButton
              label={item.label}
              value={item.value}
              subtitle={item.subtitle}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Stack>
  );
}

export default CheckInAfterBooking;
