import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import TickSvg from "../../../../assets/images/icons/tick.svg";

interface CustomSecondCheckboxProps {
  id: string;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  inputName?: string;
  label?: string;
}

function CustomSecondCheckbox({
  id,
  checked = false,
  onChange,
  inputName,
  label,
}: CustomSecondCheckboxProps) {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const toggleChecked = () => {
    const newChecked = !isChecked;
    setIsChecked(newChecked);
    if (onChange) {
      onChange(newChecked);
    }
  };

  return (
    <Box
      id={id}
      onClick={toggleChecked}
      sx={{
        display: "flex",
        width: "30px",
        height: "30px",
        padding: "6px 7px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        borderRadius: "7px",
        border: isChecked ? "none" : "1px solid var(--Gray-Gray-500, #A1A5B7)",
        background: isChecked ? "var(--Blue-light, #0065E0)" : "transparent",
        cursor: "pointer",
      }}
    >
      {isChecked && (
        <img
          src={TickSvg}
          alt="tick"
          style={{
            width: "14.844px",
            height: "11.875px",
            flexShrink: 0,
            fill: "var(--White, #FFF)",
          }}
        />
      )}
    </Box>
  );
}

export default CustomSecondCheckbox;
