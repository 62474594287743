import React, { useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { SyntheticEvent } from "react";
import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
} from "@mui/material/Autocomplete";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";

interface TagOption {
  label: string;
  value: string;
}

interface TagFilterProps {
  placeholder?: string;
  multiple?: boolean;
  value: TagOption[];
  onChange: (
    event: SyntheticEvent<Element, Event>,
    value: TagOption[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<TagOption>
  ) => void;
  errorMessage?: string | null;
}

const TagFilter: React.FC<TagFilterProps> = ({
  placeholder = "Etiket ara veya ekle...",
  multiple = true,
  value = [],
  onChange,
  errorMessage,
}) => {

  const { filterOptions } = useSelector((state: RootState) => state.filter);
  const [inputValue, setInputValue] = useState("");

  const handleChange = (
    event: SyntheticEvent<Element, Event>,
    value: TagOption | TagOption[] | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<TagOption>
  ) => {
    const newValue = value ? (Array.isArray(value) ? value : [value]) : [];
    onChange(event, newValue, reason, details);
  };

  return (
    <Autocomplete
      multiple={multiple}
      filterSelectedOptions
      value={value}
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      options={filterOptions.tagOptions}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      loading={false}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          sx={{
            padding: "0 !important",
            "& .MuiAutocomplete-inputRoot": {
              paddingRight: "15px !important",
              backgroundColor: "transparent",
            },
            "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
              borderColor: "#A1A5B7",
              borderRadius: "6px",
              padding: "0",
              height: "52px !important",
            },
          }}
          error={!!errorMessage}
          helperText={errorMessage}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          className="bg-white"
        />
      )}
      className="w-full"
    />
  );
};

export default TagFilter;
