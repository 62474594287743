import { Stack } from "@mui/material";
import React from "react";
import DetailsContentTitle from "../../../../../../../../components/shared-components/DetailsContentTitle";
import MainTextField from "../../../../../../../../components/core-components/inputs/TextField/MainTextField";
import HelperText from "../../../../../../../../components/shared-components/HelperText";
import { useTranslation } from "react-i18next";

function Address() {
  const { t } = useTranslation();
  return (
    <Stack
      sx={{ width: "100%", maxWidth: "560px" }}
      component={"form"}
      spacing={2}
    >
      <DetailsContentTitle
        title={t("detailsPages.policies.addressDetails")}
        info={t(
          "detailsPages.policies.exactlyAddressToYourPropertyArePrivateAndOnlySharedWithConfirmedGuests"
        )}
      />
      <Stack gap={"8px"}>
        <MainTextField
          title={""}
          placeholder={t(
            "detailsPages.policies.writeDirectionsToYourPropertyBelow"
          )}
          value=""
          onChange={() => {}}
          isMandatory={false}
          multiline={true}
          row={4}
        />
        <HelperText
          content={t("detailsPages.policies.4000CharactersAllowedNext")}
        />
      </Stack>
    </Stack>
  );
}

export default Address;
