import { Box, Stack } from "@mui/material";
import mainImage from "../../../../assets/images/steptwotwo.svg";

function FinishScreen() {
  return (
    <>
      <Stack flexDirection={"row"} alignItems={"center"} mt={4}>
        <Stack spacing={2}>
          <p className="subtitleOfStep">Finish</p>
          <p className="titleOfStep">
            Let’s invite your team and set their role
          </p>
          <p
            className="secondaryDescription"
            style={{
              maxWidth: "450px",
              wordWrap: "break-word",
              lineHeight: "1.5",
            }}
          >
            Our AI learns from your past and future conversations to be able to
            answer at 99% accuracy
          </p>
        </Stack>
        <Box>
          <img alt="main" className="onboardingImage" src={mainImage} />
        </Box>
      </Stack>
    </>
  );
}

export default FinishScreen;
