import React, { useState } from "react";
import { Stack, Box, IconButton, Snackbar, Alert } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";

interface BookingDetailsListItemProps {
  item1: string;
  item2: string;
  index: number;
  isEditable?: boolean;
  onSave?: (newDate: string) => Promise<void>;
}

const BookingDetailsListItem: React.FC<BookingDetailsListItemProps> = ({
  item1,
  item2,
  index,
  isEditable = false,
  onSave,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [displayDate, setDisplayDate] = useState(item2);
  const [editedTime, setEditedTime] = useState(
    dayjs(item2, "MM/DD/YYYY HH:mm")
  );
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );

  const handleSave = async () => {
    if (onSave) {
      const currentDate = dayjs(item2, "MM/DD/YYYY HH:mm");
      const newDateTime = currentDate
        .hour(editedTime.hour())
        .minute(editedTime.minute());

      const newDateTimeString = newDateTime.format("MM/DD/YYYY HH:mm");

      try {
        await onSave(newDateTimeString);
        setDisplayDate(newDateTimeString);
        setSnackbarMessage("Reservation time updated successfully");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setIsEditing(false);
      } catch (error) {
        console.error("Error updating date:", error);
        setEditedTime(dayjs(displayDate, "MM/DD/YYYY HH:mm"));
        setSnackbarMessage("Error updating reservation time");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    }
  };

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <>
      <Box padding="16px 0px" className="reservation-card-booking-details-list">
        <Box className="reservation-card-booking-details-list-items">
          <Box className="reservation-card-booking-details-list-item-1 B-16">
            {item1}
          </Box>
          <Stack direction="row" alignItems="center" gap={1}>
            <Box className="reservation-card-booking-details-list-item-2 B-14-20-500">
              {isEditing ? (
                <Stack direction="row" alignItems="center" gap={1}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      value={editedTime}
                      onChange={(newValue) =>
                        newValue && setEditedTime(newValue)
                      }
                      format="HH:mm"
                      ampm={false}
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "36px",
                          fontSize: "14px",
                        },
                      }}
                    />
                  </LocalizationProvider>
                  <IconButton
                    size="small"
                    onClick={handleSave}
                    sx={{ padding: "4px" }}
                  >
                    <CheckIcon sx={{ fontSize: 16, color: "#0065E0" }} />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => {
                      setEditedTime(dayjs(displayDate, "MM/DD/YYYY HH:mm"));
                      setIsEditing(false);
                    }}
                    sx={{ padding: "4px" }}
                  >
                    <CloseIcon sx={{ fontSize: 16, color: "#F04438" }} />
                  </IconButton>
                </Stack>
              ) : (
                <Stack direction="row" alignItems="center" spacing={1}>
                  <span>{displayDate}</span>
                  {isEditable && (
                    <IconButton
                      size="small"
                      onClick={() => setIsEditing(true)}
                      sx={{
                        padding: "4px",
                        "&:hover": {
                          backgroundColor: "rgba(0, 101, 224, 0.04)",
                        },
                      }}
                    >
                      <EditIcon sx={{ fontSize: 16, color: "#98A2B3" }} />
                    </IconButton>
                  )}
                </Stack>
              )}
            </Box>
          </Stack>
        </Box>
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        sx={{
          position: "fixed",
          zIndex: 9999,
        }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          elevation={6}
          sx={{
            width: "100%",
            backgroundColor:
              snackbarSeverity === "success" ? "#16B364" : "#F04438",
            color: "white",
            ".MuiAlert-icon": {
              color: "white",
            },
            ".MuiAlert-action": {
              color: "white",
            },
            "& .MuiAlert-message": {
              fontSize: "14px",
              fontWeight: 500,
            },
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default BookingDetailsListItem;
