import { useState, useEffect } from "react";
import "./onboardingTwoStyles.scss";
import "./OnboardingFlow.scss"
import arrowOne from "../../../assets/images/icons/arrows (1).svg";
import arrowTwo from "../../../assets/images/icons/arrows3.svg";
import { LinearProgress } from "@mui/material";
import ConnectPropertyListing from "./step1/ConnectPropertyListing";
import GetStarted from "./step1/GetStarted";
import LandingScreen from "./step2/LandingScreen";
import SuccessfullyImported from "./step1/SuccessfullyImported";
import ReviewImportedListings from "./step1/ReviewImportedListings";
import { useNavigate } from "react-router-dom";
import Step3 from "./step3/Step3";
import InviteTeamMember from "./step3/InviteTeamMember";
import FinishScreen from "./step3/FinishScreen";
import Chatbot from "./step2/Chatbot";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { useDispatch } from "react-redux";
import { flushOnboardState, initializeSignalR, postPropertyDetails, postReservationDetails, postSelectedPropertyList, setCurrentStep, setProgressCount } from "../../../store/slices/onboardSlice";
import { getNewRefreshToken } from "../../../store/slices/globalSlice";
import SyncProperties from "./step1/SyncProperties";
import WelcomePage from "./step1/WelcomePage";

const OnboardingFlow = () => {

  const {
    currentStep: step,
    stepsComplete,
    connectionStarted,
  } = useSelector((state: RootState) => state.onboard)

  const {
    accessToken
  } = useSelector((state: RootState) => state.global)
  const dispatch: AppDispatch = useDispatch()
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const initializeData = async () => {
      const accessToken = await getAccessTokenSilently();
      await dispatch(initializeSignalR(accessToken));
    };

    if (!connectionStarted) {
      initializeData();
    }

  }, [connectionStarted]);

  useEffect(() => {

  }, [])

  useEffect(() => {
    const getToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        localStorage.setItem("accessToken", token);
      } catch (error) {
        console.error("Token Error:", error);
      }
    };

    getToken();
  }, [getAccessTokenSilently]);

  const handleRefreshToken = async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      await dispatch(getNewRefreshToken(accessToken))
    } catch (error) {
      console.error("Token Error", error);
      return null;
    }
  };

  const handleGetstarted = async () => {
    dispatch(setCurrentStep(step + 1))
    await handleRefreshToken();
    dispatch(setProgressCount(10));
    try {
      dispatch(postPropertyDetails({ accessToken }))
      dispatch(setProgressCount(20));
    } catch (error) {
      throw error;
    }
  }

  const handleNext = async () => {
    try {
      if (step === 3) {
        handleGetstarted()
      } else if (step === 5) {
        dispatch(postReservationDetails({ accessToken }))
      } else if (step === 7) {
        dispatch(postSelectedPropertyList({ accessToken }))
      }

    } catch (error) {
      if (step < 11) {
        dispatch(setCurrentStep(step + 1))
      } else {
        navigate("/");
      }
    }

    if (step < 11) {
      dispatch(setCurrentStep(step + 1))
    } else {
      dispatch(flushOnboardState())
      dispatch(setCurrentStep(1))
      navigate("/");
    }

  };

  const handleBack = () => {
    if (step > 1) {
      dispatch(setCurrentStep(step - 1))
    }
  };

  const handleSelectionChangeStep12 = (selectedIds: any[]) => {
    if (selectedIds.length >= 1) {
    } else {
      alert("You must select at least one row.");
    }
  };

  const [chatbotValue, setChatbotValue] = useState("no");


  const setAddedProperties = () => {

  }
  const validateForm = () => {
    // if (chatbotValue === "yes") {
    //   setIsNextDisabled(addedProperties.length === 0);
    // } else {
    //   setIsNextDisabled(false);
    // }
  };

  const barSx = {
    width: "calc(100% / 3)",
    height: "8px",
    flexShrink: "0",
    borderRadius: "5px",
    background: "#CCE3FD",
  }

  return (
    <>
      <div className="onboarding-main">
        <div className="onboarding-wrapper">
          {step === 1 && <WelcomePage />}
          {step === 2 && <ConnectPropertyListing />}
          {step === 3 && <GetStarted handleGetStarted={handleGetstarted} />}
          {step === 4 && <SyncProperties />}
          {step === 5 && <SuccessfullyImported />}
          {step === 6 && <ReviewImportedListings />}
          {step === 7 && <LandingScreen />}
          {step === 8 &&
            <Chatbot
              validateForm={validateForm}
              onAddedPropertiesChange={setAddedProperties}
              value={chatbotValue}
              setValue={setChatbotValue}
            />
          }
          {step === 9 && <Step3 />}
          {step === 10 && (
            <InviteTeamMember handleSelectionChange={handleSelectionChangeStep12} />
          )}
          {step === 11 && <FinishScreen />}
        </div>
      </div>
      {step !== 1 && (
        <div className="onboarding-bottom-box" >
          <div className="onboarding-progress-bar">
            <LinearProgress
              sx={barSx}
              variant="determinate"
              value={(step > 6 ? 100 : (step / 6) * 100)}
            />
            <LinearProgress
              sx={barSx}
              variant="determinate"
              value={step > 6 && step <= 8 ? ((step - 6) / 2) * 100 : step > 8
                ? 100
                : 0
              }
            />
            <LinearProgress
              sx={barSx}
              variant="determinate"
              value={step > 8 ? ((step - 8) / 3) * 100 : 0}
            />
          </div>
          <div className="onboarding-bottom-buttons">
            <button
              className={`button-onboarding ${step < 6 ? "disabled" : ""}`}
              onClick={handleBack}
              disabled={step < 6}
            >
              <img src={arrowOne} alt="arrow-back" className="button-icon" />
              <span className="buttonText B-16-16-600">Back</span>
            </button>
            <button
              className={`button-onboarding next ${!stepsComplete[step] ? "disabled" : ""}`}
              onClick={handleNext}
              disabled={!stepsComplete[step]}
            >
              <span className="buttonText B-16-16-600">
                {step < 11 ? "Next" : "Finish and go playground"}
              </span>
              <img src={arrowTwo} alt="arrow-back" className="button-icon" />
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default OnboardingFlow;
