import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AUDIENCE_API_URL } from "../../api";
import { getNewRefreshTokenApi } from "../../api/globalApi";
import { decodeJWT } from "../../utils/helpers";
import { initializeSignalR } from "./onboardSlice";

interface UserJWTDetails {
  azp: string;
  customerId?: string;
  customerTitle: string;
  email: string;
  email_verified: boolean;
  exp: number;
  family_name: string;
  given_name: string;
  iat: number;
  iss: string;
  name: string;
  permissions: string[];
  scope: string;
  sub: string;
  usergroup: string;
}

export interface GlobalState {
  userDetails: UserJWTDetails | null
  accessToken: string
}

const initialState: GlobalState = {
  userDetails: null,
  accessToken: ""
}

export const getNewRefreshToken = createAsyncThunk<
  { newRefreshToken: string, newAccessToken: string, decodedToken: any, authKey: string },
  string,
  { rejectValue: string, dispatch: any }
>
  (
    "global/getNewRefreshToken",
    async (
      accessToken: string,
      { rejectWithValue, dispatch }
    ) => {
      try {
        const authKey = Object.keys(localStorage).find(
          (key) =>
            key.startsWith("@@auth0spajs@@") &&
            key.includes(AUDIENCE_API_URL ?? "")
        );

        if (!authKey) {
          throw new Error("Auth key not found")
        }

        const authData = JSON.parse(localStorage.getItem(authKey) || "{}");
        const refreshToken = authData?.body?.refresh_token;


        if (!refreshToken) {
          throw new Error("Refresh token not found")
        }

        const response = await getNewRefreshTokenApi({ accessToken, refreshToken })
        const newAccessToken = response.data.accessToken;
        const newRefreshToken = response.data.refreshToken;
        const decodedToken = await decodeJWT(newAccessToken)

        console.log(decodedToken, newAccessToken)
        dispatch(initializeSignalR(newAccessToken))
        return { newRefreshToken, newAccessToken, decodedToken, authKey }
      } catch (error: any) {
        return rejectWithValue(error.message);
      }
    })


const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    decodeAndSaveUserJWT: (state, action: PayloadAction<UserJWTDetails>) => {
      state.userDetails = action.payload
    },
    clearUserToken: (state) => {
      state.userDetails = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNewRefreshToken.fulfilled, (state, action) => {
        const {
          newRefreshToken,
          newAccessToken,
          decodedToken,
          authKey
        } = action.payload

        console.log(decodedToken)
        state.accessToken = newAccessToken
        state.userDetails = decodedToken

        localStorage.setItem("accessToken", newAccessToken);
        localStorage.setItem("accessToken_NEW", newAccessToken);


        if (authKey) {
          const auth0Data = JSON.parse(localStorage.getItem(authKey) || "{}");

          if (auth0Data.body) {
            auth0Data.body = {
              ...auth0Data.body,
              access_token: newAccessToken,
              refresh_token: newRefreshToken,
            };

            localStorage.setItem(authKey, JSON.stringify(auth0Data));


          }
        }
      })
    //.pending
    //rejected
  }
})

export const {
  decodeAndSaveUserJWT,
  clearUserToken
} = globalSlice.actions


export default globalSlice.reducer;
