import { Stack } from "@mui/material";
import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CustomDatePicker from "../../../../../../../../../components/core-components/inputs/DatePicker/CustomDatePicker";
import { useTranslation } from "react-i18next";

function DateRange() {
  const { t } = useTranslation();
  return (
    <Stack
      spacing={2}
      mt={2}
      sx={{
        paddingBottom: "24px",
        borderBottom: "1px solid var(--stroke-colors, #EBEBEB)",
      }}
    >
      <Stack maxWidth={463}>
        <p className="B-14" style={{ color: "#181C32" }}>
          {t("calendarpage.dateRange")}
        </p>
        <p className="B-12-16-400">
          {t(
            "calendarpage.theNightOfTheToDateIsExcludedRatesCanBeSyncedUpTo3YearsInTheFuture"
          )}
        </p>
      </Stack>
      <Stack
        direction="row"
        spacing={1}
        alignItems={"center"}
        sx={{ width: "100%" }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <CustomDatePicker label="Check-in" name="check_in"/>
        </LocalizationProvider>
        <p className="B-14-24-500">{t("calendarpage.to")}</p>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <CustomDatePicker label="Check-out" name="check_out"/>
        </LocalizationProvider>
      </Stack>
    </Stack>
  );
}

export default DateRange;
