import "./settings.scss";
import AccountSettingsListBox from "./components/AccountSettingsListBox";
import SettingsLayout from "../../../layouts/SettingsLayout";
import { Flex } from "@mantine/core";

function Settings() {

  return (
    <Flex
      align="flex-start"
      w="100%"
      h="calc(100vh - 77px)"
    >
      <AccountSettingsListBox />
      <Flex
        w="100%"
        h="100%"
        style={{ overflow: "auto" }}
      >
        <SettingsLayout />
      </Flex>
    </Flex>
  );
}

export default Settings;
