import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import CustomSecondCheckbox from "../../../../../../../../components/core-components/inputs/Checkbox/CustomSecondCheckbox";
import { useMediaQuery, useTheme } from "@mui/material";

interface CheckInCheckOutProps {
  title: string;
  totalBoxes: number;
  labels: string[];
  boxesPerRow: number;
}

function CheckInCheckOut({
  title,
  totalBoxes,
  labels,
  boxesPerRow,
}: CheckInCheckOutProps) {
  const rows = Math.ceil(totalBoxes / boxesPerRow);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Stack sx={{ width: "100%" }} mb={isMobile ? 4 : 0} spacing={4}>
      <p className="B-14-22-700">{title}</p>
      <Stack sx={{ width: "100%" }} spacing={isMobile ? 2 : 3}>
        {[...Array(rows)].map((_, rowIndex) => (
          <Stack
            key={rowIndex}
            direction={isMobile ? "column" : "row"}
            spacing={isMobile ? 2 : 6}
          >
            {" "}
            {[...Array(boxesPerRow)].map((_, colIndex) => {
              const boxIndex = rowIndex * boxesPerRow + colIndex;
              return boxIndex < totalBoxes ? (
                <Box
                  key={`${rowIndex}-${colIndex}`}
                  className="check-in-box-checkbox-frame B-14-24-500"
                >
                  <Box className="check-in-box-checkbox-box">
                    <CustomSecondCheckbox id={`checkbox-${boxIndex}`} />
                    <label
                      htmlFor={`checkbox-${boxIndex}`}
                      className="check-in-box-checkbox-label B-14-24-500"
                    >
                      {labels[boxIndex]}
                    </label>
                  </Box>
                </Box>
              ) : null;
            })}
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
}

export default CheckInCheckOut;
