import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FetchAllProperties, fetchAllPropertiesApi, fetchPropertyTagsApi, FetchPropertyTagsParams, postPropertyDetailsApi, postReservationDetailsApi, postSelectedPropertyListApi } from "../../api/onboardApi";
import { BASE_SIGNAL_API } from "../../api";
import signalRService from "../../signalr/SignalRService";
import { GlobalState } from "./globalSlice";
import { toast } from "react-toastify";

interface DocumentType {
  id: string;
  url: string;
  name: string;
  tags: string[] | null;
  isImage?: boolean;
}


interface OnboardState {
  currentStep: number
  connectionStarted: boolean
  stepsComplete: {
    [key: number]: boolean
  }
  onboardingData: {
    categories: any[],
    listingItems: any[],
    channels: any[],
    getStarted: {
      accountName: string,
      clientId: string,
      clientSecret: string
    },
    selectedProviderType?: number;
  }
  progressLeft: number,
  syncProgress: number
  properties: {
    Id: string;
    listingName: string;
    nickname: string;
    typeOfUnit: string;
    city: string;
    address: string;
    tags: string[];
    status: "Active" | "Passive";
    attachDocument?: any
  }[]
  propertiesLoading: boolean
  propertyTags: {
    id: string,
    tag: string,
    relation: string
  }[]
}

const initialState: OnboardState = {
  currentStep: 1,
  connectionStarted: false,
  stepsComplete: {
    1: true,
    2: false,
    3: false,
    4: false,
    5: true,
    6: false,
    7: true,
    8: true,
    9: true,
    10: true,
    11: true,
    12: true
  },
  onboardingData: {
    categories: [],
    listingItems: [],
    channels: [],
    getStarted: {
      accountName: "",
      clientId: "",
      clientSecret: "",
    }
  },
  progressLeft: 0,
  syncProgress: 0,
  properties: [],
  propertiesLoading: false,
  propertyTags: []
}

export const initializeSignalR = createAsyncThunk(
  "onboard/initializeSignalR",
  async (token: string, { dispatch }) => {

    try {
      await signalRService.startConnection(
        BASE_SIGNAL_API
      );

      try {
        const tokenPayload = JSON.parse(atob(token.split(".")[1]));
        if (tokenPayload) {
          const customerId = tokenPayload.customerId
          const email = tokenPayload.email

          if (customerId && email) {
            const connectUser = {
              CustomerId: customerId,
              UserName: email,
            };
            await signalRService.sendMessage("ConnectClient", connectUser);
          }
        }
      } catch (error) {
        console.error("Error decoding token:", error);
      }

      signalRService.onReceiveMessage("AlreadyStartedWorkflow", (data: any) => {
        toast.warning('You already finished onboarding');
        dispatch(handleAlreadyOnboard())
      })

      signalRService.onReceiveMessage("StartPropertySync", (data: any) => {
        const parsedData = JSON.parse(data)
        if (parsedData.TotalCount && parsedData.BatchSize) {
          if (parsedData.TotalCount === 0) {
          } else {
            const progressLeft = Math.ceil(parsedData.TotalCount / parsedData.BatchSize)
            dispatch(startPropertySync({ progressLeft }))
          }
        } else {
          toast.warning('No new property were found to be added');
          dispatch(handleAlreadyOnboard())
          console.error("CANNOT FIND TOTAL COUNT / BATCH SIZE")
        }
      });

      signalRService.onReceiveMessage("EndPropertySync", () => {
        dispatch(setProgressCount(null))
      });

      return true;
    } catch (error) {
      console.error("SignalR initialization error:", error);
      return false;
    }
  }
);

export const fetchAllProperties = createAsyncThunk(
  "onboard/fetchAllProperties",
  async (params: FetchAllProperties) => {
    try {
      const response = await fetchAllPropertiesApi(params)
      return response
    } catch (error) {
      console.error("API call error:", error);
    }
  }
)

export const fetchPropertyTags = createAsyncThunk(
  "onboard/fetchPropertyTags",
  async (params: FetchPropertyTagsParams) => {
    try {
      const response = await fetchPropertyTagsApi(params)
      return response
    } catch (error) {
      console.error("API call error:", error);
    }
  }
)



export const postPropertyDetails = createAsyncThunk(
  "onboard/postPropertyDetails",
  async ({ accessToken }: { accessToken: string }, { getState }) => {
    try {
      const state = getState() as { onboard: OnboardState, global: GlobalState }
      const extraData = {
        accessToken,
        customerId: state.global.userDetails?.customerId ?? "",
        selectedProviderType: state.onboard.onboardingData.selectedProviderType ?? 0
      }
      const response = await postPropertyDetailsApi(extraData)
      return response
    } catch (error: any) {
      console.error("API call error:", error);
    }
  }
)

export const postReservationDetails = createAsyncThunk(
  "onboard/postReservationDetails",
  async ({ accessToken }: { accessToken: string }, { getState }) => {
    try {
      const state = getState() as { onboard: OnboardState, global: GlobalState }
      const extraData = {
        accessToken,
        customerId: state.global.userDetails?.customerId ?? "",
        selectedProviderType: state.onboard.onboardingData.selectedProviderType ?? 0
      }
      const response = await postReservationDetailsApi(extraData)
      return response
    } catch (error: any) {
      console.error("API call error:", error);
    }
  }
)

export const postSelectedPropertyList = createAsyncThunk(
  "onboard/postSelectedPropertyList",
  async ({ accessToken }: { accessToken: string }, { getState }) => {
    try {
      const state = getState() as { onboard: OnboardState }
      const selectedPropertyIds = state.onboard.properties.map((row) => row.Id);
      const response = await postSelectedPropertyListApi({ accessToken, selectedPropertyIds })
      return response
    } catch (error: any) {
      console.error("API ERRROR")
    }
  }
)



const onboardSlice = createSlice({
  name: 'onboard',
  initialState,
  reducers: {
    setCurrentStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload
    },
    setOnboardingData: (state, action: PayloadAction<any>) => {
      state.onboardingData = action.payload
    },
    setStepsComplete: (state, action: PayloadAction<{ key: number, status: boolean }>) => {
      const { key, status } = action.payload
      state.stepsComplete = {
        ...state.stepsComplete,
        [key]: status
      }
    },
    handleAlreadyOnboard: (state) => {
      state.currentStep = 1
    },
    startPropertySync: (state, action) => {
      const { progressLeft } = action.payload
      state.progressLeft = progressLeft
      state.syncProgress = 20
    },
    setProgressCount: (state, action) => {
      if (action.payload) {
        state.syncProgress = action.payload //NOTE: if has action it means dev will be set manually
      } else if (state.progressLeft > 0) {
        //NOTE: if no payload reducer will auto handle progress bar,
        //progressLeft = check out how many more request will be come from EndPropertySync socket
        //We already set the progressLeft, we did it in first section which StartPropertySync socket
        //We use 80 as divier cause we manullay start progress bar from 20%, for user to understand app still working

        const nextSync = state.syncProgress + Math.ceil(80 / state.progressLeft)


        if (nextSync > 100) state.syncProgress = 100
        else state.syncProgress = nextSync


        state.progressLeft -= 1


        if (state.progressLeft === 0) { //NOTE: IF NO PROGRESS LEFT FINISH
          state.stepsComplete = {
            ...state.stepsComplete,
            [state.currentStep]: true
          }
          state.syncProgress = 100
          state.currentStep = 5
        }
      }
    },
    flushOnboardState: () => {
      return initialState
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(initializeSignalR.fulfilled, (state, action) => {
        state.connectionStarted = action.payload;
      })
      .addCase(initializeSignalR.rejected, (state) => {
        state.connectionStarted = false;
      })
      .addCase(fetchAllProperties.pending, (state, action) => {
        state.propertiesLoading = true
      })
      .addCase(fetchAllProperties.fulfilled, (state, action) => {
        if (action.payload) {
          const apiData = action.payload.data.items.map(
            (property: any) => ({
              Id: property.id,
              listingName: property.name || "N/A",
              nickname: property.internalName || "N/A",
              typeOfUnit: property.typeCode || "N/A",
              city: property.city || "N/A",
              address: `${property.street}, ${property.city}, ${property.state}, ${property.zipCode}`,
              tags: property.tags && property.tags.length > 0
                ? property.tags.map((tag: any) => tag.tag)
                : ["No Tags"],
              status: property.isActive ? "Active" : "Passive",
              attachDocument:
                property.files && property.files.length > 0
                  ? property.files.map((file: DocumentType) => ({
                    name: file.name,
                    tags: file.tags || [],
                    url: file.url,
                    id: file.id,
                    isImage:
                      file.name
                        .toLowerCase()
                        .match(/\.(jpg|jpeg|png|gif|webp)$/) !== null,
                  }))
                  : [null],
            })
          );
          state.properties = apiData
        }
        state.stepsComplete[6] = true
        state.propertiesLoading = false
      })
      .addCase(fetchPropertyTags.fulfilled, (state, action) => {
        if (action.payload) {
          state.propertyTags = action.payload.data
        }
      })
  }
})

export const {
  setCurrentStep,
  setOnboardingData,
  setStepsComplete,
  startPropertySync,
  setProgressCount,
  flushOnboardState,
  handleAlreadyOnboard
} = onboardSlice.actions


export default onboardSlice.reducer;
