import React from "react";

interface CustomSwitchProps {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CustomSwitch: React.FC<CustomSwitchProps> = ({ checked, onChange }) => {
  return (
    <div
      className={checked ? "toggle-button active" : "toggle-button"}
      onClick={(event) => onChange(event as any)}
    >
      <div className={checked ? "round-part active" : "round-part"}></div>
    </div>
  );
};

export default CustomSwitch;
