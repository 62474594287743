import React, { useEffect, useState } from "react";
import { GridColDef, GridRowId, GridRowSelectionModel } from "@mui/x-data-grid";
import {
  Chip,
  FormControlLabel,
  Stack,
  Switch,
  useTheme,
} from "@mui/material";
import "./listing.scss";
import ReservationImage from "../../../assets/images/reservation-img-1.jpeg";
import CustomModal from "../../../components/shared-components/CustomModal";
import GridData from "../../../components/shared-components/GridData";
import { useTranslation } from "react-i18next";
import ListingStatus from "./detail/components/modals/ListingStatus";
import DatagridPageLoading from "../../../components/shared-components/loadings/DatagridPageLoading";
import { useMediaQuery } from "@mui/material";
import MessagesSearchTextfield from "../../../components/core-components/inputs/TextField/MessagesSearchTextfield";
import MobileGridData from "../../../components/shared-components/MobileGridData";
import { Box } from "@mui/material";
import axios from "axios";
import useRefreshTokenAPI from "../../../api/useRefreshToken";
import { useAuth0 } from "@auth0/auth0-react";
import FixedHeaderDataGrid from "./FixedHeaderDataGrid";
import TagManagementModal from "./TagManagement";
import PlusIcon from "../../../assets/images/icons/plus-arrows.svg";
import PdfIcon from "../../../assets/images/docs-icon.svg";
import imageIcon from "../../../assets/images/image-icon.svg";
import TagIcon from "../../../assets/images/icons-tag.svg";
import CustomTooltip from "../../../components/shared-components/CustomTooltip";
import { BASE_API_URL } from "../../../api";
import DocumentUploadComponent, { CustomFileStruct } from "../../../components/shared-components/FileUploadComponent/DocumentUploadComponent";

interface Tag {
  id: string;
  tag: string;
}

const API_BASE_URL = process.env.REACT_APP_API_URL;

const getStatusColor = (statusName: string) => {
  switch (statusName) {
    case "Listed":
      return { color: "#50CD89", backgroundColor: "#E8FFF3" };
    case "Unlisted":
      return { color: "#D9214E", backgroundColor: "#FFF5F8" };
    case "Draft":
      return { color: "#E9B500", backgroundColor: "#FFF8DD" };
    default:
      return { color: "#000", backgroundColor: "#FFF" };
  }
};

export default function Listing() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const handleClose = () => setOpen(false);
  const [tempStatus, setTempStatus] = useState("");
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState<number>(1);
  const [status] = useState<boolean>(false);
  const [rows, setRows] = useState<any[]>([]);
  const [searchText, setSearchText] = React.useState("");
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const { mutate: refreshAccessToken } = useRefreshTokenAPI();
  const [error, setError] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<GridRowId[]>([]);
  const [isTagModalOpen, setIsTagModalOpen] = useState(false);
  const [selectedRowTags, setSelectedRowTags] = useState<Tag[]>([]);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [selectedPropertyId, setSelectedPropertyId] = useState<string>("");
  const [selectedRowId, setSelectedRowId] = useState<string | null>(null);
  const [modalFiles, setModalFiles] = useState<
    {
      name: string;
      tags: { tag: string, id?: string }[];
      size?: number;
      isImage?: boolean;
      url?: string;
      id?: string;
    }[]
  >([]);

  const handleTagClick = (row: any) => {
    setSelectedRowTags(row.tags);
    setSelectedRowId(row.Id);
    setIsTagModalOpen(true);
  };

  const updateTokens = (accessToken: string, refreshToken: string) => {
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);
    localStorage.setItem("oauthToken", accessToken);
  };

  const fetchDataWithRetry = async () => {
    const pageIndex = 1;
    const pageSize = 100;
    try {
      setLoading(true);
      setError(false);

      const accessToken = await getAccessTokenSilently();

      console.log("Access tokenGetAll Page:", accessToken);

      const response = await axios.get(
        `${API_BASE_URL}/Property/GetAll?PageIndex=${pageIndex}&PageSize=${pageSize}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      const apiData = response.data.items.map(
        (property: any) => {
          const files = property.files && property.files.length > 0 ? property.files.filter((file: any) => !file.isDeleted) : []
          return {
            Id: property.id,
            propertyId: property.id,
            listingName: property.name || "N/A",
            nickname: property.internalName || "N/A",
            typeOfUnit: property.typeCode || "N/A",
            city: property.city || "N/A",
            address: `${property.street}, ${property.city}, ${property.state}, ${property.zipCode}`,
            tags:
              property.tags &&
                Array.isArray(property.tags) &&
                property.tags.length > 0
                ? property.tags.filter(
                  (tag: { tag: string }) =>
                    tag && tag.tag && tag.tag.trim() !== ""
                )
                : [{ id: "no-tags", tag: "No tags" }],
            status: property.isActive ? "Active" : "Passive",
            aiStatus: property.status,
            attachDocument: files.length > 0 ? files.map((file: any) => ({
              name: file.name,
              tags: file.tags || [],
              url: file.url,
              id: file.id,
              isImage:
                file.name
                  .toLowerCase()
                  .match(/\.(jpg|jpeg|png|gif|webp)$/) !== null,
            })) : [null]
          }
        }
      );

      setRows(apiData);
      setError(false);
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        const status = error.response?.status;

        if (status === 401) {
          refreshAccessToken(localStorage.getItem("refreshToken") ?? "", {
            onSuccess: (refreshData: any) => {
              updateTokens(refreshData.accessToken, refreshData.refreshToken);
              fetchDataWithRetry();
            },
            onError: (refreshError: any) => {
              console.error("Token yenileme hatası:", refreshError);
              setError(true);
            },
          });
        } else if (status && (status === 500 || status >= 400)) {
          console.error("API error:", error);
          setError(true);
        } else {
          console.error("Other axios error:", error);
          setError(true);
        }
      } else {
        console.error("API isteği hatası:", error);
        setError(true);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetchDataWithRetry();
    }
  }, [isAuthenticated]);

  const handleSave = () => {
    const rowIndex = rows.findIndex((row) => row.status === selectedStatus);
    if (rowIndex !== -1) {
      const newRows = [...rows];
      newRows[rowIndex].status = tempStatus;
      setRows(newRows);
    }

    handleClose();
  };

  const handleStatusChipClick = (status: string) => {
    setSelectedStatus(status);
    setTempStatus(status);
    setOpen(true);
  };

  const handleUploadOpen = (rowId: string) => {
    setSelectedRowId(rowId);
    const selectedRow = rows.find((row) => row.Id === rowId);

    if (selectedRow) {
      const existingDocuments = selectedRow.attachDocument || [];
      console.log(existingDocuments)

      if (existingDocuments[0] !== null) {
        const documentsWithSize = existingDocuments.map((doc: any) => ({
          ...doc,
          size: 500 * 1024,
          tags: doc.tags || [],
          isImage:
            doc.name.toLowerCase().match(/\.(jpg|jpeg|png|gif|webp)$/) !== null,
          url: doc.url,
          id: doc.id,
        }));
        setModalFiles(documentsWithSize);
      } else {
        setModalFiles([]);
      }
      setSelectedPropertyId(rowId);
    }
    setIsUploadModalOpen(true);
  };

  const handleUploadClose = () => {
    setIsUploadModalOpen(false);
    setSelectedPropertyId("");
  };

  const handleUploadSave = (
    files: CustomFileStruct[]
  ) => {
    if (selectedRowId !== null) {

      //  const existingFiles = [...modalFiles].filter(
      //    (file) => file.url && file.id
      //  );

      const newFiles = files.map((file) => ({
        id: file.id as string,
        name: file.file.name,
        tags: file.tags,
        isImage:
          file.file.name.toLowerCase().match(/\.(jpg|jpeg|png|gif|webp)$/) !==
          null,
        size: file.file.size,
      }));

      const updatedFiles = newFiles

      setRows((prev) => {
        const newRows = [...prev];
        const rowIndex = newRows.findIndex((row) => row.Id === selectedRowId);

        if (rowIndex !== -1) {
          newRows[rowIndex] = {
            ...newRows[rowIndex],
            attachDocument: updatedFiles.length ? updatedFiles : [null],
          };
        }
        return newRows;
      });

      setModalFiles(updatedFiles);
    }
  };


  const EmptyState = () => (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      height="400px"
      width="100%"
    >
      <p
        className="B-14-14-600"
        style={{
          wordBreak: "break-word",
          textAlign: "center",
          width: "100%",
          color: "#3F4254",
        }}
      >
        Couldn't find any property
      </p>
    </Box>
  );

  const getColumns = (_: (status: string) => void): GridColDef<any[number]>[] => [
    {
      field: "newdata",
      headerName: t("listingpage.listingName"),
      disableColumnMenu: true,
      flex: 2,
      align: "center",
      renderCell: (params) => (
        <Stack
          gap={2}
          alignItems={"center"}
          alignContent={"center"}
          flexDirection={"row"}
          justifyContent={"start"}
          spacing={2}
          height={"100%"}
        >
          <img
            src={ReservationImage}
            alt="reservation"
            className="reservationImage"
          />
          <div className="containerListingName">
            <span className="infoText B-14-14-600">
              {params.row.nickname}
            </span>
            <span className="helperText B-12-12-500">{params.row.city}</span>
          </div>
        </Stack>
      ),
    },
    {
      field: "location",
      headerName: t("listingpage.location"),
      disableColumnMenu: true,
      flex: 2,
      align: "left",
      renderCell: (params) => (
        <div className="containerLocation">
          <span className="locationText B-14-14-500">{params.row.address}</span>
        </div>
      ),
    },
    {
      field: "agent",
      headerName: t("listingpage.agent"),
      disableColumnMenu: true,
      flex: 2,
      align: "center",
      renderCell: (params) => (
        <div className="containerLocation">
          <span className="locationText B-14-14-500">
            {params.row.propertyManager ? params.row.propertyManager : "-"}
          </span>
        </div>
      ),
    },
    {
      field: "typeOfUnit",
      headerName: "Type of unit",
      flex: 1,
      align: "left",
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => (
        <Stack alignItems={"flex-start"} pt={4.5}>
          <p
            className="TextSmMedium"
            style={{
              wordWrap: "break-word",
              whiteSpace: "normal",
              display: "flex",
              alignItems: "center",
              maxWidth: "200px",
              wordBreak: "break-all",
              textAlign: "left",
              color: "#3F4254",
            }}
          >
            {params.row.typeOfUnit}
          </p>
        </Stack>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      disableColumnMenu: true,
      align: "left",
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Stack alignItems={"flex-start"} pt={3.5}>
          <Chip
            label={params.row.status}
            className="chip"
            style={{
              backgroundColor:
                params.row.status === "Active" ? "#E8FFF3" : "#FFF5F8",
              color: params.row.status === "Active" ? "#47BE7D" : "#F1416C",
            }}
          />
        </Stack>
      ),
    },

    {
      field: "tags",
      headerName: "Tags",
      disableColumnMenu: true,
      align: "left",
      width: 150,
      sortable: false,
      renderCell: (params) => (
        <Stack
          direction={"row"}
          spacing={1}
          alignItems={"center"}
          pt={3.5}
          onClick={() => handleTagClick(params.row)}
        >
          {params.row.tags
            .slice(0, 2)
            .map((tag: { id: string; tag: string }) => (
              <Chip
                key={tag.id}
                label={tag.tag}
                className="tag"
                style={{
                  color: "#FFFFFF",
                  backgroundColor: "#3E97FF",
                  margin: "2px",
                }}
              />
            ))}
          {params.row.tags.length > 2 && (
            <Chip
              label={`+${params.row.tags.length - 2}`}
              className="tag"
              style={{
                color: "#3F4254",
                backgroundColor: "#FFFFFF",
                margin: "2px",
              }}
            />
          )}
        </Stack>
      ),
    },
    {
      field: "attachDocument",
      headerName: "Attach Document",
      disableColumnMenu: true,
      flex: 3,
      align: "left",
      width: 100,
      sortable: false,
      renderCell: (params) => {
        const documents = params.row.attachDocument || [null];
        const displayedDocuments =
          documents[0] === null ? [] : documents.slice(0, 2);
        const extraDocsCount = documents[0] === null ? 0 : documents.length - 2;

        return (
          <Stack
            direction={"row"}
            spacing={2}
            alignItems={"center"}
            sx={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            {documents[0] === null ? (
              <Chip
                icon={
                  <img
                    src={PlusIcon}
                    alt="plus-icon"
                    style={{ paddingLeft: 4 }}
                  />
                }
                onClick={() => handleUploadOpen(params.row.Id)}
                label="Attach document"
                className="tag"
                style={{
                  color: "#5E6278",
                  backgroundColor: "transparent",
                  border: "1px solid #D8D8E5",
                }}
              />
            ) : (
              <>
                {displayedDocuments.map((document: any, index: number) => (
                  <Stack
                    key={index}
                    direction={"column"}
                    spacing={1}
                    alignItems={"flex-start"}
                    sx={{
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <CustomTooltip
                      title="Click to edit or add tags"
                      offset={[0, 40]}
                      minWidth={150}
                    >
                      <Stack
                        direction={"row"}
                        spacing={"4px"}
                        alignItems={"center"}
                        padding={"4px 8px"}
                        border={"1px solid #D8D8E5"}
                        borderRadius={50}
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleUploadOpen(params.row.Id)}
                      >
                        <img
                          src={document.isImage ? imageIcon : PdfIcon}
                          alt="file-icon"
                          style={{ width: "16px", height: "16px" }}
                        />
                        <p
                          className="TextSmMedium"
                          style={{
                            color: "#3F4254",
                            wordWrap: "break-word",
                            whiteSpace: "normal",
                            display: "flex",
                            alignItems: "center",
                            maxWidth: "200px",
                            wordBreak: "break-all",
                            textAlign: "left",
                          }}
                        >
                          {document.name}
                        </p>
                        <img
                          src={TagIcon}
                          alt="tag-icon"
                          style={{ width: "16px", height: "16px" }}
                        />
                      </Stack>
                    </CustomTooltip>
                  </Stack>
                ))}
                {extraDocsCount > 0 && (
                  <Chip
                    label={`+${extraDocsCount}`}
                    className="tag"
                    onClick={() => handleUploadOpen(params.row.Id)}
                    style={{
                      color: "#5E6278",
                      backgroundColor: "transparent",
                      border: "1px solid #D8D8E5",
                      cursor: "pointer",
                    }}
                  />
                )}
              </>
            )}
          </Stack>
        );
      },
    },
    {
      field: "aiStatus",
      headerName: "AI Status",
      disableColumnMenu: true,
      align: "center",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [isActive, setIsActive] = useState(params.row.aiStatus || false);
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [isLoading, setIsLoading] = useState(false);

        const handleSwitchChange = async (
          event: React.ChangeEvent<HTMLInputElement>
        ) => {
          setIsLoading(true);
          const newStatus = event.target.checked;

          try {
            const accessToken = await getAccessTokenSilently();

            const response = await axios.put((BASE_API_URL + "/Property/UpdateStatus"),
              {
                properties: [params.row.Id],
                status: newStatus,
              },
              {
                headers: { Authorization: `Bearer ${accessToken}` },
              }
            );

            if (response.status === 200) {
              setIsActive(newStatus);
            } else {
              setIsActive(!newStatus);
              console.error("AI status güncelleme başarısız");
            }
          } catch (error) {
            setIsActive(!newStatus);
            console.error("AI status güncelleme hatası:", error);
          } finally {
            setIsLoading(false);
          }
        };

        return (
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{
              height: "100%",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={isActive}
                  onChange={handleSwitchChange}
                  disabled={isLoading}
                  sx={{
                    "& .MuiSwitch-switchBase.Mui-checked": {
                      color: "#50CD89",
                      "&:hover": {
                        backgroundColor: "rgba(80, 205, 137, 0.08)",
                      },
                    },
                    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                      backgroundColor: "#50CD89",
                    },
                    "& .MuiSwitch-track": {
                      backgroundColor: "#E4E6EF",
                    },
                  }}
                />
              }
              label={
                <span
                  className="B-14-14-500"
                  style={{
                    color: isActive ? "#3E97FF" : "#A1A5B7",
                    marginLeft: "8px",
                  }}
                ></span>
              }
              labelPlacement="end"
            />
          </Stack>
        );
      },
    },
    /**{
      field: "source",
      headerName: t("listingpage.source"),
      disableColumnMenu: true,
      flex: 0.8,
      align: "left",
      renderCell: (params) => (
        <div className="alignLeft">
          <img
            src={Logo}
            alt="logo"
            style={{ width: "61px", height: "20px" }}
          />
        </div>
      ),
    }, */

    {
      field: "lastModified",
      headerName: t("listingpage.lastModified"),
      disableColumnMenu: true,
      align: "center",
      flex: 1,
      renderCell: () => (
        <div
          style={{
            display: "flex",
            height: "100px",
            alignItems: "center",
            alignSelf: "stretch",
          }}
          className="B-14-14-600"
        >
          12.02.2024
        </div>
      ),
    },
    //  {
    //    field: "edit",
    //    headerName: "",
    //    disableColumnMenu: true,
    //    align: "center",
    //    hideSortIcons: true,
    //    sortable: false,
    //    flex: 0.5,
    //    renderCell: (params) => (
    //      <Link to="/listings/id/details">
    //        <img className="editIcon" src={edit} alt="edit" />
    //      </Link>
    //    ),
    //  },
  ];

  const mobileColumns: GridColDef[] = [
    {
      field: "listing",
      headerName: "Listing name",
      flex: 3,
      disableColumnMenu: true,
      sortable: false,
      align: "center",
      renderHeader: () => (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <span
            className="B-16-16-600"
            style={{ color: "#3F4254", lineHeight: "20px" }}
          >
            Listing name
          </span>
        </Box>
      ),
      renderCell: (params) => (
        <Box
          display="flex"
          width="100%"
          height={"100%"}
          justifyContent="start"
          alignItems="center"
        >
          <Box display="flex" alignItems="center">
            <img
              src={ReservationImage}
              alt="reservation"
              style={{
                width: "40px",
                height: "26px",
                objectFit: "cover",
                marginRight: "8px",
                borderRadius: "4px",
              }}
            />
            <Box
              display="flex"
              justifyContent={"flex-start"}
              flexDirection="column"
            >
              <span
                className="B-12-12-600"
                style={{
                  color: "var(--Gray-Gray-800, #3F4254)",
                  textOverflow: "ellipsis",
                  marginBottom: "8px",
                }}
              >
                {params.row.listingName}
              </span>
              <span
                className="B-12-12-500"
                style={{
                  color: "var(--Gray-Gray-500, #A1A5B7)",
                  textAlign: "left",
                }}
              >
                {params.row.city}
              </span>
            </Box>
          </Box>
        </Box>
      ),
    },
    {
      field: "status",
      headerName: "",
      flex: 1.5,
      align: "center",
      disableColumnMenu: true,
      renderCell: (params) => {
        const { color, backgroundColor } = getStatusColor("Listed");
        return (
          <div
            style={{
              display: "flex",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Chip
              label={params.row.status}
              sx={{
                fontWeight: "600",
                fontSize: "10px",
                letterSpacing: "-0.3px",
                color,
                backgroundColor,
                borderRadius: "10px",
                marginRight: "10px",
                fontFamily: "Inter",
                height: "25px",
              }}
            />
          </div>
        );
      },
    },
    // {
    //   field: "edit",
    //   headerName: "",
    //   disableColumnMenu: true,
    //   align: "center",
    //   headerAlign: "center",
    //   hideSortIcons: true,
    //   sortable: false,
    //   flex: 1,
    //   renderHeader: (params) => (
    //     <Box
    //       display="flex"
    //       alignItems="center"
    //       justifyContent="center"
    //       width="100%"
    //     >
    //       <IconButton onClick={handleOpenMobileModal}>
    //         <FilterListIcon
    //           sx={{ width: "20px", height: "20px", cursor: "pointer" }}
    //         />
    //       </IconButton>
    //       <CustomModalMobile
    //         open={isMobileModalOpen}
    //         handleClose={handleCloseMobileModal}
    //         handleSave={handleMobileModalSave}
    //         title="Filter listing"
    //         showFooter={true}
    //       >
    //         <Stack sx={{ width: "100%", maxHeight: "800px" }}>
    //           <Stack
    //             spacing={2}
    //             pt={2}
    //             pb={2}
    //             sx={{
    //               borderBottom: "1px solid var(--stroke-colors, #EBEBEB)",
    //             }}
    //           >
    //             <span className="B-16-16-600" style={{ color: "#3F4254" }}>
    //               Listing name
    //             </span>
    //             <Stack direction="row" spacing={1} alignItems="center">
    //               <CustomSecondCheckbox
    //                 id="listingNameId"
    //                 checked={false}
    //                 onChange={() => { }}
    //               />
    //               <span className="B-14-24-500" style={{ color: "#3F4254" }}>
    //                 Tekgul apartment
    //               </span>
    //             </Stack>
    //             <Stack direction="row" spacing={1} alignItems="center">
    //               <CustomSecondCheckbox
    //                 id="listingNameId"
    //                 checked={false}
    //                 onChange={() => { }}
    //               />
    //               <span className="B-14-24-500" style={{ color: "#3F4254" }}>
    //                 Baku Villa
    //               </span>
    //             </Stack>
    //           </Stack>
    //           <Stack
    //             spacing={2}
    //             pt={2}
    //             pb={2}
    //             sx={{
    //               borderBottom: "1px solid var(--stroke-colors, #EBEBEB)",
    //             }}
    //           >
    //             <span className="B-16-16-600" style={{ color: "#3F4254" }}>
    //               Status
    //             </span>
    //             <Stack direction="row" spacing={1} alignItems="center">
    //               <CustomSecondCheckbox
    //                 id="listed"
    //                 checked={false}
    //                 onChange={() => { }}
    //               />
    //               <span className="B-14-24-500" style={{ color: "#3F4254" }}>
    //                 Listed
    //               </span>
    //             </Stack>
    //             <Stack direction="row" spacing={1} alignItems="center">
    //               <CustomSecondCheckbox
    //                 id="unlisted"
    //                 checked={false}
    //                 onChange={() => { }}
    //               />
    //               <span className="B-14-24-500" style={{ color: "#3F4254" }}>
    //                 Unlisted
    //               </span>
    //             </Stack>
    //             <Stack direction="row" spacing={1} alignItems="center">
    //               <CustomSecondCheckbox
    //                 id="draft"
    //                 checked={false}
    //                 onChange={() => { }}
    //               />
    //               <span className="B-14-24-500" style={{ color: "#3F4254" }}>
    //                 Draft
    //               </span>
    //             </Stack>
    //           </Stack>
    //           <Stack
    //             spacing={2}
    //             pt={2}
    //             pb={2}
    //             sx={{
    //               borderBottom: "1px solid var(--stroke-colors, #EBEBEB)",
    //             }}
    //           >
    //             <span className="B-16-16-600" style={{ color: "#3F4254" }}>
    //               Source
    //             </span>
    //             <Stack direction="row" spacing={1} alignItems="center">
    //               <CustomSecondCheckbox
    //                 id="botel"
    //                 checked={false}
    //                 onChange={() => { }}
    //               />
    //               <span className="B-14-24-500" style={{ color: "#3F4254" }}>
    //                 Botel
    //               </span>
    //             </Stack>
    //             <Stack direction="row" spacing={1} alignItems="center">
    //               <CustomSecondCheckbox
    //                 id="booking"
    //                 checked={false}
    //                 onChange={() => { }}
    //               />
    //               <span className="B-14-24-500" style={{ color: "#3F4254" }}>
    //                 Booking
    //               </span>
    //             </Stack>
    //             <Stack direction="row" spacing={1} alignItems="center">
    //               <CustomSecondCheckbox
    //                 id="airbnb"
    //                 checked={false}
    //                 onChange={() => { }}
    //               />
    //               <span className="B-14-24-500" style={{ color: "#3F4254" }}>
    //                 Airbnb
    //               </span>
    //             </Stack>
    //           </Stack>
    //         </Stack>
    //       </CustomModalMobile>
    //     </Box>
    //   ),
    //   renderCell: (params) => (
    //     <Box
    //       sx={{
    //         display: "flex",
    //         justifyContent: "center",
    //         alignItems: "center",
    //         height: "100%",
    //       }}
    //     >
    //       <Link to="/listings/id/details">
    //         <img
    //           className="editIcon"
    //           src={edit}
    //           alt="edit"
    //           style={{
    //             width: "20px",
    //             height: "20px",
    //             justifyContent: "center",
    //             alignItems: "center",
    //           }}
    //         />
    //       </Link>
    //     </Box>
    //   ),
    // },
  ];

  const filteredRows = rows.filter(
    (row) =>
      row.listingName.toLowerCase().includes(searchText.toLowerCase()) ||
      row.nickname.toLowerCase().includes(searchText.toLowerCase()) ||
      row.city.toLowerCase().includes(searchText.toLowerCase()) ||
      row.address.toLowerCase().includes(searchText.toLowerCase()) ||
      row.typeOfUnit.toLowerCase().includes(searchText.toLowerCase()) ||
      row.tags.some(
        (tag: any) =>
          typeof tag === "string" &&
          tag.toLowerCase().includes(searchText.toLowerCase())
      )
  );

  return loading && !status ? (
    <DatagridPageLoading />
  ) : (
    <Box
      className={!isMobile ? "container-datagrid-pages-listening" : ""}
      sx={{
        width: { xs: "100%", sm: "auto" },
        margin: "auto",
        height: "90vh",
        display: "flex",
        flexDirection: "column",
      }}
      padding={isMobile ? 2 : "0px 40px"}
      pb={isMobile ? 20 : 0}
      mb={isMobile ? 20 : 0}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          background: "#fff",
          zIndex: 101,
          paddingTop: isMobile ? 2 : 11,
          marginBottom: 1,
        }}
      >
        {/*     <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <h1 className={isMobile ? "B-18-18-600" : "H-24"}>
            {t("listingpage.pageTitle")}
          </h1>
          <Link to="/onboardingtwo">
            <button className="buttonLg" aria-label="add listing">
              <Add />
              <p
                className={
                  isMobile ? "buttonText B-13-13-600" : "buttonText B-16-16-600"
                }
                style={{ color: "#fff" }}
              >
                {t("listingpage.addNewListingButton")}
              </p>
            </button>
          </Link>
        </Stack>*/}
      </Box>


      {GridData.length > 0 && !isMobile && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            marginTop: "32px",
          }}
        >
          <MessagesSearchTextfield
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            height="38px"
          />
          {/*rows?.length > 0 && !error ? (
            //    <InputSection
            //      showListingSelect={false}
            //      showBookingReferenceSelect={false}
            //      showGuestNameSelect={false}
            //      showBookingStatusSelect={false}
            //      showListingStatusButton={true}
            //    />
            //  ) : (*/}
        </Box>
      )}

      {isMobile && !error && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "32px",
          }}
        >
          <MessagesSearchTextfield showEndAdornment={false} height="40px" />
        </Box>
      )}

      {loading ? (
        <DatagridPageLoading status={status} />
      ) : (
        <Box
          sx={{
            flex: 1,
            marginTop: "36px",
            paddingBottom: "36px",
            position: "relative",

            overflow: "hidden",
          }}
        >
          {isMobile ? (
            rows?.length > 0 ? (
              <FixedHeaderDataGrid isMobile={true}>
                <MobileGridData
                  checkboxSelection
                  rows={rows}
                  columns={mobileColumns}
                  setPage={setPage}
                  total={rows?.length}
                  page={page}
                  rowHeight={90}
                />
              </FixedHeaderDataGrid>
            ) : (
              <EmptyState />
            )
          ) : filteredRows?.length > 0 ? (
            <FixedHeaderDataGrid>
              <GridData
                checkboxSelection
                rowSelectionModel={selectedRows}
                onRowSelectionModelChange={(
                  newSelection: GridRowSelectionModel
                ) => {
                  setSelectedRows([...newSelection]);
                  console.log("Selected rows:", newSelection);
                }}
                rows={filteredRows}
                columns={getColumns(handleStatusChipClick)}
                setPage={setPage}
                total={rows?.length}
                page={page}
                hideFooter={true}
                disableVirtualization
                autoHeight={false}
                sx={{
                  width: "100%",
                  height: "100%",
                  "& .MuiDataGrid-main": {
                    overflow: "hidden",
                    display: "flex",
                    flexDirection: "column",
                  },
                  "& .MuiCheckbox-root": {
                    padding: "8px",
                  },
                }}
              />
            </FixedHeaderDataGrid>
          ) : (
            <EmptyState />
          )}
        </Box>
      )}

      <CustomModal
        open={open}
        handleClose={handleClose}
        handleSave={handleSave}
        title={t("listingpage.listingStatus")}
      >
        <ListingStatus showTitle={false} />
      </CustomModal>
      <CustomModal
        open={isTagModalOpen}
        handleClose={() => setIsTagModalOpen(false)}
        handleSave={() => {
          setIsTagModalOpen(false);
        }}
        title="Tags"
      >
        <TagManagementModal
          existingTags={selectedRowTags.map((tag) => ({
            id: tag.id || String(Date.now()),
            tag: typeof tag === "string" ? tag : tag.tag,
          }))}
          onSave={(newTags) => {
            setSelectedRowTags(newTags);
            const updatedRows = rows.map((row) => {
              if (row.Id === selectedRowId) {
                return {
                  ...row,
                  tags: newTags,
                };
              }
              return row;
            });
            setRows(updatedRows);
          }}
        />
      </CustomModal>
      <DocumentUploadComponent
        opened={isUploadModalOpen}
        close={handleUploadClose}
        handleSave={handleUploadSave}
        existingFiles={modalFiles}
        propertyId={selectedPropertyId}
      />
      {/*<CustomModal
        open={isUploadModalOpen}
        handleClose={handleUploadClose}
        handleSave={() => { }}
        title="Upload and attach files"
        description="Supported format: pdf, csv, xlsx"
        showFooter={false}
      >

        <UploadAttachModal
          handleClose={handleUploadClose}
          handleSave={handleUploadSave}
          existingFiles={modalFiles}
          propertyId={selectedPropertyId}
          onDeleteFile={handleDeleteFile}
        />
      </CustomModal>*/}
    </Box>
  );
}
