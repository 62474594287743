import { Box, Stack } from "@mui/material";
import mainImage from "../../../../assets/images/steptwotwo.svg";

function LandingScreen() {
  return (
    <Stack
      flexDirection={"row"}
      mt={6}
      alignItems={"center"}
      justifyContent={"center"}
      alignContent={"center"}
      alignSelf={"center"}
    >
      <Stack spacing={2}>
        <p className="subtitleOfStep">Step 2</p>
        <p className="titleOfStep">
          Let’s train AI and save your time with messages with guests
        </p>
        <p
          className="secondaryDescription"
          style={{
            maxWidth: "450px",
            wordWrap: "break-word",
            lineHeight: "1.5",
          }}
        >
          Our AI learns from your past and future conversations to be able to
          answer at 99% accuracy
        </p>
      </Stack>
      <Box>
        <img alt="onboarding" className="onboardingImage" src={mainImage} />
      </Box>
    </Stack>
  );
}

export default LandingScreen;
