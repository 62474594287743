import React from "react";
import { Box, } from "@mui/material";
import moment from "moment";
import { chatBoxStyles } from "../../ChatBoxStyles";

interface MessageItem {
  id: string;
  createdAt: string;
  [key: string]: any;
}

interface DateDividerProps {
  date: string;
  onTimeRangeChange: (value: string) => void;
  chatMessagesAreaRef: React.RefObject<HTMLDivElement>;
  messageItemInComponent: MessageItem[];
}

const DateDivider: React.FC<DateDividerProps> = ({ date }) => {

  const formattedDate = moment(date).calendar(null, {
    sameDay: "[Today]",
    lastDay: "[Yesterday]",
    lastWeek: "[Last] dddd",
    sameElse: "MMMM D, YYYY",
  });

  //  const timeRanges = [
  //    { label: "Today", value: "today" },
  //    { label: "Yesterday", value: "yesterday" },
  //    { label: "Last Week", value: "lastWeek" },
  //    { label: "Last Month", value: "lastMonth" },
  //    { label: "Beginning of the Chat", value: "beginning" },
  //  ];

  const handleClick = (_: React.MouseEvent<HTMLElement>) => {
    //setAnchorEl(event.currentTarget);
  };

  // const handleMenuItemClick = (value: string) => {
  //   setSelectedValue(value);

  //   const messagesArea = chatMessagesAreaRef.current;
  //   if (!messagesArea) return;

  //   switch (value) {
  //     case "today":
  //       const todayMessages = messageItemInComponent.find((msg: MessageItem) =>
  //         moment(msg.createdAt).isSame(moment(), "day")
  //       );
  //       if (todayMessages) {
  //         const element = document.getElementById(todayMessages.id);
  //         element?.scrollIntoView({ behavior: "smooth" });
  //       }
  //       break;

  //     case "yesterday":
  //       const yesterdayStart = moment().subtract(1, "days").startOf("day");
  //       const yesterdayMessages = messageItemInComponent.find(
  //         (msg: MessageItem) =>
  //           moment(msg.createdAt).isSameOrAfter(yesterdayStart, "day")
  //       );
  //       if (yesterdayMessages) {
  //         const element = document.getElementById(yesterdayMessages.id);
  //         element?.scrollIntoView({ behavior: "smooth" });
  //       }
  //       break;

  //     case "lastWeek":
  //       const lastWeekStart = moment().subtract(7, "days").startOf("day");

  //       const messagesInLastWeek = messageItemInComponent.filter(
  //         (msg: MessageItem) =>
  //           moment(msg.createdAt).isBetween(
  //             lastWeekStart,
  //             moment(),
  //             "day",
  //             "[]"
  //           )
  //       );

  //       const oldestLastWeekMessage =
  //         messagesInLastWeek.length > 0
  //           ? messagesInLastWeek.reduce((oldest, current) =>
  //             moment(current.createdAt).isBefore(moment(oldest.createdAt))
  //               ? current
  //               : oldest
  //           )
  //           : null;

  //       if (oldestLastWeekMessage) {
  //         const element = document.getElementById(oldestLastWeekMessage.id);
  //         element?.scrollIntoView({ behavior: "smooth" });
  //       }
  //       break;

  //     case "lastMonth":
  //       const lastMonthStart = moment().subtract(30, "days").startOf("day");

  //       const messagesInLastMonth = messageItemInComponent.filter(
  //         (msg: MessageItem) =>
  //           moment(msg.createdAt).isBetween(
  //             lastMonthStart,
  //             moment(),
  //             "day",
  //             "[]"
  //           )
  //       );

  //       const oldestLastMonthMessage =
  //         messagesInLastMonth.length > 0
  //           ? messagesInLastMonth.reduce((oldest, current) =>
  //             moment(current.createdAt).isBefore(moment(oldest.createdAt))
  //               ? current
  //               : oldest
  //           )
  //           : null;

  //       if (oldestLastMonthMessage) {
  //         const element = document.getElementById(oldestLastMonthMessage.id);
  //         element?.scrollIntoView({ behavior: "smooth" });
  //       }
  //       break;

  //     case "beginning":
  //       messagesArea.scrollTop = 0;
  //       break;
  //   }

  //   onTimeRangeChange(value);
  //   handleClose();
  // };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
    >
      <Box flex="1" height="1px" bgcolor="#F1F1F2"></Box>
      <Box
        display="flex"
        alignItems="center"
        onClick={handleClick}
        sx={chatBoxStyles.chatBoxSelectDivider}
      >
        {formattedDate}
        {/*  <img
          style={{ cursor: "pointer", marginLeft: 8 }}
          src={ArrowDown}
          alt="arrow-down"
        />*/}
      </Box>
      {/* <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            padding: "10px 12px",
            flexDirection: "column",
            alignItems: "flex-start",
            alignSelf: "stretch",
            width: "180px",
            boxShadow:
              "0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)",
            borderRadius: "12px",
            overflow: "visible",
            gap: "5px",
          }}
        >
          <em
            style={{
              display: "flex",
              padding: "4px 10px",
              alignItems: "flex-start",
              alignSelf: "stretch",
              color: "#7E8299",
              fontFamily: "Inter",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "20px",
            }}
          >
            Jump to..
          </em>
          {timeRanges.map((range) => (
            <MenuItem
              key={range.value}
              onClick={() => handleMenuItemClick(range.value)}
              sx={{
                display: "flex",
                padding: "7px 10px",
                alignItems: "flex-start",
                alignSelf: "stretch",
                color: "#181C32",
                fontFamily: "Inter",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "20px",
                borderRadius: "10px",
              }}
            >
              {range.label}
            </MenuItem>
          ))}
        </Box>
      </Popover>
      */}
      <Box flex="1" height="1px" bgcolor="#F1F1F2"></Box>
    </Box>
  );
};

export default DateDivider;
