import { Stack, Typography } from "@mui/material";
import React from "react";
import DetailsContentTitle from "../../../../../../../../components/shared-components/DetailsContentTitle";
import { InputStyles } from "../../../../../../../../components/core-components/inputs/TextField/InputStyles";
import MainSelectSelection from "../../../../../../../../components/core-components/inputs/Select/MainSelectSelection";
import MainTextField from "../../../../../../../../components/core-components/inputs/TextField/MainTextField";
import { useTranslation } from "react-i18next";

function AdditionalPolicy1() {
  const { t } = useTranslation();
  return (
    <Stack
      sx={{ width: "100%", maxWidth: "463px" }}
      component={"form"}
      spacing={2}
    >
      <DetailsContentTitle
        title={t("detailsPages.policies.additionalPolicy1")}
      />
      <Stack spacing={1} sx={{ width: "100%" }}>
        <Typography variant="body2" sx={InputStyles.label}>
          {t(
            "detailsPages.policies.adjustYourRatesForGuestsThatBookUsingThisCancellationPolicy"
          )}
        </Typography>
        <MainSelectSelection
          value=""
          onChange={() => {}}
          label={"Options"}
          options={[
            {
              value: "1",
              label: `1`,
            },
            {
              value: "2",
              label: `2`,
            },
          ]}
        />
      </Stack>
      <Stack spacing={1} sx={{ width: "100%" }}>
        <MainTextField
          title={t("detailsPages.policies.ratesIncreaseDecrease")}
          placeholder={""}
          value=""
          onChange={() => {}}
          isMandatory={false}
        />
      </Stack>
    </Stack>
  );
}

export default AdditionalPolicy1;
