import { Box, FormControl, RadioGroup, Stack } from "@mui/material";
import React from "react";
import SecondaryRadioButton from "../../../../components/core-components/inputs/RadioButton/SecondaryRadioButton";
import ChatBotAutoOff from "./ChatBotAutoOff";
import ChatBotAutoYes from "./ChatBotAutoYes";

interface ChatbotProps {
  onAddedPropertiesChange: (properties: any[]) => void;
  value: string;
  setValue: (value: string) => void;
  selectedProperties?: any[];
  validateForm: () => void
}

function Chatbot({
  validateForm,
  onAddedPropertiesChange,
  value,
  setValue,
  selectedProperties,
}: ChatbotProps) {
  const [addedProperties, setAddedProperties] = React.useState<any[]>([]);

  React.useEffect(() => {
    //validateForm();
  }, [value, addedProperties, validateForm]);

  return (
    <>
      <Stack gap={"24px"}>
        <Box>
          <p className="chatbotTitle">Chatbot settings</p>
          <p className="secondaryDescription">
            Choose the best way for you to AI help you
          </p>
        </Box>
        <Box>
          <p className="radioButtonDescription">
            Would you like to have the chatbot on autopilot?
          </p>
          <FormControl fullWidth component="fieldset">
            <RadioGroup
              row
              aria-label="listing-status"
              name="listing-status"
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
                validateForm();
              }}
            >
              <SecondaryRadioButton label={"Yes"} value={"yes"} />
              <SecondaryRadioButton label={"No"} value={"no"} />
            </RadioGroup>
          </FormControl>
        </Box>
        {value === "no" ? (
          <ChatBotAutoOff />
        ) : (
          <ChatBotAutoYes
            validateForm={validateForm}
            selectedProperties={selectedProperties}
            onAddedPropertiesChange={(properties) => {
              setAddedProperties(properties);
              onAddedPropertiesChange(properties);
            }}
          />
        )}
      </Stack>
    </>
  );
}

export default Chatbot;
