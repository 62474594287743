import { useState } from "react";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { ReactComponent as DateIcon } from "../../../../assets/images/icons/date-picker-calendar.svg";

interface DatepickerProps {
  label: string;
  date?:Dayjs;
  name?:string;
}

function CustomDatePicker({ label,date,name }: DatepickerProps) {
  const [selectedDate, setSelectedDate] = useState(date || dayjs());

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        value={selectedDate}
        onChange={(newValue: dayjs.Dayjs | null) => {
          setSelectedDate(newValue || dayjs());
        }}
        className="performance-text-area-right"
        format="DD.MM.YYYY"
        slots={{
          openPickerIcon: DateIcon,
        }}
        sx={{
          display: "flex",
          width: "100%",
          borderRadius: "6px",
          border: "1px solid var(--Gray-Gray-500, #A1A5B7)",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              border: "none",
            },
            color: "var(--Gray-Gray-900, #181C32)",
            fontSize: "13px",
            fontWeight: 500,
            fontStyle: "normal",
            lineHeight: "14px",
          },
        }}
        slotProps={{
          textField: { name: name || "" },
        }}
      />
    </LocalizationProvider>
  );
}

export default CustomDatePicker;
