import React, { useState } from "react";
import {
  Stack,
  Button,
  Popover,
  Box,
  TextField,
  MenuItem,
} from "@mui/material";
import ArrowDown from "../../../../assets/images/icons/arrow-down-messages.svg";
import "./CustomSecondSelect.scss"

interface popoverProps {
  value: string | null;
  placeholder: string;
  openPopover: boolean;
  anchorEl: HTMLElement | null;
  handleClick: (event: React.MouseEvent<HTMLElement>) => void;
  handleClose: () => void;
  setSearchText2?: any;
  dataList: { name: string, id: string }[];
  setValue?: (value: string) => void;
  isSearchable?: boolean;
  borderRadius?: number;
  onChange?: (selectedValue: string) => void;
}

const CustomSecondSelect: React.FC<popoverProps> = ({
  value,
  placeholder,
  openPopover,
  anchorEl,
  handleClick,
  handleClose,
  dataList,
  setValue,
  onChange,
  isSearchable = true,
}) => {
  const [searchText, setSearchText] = useState<string>("");

  const displayedOptions = React.useMemo(() => {
    return dataList.filter((option) =>
      option.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [dataList, searchText]);

  const renderSelectedOptions = () => {
    const option = dataList.find((item: any) => item.id === value)
    if (!option) {
      const optionName = dataList.find((item: any) => item.name === value)
      if (optionName) {
        return optionName.name
      } else {
        return placeholder
      }
    } else if (option) {
      return option.name
    } else return placeholder
  }

  return (
    <Stack>
      <Button
        onClick={handleClick}
        className="custom-second-select-button"
        endIcon={
          <img
            src={ArrowDown}
            alt="arrow-down"
            style={{ transform: openPopover ? "rotate(180deg)" : "rotate(0deg)" }}
          />
        }
      >
        {renderSelectedOptions()}
      </Button>

      <Popover
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPopover-paper": {
            borderRadius: "12px !important",
          },
          "& .MuiPaper-root": {
            borderRadius: "12px !important",
          },
        }}
      >
        <Box className="custom-second-select-box" >
          {isSearchable && (
            <TextField
              id="outlined-search"
              label="Search Agent"
              type="search"
              size="small"
              autoFocus
              placeholder="Search agent..."
              fullWidth
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  e.stopPropagation();
                }
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "4px",
                },
              }}
            />
          )}
          {displayedOptions.map((option, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                if (setValue) {
                  setValue(option.id); // Seçilen değeri setValue ile günceller
                }
                handleClose(); // Popover'ı kapatır
                if (onChange) {
                  onChange(option.id); // Eğer onChange tanımlıysa seçimi iletir
                }
              }}
              className="B-14-20-400 custom-second-select-menu-item"
            >
              {option.name}
            </MenuItem>
          ))}
        </Box>
      </Popover>
    </Stack>
  );
};

export default CustomSecondSelect;
