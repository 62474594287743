
export const InputStyles = {
  select: {
    "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--Gray-Gray-500, #a1a5b7) ",
    },
    ".MuiDataGrid-columnSeparator": {
      display: "none",
    },
    "& .hideRightSeparator > .MuiDataGrid-columnSeparator": {
      display: "none !important",
    },
    "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
    {
      padding: "0px !important",
    },
    "& .css-qiwgdb.css-qiwgdb.css-qiwgdb": {
      padding: "0px !important",
    },
    "& .css-qiwgdb ": {
      padding: "0px !important",
    },

    "&.MuiDataGrid-root": {
      border: "none",
    },
    borderColor: "#A1A5B7",
    display: "flex",
    padding: "15px 15px",
    alignItems: "center",
    gap: "0px",
    borderRadius: "6px",
    fontWeight: 500,
    fontFamily: "Inter !important",
    fontSize: "14px",
    color: "#181C32",
    // m: 1,
    // border: "1px solid #A1A5B7",
  },
  selectFilter: {
    "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--Gray-Gray-500, #a1a5b7) ",
      padding: "0px !important",
    },
    // ".MuiDataGrid-columnSeparator": {
    //   display: "none",
    // },
    // "& .hideRightSeparator > .MuiDataGrid-columnSeparator": {
    //   display: "none !important",
    // },
    "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
    {
      padding: "0px !important",
    },
    "& .css-qiwgdb.css-qiwgdb.css-qiwgdb": {
      padding: "0px !important",
    },
    "& .css-qiwgdb ": {
      padding: "0px !important",
    },

    "&.MuiDataGrid-root": {
      border: "none",
    },
    display: "flex",
    padding: "0 15px",
    alignItems: "center",
    gap: "0px",
    height: "48px !important",
    borderRadius: "6px",
    fontWeight: 500,
    fontFamily: "Inter !important",
    fontSize: "14px",
    color: "#181C32",
    // m: 1,
    // border: "1px solid #A1A5B7",
  },
  textField: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#a1a5b7",
    },
    "& .MuiOutlinedInput-root": {
      padding: "15px 15px",
      alignItems: "center",
      borderRadius: "6px",
      backgroundColor: "#fff",
      color: "var(--Gray-Gray-700, #5e6278)",
      fontFamily: "Inter",
      fontSize: "14px",
      fontWeight: 400,
    },
    "& .MuiInputBase-input": {
      padding: 0,
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#0065e0",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#0065e0",
    },
  },
  label: {
    color: "var(--Gray-Gray-900, #181c32)",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 500,
    // lineHeight: "24px",
  },
};
