import React from "react";
import BasicRates from "./components/basic-rates";
import SeasonalRates from "./components/seasonal-rates";
import Discount from "./components/discount";
import Extras from "./components/extras";

function Pricing() {
  return (
    <>
      <BasicRates />
      <SeasonalRates />
      <Discount />
      <Extras />
    </>
  );
}

export default Pricing;
