import React from "react";
import { Stack } from "@mui/material";
import MainTextField from "../../../../../../../../../components/core-components/inputs/TextField/MainTextField";
import { useTranslation } from "react-i18next";

interface Props {
  title: string;
  description?: string;
}

function DaysArea(props: Props) {
  const { t } = useTranslation();
  const { title, description } = props;

  const days = [
    t("calendarpage.Sunday"),
    t("calendarpage.Monday"),
    t("calendarpage.Tuesday"),
    t("calendarpage.Wednesday"),
    t("calendarpage.Thursday"),
    t("calendarpage.Friday"),
    t("calendarpage.Saturday"),
  ];

  return (
    <Stack
      spacing={2}
      mt={2}
      sx={{
        paddingBottom: "24px",
        borderBottom: "1px solid var(--stroke-colors, #EBEBEB)",
      }}
    >
      <Stack sx={{ width: "100%" }}>
        <p className="B-14" style={{ color: "#181C32" }}>
          {title}
        </p>
        <p className="B-12-16-400">{description}</p>
      </Stack>
      <Stack spacing={2} alignItems={"center"} sx={{ width: "100%" }}>
        {[...Array(3)].map((_, rowIndex) => (
          <Stack
            key={rowIndex}
            direction="row"
            spacing={3}
            sx={{ width: "100%" }}
          >
            {[...Array(3)].map((_, colIndex) => {
              const dayIndex = rowIndex * 3 + colIndex;
              if (dayIndex >= days.length) return null;
              return (
                <Stack
                  key={colIndex}
                  spacing={1}
                  sx={{ width: "100%", maxWidth: "210px" }}
                >
                  <MainTextField
                    title={days[dayIndex]}
                    onChange={() => ""}
                    value={""}
                    placeholder="1"
                  />
                </Stack>
              );
            })}
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
}

export default DaysArea;
