import { Box, MenuItem, Stack, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

interface SelectRoleModalProps {
  displayedOptions: string[];
  selectedRole: string | null;
  setSelectedRole: (role: string) => void;
  roles: Role[];
  disabled?: boolean;
}

interface Role {
  id: string;
  name: string;
  description: string;
  providerCode: string;
}

interface RoleRule {
  id: string;
  name: string;
  description: string;
  isActive: boolean;
}

const API_BASE_URL = process.env.REACT_APP_API_URL;

const SelectRoleModal: React.FC<SelectRoleModalProps> = ({
  displayedOptions,
  selectedRole,
  setSelectedRole,
  roles,
  disabled,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { getAccessTokenSilently } = useAuth0();

  const [rules, setRules] = useState<RoleRule[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchRoleRules = async () => {
      setRules([]);

      if (selectedRole) {
        const selectedRoleObj = roles.find(
          (role) => role.name === selectedRole
        );

        if (selectedRoleObj) {
          try {
            setIsLoading(true);
            const accessToken = await getAccessTokenSilently();

            const response = await axios.get(
              `${API_BASE_URL}/Role/GetRoleRules`,
              {
                params: { roleId: selectedRoleObj.id },
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              }
            );

            setRules(response.data);
          } catch (error) {
            console.error("Role rules API error:", error);
            setRules([]);
          } finally {
            setIsLoading(false);
          }
        }
      }
    };

    fetchRoleRules();
  }, [selectedRole, roles, getAccessTokenSilently]);

  return (
    <Stack
      direction={isMobile ? "column" : "row"}
      spacing={3}
      alignItems={"flex-start"}
      width={"100%"}
      minWidth={isMobile ? "100%" : "480px"}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          alignSelf: "stretch",
          width: isMobile ? "100%" : "200px",
          borderRadius: "8px",
          overflow: "visible",
        }}
      >
        {displayedOptions.map((option, index) => (
          <MenuItem
            key={index}
            selected={selectedRole === option}
            onClick={() => setSelectedRole(option)}
            className="B-14-20-400"
            sx={{
              display: "flex",
              padding: "7px 10px",
              alignItems: "center",
              alignSelf: "stretch",
              borderRadius: "10px",
              cursor: "pointer",
              color: "#181C32",
              "&.Mui-selected": {
                backgroundColor: "#0161E0",
                color: "#FFFFFF",
                "&:hover": {
                  backgroundColor: "#0161E0",
                },
              },
            }}
            disabled={disabled}
          >
            {option}
          </MenuItem>
        ))}
      </Box>

      <Stack spacing={2} alignItems={"flex-start"}>
        <p className="properties-title" style={{ color: "#181C32" }}>
          {selectedRole
            ? `${selectedRole} of the account can`
            : "Owner of the account can"}
        </p>
        <Stack spacing={2} alignItems={"flex-start"}>
          {isLoading ? (
            <p className="TextSmRegular">Loading permissions...</p>
          ) : rules.length > 0 ? (
            rules.map((rule) => (
              <p key={rule.id} className="TextSmRegular">
                • {rule.description}
              </p>
            ))
          ) : (
            <p className="TextSmRegular">No permissions available</p>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SelectRoleModal;
