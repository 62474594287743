import { Box, Popover, Stack } from "@mui/material";
import CloseModalButton from "../../../../../../../../../components/shared-components/CloseModalButton";
import ListingArea from "../listings-area";
import DateRange from "../date-range-area";
import DaysSwitchArea from "../days-switch-area";
import CustomButton from "../../../../../../../../../components/shared-components/CustomButton";
import { useTranslation } from "react-i18next";

interface Props {
  open: boolean;
  anchorEl: HTMLButtonElement;
  handleClose: () => void;
  modalTitle: string;
  buttonText: string;
}
function BulkClosedArrivalModal(props: Props) {
  const { t } = useTranslation();
  const { open, anchorEl, handleClose, modalTitle, buttonText } = props;
  // const queryClient = useQueryClient();

  // const updateRestriction = new UpdateRestriction();
  // const { mutate, isLoading, error } = useMutation(
  //   (postData: any) => updateRestriction.add(postData),
  //   {
  //     onSuccess: () => {
  //       handleClose();
  //       queryClient.invalidateQueries("restrictionList");
  //     },
  //     onError: (error) => {
  //       console.error("Error submitting form", error);
  //     },
  //   }
  // );
  // if (error) {
  //   return <NotFoundPage />;
  // }
  const handleSubmit = (e: any) => {
    e.preventDefault();

    // const data: any = {
    //   PropertyId: "976f2687-1b8e-4e2e-8214-948e1580dfd3",
    //   RatePlanId: "fabbd739-09d0-497f-9344-9e35968931db",
    //   DateBeg: dayjs(e.target.check_in.value, "DD.MM.YYYY").format(
    //     "YYYY-MM-DD"
    //   ),
    //   DateEnd: dayjs(e.target.check_out.value, "DD.MM.YYYY").format(
    //     "YYYY-MM-DD"
    //   ),
    //   Days: [0, 1, 2, 3, 4, 5, 6].map((index) =>
    //     e.target["day" + index].checked ? 1 : 0
    //   ),
    // };
    //   mutate(data);
  };

  return (
    <>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          ".MuiPaper-root": {
            gap: "24px",
            borderRadius: "16px",
            background: "#FFF",
            padding: "24px",
            boxShadow: "0px 8px 14px 0px rgba(15, 42, 81, 0.04)",
            width: "728px",
          },
          background: "rgba(53, 48, 48, 0.21)",
        }}
      >
        <Box component={"form"} onSubmit={handleSubmit}>
          <Stack
            direction="row"
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <p className="B-16-16-600">{modalTitle}</p>
            <CloseModalButton show={true} onClick={handleClose} />
          </Stack>
          <DateRange />
          <DaysSwitchArea
            title={t("calendarpage.closedForArrival")}
            description={t(
              "calendarpage.selectTheDaysYouWantToCloseForArrival"
            )}
          />
          <ListingArea />
          <Stack
            direction="row"
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            <Stack>
              <CustomButton
                backgroundColor="#0065E0"
                color="#FFFFFF"
                onClick={() => { }}
                buttonText={buttonText}
                type="submit"
              //disable={isLoading}
              />
            </Stack>
          </Stack>
        </Box>
      </Popover>
    </>
  );
}

export default BulkClosedArrivalModal;
