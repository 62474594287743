import { useEffect } from "react";
import { FormControl } from "@mui/material";
import CounterBox from "../../../shared-components/CounterBox";

function QuickSelectCounterButtons({
  itemValue,
  handleMinusClick,
  handlePlusClick,
}: {
  itemValue: number;
  handleMinusClick: () => void;
  handlePlusClick: () => void;
}) {

  useEffect(() => { }, []);

  return (
    <FormControl>
      <CounterBox
        itemValue={itemValue}
        handleMinusClick={handleMinusClick}
        handlePlusClick={handlePlusClick}
      />
    </FormControl>
  );
}

export default QuickSelectCounterButtons;
