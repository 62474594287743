import { Button, Stack, useMediaQuery, useTheme } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { Dispatch, SetStateAction, useState } from "react";
import CustomDatePicker from "../../../../core-components/inputs/DatePicker/CustomDatePicker";
import MainSelectSelection from "../../../../core-components/inputs/Select/MainSelectSelection";
import MainTextField from "../../../../core-components/inputs/TextField/MainTextField";
import { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";

type Discount = {
  name: string;
  amount: string;
  currency: string;
};

type ExtraCharge = {
  name: string;
  amount: string;
  currency: string;
};

interface Props {
  setStep: Dispatch<SetStateAction<number>>;
  start?: Dayjs;
  end?: Dayjs;
}

function StepOne(props: Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const { setStep, start, end } = props;

  const [selectedGuests, setSelectedGuests] = useState("");
  const [discounts, setDiscounts] = useState<Discount[]>([
    { name: "", amount: "", currency: "" },
  ]);

  const [extraCharges, setExtraCharges] = useState<ExtraCharge[]>([
    { name: "", amount: "", currency: "" },
  ]);

  const handleGuestsChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedGuests(event.target.value as string);
  };


  const handleAddDiscount = () => {
    setDiscounts([...discounts, { name: "", amount: "", currency: "" }]);
  };

  const handleDiscountChange = (
    event: React.ChangeEvent<{ value: unknown }>,
    index: number,
    key: keyof Discount
  ) => {
    const newDiscounts = [...discounts];
    newDiscounts[index][key] = event.target.value as string;
    setDiscounts(newDiscounts);
  };

  const handleAddExtraCharge = () => {
    setExtraCharges([...extraCharges, { name: "", amount: "", currency: "" }]);
  };

  const handleExtraChargeChange = (
    event: React.ChangeEvent<{ value: unknown }>,
    index: number,
    key: keyof ExtraCharge
  ) => {
    const newExtraCharges = [...extraCharges];
    newExtraCharges[index][key] = event.target.value as string;
    setExtraCharges(newExtraCharges);
  };
  return (
    <>
      <Stack
        spacing={2}
        sx={{
          width: "100%",
          paddingBottom: "24px",
          borderBottom: "1px solid var(--stroke-colors, #EBEBEB)",
        }}
      >
        <Stack spacing={2} sx={{ width: "100%" }}>
          <p
            className="B-14-24-500"
            style={{ color: "#181C32", width: "100%" }}
          >
            {t("createreservationpage.checkinAndCheckOutDates")}
          </p>
          <Stack
            direction="row"
            spacing={1}
            alignItems={"center"}
            sx={{ width: "100%" }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <CustomDatePicker label="Check-in" date={start} />
            </LocalizationProvider>
            <p className="B-14-24-500">{t("createreservationpage.to")}</p>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <CustomDatePicker label="Check-out" date={end} />
            </LocalizationProvider>
          </Stack>
        </Stack>
        <Stack spacing={2} sx={{ width: "100%" }}>
          <p
            className="B-14-24-500"
            style={{ color: "#181C32", width: "100%" }}
          >
            {t("createreservationpage.numberofguests")}
          </p>
          <MainSelectSelection
            value={selectedGuests}
            onChange={handleGuestsChange}
            label={t("createreservationpage.selectNumberOfGuests")}
            options={[
              { value: "1", label: "1" },
              { value: "2", label: "2" },
              { value: "3", label: "3" },
            ]}
          />
        </Stack>
      </Stack>

      <Stack spacing={1} sx={{ width: "100%" }}>
        {isMobile
          ? discounts.map((discount, index) => (
            <Stack key={index} spacing={2} sx={{ width: "100%" }}>
              <p
                className="B-14-24-500"
                style={{ color: "#181C32", width: "100%" }}
              >
                {index === 0
                  ? t("createreservationpage.applyDiscount")
                  : `${t("createreservationpage.applyDiscount")} ${index + 1
                  }`}
              </p>
              <MainTextField
                title=""
                onChange={(e) => handleDiscountChange(e, index, "name")}
                value={discount.name}
                placeholder={t("createreservationpage.discountname")}
              />
              <Stack direction={"row"} spacing={1}>
                <MainTextField
                  title=""
                  type="number"
                  onChange={(e) => handleDiscountChange(e, index, "amount")}
                  value={discount.amount}
                  placeholder={t("createreservationpage.amount")}
                />
                <MainSelectSelection
                  value={discount.currency}
                  onChange={(e) => handleDiscountChange(e, index, "currency")}
                  label={t("createreservationpage.currency")}
                  options={[
                    { value: "USD", label: "USD" },
                    { value: "EUR", label: "EUR" },
                    { value: "GBP", label: "GBP" },
                    { value: "TRY", label: "TRY" },
                  ]}
                />
              </Stack>
            </Stack>
          ))
          : discounts.map((discount, index) => (
            <Stack key={index} spacing={2} sx={{ width: "100%" }}>
              <p
                className="B-14-24-500"
                style={{ color: "#181C32", width: "100%" }}
              >
                {index === 0
                  ? t("createreservationpage.applyDiscount")
                  : `${t("createreservationpage.applyDiscount")} ${index + 1
                  }`}
              </p>
              <Stack direction={"row"} spacing={1}>
                <MainTextField
                  title=""
                  onChange={(e) => handleDiscountChange(e, index, "name")}
                  value={discount.name}
                  placeholder={t("createreservationpage.discountname")}
                />
                <MainTextField
                  title=""
                  type="number"
                  onChange={(e) => handleDiscountChange(e, index, "amount")}
                  value={discount.amount}
                  placeholder={t("createreservationpage.amount")}
                />
                <MainSelectSelection
                  value={discount.currency}
                  onChange={(e) => handleDiscountChange(e, index, "currency")}
                  label={t("createreservationpage.currency")}
                  options={[
                    { value: "USD", label: "USD" },
                    { value: "EUR", label: "EUR" },
                    { value: "GBP", label: "GBP" },
                    { value: "TRY", label: "TRY" },
                  ]}
                />
              </Stack>
            </Stack>
          ))}
        <Button
          style={{
            backgroundColor: "transparent",
            width: "160px",
            textDecoration: "underline",
            alignSelf: "flex-end",
          }}
          onClick={handleAddDiscount}
        >
          <p className="B-14-16-600" style={{ textDecoration: "underline" }}>
            {t("createreservationpage.addanotherdiscount")}
          </p>
        </Button>
      </Stack>

      <Stack spacing={1} sx={{ width: "100%" }}>
        {isMobile
          ? extraCharges.map((charge, index) => (
            <Stack key={index} spacing={2} sx={{ width: "100%" }}>
              <p
                className="B-14-24-500"
                style={{ color: "#181C32", width: "100%" }}
              >
                {index === 0
                  ? t("createreservationpage.applyCharge")
                  : `${t("createreservationpage.applyCharge")} ${index + 1}`}
              </p>
              <MainTextField
                title=""
                onChange={(e) => handleExtraChargeChange(e, index, "name")}
                value={charge.name}
                placeholder={t("createreservationpage.chargename")}
              />
              <Stack direction={"row"} spacing={1}>
                <MainTextField
                  title=""
                  type="number"
                  onChange={(e) =>
                    handleExtraChargeChange(e, index, "amount")
                  }
                  value={charge.amount}
                  placeholder={t("createreservationpage.amount")}
                />
                <MainSelectSelection
                  value={charge.currency}
                  onChange={(e) =>
                    handleExtraChargeChange(e, index, "currency")
                  }
                  label={t("createreservationpage.currency")}
                  options={[
                    { value: "USD", label: "USD" },
                    { value: "EUR", label: "EUR" },
                    { value: "GBP", label: "GBP" },
                    { value: "TRY", label: "TRY" },
                  ]}
                />
              </Stack>
            </Stack>
          ))
          : extraCharges.map((charge, index) => (
            <Stack key={index} spacing={2} sx={{ width: "100%" }}>
              <p
                className="B-14-24-500"
                style={{ color: "#181C32", width: "100%" }}
              >
                {index === 0
                  ? t("createreservationpage.applyCharge")
                  : `${t("createreservationpage.applyCharge")} ${index + 1}`}
              </p>
              <Stack direction={"row"} spacing={1}>
                <MainTextField
                  title=""
                  onChange={(e) => handleExtraChargeChange(e, index, "name")}
                  value={charge.name}
                  placeholder={t("createreservationpage.chargename")}
                />
                <MainTextField
                  title=""
                  type="number"
                  onChange={(e) =>
                    handleExtraChargeChange(e, index, "amount")
                  }
                  value={charge.amount}
                  placeholder={t("createreservationpage.amount")}
                />
                <MainSelectSelection
                  value={charge.currency}
                  onChange={(e) =>
                    handleExtraChargeChange(e, index, "currency")
                  }
                  label={t("createreservationpage.currency")}
                  options={[
                    { value: "USD", label: "USD" },
                    { value: "EUR", label: "EUR" },
                    { value: "GBP", label: "GBP" },
                    { value: "TRY", label: "TRY" },
                  ]}
                />
              </Stack>
            </Stack>
          ))}
        <Button
          style={{
            backgroundColor: "transparent",
            width: "160px",
            textDecoration: "underline",
            alignSelf: "flex-end",
          }}
          onClick={handleAddExtraCharge}
        >
          <p className="B-14-16-600" style={{ textDecoration: "underline" }}>
            {t("createreservationpage.addanothercharge")}
          </p>
        </Button>
      </Stack>

      <Stack
        spacing={4}
        sx={{
          padding: "16px 12px",
          borderRadius: "12px",
          border: "1px solid var(--stroke-colors, #EBEBEB)",
          background: "var(--Gray-Gray-100, #F9F9F9)",
        }}
      >
        <p className="H-20-20-600">
          {t("createreservationpage.priceBreakdown")}
        </p>
        <Stack spacing={2}>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <p className="B-16-25-500" style={{ color: "#3F4254" }}>
              {t("createreservationpage.averagepricepernight")}
            </p>
            <p className="B-16-25-500" style={{ color: "#3F4254" }}>
              € 3,600.00
            </p>
          </Stack>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <p className="B-16-25-500" style={{ color: "#3F4254" }}>
              {t("createreservationpage.accommodationtotal")}
            </p>
            <p className="B-16-25-500" style={{ color: "#3F4254" }}>
              € 100.00
            </p>
          </Stack>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <p className="B-16-25-500" style={{ color: "#3F4254" }}>
              {t("createreservationpage.cleaningfee")}
            </p>
            <p className="B-16-25-500" style={{ color: "#3F4254" }}>
              € 0.00
            </p>
          </Stack>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <p className="B-16-25-500" style={{ color: "#3F4254" }}>
              {t("createreservationpage.subtotal")}
            </p>
            <p className="B-16-25-500" style={{ color: "#3F4254" }}>
              € 200.00
            </p>
          </Stack>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <p className="B-16-16-600" style={{ color: "#3F4254" }}>
              {t("createreservationpage.totalpayout")} (EUR)
            </p>
            <p className="B-16-16-600" style={{ color: "#3F4254" }}>
              € 3,900.00
            </p>
          </Stack>
        </Stack>
      </Stack>
      <Button
        className="B-16-16-600"
        style={{
          backgroundColor: "#0065E0",
          color: "#FFFFFF",
          padding: "16px 24px",
          borderRadius: "50px",
          marginTop: "48px",
          height: "48px",
          alignItems: "center",
          justifyContent: "center",
          alignSelf: "flex-end",
        }}
        onClick={() => setStep(2)}
      >
        {t("createreservationpage.nextEnterGuestDetails")}
      </Button>
    </>
  );
}

export default StepOne;
