import { useTranslation } from "react-i18next";
import DetailsInputArea from "../../../../../../../../components/shared-components/DetailsInputArea";
import ListingStatus from "../../../../components/modals/ListingStatus";
import RentalBasis from "../../../../components/modals/RentalBasis";
import Entrance from "../../../../components/modals/Entrance";
import RentalLicenceNumber from "../../../../components/inputs/RentalLicenceNumber";
import InternalListingNumber from "../../../../components/inputs/InternalListingNumber";
import Floorspace from "../../../../components/inputs/Floorspace";
import TypeOfRentals from "../../../../components/modals/TypeOfRentals";

function BasicDetails() {
  const { t } = useTranslation();
  const data = [
    {
      title: t("listingDetails.listingstatus"),
      subtitle: t("listingDetails.listedandguests"),
      component: <ListingStatus showTitle={true} />,
      isSelect: true,
      width: "812",
    },
    {
      title: t("listingDetails.internalreference"),
      subtitle: t("listingDetails.notset"),
      component: <InternalListingNumber />,
      isSelect: false,
    },
    {
      title: t("listingDetails.typeofrental"),
      subtitle: t("listingDetails.notset"),
      component: <TypeOfRentals />,
      isSelect: true,
      width: "474",
    },
    {
      title: t("listingDetails.rentalbasis"),
      subtitle: t("listingDetails.notset"),
      component: <RentalBasis />,
      isSelect: true,
      width: "464",
    },
    {
      title: t("listingDetails.entrance"),
      subtitle: t("listingDetails.notset"),
      component: <Entrance />,
      isSelect: true,
      width: "464",
    },
    {
      title: t("listingDetails.rentallicence"),
      subtitle: t("listingDetails.notset"),
      component: <RentalLicenceNumber />,
      isSelect: false,
    },
    {
      title: t("listingDetails.floorspace"),
      subtitle: t("listingDetails.notset"),
      component: <Floorspace />,
      isSelect: false,
    },
  ];
  return (
    <>
      <DetailsInputArea
        title={t("listingDetails.basicdetails")}
        data={data}
        onClick={() => {}}
        id={"basic-details"}
      />
    </>
  );
}

export default BasicDetails;
