import { Tooltip, TooltipProps } from "@mui/material";
import { styled } from "@mui/system";

interface CustomTooltipProps extends TooltipProps {
  offset?: [number, number];
  minWidth?: number;
}

const CustomTooltip = styled(
  ({ className, offset, minWidth, ...props }: CustomTooltipProps) => (
    <Tooltip
      {...props}
      classes={{ tooltip: className }}
      placement="top"
      PopperProps={{
        popperOptions: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset,
              },
            },
          ],
        },
      }}
    />
  )
)`
  display: flex;
  min-width: ${({ minWidth }) => minWidth || 20}px;
  min-height: var(--Icon-icon-lg, 24px);
  padding: 8px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  border-radius: 10px;
  background: #020e1e;
  color: #f5eff7;
  font-size: 12px;
  font-weight: 500;
`;

export default CustomTooltip;
