import React, { useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { IconButton, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "../../assets/images/Avatar.png";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Logo from "../../assets/images/Logo.png";
import ArrowDownSvg from "../../assets/images/icons/linear-arrow-down.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import arrowBack from "../../assets/images/icons/arrow-left.svg";
import { inputHeaderStyles } from "./HeaderStyles";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import Badge from "@mui/material/Badge";
import { useDispatch } from "react-redux";
import { clearUserToken } from "../../store/slices/globalSlice";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { shallowEqual } from "react-redux";
import { fetchUnreadCount } from "../../store/slices/chatSlice";
import { flushOnboardState } from "../../store/slices/onboardSlice";

interface HeaderProps {
  isOnboarding: boolean;
  setIsOnBoarding: (isOnboarding: boolean) => void;
  unreadCount?: number;
}

const Header = ({ isOnboarding }: HeaderProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { user, logout, getAccessTokenSilently } = useAuth0();
  const unreadCount = useSelector((state: RootState) => state.chat.tabMessageCounts.unread, shallowEqual)
  const dispatch: AppDispatch = useDispatch();



  const settings = [
    { name: `${t("header.profile")}`, route: "/profile" },
    { name: `${t("header.logout")}`, route: "/" },
  ];

  const pages = [
    // { name: `${t("header.today")}`, route: "/" },
    // { name: `${t("header.calendar")}`, route: "/calendar" },
    { name: `${t("header.listing")}`, route: "/listings" },
    {
      name: `${t("header.inbox")}`,
      route: "/inbox",
      badge: unreadCount,
    },
    { name: `${t("On Boarding")}`, route: "/onboarding" },
    { name: `${t("header.settings")}`, route: "/settings" },
  ];

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  useEffect(() => {
    const fetchUnread = async () => {
      const accessToken = await getAccessTokenSilently()
      dispatch(fetchUnreadCount({ accessToken }))
    }
    fetchUnread()
  }, [])

  const handleExitAndSave = () => {
    sessionStorage.clear();
    dispatch(flushOnboardState())
    navigate("/listings");
  };

  return (
    <AppBar className={`appBar ${isOnboarding ? "onboarding" : ""}`} >
      <Toolbar disableGutters className="toolbar">
        <Stack padding={"16px 0"}>
          <Link to="/inbox" >
            <img
              style={{
                cursor: "pointer",
              }}
              src={Logo}
              alt="Botel Logo"
              className="logo"
            />
          </Link>
        </Stack>
        {isOnboarding ? (
          <button onClick={handleExitAndSave} className="button-header-save-and-exit">
            <img src={arrowBack} alt="arrow-back" className="button-icon" />
            <span className="buttonText B-16-16-600">Exit & Save</span>
          </button>
        ) : (
          <>
            <Box className="menu">
              {pages.map((page, index) => (
                <Button
                  sx={{
                    display: "flex",
                    padding: "8px 4px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "16px",
                    borderRadius: "6px",
                    fontSize: "16px",
                    fontWeight: 600,
                    fontStyle: "normal",
                    lineHeight: "16px",
                    fontFamily: "Inter",
                    position: "relative",
                    minWidth: "85px",
                  }}
                  key={page.name}
                  className={`button ${location.pathname === page.route ? "active" : ""}`}
                  component={Link}
                  to={page.route}
                >
                  <span
                    className={`buttonText ${index === 0 ? "first" : ""} ${location.pathname === page.route ? "active" : ""
                      }`}
                  >
                    {page.name}
                  </span>
                  {page.badge && page.badge > 0 ? (
                    <Badge
                      badgeContent={page.badge}
                      sx={{
                        "& .MuiBadge-badge": {
                          backgroundColor: "#EF4444",
                          color: "white",
                          fontSize: "11px",
                          minWidth: "28px",
                          height: "18px",
                          padding: "0 4px",
                          top: "0px",
                          right: "-1px",
                        },
                      }}
                    />
                  ) : null}
                </Button>
              ))}
              {/*<IconButton onClick={handleOpenMenu} disableRipple>
                <Box
                  sx={{
                    display: "flex",
                    padding: "8px 4px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                    borderRadius: "6px",
                    fontSize: "16px",
                    fontWeight: 600,
                    fontStyle: "normal",
                    lineHeight: "16px",
                    fontFamily: "Inter",
                  }}
                  className={`button `}
                  component={Link}
                >
                  {t("header.menu")}
                </Box>
                <img
                  style={{
                    width: "24px",
                    height: "24px",
                  }}
                  src={ArrowDownSvg}
                  alt="Arrow Down"
                  className="arrowDown"
                />
              </IconButton>
              {/*<Menu
                sx={inputHeaderStyles.settingsMenu}
                id="menu-appbar"
                anchorEl={anchorElMenu}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                open={Boolean(anchorElMenu)}
                onClose={handleCloseMenu}
              >
                {/* <MenuItem
                  onClick={() => {
                    setAnchorElMenu(null);
                    navigate("/reservations");
                  }}
                  className="menuItem"
                  style={{
                    marginBottom: "24px",
                    width: "100px",
                    padding: "0 5px",
                  }}
                > */}
              {/* <Container className="menuArea">
                    <Typography className="B-20-30-500">
                      {t("header.reservations")}
                    </Typography>
                  </Container>
                </MenuItem> 
                <MenuItem
                  onClick={() => {
                    setAnchorElMenu(null);
                    navigate("/onboardingtwo");
                  }}
                  className="menuItem"
                  sx={{
                    marginBottom: "5px",
                    width: "100px",
                    padding: "8px 8px",
                    borderRadius: "8px",
                    fontSize: "16px",
                    transition: "all 0.2s ease",
                    "&:hover": {
                      backgroundColor: "#f5f5f5",
                    },
                  }}
                >
                  <Container className="menuArea">
                    <Typography className="B-20-30-500">
                      {t("On Boarding")}
                    </Typography>
                  </Container>
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    setAnchorElMenu(null);
                    navigate("/settings");
                  }}
                  className="menuItem"
                  sx={{
                    marginBottom: "0px",
                    fontFamily: "Inter",
                    width: "100px",
                    padding: "8px 8px",
                    borderRadius: "8px",
                    transition: "all 0.2s ease",
                    "&:hover": {
                      backgroundColor: "#f5f5f5",
                    },
                  }}
                >
                  <Container className="menuArea">
                    <Typography className="B-20-30-500">
                      {t("header.settings")}
                    </Typography>
                  </Container>
                </MenuItem>
              </Menu> */}
            </Box>
            <Stack direction="row" justifyContent={"end"} spacing={1}>
              {/* <Stack className="userBox" direction={"row"}>
                {!isSearchVisible ? (
                  <IconButton onClick={handleOpenSearch}>
                    <SearchIcon style={{ width: "20px", height: "20px" }} />
                  </IconButton>
                ) : (
                  <InputBase
                    ref={searchInputRef}
                    onMouseLeave={handleCloseSearch}
                    startAdornment={
                      <IconButton onClick={handleOpenSearch}>
                        <SearchIcon />
                      </IconButton>
                    }
                    placeholder="Search..."
                    sx={{
                      color: "#000",
                      "& .MuiSvgIcon-root": {
                        marginRight: "12px",
                      },
                      "&.Mui-focused": {
                        backgroundColor: alpha("#fff", 0.25),
                      },
                      width: "100%",
                      transition: "width 0.3s",
                      borderBottom: "1px solid #E1E3EA",
                    }}
                  />
                )}
              </Stack> */}
              <Box className="userBox">
                <IconButton
                  onClick={handleOpenUserMenu}
                  className="iconButton"
                  disableRipple
                >
                  <img
                    style={{
                      width: "30px",
                      height: "30px",
                    }}
                    src={user?.picture || Avatar}
                    alt="Avatar"
                    className="avatar"
                  />
                  <img
                    src={ArrowDownSvg}
                    alt="Arrow Down"
                    className="arrowDown"
                  />
                </IconButton>
                <Menu
                  sx={inputHeaderStyles.iconMenu}
                  PaperProps={{
                    className: "menuPaper",
                  }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting, index) => (
                    <MenuItem
                      key={setting.name}
                      onClick={() => {
                        if (setting.name === `${t("header.logout")}`) {
                          sessionStorage.clear();
                          localStorage.clear();
                          dispatch(clearUserToken())
                          handleCloseUserMenu();
                          logout({
                            logoutParams: {
                              returnTo: window.location.origin,
                            },
                          });

                        } else {
                          navigate(setting.route);
                        }
                      }}
                      className="menuItem"
                      style={{
                        marginBottom:
                          index !== settings.length - 1 ? "24px" : "0px",
                        width: "120px",
                      }}
                      component={Link}
                      to={setting.route}
                    >
                      <Container className="menuArea">
                        <Typography className="B-20-30-500">
                          {setting.name}
                        </Typography>
                      </Container>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </Stack>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
