interface Props {
  bgColor?: string;
  color?: string;
}

function AgreeIcon(props: Props) {
  const { bgColor, color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
    >
      <rect
        x="0.5"
        y="0.5"
        width="37"
        height="37"
        rx="18.5"
        stroke={bgColor || ""}
        fill={bgColor ? "" : "#181C32"}
      />
      <path
        d="M17.8336 23.1659C17.7239 23.1665 17.6152 23.1455 17.5137 23.1041C17.4122 23.0626 17.3198 23.0015 17.242 22.9242L13.417 19.0659C13.26 18.909 13.1719 18.6962 13.1719 18.4742C13.1719 18.2523 13.26 18.0395 13.417 17.8826C13.5739 17.7257 13.7867 17.6375 14.0086 17.6375C14.2305 17.6375 14.4434 17.7257 14.6003 17.8826L17.8336 21.1242L23.392 15.5576C23.5489 15.4007 23.7617 15.3125 23.9836 15.3125C24.2055 15.3125 24.4184 15.4007 24.5753 15.5576C24.7322 15.7145 24.8204 15.9273 24.8204 16.1492C24.8204 16.3712 24.7322 16.584 24.5753 16.7409L18.4253 22.8909C18.2709 23.0554 18.0589 23.154 17.8336 23.1659Z"
        fill={color || "white"}
      />
    </svg>
  );
}

export default AgreeIcon;
