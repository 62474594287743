import React from "react";
import BookingRules from "./components/booking-rules";
import Suitability from "./components/suitability";
import HouseRules from "./components/house-rules";
import BeforeBooking from "./components/before-booking";
import AfterBooking from "./components/after-booking";
import AdvanceNotice from "./components/advance-notice";
import GuestBookingOptions from "./components/guest-booking-options";

interface Props {
  subMenu: string;
  setSubMenu: React.Dispatch<React.SetStateAction<string>>;
}

function Policies(props: Props) {
  const { subMenu, setSubMenu } = props;
  return (
    <>
      {subMenu === "booking" ? (
        <>
        <AdvanceNotice/>
        <GuestBookingOptions/>
        </>
      ) : (
        <>
          <BookingRules setSubMenu={setSubMenu} />
          <Suitability />
          <HouseRules />
          <BeforeBooking />
          <AfterBooking />
        </>
      )}
    </>
  );
}

export default Policies;
