import React from "react";
import CheckInCheckOut from "../../views/pricing/components/modals/CheckInCheckOut";
import { useTranslation } from "react-i18next";

function OtherRooms() {
  const { t } = useTranslation();
  const totalBoxes = 13;
  const boxesPerRow = 3;
  const labels = [
    "Common area",
    "Eating area",
    "Common area",
    "Common area",
    "Common area",
    "Common area",
    "Common area",
    "Common area",
    "Common area",
    "Common area",
    "Common area",
    "Common area",
    "Common area",
  ];

  return (
    <CheckInCheckOut
      totalBoxes={totalBoxes}
      boxesPerRow={boxesPerRow}
      labels={labels}
      title={t("listingDetails.otherrooms")}
    />
  );
}

export default OtherRooms;
