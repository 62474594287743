import axios from "axios";
import { BASE_API_URL } from ".";
import { FiltersObject } from "../store/slices/filterSlice";


const API_BASE_URL = BASE_API_URL

export interface FetchMessagesParams {
  accessToken: string;
  page: number;
  limit: number;
  filters?: FiltersObject
  searchKey?: string;
}

export const fetchFilteredMessagesApi = async ({
  page,
  limit,
  accessToken,
  filters,
  searchKey,
}: FetchMessagesParams) => {
  try {
    const params = new URLSearchParams();

    console.log(filters)
    params.append("PageIndex", page.toString());
    params.append("PageSize", limit.toString());

    // Search Key
    if (searchKey) {
      params.append("Filter", searchKey);
    }

    if (filters) {

      // Assigned Usesr
      if (filters.assignedUsers?.length > 0) {
        filters.assignedUsers.forEach((user) => {
          params.append("AssignedUsers", user);
        });
      }

      // Sources (Channels)
      if (filters.sources?.length > 0) {
        filters.sources.forEach((sourceId) => {
          params.append("Sources", sourceId);
        });
      }

      // Cities
      if (filters.cities?.length > 0) {
        filters.cities.forEach((city) => {
          params.append("Cities", city);
        });
      }

      // Property IDs
      if (filters.propertyIds?.length > 0) {
        filters.propertyIds.forEach((propertyId) => {
          params.append("PropertyIds", propertyId);
        });
      }

      // Tags
      if (filters.tags?.length > 0) {
        filters.tags.forEach((tag) => {
          params.append("Tags", tag);
        });
      }

      // Booking Status
      if (filters.bookingStatus) {
        params.append("BookingStatus", filters.bookingStatus);
      }

      // Read Status
      if (filters.isUnreadOnly) {
        params.append("IsRead", "false");
      }
      // Not Replied
      //  params.append("NotReplied", filters.notReplied ? "true" : "false");
      //  //isNeedAttention
      //  params.append(
      //    "IsNeedAttention",
      //    filters.isNeedAttention ? "true" : "false"
      //  );
    }

    const response = await axios.get(
      `${API_BASE_URL}/Message/Get?${params.toString()}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || "Error fetching messages.");
  }
}


export interface GetAllProperties {
  accessToken: string;
}

export const getAllPropertiesApi = async ({ accessToken }: GetAllProperties) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/Property/GetAll`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return response
  } catch (error: any) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch the message."
    );
  };
}


export interface GetAllMessageSourcesParams {
  accessToken: string;
}

export const getAllMessageSourcesApi = async ({ accessToken }: GetAllMessageSourcesParams) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/Message/MessageSource`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        }
      }
    );
    return response
  } catch (error: any) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch the message."
    );
  };
}

export interface GetAllUsersParams {
  accessToken: string;
}

export const getAllUsersApi = async ({ accessToken }: GetAllUsersParams) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/User/Get`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        }
      }
    );
    return response
  } catch (error: any) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch the message."
    );
  };
}

export interface GetAllCitiesParams {
  accessToken: string;
  customerId: string
}

export const getAllCitiesApi = async ({ accessToken, customerId }: GetAllCitiesParams) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/Property/GetPropertyCities?CustomerId=${customerId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        }
      }
    );
    return response
  } catch (error: any) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch the message."
    );
  };
}

export interface GetAllTagsParams {
  accessToken: string;
}

export const getAllTagsByRelationApi = async ({ accessToken }: GetAllTagsParams) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/Tag/GetTagsByRelationType`, {
      params: {
        RelationType: "property"
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response
  } catch (error: any) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch the message."
    );
  };
}



