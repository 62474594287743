import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import HeaderTitle from "../components/HeaderTitle";
import MainTextField from "../../../../components/core-components/inputs/TextField/MainTextField";
import CustomSelect from "../../../../components/core-components/inputs/Select/CustomSelect";
import MessageCreator from "../components/MessageCreator";
import CustomButton from "../../../../components/shared-components/CustomButton";
import MobileHeader from "../../../../components/shared-components/MobileHeader";
import { useNavigate } from "react-router-dom";
import CustomModalMobile from "../../../../components/shared-components/CustomModalMobile";

const Retargeting = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isSwitchChecked, setIsSwitchChecked] = useState(true);
  const [distance, setDistance] = useState<string | number | null>(null);
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate("/menu");
  };
  const [openSetTemplateMessagesModal, setOpenSetTemplateMessagesModal] =
    useState(false);
  const [openWhenSendOffersModal, setOpenWhenSendOffersModal] = useState(false);
  const [recommendMessageInput, setRecommendMessageInput] = useState<
    string | number | null
  >(null);
  const [distanceMessageInput, setDistanceMessageInput] = useState<
    string | number | null
  >(null);
  const [sendRetargetingMessageInput, setSendRetargetingMessageInput] =
    useState<string | number | null>(null);
  const [offerCodeMessageInput, setOfferCodeMessageInput] = useState<
    string | number | null
  >(null);

  const chipLabels = [
    "{guest_first_name}",
    "{guest_surname}",
    "{host_first_name}",
    "{host_surname}",
    "{listing_name}",
    "{listing_address}",
    "{listing_location}",
    "{length_of_stay}",
    "{check_in_time}",
    "{check_in_date}",
    "{check_out_time}",
    "{check_out_date}",
    "{guest_booking_link}",
    "{guest_email}",
    "{guest_phone}",
    "{guest_phone_last_4_digits}",
    "{guest_phone_last_5_digits}",
    "{guest_phone_last_6_digits}",
    "{number_of_guests}",
    "{booking_site_name}",
    "{wifi_name}",
    "{wifi_password}",
    "{arrival_time}",
    "{departure_time}",
    "{todays_date}",
    "{direct_booking_website}",
    "{multi_unit_name}",
    "{lock_code}",
  ];

  const [messages, setMessages] = useState([
    {
      title: "Same listing available, 0 similar properties",
      messageText: `
    Hi {guest_first_name},<br /><br />
    Just wanted to let you know we have some extra availability in case you wanted to {checkin_or_extend_stay} at a {discount_percentage} discount. Here are the details:<br /><br />     
    {offer_details}<br /><br />   
    Let me know if you’re interested and look forward to hosting you!
    `,
    },
    {
      title: "Same listing available, 1 similar property",
      messageText: `Hi {guest_first_name},<br /><br />
    Just wanted to let you know we have some extra availability in case you wanted to {checkin_or_extend_stay} at a {discount_percentage} discount. Here are the details:<br /><br />     
    {offer_details}<br /><br />   
    Let me know if you’re interested and look forward to hosting you!`,
    },
    {
      title: "Same listing unavailable, 1 similar property",
      messageText: ` Hi {guest_first_name},<br /><br />
    Just wanted to let you know we have some extra availability in case you wanted to {checkin_or_extend_stay} at a {discount_percentage} discount. Here are the details:<br /><br />     
    {offer_details}<br /><br />   
    Let me know if you’re interested and look forward to hosting you!`,
    },
  ]);

  const handleSaveMessages = () => {
    setOpenSetTemplateMessagesModal(false);
  };

  return (
    <Stack
      width={"100%"}
      className={
        !isMobile
          ? "acc-users-box-multiple-title"
          : "acc-users-box-mobile-multiple-title"
      }
      pb={20}
      gap={isMobile ? 0 : 3}
    >
      {isMobile && (
        <MobileHeader
          title="Retargeting"
          description="Botel can offer to re-host a guest who stayed in the past."
          handleBackClick={handleBackClick}
          isSwitchShown={true}
          switchChecked={isSwitchChecked}
          onSwitchChange={() => {
            setIsSwitchChecked(!isSwitchChecked);
          }}
        />
      )}
      {!isMobile && (
        <Stack
          justifyContent="flex-start"
          alignSelf={"flex-start"}
          alignItems="center"
          width={"100%"}
          maxWidth={isMobile ? "100%" : "812px"}
        >
          <HeaderTitle
            title="Retargeting"
            subtitle="Botel can offer to re-host a guest who stayed in the past."
            isSwitchShown={true}
            switchChecked={isSwitchChecked}
            onSwitchChange={() => {
              setIsSwitchChecked(!isSwitchChecked);
            }}
          />
        </Stack>
      )}

      <Box
        alignSelf={"flex-start"}
        width={"100%"}
        maxWidth={isMobile ? "100%" : "812px"}
      >
        <Stack
          spacing={3}
          paddingY={isMobile ? 3 : 2}
          borderBottom={"1px solid #EBEBEB"}
          maxWidth={isMobile ? "100%" : "858px"}
        >
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            width={"100%"}
            alignContent={"center"}
            alignItems={"center"}
          >
            <p
              className="B-18-18-600"
              style={{ color: "#181C32", lineHeight: "20px" }}
            >
              When to send offers (optional):
            </p>
            {isMobile && (
              <CustomButton
                backgroundColor="transparent"
                color="#181C32"
                onClick={() => setOpenWhenSendOffersModal(true)}
                buttonText="Edit"
                marginTop={0}
                textDecoration="underline"
              />
            )}
          </Stack>
          {!isMobile && (
            <Stack
              direction={"row"}
              spacing={0}
              alignItems={"center"}
              width={"100%"}
              maxWidth={isMobile ? "100%" : "858px"}
              flexWrap="wrap"
            >
              <p
                className="B-14-20-500"
                style={{ color: "#5E6278", lineHeight: "20px" }}
              >
                Recommend
              </p>
              <Stack width={"100px"} p={1}>
                <MainTextField
                  title={""}
                  placeholder={"100"}
                  type={"number"}
                  value={recommendMessageInput || ""}
                  onChange={(event) =>
                    setRecommendMessageInput(event.target.value)
                  }
                  sx={{
                    "& .MuiInputBase-input": {
                      height: "0.45em",
                    },
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#A1A5B7",
                      },
                  }}
                />
              </Stack>
              <p
                className="B-14-20-500"
                style={{ color: "#5E6278", lineHeight: "20px" }}
              >
                similar properties (holding up to 3 more guests) that are have
                availabilities within 2 weeks of
              </p>

              <p
                className="B-14-20-500"
                style={{ color: "#5E6278", lineHeight: "20px" }}
              >
                their stay last year, restricting to the surrounding area of
              </p>
              <Stack width={"100px"} p={"8px 0px 8px 8px"}>
                <MainTextField
                  title={""}
                  placeholder={"100"}
                  type={"number"}
                  value={distanceMessageInput || ""}
                  onChange={(event) =>
                    setDistanceMessageInput(event.target.value)
                  }
                  sx={{
                    "& .MuiInputBase-input": {
                      height: "0.45em",
                    },
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#A1A5B7",
                      },
                  }}
                />
              </Stack>
              <Stack width={"100px"} p={1}>
                <CustomSelect
                  value={distance || ""}
                  pagetype="selectWithParagraphHeight"
                  onChange={(event: any) => setDistance(event.target.value)}
                  label="KM"
                  options={[
                    {
                      value: "m",
                      label: "M",
                    },
                    {
                      value: "yard",
                      label: "Yard",
                    },
                    {
                      value: "Miles",
                      label: "Miles",
                    },
                  ]}
                />
              </Stack>
              <p
                className="B-14-20-500"
                style={{ color: "#5E6278", lineHeight: "20px" }}
              >
                around where the guest previously stayed
              </p>
              <p
                className="B-14-20-500"
                style={{ color: "#5E6278", lineHeight: "20px" }}
              >
                Send retargeting message
              </p>
              <Stack width={"100px"} p={1}>
                <MainTextField
                  title={""}
                  placeholder={"100"}
                  type={"number"}
                  value={sendRetargetingMessageInput || ""}
                  onChange={(event: any) =>
                    setSendRetargetingMessageInput(event.target.value)
                  }
                  sx={{
                    "& .MuiInputBase-input": {
                      height: "0.45em",
                    },
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#A1A5B7",
                      },
                  }}
                />
              </Stack>
              <p
                className="B-14-20-500"
                style={{ color: "#5E6278", lineHeight: "20px" }}
              >
                months after the guest's stay
              </p>
              <p
                className="B-14-20-500"
                style={{ color: "#5E6278", lineHeight: "20px" }}
              >
                Offer code
              </p>
              <Stack width={"100px"} p={1}>
                <MainTextField
                  title={""}
                  placeholder={"100"}
                  type={"number"}
                  value={offerCodeMessageInput || ""}
                  onChange={(event: any) =>
                    setOfferCodeMessageInput(event.target.value)
                  }
                  sx={{
                    "& .MuiInputBase-input": {
                      height: "0.45em",
                    },
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#A1A5B7",
                      },
                  }}
                />
              </Stack>
            </Stack>
          )}
        </Stack>
        <Stack
          spacing={3}
          paddingY={isMobile ? 3 : 2}
          borderBottom={"1px solid #EBEBEB"}
          maxWidth={isMobile ? "100%" : "858px"}
        >
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            width={"100%"}
            alignContent={"center"}
            alignItems={"center"}
          >
            <p
              className="B-18-18-600"
              style={{ color: "#181C32", lineHeight: "20px" }}
            >
              Set Template Messages
            </p>
            {isMobile && (
              <CustomButton
                backgroundColor="transparent"
                color="#181C32"
                onClick={() => setOpenSetTemplateMessagesModal(true)}
                buttonText="Edit"
                marginTop={0}
                textDecoration="underline"
              />
            )}
          </Stack>
          {!isMobile && (
            <p className="B-14-20-400">
              This feature allows you to send personalized offers to guests
            </p>
          )}
        </Stack>
        {!isMobile && (
          <Stack pt={3} maxWidth={isMobile ? "100%" : "858px"}>
            <MessageCreator
              chipLabels={chipLabels}
              messages={messages}
              setMessages={setMessages}
            />
          </Stack>
        )}
      </Box>
      <CustomModalMobile
        open={openWhenSendOffersModal}
        handleClose={() => setOpenWhenSendOffersModal(false)}
        title="When to send offers (optional)"
        showFooter={false}
        handleSave={() => {}}
      >
        <Stack
          spacing={"12px"}
          width={"100%"}
          alignItems="flex-start"
          alignSelf="stretch"
        >
          <p
            className="B-14-20-500"
            style={{ color: "#5E6278", lineHeight: "20px" }}
          >
            Recommend
          </p>
          <Stack width={"100%"} direction={"row"} spacing={1}>
            <Stack flex={1}>
              <MainTextField
                title={""}
                placeholder={"100"}
                type={"number"}
                value={recommendMessageInput || ""}
                onChange={(event) =>
                  setRecommendMessageInput(event.target.value)
                }
                sx={{
                  "& .MuiInputBase-input": {
                    height: "0.45em",
                  },
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#A1A5B7",
                  },
                }}
              />
            </Stack>
          </Stack>
          <p
            className="B-14-20-500"
            style={{ color: "#5E6278", lineHeight: "20px" }}
          >
            similar properties (holding up to 3 more guests) that are have
            availabilities within 2 weeks of their stay last year, restricting
            to the surrounding area of
          </p>

          <Stack width={"100%"} direction={"row"} spacing={1}>
            <Stack flex={2}>
              <MainTextField
                title={""}
                placeholder={"100"}
                type={"number"}
                value={distanceMessageInput || ""}
                onChange={(event) =>
                  setDistanceMessageInput(event.target.value)
                }
                sx={{
                  "& .MuiInputBase-input": {
                    height: "0.45em",
                  },
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#A1A5B7",
                  },
                }}
              />
            </Stack>
            <Stack flex={1}>
              <CustomSelect
                value={distance || ""}
                pagetype="selectWithParagraphHeight"
                onChange={(event: any) => setDistance(event.target.value)}
                label="KM"
                options={[
                  {
                    value: "m",
                    label: "M",
                  },
                  {
                    value: "yard",
                    label: "Yard",
                  },
                  {
                    value: "Miles",
                    label: "Miles",
                  },
                ]}
              />
            </Stack>
          </Stack>
          <p
            className="B-14-20-500"
            style={{ color: "#5E6278", lineHeight: "20px" }}
          >
            around where the guest previously stayed
          </p>

          <p
            className="B-14-20-500"
            style={{ color: "#5E6278", lineHeight: "20px", paddingTop: "20px" }}
          >
            Send retargeting message
          </p>
          <Stack width={"100%"} direction={"row"} spacing={1}>
            <Stack flex={1}>
              <MainTextField
                title={""}
                placeholder={"100"}
                type={"number"}
                value={sendRetargetingMessageInput || ""}
                onChange={(event: any) =>
                  setSendRetargetingMessageInput(event.target.value)
                }
                sx={{
                  "& .MuiInputBase-input": {
                    height: "0.45em",
                  },
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#A1A5B7",
                  },
                }}
              />
            </Stack>
          </Stack>
          <p
            className="B-14-20-500"
            style={{ color: "#5E6278", lineHeight: "20px" }}
          >
            months after the guest's stay
          </p>
          <p
            className="B-14-20-500"
            style={{ color: "#5E6278", lineHeight: "20px" }}
          >
            Offer code
          </p>
          <Stack width={"100%"}>
            <MainTextField
              title={""}
              placeholder={"100"}
              type={"number"}
              value={offerCodeMessageInput || ""}
              onChange={(event: any) =>
                setOfferCodeMessageInput(event.target.value)
              }
              sx={{
                "& .MuiInputBase-input": {
                  height: "0.45em",
                },
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#A1A5B7",
                },
              }}
            />
          </Stack>
          <Stack width={"100%"} pt={4}>
            <CustomButton
              backgroundColor="#0065E0"
              color="#FFFFFF"
              buttonText="Save"
              marginTop={0}
              onClick={() => {
                setRecommendMessageInput(recommendMessageInput);
                setDistanceMessageInput(distanceMessageInput);
                setSendRetargetingMessageInput(sendRetargetingMessageInput);
                setOfferCodeMessageInput(offerCodeMessageInput);
                setDistance(distance);
                setOpenWhenSendOffersModal(false);
              }}
              buttonWidth="100%"
            />
          </Stack>
        </Stack>
      </CustomModalMobile>
      <CustomModalMobile
        open={openSetTemplateMessagesModal}
        handleClose={() => setOpenSetTemplateMessagesModal(false)}
        title="Set Template Messages"
        showFooter={false}
        handleSave={() => {}}
      >
        <Stack spacing={3} width={"100%"}>
          <p className="B-14-20-400">
            This feature allows you to send personalized offers to guests
          </p>
          <MessageCreator
            chipLabels={chipLabels}
            messages={messages}
            setMessages={setMessages}
          />
          <Stack width={"100%"}>
            <CustomButton
              backgroundColor="#0065E0"
              color="#FFFFFF"
              buttonText="Save"
              marginTop={0}
              onClick={() => handleSaveMessages()}
              buttonWidth="100%"
            />
          </Stack>
        </Stack>
      </CustomModalMobile>
    </Stack>
  );
};

export default Retargeting;
