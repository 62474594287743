import {
  Box,
  Button,
  Snackbar,
  SnackbarCloseReason,
  Stack,
} from "@mui/material";
import Copy from "../../../../assets/images/icons/copy.svg";

import React, { useState } from "react";
import styles from "../onboardingTwoStyles";
import arrowDown from "../../../../assets/images/arrow-down.svg";
import botel from "../../../../assets/images/icons/Asset 6.svg";
import MainTextField from "../../../../components/core-components/inputs/TextField/MainTextField";
function ChatBotAutoOff() {
  const [botelOpen, setBotelOpen] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const link = "https://botel.com"


  const toggleBotelDrawer = () => {
    setBotelOpen(!botelOpen);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(link);
    setCopySuccess(true);
  };

  const handleClose = (
    _: React.SyntheticEvent<any, Event> | Event,
    reason: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setCopySuccess(false);
  };

  return (
    <Box sx={styles.communicationContainer}>
      <Box>
        {" "}
        <p className="radioButtonDescription">
          Choose the communication channels with AI
        </p>
        <p className="secondaryDescription">
          How you want to be notified to approve AI generated answers by the
          Agency.
        </p>
      </Box>
      <Box sx={styles.innerCommunicationContainer}>
        <img
          style={{
            position: "absolute",
            right: "16px",
            top: "14px",
          }}
          src={arrowDown}
          alt="arrow"
        />
        <Stack onClick={toggleBotelDrawer} width={"100%"} gap={"8px"}>
          <Stack gap={"8px"} flexDirection={"row"}>
            {" "}
            <img
              style={{
                width: "32px",
                marginLeft: "-1px",
              }}
              className=""
              src={botel}
              alt="botel"
            />
            <p
              style={{
                fontSize: "18px",
              }}
              className="radioButtonDescription"
            >
              Botel app
            </p>
          </Stack>
          <p
            style={{
              fontSize: "16px",
            }}
            className="secondaryDescription"
          >
            Download botel app or send to team invite
          </p>
        </Stack>
        {botelOpen && (
          <>
            {" "}
            <Stack
              sx={{
                width: "100%",
              }}
              alignItems={"center"}
              gap={"12px"}
              flexDirection={"row"}
              onClick={(e) => e.stopPropagation()} // Prevent toggling when clicking inside the input area
            >
              <MainTextField
                placeholder={"Your question here"}
                onChange={(e) => {
                  e.stopPropagation(); // Ensure it doesn't toggle
                  // Handle text change logic here if needed
                }}
                value={""}
                type="text"
              />
              <Button
                sx={{
                  ...styles.createButton,
                  flex: "1",
                  minWidth: "122px",
                  // padding: "16px 16px",
                  borderRadius: "50px",
                  border: "1px solid var(--Blue-light, #0065E0)",
                  background: "var(--Blue-light, #0065E0)",
                }}
              >
                Send invite
              </Button>
            </Stack>{" "}
            <Stack
              width={"100%"}
              flexDirection={"row"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              gap={"25px"}
            >
              <p>Download Botel app from this link:</p>
              <Stack
                gap={"12px"}
                alignItems={"center"}
                flexDirection={"row"}
                justifyContent={"flex-start"}
              >
                <p className="link-url">{link}</p>
                <img
                  alt="copy"
                  className="copy-icon"
                  style={{ cursor: "pointer", width: "24px" }}
                  src={Copy}
                  onClick={handleCopy}
                />
                <Snackbar
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  open={copySuccess}
                  autoHideDuration={1000}
                  onClose={handleClose}
                  message="Copied to clipboard"
                />
              </Stack>
            </Stack>
          </>
        )}
      </Box>{" "}
      {/* <Box onClick={toggleEmailDrawer} sx={styles.innerCommunicationContainer}>
        <img
          style={{
            position: "absolute",
            right: "16px",
            top: "14px",
          }}
          src={arrowDown}
          alt="arrow"
        />
        <Stack>
          <p
            style={{
              fontSize: "18px",
            }}
            className="radioButtonDescription"
          >
            Email
          </p>
          <p
            style={{
              fontSize: "16px",
            }}
            className="secondaryDescription"
          >
            Connect your email to get notification in case AI could not answer
          </p>
        </Stack>
        {emailOpen && (
          <Stack flexDirection={"row"} sx={styles.connectContainer}>
            <Stack gap={"8px"} flexDirection={"row"}>
              <img className="stepTwoIcons" src={gmail} />{" "}
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                }}
                className="radioButtonDescription"
              >
                Email
              </p>
            </Stack>
            <Button sx={styles.connectButton}>Connect</Button>
          </Stack>
        )}
      </Box>{" "} */}
      <Box sx={styles.innerCommunicationContainer}>
        <Box sx={styles.comingSoon}>Coming soon</Box>

        <Stack>
          <p
            style={{
              fontSize: "18px",
            }}
            className="radioButtonDescription"
          >
            Whatsapp
          </p>
          <p
            style={{
              fontSize: "16px",
            }}
            className="secondaryDescription"
          >
            Connect your whatsapp to get notification in case AI could not
            answer
          </p>
        </Stack>
        {/*whatsappOpen && (
          <Stack flexDirection={"row"} sx={styles.connectContainer}>
            <Stack gap={"8px"} flexDirection={"row"}>
              <img className="stepTwoIcons" src={whatsapp} />{" "}
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                }}
                className="radioButtonDescription"
              >
                Whatsapp business
              </p>
            </Stack>
            <Button sx={styles.connectButton}>Connect</Button>
          </Stack>
        )*/}
      </Box>
      <Box sx={styles.innerCommunicationContainer}>
        <Box sx={styles.comingSoon}>Coming soon</Box>
        <Stack>
          <p
            style={{
              fontSize: "18px",
            }}
            className="radioButtonDescription"
          >
            Slack
          </p>
          <p
            style={{
              fontSize: "16px",
            }}
            className="secondaryDescription"
          >
            Connect your whatsapp to get notification in case AI could not
            answer
          </p>
        </Stack>
      </Box>
      <Box sx={styles.innerCommunicationContainer}>
        <Box sx={styles.comingSoon}>Coming soon</Box>

        <Stack>
          <p
            style={{
              fontSize: "18px",
            }}
            className="radioButtonDescription"
          >
            SMS
          </p>
          <p
            style={{
              fontSize: "16px",
            }}
            className="secondaryDescription"
          >
            Connect your whatsapp to get notification in case AI could not
            answer
          </p>
        </Stack>
      </Box>
    </Box>
  );
}

export default ChatBotAutoOff;
