import { Stack, useMediaQuery, useTheme } from "@mui/material";
import UploadPhotoArea from "./UploadPhotoArea";
import CustomModalMobile from "../../../../components/shared-components/CustomModalMobile";
import CustomModal from "../../../../components/shared-components/CustomModal";

interface LogoUploadModalProps {
  open: boolean;
  onClose: () => void;
}

function LogoUploadModal({ open, onClose }: LogoUploadModalProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const modalContent = (
    <>
      <Stack
        spacing={3}
        sx={{ padding: isMobile ? "0px 0px 24px 0px" : "0px" }}
        width={"100%"}
      >
        <UploadPhotoArea />
      </Stack>
    </>
  );

  return isMobile ? (
    <CustomModalMobile
      open={open}
      handleClose={onClose}
      title="Logo"
      description="Upload your logo to make your brand stand out. We recommend 400x400px for best results."
      showFooter={true}
      handleSave={onClose}
    >
      {modalContent}
    </CustomModalMobile>
  ) : (
    <CustomModal
      open={open}
      handleClose={onClose}
      title="Logo"
      description="Upload your logo to make your brand stand out. We recommend 400x400px for best results."
      showFooter={true}
      handleSave={onClose}
    >
      {modalContent}
    </CustomModal>
  );
}

export default LogoUploadModal;
