import { useState } from "react";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import MainTextField from "../../../../components/core-components/inputs/TextField/MainTextField";
import InstagramSvg from "../../../../assets/images/icons/instagram-2-1.svg";
import LinkedinSvg from "../../../../assets/images/icons/linkedin-icon.svg";
import TwitterSvg from "../../../../assets/images/icons/twitter.svg";
import YoutubeSvg from "../../../../assets/images/icons/youtube-3.svg";
import TiktokSvg from "../../../../assets/images/icons/tiktok-icon-2 1.svg";
import SocialMediaButton from "./SocialMediaButton";
import CustomModalMobile from "../../../../components/shared-components/CustomModalMobile";
import CustomModal from "../../../../components/shared-components/CustomModal";

interface ExternalAccountsModalProps {
  open: boolean;
  onClose: () => void;
}

function ExternalAccountsModal({ open, onClose }: ExternalAccountsModalProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [linkedinVisible, setLinkedinVisible] = useState(false);
  const [twitterVisible, setTwitterVisible] = useState(false);
  const [instagramVisible, setInstagramVisible] = useState(false);
  const [youtubeVisible, setYoutubeVisible] = useState(false);
  const [tiktokVisible, setTiktokVisible] = useState(false);

  const modalContent = (
    <Stack
      spacing={3}
      sx={{ padding: isMobile ? "0px 0px 24px 0px" : "0px 24px" }}
    >
      <Stack spacing={1}>
        <p className="B-14-22-500">Google Business</p>
        <MainTextField
          title={""}
          placeholder={"https://www.google.com"}
          value={""}
          onChange={() => { }}
        />
      </Stack>
      <Stack spacing={1}>
        <p className="B-14-22-500">Airbnb</p>
        <MainTextField
          title={""}
          placeholder={"https://www.airbnb.com"}
          value={""}
          onChange={() => { }}
        />
      </Stack>
      {linkedinVisible && (
        <Stack spacing={1}>
          <p className="B-14-22-500">Linkedin</p>
          <MainTextField
            title={""}
            placeholder={"https://www.linkedin.com"}
            value={""}
            onChange={() => { }}
          />
        </Stack>
      )}
      {twitterVisible && (
        <Stack spacing={1}>
          <p className="B-14-22-500">Twitter</p>
          <MainTextField
            title={""}
            placeholder={"https://www.twitter.com"}
            value={""}
            onChange={() => { }}
          />
        </Stack>
      )}
      {instagramVisible && (
        <Stack spacing={1}>
          <p className="B-14-22-500">Instagram</p>
          <MainTextField
            title={""}
            placeholder={"https://www.instagram.com"}
            value={""}
            onChange={() => { }}
          />
        </Stack>
      )}
      {youtubeVisible && (
        <Stack spacing={1}>
          <p className="B-14-22-500">Youtube</p>
          <MainTextField
            title={""}
            placeholder={"https://www.youtube.com"}
            value={""}
            onChange={() => { }}
          />
        </Stack>
      )}
      {tiktokVisible && (
        <Stack spacing={1}>
          <p className="B-14-22-500">Tiktok</p>
          <MainTextField
            title={""}
            placeholder={"https://www.tiktok.com"}
            value={""}
            onChange={() => { }}
          />
        </Stack>
      )}
      <Stack spacing={1}>
        <p className="B-14-22-500">And more social media accounts to add</p>
        <Stack direction={"row"} spacing={1}>
          <SocialMediaButton
            iconSrc={LinkedinSvg}
            altText="linkedin"
            onClick={() => setLinkedinVisible(true)}
          />
          <SocialMediaButton
            iconSrc={TwitterSvg}
            altText="twitter"
            onClick={() => setTwitterVisible(true)}
          />
          <SocialMediaButton
            iconSrc={InstagramSvg}
            altText="instagram"
            onClick={() => setInstagramVisible(true)}
          />
          <SocialMediaButton
            iconSrc={YoutubeSvg}
            altText="youtube"
            onClick={() => setYoutubeVisible(true)}
          />
          <SocialMediaButton
            iconSrc={TiktokSvg}
            altText="tiktok"
            onClick={() => setTiktokVisible(true)}
          />
        </Stack>
      </Stack>
    </Stack>
  );

  return isMobile ? (
    <CustomModalMobile
      open={open}
      handleClose={onClose}
      title="External Accounts"
      description="Add your Google Business, Airbnb, and social media accounts to increase trust and grow your brand’s online presence."
      showFooter={true}
      handleSave={onClose}
    >
      {modalContent}
    </CustomModalMobile>
  ) : (
    <CustomModal
      open={open}
      handleClose={onClose}
      title="External Accounts"
      description="Add your Google Business, Airbnb, and social media accounts to increase trust and grow your brand’s online presence."
      showFooter={true}
      handleSave={onClose}
      maxHeight={"calc(100vh - 300px)"}
    >
      {modalContent}
    </CustomModal>
  );
}

export default ExternalAccountsModal;
