import React from "react";

interface CapitalizeWordsProps {
  text: string;
}

const CapitalizeWords: React.FC<CapitalizeWordsProps> = ({ text }) => {
  const capitalizeWords = (str: string) => {
    if (!str) {
      return "";
    }
    return str.replace(/\b\w/g, function (char) {
      return char.toUpperCase();
    });
  };

  return <>{capitalizeWords(text)}</>;
};

export default CapitalizeWords;
