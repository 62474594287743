import {
  BASE_API_URL,
  BASE_API_URL2,
  BASE_API_URL3,
  BASE_API_URL4,
  BASE_API_URL5,
  CHANNEL_API_URL,
} from ".";

// export const API_PREFIX = 'api/';
export const API_URL = BASE_API_URL;
export const API_URL2 = BASE_API_URL2;
export const API_URL3 = BASE_API_URL3;
export const API_URL4 = BASE_API_URL4;
export const API_URL5 = BASE_API_URL5;

export const FINAL_HOST = BASE_API_URL;
export const EXAMPLE_ENDPOINT = API_URL + "example/";
export const BOOKING_ENDPOINT = API_URL + "booking/";
export const PROPERTY_ENDPOINT = API_URL2 + "property/";
export const MESSAGE_HEADER_ENDPOINT = API_URL + "/Message/";
export const MESSAGE_ITEM_ENDPOINT = API_URL + "/Message/";
export const MESSAGE_ENDPOINT = API_URL + "/Message/";
export const RESERVATION_ENDPOINT = API_URL + "Reservation/";

export const PMS_API_URL = API_URL + "/Channel/Get";
export const CHANNEL_CONNECT_API = CHANNEL_API_URL + "/Connection/Connect";
export const CONNECTION_API_URL = API_URL + "/User/RefreshToken";
