import React from "react";
import { Box } from "@mui/material";
import arrows from "../../assets/images/icons/arrows.svg";
import { useTranslation } from "react-i18next";
import { listingAreaContainerStyles } from "./styles/SharedComponentsStyles";

interface ListingDetailsEditingBoxProps {
  border?: boolean;
  onClose: () => void;
  children: React.ReactNode;
  footer?: boolean;
}

const ListingDetailsEditingBox: React.FC<ListingDetailsEditingBoxProps> = ({
  onClose,
  border,
  children,
  footer = true,
}) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={
        border
          ? listingAreaContainerStyles.mainContainer
          : listingAreaContainerStyles.innerMainContainer
      }
    >
      <Box sx={listingAreaContainerStyles.innerContainer}>
        <button
          className="listing-details-is-editing-box-close-button"
          onClick={onClose}
        >
          <img
            style={{ width: "10.591px", height: "10.611px", flexShrink: 0 }}
            src={arrows}
            alt="close"
          />
        </button>
        <Box
          sx={{
            padding: "24px",
          }}
        >
          {" "}
          {children}
        </Box>
        {footer ? (
          <Box sx={listingAreaContainerStyles.modalBottomPart}>
            <button
              onClick={onClose}
              className="listing-details-is-editing-box-bottom-button-cancel"
            >
              {t("customModal.cancel")}
            </button>
            <button className="listing-details-is-editing-box-bottom-button-save B-13-13-600">
              {t("customModal.save")}
            </button>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default ListingDetailsEditingBox;
