const styles = {
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    paddingTop: "100px",
    paddingBottom: "200px",
    // justifyContent: "center",
    // marginLeft: "100px",
    // marginRight: "100px",
    overflowY: "auto",
  },
  comingSoon: {
    background: "var(--Danger-Danger-Active, #D9214E)",

    padding: "6px 8px",

    fontFamily: "Inter",
    fontWeight: "600",
    fontSize: "10px",
    color: "var(--White-White-Base, #FFFFFF)",
    gap: "var(--Spacing-space-6, 10px)",
    position: "absolute",
    right: "0px",
    borderRadius: " var(--none, 0px) 12px;",
    top: "0px",
  },

  innerModalContainer: {
    display: "flex",
    maxWidth: "400px",
    width: "100%",
    margin: "0px !important",
    // justifyContent: "center",
    alignItems: "flex-start",
    // alignSelf: "stretch",
    borderRadius: "12px",
    flexDirection: "column",
    background: "#FFF",
  },
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "20px",
    backgroundColor: "white",
    margin: "50px auto",
    borderRadius: "12px",
    maxWidth: "848px",
    width: "100%",
    boxShadow: 24,
    outline: "none",
    position: "relative",
    height: "100%",
    maxHeight: "650px",
  },
  createButton: {
    color: "var(--Base-White, #FFF)",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "var(--Label-Large-Size, 14px)",
    fontStyle: "normal",
    padding: "12px 16px",
    fontWeight: "600",
    lineHeight: "var(--Label-Large-Line-Height, 20px)" /* 142.857% */,
    letterSpacing: "var(--Label-Large-Tracking, 0.1px)",
    borderRadius: "20px",
    background: "var(--Main-blue, #0161E0)",
    "&:hover": {
      background: "var(--Main-blue-hover, #0151C1)",
    },
  },
  communicationContainer: {
    display: "inline-flex",
    padding: "16px",
    flexDirection: "column",
    justifyContent: "center",
    position: "relative",
    alignItems: "flex-start",
    borderRadius: "12px",
    border: "var(--none, 1px) solid var(--Previous-300, #e1e3ea)",
    background: "var(--Gray-50, #f9fafb)",
    gap: "24px",
    width: "100%",
    maxWidth: "100%",
  },
  innerPropertiesContainer: {
    display: "flex",
    cursor: "pointer",
    padding: "6px 16px",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "var(--radius-sm, 6px)",
    border: "1px solid var(--stroke-colors, #EBEBEB)",
    width: "100%",
    background: "var(--Base-White, #FFF)",
  },
  innerCommunicationContainer: {
    position: "relative",
    display: "flex",
    cursor: "pointer",
    padding: "12px 16px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "var(--Spacing-space-12, 32px)",
    alignSelf: "stretch",
    borderRadius: "12px",
    border: "1px solid var(--Gray-300, #D0D5DD)",
    background: "var(--Gray-25, #FCFCFD)",
  },
  connectButton: {
    padding: "var(--Spacing-space-6, 10px) var(--Spacing-space-10, 24px)",
    justifyContent: "center",

    color: "var(--Previous-800, var(--Previous-800, #3F4254))",
    textAlign: "center",
    /* Text sm/Medium */
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "20px" /* 142.857% */,
    borderRadius: "100px",
    border: "var(--none, 1px) solid var(--Previous-500, #A1A5B7)",
  },
  connectContainer: {
    display: "flex",
    padding: "16px 20px",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "stretch",

    borderRadius: "8px",
    background: "var(--Previous-200, #F1F1F2)",
  },
  contentWrapper: {
    marginLeft: "100px",
    marginRight: "100px",
    width: "auto",
  },
};

export default styles;
