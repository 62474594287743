import React, { useState } from "react";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import MainTextField from "../../../../../../../../components/core-components/inputs/TextField/MainTextField";
import Typography from "@mui/material/Typography";
import { InputStyles } from "../../../../../../../../components/core-components/inputs/TextField/InputStyles";
import MainSelectSelection from "../../../../../../../../components/core-components/inputs/Select/MainSelectSelection";
import { useTranslation } from "react-i18next";

interface DiscountsModalProps {
  title: string;
  textsTitle: string;
  firstDescription?: string;
  secondDescription?: string;
  longerStaysDiscountTitle?: string;
  longerStaysDiscount?: boolean;
  lastMinuteDiscount?: boolean;
  lastMinuteDiscountTitle?: string;
  earlyBirdDiscount?: boolean;
  earlyBirdDiscountTitle?: string;
}

function DiscountsModal({
  title,
  textsTitle,
  firstDescription,
  secondDescription,
  longerStaysDiscountTitle,
  longerStaysDiscount,
  lastMinuteDiscount,
  lastMinuteDiscountTitle,
  earlyBirdDiscount,
  earlyBirdDiscountTitle,
}: DiscountsModalProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const [sevenNightPrice, setSevenNightPrice] = useState("");
  const [twelveNightPrice, setTwelveNightPrice] = useState("");
  const [twentyOneNightPrice, setTwentyOneNightPrice] = useState("");
  const [twentyEightNightPrice, setTwentyEightNightPrice] = useState("");

  const handleInputChange =
    (setValue: React.Dispatch<React.SetStateAction<string>>) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      if (!isNaN(Number(value)) && Number.isInteger(Number(value))) {
        setValue(value);
      }
    };

  return (
    <Stack sx={{ width: "100%" }} spacing={3}>
      <p className="B-14-22-700">{title}</p>
      <Stack sx={{ maxWidth: "560px" }} spacing={5}>
        <Stack spacing={2}>
          <p className="B-14-24-500" style={{ color: "#3f4254" }}>
            {textsTitle}
          </p>
          {(firstDescription || secondDescription) && (
            <Stack spacing={2}>
              <p
                className="B-14-24-500"
                style={{ color: "#7e8299", fontWeight: 400 }}
              >
                {firstDescription}
              </p>
              <p
                className="B-14-24-500"
                style={{ color: "#7e8299", fontWeight: 400 }}
              >
                {secondDescription}
              </p>
            </Stack>
          )}
        </Stack>
        <Stack sx={{ width: "100%" }} spacing={3}>
          {longerStaysDiscount && (
            <>
              <p
                className="input-area-title B-14-24-500"
                style={{ fontWeight: 600 }}
              >
                {longerStaysDiscountTitle}
              </p>
              <Stack direction={isMobile ? "column" : "row"} spacing={2}>
                <Stack sx={{ width: "100%" }} spacing={1}>
                  <MainTextField
                    title={t("detailsPages.pricing.sevenNightsOrMore")}
                    placeholder=""
                    value={sevenNightPrice}
                    onChange={handleInputChange(setSevenNightPrice)}
                    startAdornment="%"
                    type="number"
                  />
                </Stack>
                <Stack sx={{ width: "100%" }} spacing={1}>
                  <MainTextField
                    title={t("detailsPages.pricing.twelveNightsOrMore")}
                    placeholder=""
                    value={twelveNightPrice}
                    onChange={handleInputChange(setTwelveNightPrice)}
                    startAdornment="%"
                    type="number"
                  />
                </Stack>
              </Stack>
              <Stack direction={isMobile ? "column" : "row"} spacing={2}>
                <Stack sx={{ width: "100%" }} spacing={1}>
                  <MainTextField
                    title={t("detailsPages.pricing.twentyOneNightsOrMore")}
                    placeholder=""
                    value={twentyOneNightPrice}
                    onChange={handleInputChange(setTwentyOneNightPrice)}
                    startAdornment="%"
                    type="number"
                  />
                </Stack>
                <Stack sx={{ width: "100%" }} spacing={1}>
                  <MainTextField
                    title={t("detailsPages.pricing.twentyEightNightsOrMore")}
                    placeholder=""
                    value={twentyEightNightPrice}
                    onChange={handleInputChange(setTwentyEightNightPrice)}
                    startAdornment="%"
                    type="number"
                  />
                </Stack>
              </Stack>
            </>
          )}
          {lastMinuteDiscount && (
            <>
              <p
                className="input-area-title B-14-24-500"
                style={{ fontWeight: 600 }}
              >
                {lastMinuteDiscountTitle}
              </p>
              <Stack direction={isMobile ? "column" : "row"} spacing={2}>
                <Stack sx={{ width: "100%" }} spacing={1}>
                  <Typography variant="body2" sx={InputStyles.label}>
                    {t("detailsPages.pricing.bookingMadeMoreThan")}
                  </Typography>
                  <MainSelectSelection
                    value=""
                    onChange={() => {}}
                    label={"Select"}
                    options={[
                      {
                        value: "Mandatory-included",
                        label: `Mandatory-included`,
                      },
                      {
                        value: "No",
                        label: `No`,
                      },
                    ]}
                  />
                </Stack>
                <Stack sx={{ width: "100%" }} spacing={1}>
                  <MainTextField
                    title={t("detailsPages.pricing.fourteenNightsOrMore")}
                    placeholder=""
                    value={twelveNightPrice}
                    onChange={handleInputChange(setTwelveNightPrice)}
                    startAdornment="%"
                  />
                </Stack>
              </Stack>
            </>
          )}
          {earlyBirdDiscount && (
            <>
              <p
                className="input-area-title B-14-24-500"
                style={{ fontWeight: 600 }}
              >
                {earlyBirdDiscountTitle}
              </p>
              <Stack direction={isMobile ? "column" : "row"} spacing={2}>
                <Stack sx={{ width: "100%" }} spacing={1}>
                  <Typography variant="body2" sx={InputStyles.label}>
                    {t("detailsPages.pricing.bookingMadeMoreThan")}
                  </Typography>
                  <MainSelectSelection
                    value=""
                    onChange={() => {}}
                    label={"Select"}
                    options={[
                      {
                        value: "Mandatory-included",
                        label: `Mandatory-included`,
                      },
                      {
                        value: "No",
                        label: `No`,
                      },
                    ]}
                  />
                </Stack>
                <Stack sx={{ width: "100%" }} spacing={1}>
                  <MainTextField
                    title={t("detailsPages.pricing.fourteenNightsOrMore")}
                    placeholder=""
                    value={twelveNightPrice}
                    onChange={handleInputChange(setTwelveNightPrice)}
                    startAdornment="%"
                  />
                </Stack>
              </Stack>
            </>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}

export default DiscountsModal;
