import { Route, Routes } from "react-router-dom";
import PmsChannels from "../views/private/settings/pages/PmsChannels";
import BookingSite from "../views/private/settings/pages/BookingSite";
import PromoCodes from "../views/private/settings/pages/PromoCodes";
import AccountUsers from "../views/private/settings/pages/AccountUsers";
import Payments from "../views/private/settings/pages/Payments";
import BookingBooster from "../views/private/settings/pages/BookingBooster";
import Retargeting from "../views/private/settings/pages/Retargeting";
import Autopilot from "../views/private/settings/pages/Autopilot";
import InquiryWinBack from "../views/private/settings/pages/InquiryWinBack";
import EarlyCheckin from "../views/private/settings/pages/EarlyCheckin";
import NotificationSettings from "../views/private/settings/pages/NotificationSettings";
import LateCheckout from "../views/private/settings/pages/LateCheckout";

function SettingsLayout() {
  return (
    <Routes>
      <Route path="/" element={<AccountUsers />} />
      <Route path="/user-management" element={<AccountUsers />} />
      <Route path="/booking-booster" element={<BookingBooster />} />
      <Route path="/retargeting" element={<Retargeting />} />
      <Route path="/inquiry-win-back" element={<InquiryWinBack />} />
      <Route path="/autopilot" element={<Autopilot />} />
      <Route path="/early-check-in-upsell" element={<EarlyCheckin />} />
      <Route path="/late-check-out-upsell" element={<LateCheckout />} />
      <Route path="/notification-settings" element={<NotificationSettings />} />
      <Route path="/pms-and-channels" element={<PmsChannels />} />
      <Route path="/payments" element={<Payments />} />
      <Route path="/booking-site" element={<BookingSite />} />
      <Route path="/promo-codes" element={<PromoCodes />} />
    </Routes>
  );
}

export default SettingsLayout;
