import { Stack, } from "@mui/material";
import React, { useState, useEffect } from "react";
import GetStartedImage from "../../../../assets/images/get-started-image.svg";
import FindBotelImage from "../../../../assets/images/get-started-find-botel-img.png";
import GenerateKeysImage from "../../../../assets/images/get-started-generate-keys-img.png";
import SetupImage from "../../../../assets/images/get-started-setup-img.png";
import ArrowDown from "../../../../assets/images/icons/arrowdown.svg";
import useConnectionAPI from "../../../../api/useConnection";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { setOnboardingData, setStepsComplete } from "../../../../store/slices/onboardSlice";
import { useDispatch } from "react-redux";
import { Button, Flex, Container, Title, Text, TextInput } from "@mantine/core";


interface ContentButtonStackProps {
  title: string;
  details: string;
  image: string;
  showDetails: boolean;
  setShowDetails: React.Dispatch<React.SetStateAction<boolean>>;
  borderBottom: boolean;
}

const ContentButtonStack: React.FC<ContentButtonStackProps> = ({
  title,
  details,
  image,
  showDetails,
  setShowDetails,
  borderBottom,
}) => (
  <Stack>
    <Stack
      direction={"row"}
      className="showContentButtonStack"
      borderBottom={borderBottom && !showDetails ? "1px solid #cbd5e0" : "none"}
      onClick={() => setShowDetails(!showDetails)}
    >
      <p className="B-18-28-600">{title}</p>
      <img
        src={ArrowDown}
        alt="arrow-down"
        style={{
          transition: "transform 0.3s",
          transform: showDetails ? "rotate(180deg)" : "none",
        }}
      />
    </Stack>
    {showDetails && (
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        paddingBottom={"20px"}
        borderBottom={borderBottom ? "1px solid #cbd5e0" : "none"}
      >
        <p className="TextMdRegular">{details}</p>
        <img alt="get started" src={image} className="getStartedImage" />
      </Stack>
    )}
  </Stack>
);

const GetStarted = ({ handleGetStarted }: { handleGetStarted: () => void }) => {
  const STORAGE_KEYS = {
    Hostaway: "hostawayConnectionInfo",
    Guesty: "guestyTokenInfo",
    Airbnb: "airbnbConnectionInfo",
  };
  const CONNECTION_STATE_KEYS = {
    Hostaway: "hostawayConnectionState",
    Guesty: "guestyConnectionState",
    Airbnb: "airbnbConnectionState",
  };



  const { onboardingData } = useSelector((state: RootState) => state.onboard)
  const dispatch = useDispatch()
  const compStep = 3
  const [showFindBotelDetails, setShowFindBotelDetails] = useState(false);
  const [showSetupDetails, setShowSetupDetails] = useState(false);
  const [showGenerateKeysDetails, setShowGenerateKeysDetails] = useState(false);
  const { mutate: connectChannel } = useConnectionAPI();
  const selectedProvider = onboardingData.channels[0];
  const isHostaway = selectedProvider === "Hostaway";
  const isAirbnb = selectedProvider === "Airbnb";
  const [tokenError, setTokenError] = useState<string | null>(null);
  const [isVerifying, setIsVerifying] = useState(false);
  const [isConnected, setIsConnected] = useState(() => {
    const stateKey =
      CONNECTION_STATE_KEYS[
      selectedProvider as keyof typeof CONNECTION_STATE_KEYS
      ];
    const savedConnectionState = sessionStorage.getItem(stateKey);
    if (savedConnectionState) {
      dispatch(setStepsComplete({ key: compStep, status: true }))
      return true
    } else return false
  });

  const handleAccountNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setOnboardingData({
      ...onboardingData,
      getStarted: {
        ...onboardingData.getStarted,
        accountName: e.target.value,
      },
    }));
  };

  const handleClientIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setOnboardingData({
      ...onboardingData,
      getStarted: {
        ...onboardingData.getStarted,
        clientId: e.target.value,
      },
    }));
  };

  const handleClientSecretChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setOnboardingData({
      ...onboardingData,
      getStarted: {
        ...onboardingData.getStarted,
        clientSecret: e.target.value,
      },
    }));
  };

  useEffect(() => {
    const storageKey = STORAGE_KEYS[selectedProvider as keyof typeof STORAGE_KEYS];

    console.log(storageKey)
    const savedInfo = sessionStorage.getItem(storageKey);
    console.log(savedInfo, 'SAVED')
    if (savedInfo) {
      const parsedInfo = JSON.parse(savedInfo);

      switch (selectedProvider) {
        case "Hostaway":
          dispatch(setOnboardingData({
            ...onboardingData,
            getStarted: {
              ...onboardingData.getStarted,
              accountName: parsedInfo.title || "",
              clientId: parsedInfo.clientId || "",
              clientSecret: parsedInfo.clientSecret || "",
            },
          }));
          break;

        case "Guesty":
          dispatch(setOnboardingData({
            ...onboardingData,
            getStarted: {
              ...onboardingData.getStarted,
              accountName: parsedInfo.title || "",
              clientSecret: parsedInfo.clientCode || "",
            },
          }));
          break;

        case "Lodgify":
          dispatch(setOnboardingData({
            ...onboardingData,
            getStarted: {
              ...onboardingData.getStarted,
              accountName: parsedInfo.title || "",
              clientSecret: parsedInfo.clientCode || "",
            },
          }));
          break;

        case "Airbnb":
          dispatch(setOnboardingData({
            ...onboardingData,
            getStarted: {
              ...onboardingData.getStarted,
              accountName: parsedInfo.title || "",
              clientSecret: parsedInfo.token || "",
            },
          }));
          break;
      }
    }
  }, [selectedProvider]);

  const handleSubmitForm = async () => {
    const { accountName, clientId, clientSecret } =
      onboardingData.getStarted;

    let isValid = false;
    switch (selectedProvider) {
      case "Hostaway":
        isValid = !!(accountName && clientId && clientSecret);
        break;
      case "Guesty":
        isValid = !!(accountName && clientSecret);
        break;
      case "Lodgify":
        isValid = !!(accountName && clientSecret);
        break;
      default:
        isValid = false;
    }

    if (!isValid) {
      setTokenError("Please fill in all fields");
      return;
    }

    setIsVerifying(true);

    try {
      let requestData;
      switch (selectedProvider) {
        case "Hostaway":
          requestData = {
            title: accountName,
            clientId: clientId,
            clientSecret: clientSecret,
            providerType: "Hostaway",
          };
          break;

        case "Airbnb":
          requestData = {
            title: accountName,
            token: clientSecret,
            providerType: "Airbnb",
          };
          break;

        default:
          requestData = {
            title: accountName,
            token: clientSecret,
            providerType: selectedProvider,
          };
      }

      connectChannel(requestData, {
        onSuccess: (response) => {
          if (response && response.success) {
            console.log("Connection API success:", response.data);
            setTokenError(null);
            setIsConnected(true);

            let connectionInfo;
            switch (selectedProvider) {
              case "Hostaway":
                connectionInfo = {
                  title: accountName,
                  clientId: clientId,
                  clientSecret: clientSecret,
                };
                break;

              case "Airbnb":
                connectionInfo = {
                  title: accountName,
                  token: clientSecret,
                };
                break;

              case "Guesty":
                connectionInfo = {
                  title: accountName,
                  clientId: response.data?.clientId,
                  clientCode: response.data?.clientSecret,
                };
                break;
              case "Lodgify":
                connectionInfo = {
                  title: accountName,
                  clientId: response.data?.clientId,
                  clientCode: response.data?.clientSecret,
                };
                break;
            }

            const storageKey =
              STORAGE_KEYS[selectedProvider as keyof typeof STORAGE_KEYS];
            const stateKey =
              CONNECTION_STATE_KEYS[
              selectedProvider as keyof typeof CONNECTION_STATE_KEYS
              ];

            sessionStorage.setItem(storageKey, JSON.stringify(connectionInfo));
            sessionStorage.setItem(stateKey, "true");

            handleGetStarted()
            dispatch(setStepsComplete({ key: compStep, status: false }))
          } else {
            dispatch(setStepsComplete({ key: compStep, status: false }))
          }
          setIsVerifying(false);
        },
        onError: (error) => {
          console.error("Connection API error:", error);
          dispatch(setStepsComplete({ key: compStep, status: false }))
          setIsVerifying(false);
          setIsConnected(false);
          const stateKey =
            CONNECTION_STATE_KEYS[
            selectedProvider as keyof typeof CONNECTION_STATE_KEYS
            ];
          sessionStorage.removeItem(stateKey);
        },
      });
    } catch (error) {
      console.error("Error in handleSubmitForm:", error);
      dispatch(setStepsComplete({ key: compStep, status: false }))
      setIsVerifying(false);
      setIsConnected(false);
      const stateKey =
        CONNECTION_STATE_KEYS[
        selectedProvider as keyof typeof CONNECTION_STATE_KEYS
        ];
      sessionStorage.removeItem(stateKey);
    }
  };

  const steps = [
    {
      title: isHostaway
        ? "Go to Hostaway Dashboard"
        : "Find Botel on the Guesty marketplace",
      details: isHostaway
        ? "Go to your Hostaway dashboard to get your Account ID and API Key"
        : "If you can't find it press this link https://app.guesty.com/integrations/partners/botel",
      image: FindBotelImage,
      showDetails: showFindBotelDetails,
      setShowDetails: setShowFindBotelDetails,
    },
    {
      title: isHostaway
        ? "Generate API Key"
        : "Setup a new application on Guesty",
      details: isHostaway
        ? "Go to API Settings section to generate your API key"
        : "You have to register new application. You can call it Stayz and the application description can be chatbot.",
      image: SetupImage,
      showDetails: showSetupDetails,
      setShowDetails: setShowSetupDetails,
    },
    {
      title: isHostaway
        ? "Enter Your Credentials"
        : "Generate your secret keys",
      details: isHostaway
        ? "Enter your Account ID and API Key in the fields above"
        : "Copy and paste these keys above. Make sure to press OK on Guesty.",
      image: GenerateKeysImage,
      showDetails: showGenerateKeysDetails,
      setShowDetails: setShowGenerateKeysDetails,
    },
  ];


  const renderPlaceHolder = selectedProvider
  const renderTokenLabel = selectedProvider === "Guesty" ? "Guesty Secret" : selectedProvider === "Hostaway" ? "Hostaway API token" : "Lodgify API key"

  return (
    <Container size="lg">
      <Flex direction="column" align={"center"} gap={30} w="100%">
        <Flex direction="column" align="center">
          <Title order={1} fw={600} >
            Great! Lets get started.
          </Title>
          <Text size="lg" fw={500} c="dimmed">
            Connect seamlessly with our platform by easily generating your
            credentials and adding your listings today.
          </Text>
        </Flex>
        <Flex px={20} gap={20} justify="center" w="100%">
          <img
            src={GetStartedImage}
            width="40%"
            alt="get-started"
          />
          <Flex miw={300} w="40%" mih={250} direction="column" align="center" ta="left" justify="space-evenly">
            <TextInput
              w="100%"
              size="md"
              label="Please enter your company name"
              value={onboardingData.getStarted.accountName}
              onChange={handleAccountNameChange}
              placeholder={`Your ${renderPlaceHolder} Account Name`}
            />

            {selectedProvider === "Hostaway" &&
              <TextInput
                w="100%"
                size="md"
                label="Account ID"
                value={onboardingData.getStarted.clientId}
                onChange={handleClientIdChange}
                placeholder="12345"
              />
            }
            <TextInput
              w="100%"
              size="md"
              label={`Please Enter your ${renderTokenLabel}`}
              value={onboardingData.getStarted.clientSecret}
              onChange={handleClientSecretChange}
              placeholder="xxx-xxx-xxx"
              error={tokenError}
            />
            <Button
              loading={isVerifying}
              variant="contained"
              color="primary"
              bg={isConnected ? "gray.3" : "blue.5"}
              onClick={handleSubmitForm}
              disabled={isVerifying || isConnected}
            >
              {isConnected ? "Connected" : "Verify Connection"}
            </Button>
          </Flex>
        </Flex>
        <p className="TextSmMedium" style={{ marginTop: "40px" }}>
          {isHostaway
            ? "If you don't have your API credentials, please reach out to us at help@botel.ai"
            : isAirbnb
              ? "If you don't have your Airbnb API token, please reach out to us at help@botel.ai"
              : "If you don't have your token. Follow these steps to get your token. If you need help, write to us at help@botel.ai"}
        </p>
        <Flex
          p="0 20px"
          style={{ borderRadius: "var(--mantine-radius-md)" }}
          miw={900}
          w="100%"
          bg="gray.1"
          direction="column"
        >
          {steps.map((step, index) => (
            <ContentButtonStack
              key={index}
              title={step.title}
              details={step.details}
              image={step.image}
              showDetails={step.showDetails}
              setShowDetails={step.setShowDetails}
              borderBottom={index !== steps.length - 1}
            />
          ))}
        </Flex>
      </Flex >
    </Container >
  );
}

export default GetStarted;
