import React from "react";
import { Typography } from "@mui/material";

interface HelperTextProps {
  content: string;
}

const HelperText: React.FC<HelperTextProps> = ({ content }) => {
  return (
    <Typography
      sx={{
        fontSize: "12px",
        fontWeight: "700",
        fontFamily: "Inter",
        color: "var(--Gray-Gray-600, #7E8299)",
      }}
    >
      {content}
    </Typography>
  );
};

export default HelperText;
