import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { InputStyles } from "../../../../../../../../components/core-components/inputs/TextField/InputStyles";
import MainSelectSelection from "../../../../../../../../components/core-components/inputs/Select/MainSelectSelection";
import MainTextField from "../../../../../../../../components/core-components/inputs/TextField/MainTextField";
import DetailsContentTitle from "../../../../../../../../components/shared-components/DetailsContentTitle";
import { useTranslation } from "react-i18next";

function Pets() {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <Stack
        sx={{ width: "100%", maxWidth: "560px" }}
        component={"form"}
        spacing={2}
      >
        <DetailsContentTitle title={t("detailsPages.policies.pets")} />
        <Stack gap={"18px"} direction={isMobile ? "column" : "row"}>
          <Stack spacing={1} sx={{ width: "100%" }}>
            <Typography variant="body2" sx={InputStyles.label}>
              {t("detailsPages.policies.maximumPetsAllowed")}
            </Typography>
            <MainSelectSelection
              value=""
              onChange={() => {}}
              label={"Options"}
              options={[
                {
                  value: "1",
                  label: `1`,
                },
                {
                  value: "2",
                  label: `2`,
                },
              ]}
            />
          </Stack>

          <Stack spacing={1} sx={{ width: "100%" }}>
            <Typography variant="body2" sx={InputStyles.label}>
              {t("detailsPages.policies.isThereAFeeForPets")}
            </Typography>
            <MainSelectSelection
              value=""
              onChange={() => {}}
              label={"Options"}
              options={[
                {
                  value: "Yes",
                  label: `Yes`,
                },
                {
                  value: "No",
                  label: `No`,
                },
              ]}
            />
          </Stack>
        </Stack>

        <Stack gap={"18px"} direction={isMobile ? "column" : "row"}>
          <Stack spacing={1} sx={{ width: "100%" }}>
            <Typography variant="body2" sx={InputStyles.label}>
              {t("detailsPages.policies.feeBasis")}
            </Typography>
            <MainSelectSelection
              value=""
              onChange={() => {}}
              label={"Options"}
              options={[
                {
                  value: "1",
                  label: `1`,
                },
                {
                  value: "2",
                  label: `2`,
                },
              ]}
            />
          </Stack>

          <Stack spacing={1} sx={{ width: "100%" }}>
            <Typography variant="body2" sx={InputStyles.label}>
              {t("detailsPages.policies.amount")}
            </Typography>
            <Stack gap={"12px"} direction={"row"}>
              <MainTextField
                title={""}
                placeholder={"100"}
                value=""
                onChange={() => {}}
                isMandatory={false}
              />
              <Box sx={{ width: "79px" }}>
                <MainSelectSelection
                  value=""
                  onChange={() => {}}
                  label={"USD"}
                  minWidth={"auto"}
                  options={[
                    {
                      value: "1",
                      label: `1`,
                    },
                    {
                      value: "2",
                      label: `2`,
                    },
                  ]}
                />
              </Box>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}

export default Pets;
