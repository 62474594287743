import {
  AccordionSummary,
  Box,
  Stack,
  Typography,
  Accordion as MuiAccordion,
  AccordionDetails,
} from "@mui/material";
import React, { useRef, useState } from "react";
import {
  sxAccordion,
  sxAccordionDetails,
  sxAccordionSummary,
  sxTextOverflow,
} from "../../../../../assets/styles";
import { transformData } from "../table/helper";
import ArrowDownSvg from "../../../../../assets/images/icons/linear-arrow-down.svg";
import { useQuery } from "react-query";
import dayjs, { Dayjs } from "dayjs";
import LoadingWrapper from "../../../../../components/shared-components/LoadingWrapper";
import SkeletonCalendar from "../skeleton-calendar";
import ModalsSection from "../modals-section";
import NotFoundPage from "../../../error/NotFoundPage";
import axios from "axios";
import { BASE_API_URL } from "../../../../../api";

interface Props {
  hiddenRows: string[];
  property: any;
  startDate: Dayjs;
}

function Accordion(props: Props) {
  const { hiddenRows, property, startDate } = props;
  const [expanded, setExpanded] = useState<boolean>(false);
  const [modalState, setModalState] = useState<{
    type: string;
    anchorEl: HTMLButtonElement | null;
    propertyId: string;
    ratePlanId: string;
    start: Dayjs;
    value: any;
  }>({
    type: "",
    anchorEl: null,
    propertyId: "",
    ratePlanId: "",
    start: dayjs(),
    value: null,
  });

  const lastBoxRef = useRef<HTMLButtonElement | null>(null);

  const handleAccordionClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const handleModalClose = () =>
    setModalState({
      type: "",
      anchorEl: null,
      ratePlanId: "",
      propertyId: "",
      start: dayjs(),
      value: null,
    });

  const { data, isLoading, refetch, error } = useQuery(
    ["restrictionList", startDate],
    async () => {
      const res = await axios.post(`${BASE_API_URL}/rateplan/restriction/list`, {
        RatePlanId: "fabbd739-09d0-497f-9344-9e35968931db",
        StartDate: startDate.format("YYYY-MM-DD"),
        EndDate: startDate.add(13, "day").format("YYYY-MM-DD"),
      });

      return res?.data?.Body;
    }
  );
  if (error) {
    return <NotFoundPage />;
  }
  const handleClickBasedOnRow = (
    rowTitle: string,
    propertyId: string,
    ratePlanId: string,
    e: React.MouseEvent<HTMLDivElement>,
    index: number,
    value: any
  ) => {
    e.stopPropagation();
    if (lastBoxRef.current) {
      setModalState({
        type: rowTitle,
        propertyId,
        ratePlanId,
        anchorEl: lastBoxRef.current,
        start: startDate.add(index, "day"),
        value,
      });
    }
  };
  return (
    <>
      <MuiAccordion sx={sxAccordion} expanded={expanded}>
        <AccordionSummary
          sx={[
            sxAccordionSummary,
            { borderBottom: "1px solid #DDD", height: "51px" },
          ]}
        >
          <Stack
            sx={{
              width: "319px",
              padding: "6px 24px",
              flexShrink: "0",
            }}
            direction={"row"}
            alignItems={"center"}
            gap={"12px"}
            onClick={() => setExpanded((prev) => !prev)}
          >
            <Box
              component={"img"}
              src={property.ImageUrl}
              sx={{
                width: "32px",
                height: "26px",
                borderRadius: "3px",
                flexShrink: "0",
              }}
            />
            <Typography
              variant="h3"
              sx={[sxTextOverflow, { fontWeight: "700" }]}
            >
              {property.Name}
            </Typography>
            <Box
              component={"img"}
              src={ArrowDownSvg}
              className="arrow-down-accordion"
            />
          </Stack>

          <Stack
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(14,1fr)",
              width: "100%",
              borderTop: "1px solid #DDD",
            }}
          >
            {Array.from({ length: 14 }).map((_, index) => (
              <Box
                key={index}
                // onMouseDown={(e) => {
                //   handleMouseDown(index);
                // }}
                // onMouseOver={(e) => {
                //   handleMouseOver(index);
                // }}
                onClick={handleAccordionClick}
                ref={index === 13 ? lastBoxRef : null}
                sx={{
                  borderLeft: "1px solid #DDD",
                  background:
                    // selectionStart !== null &&
                    // selectionEnd !== null &&
                    // index + 1 >= Math.min(selectionStart, selectionEnd) &&
                    // index + 1 <= Math.max(selectionStart, selectionEnd)
                    //   ? "#EEF6FF"
                    //   :
                    "#F9F9F9",
                  cursor: "copy",
                  "&:hover": {
                    border: "1px solid #0065E0",
                  },
                }}
              ></Box>
            ))}
          </Stack>
        </AccordionSummary>
        <LoadingWrapper
          loading={isLoading}
          component={<SkeletonCalendar secondary={true} />}
        >
          {data && (
            <AccordionDetails sx={sxAccordionDetails}>
              {React.Children.toArray(
                transformData(data)
                  .filter((detail: any) => hiddenRows.includes(detail.title))
                  .map((detail) => (
                    <Stack
                      sx={{
                        borderBottom: "1px solid #DDD",
                        height: "26px",
                      }}
                      direction={"row"}
                    >
                      <Stack
                        sx={{
                          width: "319px",
                          padding: "6px 24px",
                          flexShrink: "0",
                          height: "100%",
                        }}
                        alignItems={"center"}
                        direction={"row"}
                      >
                        <Typography
                          variant="h3"
                          sx={[
                            sxTextOverflow,
                            { color: "#7E8299", fontSize: "12px" },
                          ]}
                        >
                          {detail.title}
                        </Typography>
                      </Stack>
                      <Stack
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "repeat(14,1fr)",
                          width: "100%",
                        }}
                      >
                        {React.Children.toArray(
                          detail.data.map(
                            (detailData: { value: any }, index: number) => (
                              <Box
                                sx={{
                                  borderLeft: "1px solid #DDD",
                                  background: "#EEF6FF",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  overflow: "hidden",
                                  cursor: "pointer",
                                  "&:hover": {
                                    border: "1px solid #0065E0",
                                  },
                                }}
                                onClick={(e) =>
                                  handleClickBasedOnRow(
                                    detail.title,
                                    data[0].PropertyId,
                                    data[0].RatePlanId,
                                    e,
                                    index,
                                    detailData.value
                                  )
                                }
                              >
                                <Typography
                                  variant="h6"
                                  sx={[
                                    sxTextOverflow,
                                    {
                                      fontSize: "12px",
                                    },
                                  ]}
                                >
                                  {detailData.value}
                                </Typography>
                              </Box>
                            )
                          )
                        )}
                      </Stack>
                    </Stack>
                  ))
              )}
            </AccordionDetails>
          )}
        </LoadingWrapper>
      </MuiAccordion>

      <ModalsSection
        modalState={modalState}
        refetch={refetch}
        handleModalClose={handleModalClose}
      />
    </>
  );
}

export default Accordion;
