import axios from 'axios';


export const axiosInterceptor = async () => {
    axios.interceptors.request.use(
        async config => {

            const token = localStorage.getItem('token')

            if (token) {
                config.headers.Authorization = 'Bearer ' + token;
            } else {
                //TODO handle no token (refresh token or logout)
            }

            return config;
        },
        error => {
            Promise.reject(error);
        },
    );
};

export const axiosInterceptorResponse = () => {
    axios.interceptors.response.use(
        response => {
            return response;
        },
        async function (error) {
            if (error && error.response && error.response.status === 401) {
                //TODO handle 401 error

                // store.dispatch(onSignOutRedux());
                return Promise.reject(error);
            }
            return Promise.reject(error);
        },
    );
};
