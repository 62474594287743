import React from "react";
import EventsAllowed from "../../policies/components/forms/EventsAllowed";
import DetailsInputArea from "../../../../../../../components/shared-components/DetailsInputArea";
import { useTranslation } from "react-i18next";

function GuestSafety() {
  const { t } = useTranslation();
  const data = [
    {
      title: t("listingDetails.beddingandtowels"),
      subtitle: "",
      component: <EventsAllowed />,
      isSelect: false,
      quickSelect: true,
    },
    {
      title: t("listingDetails.carbonmonoxidedetector"),
      subtitle: "",
      component: <EventsAllowed />,
      isSelect: false,
      quickSelect: true,
    },
    {
      title: t("listingDetails.emergencyexit"),
      subtitle: "",
      component: <EventsAllowed />,
      isSelect: false,
      quickSelect: true,
    },
    {
      title: t("listingDetails.fireemergencycontact"),
      subtitle: "",
      component: <EventsAllowed />,
      isSelect: false,
      quickSelect: true,
    },
    {
      title: t("listingDetails.contactlesscheckin"),
      subtitle: "",
      component: <EventsAllowed />,
      isSelect: false,
      quickSelect: true,
    },
    {
      title: t("listingDetails.enhancedcleaning"),
      subtitle: "",
      component: <EventsAllowed />,
      isSelect: false,
      quickSelect: true,
    },
    {
      title: t("listingDetails.fireextinguisher"),
      subtitle: "",
      component: <EventsAllowed />,
      isSelect: false,
      quickSelect: true,
    },
    {
      title: t("listingDetails.firstaidkit"),
      subtitle: "",
      component: <EventsAllowed />,
      isSelect: false,
      quickSelect: true,
    },
  ];

  return (
    <>
      <DetailsInputArea
        title={t("listingDetails.guestsafety")}
        data={data}
        onClick={() => {}}
        id={"guest-safety"}
      />
    </>
  );
}

export default GuestSafety;
