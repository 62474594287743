import React, { useEffect, useMemo, useState } from "react";
import goback from "../../../../../assets/images/icons/go-back.svg";
import SendIcon from "@mui/icons-material/Send";
//import RichTextBox from "./RichTextBox";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../../../store/store";
import { useSelector } from "react-redux";
import { clearInputMessage, postSendMessage, setInputMessage } from "../../../../../store/slices/chatSlice";
import "./ChatTextfield.scss"
import { debounce } from "../../../../../utils/helpers";
import { Button, Select, Textarea } from "@mantine/core";

const ChatTextfield = () => {
  const { messageId, selectedMessage } = useSelector((state: RootState) => state.chat);

  const inputMessage = useSelector((state: RootState) => state.chat.messagesCache[messageId].chatInput);
  const [localInputMessage, setLocalInputMessage] = useState<string>('');
  const dispatch = useDispatch<AppDispatch>();
  const [selectedOption, setSelectedOption] = useState<string>("1");
  //const [open, setOpen] = useState(false);
  //const [showToolbar, setShowToolbar] = useState(!isMobile);
  const toggleToolbar = () => {
    //    setShowToolbar((prev) => !prev);
  };
  const { getAccessTokenSilently } = useAuth0();

  const debouncedDispatch = useMemo(
    () =>
      debounce((message: string, id: string) => {
        dispatch(setInputMessage({ inputMessage: message, messageId: id }));
      },
        1000
      ),
    [dispatch]
  );

  useEffect(() => {
    debouncedDispatch(localInputMessage, messageId);

    return () => {
      debouncedDispatch.cancel?.();
    };
  }, [localInputMessage]);

  useEffect(() => {
    setLocalInputMessage(inputMessage)
  }, [inputMessage, messageId])


  const sendMessage = async () => {
    if (localInputMessage.trim()) {
      const accessToken = await getAccessTokenSilently();
      const messageDetails = {
        propertyId: selectedMessage?.propertyId ?? "",
        messageId: messageId,
        message: localInputMessage,
        messageType: +selectedOption === 5 ? "internal" : "message" as any,
        channelId: "",
        channel: "",
        sender: +selectedOption === 6 ? "guest" : "property" as "guest" | "property",
        usedAI: false,
        isNeedAttention: false,
      }
      setLocalInputMessage("")
      if (inputMessage) {
        //  await dispatch(updateMessageNeededPost({
        //    accessToken,
        //    messageDetails: {
        //      messageId,
        //      status: "approved"
        //    }
        //  }))
      }

      dispatch(postSendMessage(
        { accessToken, messageDetails }
      ))
      dispatch(clearInputMessage())
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setLocalInputMessage(e.target.value)
    //dispatch(setLocal(e.target.value))
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && localInputMessage.trim()) {
      if (e.metaKey || e.ctrlKey) {
        e.preventDefault();
        if (localInputMessage.trim()) {
          sendMessage();
        }
      }
    }
  };

  // const handleQuillChange = (content: string) => {
  //   setLocalInputMessage(content)
  // };

  const handleSelectChange = (value: any) => {
    setSelectedOption(value);
    //  if (isMobile) {
    //    setShowToolbar(false);
    //  } else {
    //    if (event.target.value === 2 || event.target.value === 4) {
    //      setShowToolbar(true);
    //    }
    //  }
  };

  const menuValues = [
    { label: "Via PMS", value: "1" },
    { label: "Internal Note", value: "5" },
    { label: "Customer", value: "6" }
  ];

  return (
    <div className="chat-text-field-box">
      <div
        className="chat-back-button"
        onClick={toggleToolbar}
      >
        <img src={goback} alt="send" />
      </div>
      {/*isMobile && (
        <>
          <SelectChannelMobileComponent
            value={selectedOption}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            handleChange={handleSelectChange}
          />
        </>
      )*/}
      {/*
        +selectedOption === 2 || +selectedOption === 4 ? (
          <RichTextBox
            showToolbar={showToolbar}
            value={localInputMessage}
            onChange={handleQuillChange}
          />
        ) : (*/}
      <Textarea
        name="message"
        value={localInputMessage}
        onChange={handleInputChange}
        placeholder="Type your message here..."
        autosize
        minRows={2}
        maxRows={6}
        //disabled={isLoading}
        variant="outlined"
        onKeyPress={handleKeyPress}
        className="chat-text-field"
      />
      <div className="textfield-extras" >
        <Select
          placeholder="Pick value"
          data={menuValues}
          checkIconPosition="left"
          radius="md"
          variant="unstyled"
          size="md"
          maw={150}
          onChange={handleSelectChange}
          value={selectedOption}
          styles={{
            input: {
              caretColor: "transparent", // Remove text cursor
            },
          }}
        />

        {/*
        <SelectChannelComponent
          value={+selectedOption}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
        //handleChange={handleSelectChange}
        />

          <EditingStack
            toggleToolbar={toggleToolbar}
            selectedOption={selectedOption}
          />
          */}
        <Button
          onClick={sendMessage}
          type="submit"
          radius="xl"
          size="md"
          className="chat-text-field-send-button"
          rightSection={<SendIcon sx={{ fontSize: "16px" }} />}
          disabled={!localInputMessage.trim()}
        >
          Send
        </Button>
      </div>
    </div >
  );
};

export default ChatTextfield;
