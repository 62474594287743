import * as React from "react";
import { SVGProps } from "react";
import GetConfirmationColor from "../../../components/shared-components/GetConfirmationColor";

interface StatusEllipseSvgProps extends SVGProps<SVGSVGElement> {
  status: string;
}

const StatusEllipseSvg: React.FC<StatusEllipseSvgProps> = ({
  status,
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={10}
      height={10}
      fill="none"
      {...props}
    >
      <circle
        cx={5}
        cy={5}
        r={5}
        fill={GetConfirmationColor(status || "unknown")}
      />
    </svg>
  );
};

export default StatusEllipseSvg;
