import React from "react";
import { useTranslation } from "react-i18next";
import NotFound from "../../assets/images/not-found.svg";

const EmptyList = (props: any) => {
  const { t } = useTranslation();
  const { message } = props;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "56px",
        marginBottom: "56px",
      }}
    >
      <h1 className="H-34-34-600">{t(message)}</h1>
      <img
        src={NotFound}
        alt="Empty List"
        style={{ width: "343.125px", height: "305px", flexShrink: 0 }}
      />
    </div>
  );
};

export default EmptyList;
