import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MessageHeader, MessageHeaderResponse, MessageItem, MessageItemResponse, ReservationDetails } from '../../types';
import signalRService from "../../signalr/SignalRService";
import {
  FetchMessagesParams,
  fetchReservationDetailsApi,
  FetchReservationDetailsParams,
  fetchSingleMessageApi,
  fetchUnreadCountApi,
  FetchUnreadCountParams,
  FilterType,
  getMessageDetailApi,
  GetMessageDetailParams,
  postSendMessageApi,
  PostSendMessageParams,
  putAssignUserApi,
  PutAssignUserParams,
  updateMessageHeaderApi,
  UpdateMessageHeaderParams,
  updateMessageStatusApi,
  UpdateMessageStatusParams,
  updateReadStatusApi
} from '../../api/messageApi';
import { BASE_SIGNAL_API } from '../../api';
import { fetchMessagesApi } from '../../api/messageApi';
import { RootState } from '../store';

// Function to sort messages by date
const sortMessagesByDate = (messages: MessageHeader[]) => {
  return messages?.sort(
    (a: any, b: any) => b.lastMessageReceivedAt - a.lastMessageReceivedAt
  );
};

// HANDLE FILTER SIDE
interface ChatState {
  //BOOLS
  connectionStarted: boolean;
  actionNeededModalOpen: boolean;
  messagesLoading: boolean,
  singleMessageLoading: boolean,
  reservationLoading: boolean,
  mobileMessageOpen: boolean;
  filterType: FilterType;
  //ARRS
  allMessages: MessageHeader[];
  //MAPS
  messagesCache: {
    [messageId: string]: {
      chatMessages: MessageItem[],
      chatInput: string
    }
  }
  chatMessagePages: {
    [messageId: string]: { currentPage: number; hasMore: boolean };
  }
  //OBJECTS
  tabMessageCounts: {
    all: number,
    unread: number,
    actionNeeded: number,
    notReplied: number,
    archived: number,
  }

  messageMetaData: MessageHeaderResponse | null;
  selectedMessage: MessageHeader | null
  reservationCache: { [bookingId: string]: ReservationDetails | null }
  // STRINGS
  messageId: string
  bookingId: string
  messageSendLoading: string;
  error: string | null

}

const initialState: ChatState = {
  connectionStarted: false,
  actionNeededModalOpen: false,
  messagesLoading: false,
  singleMessageLoading: false,
  reservationLoading: false,
  mobileMessageOpen: false,
  filterType: FilterType.NONE,
  allMessages: [],
  messagesCache: {},
  chatMessagePages: {},
  tabMessageCounts: {
    all: 0,
    unread: 0,
    actionNeeded: 0,
    notReplied: 0,
    archived: 0,
  },
  messageMetaData: null,
  selectedMessage: null,
  reservationCache: {},
  messageId: '',
  bookingId: '',
  messageSendLoading: "",
  error: null,
};
//ALL MESSAGES NEED TO BE OJBECt

export const initializeSignalR = createAsyncThunk(
  "chat/initializeSignalR",
  async (token: string, { dispatch }) => {

    try {
      await signalRService.startConnection(
        BASE_SIGNAL_API
      );

      try {
        const tokenPayload = JSON.parse(atob(token.split(".")[1]));
        if (tokenPayload) {
          const customerId = tokenPayload.customerId
          const email = tokenPayload.email

          if (customerId && email) {
            const connectUser = {
              CustomerId: customerId,
              UserName: email,
            };
            await signalRService.sendMessage("ConnectClient", connectUser);
          }
        }
      } catch (error) {
        console.error("Error decoding token:", error);
      }

      signalRService.onReceiveMessage("SendMessage", (message) => {
        dispatch(handleSignalRMessage(message));
      });

      signalRService.onReceiveMessage("ReceiveMessage", (message) => {
        dispatch(handleSignalRMessage(message));
      });

      signalRService.onReceiveMessage("NeedAttentionMessage", (message) => {
        dispatch(handleMessageWithType({ message, messageType: "actionneeded" }));
      });

      signalRService.onReceiveMessage("SemiautoMessage", (message) => {
        dispatch(handleMessageWithType({ message, messageType: "semiauto" }));
      });

      signalRService.onReceiveMessage("NeedMessageIsChange", (message) => {
        dispatch(handleMessageRemoveAttention(message));
      });

      signalRService.onReceiveMessage("NewConversation", (message) => {
        let parsedMessage: MessageHeader;
        if (typeof message === "string") {
          parsedMessage = JSON.parse(message);
        } else {
          parsedMessage = message;
        }
        //WARN: NEED TO CALL API WITH THAT MESSAGE ID FROM HERE, THIS NEED TO TEST NOT SURE WORKING OR NOT,
        //@ts-ignore
        dispatch(getMessageDetail({ accessToken: token, id: parsedMessage.Id }))
      });

      return true;
    } catch (error) {
      console.error("SignalR initialization error:", error);
      return false;
    }
  }
);



// TODO: ADD LOADING AND ITS WORKING BUT NEED TO BE BETTER
export const fetchMessages = createAsyncThunk(
  "chat/fetchMessages",
  async (
    params: FetchMessagesParams,
    { rejectWithValue }
  ) => {
    try {
      const response = await fetchMessagesApi(params);
      // Sort the messages by date before returning
      return {
        ...response,
        items: sortMessagesByDate(response.items ?? []),
        filterType: params.filterType
      };
    } catch (error: any) {
      console.error("Error fetching messages:", error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const getMessageDetail = createAsyncThunk(
  "chat/getMessageDetail",
  async (params: GetMessageDetailParams, { rejectWithValue }) => {
    try {
      const response = await getMessageDetailApi(params);
      return response
    } catch (error: any) {
      console.error("Error fetching messages:", error.message);
      return rejectWithValue(error.message);
    }
  }
)

export const fetchSingleMessage = createAsyncThunk(
  "chat/fetchSingleMessage",
  async (
    params: { messageId: string; page: number; limit: number; accessToken: string },
    { rejectWithValue, getState }
  ) => {
    const { chat } = getState() as RootState
    const mCache = chat.messagesCache[params.messageId]
    if (!!mCache) {
      try {
        return await fetchSingleMessageApi(params);
      } catch (error: any) {
        return rejectWithValue(error.message);
      }
    }
  }
)

export const fetchReservationDetails = createAsyncThunk(
  "chat/fetchReservationDetails",
  async (
    params: FetchReservationDetailsParams,
    { rejectWithValue, getState, dispatch }
  ) => {

    const { chat } = getState() as RootState
    if (chat.reservationCache[params.bookingId]) {
      dispatch(setReservation(params.bookingId))
    } else {
      try {
        return await fetchReservationDetailsApi(params);
      } catch (error: any) {
        return rejectWithValue(error.message);
      }
    }
  }
)

export const fetchUnreadCount = createAsyncThunk(
  "chat/fetchUnreadCount",
  async (
    params: FetchUnreadCountParams,
    { rejectWithValue }
  ) => {
    try {
      return await fetchUnreadCountApi(params);
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
)

//WARN: POST

export const postSendMessage = createAsyncThunk(
  "chat/postSendMessage",
  async (params: PostSendMessageParams, { rejectWithValue }) => {
    try {
      return await postSendMessageApi(params)
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const putAssignUser = createAsyncThunk(
  "chat/putAssignUser",
  async (
    params: PutAssignUserParams,
    { rejectWithValue }
  ) => {
    try {
      return await putAssignUserApi(params);
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
)

export const updateMessageHeader = createAsyncThunk(
  "chat/updateMessageHeader",
  async (
    params: UpdateMessageHeaderParams,
    { rejectWithValue }
  ) => {
    try {
      await updateMessageHeaderApi(params);
      return params.updateData
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
)

export const updateMessageReadStatus = createAsyncThunk(
  "chat/updateReadStatus",
  async (
    params: any,
    { rejectWithValue }
  ) => {
    try {
      return await updateReadStatusApi(params)
      //TODO: HANDLE LOCAL STATE
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);


export const updateMessageNeededPost = createAsyncThunk(
  "chat/updateMessageNeededPost",
  async (params: UpdateMessageStatusParams, { rejectWithValue }) => {
    try {
      return await updateMessageStatusApi(params)
    } catch (error: any) {
      return rejectWithValue(error.message);
    }

  }

)



const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setActionNeededModal: (state, action: PayloadAction<boolean>) => {
      state.actionNeededModalOpen = action.payload
    },
    toggleActionNeededModal: (state) => {
      state.actionNeededModalOpen = !state.actionNeededModalOpen
    },
    setInputMessage: (state, action: PayloadAction<{ inputMessage: string, messageId: string }>) => {
      if (action.payload.messageId) {
        state.messagesCache[action.payload.messageId].chatInput = action.payload.inputMessage;
      }
    },
    clearInputMessage: (state) => {
      state.messagesCache[state.messageId].chatInput = ""
    },
    setMessagesLoading: (state, action: PayloadAction<boolean>) => {
      state.messagesLoading = action.payload
    },
    setMobileOpen: (state, action: PayloadAction<boolean>) => {
      state.mobileMessageOpen = action.payload
    },
    setSelectedMessage: (state, action: PayloadAction<MessageHeader | null>) => {
      if (action.payload) {
        const messageId = action.payload.id;

        if (!state.messagesCache[messageId]) {
          state.messagesCache[messageId] = {
            chatMessages: [],
            chatInput: "",
          };
        }
        state.selectedMessage = action.payload
        state.messageId = messageId;
      }
    },
    setReservation(state, action: PayloadAction<string>) {
      state.bookingId = action.payload
    },
    clearReservationState: (state) => {
      state.reservationCache = {}
    },
    setFilterType: (state, action: PayloadAction<FilterType>) => {
      state.filterType = action.payload
    },
    handleFilteredMessages: (state, action) => {
      const { items, ...rest } = action.payload;

      state.messageMetaData = rest
      state.allMessages = items

      if (state.tabMessageCounts.all === 0) {
        state.tabMessageCounts = items.reduce(
          (acc: any, message: any) => ({
            all: 0,
            unread: acc.unread,
            actionNeeded: acc.actionNeeded + (message.isNeedAttention ? 1 : 0),
            notReplied: acc.notReplied + (message.messageNeedPost.status === "" ? 0 : 1),
            archived: acc.archived,
          }),
          {
            all: 0,
            unread: state.tabMessageCounts.unread,
            actionNeeded: 0,
            notReplied: 0,
            archived: 0,
          }
        );
        state.tabMessageCounts.all = rest.totalCount
      }

      state.messagesLoading = false;
    },
    handleSignalRMessage: (state, action) => {
      let parsedMessage: any;

      if (typeof action.payload === "string") {
        parsedMessage = JSON.parse(action.payload)
      } else {
        parsedMessage = action.payload
      }

      if (!parsedMessage.MessageId) return;

      //NOTE: IF MESSAGE COME WıTH CURRENT USER EMAIL DON'T ADD TO STATE 
      //CAUSE WEBSOCKET ALSO SEND OUR MESSAGE BACK TO US
      const userEmail = localStorage.getItem("email")
      if (parsedMessage.UserName && parsedMessage.UserName === userEmail) return;

      if (!state.messagesCache[parsedMessage.MessageId]) {
        state.messagesCache[parsedMessage.MessageId] = {
          chatMessages: [],
          chatInput: "",
        };
      }

      //      if (!state.chatMessagesMap[parsedMessage.MessageId]) {
      //        state.chatMessagesMap[parsedMessage.MessageId] = [];
      //      }

      const newMessageItem = {
        id: String(Date.now()),
        messageId: parsedMessage.MessageId,
        sender: parsedMessage.Sender || "guest",
        message: parsedMessage.Message,
        messageType: parsedMessage.MessageType,
        createdAt: parsedMessage.CreatedAt || new Date().toISOString(),
        createdByName: parsedMessage.CreatedByName,
        source: parsedMessage.source || "manual"
      };

      //NOTE: Add to our chat map message
      state.messagesCache[parsedMessage.MessageId].chatMessages.push(newMessageItem)
      //state.chatMessagesMap[parsedMessage.MessageId].push(newMessageItem);

      const existingMessageIndex = state.allMessages.findIndex(
        (msg) => msg.id === parsedMessage.MessageId
      );

      //NOTE: add to all messages, which left side messages on screen
      if (existingMessageIndex !== -1) {
        const updatedMessage = {
          ...state.allMessages[existingMessageIndex],
          lastMessage: parsedMessage.Message,
          lastMessageReceivedAt: new Date().toISOString(),
          messageCount:
            (state.allMessages[existingMessageIndex].messageCount || 0) + 1,
          isRead: false,
        };

        state.allMessages.splice(existingMessageIndex, 1);
        state.allMessages.unshift(updatedMessage);
      }
    },
    handleMessageRemoveAttention: (state, action) => {
      //NOTE: IF MESSAGE HANDLED FROM PMS OR OTHER PLACE REMOVE IS NEED ATTENTION
      let parsedMessage: any;

      if (typeof action.payload === "string") {
        parsedMessage = JSON.parse(action.payload)
      } else {
        parsedMessage = action.payload
      }

      if (!parsedMessage.MessageId) return;

      const messageIndex = state.allMessages.findIndex(
        (msg) => msg.id === parsedMessage.MessageId
      );

      if (messageIndex !== -1) {
        if (state.selectedMessage && state.selectedMessage.id === parsedMessage.MessageId) {
          state.selectedMessage = {
            ...state.selectedMessage,
            isNeedAttention: false,
            messageNeedPost: {
              status: "",
              messageType: "",
              messageAIKnows: "",
              message: "",
              id: null,
              isRegenerate: null,
            },
          }
        }

        state.allMessages[messageIndex] = {
          ...state.allMessages[messageIndex],
          isNeedAttention: false,
          messageNeedPost: {
            status: "",
            messageType: "",
            messageAIKnows: "",
            message: "",
            id: null,
            isRegenerate: null,
          },
        };
      }

    },
    handleMessageWithType: (state, action: { payload: { message: any, messageType: string } }) => {
      //NOTE: HANDLE IF MESSAGE HAS ANY TYPE
      const { message, messageType } = action.payload;
      let parsedMessage: any;

      if (typeof message === "string") {
        parsedMessage = JSON.parse(message)
      } else {
        parsedMessage = message
      }

      if (!parsedMessage.MessageId) return;

      const userEmail = localStorage.getItem("email")
      if (parsedMessage.UserName && parsedMessage.UserName === userEmail) return;


      const messageIndex = state.allMessages.findIndex(
        (msg) => msg.id === parsedMessage.MessageId
      );

      //TEST: NOT DO FOR SELECETED DO FOR IN ALL MESSAGE

      if (messageIndex !== -1) {
        if (state.selectedMessage && state.selectedMessage.id === parsedMessage.MessageId) {
          state.selectedMessage = {
            ...state.selectedMessage,
            isNeedAttention: messageType === "actionneeded" ? true : false,
            messageNeedPost: {
              status: "pending",
              messageType: messageType,
              messageAIKnows: "",
              message: parsedMessage.Message,
              id: parsedMessage.MessageId,
              isRegenerate: null,
            },
          }
        }

        state.allMessages[messageIndex] = {
          ...state.allMessages[messageIndex],
          isNeedAttention: messageType === "actionneeded" ? true : false,
          messageNeedPost: {
            status: "pending",
            messageType: messageType,
            messageAIKnows: "",
            message: parsedMessage.Message,
            id: null,
            isRegenerate: null,
          },
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initializeSignalR.fulfilled, (state, action) => {
        state.connectionStarted = action.payload;
      })
      .addCase(initializeSignalR.rejected, (state) => {
        state.connectionStarted = false;
        state.error = "SignalR bağlantısı başlatılamadı";
      })
      .addCase(fetchMessages.pending, (state, action) => {
        state.messagesLoading = true;
        state.error = null;
        const params = action.meta.arg;
        if (state.filterType !== params.filterType) {
          state.allMessages = []
        }
      })
      .addCase(fetchMessages.fulfilled, (state, action) => {
        const { items, ...rest } = action.payload;

        //if new messages filter diffrent or filter is search do that
        if (
          state.filterType !== rest.filterType ||
          rest.filterType === FilterType.SEARCH
        ) {
          state.messageMetaData = rest
          state.allMessages = items
        } else {
          if (state.allMessages.length > 0) {
            state.allMessages = [...state.allMessages, ...items]
          } else {
            state.messageMetaData = rest
            state.allMessages = items
          }
        }

        // if payload has filter type set it 
        if (rest.filterType) {
          state.filterType = rest.filterType
        }


        if (state.tabMessageCounts.all === 0) {
          state.tabMessageCounts = items.reduce(
            (acc: any, message: any) => ({
              all: 0,
              unread: acc.unread,
              actionNeeded: acc.actionNeeded + (message.isNeedAttention ? 1 : 0),
              notReplied: acc.notReplied + (message.messageNeedPost.status === "" ? 0 : 1),
              archived: acc.archived,
            }),
            {
              all: 0,
              unread: state.tabMessageCounts.unread,
              actionNeeded: 0,
              notReplied: 0,
              archived: 0,
            }
          );
          state.tabMessageCounts.all = rest.totalCount
        }

        state.messagesLoading = false;
      })
      .addCase(fetchMessages.rejected, (state, action) => {
        state.messagesLoading = false;
        state.error = action.payload as string;
      })
      .addCase(getMessageDetail.fulfilled, (state, action) => {
        const message = action.payload.data as unknown as MessageItemResponse
        if (message && message.items) {
          const items = message.items[0] as MessageHeader
          state.allMessages = [items, ...state.allMessages]
        }
      })
      .addCase(fetchSingleMessage.pending, (state, action) => {
        const params = action.meta.arg;
        if (state.messagesCache[params.messageId].chatMessages.length === 0) {
          state.singleMessageLoading = true;
          state.error = null;
        }
      })
      .addCase(fetchSingleMessage.fulfilled, (state, action) => {
        if (action.payload) {
          const { messageId, data, page, totalCount } = action.payload;

          if (!state.chatMessagePages[messageId]) {
            state.chatMessagePages[messageId] = {
              currentPage: 1,
              hasMore: true,
            };
          }

          if (data.items) {
            if (page === 1) {
              state.messagesCache[messageId].chatMessages = data.items
              //state.chatMessagesMap[messageId] = data.items;
            } else {
              //const existingMessages = state.chatMessagesMap[messageId] || [];
              const newMessages = data.items;
              const existingMessages = state.messagesCache[messageId].chatMessages || [];

              const uniqueNewMessages = newMessages.filter(
                (newMsg: any) =>
                  !existingMessages.some(
                    (existingMsg) => existingMsg.id === newMsg.id
                  )
              );

              state.messagesCache[messageId].chatMessages = [
                ...uniqueNewMessages,
                ...existingMessages
              ]

              // state.chatMessagesMap[messageId] = [
              //   ...uniqueNewMessages,
              //   ...existingMessages,
              // ];
            }

            state.chatMessagePages[messageId] = {
              currentPage: page,
              hasMore: (state.messagesCache[messageId].chatMessages?.length || 0) < totalCount,
            };

            const message = state.allMessages.find(item => item.id === messageId)

            if (message) {
              state.selectedMessage = message
            }
          }
          state.singleMessageLoading = false
        }
      })
      .addCase(fetchSingleMessage.rejected, (state, action) => {
        state.error = action.error.message || "Mesajlar yüklenirken bir hata oluştu";
        state.singleMessageLoading = false
      })
      .addCase(fetchReservationDetails.pending, (state, action) => {
        const params = action.meta.arg;
        if (!state.reservationCache[params.bookingId]) {
          state.reservationLoading = true
        }
      })
      .addCase(fetchReservationDetails.fulfilled, (state, action) => {
        if (action.payload) {
          if (!state.reservationCache[action.payload.id]) {
            state.reservationCache[action.payload.id] = null
          }
          state.bookingId = action.payload.id
          state.reservationCache[action.payload.id] = action.payload
          state.reservationLoading = false
        }
      })
      .addCase(fetchReservationDetails.rejected, (state) => {
        state.reservationLoading = false
      })
      .addCase(fetchUnreadCount.fulfilled, (state, action) => {
        state.tabMessageCounts.unread = action.payload?.data.count
      })
      //WARN: PUT
      .addCase(putAssignUser.pending, (state) => {
        state.messagesLoading = true;
        state.error = null;
      })
      .addCase(putAssignUser.fulfilled, () => {
      })
      .addCase(putAssignUser.rejected, (state, action) => {
        state.error = action.error.message || "Mesajlar yüklenirken bir hata oluştu";
      })
      .addCase(postSendMessage.pending, (state, action) => {
        const id = Math.floor(Math.random() * 1000000).toString();
        state.messageSendLoading = id;

        // Access the `params` from action.meta.arg
        const params = action.meta.arg;

        const newMessage = {
          ...params.messageDetails,
          id,
          createdAt: new Date().toISOString(),
          createdByName: "You",
          source: "manual"
        };

        state.messagesCache[state.messageId].chatMessages.push(newMessage)
        //state.chatMessagesMap[state.messageId].push(newMessage);
        state.messagesCache[state.messageId].chatInput = "";

      })
      .addCase(postSendMessage.fulfilled, (state, action) => {
        const id = Math.floor(Math.random() * 1000000).toString()
        const { messageId } = state
        const newMessage = {
          ...action.payload,
          id,
          createdAt: new Date().toISOString(),
          createdByName: "Property",
        };

        const messageIndex = state.allMessages.findIndex(
          (msg) => msg.id === messageId
        );

        if (messageIndex !== -1) {
          const updatedMessage = {
            ...state.allMessages[messageIndex],
            lastMessage: newMessage.message,
            lastMessageReceivedAt: newMessage.createdAt,
            messageCount: (state.allMessages[messageIndex].messageCount || 0) + 1,
          };

          state.allMessages.splice(messageIndex, 1);
          state.allMessages.unshift(updatedMessage);
        }
        state.messageSendLoading = ""
      })
      .addCase(postSendMessage.rejected, (state, action) => {
        state.messageSendLoading = ""
      })
      .addCase(updateMessageNeededPost.fulfilled, (state, action) => {
        const messageIndex = state.allMessages.findIndex(
          (msg) => msg.id === state.messageId
        );

        if (messageIndex > -1) {
          const messageNeedPost = {
            status: "",
            messageType: "",
            messageAIKnows: "",
            message: "",
            id: "",
            isRegenerate: null,
          }

          //WARN: CAN BE BUG THERE NEED TO CHECK

          if (state.selectedMessage) {
            state.selectedMessage = {
              ...state.selectedMessage,
              isNeedAttention: false,
              messageNeedPost
            }
          }

          state.allMessages[messageIndex] = {
            ...state.allMessages[messageIndex],
            isNeedAttention: false,
            messageNeedPost
          };

          if (state.tabMessageCounts.actionNeeded > 0) {
            state.tabMessageCounts.actionNeeded -= 1;
          }
        }

      })
      .addCase(updateMessageNeededPost.rejected, (state, action) => {
      })
      .addCase(updateMessageReadStatus.fulfilled, (state) => {
        const messageIndex = state.allMessages.findIndex(
          (msg) => msg.id === state.messageId
        );
        if (state.allMessages && state.messageId) {
          state.allMessages[messageIndex].isRead = true
          if (state.tabMessageCounts.unread > 0) {
            state.tabMessageCounts.unread -= 1;
          }
        }
      })
      .addCase(updateMessageHeader.fulfilled, (state, action) => {
        const messageIndex = state.allMessages.findIndex(
          (msg) => msg.id === state.messageId
        );
        if (state.allMessages && state.messageId) {
          state.allMessages[messageIndex].isRead = false
          state.tabMessageCounts.unread += 1;
        }
      })
  },
});

export const {
  setInputMessage,
  setActionNeededModal,
  setSelectedMessage,
  setMobileOpen,
  setFilterType,
  setReservation,
  setMessagesLoading,
  clearInputMessage,
  clearReservationState,
  toggleActionNeededModal,
  handleSignalRMessage,
  handleMessageWithType,
  handleFilteredMessages,
  handleMessageRemoveAttention
} = chatSlice.actions;

export const selectMessageItemsPageInfo = (
  state: { chat: ChatState },
  messageId: string
) =>
  state.chat.chatMessagePages[messageId] || {
    currentPage: 1,
    hasMore: true,
  };


export default chatSlice.reducer;

