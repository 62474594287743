const GetConfirmationColor = (status: string | undefined): string => {
  if (!status) {
    return "#000000"; // Varsayılan renk (Siyah)
  }

  switch (status.toLowerCase()) {
    case "confirmed":
      return "#5DBB63"; // Açık Yeşil
    case "currently hosting":
      return "#5DBB63"; // Açık Yeşil
    case "modified":
      return "#D9A34A"; // Altın Sarısı
    case "check-in today":
      return "#5DBB63"; // Açık Yeşil
    case "inquiry":
      return "#101A47"; // Koyu Lacivert
    case "post stay":
      return "#101A47"; // Koyu Lacivert
    case "pending":
      return "#D9A34A"; // Altın Sarısı
    case "check-in tomorrow":
      return "#5DBB63"; // Açık Yeşil
    case "expired":
      return "#F04A5D"; // Kırmızı
    case "no show":
      return "#101A47"; // Koyu Lacivert
    case "cancelled":
    case "canceled":
      return "#F04A5D"; // Kırmızı
    case "check-out today":
      return "#F04A5D"; // Kırmızı
    case "unknown":
      return "#101A47"; // Koyu Lacivert
    case "arriving in 2 days":
      return "#5DBB63"; // Açık Yeşil
    case "check-out tomorrow":
      return "#F04A5D"; // Kırmızı
    default:
      return "#000000"; // Siyah (Varsayılan renk)
  }
};

export default GetConfirmationColor;
