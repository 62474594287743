import "react-day-picker/dist/style.css";
import { DateRange, DayPicker } from "react-day-picker";

export default function DatePicker({
  range,
  numberOfMonth,
  setRange,
  styles,
  setSelectedPeriod,
  marginTop,
  isMobile,
  marginRight,
}: {
  range: DateRange;
  styles?: any;
  isMobile?: boolean;
  setRange: any;
  numberOfMonth?: number;
  marginTop?: string;
  setSelectedPeriod: (period: string) => void;
  marginRight?: string;
}) {

  const handleSelect = (newRange: any) => {
    setRange(newRange);
    setSelectedPeriod("custom");
  };

  return (
    <div
      style={{
        border: isMobile ? "none" : "1px solid #e0e0e0",
        borderRadius: "8px",
        marginTop: marginTop,
        marginRight: marginRight,
        width: isMobile ? "100%" : "auto",
      }}
    >
      <DayPicker
        styles={styles ? styles : null}
        mode="range"
        numberOfMonths={numberOfMonth ? numberOfMonth : 2}
        selected={range}
        onSelect={handleSelect}
      />
    </div>
  );
}
