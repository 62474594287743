import DetailsInputArea from "../../../../../../../../components/shared-components/DetailsInputArea";
import DiscountsModal from "../modals/DiscountsModal";
import { useTranslation } from "react-i18next";
import CleaningAndTaxes from "../modals/CleaningAndTaxes";

function Discount() {
  const { t } = useTranslation();
  const data = [
    {
      title: t("detailsPages.pricing.discountForLongerStays"),
      subtitle: t("detailsPages.pricing.notSet"),
      component: (
        <DiscountsModal
          title={t("detailsPages.pricing.discountForLongerStays")}
          textsTitle={t(
            "detailsPages.pricing.ifYouWouldLikeToOfferDiscountedRatesForLongerStaysYouCanDefineTheDiscountRateHere"
          )}
          firstDescription={t(
            "detailsPages.pricing.theDiscountIsAppliedToTheRentalRateForTheEntireBooking"
          )}
          secondDescription={t(
            "detailsPages.pricing.youCanChooseWhetherToApplyThisDiscountInYourOtherSpecialRatesRules"
          )}
          longerStaysDiscountTitle={t(
            "detailsPages.pricing.bookingLengthAndDiscountAmount"
          )}
          longerStaysDiscount={true}
        />
      ),
      isSelect: false,
    },
    {
      title: t("detailsPages.pricing.lastMinuteEarlyBirdDiscounts"),
      subtitle: t("detailsPages.pricing.notSet"),
      component: (
        <DiscountsModal
          title={t("detailsPages.pricing.lastMinuteEarlyBirdDiscounts")}
          textsTitle={t(
            "detailsPages.pricing.defineDiscountsForGuestsBookingEarlyOrAtTheLastMinuteTheseDiscountsApplyForAllPeriodsWhichMatchTheRulesBelow"
          )}
          lastMinuteDiscountTitle={t(
            "detailsPages.pricing.lastMinuteDiscounts"
          )}
          lastMinuteDiscount={true}
          earlyBirdDiscountTitle={t("detailsPages.pricing.earlyBirdDiscounts")}
          earlyBirdDiscount={true}
        />
      ),
      isSelect: false,
    },
    {
      title: t("detailsPages.pricing.specialDiscounts"),
      subtitle: t("detailsPages.pricing.notSet"),
      component: (
        <CleaningAndTaxes
          title={t("detailsPages.pricing.specialDiscounts")}
          inputTitle={t("detailsPages.pricing.giveAName")}
          showPrice={true}
          showSpecialDiscount={true}
        />
      ),
      isSelect: false,
    },
  ];

  return (
    <>
      <DetailsInputArea
        title={t("detailsPages.pricing.discounts")}
        data={data}
        onClick={() => { }}
        id={"discount"}
      />
    </>
  );
}

export default Discount;
