const LoadingDots = () => {
  return (
    <div className="make-center">
      <div className="loading-container">
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <style>
          {`
          .make-center {
              display: flex;
              justify-content: center;
              align-items: center;
              min-height: 100%;
              width: 100%; 
          }

          .loading-container {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
          }

          .dot {
            width: 10px;
            height: 10px;
            background-color: #007bff; /* Adjust color as needed */
            border-radius: 50%;
            animation: loading-dots 1.2s infinite ease-in-out;
          }

          .dot:nth-child(1) {
            animation-delay: 0s;
          }

          .dot:nth-child(2) {
            animation-delay: 0.2s;
          }

          .dot:nth-child(3) {
            animation-delay: 0.4s;
          }

          @keyframes loading-dots {
            0%, 80%, 100% {
              transform: scale(0);
              opacity: 0.5;
            }
            40% {
              transform: scale(1);
              opacity: 1;
            }
          }
        `}
        </style>
      </div>
    </div>
  );
};

export default LoadingDots;

