import React from "react";
import { Fade } from "@mui/material";
import SMS from "../../../../../assets/images/icons/message channels.svg";
import Airbnb from "../../../../../assets/images/icons/Group 1171274844.svg";
import Whatsapp from "../../../../../assets/images/icons/JvsnINJ2CZv 1.svg";
import CommunicationIcon from "../../../../../assets/images/icons/communication.svg";
import CapitalizeWords from "../../../../../components/shared-components/CapitalizeWords";
import MessageFormatter from "./MessageFormatter";
import { MessageItem } from "../../../../../types";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import "./Chat.scss"
import { Flex } from "@mantine/core";
import { h_formatUTCtoLocaleTime } from "../../../../../utils/helpers";

interface ChatProps {
  message: MessageItem
  showTime: boolean;
  isRead?: boolean;
}

const Chat: React.FC<ChatProps> = ({ message, showTime, }) => {
  const viaIcons = [SMS, Airbnb, Whatsapp];
  const { messageSendLoading } = useSelector((state: RootState) => state.chat);


  const _displayName = () => {
    if (message?.sender === "property") {
      return message.createdByName || "Property";
    }
    return <CapitalizeWords text={message.sender} />;
  };

  const _displayTag = () => {
    if (message.sender === "property") {
      return message.createdByName ? (
        <span
          className="B-12-12-600"
          style={{ opacity: "0.5", color: "var(--Gray-700, #344054)" }}
        >
          {message.createdByName}
        </span>
      ) : (
        <span
          className="B-12-12-600"
          style={{ opacity: "0.5", color: "var(--Gray-700, #344054)" }}
        >
          AGENT
        </span>
      );
    } else {
      let icon = ""
      switch (message.source) {
        case "manual":
          icon = viaIcons[0]
          break;
        case "airbnb":
          icon = viaIcons[1]
          break;
        case "whatsapp":
          icon = viaIcons[2]
          break;
      }
      return <img src={icon} alt="User" style={{ opacity: "0.5" }} />
    }
  };

  const messageBoxClassname = [
    "chat-box",
    message.sender === "property" ? "property" : "user",
    message.messageType === "internal" && "internal-note",
    messageSendLoading === message.id && "loading",
  ].filter(Boolean).join(" ")

  return (
    <Fade in={true} timeout={500}>
      <div
        id={message.id}
        className={messageBoxClassname}
      >
        <div className="message-inside-text">
          {message.messageType === "internal" && (
            <Flex align="center" gap="10px">
              <img src={CommunicationIcon} alt="communication-icon" />
              <span
                className="B-12-12-600"
                style={{ opacity: "0.5", color: "var(--Warning-400, #FDB022)" }}
              >
                INTERNAL NOTE
              </span>
            </Flex>
          )}
          <div className="message-inside-text-top">
            <p className="message-inside-text-name B-16-24-600">
              {_displayName()}
            </p>
            {_displayTag()}
          </div>

          <div className="message-inside-text-message B-14-20-400">
            <MessageFormatter text={message.message} />
          </div>
          {showTime && (<p className="message-inside-text-time">{h_formatUTCtoLocaleTime(message.createdAt)}</p>)}
        </div>
      </div>
    </Fade>
  );
};

export default Chat;
