import { Stack, Typography } from "@mui/material";
import React from "react";

export interface Props {
  title: string;
  info?: string;
}

function DetailsContentTitle(props: Props) {
  const { title, info } = props;
  return (
    <Stack gap={"4px"}>
      <Typography
        sx={{
          color: "var(--Gray-Gray-900, #181c32)",
          fontFamily: "Inter",
          fontSize: "18px",
          fontStyle: "normal",
          fontWeight: "600",
          lineHeight: "24px",
          letterSpacing: "-0.18px",
        }}
      >
        {title}
      </Typography>
      {info && <p className="B-14-20-400">{info}</p>}
    </Stack>
  );
}

export default DetailsContentTitle;
