import { Middleware } from "redux";
import { handleFilteredMessages, setMessagesLoading } from "../slices/chatSlice";

export const filterMiddleware: Middleware = ({ dispatch }) => (next) => (action: any) => {
  if (action.type === "filter/fetchFilteredMessages/pending") {
    dispatch(setMessagesLoading(true))
  }

  if (action.type === "filter/fetchFilteredMessages/fulfilled") {
    dispatch(handleFilteredMessages(action.payload))
  }

  if (action.type === "filter/fetchFilteredMessages/rejecetd") {
    dispatch(setMessagesLoading(false))
  }


  return next(action);
};


