import { Stack } from "@mui/material";
import { useState } from "react";
import StepTwo from "./components/step-two";
import StepOne from "./components/step-one";
import { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";

interface Props {
  start?: Dayjs;
  end?: Dayjs;
}
function NewBooking(props: Props) {
  const { t } = useTranslation();
  const { start, end } = props;
  const [step, setStep] = useState<number>(1);

  return (
    <>
      <Stack spacing={3} sx={{ width: "100%" }}>
        <p
          className="H-24"
          style={{
            textAlign: "center",
          }}
        >
          {t("createreservationpage.createReservation")}
        </p>
        {step === 1 ? (
          <StepOne setStep={setStep} start={start} end={end} />
        ) : (
          <StepTwo setStep={setStep} />
        )}
      </Stack>
    </>
  );
}

export default NewBooking;
