import "./Autopilot.scss"
import ChatBot from "../../../../components/shared-components/ChatBot/ChatBot";
import { Text, Title } from "@mantine/core";

const Autopilot = () => {
  return (
    <div className="autopilot-main">
      <div className="autopilot-header">
        <Title order={2}>Autopilot</Title>
        <Text c="dimmed">
          Automatically respond to guest messages with AI.
          <br />
          Note: Autopilot runs on a different system from draft mode.
          Since it automatically sends responses to guests without waiting
          for your approval, it doesn't make anything up. It only uses
          information that you've already supplied to Botel either via
          your PMS or via your host notes.

        </Text>
      </div>
      <ChatBot />
    </div>
  );
};

export default Autopilot;
