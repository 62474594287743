import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { menuDatas } from "../menu/data";
import ArrowDownSvg from "../../../../../../assets/images/icons/arrow-right.svg";

interface Props {
  subMenu: string;
  setSubMenu: React.Dispatch<React.SetStateAction<string>>;
}

function MobileMenu({ subMenu, setSubMenu }: Props) {
  const location = useLocation();
  const [activeSection, setActiveSection] = useState<string>("");
  const sectionsRef = useRef<{ [key: string]: Element }>({});
  const menuTabsRef = useRef<HTMLDivElement>(null); // Reference to the menu tabs container

  useEffect(() => {
    const setActiveSectionFromUrl = () => {
      const current = location.pathname.split("/");
      const currentPath = current[current.length - 1];

      const matchedMenu = menuDatas(subMenu).find((menuData) =>
        currentPath.includes(menuData.url)
      );

      if (matchedMenu) {
        setActiveSection(matchedMenu.url);
      }
    };

    setActiveSectionFromUrl();

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5,
      }
    );

    menuDatas(subMenu).forEach((menuData: any) => {
      const element = document.getElementById(menuData.url);
      if (element) {
        sectionsRef.current[menuData.url] = element;
        observer.observe(element);
      }
    });

    return () => {
      if (sectionsRef.current) {
        Object.values(sectionsRef.current).forEach((element) => {
          observer.unobserve(element);
        });
      }
    };
  }, [location.pathname, subMenu]);

  const handleNavButtonClick = () => {
    if (menuTabsRef.current) {
      const maxScrollLeft =
        menuTabsRef.current.scrollWidth - menuTabsRef.current.clientWidth;
      const newScrollLeft = Math.min(
        menuTabsRef.current.scrollLeft + 100,
        maxScrollLeft
      );
      menuTabsRef.current.scrollTo({ left: newScrollLeft, behavior: "smooth" });
    }
  };

  return (
    <Box className="mobile-menu-container">
      {!subMenu && (
        <>
          <Box
            className="mobile-menu-tabs"
            ref={menuTabsRef}
            sx={{
              display: "flex",
              overflowX: "auto",
              whiteSpace: "nowrap",
              padding: "10px 0",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            {React.Children.toArray(
              menuDatas(subMenu).map((menuData: any) => (
                <Link to={menuData.url}>
                  <p
                    className={`mobile-menu-tab ${
                      activeSection === menuData.url ? "active" : ""
                    }`}
                  >
                    {menuData.title}
                  </p>
                </Link>
              ))
            )}
          </Box>
          <img
            className="menu-nav-button"
            src={ArrowDownSvg}
            alt="arrows-horizontal"
            onClick={handleNavButtonClick}
            style={{ cursor: "pointer" }}
          />
        </>
      )}
    </Box>
  );
}

export default MobileMenu;
