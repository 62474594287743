import { AxiosInstance, AxiosResponse } from "axios";
import createApiInstance from "../core/apiInstance";

interface ApiResponse<T> {
  data: T;
  status: boolean;
  statusCode: number;
  errorMessage: string;
}

export class BaseService<T> {
  private endpointUrl: string = "";
  private apiInstance: AxiosInstance;

  constructor(url: string, baseURL: string | undefined) {
    this.endpointUrl = url;
    this.apiInstance = createApiInstance(baseURL);
  }

  async getAll(url = this.endpointUrl): Promise<ApiResponse<T>> {
    return this.apiInstance
      .get(url)
      .then((apiResponse: AxiosResponse<T>) => {
        return {
          data: apiResponse.data,
          status: true,
          statusCode: apiResponse.status,
          errorMessage: "",
        };
      })
      .catch((error) => {
        throw error;
      });
  }

  async getById(
    id: string,
    url = `${this.endpointUrl}/${id}`
  ): Promise<ApiResponse<T>> {
    return this.apiInstance
      .get(url)
      .then((apiResponse: AxiosResponse<T>) => ({
        data: apiResponse.data,
        status: true,
        statusCode: apiResponse.status,
        errorMessage: "",
      }))
      .catch((error) => {
        throw error;
      });
  }

  async add(data: T, url = this.endpointUrl): Promise<ApiResponse<T>> {
    return this.apiInstance
      .post(url, data)
      .then((apiResponse: AxiosResponse<T>) => ({
        data: apiResponse.data,
        status: true,
        statusCode: apiResponse.status,
        errorMessage: "",
      }))
      .catch((error) => {
        throw error;
      });
  }

  async update(data: T, url = this.endpointUrl): Promise<ApiResponse<T>> {
    return this.apiInstance
      .put(url, data)
      .then((apiResponse: AxiosResponse<T>) => ({
        data: apiResponse.data,
        status: true,
        statusCode: apiResponse.status,
        errorMessage: "",
      }))
      .catch((error) => {
        throw error;
      });
  }

  async remove(id: string, url = `${this.endpointUrl}/${id}`): Promise<void> {
    return this.apiInstance
      .delete(url)
      .then(() => {
        return;
      })
      .catch((error) => {
        throw error;
      });
  }
}
