import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import MainTextField from "../../../../components/core-components/inputs/TextField/MainTextField";
import CustomButton from "../../../../components/shared-components/CustomButton";

interface PhoneModalProps {
  phone: string;
  onSave: (email: string) => void;
}

function PhoneModal({ phone: initialPhone, onSave }: PhoneModalProps) {
  const [phone, setPhone] = useState<string>(initialPhone);
  const [phoneError, setPhoneError] = useState<string | null>(null);

  useEffect(() => {
    setPhone(initialPhone);
  }, [initialPhone]);

  const validatePhone = (phone: string) => {
    const re = /^\+90\d{10}$/;
    return re.test(phone);
  };

  const handleSave = () => {
    if (validatePhone(phone)) {
      setPhoneError(null);
      onSave(phone);
    } else {
      setPhoneError("Invalid phone number");
    }
  };

  return (
    <>
      <Stack spacing={3} pb={4}>
        <Stack spacing={"12px"}>
          <p className="B-14-20-500">Phone</p>
          <MainTextField
            title=""
            onChange={(e) => setPhone(e.target.value)}
            value={phone}
            placeholder="+90555555555"
            errorMessage={phoneError}
            type="tel"
          />
        </Stack>
      </Stack>
      <CustomButton
        backgroundColor="#0065E0"
        color="#FFFFFF"
        onClick={handleSave}
        buttonText="Save"
        marginTop={0}
        buttonWidth={"100%"}
      />
    </>
  );
}

export default PhoneModal;
