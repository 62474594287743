import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import { useTranslation } from "react-i18next";
import NavbarListingIcon from "../../assets/images/icons/vuesax/bold/vuesax/bold/kanban.svg";
import NavbarInboxIcon from "../../assets/images/icons/vuesax/bold/vuesax/bold/message.svg";
import NavbarMenuIcon from "../../assets/images/icons/vuesax/linear/vuesax/linear/menu.svg";

interface NavbarMobileProps {
  isOnboarding: boolean;
  setIsOnBoarding: (isOnboarding: boolean) => void;
}

const NavbarMobile = ({ isOnboarding }: NavbarMobileProps) => {
  const { t } = useTranslation();

  const pages = [
    // {
    //   name: `${t("header.today")}`,
    //   route: "/",
    //   icon: <img src={NavbarHomeIcon} alt="Home" />,
    // },
    // {
    //   name: `${t("header.calendar")}`,
    //   route: "/calendar",
    //   icon: <img src={NavbarCalendarIcon} alt="Calendar" />,
    // },
    {
      name: `${t("header.listing")}`,
      route: "/listings",
      icon: <img src={NavbarListingIcon} alt="Listing" />,
    },
    {
      name: `${t("header.inbox")}`,
      route: "/inbox",
      icon: <img src={NavbarInboxIcon} alt="Inbox" />,
    },
    {
      name: `${t("header.menu")}`,
      route: "/menu",
      icon: <img src={NavbarMenuIcon} alt="Menu" />,
    },
  ];

  const location = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = useState(-1);

  useEffect(() => {
    const currentIndex = pages.findIndex(
      (page) => page.route === location.pathname
    );

    setValue(currentIndex !== -1 ? currentIndex : -1);
  }, [location]);

  return (
    <>
      {!isOnboarding && (
        <BottomNavigation
          value={value}
          onChange={(_, newValue) => {
            if (pages[newValue].name !== `${t("header.menu")}`) {
              setValue(newValue);
              navigate(pages[newValue].route);
            } else {
              navigate(pages[newValue].route);
            }
          }}
          showLabels
          sx={{
            width: "100%",
            height: "96px",
            position: "fixed",
            bottom: 0,
            zIndex: 100,
            padding: "16px 0px 24px 0px",
            borderTop: "1px solid var(--stroke-colors, #EBEBEB)",
          }}
        >
          {pages.map((page, index) => (
            <BottomNavigationAction
              key={page.route}
              label={page.name}
              icon={page.icon}
              sx={{
                display: "flex",
                width: "76px",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "4px",
                flexShrink: 0,
                color: value === index ? "#0065E0" : "#000000",
                "& img": {
                  filter:
                    value === index
                      ? "brightness(0) saturate(100%) invert(22%) sepia(94%) saturate(3058%) hue-rotate(191deg) brightness(97%) contrast(102%)"
                      : "none",
                },
              }}
            />
          ))}
        </BottomNavigation>
      )}
    </>
  );
};

export default NavbarMobile;
