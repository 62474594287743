import React, { ChangeEventHandler } from "react";
import checkTick from "../../../../assets/images/icons/Path 94.svg";
import { Stack } from "@mui/material";
interface CustomCheckboxProps {
  label: string;
  position?: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  inputName: string;
}

function CustomCheckbox({
  label,
  onChange,
  inputName,
  position,
}: CustomCheckboxProps) {
  return (
    <>
      <Stack
        alignItems={"center"}
        justifyContent={"flex-start"}
        gap={1}
        flexDirection={"row"}
      >
        {" "}
        {position === "right" && (
          <label className="checkbox-label" htmlFor={inputName}>
            {label}
          </label>
        )}
        <label id="checkbox-label" className="custom-checkbox">
          <input
            id={inputName}
            name={inputName}
            onChange={onChange}
            type="checkbox"
          />
          <span className="checkmark">
            <img src={checkTick} alt="Checkmark" />
          </span>
        </label>
        {position === "left" && (
          <label className="checkbox-label" htmlFor={inputName}>
            {label}
          </label>
        )}
      </Stack>
    </>
  );
}

export default CustomCheckbox;
