import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import IgnoreIcon from "../../Svgs/ignore-icon";
import AgreeIcon from "../../Svgs/agree-icon";

function QuickSelectRadioButtons() {
  return (
    <>
      <FormControl>
        <RadioGroup
          name={"quick-select"}
          sx={{
            flexDirection: "row",
            "& .MuiButtonBase-root.MuiRadio-root": {
              padding: "1px",
              "&:hover":{
                background:"transparent"
              }
            },
            "& .MuiTypography-root": {
              display: "none",
            },
            "& .MuiFormControlLabel-root":{
                margin:"0px",
            }
          }}
        >
          <FormControlLabel
            value="no"
            control={
              <Radio
                icon={<IgnoreIcon bgColor={"#A1A5B7"} color={"#A1A5B7"} />}
                checkedIcon={<IgnoreIcon />}
                sx={{paddingRight:"14px !important"}}
                disableRipple
              />
            }
            label="no"
          />
          <FormControlLabel
            value="yes"
            control={
              <Radio
                icon={<AgreeIcon bgColor={"#A1A5B7"} color={"#A1A5B7"} />}
                checkedIcon={<AgreeIcon />}
                disableRipple
              />
            }
            label="yes"
          />
        </RadioGroup>
      </FormControl>
    </>
  );
}

export default QuickSelectRadioButtons;
