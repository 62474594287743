import React, { useEffect, useRef, useState } from "react";
import "../../../../assets/sass/components/_reservation-details-card.scss";
import avatar from "../../../../assets/images/avatar2.jpeg";
import { useTranslation } from "react-i18next";
import moment from "moment";
import i18n from "../../../../utils/i18n/i18n";
import {
  useMediaQuery,
  useTheme,
  MenuItem,
  Select,
  Popover,
} from "@mui/material";
import { Stack } from "@mui/system";
import { Button, Box } from "@mui/material";
import StatusEllipseSvg from "../../../../assets/images/icons/StatusEllipseSvg";
import Airbnb from "../../../../assets/images/icons/Group 1171274844.svg";
import Hostaway from "../../../../assets/images/icons/hostaway.svg";
import Guesty from "../../../../assets/images/icons/guesty.svg";
import Call from "../../../../assets/images/icons/call-icon.svg";
import ArrowDown from "../../../../assets/images/icons/arrowdown.svg";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers-pro/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangeCalendar } from "@mui/x-date-pickers-pro/DateRangeCalendar";
import dayjs, { Dayjs } from "dayjs";
import CalendarReservationDetailsModal from "./CalendarReservationDetailsModal";
import isBetween from "dayjs/plugin/isBetween";
import SuggestModal from "./components/SuggestModal";
import DeclineModal from "./components/DeclineModal";
import ApproveModal from "./components/ApproveModal";
import SpecialOfferModal from "./components/SpecialOfferModal";
import GetConfirmationColor from "../../../../components/shared-components/GetConfirmationColor";
import CapitalizeWords from "../../../../components/shared-components/CapitalizeWords";
import { parsePhoneNumber, CountryCode } from "libphonenumber-js";
import BookingDetailsListItem from "./BookingDetailsListItem";
import { useAuth0 } from "@auth0/auth0-react";
import { ReservationDetails } from "../../../../types";

moment.locale(i18n.language);
dayjs.extend(isBetween);

interface GuestPaidItem {
  item: string;
  price: string;
}

interface ReservationDetailsCardProps {
  isOpen?: boolean;
  setIsOpen?: (value: boolean) => void;
  reservationData: ReservationDetails
}

function getCurrencySymbol(currencyCode: string): string {
  switch (currencyCode) {
    case "USD":
      return "$";
    case "EUR":
      return "€";
    case "GBP":
      return "£";
    case "TRY":
      return "₺";
    default:
      return currencyCode;
  }
}

const CustomerNameContent = ({
  fullName,
  customerOtaUrl,
}: {
  fullName: string;
  customerOtaUrl?: string;
}) => {
  if (customerOtaUrl) {
    return (
      <Box
        component="span"
        className="B-16-24-600"
        sx={{
          position: "relative",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          gap: "4px",
          "&:hover": {
            textDecoration: "underline",
          },
        }}
      >
        <a
          href={customerOtaUrl}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: "inherit",
            textDecoration: "none",
            display: "flex",
            alignItems: "center",
            gap: "4px",
          }}
        >
          {fullName}
          <svg
            width="12"
            height="12"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6" />
            <polyline points="15 3 21 3 21 9" />
            <line x1="10" y1="14" x2="21" y2="3" />
          </svg>
        </a>
      </Box>
    );
  }

  return (
    <Box
      component="span"
      className="B-16-24-600"
      sx={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        gap: "4px",
      }}
    >
      {fullName}
    </Box>
  );
};
const ReservationCardBody = ({
  confirm,
  fullName,
  details,
  propertyImageUrl,
  phone,
  reservationData,
  propertyUrl,
}: {
  confirm: string;
  fullName: string;
  details: string[];
  propertyImageUrl?: string;
  phone?: string;
  provider?: string;
  reservationData?: any;
  propertyUrl?: string;
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isRotated, setIsRotated] = useState(false);
  const callOptions = ["Phone Call", "Whatsapp Call", "Facetime Audio"];
  const open = Boolean(anchorEl);
  const [openSuggestModal, setOpenSuggestModal] = useState(false);
  const handleCloseSuggestModal = () => setOpenSuggestModal(false);
  const handleSaveSuggestModal = () => {
    setOpenSuggestModal(false);
  };
  const [openDeclineModal, setOpenDeclineModal] = useState(false);
  const handleCloseDeclineModal = () => setOpenDeclineModal(false);
  const handleSaveDeclineModal = () => {
    setOpenDeclineModal(false);
  };
  const [openApproveModal, setOpenApproveModal] = useState(false);
  const handleOpenApproveModal = () => setOpenApproveModal(true);
  const handleCloseApproveModal = () => setOpenApproveModal(false);
  const handleSaveApproveModal = () => {
    setOpenApproveModal(false);
  };

  const [openSpecialOfferModal, setOpenSpecialOfferModal] = useState(false);
  const handleCloseSpecialOfferModal = () => setOpenSpecialOfferModal(false);
  const handleSaveSpecialOfferModal = () => {
    setOpenSpecialOfferModal(false);
  };
  const [reservationStatus, setReservationStatus] = useState(
    reservationData.status
  );

  const handleStatusUpdate = (newStatus: string) => {
    setReservationStatus(newStatus);
  };
  const handleCallClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setIsRotated(!isRotated);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setIsRotated(false);
  };

  const showPreApproveButton =
    confirm === "inquiry" &&
    (reservationData?.otaName?.toLowerCase() === "airbnb" ||
      reservationData?.otaName?.toLowerCase() === "airbnb2");

  const PropertyContent = () => (
    <Stack
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        borderRadius: "100px",
      }}
    >
      {propertyUrl ? (
        <Box
          component="a"
          href={propertyUrl}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="View property details"
          sx={{
            position: "relative",
            display: "block",
            transition: "transform 0.2s ease",
            "&:hover": {
              transform: "scale(1.05)",
            },
          }}
        >
          <img
            src={propertyImageUrl || avatar}
            alt="avatar"
            style={{
              width: "60px",
              height: "60px",
              borderRadius: "100px",
              transition: "opacity 0.2s ease",
            }}
          />
          <Box
            sx={{
              position: "absolute",
              top: -4,
              right: -4,
              backgroundColor: "white",
              borderRadius: "50%",
              padding: "4px",
              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "16px",
              height: "16px",
            }}
          >
            <svg
              width="10"
              height="10"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#666"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6" />
              <polyline points="15 3 21 3 21 9" />
              <line x1="10" y1="14" x2="21" y2="3" />
            </svg>
          </Box>
        </Box>
      ) : (
        <img
          src={propertyImageUrl || avatar}
          alt="avatar"
          style={{
            width: "60px",
            height: "60px",
            borderRadius: "100px",
          }}
        />
      )}
    </Stack>
  );

  const formatPhoneNumber = (phoneNumber: string): string => {
    try {
      const cleanNumber = phoneNumber.replace(/\D/g, "");

      let parsedNumber;

      try {
        parsedNumber = parsePhoneNumber(cleanNumber);
      } catch {
        const commonCountries: CountryCode[] = ["TR", "SA", "AE", "US", "GB"];

        for (const country of commonCountries) {
          try {
            parsedNumber = parsePhoneNumber(cleanNumber, country);
            if (parsedNumber?.isValid()) {
              break;
            }
          } catch {
            continue;
          }
        }
      }

      if (parsedNumber?.isValid()) {
        return parsedNumber.formatInternational();
      }

      if (cleanNumber.length === 10) {
        return `+90 ${cleanNumber.slice(0, 3)} ${cleanNumber.slice(
          3,
          6
        )} ${cleanNumber.slice(6, 8)} ${cleanNumber.slice(8)}`;
      }

      return phoneNumber;
    } catch (error) {
      console.error("Telefon numarası formatlanırken hata oluştu:", error);
      return phoneNumber;
    }
  };

  return (
    <Stack
      padding={isMobile ? 0 : "20px"}
      gap="10px"
      className="reservation-card-body"
    >
      <Stack
        direction={"row"}
        padding="8px 12px"
        gap="8px"
        className="reservation-card-body-gray-area"
        justifyContent={"space-between"}
      >
        <Stack alignItems="flex-start" gap="4px">
          <Stack
            direction={"row"}
            justifyContent="center"
            alignItems="center"
            gap="4px"
          >
            <StatusEllipseSvg status={reservationStatus} />
            <span
              className="B-12-18-500"
              style={{ color: GetConfirmationColor(reservationStatus) }}
            >
              <CapitalizeWords text={reservationStatus} />
            </span>
          </Stack>
          <Stack
            direction={"row"}
            justifyContent="center"
            alignItems="center"
            gap="8px"
          >
            <CustomerNameContent
              fullName={fullName}
              customerOtaUrl={reservationData?.customerOtaUrl}
            />

            {(() => {
              const source = reservationData?.otaName?.toLowerCase?.();

              switch (source) {
                case "guesty":
                  return (
                    <Box
                      component="img"
                      src={Guesty}
                      alt="guesty-platform"
                      sx={{
                        width: "16.717px",
                        height: "18px",
                        flexShrink: 0,
                        opacity: 0.5,
                      }}
                    />
                  );
                case "airbnb":
                case "airbnb2":
                  return (
                    <Box
                      component="img"
                      src={Airbnb}
                      alt="airbnb-platform"
                      sx={{
                        width: "16.717px",
                        height: "18px",
                        flexShrink: 0,
                        opacity: 0.5,
                      }}
                    />
                  );
                case "hostaway":
                  return (
                    <Box
                      component="img"
                      src={Hostaway}
                      alt="hostaway-platform"
                      sx={{
                        width: "16.717px",
                        height: "18px",
                        flexShrink: 0,
                        opacity: 0.5,
                      }}
                    />
                  );
                case "lodgify":
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "16.717px",
                        height: "18px",
                        borderRadius: "50%",
                        bgcolor: "#F1F1F1",
                        fontSize: "10px",
                        fontWeight: 600,
                        color: "#666",
                        textTransform: "uppercase",
                        opacity: 0.5,
                      }}
                    >
                      L
                    </Box>
                  );
                case "manual":
                  return (
                    <Box
                      component="span"
                      sx={{
                        fontSize: "10px",
                        color: "#667085",
                        opacity: 0.5,
                      }}
                    >
                      {reservationData?.provider}
                    </Box>
                  );
                default:
                  return source ? (
                    <Box
                      component="span"
                      sx={{
                        fontSize: "10px",
                        color: "#667085",
                        opacity: 0.5,
                      }}
                    >
                      {source.charAt(0).toUpperCase() + source.slice(1)}
                    </Box>
                  ) : null;
              }
            })()}
          </Stack>
          <Stack alignItems="flex-start" spacing={"4px"}>
            {details.map((detail, index) => (
              <p key={index} className="reservation-detail B-14-20-500">
                {detail}
              </p>
            ))}
          </Stack>
        </Stack>
        <PropertyContent />
        {/* <Stack
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{
            borderRadius: "100px",
          }}
        >
          <Box
            component="a"
            href={propertyUrl}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="View property details"
            sx={{
              position: "relative",
              display: "block",
              transition: "transform 0.2s ease",
              "&:hover": {
                transform: "scale(1.05)",
              },
            }}
          >
            <img
              src={propertyImageUrl || avatar}
              alt="avatar"
              style={{
                width: "60px",
                height: "60px",
                borderRadius: "100px",
                transition: "opacity 0.2s ease",
              }}
            />
            <Box
              sx={{
                position: "absolute",
                top: -4,
                right: -4,
                backgroundColor: "white",
                borderRadius: "50%",
                padding: "4px",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "16px",
                height: "16px",
              }}
            >
              <svg
                width="10"
                height="10"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#666"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6" />
                <polyline points="15 3 21 3 21 9" />
                <line x1="10" y1="14" x2="21" y2="3" />
              </svg>
            </Box>
          </Box>
        </Stack> */}
      </Stack>
      {phone && (
        <Stack
          direction={"row"}
          padding="8px 12px"
          gap="8px"
          className="reservation-card-body-gray-area"
          justifyContent={"space-between"}
        >
          <Stack alignItems="flex-start" gap="4px">
            <span
              className="B-12-18-500"
              style={{ color: "var(--Gray-900, #101828)" }}
            >
              Phone Number
            </span>
            <span
              className="B-14-20-500"
              style={{ color: "var(--Gray-500, #667085)" }}
            >
              {formatPhoneNumber(phone)}
            </span>
          </Stack>
          <Button onClick={handleCallClick}>
            <Stack direction={"row"} spacing={1}>
              <img src={Call} alt="call-icon"></img>
              <img
                src={ArrowDown}
                alt="arrow-down"
                style={{
                  transition: "transform 0.3s",
                  transform: isRotated ? "rotate(180deg)" : "none",
                }}
              ></img>
            </Stack>
          </Button>
          <Popover
            sx={{
              "& .MuiPopover-paper": {
                borderRadius: "12px !important",
              },
              "& .MuiPaper-root": {
                borderRadius: "12px !important",
              },
            }}
            open={open}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Box
              sx={{
                display: "flex",
                padding: "10px 12px",
                flexDirection: "column",
                alignItems: "flex-start",
                alignSelf: "stretch",
                width: "180px",
                boxShadow:
                  "0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)",
                borderRadius: "12px",
                overflow: "visible",
                gap: "5px",
              }}
            >
              {callOptions.map((option) => (
                <MenuItem
                  key={option}
                  onClick={handlePopoverClose}
                  className="B-14-20-400"
                  sx={{
                    display: "flex",
                    padding: "7px 10px",
                    alignItems: "flex-start",
                    alignSelf: "stretch",
                    borderRadius: "10px",
                    cursor: "pointer",
                    color: "#181C32",
                    "&.Mui-selected": {
                      background: "#E4F2FD",
                    },
                  }}
                >
                  {option}
                </MenuItem>
              ))}
            </Box>
          </Popover>
        </Stack>
      )}
      {showPreApproveButton && (
        <Stack
          padding="8px 12px"
          gap="8px"
          className="reservation-card-body-gray-area"
          justifyContent={"space-between"}
        >
          <Stack
            padding="8px 12px"
            gap="8px"
            className="reservation-card-body-gray-area"
            justifyContent={"space-between"}
          >
            <Stack direction={"row"} spacing={1} width={"100%"}>
              <button
                className="reservation-card-body-button B-14-20-500"
                onClick={handleOpenApproveModal}
              >
                Pre-approve
              </button>
            </Stack>
          </Stack>

          {/* <Stack direction={"row"} width={"100%"}>
          <button
            className="reservation-card-body-button-secondary B-14-20-500"
            style={{ width: "60%" }}
            onClick={handleOpenSuggestModal}
          >
            <Stack direction={"row"} spacing={1}>
              <img src={List} alt="list-icon"></img>
              <span>Suggest New Listing</span>
            </Stack>
          </button>
          <button
            className="reservation-card-body-button-secondary B-14-20-500"
            style={{ width: "40%" }}
            onClick={handleOpenDeclineModal}
          >
            <Stack direction={"row"} spacing={1}>
              <img src={Arrows} alt="close-icon"></img>
              <span>Decline</span>
            </Stack>
          </button>
        </Stack> */}
        </Stack>
      )}
      <SuggestModal
        open={openSuggestModal}
        handleClose={handleCloseSuggestModal}
        handleSave={handleSaveSuggestModal}
      />
      <DeclineModal
        open={openDeclineModal}
        handleClose={handleCloseDeclineModal}
        handleSave={handleSaveDeclineModal}
      />
      <ApproveModal
        open={openApproveModal}
        handleClose={handleCloseApproveModal}
        handleSave={handleSaveApproveModal}
        bookingId={reservationData.id}
        onStatusUpdate={handleStatusUpdate}
      />
      <SpecialOfferModal
        open={openSpecialOfferModal}
        handleClose={handleCloseSpecialOfferModal}
        handleSave={handleSaveSpecialOfferModal}
      />
    </Stack>
  );
};

const GuestPaidTableItem: React.FC<GuestPaidItem> = ({ item, price }) => (
  <Box className="reservation-card-guest-paid-table-item B-14-20-500">
    {item}
    <p className="reservation-card-guest-paid-table-item B-14-20-500">
      {price}
    </p>
  </Box>
);

const TotalPrice = ({
  currency,
  total,
}: {
  currency: string;
  total: number;
}) => {
  const currencySymbol = getCurrencySymbol(currency.split(" ")[2] || currency);

  return (
    <div className="reservation-card-guest-paid-table-item B-18-18-600">
      {currency}
      <p className="reservation-card-guest-paid-table-price B-18-18-600">
        {`${currencySymbol} ${new Intl.NumberFormat("tr-TR", {
          minimumFractionDigits: 2,
        }).format(total)}`}
      </p>
    </div>
  );
};

const ReservationDetailsCard: React.FC<ReservationDetailsCardProps> = ({
  reservationData,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const [showDetails, setShowDetails] = useState(true);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const calendarContainerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [calendarReservations, setCalendarReservations] = useState<any[]>([]);
  const [calendarDate, setCalendarDate] = useState<
    [Dayjs | null, Dayjs | null]
  >([dayjs(reservationData.checkInDate), dayjs(reservationData.checkOutDate)]);
  const { getAccessTokenSilently } = useAuth0();

  interface UpdateReservationRequest {
    id: string;
    checkInDate?: string;
    checkOutDate?: string;
  }
  const API_BASE_URL = process.env.REACT_APP_API_URL;

  const fetchMonthReservations = async (startDate: string, endDate: string) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}/Reservation/GetAll`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken_NEW")}`,
        },
        body: JSON.stringify({
          pageIndex: 1,
          pageSize: 100,
          startDate: startDate,
          endDate: endDate,
          status: ["confirmed"],
          properties: [reservationData.propertyId],
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP hata! Durum: ${response.status}`);
      }

      const data = await response.json();
      if (data && data.items && Array.isArray(data.items)) {
        setCalendarReservations(data.items);
      } else {
        setCalendarReservations([]);
      }
    } catch (error) {
      console.error("Rezervasyon verisi çekme hatası:", error);
      setCalendarReservations([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleMonthChange = (newDate: Dayjs) => {
    const startDate = newDate.startOf("month").format("YYYY-MM-DD");
    const endDate = newDate.endOf("month").format("YYYY-MM-DD");

    fetchMonthReservations(startDate, endDate);
  };

  const guestPaidTableItems: GuestPaidItem[] =
    reservationData?.services?.map((service: any) => ({
      item: service.name,
      price: `${getCurrencySymbol(
        reservationData.currency
      )} ${service.totalPrice.toFixed(2)}`,
    })) || [];

  const bookingDetails = [
    {
      item1: t("inboxpage.guests"),
      item2: `${reservationData.adults} ${t("inboxpage.adults")}${reservationData.children
        ? `, ${reservationData.children} ${t("inboxpage.children")}`
        : ""
        }${reservationData.infants
          ? `, ${reservationData.infants} ${t("inboxpage.infants")}`
          : ""
        }`,
      isEditable: false,
    },
    {
      item1: t("inboxpage.checkin"),
      item2: moment(reservationData.checkInDate).format("MM/DD/YYYY HH:mm"),
      isEditable: true,
      onSave: async (newDate: string) => {
        try {
          await updateReservation({
            id: reservationData.id,
            checkInDate: moment(newDate, "MM/DD/YYYY HH:mm").format(),
          });
        } catch (error) {
          console.error("Check-in tarihi güncellenirken hata oluştu:", error);
        }
      },
    },
    {
      item1: t("inboxpage.checkout"),
      item2: moment(reservationData.checkOutDate).format("MM/DD/YYYY HH:mm"),
      isEditable: true,
      onSave: async (newDate: string) => {
        try {
          await updateReservation({
            id: reservationData.id,
            checkOutDate: moment(newDate, "MM/DD/YYYY HH:mm").format(),
          });
        } catch (error) {
          console.error("Check-out tarihi güncellenirken hata oluştu:", error);
        }
      },
    },
    {
      item1: t("inboxpage.bookingdate"),
      item2: moment(reservationData.createdAt).format("MM/DD/YYYY HH:mm"),
      isEditable: false,
    },
    {
      item1: t("inboxpage.confirmationcode"),
      item2: reservationData.otaReservationCode || "-",
      isEditable: false,
    },
  ];

  const handleDayClick = (event: MouseEvent, date: Dayjs) => {
    console.log(date);

    const target = event.currentTarget as HTMLElement;
    if (
      date.isBetween(
        dayjs(reservationData.checkInDate),
        dayjs(reservationData.checkOutDate),
        null,
        "[]"
      )
    ) {
      setAnchorEl(target);
      setOpenModal(true);
    }
  };

  const shouldDisableDate = (date: any) => {
    return date.date() === 0 || date.date() === 0;
  };
  const reservationBodyData = {
    confirm: reservationData.status,
    fullName: reservationData.customerNameSurname,
    propertyImageUrl: reservationData.propertyImageUrl,
    phone: reservationData.phone,
    provider: reservationData.otaName,
    propertyUrl: reservationData.propertyUrl,
    details: [
      `${reservationData.propertyName} : ${reservationData.roomName}`,
      `${moment(reservationData.checkInDate).format("MM/DD")} – ${moment(
        reservationData.checkOutDate
      ).format("MM/DD")} (${reservationData.days} ${t("inboxpage.nights")})`,
      `${reservationData.adults +
      reservationData.children +
      reservationData.infants
      } ${t("inboxpage.guests")} · ${getCurrencySymbol(
        reservationData.currency
      )} ${reservationData.amount}`,
    ],
  };

  interface UpdateReservationRequest {
    id: string;
    checkInDate?: string;
    checkOutDate?: string;
  }

  const updateReservation = async (data: UpdateReservationRequest) => {
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await fetch(`${API_BASE_URL}/Reservation/Update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          reservation: {
            id: data.id,
            checkInDate: data.checkInDate,
            checkOutDate: data.checkOutDate,
          },
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      console.error("Rezervasyon güncelleme hatası:", error);
      throw error;
    }
  };

  const options = [
    {
      value: 1,
      label: "Res. HMFWNNCWTN",
      subLabel: "08/17/2024 - 08/23/2024",
    },
    {
      value: 2,
      label: "Res. ZKFLFNCWOB",
      subLabel: "06/08/2023 - 07/12/2023",
    },
    {
      value: 3,
      label: "Res. LSJLFNCNWE",
      subLabel: "07/18/2022 - 07/25/2022",
    },
  ];

  useEffect(() => {
    const applyStyles = () => {
      const calendarDays = document.querySelectorAll(".MuiPickersDay-root");
      const checkInDate = dayjs(reservationData.checkInDate);
      const checkOutDate = dayjs(reservationData.checkOutDate);

      calendarDays.forEach((day) => {
        const dayElement = day as HTMLElement;
        const timestamp = dayElement.getAttribute("data-timestamp");

        if (timestamp) {
          const dayDate = dayjs(Number(timestamp));

          dayElement.style.backgroundColor = "";
          dayElement.style.background = "";
          dayElement.classList.remove("Mui-selected");

          if (
            dayDate.isSame(checkInDate, "day") ||
            dayDate.isSame(checkOutDate, "day")
          ) {
            dayElement.style.backgroundColor = "#0065E0";
            dayElement.style.color = "#FFFFFF";
            dayElement.style.borderRadius = "50%";
          } else if (dayDate.isBetween(checkInDate, checkOutDate, "day")) {
            dayElement.style.backgroundColor = "rgba(0, 101, 224, 0.08)";
            dayElement.style.borderRadius = "0";
          }

          if (Array.isArray(calendarReservations)) {
            calendarReservations.forEach((reservation) => {
              const resCheckIn = dayjs(reservation.checkInDate);
              const resCheckOut = dayjs(reservation.checkOutDate);

              if (
                dayDate.isBetween(resCheckIn, resCheckOut, "day", "[]") &&
                !dayDate.isBetween(checkInDate, checkOutDate, "day", "[]")
              ) {
                if (
                  dayDate.isSame(resCheckIn, "day") ||
                  dayDate.isSame(resCheckOut, "day")
                ) {
                  dayElement.style.background = "#0065E0";
                  dayElement.style.color = "#FFFFFF";
                } else {
                  dayElement.style.backgroundColor = "rgba(0, 101, 224, 0.08)";
                }
              }
            });
          }

          if (dayDate.isSame(dayjs(), "day")) {
            dayElement.classList.add("today-highlight");
          }

          if (
            dayDate.isBetween(checkInDate, checkOutDate, "day", "[]") ||
            calendarReservations.some((reservation) =>
              dayDate.isBetween(
                dayjs(reservation.checkInDate),
                dayjs(reservation.checkOutDate),
                "day",
                "[]"
              )
            )
          ) {
            dayElement.addEventListener("click", (event) =>
              handleDayClick(event as MouseEvent, dayDate)
            );
          }
        }
      });
    };

    const observer = new MutationObserver(applyStyles);

    if (calendarContainerRef.current) {
      observer.observe(calendarContainerRef.current, {
        childList: true,
        subtree: true,
      });

      applyStyles();
    }

    return () => {
      observer.disconnect();
    };
  }, [
    reservationData.checkInDate,
    reservationData.checkOutDate,
    calendarReservations,
    handleDayClick,
  ]);

  const handleClose = () => {
    setAnchorEl(null);
    setOpenModal(false);
  };

  useEffect(() => {
    const applyStyles = () => {
      const calendarDays = document.querySelectorAll(".MuiPickersDay-root");
      const checkInDate = dayjs(reservationData.checkInDate);
      const checkOutDate = dayjs(reservationData.checkOutDate);

      calendarDays.forEach((day) => {
        const dayElement = day as HTMLElement;
        const timestamp = dayElement.getAttribute("data-timestamp");

        if (timestamp) {
          const dayDate = dayjs(Number(timestamp));

          if (dayDate.date() === 28) {
            dayElement.classList.add("dot-bottom-left");

            dayElement.addEventListener("mouseenter", () => {
              const tooltip = document.querySelector(".tooltip") as HTMLElement;
              if (tooltip) {
                tooltip.style.display = "flex";

                const dayRect = dayElement.getBoundingClientRect();
                const tooltipRect = tooltip.getBoundingClientRect();
                const viewportWidth = window.innerWidth;

                let left = dayRect.left;
                let top = dayRect.bottom + window.scrollY;

                if (left + tooltipRect.width > viewportWidth) {
                  left = viewportWidth - tooltipRect.width - 120;
                }

                tooltip.style.left = `${left + 10}px`;
                tooltip.style.top = `${top - 75}px`;
              }
            });

            dayElement.addEventListener("mouseleave", () => {
              const tooltip = document.querySelector(".tooltip") as HTMLElement;
              if (tooltip) {
                tooltip.style.display = "none";
              }
            });
          }

          dayElement.classList.remove(
            "half-selected-right",
            "half-selected-left",
            "Mui-selected"
          );

          if (dayDate.isBetween(checkInDate, checkOutDate, "day", "[]")) {
            dayElement.classList.add("Mui-selected");
          }

          if (dayDate.isSame(checkInDate, "day")) {
            dayElement.classList.remove("Mui-selected");
            dayElement.classList.add("half-selected-right");
          } else if (dayDate.isSame(checkOutDate, "day")) {
            dayElement.classList.remove("Mui-selected");
            dayElement.classList.add("half-selected-left");
          }

          if (dayDate.isSame(dayjs(), "day")) {
            dayElement.classList.add("today-highlight");
          }
        }
      });
    };

    const observer = new MutationObserver(applyStyles);

    if (calendarContainerRef.current) {
      observer.observe(calendarContainerRef.current, {
        childList: true,
        subtree: true,
      });

      applyStyles();
    }

    return () => {
      observer.disconnect();
    };
  }, [reservationData.checkInDate, reservationData.checkOutDate]);

  return (
    <Stack
      width="100%"
      minWidth={isMobile ? "100%" : "300px"}
      maxWidth={isMobile ? "100%" : "340px"}
      height={"100%"}
      border="none"
      borderLeft={isMobile ? "none" : "1px solid var(--stroke-colors, #ebebeb)"}
      className="reservation-details-card"
    >
      {!isMobile && (
        <Box className="reservation-card-header">
          <Stack gap={"2px"}>
            <p className="reservation-card-header-title B-16-24-600">
              {t("inboxpage.reservationdetails")}
            </p>

            <Select
              open={open}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              defaultValue={1}
              variant="standard"
              disableUnderline
              sx={{
                minWidth: "100px",
                backgroundColor: "transparent",
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "20px",
                color: "#181C32",
                "& .MuiSelect-icon": {
                  color: "#3F4254",
                },
                "& .MuiSelect-select": {
                  paddingLeft: 0,
                  paddingRight: 0,
                  display: "flex",
                  alignItems: "center",
                  paddingBottom: "6px",
                  backgroundColor: "transparent",
                },
                "& .MuiInputBase-input": {
                  paddingBottom: 0,
                },
                "& .MuiInput-underline:before, & .MuiInput-underline:after": {
                  display: "none",
                },
              }}
              IconComponent={() => (
                <img
                  src={ArrowDown}
                  alt="arrow-down"
                  style={{
                    transition: "transform 0.3s",
                    transform: open ? "rotate(180deg)" : "rotate(0deg)",
                    cursor: "pointer",
                  }}
                  onClick={() => setOpen(!open)}
                />
              )}
              MenuProps={{
                PaperProps: {
                  sx: {
                    mt: 1,
                    width: "270px",
                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.3)",
                    borderRadius: "8px",
                    overflow: "visible",
                    padding: "12px",
                    alignItems: "flex-start",
                    gap: "5px",
                    "& .MuiMenuItem-root": {
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "20px",
                      color: "#181C32",
                      padding: "7px 10px",
                      borderRadius: "10px",
                    },
                    "& .MuiMenuItem-root.Mui-selected": {
                      backgroundColor: "rgba(0, 101, 224, 0.08)",
                    },
                    "& .MuiMenuItem-root:last-child": {
                      borderBottom: "none",
                    },
                  },
                },
              }}
              renderValue={(selectedValue) => {
                const selectedOption = options.find(
                  (option) => option.value === selectedValue
                );
                return selectedOption ? selectedOption.label : "";
              }}
            >
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <Stack alignItems="flex-start" gap="2px">
                    <span
                      className="B-14-20-500"
                      style={{ color: "var(--Gray-500, #667085)" }}
                    >
                      {option.label}
                    </span>
                    {option.subLabel && (
                      <span
                        className="B-14-20-500"
                        style={{ color: "var(--Gray-900, #101828)" }}
                      >
                        {option.subLabel}
                      </span>
                    )}
                  </Stack>
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </Box>
      )}
      {/* // TODO - Takvim */}
      <Stack
        alignItems={"center"}
        flexDirection={"row"}
        justifyContent={"center"}
        width={"100%"}
        padding={0}
        ref={calendarContainerRef}
        position="relative"
      >
        {isLoading && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 1000,
            }}
          >
            <style>
              {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
            </style>
            <div
              style={{
                width: "30px",
                height: "30px",
                border: "3px solid #f3f3f3",
                borderTop: "3px solid #0065E0",
                borderRadius: "50%",
                animation: "spin 1s linear infinite",
              }}
            />
          </div>
        )}

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer
            components={["DateRangeCalendar"]}
            sx={{
              width: "100%",
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                display: "none !important",
                width: "0 !important",
              },
              "-ms-overflow-style": "none !important",
              scrollbarWidth: "none !important",
              "& .MuiDateRangeCalendar-root": {
                width: "100%",
              },
            }}
          >
            <DateRangeCalendar
              value={calendarDate}
              onChange={(newValue) => {
                if (newValue && newValue[0] && newValue[1]) {
                  setCalendarDate(newValue as [Dayjs, Dayjs]);
                }
              }}
              onMonthChange={handleMonthChange}
              shouldDisableDate={shouldDisableDate}
              readOnly={true}
              calendars={1}
              showDaysOutsideCurrentMonth
              sx={{
                width: "100%",
                minHeight: "385px",
                "& .MuiPickersCalendarHeader-root": {
                  marginBottom: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "0 16px",
                  marginLeft: "10px",
                  "& .MuiPickersArrowSwitcher-button": {
                    color: "#0065E0",
                    "&:hover": {
                      backgroundColor: "rgba(0, 101, 224, 0.04)",
                    },
                    "&:disabled": {
                      opacity: 0.5,
                    },
                  },
                  "& .MuiPickersCalendarHeader-label": {
                    color: "#101828",
                    fontWeight: 600,
                  },
                },
                "& .MuiDayCalendar-header": {
                  marginLeft: "20px",
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "0 16px",
                  "& .MuiTypography-root": {
                    color: "#667085",
                    fontWeight: 500,
                  },
                },
                "& .MuiDayCalendar-monthContainer": {
                  overflow: "visible",
                },
                "& .MuiPickersDay-root": {
                  fontWeight: 500,
                  color: "#101828",
                  "&:hover": {
                    backgroundColor: "rgba(0, 101, 224, 0.04)",
                  },
                  "&.Mui-selected": {
                    backgroundColor: "rgba(0, 101, 224, 0.08)",
                    color: "#101828",
                  },
                },
                "& .MuiPickersSlideTransition-root": {
                  minHeight: "240px",
                  overflow: "visible",
                },
                overflowY: "auto",
                "&::-webkit-scrollbar": { display: "none" },
                msOverflowStyle: "none",
                scrollbarWidth: "none",
              }}
            />
          </DemoContainer>
        </LocalizationProvider>
      </Stack>
      <Popover
        open={openModal}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPopover-paper": {
            borderRadius: "12px !important",
            width: "312px",
            padding: "12px 16px",
            alignItems: "flex-start",
            background: "#F3F3F3",
          },
          "& .MuiPaper-root": {
            borderRadius: "12px !important",
            background: "#F3F3F3",
          },
        }}
      >
        <CalendarReservationDetailsModal reservationData={reservationData} />
      </Popover>
      {/* // TODO - Customer Bilgileri alanı */}
      <ReservationCardBody
        {...reservationBodyData}
        reservationData={reservationData}
      />{" "}
      <Box
        padding={isMobile ? "20px 0" : "20px"}
        className="reservation-card-booking-details"
        onClick={() => setShowDetails(!showDetails)}
      >
        <p className="B-18-28-600">{t("inboxpage.bookingdetails")}</p>
        <img
          src={ArrowDown}
          alt="arrow-down"
          style={{
            transition: "transform 0.3s",
            transform: showDetails ? "rotate(180deg)" : "none",
          }}
        />
      </Box>
      {showDetails && (
        <Stack
          alignItems="flex-start"
          alignSelf="stretch"
          padding={isMobile ? 0 : "0 20px"}
        >
          {bookingDetails.map((detail, index) => (
            <BookingDetailsListItem
              key={`booking-${index}`}
              {...detail}
              index={index}
              isEditable={detail.isEditable}
              onSave={detail.onSave}
            />
          ))}
          <Box className="reservation-card-guest-paid">
            <p className="reservation-card-guest-paid-title B-18-28-600">
              {t("inboxpage.guestpaid")}
            </p>
            <Box className="reservation-card-guest-paid-table">
              {guestPaidTableItems.map((item: GuestPaidItem, index: number) => (
                <GuestPaidTableItem key={index} {...item} />
              ))}
              <TotalPrice
                currency={
                  `${t("inboxpage.total")}` +
                  " ( " +
                  reservationData.currency +
                  " )"
                }
                total={reservationData.amount}
              />
            </Box>
          </Box>
        </Stack>
      )}
      {/* ======================== */}
    </Stack>
  );
};

export default ReservationDetailsCard;
