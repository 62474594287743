import axios from "axios";
import { BASE_API_URL } from ".";

const API_BASE_URL = BASE_API_URL

export interface GetChannelsParams {
  accessToken: string;
}

export const getChannelApi = async ({ accessToken, }: GetChannelsParams) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/Channel/Get`,
      {
        headers: {
          audience: API_BASE_URL,
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return response
  } catch (error: any) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch the message."
    );
  };
}

