import { Button, Chip, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowDown from "../../../../assets/images/icons/arrow-down-messages.svg";
import CustomModalMobile from "../../../../components/shared-components/CustomModalMobile";
import SelectRoleModal from "../../onboardingTwo/step3/SelectRoleModal";
import CustomButton from "../../../../components/shared-components/CustomButton";
import ListingModal from "../../onboardingTwo/step2/ListingModal";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";


interface MobileDetailsModalProps {
  name: string;
  email: string;
  status: string;
  role: string;
  tags: string[];
  listingResponsibleIds: string[];
  displayedOptions: string[];
  selectedRole: string | null;
  setSelectedRole: (role: string | null) => void;
  handleSave: () => void;
  handleStatusChange: () => void;
  handleAddProperty: () => void;
  search: string;
  handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  selectAll: boolean;
  handleSelectAll: () => void;
  filteredListings: any;
  handleToggle: (id: number) => void;
  selectedListings: number[];
  handleRemove: (id: number) => void;
  dummyListings: any;
}

interface Role {
  id: string;
  name: string;
  description: string;
  providerCode: string;
}
function MobileDetailsModal({
  name,
  email,
  status,
  tags,
  listingResponsibleIds,
  displayedOptions,
  selectedRole,
  setSelectedRole,
  handleSave,
  handleStatusChange,
  handleAddProperty,
  selectedListings,
}: MobileDetailsModalProps) {
  const [isMobileRoleModalOpen, setIsMobileRoleModalOpen] =
    React.useState(false);
  const [isMobileListingModalOpen, setIsMobileListingModalOpen] =
    React.useState(false);
  const [roles, setRoles] = useState<Role[]>([]);
  const { getAccessTokenSilently } = useAuth0();
  const API_BASE_URL = process.env.REACT_APP_API_URL;
  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        const response = await axios.get(
          `${API_BASE_URL}/Role/List`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setRoles(response.data);
      } catch (error) {
        console.error("Roles API error:", error);
      }
    };

    fetchRoles();
  }, [getAccessTokenSilently]);


  const handleRoleSelection = (role: string) => {
    setSelectedRole(role);
  };

  const buttonText = status === "Invited" ? "Undo" : "Invite";

  return (
    <Stack spacing={3}>
      <Stack
        spacing={2}
        sx={{
          height: "100px",
          padding: "var(--Spacing-space-10, 24px) 0px",
          borderBottom: "6px solid var(--stroke-colors, #EBEBEB)",
        }}
      >
        <Stack justifyContent={"space-between"} direction={"row"}>
          <Stack
            spacing={"4px"}
            alignItems={"flex-start"}
            justifyContent={"center"}
          >
            <p className="B-18-18-600">{name}</p>
            <p className="B-14-20-500" style={{ color: "#7E8299" }}>
              {email}
            </p>
          </Stack>
          <Chip
            label={status}
            className="chip"
            style={{
              backgroundColor: status === "Invited" ? "#E8FFF3" : "#FFF5F8",
              color: status === "Invited" ? "#47BE7D" : "#F1416C",
            }}
          />
        </Stack>
      </Stack>
      <Stack
        spacing={2}
        pb={3}
        sx={{
          borderBottom: "6px solid var(--stroke-colors, #EBEBEB)",
        }}
      >
        <p className="H-20-20-600">Role</p>
        <Button
          onClick={() => setIsMobileRoleModalOpen(true)}
          sx={{
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: "20px",
            padding: "7px 10px",
            borderRadius: "6px",
            border: "1px solid #E1E3EA",
            color: "var(--Gray-500, #667085)",
            minWidth: "100px",
            width: "100%",
            height: "38px",
            justifyContent: "space-between",
          }}
          endIcon={<img src={ArrowDown} alt="arrow-down" />}
        >
          {selectedRole || "Select role"}
        </Button>
      </Stack>
      <Stack
        spacing={2}
        pb={3}
        sx={{
          borderBottom: "6px solid var(--stroke-colors, #EBEBEB)",
        }}
      >
        <p className="H-20-20-600">Tags</p>
        <Stack
          sx={{
            display: "flex",
            alignItems: !tags[0] ? "flex-start" : "center",
            width: "100%",
            height: "100%",
            paddingY: "12px",
            alignContent: "center",
            gap: "4px",
            alignSelf: "stretch",
            flexWrap: "wrap",
          }}
          direction={"row"}
          spacing={"4px"}
        >
          {tags.map((tag: string) => (
            <Chip
              label={tag}
              className="tag"
              style={{
                color: "#FFFFFF",
                backgroundColor: "#3E97FF",
              }}
            />
          ))}
        </Stack>
      </Stack>
      <Stack
        spacing={2}
        pb={3}
        sx={{
          borderBottom: "6px solid var(--stroke-colors, #EBEBEB)",
        }}
      >
        <p className="H-20-20-600">Listing responsible</p>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          py={"4px"}
          alignItems={"center"}
        >
          <p className="B-16-24-500" style={{ marginTop: "4px" }}>
            {listingResponsibleIds.length} Listings
          </p>
          <button
            style={{
              fontFamily: "Inter",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              margin: "0px",
            }}
            className="edit-button B-12-12-600"
            onClick={() => setIsMobileListingModalOpen(true)}
          >
            Add/Remove
          </button>
        </Stack>
      </Stack>
      <Stack width={"100%"} pt={"4px"}>
        <CustomButton
          backgroundColor="#0065E0"
          color="#FFFFFF"
          onClick={() => {
            handleStatusChange();
          }}
          buttonText={buttonText}
          marginTop={0}
          buttonWidth="100%"
        />
      </Stack>
      <CustomModalMobile
        open={isMobileRoleModalOpen}
        handleClose={() => setIsMobileRoleModalOpen(false)}
        title="Role"
        showFooter={true}
        handleSave={() => {
          handleSave();
          setIsMobileRoleModalOpen(false);
        }}
      >
        <SelectRoleModal
          displayedOptions={displayedOptions}
          selectedRole={selectedRole}
          setSelectedRole={handleRoleSelection}
          roles={roles}
        />
      </CustomModalMobile>
      <CustomModalMobile
        open={isMobileListingModalOpen}
        handleClose={() => setIsMobileListingModalOpen(false)}
        title="Listing Responsibility"
        showFooter={false}
        handleSave={() => {
          handleAddProperty();
          setIsMobileListingModalOpen(false);
        }}
        height="100%"
      >
        <ListingModal
          handleAddProperty={handleAddProperty}
          selectedListings={selectedListings}
        />
      </CustomModalMobile>
    </Stack>
  );
}

export default MobileDetailsModal;
