import DetailsInputArea from "../../../../../../../../components/shared-components/DetailsInputArea";
import CheckIn from "../forms/CheckIn";
import CheckInContactPerson from "../forms/CheckInContactPerson";
import CheckInInstructions from "../forms/CheckInInstructions";
import CheckOut from "../forms/CheckOut";
import ClosestAirports from "../forms/ClosestAirports";
import Directions from "../forms/Directions";
import InteractionPreferences from "../forms/InteractionPreferences";
import KeyCollectionPoint from "../forms/KeyCollectionPoint";
import { useTranslation } from "react-i18next";

function BeforeBooking() {
  const { t } = useTranslation();
  const data = [
    {
      title: t("detailsPages.policies.checkIn"),
      subtitle: t("detailsPages.pricing.notSet"),
      component: <CheckIn />,
      isSelect: false,
    },
    {
      title: t("detailsPages.policies.checkInContactPerson"),
      subtitle: t("detailsPages.pricing.notSet"),
      component: <CheckInContactPerson />,
      isSelect: false,
    },
    {
      title: t("detailsPages.policies.checkOut"),
      subtitle: t("detailsPages.pricing.notSet"),
      component: <CheckOut />,
      isSelect: false,
    },
    {
      title: t("detailsPages.policies.keyCollectionPoint"),
      subtitle: t("detailsPages.pricing.notSet"),
      component: <KeyCollectionPoint />,
      isSelect: true,
    },
    {
      title: t("detailsPages.policies.interactionPreferences"),
      subtitle: t("detailsPages.pricing.notSet"),
      component: <InteractionPreferences />,
      isSelect: true,
      width: "536",
    },
    {
      title: t("detailsPages.policies.checkInInstructions"),
      subtitle: t("detailsPages.pricing.notSet"),
      component: <CheckInInstructions />,
      isSelect: false,
    },
    {
      title: t("detailsPages.policies.closestAirports"),
      subtitle: t("detailsPages.pricing.notSet"),
      component: <ClosestAirports />,
      isSelect: false,
    },
    {
      title: t("detailsPages.policies.directions"),
      subtitle: t("detailsPages.pricing.notSet"),
      component: <Directions />,
      isSelect: false,
    },
  ];
  return (
    <>
      <DetailsInputArea
        title={t("detailsPages.policies.beforeBooking")}
        data={data}
        onClick={() => { }}
        id={"before-booking"}
      />
    </>
  );
}

export default BeforeBooking;
