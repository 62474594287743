import React, { useEffect, useRef, useState, } from "react";
import {
  Box,
  Chip,
  CircularProgress,
  IconButton,
  Paper,
  Stack,
} from "@mui/material";
import { MessageHeader } from "../../../../types";
import { useTranslation } from "react-i18next";
import IncomeMessages from "./components/IncomeMessages";
import SearchIcon from "../../../../assets/images/icons/searchColored.svg";
import { ScrollableTabsStack } from "./components/ScrollableTabsStack";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store/store";
import { VariableSizeList } from "react-window";
import { useAuth0 } from "@auth0/auth0-react";
import {
  setSelectedMessage,
  fetchMessages,
  fetchSingleMessage,
  initializeSignalR,
  updateMessageReadStatus,
  fetchReservationDetails,
  clearReservationState,
  setFilterType,
  setMobileOpen,
} from "../../../../store/slices/chatSlice";
import SearchBar from "./components/SearchBar";
import { FilterType } from "../../../../api/messageApi";
import LoadingDots from "../../../../components/shared-components/loadings/LoadingDots";
import FilterDrawer from "./components/FilterDrawer";
import { debounce } from "../../../../utils/helpers";

export interface AssigneeOption {
  label: string;
  value: string;
}
const MessagesCard: React.FC = () => {
  const dispatch: AppDispatch = useDispatch<AppDispatch>();

  const {
    messageId,
    messageMetaData,
    allMessages: messages,
    messagesLoading,
    filterType,
    connectionStarted,
    tabMessageCounts,
    messagesCache
  } = useSelector((state: RootState) => state.chat);

  const listRef = useRef<VariableSizeList<any>>(null);
  const { t } = useTranslation();
  const [selectedChip, setSelectedChip] = useState<string>("All");
  const { getAccessTokenSilently } = useAuth0();
  const PAGE_SIZE = 10;
  // const [tagOptions] = useState<AssigneeOption[]>([
  //   { label: "Luxury", value: "luxury" },
  //   { label: "Budget", value: "budget" },
  //   { label: "Family", value: "family" },
  // ]);

  const currentPage = useRef<number>(2)

  const fetchOnFirstRender = async () => {
    const accessToken = await getAccessTokenSilently();
    await dispatch(
      fetchMessages({
        page: 1,
        limit: 50,
        accessToken,
        filterType
      })
    );
  }

  useEffect(() => {
    if (filterType === FilterType.FULL_FILTER) {
      setSelectedChip("All")
    }
  }, [filterType])


  useEffect(() => {
    const initializeData = async () => {
      const accessToken = await getAccessTokenSilently();
      if (!connectionStarted) {
        await dispatch(initializeSignalR(accessToken));
      }
    };
    initializeData();
    fetchOnFirstRender();
  }, []);

  const scrollToTop = () => {
    const messagesList = document.querySelector(".messages-income-area");
    if (messagesList) {
      messagesList.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const handleChipClick = async (label: string) => {
    if (label === selectedChip) return '';
    try {
      setSelectedChip(label);
      currentPage.current = 1;

      const accessToken = await getAccessTokenSilently();


      let newFilterType: FilterType;
      switch (label) {
        case "Unread":
          newFilterType = FilterType.UNREAD;
          break;
        case "Not Replied":
          newFilterType = FilterType.NOT_REPLIED;
          break;
        case "Action Needed":
          newFilterType = FilterType.ACTION_NEED;
          break;
        case "All":
          newFilterType = FilterType.ALL;
          break;
        default:
          newFilterType = FilterType.NONE; // Default to NONE or another fallback
          break;
      }

      dispatch(
        fetchMessages({
          page: 1,
          limit: 50,
          accessToken,
          filterType: newFilterType
        })
      );

    } catch (error) {
      console.error("Error handling chip click:", error);
    }
  }

  const handleMessageClick = async (message: MessageHeader) => {
    try {
      dispatch(setMobileOpen(true))
      if (message.id === messageId) return ''
      dispatch(setSelectedMessage(message))

      const accessToken = await getAccessTokenSilently();

      if (message.bookingId) {
        dispatch(fetchReservationDetails({ accessToken, bookingId: message.bookingId }))
      } else {
        dispatch(clearReservationState())
      }
      // PERF: DONE
      dispatch(
        fetchSingleMessage({
          messageId: message.id,
          page: 1,
          limit: PAGE_SIZE,
          accessToken,
        })
      );

      if (!message.isRead) {
        dispatch(updateMessageReadStatus({
          messageId: message.id,
          accessToken,
        }))
      }

      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set("inboxId", message.id);
    } catch (error) {
      console.error("Error handling message click:", error);
    }
  };

  // PERF:  Scroll bar bug fixed, now its working as expected
  const handleScroll = async ({ scrollOffset }: any) => {
    if (!messagesLoading) {
      const itemSize = 50;
      const height = 600;
      const itemCount = messages.length;
      const endIndex = Math.min(
        itemCount - 1,
        Math.floor((scrollOffset + height) / itemSize) - 1
      );

      if (
        messages &&
        endIndex >= itemCount - 5 &&
        currentPage.current < ((messageMetaData && messageMetaData.totalPageCount) ?? 0)
      ) {
        const accessToken = await getAccessTokenSilently();
        dispatch(
          fetchMessages({
            page: currentPage.current,
            limit: 50,
            accessToken,
            filterType
          })
        )
        currentPage.current = currentPage.current + 1
      }
    }
  }
  const returnCorrectFilterCount = (filterTitle: string): number => {
    switch (filterTitle) {
      case "Unread":
        return tabMessageCounts.unread;
      case "Action Needed":
        return tabMessageCounts.actionNeeded
      case "Not Replied":
        return tabMessageCounts.notReplied
      default:
        return 0
    }
  }

  const debouncedHandleScroll = debounce(async (args) => {
    await handleScroll(args);
  }, 1000);


  const tabFilters = ["All", "Not Replied", "Action Needed", "Unread"]


  return (
    <Stack className="messages-card">
      <Stack className="card-container">
        <Stack className="flex-box">
          {filterType === FilterType.SEARCH ? (
            <SearchBar />
          ) : (
            <>
              <p className="messages-card-header H-24-32-600">
                {t("inboxpage.allmessages")}
              </p>
              <Box className="messages-card-settings-box" >
                <IconButton onClick={() => dispatch(setFilterType(FilterType.SEARCH))} className="icon-button">
                  <img src={SearchIcon} alt="search" />
                </IconButton>
                <FilterDrawer />
              </Box>
            </>
          )}
        </Stack>

        <Stack className="messages-card-filter-box" >
          {/* FILTERS*/}
          <ScrollableTabsStack>
            {tabFilters.map((label, index) => (
              <Chip
                key={index}
                clickable
                onClick={() => handleChipClick(label)}
                className={`custom-chip ${selectedChip === label ? "active" : ""}`}
                label={
                  <Box className="custom-chip-box">
                    <span>
                      {label}
                    </span>
                    {label !== "All" &&
                      <Box className={`chip-count ${selectedChip === label ? "active" : ""}`} >
                        <span>
                          {returnCorrectFilterCount(label)}
                        </span>
                      </Box>
                    }
                  </Box>
                }
              />
            ))}
          </ScrollableTabsStack>
        </Stack>
      </Stack>
      {messagesLoading && messages.length === 0 ?
        <LoadingDots />
        :
        messages.length === 0 ? (
          <Box className="messages-card-message-not-found-box">
            <p className="B-14-14-600">
              Couldn't find any message
            </p>
          </Box>
        ) : (
          <Stack
            alignItems="flex-start"
            height="100%"
            width={"100%"}
            overflow="auto"
          >
            {/*@ts-ignore*/}
            <VariableSizeList
              ref={listRef as any}
              height={window.innerHeight}
              itemSize={() => 220}
              itemCount={messages.length}
              width="100%"
              className="messages-income-area"
              onScroll={debouncedHandleScroll}
              overscanCount={10}
            >
              {({ index, style }) => {
                const message = messages[index];
                let draftMessage = ""
                if (messagesCache[message.id]) {
                  draftMessage = messagesCache[message.id].chatInput
                }
                return (
                  <Paper
                    onClick={() => handleMessageClick(message)}
                    key={message.id}
                    elevation={0}
                    style={{
                      ...style,
                      backgroundColor: "transparent",
                      border: "none",
                      cursor: "pointer",
                      width: "100%",
                      //paddingBottom: "16px",
                    }}
                  >
                    {/* PERF: PROPS CLEANED */}
                    <IncomeMessages
                      message={message}
                      selectedMessage={messageId}
                      draftMessage={draftMessage}
                    />
                  </Paper>
                );
              }}
            </VariableSizeList>
            {messagesLoading &&
              <Box className="messages-card-loading-box">
                <CircularProgress
                  size={22}
                  thickness={4}
                  sx={{
                    color: "#0065E0",
                  }}
                />
              </Box>
            }
            {/* {TODO: handle Scroll to top showTopUpButton && ( */}
            <IconButton onClick={scrollToTop} className="messages-card-scroll-top-button" >
              <Box
                sx={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  lineHeight: "16px",
                }}
              >
                ↑
              </Box>
            </IconButton>
            {/*)}*/}
          </Stack>
        )
      }
    </Stack >
  );
};

export default React.memo(MessagesCard);
