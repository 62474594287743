import DetailsInputArea from "../../../../../../../../components/shared-components/DetailsInputArea";
import ListingStatus from "../../../../components/modals/ListingStatus";

interface Props {
  setSubMenu: React.Dispatch<React.SetStateAction<string>>;
}

function BookingRules(props: Props) {
  const { setSubMenu } = props;

  const data = [
    {
      title: "Booking rules",
      subtitle: "Not set",
      component: <ListingStatus showTitle={true} />,
      isSelect: false,
      subMenu: true,
      onClick: () => setSubMenu("booking"),
    },
  ];

  return (
    <>
      <DetailsInputArea
        title={"Policies and rules"}
        data={data}
        onClick={() => {}}
        id={"booking-rules"}
      />
    </>
  );
}

export default BookingRules;
