import React, { useState } from "react";
import HeaderTitle from "../detail-header/HeaderTitle";
import MainTextField from "../../../../../../components/core-components/inputs/TextField/MainTextField";
import { Box, Stack } from "@mui/material";
import MainSelectSelection from "../../../../../../components/core-components/inputs/Select/MainSelectSelection";
import { useTranslation } from "react-i18next";

function Floorspace() {
  const { t } = useTranslation();
  const [data, setData] = useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData(e.target.value);
  };

  return (
    <>
      {" "}
      <Stack spacing={2}>
        <HeaderTitle title={t("listingDetails.floorspaceDetails")} />
        <Stack spacing={3}>
          <Stack
            sx={{
              gap: 2,
              alignItems: "flex-end",
              justifyContent: "center",
              height: "100%",
            }}
            display="flex"
            flexDirection="row"
            spacing={3}
          >
            <Box
              sx={{
                gap: 2,
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
              flex={2}
            >
              <MainTextField
                title={t("listingDetails.floorspace")}
                onChange={handleChange}
                value={data}
                placeholder="100"
                type="number"
              />
            </Box>
            <Box flex={1}>
              <MainSelectSelection
                value=""
                onChange={() => { }}
                label={"M2"}
                options={[
                  {
                    value: "M2",
                    label: `m2`,
                  },
                  {
                    value: "No",
                    label: `No`,
                  },
                ]}
              />
            </Box>
          </Stack>{" "}
          <Stack
            sx={{
              gap: 2,
              alignItems: "flex-end",
              justifyContent: "center",
              height: "100%",
            }}
            display="flex"
            flexDirection="row"
            spacing={3}
          >
            <Box
              sx={{
                gap: 2,
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
              flex={2}
            >
              <MainTextField
                title={t("listingDetails.floorspace")}
                onChange={handleChange}
                value={data}
                placeholder="100"
                type="number"
              />
            </Box>
            <Box flex={1}>
              <MainSelectSelection
                value=""
                onChange={() => { }}
                label={"M2"}
                options={[
                  {
                    value: "M2",
                    label: `m2`,
                  },
                  {
                    value: "No",
                    label: `No`,
                  },
                ]}
              />
            </Box>
          </Stack>
        </Stack>
        <MainTextField
          title={t("listingDetails.floorsInTheBuilding")}
          onChange={handleChange}
          value={data}
          type="number"
          placeholder="100"
        />
      </Stack>
    </>
  );
}

export default Floorspace;
