interface Props {
  bgColor?: string;
  color?: string;
}
function IgnoreIcon(props: Props) {
  const { bgColor, color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
    >
      <rect
        x="0.5"
        y="0.5"
        width="37"
        height="37"
        rx="18.5"
        stroke={bgColor || ""}
        fill={bgColor ? "" : "#181C32"}
      />
      <path
        d="M20.1757 18.9992L23.1673 16.0326C23.3265 15.8712 23.415 15.6533 23.4134 15.4267C23.4119 15.2001 23.3203 14.9834 23.159 14.8242C22.9977 14.6651 22.7797 14.5766 22.5531 14.5781C22.3265 14.5797 22.1098 14.6712 21.9507 14.8326L19.0007 17.8242L16.0507 14.8909C15.8945 14.7357 15.6833 14.6486 15.4632 14.6486C15.243 14.6486 15.0318 14.7357 14.8757 14.8909C14.7976 14.9684 14.7356 15.0605 14.6933 15.1621C14.6509 15.2636 14.6292 15.3726 14.6292 15.4826C14.6292 15.5926 14.6509 15.7015 14.6933 15.803C14.7356 15.9046 14.7976 15.9968 14.8757 16.0742L17.8257 18.9992L14.834 21.9659C14.6749 22.1272 14.5863 22.3452 14.5879 22.5718C14.5895 22.7984 14.681 23.0151 14.8423 23.1742C15.0037 23.3334 15.2216 23.4219 15.4482 23.4203C15.6748 23.4188 15.8915 23.3272 16.0507 23.1659L19.0007 20.1742L21.9507 23.1076C22.1068 23.2628 22.318 23.3499 22.5382 23.3499C22.7583 23.3499 22.9695 23.2628 23.1257 23.1076C23.2038 23.0301 23.2658 22.9379 23.3081 22.8364C23.3504 22.7348 23.3722 22.6259 23.3722 22.5159C23.3722 22.4059 23.3504 22.297 23.3081 22.1954C23.2658 22.0939 23.2038 22.0017 23.1257 21.9242L20.1757 18.9992Z"
        fill={color || "white"}
      />
    </svg>
  );
}

export default IgnoreIcon;
