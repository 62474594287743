import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid, useTheme, useMediaQuery } from "@mui/material";
import { styled } from "@mui/system";

interface RentalOptionProps {
  active: boolean;
}

const RentalOption = styled(Box)<RentalOptionProps>(({ theme, active }) => ({
  color: active ? "#FFFFFF" : "#7E8299",
  fontFamily: "Inter",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "400",
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "48px",
  lineHeight: "22px",
  borderRadius: "6px",
  border: active ? "1px solid #0065E0" : "1px solid #E1E3EA",
  background: active ? "#0065E0" : "transparent",
  padding: theme.spacing(2),
  cursor: "pointer",

  "&:hover": {
    backgroundColor: active ? "#0057b7" : "#f0f0f0",
  },
  width: "100%",
  marginBottom: theme.spacing(2),
}));


const rentalOptions: any[] = [
  { value: "option1", text: "Option 1" },
  { value: "option2", text: "Option 2" },
  { value: "option3", text: "Option 3" },
  { value: "option4", text: "Option 4" },
  { value: "option5", text: "Option 5" },
];

function TypeOfRentals() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const [activeOption, setActiveOption] = useState<string | null>(null);

  const handleClick = (value: string) => {
    setActiveOption(value);
  };

  return (
    <Box
      sx={{
        maxWidth: "812px",
        width: "100%",
        padding: "0px !important",
        margin: "0px",
      }}
    >
      <p className="listing-details-layout-box-content-title">
        {t("listingDetails.typeofrental")}
      </p>
      <Grid
        sx={{
          width: "100%",
        }}
        mt={isMobile ? 5 : 3}
        container
        mb={isMobile ? 1 : 0}
      >
        {rentalOptions.map((option) => (
          <Grid item key={option.value} xs={12}>
            <RentalOption
              active={activeOption === option.value}
              onClick={() => handleClick(option.value)}
            >
              {option.text}
            </RentalOption>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default TypeOfRentals;
