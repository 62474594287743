import React from "react";
import { Stack } from "@mui/material";
import CustomCheckbox from "../../../../../../../../../components/core-components/inputs/Checkbox/CustomCheckbox";

interface ListingProps {
  listing: {
    image: string;
    title: string;
  };
}

function ListingRow({ listing }: ListingProps) {
  return (
    <Stack direction="row" sx={{ width: "100%", alignItems: "center" }}>
      <CustomCheckbox
        position="left"
        label={""}
        inputName="dateFilter"
        onChange={() => {}}
      />
      <Stack spacing={1} direction={"row"} alignItems="center">
        <img
          src={listing.image}
          alt="listing-img"
          style={{
            width: "40px",
            height: "40px",
            objectFit: "cover",
            borderRadius: "4px",
          }}
        />
        <p className="B-14-24-500" style={{ color: "#3F4254" }}>
          {listing.title}
        </p>
      </Stack>
    </Stack>
  );
}

export default ListingRow;
