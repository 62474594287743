import NumberModal from "../header/components/number-modal";
import { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";
import BooleanModal from "../header/components/boolean-modal";

interface Props {
  refetch?: () => void;
  modalState: {
    type: string;
    anchorEl: HTMLButtonElement | null;
    propertyId: string;
    ratePlanId: string;
    start: Dayjs;
    value: any;
  };
  handleModalClose: () => void;
}

function ModalsSection(props: Props) {
  const { refetch, modalState, handleModalClose } = props;
  const { t } = useTranslation();

  const modalInfo = (type: string | undefined) => {
    switch (type) {
      case "MinStay":
        return {
          title: t("calendarpage.minLengthOfStay"),
          titleFirst: t("calendarpage.selectDateRange"),
          descriptionFirst: t(
            "calendarpage.theNightOfTheToDateIsExcludedMinimumLengthOfStayCanBeSyncedUpTo3YearsInTheFuture"
          ),
          titleSecond: t("calendarpage.minLengthOfStay"),
          descriptionSecond: t("calendarpage.enterAValueBetween1And31"),
          buttonText: t("calendarpage.updateAndSyncLengthOfMinimumStay"),
          type: "number",
        };
      case "MaxStay":
        return {
          title: t("calendarpage.maxLengthOfStay"),
          titleFirst: t("calendarpage.selectDateRange"),
          descriptionFirst: t(
            "calendarpage.theNightOfTheToDateIsExcludedMaximumLengthOfStayCanBeSyncedUpTo3YearsInTheFuture"
          ),
          titleSecond: t("calendarpage.maxLengthOfStay"),
          descriptionSecond: t("calendarpage.enterAValueBetween1And31"),
          buttonText: t("calendarpage.updateAndSyncLengthOfMaximumStay"),
          type: "number",
        };
      case "MinStayArrival":
        return {
          title: t("calendarpage.minLengthOfStayArrival"),
          titleFirst: t("calendarpage.selectDateRange"),
          descriptionFirst: t(
            "calendarpage.theNightOfTheToDateIsIncludedMinimumLengthOfStayCanBeSyncedUpTo3YearsInTheFuture"
          ),
          titleSecond: t("calendarpage.minLengthOfStayArrival"),
          descriptionSecond: t("calendarpage.enterAValueBetween1And31"),
          buttonText: t("calendarpage.updateAndSyncLengthOfMinimumStayArrival"),
          type: "number",
        };
      case "MinStayThrough":
        return {
          title: t("calendarpage.minLengthOfStayThrough"),
          titleFirst: t("calendarpage.selectDateRange"),
          descriptionFirst: t(
            "calendarpage.theNightOfTheToDateIsIncludedMinimumLengthOfStayCanBeSyncedUpTo3YearsInTheFuture"
          ),
          titleSecond: t("calendarpage.minLengthOfStayThrough"),
          descriptionSecond: t("calendarpage.enterAValueBetween1And31"),
          buttonText: t("calendarpage.updateAndSyncLengthOfMinimumStayThrough"),
          type: "number",
        };
      case "MaxAvailability":
        return {
          title: t("calendarpage.maxAvailability"),
          titleFirst: t("calendarpage.selectDateRange"),
          descriptionFirst: t(
            "calendarpage.theNightOfTheToDateIsIncludedMaximumAvailabilityCanBeSyncedUpTo3YearsInTheFuture"
          ),
          titleSecond: t("calendarpage.maxAvailability"),
          descriptionSecond: t("calendarpage.enterAValueBetween1And31"),
          buttonText: t("calendarpage.updateAndSyncMaxAvailability"),
          type: "number",
        };
      case "AvailabilityOffset":
        return {
          title: t("calendarpage.availabilityOffset"),
          titleFirst: t("calendarpage.selectDateRange"),
          descriptionFirst: t(
            "calendarpage.theNightOfTheToDateIsIncludedAvailabilityOffsetCanBeSyncedUpTo3YearsInTheFuture"
          ),
          titleSecond: t("calendarpage.availabilityOffset"),
          descriptionSecond: t("calendarpage.enterAValueBetween1And31"),
          buttonText: t("calendarpage.updateAndSyncAvailabilityOffset"),
          type: "number",
        };
      case "PersonCount":
        return {
          title: t("calendarpage.personCount"),
          titleFirst: t("calendarpage.selectDateRange"),
          descriptionFirst: t(
            "calendarpage.theNightOfTheToDateIsIncludedPersonCountCanBeSyncedUpTo3YearsInTheFuture"
          ),
          titleSecond: t("calendarpage.personCount"),
          descriptionSecond: t("calendarpage.enterAValueBetween1And31"),
          buttonText: t("calendarpage.updateAndSyncPersonCount"),
          type: "number",
        };
      case "ClosedToArrival":
        return {
          title: t("calendarpage.closedForArrival"),
          titleFirst: t("calendarpage.selectDateRange"),
          descriptionFirst: t("calendarpage.theNightOfTheToDateIsExcluded"),
          buttonText: t("calendarpage.updateAndSyncClosedForArrival"),
          switchText: t("calendarpage.closedForArrival"),
          type: "boolean",
        };
      case "ClosedToDeparture":
        return {
          title: t("calendarpage.closedForDeparture"),
          titleFirst: t("calendarpage.selectDateRange"),
          descriptionFirst: t("calendarpage.theNightOfTheToDateIsExcluded"),
          buttonText: t("calendarpage.updateAndSyncClosedForDeparture"),
          switchText: t("calendarpage.closedForDeparture"),
          type: "boolean",
        };
      case "Stopsell":
        return {
          title: t("calendarpage.stopSell"),
          titleFirst: t("calendarpage.selectDateRange"),
          descriptionFirst: t("calendarpage.theNightOfTheToDateIsExcluded"),
          buttonText: t("calendarpage.updateAndSyncStopSell"),
          switchText: t("calendarpage.stopSell"),
          type: "boolean",
        };
      case "IsActive":
        return {
          title: t("calendarpage.isActive"),
          titleFirst: t("calendarpage.selectDateRange"),
          descriptionFirst: t("calendarpage.theNightOfTheToDateIsExcluded"),
          buttonText: t("calendarpage.updateAndSyncIsActive"),
          switchText: t("calendarpage.isActive"),
          type: "boolean",
        };
      case "Rate":
        return {
          title: t("calendarpage.spotRates"),
          titleFirst: t("calendarpage.dateRange"),
          descriptionFirst: t("calendarpage.spotRates"),
          titleSecond: t("calendarpage.rate"),
          buttonText: t("calendarpage.setSpotRate"),
          type: "number",
          showCurrency: true,
        };
    }
  };

  return (
    <>
      {modalState.type && modalState.anchorEl && (
        <>
          {modalInfo(modalState.type)?.type === "number" && (
            <NumberModal
              open={Boolean(modalState.anchorEl)}
              anchorEl={modalState.anchorEl}
              handleClose={handleModalClose}
              modalTitle={modalInfo(modalState.type)?.title}
              titleFirst={modalInfo(modalState.type)?.titleFirst}
              descriptionFirst={modalInfo(modalState.type)?.descriptionFirst}
              titleSecond={modalInfo(modalState.type)?.titleSecond}
              descriptionSecond={modalInfo(modalState.type)?.descriptionSecond}
              buttonText={modalInfo(modalState.type)?.buttonText}
              ratePlanId={modalState.ratePlanId}
              propertyId={modalState.propertyId}
              start={modalState.start}
              refetch={refetch}
              fieldName={modalState.type}
              value={modalState.value}
              showCurrency={modalInfo(modalState.type)?.showCurrency}
            />
          )}
          {modalInfo(modalState.type)?.type === "boolean" && (
            <BooleanModal
              open={Boolean(modalState.anchorEl)}
              anchorEl={modalState.anchorEl}
              handleClose={handleModalClose}
              modalTitle={modalInfo(modalState.type)?.title}
              title={modalInfo(modalState.type)?.titleFirst}
              description={modalInfo(modalState.type)?.descriptionFirst}
              buttonText={modalInfo(modalState.type)?.buttonText}
              switchText={modalInfo(modalState.type)?.switchText}
              ratePlanId={modalState.ratePlanId}
              propertyId={modalState.propertyId}
              start={modalState.start}
              refetch={refetch}
              fieldName={modalState.type}
              value={modalState.value}
            />
          )}
        </>
      )}
    </>
  );
}

export default ModalsSection;
