import Airbnb from "../../../../assets/images/icons/airbnb.svg";
import Guesty from "../../../../assets/images/icons/guesty.svg";
import HostAway from "../../../../assets/images/icons/hostaway.svg";
import Lodgify from "../../../../assets/images/icons/lodgify.svg";
import Hostfully from "../../../../assets/images/icons/hostfully.svg";
import Hospitable from "../../../../assets/images/icons/hospitable.svg";
import Mews from "../../../../assets/images/icons/mews.svg";
import Upload from "../../../../assets/images/icons/Component 2/Primary.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { useDispatch } from "react-redux";
import { setOnboardingData, setStepsComplete } from "../../../../store/slices/onboardSlice";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getChannelApi } from "../../../../api/channelApi";
import { Container, Grid, Flex, Text, Title, Overlay } from "@mantine/core";
import "./ConnectPropertyListing.scss"


const getProviderType = (channelCode: string): number => {
  const providerTypeMap: { [key: string]: number } = {
    Guesty: 1,
    Hostaway: 2,
    Lodgify: 5,
    Hostfully: 4,
    Mews: 6,
    Airbnb: 7,
  };
  return providerTypeMap[channelCode] || 1;
};

const ConnectPropertyListing = () => {
  const { onboardingData } = useSelector((state: RootState) => state.onboard)
  const compStep = 2
  const dispatch = useDispatch()
  const [channels, setChannels] = useState<any[]>([])
  const { getAccessTokenSilently } = useAuth0();

  const handleClick = (category: string) => {
    const providerType = getProviderType(category);

    console.log("Channel Selection:", {
      selectedChannel: category,
      providerType: providerType,
    });

    dispatch(setOnboardingData({
      ...onboardingData,
      channels: [category],
      categories: [category],
      selectedProviderType: providerType,
    }))

    dispatch(setStepsComplete({ key: compStep, status: true }))

    console.log("Updated Onboarding Data:", {
      selectedChannel: category,
      providerType: providerType,
    });

  };


  useEffect(() => {
    const fetchChannels = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        const response = await getChannelApi({ accessToken })

        setChannels(response.data || [])
      } catch (error) {
        console.error("API error:", error);
        throw error;
      }
    };

    fetchChannels()
  }, [])


  if (!Array.isArray(channels)) {
    return <p>Veri yüklenirken bir hata oluştu.</p>;
  }

  const getIconForChannel = (code: string) => {
    switch (code) {
      case "Airbnb":
        return Airbnb;
      case "Guesty":
        return Guesty;
      case "Hostaway":
        return HostAway;
      case "Lodgify":
        return Lodgify;
      case "Hostfully":
        return Hostfully;
      case "Hospitable":
        return Hospitable;
      case "Mews":
        return Mews;
      default:
        return Upload;
    }
  };


  const RenderChannelItem = ({ channel, onClick }: { channel: any, onClick: any }) => {
    const isComingSoon = !channel.isActive
    const categoryText = channel.code
    const imgSrc = getIconForChannel(channel.code)
    const helperText = channel.type
    const selectedActive = onboardingData.selectedProviderType === getProviderType(channel.code)
    return (
      <div className={`channel-box ${selectedActive && "active"}`} onClick={isComingSoon ? undefined : onClick} >
        {isComingSoon && <Overlay radius="lg" color="#fff" backgroundOpacity={0.5} />}
        <div className="flex-box">
          {isComingSoon && (
            <div className="coming-soon">
              <Text size="sm" fw={500} c="white">
                Coming soon
              </Text>
            </div>
          )}
          <img
            src={imgSrc}
            alt="category"
            className="category-image"
            style={{ width: 48, height: 48, marginRight: 16 }}
          />
          <div className="containerCategory">
            <span className="infoCategoryText B-16-16-500">{categoryText}</span>
            <span className="helperCategoryText B-14-20-400">{helperText}</span>
          </div>
        </div>
      </div >
    )
  }

  return (
    <Flex direction="column" align="center" gap={40}>
      <Flex direction="column" align="center">
        <Title order={1} fw={600} >Connect Your Property Listings</Title>
        <Text size="lg" fw={500} c="dimmed">
          Seamlessly integrate with your existing property management systems.
        </Text>
      </Flex>
      <Container size="xl">
        <Grid>
          {channels.map((channel: any) => (
            <Grid.Col span={{ base: 12, md: 6, lg: 4 }} key={channel.id}>
              <RenderChannelItem channel={channel} onClick={() => handleClick(channel.code)} />
            </Grid.Col>
          ))}
        </Grid>
      </Container>
    </Flex>
  );
}

export default ConnectPropertyListing;
