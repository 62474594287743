export const menuDatas = (subMenu: string) => {
  if (subMenu === "booking") {
    return bookingRulesData;
  } else if (subMenu === "otherEssentials") {
    return otherEssentialsData;
  } else if (subMenu === "photos") {
    return photosData;
  }
  return mainMenuData;
};

const mainMenuData = [
  {
    title: "Listing details",
    url: "details",
    list: [
      {
        title: "Location",
        url: "location",
      },
      {
        title: "Photos",
        url: "photos",
      },
      { title: "Basic-details", url: "basic-details" },
      {
        title: "Rooms",
        url: "rooms",
      },
      {
        title: "Description",
        url: "description",
      },
      {
        title: "Facilities",
        url: "facilities",
      },
    ],
  },
  {
    title: "Pricing and availability",
    url: "pricing",
    list: [
      {
        title: "Basic rates",
        url: "basic-rates",
      },
      {
        title: "Seasonal rates",
        url: "seasonal-rates",
      },
      { title: "Discount", url: "discount" },
      {
        title: "Extras",
        url: "extras",
      },
    ],
  },
  {
    title: "Policies and rules",
    url: "policies",
    list: [
      {
        title: "Booking rules",
        url: "booking-rules",
      },
      { title: "Suitability", url: "suitability" },
      {
        title: "House rules",
        url: "house-rules",
      },
      {
        title: "Before booking",
        url: "before-booking",
      },
      {
        title: "After booking",
        url: "after-booking",
      },
    ],
  },
];

const bookingRulesData = [
  {
    title: "Booking rules",
    url: "policies",
    list: [
      {
        title: "Advance notice",
        url: "advance-notice",
      },
      {
        title: "Guest booking options",
        url: "guest-booking-options",
      },
    ],
  },
];

const otherEssentialsData = [
  {
    title: "Other essentials",
    url: "details",
    list: [
      {
        title: "Guest safety",
        url: "guest-safety",
      },
      {
        title: "Kitchens",
        url: "kitchens",
      },
      {
        title: "Living/dining room",
        url: "living-dining-room",
      },
      {
        title: "Outdoor",
        url: "outdoor",
      },
      {
        title: "Miscellaneous",
        url: "miscellaneous",
      },
    ],
  },
];

const photosData = [
  {
    title: "Photos",
    url: "details",
    list: [
      {
        title: "Cover photo",
        url: "cover-photo",
      },
      {
        title: "All photos",
        url: "all-photos",
      },
    ],
  },
];
