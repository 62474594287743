export const keys = [
  "Rate",
  "MinStayArrival",
  "MinStayThrough",
  "MinStay",
  "MaxStay",
  "ClosedToArrival",
  "ClosedToDeparture",
  "Stopsell",
  "MaxAvailability",
  "AvailabilityOffset",
  "IsActive",
  "PersonCount",
  "PersonType",
];
