import { useState, SetStateAction, useEffect } from "react";
import styles from "../onboardingTwoStyles";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import iconbutton from "../../../../assets/images/IconButton.svg";
import { Option } from "../../../../components/core-components/inputs/Select/CustomSelect";
import ListingModal from "./ListingModal";
import AssignerSelect from "../../inbox/messages/components/Autocomplate";
import timeIcon from "../../../../assets/images/icons/time-icon.svg";
import { inputSectionStyles } from "../../listing/DatagridStyles";
import MainSelectSelection from "../../../../components/core-components/inputs/Select/MainSelectSelection";
import CustomModal from "../../../../components/shared-components/CustomModal";
import CustomMobileSelectMenu from "../../../../components/core-components/inputs/Select/CustomMobileSelectMenu";
import CustomModalMobile from "../../../../components/shared-components/CustomModalMobile";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

type AssigneeOption = {
  label: string;
  value: string;
};

type ChatBotAutoYesProps = {
  validateForm: (hasError: boolean) => void;
  onAddedPropertiesChange: (properties: any[]) => void;
  selectedProperties?: any[];
};
const API_BASE_URL = process.env.REACT_APP_API_URL;

function ChatBotAutoYes({
  validateForm,
  onAddedPropertiesChange,
  selectedProperties,
}: ChatBotAutoYesProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [reserveStatus, setReserveStatus] = useState("");
  const [workingStartHour, setWorkingStartHour] = useState<string>("10:00");
  const [workingEndHour, setWorkingEndHour] = useState<string>("22:00");
  const [workingDays, setWorkingDays] = useState<AssigneeOption[]>([]);
  const [selectedPropertyId, setSelectedPropertyId] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [selectedListings, setSelectedListings] = useState<number[]>([]);
  const [addedProperties, setAddedProperties] = useState<any[]>([]);
  const [openMobileModal, setOpenMobileModal] = useState(false);
  const [reserveStatusError, setReserveStatusError] = useState<string | null>(
    null
  );
  const [workingMode, setWorkingMode] = useState("");
  const [workingModeError, setWorkingModeError] = useState<string | null>(null);
  const [workingDaysError, setWorkingDaysError] = useState<string | null>(null);
  const [isReserveStatusTouched, setIsReserveStatusTouched] = useState(false);
  const [isWorkingModeTouched, setIsWorkingModeTouched] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [autoPilotId, setAutoPilotId] = useState<string>("");

  useEffect(() => {
    if (selectedProperties && selectedProperties.length > 0) {
      const formattedProperties = {
        id: Date.now(),
        listingIds: selectedProperties.map((prop) => prop.propertyId),
        reserveStatus,
        workingMode,
        workingDays,
        workingStartHour,
        workingEndHour,
      };

      setAddedProperties([formattedProperties]);

      saveToApi(formattedProperties);
    }
  }, [selectedProperties]);

  const saveToApi = async (property: any) => {
    try {
      const token = await getAccessTokenSilently();
      const customerId = getCustomerIdFromToken();

      if (!customerId) {
        console.error("CustomerId could not be retrieved from token");
        return;
      }

      const propertyIds = property.listingIds.map((id: number) =>
        id.toString()
      );

      const weekDays = property.workingDays.map((day: { value: string }) => {
        const dayMapping: { [key: string]: string } = {
          Monday: "1",
          Tuesday: "2",
          Wednesday: "3",
          Thursday: "4",
          Friday: "5",
          Saturday: "6",
          Sunday: "7",
        };
        return dayMapping[day.value];
      });

      const baseRequestData = {
        customerId: customerId,
        reservationStatus: property.reserveStatus,
        workingMode: property.workingMode,
        isActive: true,
        properties: propertyIds,
        weekDays: weekDays,
        startTime: property.workingStartHour,
        endTime: property.workingEndHour,
      };

      if (autoPilotId) {
        const updateRequestData = {
          id: autoPilotId,
          ...baseRequestData,
        };

        await axios.put(
          `${API_BASE_URL}/CustomerAutoPilot/Update`,
          updateRequestData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
      } else {
        const response = await axios.post(
          `${API_BASE_URL}/CustomerAutoPilot/Add`,
          baseRequestData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data && response.data.id) {
          setAutoPilotId(response.data.id);
        }
      }
    } catch (error: any) {
      if (error.response) {
      }
    }
  };

  const handleBlur = (field: string) => {
    switch (field) {
      case "reserveStatus":
        setIsReserveStatusTouched(true);
        break;
      case "workingMode":
        setIsWorkingModeTouched(true);
        break;
      case "workingDays":
        break;
      default:
        break;
    }
    validateFields();
  };

  const handleOpenModal = () => {
    const hasError = validateFields();
    if (!hasError) {
      setOpenModal(true);
    } else {
      setIsReserveStatusTouched(true);
      setIsWorkingModeTouched(true);
    }
  };

  const handleOpenMobileModal = () => {
    const hasError = validateFields();
    if (!hasError) {
      setOpenMobileModal(true);
    } else {
      setIsReserveStatusTouched(true);
      setIsWorkingModeTouched(true);
    }
  };

  const handleCloseModal = () => setOpenModal(false);
  const handleCloseMobileModal = () => setOpenMobileModal(false);

  const validateFields = (skipSaveAllValidation = false): boolean => {
    if (skipSaveAllValidation && addedProperties.length > 0) {
      return false;
    }

    let hasError = false;

    if (!reserveStatus) {
      setReserveStatusError("Reservation status is required.");
      hasError = true;
    } else {
      setReserveStatusError(null);
    }

    if (!workingMode) {
      setWorkingModeError("Working mode is required.");
      hasError = true;
    } else {
      setWorkingModeError(null);
    }

    if (workingMode === "Custom" && workingDays.length === 0) {
      setWorkingDaysError("Working days are required.");
      hasError = true;
    } else {
      setWorkingDaysError(null);
    }

    return hasError;
  };

  const handleAddProperty = (selectedListings: number[]) => {
    if (selectedPropertyId) {
      updateProperty(selectedPropertyId, selectedListings);
    } else {
      const newProperty = {
        id: Date.now(),
        listingIds: selectedListings,
        reserveStatus,
        workingMode,
        workingDays,
        workingStartHour,
        workingEndHour,
      };
      setAddedProperties((prevProperties) => {
        const updatedProperties = [...prevProperties, newProperty];
        saveToApi(newProperty);
        return updatedProperties;
      });
    }

    setIsReserveStatusTouched(false);
    setIsWorkingModeTouched(false);
    resetFormAndCloseModal();
  };

  const updateProperty = (id: string, updatedListings: number[]) => {
    setAddedProperties((prevProperties) => {
      const updatedProperties = prevProperties.map((property) =>
        property.id === id
          ? {
            ...property,
            listingIds: updatedListings,
          }
          : property
      );

      const updatedProperty = updatedProperties.find((p) => p.id === id);
      if (updatedProperty) {
        saveToApi(updatedProperty);
      }

      return updatedProperties;
    });
    setSelectedPropertyId("");
    setSelectedListings([]);
  };

  const handleFormChange = async (field: string, value: any) => {
    switch (field) {
      case "reserveStatus":
        setReserveStatus(value);
        setReserveStatusError(null);
        break;
      case "workingMode":
        setWorkingMode(value);
        setWorkingModeError(null);
        break;
      case "workingDays":
        setWorkingDays(value);
        setWorkingDaysError(null);
        break;
      case "workingStartHour":
        setWorkingStartHour(value);
        break;
      case "workingEndHour":
        setWorkingEndHour(value);
        break;
    }

    if (addedProperties.length > 0 && autoPilotId) {
      const currentProperty = addedProperties[0];
      const updatedProperty = {
        ...currentProperty,
        [field]: value,
        reserveStatus,
        workingMode,
        workingDays: field === "workingDays" ? value : workingDays,
        workingStartHour:
          field === "workingStartHour" ? value : workingStartHour,
        workingEndHour: field === "workingEndHour" ? value : workingEndHour,
      };

      await saveToApi(updatedProperty);
    }
  };

  useEffect(() => {
    onAddedPropertiesChange(addedProperties);
  }, [addedProperties]);

  const resetFormAndCloseModal = () => {
    setReserveStatus("");
    setWorkingMode("");
    setWorkingDays([]);
    setWorkingStartHour("10:00");
    setWorkingEndHour("22:00");
    setSelectedListings([]);
    setOpenModal(false);
    setOpenMobileModal(false);
  };

  useEffect(() => {
    onAddedPropertiesChange(addedProperties);
  }, [addedProperties]);

  const getCustomerIdFromToken = () => {
    const token = localStorage.getItem("accessToken");
    if (!token) return null;
    try {
      const tokenPayload = JSON.parse(atob(token.split(".")[1]));
      return tokenPayload.customerId;
    } catch (error) {
      console.error("Error decoding token:", error);
      return null;
    }
  };


  const timeOptions: Option[] = [
    { value: "00:00", label: "00:00" },
    { value: "01:00", label: "01:00" },
    { value: "02:00", label: "02:00" },
    { value: "03:00", label: "03:00" },
    { value: "04:00", label: "04:00" },
    { value: "05:00", label: "05:00" },
    { value: "06:00", label: "06:00" },
    { value: "07:00", label: "07:00" },
    { value: "08:00", label: "08:00" },
    { value: "09:00", label: "09:00" },
    { value: "10:00", label: "10:00" },
    { value: "11:00", label: "11:00" },
    { value: "12:00", label: "12:00" },
    { value: "13:00", label: "13:00" },
    { value: "14:00", label: "14:00" },
    { value: "15:00", label: "15:00" },
    { value: "16:00", label: "16:00" },
    { value: "17:00", label: "17:00" },
    { value: "18:00", label: "18:00" },
    { value: "19:00", label: "19:00" },
    { value: "20:00", label: "20:00" },
    { value: "21:00", label: "21:00" },
    { value: "22:00", label: "22:00" },
    { value: "23:00", label: "23:00" },
  ];
  const reservationStatusOptions: any[] = [
    { value: "Inquiry", label: "Inquiry" },
    { value: "Confirmed", label: "Confirmed" },
    { value: "Canceled", label: "Canceled" },
  ];
  const workingModeOptions = [
    { value: "7/24 Online", label: "7/24 Online" },
    { value: "Custom", label: "Custom" },
  ];
  const nightsOptions = [
    { label: "Monday", value: "Monday" },
    { label: "Tuesday", value: "Tuesday" },
    { label: "Wednesday", value: "Wednesday" },
    { label: "Thursday", value: "Thursday" },
    { label: "Friday", value: "Friday" },
    { label: "Saturday", value: "Saturday" },
    { label: "Sunday", value: "Sunday" },
  ];

  useEffect(() => {
    const initialError = validateFields();
    validateForm(initialError);
  }, []);

  useEffect(() => {
    const error = validateFields();
    validateForm(error || addedProperties.length === 0);
  }, [reserveStatus, workingMode, workingDays, addedProperties]);

  useEffect(() => {
    onAddedPropertiesChange(addedProperties);
  }, [addedProperties]);

  useEffect(() => {
    const fetchAutoPilotData = async () => {
      try {
        const token = await getAccessTokenSilently();
        const customerId = getCustomerIdFromToken();

        if (!customerId) return;

        const response = await axios.get(
          `${API_BASE_URL}/CustomerAutoPilot/Get?CustomerId=${customerId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = response.data;
        if (data) {
          setAutoPilotId(data.id || "");

          if (data.reservationStatus !== undefined) {
            setReserveStatus(data.reservationStatus);
          }

          if (data.workingMode !== undefined) {
            setWorkingMode(data.workingMode);
          }

          if (Array.isArray(data.weekDays)) {
            const dayMapping: { [key: string]: string } = {
              "1": "Monday",
              "2": "Tuesday",
              "3": "Wednesday",
              "4": "Thursday",
              "5": "Friday",
              "6": "Saturday",
              "7": "Sunday",
            };

            const formattedDays = data.weekDays.map((day: string) => ({
              label: dayMapping[day] || day,
              value: dayMapping[day] || day,
            }));

            setWorkingDays(formattedDays);
          }

          if (data.startTime) {
            setWorkingStartHour(data.startTime);
          }

          if (data.endTime) {
            setWorkingEndHour(data.endTime);
          }

          if (data.properties && data.properties.length > 0) {
            const listingIds = data.properties.map((id: string) => id);

            const formattedProperty = {
              id: Date.now(),
              listingIds: listingIds,
              reserveStatus: data.reservationStatus || "",
              workingMode: data.workingMode || "",
              workingDays: data.weekDays || [],
              workingStartHour: data.startTime || "10:00",
              workingEndHour: data.endTime || "22:00",
            };

            setAddedProperties([formattedProperty]);
          }

          setTimeout(() => {
            validateFields(true);
          }, 0);
        }
      } catch (error) { }
    };

    fetchAutoPilotData();
  }, [getAccessTokenSilently]);

  return (
    <Box sx={styles.communicationContainer}>
      <p className="radioButtonDescription">Edit auto-pilot settings</p>
      <Stack sx={{ width: "100%" }} gap={"12px"}>
        <p className="labelSelect">Reservation status</p>
        {isMobile ? (
          <CustomMobileSelectMenu
            title="Select working mode"
            options={reservationStatusOptions}
            multiple={false}
            onBlur={() => handleBlur("reserveStatus")}
            selectedValues={[reserveStatus]}
            setSelectedValues={(
              values: string[] | SetStateAction<string[]>
            ) => {
              if (Array.isArray(values)) {
                handleFormChange("reserveStatus", values[0]);
              }
            }}
          />
        ) : (
          <MainSelectSelection
            options={reservationStatusOptions}
            isFilter={true}
            pageType="chatbot"
            onChange={(e: any) => {
              handleFormChange("reserveStatus", e.target.value);
            }}
            value={reserveStatus || ""}
            label={"Select"}
            error={!!reserveStatusError}
          />
        )}
        {reserveStatusError && isReserveStatusTouched && (
          <p className="error-message" style={{ margin: "3px 14px 0px" }}>
            {reserveStatusError}
          </p>
        )}
      </Stack>

      <Stack sx={{ width: "100%" }} gap={"12px"}>
        <p className="labelSelect">Working mode</p>
        {isMobile ? (
          <CustomMobileSelectMenu
            title="Select working mode"
            options={workingModeOptions}
            multiple={false}
            onBlur={() => handleBlur("workingMode")}
            selectedValues={[workingMode]}
            setSelectedValues={(
              values: string[] | SetStateAction<string[]>
            ) => {
              if (Array.isArray(values)) {
                handleFormChange("reserveStatus", values[0]);
              }
            }}
          />
        ) : (
          <MainSelectSelection
            options={workingModeOptions}
            isFilter={false}
            pageType="chatbot"
            onChange={(e: any) => {
              handleFormChange("workingMode", e.target.value);
            }}
            value={workingMode}
            label={"Select"}
            error={!!workingModeError}
          />
        )}
        {workingModeError && isWorkingModeTouched && (
          <p className="error-message" style={{ margin: "3px 14px 0px" }}>
            {workingModeError}
          </p>
        )}
      </Stack>
      {workingMode === "Custom" && (
        <>
          <Stack sx={{ width: "100%" }} gap={"12px"}>
            <p className="labelSelect">Working days in a week </p>
            {isMobile ? (
              <CustomMobileSelectMenu
                title="Which nights?"
                options={nightsOptions}
                multiple={true}
                selectedValues={workingDays.map((day) => day.value)}
                setSelectedValues={(
                  values: string[] | SetStateAction<string[]>
                ) => {
                  if (Array.isArray(values)) {
                    const formattedDays = values.map((value) => ({
                      label: value,
                      value,
                    }));
                    handleFormChange("workingDays", formattedDays);
                  }
                }}
              />
            ) : (
              <AssignerSelect
                isSelectedItemsChip={true}
                multiple={true}
                pageType="chatbot"
                options={nightsOptions}
                value={workingDays}
                onChange={(
                  event,
                  newValue: AssigneeOption | AssigneeOption[] | null
                ) => {
                  if (Array.isArray(newValue)) {
                    handleFormChange("workingDays", newValue);
                  }
                }}
                placeholder={
                  workingDays.length === 0 ? "Select week days" : undefined
                }
              />
            )}
            {workingDaysError && (
              <p className="error-message" style={{ marginLeft: "14px" }}>
                {workingDaysError}
              </p>
            )}
          </Stack>
          <Stack
            flexDirection={isMobile ? "column" : "row"}
            width={"100%"}
            gap={"24px"}
          >
            <FormControl fullWidth>
              <p className="labelSelect">Start working hour </p>
              {isMobile ? (
                <CustomMobileSelectMenu
                  title="Select start working hour"
                  options={timeOptions as { value: string; label: string }[]}
                  multiple={false}
                  selectedValues={[workingStartHour]}
                  setSelectedValues={(
                    values: string[] | SetStateAction<string[]>
                  ) => {
                    if (Array.isArray(values)) {
                      handleFormChange("workingStartHour", values[0]);
                    }
                  }}
                />
              ) : (
                <Select
                  value={workingStartHour}
                  onChange={(event) =>
                    handleFormChange("workingStartHour", event.target.value)
                  }
                  sx={inputSectionStyles.chatBot}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  IconComponent={(props) => (
                    <img {...props} src={timeIcon} alt="arrow-down" />
                  )}
                >
                  <MenuItem value="">
                    <em>19:00</em>
                  </MenuItem>
                  {timeOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </FormControl>
            <FormControl fullWidth>
              <p className="labelSelect">End working hour </p>
              {isMobile ? (
                <CustomMobileSelectMenu
                  title="Select end working hour"
                  options={timeOptions as { value: string; label: string }[]}
                  multiple={false}
                  selectedValues={[workingEndHour]}
                  setSelectedValues={(
                    values: string[] | SetStateAction<string[]>
                  ) => {
                    if (Array.isArray(values)) {
                      handleFormChange("workingEndHour", values[0]);
                    }
                  }}
                />
              ) : (
                <Select
                  value={workingEndHour}
                  onChange={(event) =>
                    handleFormChange("workingEndHour", event.target.value)
                  }
                  sx={inputSectionStyles.chatBot}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  IconComponent={(props) => (
                    <img {...props} src={timeIcon} alt="arrow-down" />
                  )}
                >
                  <MenuItem value="">
                    <em>20:00</em>
                  </MenuItem>
                  {timeOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </FormControl>
          </Stack>
        </>
      )}
      <Stack
        width={"100%"}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <p className="labelSelect">Choose listing responsible</p>
        <button
          onClick={() => {
            setSelectedListings([]);
            isMobile ? handleOpenMobileModal() : handleOpenModal();
          }}
          className="add-listing"
          style={{ backgroundColor: "transparent", border: "none" }}
        >
          <span style={{ fontSize: "20px" }}>+</span> Add Listing
        </button>
      </Stack>

      {addedProperties.length > 0 && (
        <Stack width={"100%"} pt={"24px"} borderTop={"1px solid #E1E3EA"}>
          <p className="properties-title">Properties</p>
        </Stack>
      )}
      <CustomModal
        open={openModal}
        handleClose={handleCloseModal}
        handleSave={() => { }}
        title="Choose listings"
        showFooter={false}
      >
        <ListingModal
          handleAddProperty={handleAddProperty}
          selectedListings={selectedListings}
        />
      </CustomModal>
      <CustomModalMobile
        open={openMobileModal}
        handleClose={handleCloseMobileModal}
        title="Add Listing"
        showFooter={false}
        handleSave={() => { }}
      >
        <ListingModal
          handleAddProperty={handleAddProperty}
          selectedListings={selectedListings}
        />
      </CustomModalMobile>
      {addedProperties.map((property) => (
        <Box sx={styles.innerPropertiesContainer} key={property.id}>
          <Stack
            flexDirection={"row"}
            justifyContent={"flex-start"}
            alignItems={"center"}
            gap={"16px"}
          >
            <Stack
              justifyContent={"flex-start"}
              alignItems={"center"}
              flexDirection={"row"}
            >
              <img
                src={iconbutton}
                style={{
                  marginRight: "8px",
                }}
                alt="icon"
              />
              <p>{property.listingIds.length} listings </p>
            </Stack>
            <Stack gap={"8px"} flexDirection={"row"} alignItems={"center"}>
              {isMobile ? (
                <>
                  <p className="property-tag">{property.reserveStatus}</p>
                  <p className="property-tag">
                    + {property.workingMode === "Custom" ? 3 : 1}
                  </p>
                </>
              ) : (
                <>
                  <p className="property-tag">{property.reserveStatus}</p>
                  <p className="property-tag">{property.workingMode}</p>
                  {property.workingMode === "Custom" && (
                    <>
                      <p className="property-tag">
                        {property.workingStartHour}
                      </p>
                      <p className="property-tag">{property.workingEndHour}</p>
                    </>
                  )}
                </>
              )}
            </Stack>
          </Stack>
          <Box>
            <Button
              onClick={() => {
                setSelectedListings(property.listingIds);
                setSelectedPropertyId(property.id);
                isMobile ? setOpenMobileModal(true) : setOpenModal(true);
              }}
            >
              Update
            </Button>
            <Button
              onClick={async () => {
                if (autoPilotId) {
                  try {
                    const token = await getAccessTokenSilently();
                    await axios.put(
                      `${API_BASE_URL}/CustomerAutoPilot/Update`,
                      {
                        id: autoPilotId,
                        customerId: getCustomerIdFromToken(),
                        reservationStatus: reserveStatus,
                        workingMode: workingMode,
                        isActive: true,
                        properties: [],
                        weekDays: workingDays.map((day) => {
                          const dayMapping: { [key: string]: string } = {
                            Monday: "1",
                            Tuesday: "2",
                            Wednesday: "3",
                            Thursday: "4",
                            Friday: "5",
                            Saturday: "6",
                            Sunday: "7",
                          };
                          return dayMapping[day.value];
                        }),
                        startTime: workingStartHour,
                        endTime: workingEndHour,
                      },
                      {
                        headers: {
                          Authorization: `Bearer ${token}`,
                          "Content-Type": "application/json",
                        },
                      }
                    );
                  } catch (error) {
                    console.error("Error deleting property:", error);
                  }
                }
                setAddedProperties((prevProperties) =>
                  prevProperties.filter((p) => p.id !== property.id)
                );
              }}
              sx={{
                marginLeft: "10px",
                color: "red",
              }}
            >
              Delete
            </Button>
          </Box>
        </Box>
      ))}
    </Box>
  );
}

export default ChatBotAutoYes;
