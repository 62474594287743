import React from "react";
import Whatsapp from "../../../../../assets/images/icons/JvsnINJ2CZv 1.svg";
import Arrow from "../../../../../assets/images/icons/triange.svg";
import StatusEllipseSvg from "../../../../../assets/images/icons/StatusEllipseSvg";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import moment from "moment";
import i18n from "../../../../../utils/i18n/i18n";
import walk from "../../../../../assets/images/icons/walking.svg";
import CapitalizeWords from "../../../../../components/shared-components/CapitalizeWords";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../store/store";
import { toggleActionNeededModal } from "../../../../../store/slices/chatSlice";
import { MessageHeader } from "../../../../../types";
import "../MessageCard.scss"
import { IconMapPin } from "@tabler/icons-react";
import { Flex, Text } from "@mantine/core";
import { h_formatUTCtoLocaleTime } from "../../../../../utils/helpers";

interface IncomeMessagesProps {
  message: MessageHeader
  draftMessage: string,
  selectedMessage: string;
  bookingName?: string;
}

//checkIn={
//                      message.bookingCheckIn
//                        ? moment(message.bookingCheckIn)
//                          .locale(i18n.language)
//                          .format("MMM DD")
//                        : ""
//                    }
//

//  checkOut={
//                      message.bookingCheckOut
//                        ? moment(message.bookingCheckOut)
//                          .locale(i18n.language)
//                          .format("MMM DD")
//                        : ""
//                    }


const IncomeMessages: React.FC<IncomeMessagesProps> = ({
  message,
  selectedMessage,
  draftMessage
}) => {
  const theme = useTheme();


  const dispatch = useDispatch<AppDispatch>();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  //const [localNeedAction, setLocalNeedAction] = useState(isNeedAttention);

  //  useEffect(() => {
  //    const currentMessage = messages.find((msg) => msg.id === messageId);
  //    if (currentMessage) {
  //      const shouldShowAction = Boolean(
  //        currentMessage.isNeedAttention &&
  //        (!currentMessage.messageNeedPost ||
  //          currentMessage.messageNeedPost.status !== "declined")
  //      );
  //
  //      setLocalNeedAction(shouldShowAction);
  //    }
  //  }, [messages]);
  //  //messageId]);

  moment.locale(i18n.language);

  const formatMessageDate = (dateString: string) => {
    const messageDate = moment(dateString);
    const today = moment();

    if (messageDate.isSame(today, "day")) {
      return h_formatUTCtoLocaleTime(dateString)
    }

    return messageDate.locale(i18n.language).format("MMM DD");
  };

  const truncateMessage = (message: string) => {
    const maxWidth = 320;
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    if (context) {
      context.font = "13px Inter";
      if (context.measureText(message).width > maxWidth) {
        let truncated = message;
        while (context.measureText(truncated + "...").width > maxWidth) {
          truncated = truncated.slice(0, -1);
        }
        return truncated.slice(0, -3) + "...";
      }
    }
    return message;
  };

  const stripHtml = (html: string) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  };

  const {
    bookingStatus: isConfirmed,
    id: messageId,
    isNeedAttention,
    lastMessage,
    assignedUser,
    isRead,
    propertyName
  } = message

  return (
    <Stack
      padding={isMobile ? "20px 10px" : "20px"}
      alignItems="center"
      alignSelf="stretch"
      height="100%"
      borderBottom="1px solid #EBEBEB"
      width={"100%"}
      sx={{
        overflowX: "hidden",
        cursor: "pointer",
        // background: "#fff !important",
        position: "relative",
        "&:hover": {
          background:
            "linear-gradient(0deg, rgba(0, 101, 224, 0.05) 0%, rgba(0, 101, 224, 0.05) 100%), #FFF",
        },
      }}
    >
      {selectedMessage === messageId && (
        <>
          {!isMobile && (
            <div
              style={{
                position: "absolute",
                right: -22,
                top: "20%",
                zIndex: "9999 !important",
              }}
            >
              <img
                style={{
                  width: "44px",
                  height: "44px",
                }}
                src={Arrow}
                alt="Arrow Down"
              />
            </div>
          )}
        </>
      )}
      <Stack
        justifyContent="center"
        alignItems="flex-start"
        spacing={"4px"}
        flex="1 0 0"
        width={"100%"}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          alignSelf="stretch"
          width={"100%"}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <span
              className="B-16-24-600"
              style={{
                fontWeight: !isRead ? "bold" : "normal",
              }}
            >
              {message?.title || "Unnamed Customer"}
            </span>{" "}
            {!isRead && (
              <span
                style={{
                  width: "8px",
                  height: "8px",
                  borderRadius: "50%",
                  backgroundColor: "#007AFF",
                  marginLeft: "4px",
                }}
              />
            )}
            {isConfirmed ? null : (
              <img
                src={Whatsapp}
                alt="whatsapp"
                style={{ width: "16.717px", height: "18px", flexShrink: 0 }}
              />
            )}
          </Stack>
          <span
            className="B-12-18-500"
            style={{
              fontWeight: !isRead ? "bold" : "normal",
            }}
          >
            {formatMessageDate(message?.lastMessageReceivedAt)}
          </span>
        </Stack>

        {draftMessage ?
          <span className={`income-messages-message-text draft`} >
            Draft: {draftMessage}
          </span>

          :
          < span className={`income-messages-message-text ${!isRead ? "unread" : ""}`} >
            {truncateMessage(stripHtml(lastMessage))}
          </span>
        }

        {(isConfirmed || propertyName || assignedUser) && (
          <Flex w="100%" direction="column" gap={5}>
            {isConfirmed && (
              <Flex align="center" gap={8} pl={2}>
                <StatusEllipseSvg status={isConfirmed || "unknown"} />
                <Text size="sm" c="gray.6">
                  <CapitalizeWords text={isConfirmed} />
                </Text>
              </Flex>
            )}

            <Flex align="center" gap={5}>
              <IconMapPin size="16px" color="gray" />
              {propertyName && (
                <Text size="sm" c="gray.6">
                  {propertyName}
                </Text>
              )}
            </Flex>

            {assignedUser && (
              <span className="B-12-18-500">
                {selectedMessage === messageId ? (
                  <>
                    {(assignedUser) && (
                      <>Assigned to {assignedUser}</>
                    )}
                  </>
                ) : (
                  <>{`Assigned to ${assignedUser}`}</>
                )}
              </span>
            )}
          </Flex>
        )}
        {isNeedAttention && (
          <button
            onClick={(e) => { dispatch(toggleActionNeededModal()) }}
            className="ai-button"
          >
            <img src={walk} alt="walk icon" />
            Action Needed
          </button>
        )}


        {/*  {localNeedAction && (
          <button
            onClick={(e) => {
              dispatch(toggleActionNeededModal())
            }}
            className="ai-button"
          >
            <img src={walk} alt="walk icon" />
            Action Needed
          </button>
        )}
      */}
      </Stack>
    </Stack >
  );
};

export default IncomeMessages;
