import React, { useState } from "react";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import OptionalExtraBox from "./OptionalExtraBox";
import PlusSvg from "../../../../../../../../assets/images/icons/add-icon.svg";
import MainTextField from "../../../../../../../../components/core-components/inputs/TextField/MainTextField";
import MainSelectSelection from "../../../../../../../../components/core-components/inputs/Select/MainSelectSelection";
import CloseSvg from "../../../../../../../../assets/images/icons/arrows.svg";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";

type Extra = {
  title: string;
  price: string;
};

function OptionalExtras() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const [price, setPrice] = useState("");
  const [currency, setCurrency] = useState("");
  const [feeBasis, setFeeBasis] = useState("");
  const [extras, setExtras] = useState<Extra[]>([]);
  const [isAdding, setIsAdding] = useState(false);
  const [title, setTitle] = useState("");
  const [editingIndex, setEditingIndex] = useState<number | null>(null);

  const handleAddExtra = () => {
    setIsAdding(true);
    setEditingIndex(null);
  };

  const handleSaveExtra = () => {
    //editing
    if (editingIndex !== null) {
      setExtras(
        extras.map((extra, index) =>
          index === editingIndex
            ? { title, price: `${currency}${price} ${feeBasis}` }
            : extra
        )
      );
    }
    //adding
    else {
      setExtras([
        ...extras,
        { title, price: `${currency}${price} ${feeBasis}` },
      ]);
    }
    setTitle("");
    setPrice("");
    setCurrency("");
    setFeeBasis("");
    setIsAdding(false);
  };

  const handleDeleteExtra = (index: number) => {
    setExtras(extras.filter((_, i) => i !== index));
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (!isNaN(Number(value))) {
      setPrice(value);
    }
  };

  const handleCurrencyChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setCurrency(event.target.value as string);
  };

  const handleFeeBasisChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setFeeBasis(event.target.value as string);
  };

  const handleEditExtra = (index: number) => {
    const extra = extras[index];
    setTitle(extra.title);
    setPrice(extra.price);
    setIsAdding(true);
    setEditingIndex(index);
  };

  return (
    <Stack sx={{ width: "100%" }} spacing={3}>
      <p className="B-14-22-700">
        {t("detailsPages.pricing.optionalExtrasAndServices")}
      </p>
      {extras.length > 0 && (
        <Grid container>
          {extras.map((extra, index) => (
            <Grid
              item
              xs={12}
              sm={isMobile ? undefined : 4}
              key={index}
              style={isMobile ? { marginBottom: 24 } : { paddingRight: 24 }}
            >
              <OptionalExtraBox
                title={extra.title}
                price={extra.price}
                onEdit={() => handleEditExtra(index)}
                onDelete={() => handleDeleteExtra(index)}
              />
            </Grid>
          ))}
        </Grid>
      )}
      <Stack sx={{ width: "241px" }} spacing={1}>
        <button
          onClick={handleAddExtra}
          style={{
            borderRadius: "50px",
            border: "1px solid var(--Gray-Gray-800, #3F4254)",
            padding: "9px 12px",
            backgroundColor: "transparent",
            cursor: "pointer",
            width: "130px",
            height: "38px",
          }}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <img
              src={PlusSvg}
              alt="plus-icon"
              style={{
                width: "11.667px",
                height: "11.667px",
              }}
            />
            <p className="B-12-12-600">
              {t("detailsPages.pricing.addAnExtra")}
            </p>
          </Stack>
        </button>
      </Stack>
      {/*  Form alanı */}
      {isAdding && (
        <Stack
          sx={{
            maxWidth: "624px",
            border: "1px solid #C3C3C3",
            borderRadius: "12px",
            padding: "24px 32px",
          }}
          spacing={3}
        >
          <Stack
            sx={{ width: "100%" }}
            direction="row"
            justifyContent="space-between"
          >
            <p className="B-14-22-700">
              {t("detailsPages.pricing.optionalExtraAndService")}
            </p>
            <button
              onClick={() => setIsAdding(false)}
              style={{
                backgroundColor: "transparent",
                border: "none",
                cursor: "pointer",
              }}
            >
              <img
                src={CloseSvg}
                alt="close-icon"
                style={{ width: "10.591px", height: "10.611px" }}
              />
            </button>
          </Stack>
          <Stack sx={{ maxWidth: "560px" }} spacing={2}>
            <Stack spacing={1}>
              <p className="input-area-title B-14">
                {t("detailsPages.pricing.extraServicesName")}
              </p>
              <MainTextField
                placeholder={t("detailsPages.pricing.giveItName")}
                value={title}
                onChange={handleTitleChange}
              />
            </Stack>
            <Stack
              sx={{ width: "100%" }}
              direction={isMobile ? "column" : "row"}
              spacing={2}
            >
              <Stack sx={{ width: "100%" }} spacing={1}>
                <p className="input-area-title B-14">
                  {t("detailsPages.pricing.feeBasis")}
                </p>
                <Stack sx={{ width: "100%" }} spacing={1}>
                  <MainSelectSelection
                    value={feeBasis}
                    onChange={handleFeeBasisChange}
                    label={"Fee basis"}
                    options={[
                      {
                        value: "per day",
                        label: `per day`,
                      },
                      {
                        value: "no",
                        label: `no`,
                      },
                    ]}
                  />
                </Stack>
              </Stack>
              <Stack sx={{ width: "100%" }} spacing={1}>
                <p className="input-area-title B-14">
                  {t("detailsPages.pricing.amount")}
                </p>
                <Stack spacing={1} direction="row">
                  <MainTextField
                    placeholder="100"
                    type="number"
                    value={price}
                    onChange={handlePriceChange}
                  />
                  <MainSelectSelection
                    value={currency}
                    onChange={handleCurrencyChange}
                    label={"Currency"}
                    options={[
                      {
                        value: "$",
                        label: `$`,
                      },
                      {
                        value: "€",
                        label: `€`,
                      },
                      {
                        value: "£",
                        label: `£`,
                      },
                      {
                        value: "₺",
                        label: `₺`,
                      },
                      {
                        value: "No",
                        label: `No`,
                      },
                    ]}
                  />
                </Stack>
              </Stack>
            </Stack>

            <Stack
              sx={{ width: "100%" }}
              direction={isMobile ? "column" : "row"}
              spacing={2}
            >
              <Stack sx={{ width: "100%" }} spacing={1}>
                <p className="input-area-title B-14">
                  {t("detailsPages.pricing.earlierTheGuestCanOrderIt")}
                </p>
                <Stack sx={{ width: "100%" }} spacing={1}>
                  <MainSelectSelection
                    value="At the time of booking"
                    onChange={() => {}}
                    label={"At the time of booking"}
                    options={[
                      {
                        value: "At the time of booking",
                        label: `At the time of booking`,
                      },
                      {
                        value: "No",
                        label: `No`,
                      },
                    ]}
                  />
                </Stack>
              </Stack>
              <Stack sx={{ width: "100%" }} spacing={1}>
                <p className="input-area-title B-14">
                  {t("detailsPages.pricing.latestTheGuestCanOrderIt")}
                </p>
                <Stack sx={{ width: "100%" }} spacing={1}>
                  <MainSelectSelection
                    value="No restriction"
                    onChange={() => {}}
                    label={"No restriction"}
                    options={[
                      {
                        value: "No restriction",
                        label: `No restriction`,
                      },
                      {
                        value: "No",
                        label: `No`,
                      },
                    ]}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            sx={{ width: "100%" }}
            direction="row"
            justifyContent="flex-end"
          >
            <button onClick={handleSaveExtra} className="applyButton">
              {t("customModal.save")}
            </button>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}

export default OptionalExtras;
