import React from "react";
import { Box, Skeleton, useMediaQuery, useTheme } from "@mui/material";
import Stack from "@mui/material/Stack";

function DatagridPageLoading({ status }: { status?: boolean }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      className={!isMobile ? "container-datagrid-pages" : ""}
      sx={{
        width: { xs: "100%", sm: "auto" },
        margin: "auto",
      }}
      padding={isMobile ? 2 : "0px 40px"}
      pb={isMobile ? 10 : 0}
    >
      <Stack spacing={5} my={isMobile ? 0 : 4}>
        {!status && (
          <Stack spacing={4}>
            <Stack direction="row" justifyContent={"space-between"}>
              <Skeleton
                variant="text"
                width={isMobile ? 100 : 210}
                sx={{ fontSize: "1.5rem" }}
                animation="wave"
              />
              <Skeleton
                variant="rounded"
                width={isMobile ? 100 : 210}
                height={isMobile ? 38 : 48}
                animation="wave"
              />
            </Stack>
            <Stack direction="row" justifyContent={"space-between"}>
              <Skeleton
                variant="rounded"
                width={"100%"}
                height={38}
                animation="wave"
              />
            </Stack>
          </Stack>
        )}
        <Stack>
          <Skeleton
            variant="rounded"
            width={"100%"}
            height={348}
            animation="wave"
          />
        </Stack>
      </Stack>
    </Box>
  );
}

export default DatagridPageLoading;
