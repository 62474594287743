import React from "react";
import {
  Autocomplete,
  TextField,
  Checkbox,
  ListItemText,
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
} from "@mui/material";
import ArrowDown from "../../../../../assets/images/icons/down.svg";
import checked from "../../../../../assets/images/icons/checkedIcon.svg";
import unchecked from "../../../../../assets/images/icons/Checkbox.svg";
import { Chip } from "@mui/material";

interface AssigneeOption {
  label: string;
  value: string;
}

interface AssignerSelectProps {
  options: AssigneeOption[];
  pageType?: string;
  value: AssigneeOption[];
  onChange: (
    event: React.SyntheticEvent<Element, Event>,
    value: AssigneeOption | AssigneeOption[] | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<AssigneeOption> | undefined
  ) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  placeholder?: string;
  multiple?: boolean;
  isSelectedItemsChip?: boolean;
  errorMessage?: string | null;
  disabled?: boolean;
}

const AssignerSelect: React.FC<AssignerSelectProps> = ({
  options,
  value,
  multiple,
  pageType,
  onChange,
  onBlur,
  placeholder = "Select",
  isSelectedItemsChip = false,
  errorMessage = null,
  disabled = false,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const checkedIcon = <img width="16px" height="16px" src={checked} alt="checked" />;
  const uncheckedIcon = <img width="16px" height="16px" src={unchecked} alt="unchecked" />;
  const [open, setOpen] = React.useState(false);

  const renderTags = (value: AssigneeOption[]) => {
    if (isSelectedItemsChip) {
      const maxChipsToShow = isMobile ? 2 : 7;
      const totalChips = value.length;

      if (totalChips === 0) return null;

      const displayedChips = value.slice(0, maxChipsToShow);
      const extraCount =
        totalChips > maxChipsToShow ? `+${totalChips - maxChipsToShow}` : "";

      return (
        <span
          style={{
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {displayedChips.map((option) => (
            <Chip
              key={option.value}
              label={option.label}
              style={{
                backgroundColor: "#3E97FF",
                color: "#FFFFFF",
                marginRight: "5px",
              }}
            />
          ))}
          {extraCount && (
            <Chip
              label={extraCount}
              style={{
                backgroundColor: "#3E97FF",
                color: "#FFFFFF",
              }}
            />
          )}
        </span>
      );
    }

    if (multiple) {
      const maxTagsToShow = 2;
      const totalTags = value.length;

      if (totalTags === 0) return "";

      const displayedTags = value
        .slice(0, maxTagsToShow)
        .map((option) => option.label.split(" ")[0]);

      const extraCount =
        totalTags > maxTagsToShow ? `+${totalTags - maxTagsToShow}` : "";

      return `${displayedTags.join(", ")}${extraCount ? `, ${extraCount}` : ""
        }`;
    }

    return "";
  };

  return (
    <Autocomplete
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      multiple={multiple}
      value={multiple ? value : null}
      onChange={onChange}
      disabled={disabled}
      options={options}
      disableCloseOnSelect
      getOptionLabel={(option) => option?.label || ""}
      renderOption={(props, option, { selected }) => (
        <Box
          component="li"
          {...props}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Checkbox
            icon={uncheckedIcon}
            checkedIcon={checkedIcon}
            checked={selected}
          />
          <ListItemText primary={option.label} />
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            padding: "0 !important",
            "& .MuiAutocomplete-inputRoot": {
              paddingRight: "15px !important",
              backgroundColor: pageType ? "white" : "transparent",
            },
            "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
              borderColor: errorMessage ? "#d32f2f" : "#A1A5B7",
              borderRadius: "6px",
              padding: "0",
              height: pageType ? "48px" : "52px !important",
            },
          }}
          variant="outlined"
          placeholder={placeholder}
          error={!!errorMessage}
          onBlur={onBlur}
          helperText={
            errorMessage && (
              <span
                className="error-message"
                style={{ marginLeft: 0, paddingLeft: 0 }}
              >
                {errorMessage}
              </span>
            )
          }
          InputProps={{
            ...params.InputProps,
            startAdornment: <span>{renderTags(value)}</span>,
            endAdornment: (
              <React.Fragment>
                <img
                  onClick={(event) => {
                    event.stopPropagation();
                    setOpen((prevOpen) => !prevOpen);
                  }}
                  src={ArrowDown}
                  alt="arrow-down"
                  style={{
                    cursor: "pointer",
                    transform: open ? "rotate(180deg)" : "none",
                  }}
                />
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      popupIcon={null}
    />
  );
};

export default AssignerSelect;
