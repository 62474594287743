import { Box, Modal, Stack } from "@mui/material";
import React from "react";
import CloseModalButton from "./CloseModalButton";
import { useTranslation } from "react-i18next";
import CustomButton from "./CustomButton";

interface CustomModalProps {
  open: boolean;
  handleClose: () => void;
  handleSave: () => void;
  title: string;
  children: React.ReactNode;
  showFooter?: boolean;
  showHeader?: boolean;
  description?: string;
  blueButtonText?: string;
  disabled?: boolean;
  maxHeight?: string | number;
}

const CustomModal: React.FC<CustomModalProps> = ({
  open,
  handleClose,
  handleSave,
  title,
  children,
  showFooter = true,
  showHeader = true,
  description,
  blueButtonText = "Save",
  disabled,
  maxHeight = "auto",
}) => {
  const { t } = useTranslation();
  return (
    <Modal open={open} onClose={handleClose}>
      <div onClick={handleClose}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        >
          <Box
            sx={{
              display: "inline-flex",
              flexDirection: "column",
              alignItems: "flex-start",
              padding: "24px",
              gap: "24px",
              backgroundColor: "background.paper",
              background: "#FFF",
              boxShadow: "0px 16px 32px 0px rgba(0, 0, 0, 0.12)",
              borderRadius: "12px",
              minWidth: "480px",
            }}
            onClick={(event) => event.stopPropagation()}
          >
            {showHeader && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  alignSelf: "stretch",
                }}
              >
                <Stack width={"100%"} spacing={1}>
                  <p
                    style={{
                      color: "var(--Gray-Gray-900, #181C32)",
                      fontFamily: "Inter",
                      fontSize: "24px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "24px",
                      letterSpacing: "-0.24px",
                    }}
                  >
                    {title}
                  </p>
                  {description && (
                    <p
                      style={{
                        color: "var(--Previous-600, #7E8299)",
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "20px",
                      }}
                    >
                      {description}
                    </p>
                  )}
                </Stack>
                <CloseModalButton show={true} onClick={handleClose} />
              </Box>
            )}
            {/* Content */}
            <Box
              sx={{
                maxHeight: maxHeight,
                overflowY: "auto",
                overflowX: "hidden",
                width: "100%",
                paddingRight: "8px",
              }}
            >
              {children}
            </Box>
            {/* ------- */}
            {showFooter && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  alignSelf: "stretch",
                }}
              >
                <CustomButton
                  backgroundColor="transparent"
                  color="var(--Gray-Gray-900, #181C32)"
                  onClick={handleClose}
                  buttonText={t("customModal.cancel")}
                  marginTop={0}
                  buttonWidth="auto"
                  textDecoration="underline"
                />
                <CustomButton
                  backgroundColor="#0065E0"
                  color="#FFFFFF"
                  onClick={handleSave}
                  buttonText={blueButtonText}
                  marginTop={0}
                  buttonWidth="auto"
                  disable={disabled}
                />
              </Box>
            )}
          </Box>
        </Box>
      </div>
    </Modal>
  );
};

export default CustomModal;
