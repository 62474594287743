import { Popover, Stack, Button } from "@mui/material";
import { useState } from "react";
import BulkDate from "../../../../../../../assets/images/icons/bulking-date.svg";
import BulkRate from "../../../../../../../assets/images/icons/bulking-rate.svg";
import BulkMinStay from "../../../../../../../assets/images/icons/bulking-in.svg";
import BulkClosedArrival from "../../../../../../../assets/images/icons/bulking-exit.svg";
import BulkRateUpdateModal from "./components/bulk-rate-update-modal";
import BulkMinStayUpdateModal from "./components/bulk-min-stay-update-modal";
import BulkClosedArrivalModal from "./components/bulk-closed-for-arrival-modal";
import BulkClosedDepartureModal from "./components/bulk-closed-for-departure-modal";
import { useTranslation } from "react-i18next";

interface Props {
  open: boolean;
  anchorEl: HTMLButtonElement;
  handleClose: () => void;
}

function BulkUpdateModal(props: Props) {
  const { t } = useTranslation();
  const { open, anchorEl, handleClose } = props;

  const [bulkRateUpdateAnchorEl, setBulkRateUpdateAnchorEl] =
    useState<HTMLButtonElement | null>(null);

  const [bulkMinStayUpdateAnchorEl, setBulkMinStayUpdateAnchorEl] =
    useState<HTMLButtonElement | null>(null);

  const [bulkClosedArrivalAnchorEl, setBulkClosedArrivalAnchorEl] =
    useState<HTMLButtonElement | null>(null);

  const [bulkClosedDepartureAnchorEl, setBulkClosedDepartureAnchorEl] =
    useState<HTMLButtonElement | null>(null);

  // const [bulkUpdateAnchorEl, setBulkUpdateAnchorEl] =
  //   useState<HTMLButtonElement | null>(null);

  const handleBulkRateUpdateClose = () => {
    setBulkRateUpdateAnchorEl(null);
  };

  const handleBulkMinStayUpdateClose = () => {
    setBulkMinStayUpdateAnchorEl(null);
  };

  const handleBulkClosedArrivalClose = () => {
    setBulkClosedArrivalAnchorEl(null);
  };

  const handleBulkClosedDepartureClose = () => {
    setBulkClosedDepartureAnchorEl(null);
  };

  const bulkUpdateModals = [
    {
      title: t("calendarpage.bulkRateUpdate"),
      icon: BulkRate,
      onClick: (e: any) => setBulkRateUpdateAnchorEl(e.currentTarget),
    },
    {
      title: t("calendarpage.bulkUpdateMinLengthOfStay"),
      icon: BulkDate,
      onClick: (e: any) => setBulkMinStayUpdateAnchorEl(e.currentTarget),
    },
    {
      title: t("calendarpage.bulkUpdateClosedForArrival"),
      icon: BulkClosedArrival,
      onClick: (e: any) => setBulkClosedArrivalAnchorEl(e.currentTarget),
    },
    {
      title: t("calendarpage.bulkUpdateClosedForDeparture"),
      icon: BulkMinStay,
      onClick: (e: any) => setBulkClosedDepartureAnchorEl(e.currentTarget),
    },
  ];

  return (
    <>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          " .MuiPaper-root": {
            borderRadius: "12px",
            background: "#FFF",
            padding: " 32px 24px",
            boxShadow: "0px 8px 14px 0px rgba(15, 42, 81, 0.04)",
            minWidth: "360px",
          },
          background: "rgba(53, 48, 48, 0.21)",
        }}
      >
        {bulkUpdateModals.map((item, index) => (
          <Button
            key={index}
            onClick={item.onClick}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              width: "100%",
            }}
          >
            <Stack direction="row" spacing={1}>
              <img src={item.icon} alt="bulk-update-icon" />
              <p className="B-16-25-500">{item.title}</p>
            </Stack>
          </Button>
        ))}
      </Popover>

      {bulkRateUpdateAnchorEl && (
        <BulkRateUpdateModal
          open={Boolean(bulkRateUpdateAnchorEl)}
          anchorEl={bulkRateUpdateAnchorEl}
          handleClose={handleBulkRateUpdateClose}
          modalTitle={t("calendarpage.bulkRateUpdate")}
          buttonText={t("calendarpage.updateAndSyncRates")}
        />
      )}

      {bulkMinStayUpdateAnchorEl && (
        <BulkMinStayUpdateModal
          open={Boolean(bulkMinStayUpdateAnchorEl)}
          anchorEl={bulkMinStayUpdateAnchorEl}
          handleClose={handleBulkMinStayUpdateClose}
          modalTitle={t("calendarpage.bulkMinimumLengthOfStayUpdate")}
          buttonText={t("calendarpage.updateAndSyncLengthOfStay")}
        />
      )}

      {bulkClosedArrivalAnchorEl && (
        <BulkClosedArrivalModal
          open={Boolean(bulkClosedArrivalAnchorEl)}
          anchorEl={bulkClosedArrivalAnchorEl}
          handleClose={handleBulkClosedArrivalClose}
          modalTitle={t("calendarpage.bulkClosedForArrivalUpdate")}
          buttonText={t("calendarpage.updateAndSyncClosedForArrival")}
        />
      )}

      {bulkClosedDepartureAnchorEl && (
        <BulkClosedDepartureModal
          open={Boolean(bulkClosedDepartureAnchorEl)}
          anchorEl={bulkClosedDepartureAnchorEl}
          handleClose={handleBulkClosedDepartureClose}
          modalTitle={t("calendarpage.bulkClosedForDepartureUpdate")}
          buttonText={t("calendarpage.updateAndSyncClosedForDeparture")}
        />
      )}
    </>
  );
}

export default BulkUpdateModal;
