export const dataGridStyles = {
  root: {
    fontFamily: "Inter !important",
    border: "1px solid var(--Gray-Gray-300, #E1E3EA)",
    background: "#FFF",
    "& .css-1essi2g-MuiDataGrid-columnHeaderRow": {
      background: "transparent !important",
    },
    "& .css-1k14hdo .MuiDataGrid-container--top ": {
      background: "transparent !important",
    },
    "& .MuiDataGrid-container--top [role='row'], .MuiDataGrid-container--bottom [role='row']":
    {
      background: "transparent !important",
    },
    borderRadius: "12px !important",
    "& .MuiDataGrid-container": {
      background: "transparent",
    },
    "& .css-1oudwrl::after ": {
      backgroundColor: "transparent",
    },
    "& .css-vubbuv ": {
      fill: "#A1A5B7",
    },

    "& .css-i4bv87-MuiSvgIcon-root ": {
      fill: "#A1A5B7",
      strokeWidth: "1px",
    },

    "& .MuiDataGrid-columnsContainer": {
      background: "transparent",
    },

    "& .MuiDataGrid-columnHeaderTitleContainer": {
      justifyContent: "left !important",
    },
    "& .css-1fczbef-MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer ":
    {
      display: "flex !important",
      justifyContent: "left !important",
    },
    "& .css-1hup4h6-MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer ":
    {
      display: "flex !important",
      justifyContent: "left !important",
    },

    "& .css-16ukuru-MuiDataGrid-root .MuiDataGrid-container--top [role=row]": {
      background: "transparent !important",
    },
    "& .MuiDataGrid-columnHeader": {
      borderBottom: "none",
      borderTopLeftRadius: 6,
      borderTopRightRadius: 6,
    },
    "& .MuiDataGrid-cell": {
      borderBottom: "none",
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      border: "none",
      color: "#3F4254",
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
    },
    "& .MuiDataGrid-main": {
      "& .MuiDataGrid-columnHeaders": {
        borderBottom: "1px solid var(--stroke-colors, #EBEBEB)",
      },
    },
    "& .MuiDatagrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within":
    {
      outline: "none",
    },
  },
};

export const mobileDataGridStyles = {
  root: {
    fontFamily: "Inter !important",
    border: "none",
    background: "transparent",
    "& .css-1essi2g-MuiDataGrid-columnHeaderRow": {
      background: "transparent !important",
    },
    "& .css-1k14hdo .MuiDataGrid-container--top ": {
      background: "transparent !important",
    },
    "& .MuiDataGrid-container--top [role='row'], .MuiDataGrid-container--bottom [role='row']":
    {
      background: "transparent !important",
    },
    "& .MuiDataGrid-container": {
      background: "transparent",
    },
    "& .css-1oudwrl::after ": {
      backgroundColor: "transparent",
    },
    "& .css-vubbuv ": {
      fill: "#A1A5B7",
    },

    "& .css-i4bv87-MuiSvgIcon-root ": {
      fill: "#A1A5B7",
      strokeWidth: "1px",
    },

    "& .MuiDataGrid-columnsContainer": {
      background: "transparent",
    },

    "& .MuiDataGrid-columnHeaderTitleContainer": {
      justifyContent: "left !important",
    },
    "& .css-1fczbef-MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer ":
    {
      display: "flex !important",
      justifyContent: "left !important",
    },
    "& .css-1hup4h6-MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer ":
    {
      display: "flex !important",
      justifyContent: "left !important",
    },

    "& .css-16ukuru-MuiDataGrid-root .MuiDataGrid-container--top [role=row]": {
      background: "transparent !important",
    },
    "& .MuiDataGrid-columnHeader": {
      borderBottom: "none",
      borderTopLeftRadius: 6,
      borderTopRightRadius: 6,
    },
    "& .MuiDataGrid-cell": {
      borderBottom: "none",
      paddingTop: "16px !important",
      paddingBottom: "16px !important",
      height: "auto !important", // Set height to auto for mobile view
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      border: "none",
      color: "#3F4254",
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
    },
    "& .MuiDataGrid-main": {
      "& .MuiDataGrid-columnHeaders": {
        borderBottom: "none",
      },
    },
    "& .MuiDatagrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within":
    {
      outline: "none",
    },
  },
};

export const checkboxStyles = {
  root: {
    "& .MuiIconButton-root": {
      padding: "4px",
      borderRadius: "20px !important",
    },
    "&$checked": {
      color: "#50CD89 !important",
    },
    "& .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,": {
      color: "#50CD89 !important",
      borderRadius: "20px !important",
    },
  },
};
export const inputSectionStyles = {
  formControl: {
    minWidth: 120,
    m: 1,
  },
  detailsMainModal: {
    marginTop: "24px",
    position: "relative",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0px",
  },
  aiTextField: {
    display: "flex",
    width: "480px",
    height: "56px",
    padding: "18px 16px",
    alignItems: "flex-start",
    gap: "10px",
    borderRadius: "6px",
    alignSelf: "stretch",
    color: "var(--Gray-Gray-600, #7e8299)",
  },

  homePageSelect: {
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid var(--Gray-Gray-300, #E1E3EA) !important",
    },
    "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
      border: "1px solid var(--Gray-Gray-300, #E1E3EA) !important",
    },
    ".MuiDataGrid-columnSeparator": {
      display: "none",
    },
    "& .hideRightSeparator > .MuiDataGrid-columnSeparator": {
      display: "none !important",
    },
    "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
    {
      padding: "0px !important",
    },
    "& .css-qiwgdb.css-qiwgdb.css-qiwgdb": {
      padding: "0px !important",
    },
    "& .css-qiwgdb ": {
      padding: "0px !important",
    },
    "&.MuiDataGrid-root": {
      border: "none",
    },

    // minWidth: "100px",
    display: "flex",
    height: "38px",
    padding: "13px 16px",
    alignItems: "center",
    gap: "10px",
    borderRadius: "20px",
    background: "#FFF",

    fontWeight: 500,
    fontFamily: "Inter !important",
    fontSize: "14px",
    // m: 1,
    // border: "1px solid #A1A5B7",
  },
  dateField: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid var(--Gray-Gray-300, #E1E3EA)",
    },

    "&::-webkit-calendar-picker-indicator": {
      display: "none",
      "-webkit-appearance": "none",
    },
    "& .MuiOutlinedInput-root": {
      display: "flex",
      width: "135.025px",
      borderRadius: "6px",
      background: "#FFF",
      height: "40px",
      // padding: "8px 12px",
      // gap: "12px",
      alignItems: "center",
      fontSize: "12px",
      fontWeight: 500,
      fontFamily: "Inter !important",
    },
  },

  dateSelect: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid var(--Gray-Gray-300, #E1E3EA)",
    },
    "& .MuiSelect-select.MuiSelect-outlined": {
      padding: "16px 38px 16px 16px !important",
    },
    "& .MuiSelect-icon.MuiSelect-iconOutlined": {
      right: "16px",
      top: "auto",
    },

    // minWidth: "100px",
    display: "flex",
    height: "38px",
    // padding: "13px 16px",
    alignItems: "center",
    gap: "10px",
    borderRadius: "6px",
    background: "#FFF",
    fontWeight: 500,
    fontFamily: "Inter !important",
    fontSize: "14px",
  },

  selectWithTextFieldHeight: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #A1A5B7",
      borderRadius: "6px",
      padding: "0",
    },
    "& .MuiSelect-select.MuiSelect-outlined": {
      padding: "16px 38px 16px 16px !important",
    },
    "& .MuiSelect-icon.MuiSelect-iconOutlined": {
      right: "16px",
      top: "auto",
    },

    // minWidth: "100px",
    display: "flex",
    height: "50.5px",
    // padding: "13px 16px",
    alignItems: "center",
    gap: "10px",
    borderRadius: "6px",
    background: "#FFF",
    fontWeight: 500,
    fontFamily: "Inter !important",
    fontSize: "14px",
  },

  selectWithParagraphHeight: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #A1A5B7",
      borderRadius: "6px",
      padding: "0",
    },
    "& .MuiSelect-select.MuiSelect-outlined": {
      padding: "16px 38px 16px 16px !important",
    },
    "& .MuiSelect-icon.MuiSelect-iconOutlined": {
      right: "16px",
      top: "auto",
    },

    // minWidth: "100px",
    display: "flex",
    height: "40px",
    // padding: "13px 16px",
    alignItems: "center",
    gap: "10px",
    borderRadius: "6px",
    background: "#FFF",
    fontWeight: 500,
    fontFamily: "Inter !important",
    fontSize: "14px",
  },

  textField: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid var(--Gray-Gray-300, #E1E3EA)",
    },
    "& .MuiInputBase-input": {
      cursor: "pointer",
    },
    "& .MuiOutlinedInput-root": {
      display: "flex",
      width: "148.025px",
      borderRadius: "20px",
      background: "#FFF",
      height: "40px",
      padding: "8px 12px",
      gap: "12px",
      alignItems: "center",
      fontSize: "12px",
      fontFamily: "Inter !important",
      cursor: "pointer",
    },
  },
  select: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid var(--Gray-Gray-300, #E1E3EA)",
    },
    "& .MuiSelect-select.MuiSelect-outlined": {
      padding: "16px 38px 16px 16px !important",
    },
    "& .MuiSelect-icon.MuiSelect-iconOutlined": {
      right: "16px",
      top: "auto",
    },
    display: "flex",
    height: "38px",
    alignItems: "center",
    borderRadius: "20px",
    background: "#FFF",
    color: "var(--Gray-Gray-800, #3F4254)",
    fontWeight: 400,
    fontFamily: "Inter !important",
    fontSize: "14px",
    // m: 1,
    // border: "1px solid #A1A5B7",
  },
  chatBot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid var(--Gray-Gray-300, #E1E3EA)",
    },
    "& .MuiSelect-select.MuiSelect-outlined": {
      padding: "16px 38px 16px 16px !important",
    },
    "& .MuiSelect-icon.MuiSelect-iconOutlined": {
      right: "16px",
      top: "auto",
    },
    display: "flex",
    height: "38px",
    width: "100% !important",
    alignItems: "center",
    borderRadius: "6px",
    background: "#FFF",
    color: "var(--Gray-Gray-800, #3F4254)",
    fontWeight: 400,
    fontFamily: "Inter !important",
    fontSize: "14px",
    // m: 1,
    // border: "1px solid #A1A5B7",
  },
  stack: {
    direction: "row",
    justifyContent: "space-between",
    alignItems: "center",
    spacing: 2,
  },
};

export const inputCoreStyles = {
  formControl: {
    minWidth: 120,
    m: 1,
  },
  datePicker: {
    "& .MuiInputBaseroot": {},
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #A1A5B7",
    },
    "& .MuiOutlinedInput-root": {
      display: "flex",
      backgroundColor: "white",
      "flex-direction": "row-reverse",
      width: "100%",
      borderRadius: "6px",
      height: "40px",
      padding: "8px 1px",
      gap: "12px",
      alignItems: "center",
      fontSize: "12px",
      // fontWeight: 600,
      fontFamily: "Inter !important",
    },
  },
  textField: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #A1A5B7",
    },
    "& .MuiOutlinedInput-root": {
      display: "flex",
      // width: "138.025px",
      borderRadius: "6px",
      height: "40px",
      padding: "8px 1px",
      gap: "12px",
      alignItems: "center",
      fontSize: "12px",
      fontWeight: 600,
      fontFamily: "Inter !important",
    },
  },
  textFieldFilter: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #E1E3EA",
    },
    "& .MuiOutlinedInput-root": {
      display: "flex",
      // width: "138.025px",
      borderRadius: "6px",
      height: "40px",
      padding: "8px 1px",
      gap: "12px",
      alignItems: "center",
      fontSize: "12px",
      fontWeight: 600,
      fontFamily: "Inter !important",
    },
  },
  select: {
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #A1A5B7 !important",
    },
    "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
      border: "1px solid #A1A5B7 !important",
    },
    ".MuiDataGrid-columnSeparator": {
      display: "none",
    },
    "& .hideRightSeparator > .MuiDataGrid-columnSeparator": {
      display: "none !important",
    },
    "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
    {
      padding: "0px !important",
    },
    "& .css-qiwgdb.css-qiwgdb.css-qiwgdb": {
      padding: "0px !important",
    },
    "& .css-qiwgdb ": {
      padding: "0px !important",
    },
    "&.MuiDataGrid-root": {
      border: "none",
    },

    // minWidth: "100px",
    display: "flex",
    height: "38px",
    padding: "13px 12px",
    alignItem: "center",
    gap: "10px",
    borderRadius: "6px",

    fontWeight: 600,
    fontFamily: "Inter !important",
    fontSize: "12px",
    // m: 1,
    // border: "1px solid #A1A5B7",
  },
  stack: {
    direction: "row",
    justifyContent: "space-between",
    alignItems: "center",
    spacing: 2,
  },
};
