import React from "react";
import {
  Box,
  Stack,
  Typography,
} from "@mui/material";
import { Dayjs } from "dayjs";
import { generateDates } from "./helper";
import Accordion from "../accordion";

interface Props {
  startDate: Dayjs;
  hiddenRows: string[];
  propertyList: any;
}

const Table: React.FC<Props> = ({
  startDate,
  hiddenRows,
  propertyList,
}) => {
  // const [createBookingModal, setCreateBookingModal] =
  //   useState<HTMLButtonElement | null>(null);

  // const [isSelecting, setIsSelecting] = useState(false);
  // const [selectionStart, setSelectionStart] = useState<number | null>(null);
  // const [selectionEnd, setSelectionEnd] = useState<number | null>(null);

  // const handleCreateBookingClose = () => {
  //   setCreateBookingModal(null);
  //   setSelectionEnd(null);
  //   setSelectionStart(null);
  // };



  // const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
  //   e.stopPropagation();
  //   if (lastBoxRef.current) {
  //     setCreateBookingModal(lastBoxRef.current);
  //   }
  // };



  // const handleMouseDown = (index: number) => {
  //   setIsSelecting(true);
  //   setSelectionStart(index + 1);
  //   setSelectionEnd(index + 1);
  // };

  // const handleMouseOver = (index: number) => {
  //   if (isSelecting) {
  //     setSelectionEnd(index + 1);
  //   }
  // };

  // const handleMouseUp = () => {
  //   setIsSelecting(false);
  //   if (lastBoxRef.current) {
  //     setCreateBookingModal(lastBoxRef.current);
  //   }
  // };
  // useEffect(() => {
  //   if (isSelecting) {
  //     window.addEventListener("mouseup", handleMouseUp);
  //   } else {
  //     window.removeEventListener("mouseup", handleMouseUp);
  //   }
  //   return () => {
  //     window.removeEventListener("mouseup", handleMouseUp);
  //   };
  // }, [isSelecting]);

  return (
    <>
      <Box>
        <Stack direction={"row"}>
          <Box sx={{ width: "319px", flexShrink: "0" }}></Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(14,1fr)",
              width: "100%",
            }}
          >
            {React.Children.toArray(
              generateDates(startDate).map((date) => {
                const isWeekend = date.day() === 0 || date.day() === 6;
                const today = date.isSame(startDate, "day");
                return (
                  <Box
                    sx={{
                      textAlign: "center",
                      background: today ? "#222" : "",
                      color: today ? "#FFFFFF" : "#222222",
                      margin: "8px 18px",
                      borderRadius: "8px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: isWeekend ? "700" : "400",
                        color: "currentcolor",
                      }}
                    >
                      {date.format("dd")}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: isWeekend ? "700" : "400",
                        color: "currentcolor",
                      }}
                    >
                      {date.format("DD")}
                    </Typography>
                  </Box>
                );
              })
            )}
          </Box>
        </Stack>
        {React.Children.toArray(propertyList.map((property: any) => <Accordion hiddenRows={hiddenRows} property={property} startDate={startDate} />))}
      </Box>

      {/* {createBookingModal && (
        <CreateBookingModal
          open={Boolean(createBookingModal)}
          anchorEl={createBookingModal}
          handleClose={handleCreateBookingClose}
          start={startDate.add(selectionStart ? selectionStart - 1 : 0, "day")}
          end={startDate.add(selectionEnd ? selectionEnd - 1 : 0, "day")}
        />
      )} */}

    </>
  );
};

export default Table;
