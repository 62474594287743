import { Stack, useMediaQuery, useTheme } from "@mui/material";
import { useState } from "react";
import Header from "./components/header";
import Table from "./components/table";
import { useQuery } from "react-query";
import dayjs, { Dayjs } from "dayjs";
import LoadingWrapper from "../../../components/shared-components/LoadingWrapper";
import SkeletonCalendar from "./components/skeleton-calendar";
import NotFoundPage from "../error/NotFoundPage";
import axios from "axios";
import { BASE_API_URL } from "../../../api";

function Calendar() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [startDate, setStartDate] = useState<Dayjs>(dayjs());
  const [hiddenRows, setHiddenRows] = useState<string[]>([
    "Rate",
    "MinStay",
    "MaxStay",
  ]);


  const {
    data: propertyList,
    isLoading: propertyListLoading,
    error: propertyError,
  } = useQuery(["propertyList"], async () => {
    const res = await axios.post(`${BASE_API_URL}/rateplan/restriction/list`, {
      Limit: 3,
      Page: 1,
    });
    return res?.data?.Body;
  });
  if (propertyError) {
    return <NotFoundPage />;
  }
  return (
    // TODO: Remove this line  position="relative" top="77px"
    <Stack gap={"32px"} marginTop={"52px"} position="relative" top="77px">
      {isMobile ? (
        "mobile"
      ) : (
        <>
          {" "}
          <Header
            startDate={startDate}
            setStartDate={setStartDate}
            hiddenRows={hiddenRows}
            setHiddenRows={setHiddenRows}
          />
          <LoadingWrapper
            loading={propertyListLoading}
            component={<SkeletonCalendar />}
          >
            <Table
              startDate={startDate}
              hiddenRows={hiddenRows}
              propertyList={propertyList}
            />
          </LoadingWrapper>
        </>
      )}
    </Stack>
  );
}

export default Calendar;
