import {
  FormControl,
  RadioGroup,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CustomRadioButton from "../../../../../../components/core-components/inputs/RadioButton/CustomRadioButton";
import React from "react";
import listedicon from "../../../../../../assets/images/icons/listedIcon.svg";
import unlistedIcon from "../../../../../../assets/images/icons/unlistedIcon.svg";
import deleteIcon from "../../../../../../assets/images/icons/deleteListedIcon.svg";
import { useTranslation } from "react-i18next";

interface ListingStatusProps {
  showTitle: boolean;
}

interface dataTypes {
  label: string | JSX.Element;
  subtitle: string;
  value: string;
  icon?: string | JSX.Element;
}

const Listedtitle = ({ icon, title }: any) => {
  return (
    <Stack
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "4px",
      }}
    >
      <img src={icon} alt="icon" />
      <span>{title}</span>
    </Stack>
  );
};

function ListingStatus({ showTitle }: ListingStatusProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const data: dataTypes[] = [
    {
      label: (
        <Listedtitle
          icon={unlistedIcon}
          title={`${t("listingDetails.unlisted")}`}
        />
      ),
      subtitle: t("listingpage.removeListingDescription"),
      value: "unlisted",
      icon: unlistedIcon,
    },
    {
      label: (
        <Listedtitle
          icon={listedicon}
          x
          title={`${t("listingDetails.listed")}`}
        />
      ),
      subtitle: t("listingpage.publishedDescription"),
      value: "listed",
      icon: listedicon,
    },
    {
      label: (
        <Listedtitle
          icon={deleteIcon}
          title={`${t("listingDetails.delete")}`}
        />
      ),
      subtitle: t("listingpage.deleteListingDescription"),
      value: "delete",
      icon: deleteIcon,
    },
  ];
  const [value, setValue] = React.useState("listed");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <Stack sx={{ width: "100%" }} mb={isMobile ? 4 : 0} spacing={5}>
      {showTitle && (
        <p className="listing-details-layout-box-content-title">
          {t("listingDetails.listingstatus")}
        </p>
      )}
      <FormControl fullWidth component="fieldset">
        <RadioGroup
          aria-label="listing-status"
          name="listing-status"
          value={value}
          onChange={handleChange}
        >
          {data.map((item) => (
            <CustomRadioButton
              subtitle={item.subtitle}
              label={item.label}
              value={item.value}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Stack>
  );
}

export default ListingStatus;
