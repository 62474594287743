import React, { useState } from "react";
import HeaderTitle from "../detail-header/HeaderTitle";
import MainTextField from "../../../../../../components/core-components/inputs/TextField/MainTextField";
import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";

function InternalListingNumber() {
  const [data, setData] = useState("");
  const { t } = useTranslation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData(e.target.value);
  };

  return (
    <>
      <Stack spacing={3}>
        <HeaderTitle title={`${t("listingDetails.internalreference")}`} />
        <MainTextField
          onChange={handleChange}
          value={data}
          placeholder="YFRRCC767BGG"
        />
      </Stack>
    </>
  );
}

export default InternalListingNumber;
