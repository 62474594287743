import React from "react";
import { Stack } from "@mui/material";
import MessagesSearchTextfield from "../../../../../../../../../components/core-components/inputs/TextField/MessagesSearchTextfield";
import CustomCheckbox from "../../../../../../../../../components/core-components/inputs/Checkbox/CustomCheckbox";
import ListingRow from "../listing-row";
import ListingImage from "../../../../../../../../../assets/images/image31.png";
import { useTranslation } from "react-i18next";

function ListingArea() {
  const { t } = useTranslation();
  const listings = [
    { image: ListingImage, title: "Tekgul Apart Baku near" },
    { image: ListingImage, title: "Tekgul Apart Baku near" },
    { image: ListingImage, title: "Tekgul Apart Baku near" },
    { image: ListingImage, title: "Tekgul Apart Baku near" },
    { image: ListingImage, title: "Tekgul Apart Baku near" },
    { image: ListingImage, title: "Tekgul Apart Baku near" },
  ];

  return (
    <Stack
      spacing={2}
      mt={2}
      sx={{
        paddingBottom: "24px",
        borderBottom: "1px solid var(--stroke-colors, #EBEBEB)",
      }}
    >
      <Stack sx={{ width: "100%" }}>
        <p className="B-14" style={{ color: "#181C32" }}>
          {t("calendarpage.listing")}
        </p>
        <p className="B-12-16-400">
          {t("calendarpage.selectOneOrMoreListingsToUpdateRates")}
        </p>
      </Stack>
      <MessagesSearchTextfield showEndAdornment={false} />
      <Stack spacing={1} sx={{ width: "100%" }}>
        <Stack direction="row" spacing={1} sx={{ width: "100%" }}>
          <CustomCheckbox
            position="right"
            label={""}
            inputName="dateFilter"
            onChange={() => {}}
          />
          <p className="B-14-24-500" style={{ color: "#3F4254" }}>
            {t("calendarpage.selectAllListings")}
          </p>
        </Stack>
        <Stack spacing={2} alignItems={"center"} sx={{ width: "100%" }}>
          {[...Array(3)].map((_, rowIndex) => (
            <Stack
              key={rowIndex}
              direction="row"
              spacing={3}
              sx={{ width: "100%" }}
            >
              {[...Array(2)].map((_, colIndex) => {
                const listingIndex = rowIndex * 2 + colIndex;
                if (listingIndex >= listings.length) return null;
                return (
                  <ListingRow key={colIndex} listing={listings[listingIndex]} />
                );
              })}
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
}

export default ListingArea;
