import axios from "axios";
import { BASE_API_URL } from ".";

const API_BASE_URL = BASE_API_URL

export interface GetAutoPilotDataApiParams {
  accessToken: string;
  customerId: string;
}


export const getAllAutopiloDataApi = async ({
  accessToken,
  customerId
}: GetAutoPilotDataApiParams) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/CustomerAutoPilot/GetAll`,
      {
        params: {
          customerId
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return response
  } catch (error: any) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch the message."
    );
  };
}
export interface PutUpdateAutopilotDataApiParams {
  accessToken: string,
  autopilotData: {
    id: string;
    customerId: string;
    reservationStatus: any;
    workingMode: any;
    isActive: boolean;
    properties: any;
    weekDays: any;
    startTime: any;
    endTime: any;
  }
}

export const putUpdateAutopilotDataApi = async ({
  accessToken,
  autopilotData
}: PutUpdateAutopilotDataApiParams) => {
  try {
    const response = await axios.put(
      `${API_BASE_URL}/CustomerAutoPilot/Update`,
      autopilotData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return response
  } catch (error: any) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch the message."
    );
  };
}

export interface PostNewAutopilotDataApiParams {
  accessToken: string,
  autopilotData: {
    customerId: string;
    reservationStatus: any;
    workingMode: any;
    isActive: boolean;
    properties: any;
    weekDays: any;
    startTime: any;
    endTime: any;
  }
}

export const postNewAutopilotDataApi = async ({
  accessToken,
  autopilotData
}: PostNewAutopilotDataApiParams) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/CustomerAutoPilot/Add`,
      autopilotData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return response
  } catch (error: any) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch the message."
    );
  };
}


export interface DeleteAutopilotDataApiParams {
  accessToken: string,
  autopilotId: string
}

export const deleteAutopilotDataApi = async ({
  accessToken,
  autopilotId
}: DeleteAutopilotDataApiParams) => {
  try {
    const response = await axios.delete(
      `${API_BASE_URL}/CustomerAutoPilot/Delete?`,
      {
        params: { Id: autopilotId },
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return response
  } catch (error: any) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch the message."
    );
  };
}
