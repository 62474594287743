import { Progress } from "@mantine/core";
import { Box, Stack } from "@mui/material";
import { useSelector } from "react-redux";
import mainImage from "../../../../assets/images/steptwotwo.svg";
import { RootState } from "../../../../store/store";


const SyncProperties = () => {
  const syncProgress = useSelector((state: RootState) => state.onboard.syncProgress)
  return (
    <>
      <Stack direction={"row"} spacing={1} alignItems={"center"} width={"100%"}>
        <Box sx={{ minWidth: 130 }}>
          <span className="TextSmMedium">Listings are loading</span>
        </Box>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ width: "100%", mr: 1 }}>
              <Progress radius="lg" size="md" color="blue.5" value={syncProgress} animated transitionDuration={200} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
              <span className="TextSmMedium">{syncProgress + "%"}</span>
            </Box>
          </Box>
        </Box>
      </Stack>
      <Stack
        flexDirection={"row"}
        alignItems={"center"}
        mt={4}
        justifyContent={"center"}
        alignContent={"center"}
        alignSelf={"center"}
      >
        <Stack spacing={2}>
          <p className="subtitleOfStep">Step 1</p>
          <p className="titleOfStep">
            We are analysing all your conversations and property data
          </p>
          <p
            className="secondaryDescription"
            style={{
              maxWidth: "450px",
              wordWrap: "break-word",
              lineHeight: "1.5",
            }}
          >
            Our AI learns from your past and future conversations to be able to
            answer at 99% accuracy
          </p>
        </Stack>
        <Box>
          <img alt="onboarding" className="onboardingImage" src={mainImage} />
        </Box>
      </Stack>
    </>
  );
}

export default SyncProperties;
