import {
  Chip,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ArrowRight from "../../../assets/images/icons/arrow-right.svg";
import { useAuth0 } from "@auth0/auth0-react";

function Menu() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const { logout } = useAuth0();
  const { user } = useAuth0();

  // const settingsOptions = [
  //   { path: "/settings/user-management", name: "User Management" },
  //   { path: "/settings/booking-booster", name: "Booking Booster" },
  //   { path: "/settings/retargeting", name: "Retargeting" },
  //   { path: "/settings/inquiry-win-back", name: "Inquiry Win Back" },
  //   { path: "/settings/autopilot", name: "Autopilot" },
  //   { path: "/settings/early-check-in-upsell", name: "Early Check-in Upsell" },
  //   { path: "/settings/late-check-out-upsell", name: "Late Check-out Upsell" },
  //   { path: "/settings/notification-settings", name: "Notification Settings" },
  //   { path: "/settings/pms-and-channels", name: "PMS & Channels" },
  //   { path: "/settings/payments", name: "Payments" },
  //   { path: "/settings/booking-site", name: "Booking Site" },
  //   { path: "/settings/promo-codes", name: "Promo Codes" },
  // ];

  // const location = useLocation();
  const navigate = useNavigate();
  //const [settingsExpanded, setSettingsExpanded] = useState(false);

  // const handleSettingsClick = () => {
  //   setSettingsExpanded(!settingsExpanded);
  // };

  // const handleSubheadingClick = (route: string) => {
  //   navigate(route);
  // };

  const handleProfileNavigation = () => {
    navigate("/profile");
  };

  return (
    <Stack
      spacing={2}
      width={"100%"}
      sx={{
        position: "fixed",
        width: "100%",
        margin: "auto",
        overflow: "auto",
        backgroundColor: "#fff",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
      height={isMobile ? "100%" : "calc(100vh - 77px)"}
      padding={"16px 16px 200px 16px"}
    >
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        onClick={handleProfileNavigation}
      >
        <Stack
          direction={"row"}
          spacing={1}
          padding={"16px 0px"}
          sx={{ borderBottom: "1px solid var(--stroke-colors, #EBEBEB)" }}
        >
          <Stack
            spacing={1}
            alignItems={"flex-start"}
            justifyContent={"center"}
          >
            <p className="B-16-16-600">{user?.name}</p>
            <p className="B-14-20-400">{user?.email}</p>
          </Stack>
          <Stack alignSelf={"flex-end"}>
            <Chip
              label="Admin"
              className="B-12-12-600"
              sx={{
                height: "26px",
                padding: "7px",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "4px",
                background: "var(--Gray-Gray-300, #E1E3EA)",
              }}
            />
          </Stack>
        </Stack>
        <img
          src={ArrowRight}
          style={{ width: "10px" }}
          alt="go-to-profile"
          onClick={handleProfileNavigation}
        />
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
        pb={1}
        onClick={() => {
          navigate("/reservations");
        }}
      >
        <Typography
          className="B-16-16-600"
          sx={{ color: "#181C32", paddingLeft: "0" }}
        >
          {t("header.reservations")}
        </Typography>
        <img src={ArrowRight} alt="Menu" />
      </Stack>
      {/* <Stack spacing={2}>
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
          pb={1}
          onClick={handleSettingsClick}
        >
          <Typography
            className="B-16-16-600"
            sx={{ color: "#181C32", paddingLeft: "0" }}
          >
            {t("header.settings")}
          </Typography>
          <img
            src={settingsExpanded ? LinearArrowDown : ArrowRight}
            alt="Menu"
          />
        </Stack>
        {settingsExpanded && (
          <Stack spacing={1} alignItems={"flex-start"}>
            {settingsOptions.map((option) => (
              <Button
                onClick={() => handleSubheadingClick(option.path)}
                sx={{
                  color: "#181C32",
                  padding: "8px",
                  width: "100%",
                  justifyContent: "flex-start",
                  background:
                    location.pathname === option.path
                      ? "var(--Primary-Primary-Light, #EEF6FF)"
                      : "none",
                  borderRadius: "var(--radius-sm, 6px)",
                }}
              >
                {option.name}
              </Button>
            ))}
          </Stack>
        )}
      </Stack> */}
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
        pb={1}
        onClick={() => {
          logout({
            logoutParams: {
              returnTo: window.location.origin,
            },
          });
        }}
      >
        <Typography
          className="B-16-16-600"
          sx={{ color: "#181C32", paddingLeft: "0" }}
        >
          Log Out
        </Typography>
        <img src={ArrowRight} alt="Menu" />
      </Stack>
    </Stack>
  );
}

export default Menu;
