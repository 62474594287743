import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { Add } from "@mui/icons-material";
import CustomSwitch from "../../../../components/shared-components/CustomSwitch";

// create interface for HeaderTitleProps
interface HeaderTitleProps {
  title: string;
  subtitle: any;
  rightElement?: boolean;
  onclick?: () => void;
  rightElementButtonText?: string;
  isSwitchShown?: boolean;
  switchChecked?: boolean;
  onSwitchChange?: () => void;
}

const HeaderTitle: React.FC<HeaderTitleProps> = ({
  title,
  subtitle,
  rightElement = false,
  onclick,
  rightElementButtonText,
  isSwitchShown = false,
  switchChecked = true,
  onSwitchChange,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: "100%" }}
        maxWidth={"812px"}
      >
        <Box
          className="acc-users-box-header-left"
          maxWidth={"812px"}
          width={"100%"}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <p
              className={
                isMobile
                  ? "acc-users-box-header-title B-18-18-600"
                  : "acc-users-box-header-title H-24"
              }
            >
              {title}
            </p>
            {isSwitchShown && (
              <CustomSwitch
                checked={switchChecked}
                onChange={
                  onSwitchChange as (
                    event: React.ChangeEvent<HTMLInputElement>
                  ) => void
                }
              />
            )}
          </Stack>
          <p
            className={
              isMobile
                ? "acc-users-box-header-subtitle B-12-16-400"
                : "acc-users-box-header-subtitle B-14-20-400"
            }
          >
            {subtitle}
          </p>
        </Box>
        {rightElement && (
          <Box className="acc-users-box-header-right">
            <button
              className="buttonLg"
              aria-label="add listing"
              onClick={onclick}
              style={{ width: "167px" }}
            >
              <Add />
              <p
                className={
                  isMobile ? "buttonText B-13-13-600" : "buttonText B-16-16-600"
                }
                style={{ color: "#fff" }}
              >
                {rightElementButtonText}
              </p>
            </button>
          </Box>
        )}
      </Box>
    </>
  );
};

export default HeaderTitle;
