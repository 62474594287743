import {
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CustomModalMobile from "../../../../../components/shared-components/CustomModalMobile";
import CustomModal from "../../../../../components/shared-components/CustomModal";
import MainSelectSelection from "../../../../../components/core-components/inputs/Select/MainSelectSelection";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CustomDatePicker from "../../../../../components/core-components/inputs/DatePicker/CustomDatePicker";
import MainTextField from "../../../../../components/core-components/inputs/TextField/MainTextField";

interface SpecialOfferModalProps {
  open: boolean;
  handleClose: () => void;
  handleSave: () => void;
}

const SpecialOfferModal: React.FC<SpecialOfferModalProps> = ({
  open,
  handleClose,
  handleSave,
}) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"));
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedGuestValue, setSelectedGuestValue] = useState("");

  const modalContent = (
    <>
      <Stack
        gap={"12px"}
        alignItems={"flex-start"}
        alignSelf={"stretch"}
        width={"100%"}
        minWidth={isMobile ? "100%" : "680px"}
      >
        <Stack spacing={1} alignItems={"flex-start"} width={"100%"}>
          <span className="B-14-20-500">Listing</span>
          <Stack width={"100%"}>
            <MainSelectSelection
              value={selectedValue}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                setSelectedValue(event.target.value as string)
              }
              label={"Select a listing"}
              options={[
                {
                  value: "Listing 1",
                  label: `Listing 1`,
                },
                {
                  value: "Listing 2",
                  label: `Listing 2`,
                },
              ]}
            />
          </Stack>
        </Stack>
        <Stack spacing={1} alignItems={"flex-start"} width={"100%"}>
          <span className="B-14-20-500">Check-in and Check-out dates</span>
          <Stack
            direction="row"
            spacing={1}
            alignItems={"center"}
            sx={{ width: "100%" }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <CustomDatePicker label="Check-in" name="check_in" />
            </LocalizationProvider>
            <p className="B-14-24-500">{t("calendarpage.to")}</p>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <CustomDatePicker label="Check-out" name="check_out" />
            </LocalizationProvider>
          </Stack>
        </Stack>
        <Stack spacing={1} alignItems={"flex-start"} width={"100%"}>
          <span className="B-14-20-500">Number of guests</span>
          <Stack width={"100%"}>
            <MainSelectSelection
              value={selectedGuestValue}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                setSelectedGuestValue(event.target.value as string)
              }
              label={"Select guest number"}
              options={[
                {
                  value: "1",
                  label: `1`,
                },
                {
                  value: "2",
                  label: `2`,
                },
                {
                  value: "3",
                  label: `3`,
                },
              ]}
            />
          </Stack>
        </Stack>
        <Stack spacing={1} alignItems={"flex-start"} width={"100%"}>
          <span className="B-14-20-500">Subtotal</span>
          <MainTextField
            title=""
            onChange={() => { }}
            value={""}
            placeholder="0$"
          />
          <p className="B-14-20-400">
            Enter a subtotal that includes any cleaning or extra guest fees.
            This won’t include service fees or applicable taxes.
          </p>
        </Stack>
        <Stack spacing={1} alignItems={"flex-end"} width={"100%"} mt={2}>
          <button
            onClick={handleSave}
            style={{
              display: "flex",
              height: "48px",
              padding: "12px 16px",
              justifyContent: "center",
              gap: "8px",
              alignItems: "center",
              cursor: "pointer",
              border: "none",
              borderRadius: "50px",
              background: "var(--Blue-light, #0065E0)",
              fontFamily: "Inter",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "20px" /* 107.692% */,
              color: "var(--white-dark-white, #FFF)",
            }}
          >
            Send special offer
          </button>
        </Stack>
      </Stack>
    </>
  );

  return isMobile ? (
    <CustomModalMobile
      open={open}
      handleClose={handleClose}
      handleSave={handleSave}
      title="Send a special offer"
      showFooter={false}
    >
      <Stack spacing={2} sx={{ width: "100%" }} pb={8}>
        {modalContent}
      </Stack>
    </CustomModalMobile>
  ) : (
    <CustomModal
      open={open}
      handleClose={handleClose}
      handleSave={handleSave}
      title="Send a special offer"
      showFooter={false}
    >
      {modalContent}
    </CustomModal>
  );
};

export default SpecialOfferModal;
