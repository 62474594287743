import { Stack } from "@mui/material";
import { useRef, useEffect, MouseEvent } from "react";

interface UseDragScrollProps {
  onScrollStart?: () => void;
  onScrollEnd?: () => void;
}

const useDragScroll = ({
  onScrollStart,
  onScrollEnd,
}: UseDragScrollProps = {}) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const isDragging = useRef(false);
  const startX = useRef(0);
  const scrollLeft = useRef(0);

  useEffect(() => {
    const element = scrollRef.current;
    if (!element) return;

    const onMouseDown = (e: MouseEvent) => {
      isDragging.current = true;
      startX.current = e.pageX - element.offsetLeft;
      scrollLeft.current = element.scrollLeft;
      element.style.cursor = "grabbing";
      element.style.userSelect = "none";
      onScrollStart?.();
    };

    const onMouseUp = () => {
      isDragging.current = false;
      element.style.cursor = "grab";
      element.style.userSelect = "";
      onScrollEnd?.();
    };

    const onMouseLeave = () => {
      isDragging.current = false;
      element.style.cursor = "grab";
      element.style.userSelect = "";
      onScrollEnd?.();
    };

    const onMouseMove = (e: MouseEvent) => {
      if (!isDragging.current) return;
      e.preventDefault();
      const x = e.pageX - element.offsetLeft;
      const walk = (x - startX.current) * 1.5;
      element.scrollLeft = scrollLeft.current - walk;
    };

    element.addEventListener("mousedown", onMouseDown as any);
    element.addEventListener("mouseup", onMouseUp);
    element.addEventListener("mouseleave", onMouseLeave);
    element.addEventListener("mousemove", onMouseMove as any);

    return () => {
      element.removeEventListener("mousedown", onMouseDown as any);
      element.removeEventListener("mouseup", onMouseUp);
      element.removeEventListener("mouseleave", onMouseLeave);
      element.removeEventListener("mousemove", onMouseMove as any);
    };
  }, [onScrollStart, onScrollEnd]);

  return scrollRef;
};

const ScrollableTabsStack: React.FC<{
  children: React.ReactNode;
  className?: string;
}> = ({ children, className }) => {
  const scrollRef = useDragScroll();

  return (
    <Stack
      ref={scrollRef}
      alignItems="center"
      gap="10px"
      alignSelf="stretch"
      direction="row"
      width="100%"
      sx={{
        overflowY: "hidden",
        overflowX: "auto",
        height: "40px",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        paddingRight: "20px",
        cursor: "grab",
        "&:active": {
          cursor: "grabbing",
        },
      }}
      className={className}
    >
      {children}
    </Stack>
  );
};

export { ScrollableTabsStack, useDragScroll };
