import {
  Box,
} from "@mui/material";
import TitleBoxH3 from "./TitleBoxH3";
import { Fragment, useState } from "react";
import ListingDetailsEditingBox from "./ListingDetailsEditingBox";
import ModalContainer from "../../views/private/listing/detail/components/modals/ModalContainer";
import ArrowDownSvg from "../../assets/images/icons/linear-arrow-down.svg";
import { useTranslation } from "react-i18next";

interface DataType {
  title: string;
  isSelect: boolean;
  subtitle: string;
  width?: string;
  component: React.ReactNode;
  quickSelect?: boolean;
  subMenu?: boolean;
  onClick?: () => void;
  fontWeight?: number;
  showComponent?: boolean;
}
interface InputArea {
  onClick: () => void;
  data: DataType[];
  title: string;
  id: string;
  width?: string;
}
function DetailsInputArea({ data, title, id }: InputArea) {
  const { t } = useTranslation();
  const [editingTitles, setEditingTitles] = useState<string[]>([]);
  const [modalOpen, setModalOpen] = useState<{ [key: string]: boolean }>({});

  const handleEditClick = (title: string, type: string) => {
    if (type === "select") {
      setEditingTitles((prev) => [...prev, title]);
      setModalOpen((prev) => ({ ...prev, [title]: true }));
    } else {
      setEditingTitles((prev) => [...prev, title]);
    }
  };

  const handleCloseEditing = (title: string) => {
    setEditingTitles((prev) => prev.filter((t) => t !== title));
    setModalOpen((prev) => ({ ...prev, [title]: false }));
  };

  return (
    <>
      <Box className="listing-details-layout-box-content-box" id={id}>
        {title && (
          <p className="listing-details-layout-box-content-title">{title}</p>
        )}
        <Box className="listing-details-layout-box-content">
          {data &&
            data.map((item, i) => (
              <Fragment key={i}>
                {item.quickSelect ? (
                  <>
                    <Box
                      component={"form"}
                      className="listing-details-layout-box-content"
                    >
                      <TitleBoxH3 title={item.title} subtitle={item.subtitle}>
                        {item.component}
                      </TitleBoxH3>
                    </Box>
                  </>
                ) : item.isSelect ? (
                  <Box className="listing-details-layout-box-content">
                    <TitleBoxH3 title={item.title} subtitle={item.subtitle}>
                      <button
                        onClick={() => handleEditClick(item.title, "select")}
                        className="listings-details-edit-button"
                      >
                        {t("detailsPages.details.edit")}
                      </button>
                    </TitleBoxH3>
                    {editingTitles.includes(item.title) && (
                      <ModalContainer
                        width={item.width}
                        open={modalOpen[item.title]}
                        setOpen={(open) =>
                          setModalOpen((prev) => ({
                            ...prev,
                            [item.title]: open,
                          }))
                        }
                      >
                        {item.component}
                      </ModalContainer>
                    )}
                  </Box>
                ) : editingTitles.includes(item.title) ? (
                  <ListingDetailsEditingBox
                    onClose={() => handleCloseEditing(item.title)}
                  >
                    {item.component}
                  </ListingDetailsEditingBox>
                ) : (
                  <Box className="listing-details-layout-box-content">
                    <TitleBoxH3
                      title={item.title}
                      subtitle={item.subtitle}
                      subMenu={item.subMenu}
                      fontWeight={item.fontWeight}
                      showComponent={item.showComponent}
                      component={item.component}
                    >
                      <button
                        onClick={() =>
                          item.subMenu
                            ? item.onClick && item.onClick()
                            : handleEditClick(item.title, "input")
                        }
                        className="listings-details-edit-button"
                      >
                        {t("detailsPages.details.edit")}
                        {item.subMenu && (
                          <img
                            src={ArrowDownSvg}
                            alt="listing"
                            style={{
                              transition: "transform 0.3s",
                              transform: "rotate(-90deg)",
                              marginLeft: "4px",
                            }}
                          />
                        )}
                      </button>
                    </TitleBoxH3>
                  </Box>
                )}
              </Fragment>
            ))}
        </Box>
      </Box>
    </>
  );
}

export default DetailsInputArea;
