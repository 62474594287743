import { Stack, useMediaQuery, useTheme } from "@mui/material";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";

interface CoverPhotoProps {
  coverPhoto: string;
  setCoverPhoto: (photo: string) => void;
}

function CoverPhoto({ coverPhoto, setCoverPhoto }: CoverPhotoProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleChangePhoto = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (
      file &&
      (file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png")
    ) {
      if (file.size > 25 * 1024 * 1024) {
        alert(t("onboardingpages.step2.invalidFileSize"));
        return;
      }

      const img = new Image();
      img.onload = () => {
        if (img.width > 1024 || img.height > 683) {
          alert(t("onboardingpages.step2.invalidFileSize"));
          return;
        }

        const reader = new FileReader();
        reader.onloadend = () => {
          if (reader.result) {
            setCoverPhoto(reader.result as string);
          }
        };
        reader.readAsDataURL(file);
      };
      img.src = URL.createObjectURL(file);
    } else {
      alert(t("onboardingpages.step2.invalidFileSize"));
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <Stack
      direction={isMobile ? "column" : "row"}
      justifyContent="space-between"
      id="cover-photo"
    >
      <Stack spacing={3} sx={{ maxWidth: "300px" }} mb={isMobile ? 4 : 0}>
        <Stack spacing={1}>
          <p className="cover-photo-title">
            {t("detailsPages.details.coverPhoto")}
          </p>
          <p className="cover-photo-subtitle">
            {t(
              "detailsPages.details.yourCoverPhotoIsGuestsFirstImpressionOfYourListing"
            )}
          </p>
        </Stack>
        <button
          className="change-photo-button B-13-13-600"
          onClick={handleButtonClick}
        >
          {t("detailsPages.details.changePhoto")}
        </button>
        <input
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          ref={fileInputRef}
          onChange={handleChangePhoto}
        />
      </Stack>
      <img
        src={coverPhoto}
        alt="cover"
        style={{
          height: "274px",
          borderRadius: "12px",
          objectFit: "cover",
        }}
        width={isMobile ? "100%" : 400}
      />
    </Stack>
  );
}

export default CoverPhoto;
