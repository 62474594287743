import { Box } from "@mui/material";
import React from "react";
import "../../assets/sass/components/_title-box-h3.scss";

interface TitleBoxH3Props {
  title: string;
  subtitle: string;
  children?: React.ReactNode;
  subMenu?: boolean;
  fontWeight?: number;
  borderBottom?: boolean;
  showComponent?: boolean;
  component?: React.ReactNode;
}

const TitleBoxH3: React.FC<TitleBoxH3Props> = ({
  title,
  subtitle,
  children,
  subMenu,
  fontWeight,
  borderBottom = true,
  showComponent,
  component,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        borderBottom: borderBottom
          ? "1px solid var(--stroke-colors, #ebebeb)"
          : "none",
      }}
    >
      <Box
        className="title-box-h3-box"
        sx={{
          borderBottom: "none",
          paddingTop: showComponent ? "0px" : "32px",
        }}
      >
        <Box className="title-box-h3-box-left">
          <p
            className={
              subMenu && !fontWeight
                ? "listing-details-layout-box-content-title"
                : "title-box-h3-box-title B-16"
            }
          >
            {title}
          </p>
          <p className="title-box-h3-box-subtitle B-14-20-400">{subtitle}</p>
        </Box>
        {children}
      </Box>
      {showComponent && (
        <Box sx={{ width: "100%", paddingBottom: "32px" }}>{component}</Box>
      )}
    </Box>
  );
};

export default TitleBoxH3;
