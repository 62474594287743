import { Box, IconButton, Stack, FormControlLabel, Checkbox, Button, Drawer, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import MainSelectSelection from "../../../../../components/core-components/inputs/Select/MainSelectSelection";
import { AppDispatch, RootState } from "../../../../../store/store";
import Settings from "../../../../../assets/images/icons/setting-4.svg";
import AssignerSelect from "./Autocomplate";
import TagFilter from "./TagFilter";
import "./FilterDrawer.scss"
import { useAuth0 } from "@auth0/auth0-react";
import { FilterType } from "../../../../../api/messageApi";
import { getAllProperties, getAllTags, getAllUsers, getAllCities, resetFilters, setFilters, fetchFilteredMessages, getAllMessageSources } from "../../../../../store/slices/filterSlice";
import { fetchMessages, setFilterType } from "../../../../../store/slices/chatSlice";

const FilterDrawer = () => {
  const [checkIn, setCheckIn] = useState<string>("");
  const [modalState, setModalState] = useState<boolean>(false);
  const { getAccessTokenSilently } = useAuth0();
  const [buttonLoading, setButtonLoading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const {
    filters,
    filterOptions,
    isFilterActive
  } = useSelector((state: RootState) => state.filter);
  const { userDetails } = useSelector((state: RootState) => state.global);

  useEffect(() => {
    const getAll = async () => {
      const accessToken = await getAccessTokenSilently();
      dispatch(getAllProperties({ accessToken }))
      dispatch(getAllMessageSources({ accessToken }))
      dispatch(getAllUsers({ accessToken }))
      if (userDetails?.customerId) {
        dispatch(getAllCities({ accessToken, customerId: userDetails.customerId }))
      }
      dispatch(getAllTags({ accessToken }))
    }

    if (modalState) {
      getAll()
    }
  }, [modalState])


  const fetchDefault = async () => {
    dispatch(resetFilters())
    const accessToken = await getAccessTokenSilently();
    const filterType = FilterType.NONE
    dispatch(
      fetchMessages({
        accessToken,
        page: 1,
        limit: 50,
        filterType
      })
    ).unwrap();
  }

  const handleApply = async () => {
    try {
      const currentFilters = { ...filters };

      setButtonLoading(true)
      const hasValidFilters = Object.values(currentFilters).some(
        (value) =>
          value &&
          ((Array.isArray(value) && value.length > 0) ||
            (!Array.isArray(value) && value !== null && value !== ""))
      );

      const accessToken = await getAccessTokenSilently();

      if (!hasValidFilters) {
        fetchDefault()
        return;
      }


      if (isFilterActive) {
        dispatch(setFilterType(FilterType.FULL_FILTER))
      }

      if (currentFilters.assignedUsers?.length > 0) {
        const emailAddresses = currentFilters.assignedUsers.map((userId) => {
          const user = filterOptions.assignerOptions.find((opt) => opt.value === userId);
          return decodeURIComponent(user?.email || userId);
        });

        dispatch(
          setFilters({
            ...currentFilters,
            assignedUsers: emailAddresses,
          })
        );
      }


      await dispatch(
        fetchFilteredMessages({
          accessToken,
          page: 1,
          limit: 50,
        })
      ).unwrap();

    } catch (error) {
      console.error("Filtreleme hatası:", error);
    } finally {
      setButtonLoading(false)
      setModalState(false)
    }
  };


  return (
    <div style={{ position: "relative" }}>
      <IconButton onClick={() => { setModalState(true) }} className="icon-button" >
        <img src={Settings} alt="Settings" />
      </IconButton>
      <Drawer
        anchor="left"
        open={modalState}
        onClose={() => { setModalState(false) }}
        hideBackdrop={true}
        className="mui-filter-drawer"
        PaperProps={{
          sx: {
            top: "77px",
            position: "relative",
            width: "100%",
            maxWidth: "100%",
            overflow: "hidden",
            boxShadow: "none",
          },
        }}
      >
        <Box className="filter-drawer">
          <div className="main-flex-box">
            <div className="filter-header" >
              <p className="filter-modal-title">{t("inboxFilter.filters")}</p>
              <Stack direction="row" spacing={2}>
                {/*<p onClick={handleReset} className="filter-reset">
                  Reset All
                </p>*/}
                <p onClick={() => { fetchDefault() }} className="filter-reset" >
                  Clear Filters
                </p>
              </Stack>
            </div>

            <div className="form-flex-box" >
              <p className="filter-modal-label">
                {t("inboxFilter.messageStatus")}
              </p>
              <FormControlLabel
                className="form-control-label"
                control={
                  <Checkbox
                    onChange={(e) => {
                      dispatch(setFilters({ isUnreadOnly: e.target.checked }));
                    }}
                    checked={filters.isUnreadOnly}
                  />
                }
                label={t("inboxFilter.seeOnlyUnread")}
              />
            </div>

            <div className="form-flex-box">
              <p className="filter-input-label">{t("inboxFilter.assigners")}</p>
              <AssignerSelect
                multiple={true}
                placeholder={t("inboxFilter.selectOrType")}
                options={filterOptions.assignerOptions}
                value={filters.assignedUsers.map((value) => {
                  const user = filterOptions.assignerOptions.find((opt) => opt.value === value);
                  return {
                    label: user ? user.label : value,
                    value: value,
                  };
                })}
                onChange={(event, value) => {
                  if (!value || !Array.isArray(value)) return;
                  const values = value.map((option) => option.value);
                  dispatch(setFilters({ assignedUsers: values }));
                  //setAssigneeValues(value);
                }}
              />
            </div>

            <Stack spacing={1}>
              <p className="filter-modal-label">{t("inboxFilter.channels")}</p>
              <AssignerSelect
                multiple={true}
                placeholder={t("inboxFilter.selectOrType")}
                options={filterOptions.channelOptions.map((option) => ({
                  ...option,
                  label: option.label,
                }))}
                value={filters.sources.map((value) => {
                  const channel = filterOptions.channelOptions.find(
                    (opt) => opt.value === value
                  );
                  return {
                    label: channel ? channel.label : value,
                    value: value,
                  };
                })}
                onChange={(event, value) => {
                  if (!value || !Array.isArray(value)) return;
                  const values = value.map((option) => option.value);
                  dispatch(setFilters({ sources: values }));
                  //setChannelValues(value);
                }}
              />
            </Stack>

            <Stack spacing={1}>
              <p className="filter-modal-label">{t("inboxFilter.listingTags")}</p>
              <TagFilter
                placeholder={t("inboxFilter.selectOrType")}
                multiple={true}
                value={filters.tags.map((tag) => ({
                  label: tag,
                  value: tag,
                }))}
                onChange={(event, newValue) => {
                  const tagValues = newValue.map((tag) => tag.label);
                  dispatch(setFilters({ tags: tagValues }));
                }}
              />
            </Stack>

            <Stack spacing={1}>
              <p className="filter-input-label">
                {t("inboxFilter.listingCities")}
              </p>
              <AssignerSelect
                multiple={true}
                placeholder={t("inboxFilter.selectOrType")}
                options={filterOptions.cityOptions}
                value={filters.cities.map((value) => ({ label: value, value }))}
                onChange={(event, value) => {
                  if (!value || !Array.isArray(value)) return;
                  const values = value.map((option) =>
                    typeof option === "string" ? option : option.value
                  );
                  dispatch(setFilters({ cities: values }));
                }}
              />
            </Stack>

            <Stack spacing={1}>
              <p className="filter-input-label">{t("inboxFilter.listing")}</p>
              <Box position="relative">
                <AssignerSelect
                  multiple={true}
                  placeholder={t("inboxFilter.selectOrType")}
                  options={filterOptions.propertyOptions}
                  value={filters.propertyIds.map((value) => {
                    const property = filterOptions.propertyOptions.find(
                      (opt) => opt.value === value
                    );
                    return property || { label: value, value };
                  })}
                  onChange={(event, value) => {
                    if (!value || !Array.isArray(value)) return;
                    const values = value.map((option) => option.value);
                    dispatch(setFilters({ propertyIds: values }));
                    //setListingValues(value); }}
                    // disabled = {propertyLoading}
                    // errorMessage = {
                    //   propertyError? "Veriler yüklenirken bir hata oluştu": null
                    // }
                    // {
                    //   propertyLoading && (
                    //     <Box
                    //       sx={{
                    //         position: "absolute",
                    //         right: "40px",
                    //         top: "50%",
                    //         transform: "translateY(-50%)",
                    //       }}
                    //     >
                    //       <CircularProgress size={20} />
                    //     </Box>
                    //   )
                  }} />
              </Box>
            </Stack>
            <Stack spacing={1}>
              <p className="filter-input-label">{t("inboxFilter.checkIn")}</p>
              <MainSelectSelection
                options={[
                  { value: "check_in_today", label: "Check in today" },
                  { value: "currently_hosting", label: "Currently hosting" },
                  {
                    value: "arriving_in_two_days",
                    label: "Arriving in two days",
                  },
                  { value: "expired", label: "Expired" },
                  { value: "check_out_today", label: "Check out today" },
                  { value: "check_out_tomorrow", label: "Check out tomorrow" },
                ]}
                isFilter={true}
                value={checkIn}
                onChange={(e) => {
                  const value = e.target.value as string;
                  setCheckIn(value);
                  dispatch(
                    setFilters({
                      bookingStatus: value,
                    })
                  );
                }}
                label={t("inboxFilter.selectOrType")}
              />
            </Stack>
          </div>
        </Box >
        <div className="buttons-box" >
          <Button
            onClick={() => { setModalState(false) }}
            className="button cancel"
          //disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            onClick={handleApply}
            className="button apply"
            disabled={buttonLoading}
          >
            {buttonLoading && <CircularProgress size={20} thickness={4} sx={{ color: "#fff" }} />}
            Apply
          </Button>
        </div>
      </Drawer >
    </div >
  )

}

export default FilterDrawer;
