import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import OnlineSvg from "../../../../../../assets/images/icons/online.svg";
import CalendarSvg from "../../../../../../assets/images/icons/calendar-icon.svg";
import EyeSvg from "../../../../../../assets/images/icons/eye-icon.svg";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ArrowBack from "../../../../../../assets/images/icons/linear-arrow-down.svg";

import { useTranslation } from "react-i18next";
interface Props {
  subMenu: string;
  setSubMenu: React.Dispatch<React.SetStateAction<string>>;
}
function DetailHeader({ subMenu, setSubMenu }: Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const [isSticky, setIsSticky] = useState<boolean>(false);
  const stickyHeaderHeight = 104;

  const handleHashChange = () => {
    const { hash } = window.location;
    if (hash) {
      const target = document.querySelector(hash);
      if (target) {
        const yOffset = -stickyHeaderHeight;
        const y =
          target.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
        window.history.replaceState(
          "",
          document.title,
          window.location.pathname
        );
      }
    }
  };
  const handleBackButtonClick = () => {
    setSubMenu("");
    // setIsSubMenu(false);
  };
  useEffect(() => {
    const handleScroll = () => {
      const isNowSticky = window.scrollY > 0;
      setIsSticky(isNowSticky);
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("hashchange", handleHashChange);

    handleHashChange();

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  return (
    <Box
      sx={{
        minHeight: isMobile ? "40px" : isSticky ? "104px" : "94px",
        position: "sticky",
        top: 0,
        padding: isSticky ? "10px 0px" : "0px",
        background: "transparent",
        width: "100%",
        zIndex: "10",
      }}
    >
      <Box className="listing-details-layout-box-header">
        {!subMenu ? (
          <>
            <Box className="listing-details-layout-box-header-texts-box">
              <p className="listing-details-layout-box-header-title B-18-18-600">
                Forest Haven: Villa With Pool
              </p>
              <p className="listing-details-layout-box-header-subtitle B-14-20-400">
                This listing is connected to Airbnb and Channex .
              </p>
            </Box>
            <Box className="listing-details-layout-box-header-buttons-box">
              <button className="listing-details-layout-box-header-button-one">
                <img
                  src={OnlineSvg}
                  alt="listing"
                  className="listing-details-status-icon"
                />
                <p className="listing-details-layout-box-header-button-one-text">
                  Listed
                </p>
              </button>
              <button className="listing-details-layout-box-header-button-one">
                <img
                  src={CalendarSvg}
                  alt="calendar"
                  className="listing-details-calendar-icon"
                />
                <p className="listing-details-layout-box-header-button-one-text">
                  {t("detailsPages.viewCalendar")}
                </p>
              </button>
              <Link
                to={"/listings/preview/dsjkd"}
                className="listing-details-layout-box-header-button-two"
              >
                <img
                  src={EyeSvg}
                  alt="view"
                  className="listing-details-eye-icon"
                />
                <p className="listing-details-layout-box-header-button-two-text">
                  {t("detailsPages.previewListing")}
                </p>
              </Link>
            </Box>
          </>
        ) : (
          <Stack
            flexDirection="row"
            alignItems="center"
            sx={{
              gap: "10px",
            }}
          >
            {" "}
            <img
              className="menu-back-button"
              src={ArrowBack}
              alt="arrows-horizontal"
              onClick={handleBackButtonClick}
              style={{
                cursor: "pointer",
                transition: "transform 0.3s",

                transform: "rotate(90deg)",
                width: "25px",
              }}
            />
            <p className="listing-details-layout-box-header-title B-18-18-600">
              Forest Haven: Villa With Pool
            </p>
          </Stack>
        )}
      </Box>
    </Box>
  );
}

export default DetailHeader;
