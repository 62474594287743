import { Box, Skeleton, Stack } from "@mui/material";
import React from "react";

interface Props {
  secondary?: boolean;
}
function SkeletonCalendar(props: Props) {
  const {secondary} = props;
  return (
    <>
      <Stack gap={"8px"}>
        {!secondary && (
           <Stack direction={"row"}>
          <Box
            sx={{
              width: "319px",
              flexShrink: "0",
            }}
          ></Box>
          {/* <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> */}
          <Skeleton variant="rounded" width={"100%"} height={44} />
        </Stack>)
        }
       
        <Skeleton variant="rounded" width={"100%"} height={secondary ? 150 : 500} />

      </Stack>
    </>
  );
}

export default SkeletonCalendar;
