import { Dayjs } from "dayjs";
import { keys } from "../../data";

export const transformData = (data: any) => {
  const result = keys.map((key) => ({
    title: key,
    data: data.map((item: any) => ({
      date: item.Date.split("T")[0],
      value:
        item[key] === false
          ? "no"
          : item[key] === true
          ? "yes"
          : item[key].toString(),
    })),
  }));

  return result;
};

export const generateDates = (date: Dayjs): Dayjs[] => {
  const dates: Dayjs[] = [];
  for (let i = 0; i <= 13; i++) {
    dates.push(date.add(i, "day"));
  }
  return dates;
};
