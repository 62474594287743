import React from "react";
import { FormControlLabel, Radio, Stack } from "@mui/material";

const UncheckedIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <circle cx="12" cy="12" r="11.4" stroke="#DEE2E6" stroke-width="1.2" />
  </svg>
);

const CheckedIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <circle cx="12" cy="12" r="8.4" stroke="#3E97FF" stroke-width="7.2" />
  </svg>
);

const SecondaryRadioButton = ({
  value,
  label,
  subtitle,
  checked,
  onChange,
  textColor = "#7E8299",
}: {
  value: string;
  label: string | JSX.Element;
  subtitle?: string;
  checked?: boolean;
  onChange?: () => void;
  textColor?: string;
}) => (
  <FormControlLabel
    value={value}
    control={
      <Radio
        icon={UncheckedIcon}
        checkedIcon={CheckedIcon}
        checked={checked}
        onChange={onChange}
      />
    }
    label={
      <>
        <Stack spacing={0}>
          <p
            style={{
              fontFamily: "Inter",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "20px" /* 142.857% */,
              color: textColor,
            }}
          >
            {label}
          </p>
        </Stack>
      </>
    }
  />
);
export default SecondaryRadioButton;
