import { Box, Popover, Stack } from "@mui/material";
import React from "react";
import CloseModalButton from "../../../../../../../components/shared-components/CloseModalButton";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CustomDatePicker from "../../../../../../../components/core-components/inputs/DatePicker/CustomDatePicker";
import CustomSwitch from "../../../../../../../components/shared-components/CustomSwitch";
import CustomButton from "../../../../../../../components/shared-components/CustomButton";
import { useTranslation } from "react-i18next";
import dayjs, { Dayjs } from "dayjs";
import { UpdateRestriction } from "../../../../../../../network/calendar/UpdateRestriction";
import { useMutation } from "react-query";

interface Props {
  open: boolean;
  anchorEl: HTMLButtonElement;
  handleClose: () => void;
  modalTitle?: string;
  buttonText?: string;
  switchText?: string;
  title?: string;
  description?: string;
  ratePlanId?: string;
  propertyId?: string;
  start?: Dayjs;
  refetch?: () => void;
  fieldName?: string;
  value: any;
}

function BooleanModal(props: Props) {
  const { t } = useTranslation();
  const {
    open,
    anchorEl,
    handleClose,
    modalTitle,
    buttonText,
    switchText,
    title,
    description,
    ratePlanId,
    propertyId,
    start,
    fieldName,
    refetch,
    value,
  } = props;
  const [closedSwitch, setClosedDatesSwitch] = React.useState(
    value === "no" ? false : true
  );

  const handleClosedDatesSwitch = () => {
    setClosedDatesSwitch((prevState) => !prevState);
  };

  const updateRestriction = new UpdateRestriction();
  const { mutate, isLoading } = useMutation(
    (postData: any) => updateRestriction.add(postData),
    {
      onSuccess: (res) => {
        handleClose();
        refetch && refetch();
      },
      onError: (error) => {
        console.error("Error submitting form", error);
      },
    }
  );

  const handleSubmit = (e: any) => {
    e.preventDefault();

    const formData: any = {
      PropertyId: propertyId,
      RatePlanId: ratePlanId,
      DateBeg: dayjs(e.target.check_in.value, "DD.MM.YYYY").format(
        "YYYY-MM-DD"
      ),
      DateEnd: dayjs(e.target.check_out.value, "DD.MM.YYYY").format(
        "YYYY-MM-DD"
      ),
    };

    if (fieldName) {
      formData[fieldName] = closedSwitch;
    }

    mutate(formData);
  };

  return (
    <>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          ".MuiPaper-root": {
            gap: "24px",
            borderRadius: "16px",
            background: "#FFF",
            padding: "24px",
            boxShadow: "0px 8px 14px 0px rgba(15, 42, 81, 0.04)",
            width: "728px",
          },
          background: "rgba(53, 48, 48, 0.21)",
        }}
      >
        <Box component={"form"} onSubmit={handleSubmit}>
          <Stack
            direction="row"
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <p className="B-16-16-600">{modalTitle}</p>
            <CloseModalButton show={true} onClick={handleClose} />
          </Stack>
          <Stack spacing={3} mt={2}>
            <Stack spacing={2}>
              <Stack maxWidth={463}>
                <p className="B-14" style={{ color: "#181C32" }}>
                  {title}
                </p>
                <p className="B-12-16-400">{description}</p>
              </Stack>
              <Stack
                direction="row"
                spacing={1}
                alignItems={"center"}
                sx={{ width: "100%" }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <CustomDatePicker
                    label="Check-in"
                    name={"check_in"}
                    date={start}
                  />
                </LocalizationProvider>
                <p className="B-14-24-500">{t("calendarpage.to")}</p>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <CustomDatePicker
                    label="Check-out"
                    name={"check_out"}
                    date={start}
                  />
                </LocalizationProvider>
              </Stack>
            </Stack>
            <Stack direction="row" spacing={1} style={{ marginTop: "24px" }}>
              <CustomSwitch
                checked={closedSwitch}
                onChange={handleClosedDatesSwitch}
              />
              <p className="B-14" style={{ color: "#181C32" }}>
                {switchText}
              </p>
            </Stack>
            <Stack
              direction="row"
              justifyContent={"flex-end"}
              alignItems={"center"}
            >
              <Stack>
                <CustomButton
                  backgroundColor="#0065E0"
                  color="#FFFFFF"
                  // onClick={handleClose}
                  buttonText={buttonText}
                  type={"submit"}
                  disable={isLoading}
                />
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Popover>
    </>
  );
}

export default BooleanModal;
