import React from "react";
import { Stack } from "@mui/material";

interface OfferItemProps {
  src: string;
  alt: string;
  text: string;
}

const OfferItem: React.FC<OfferItemProps> = ({ src, alt, text }) => (
  <Stack direction="row" spacing={2}>
    <img src={src} alt={alt} style={{ width: "24px", height: "24px" }} />
    <p className="B-16-16-500 preview-listing-offer-text">{text}</p>
  </Stack>
);

export default OfferItem;
