import { Stack, Button, useMediaQuery, useTheme } from "@mui/material";
import React, { Dispatch, SetStateAction, useState } from "react";
import MainTextField from "../../../../core-components/inputs/TextField/MainTextField";
import AiIcon from "../../../../../assets/images/icons/technologies.svg";
import ArrowsBack from "../../../../../assets/images/icons/arrows (1).svg";
import CustomButton from "../../../CustomButton";
import { useTranslation } from "react-i18next";

interface Props {
  setStep: Dispatch<SetStateAction<number>>;
}

function StepTwo(props: Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const { setStep } = props;
  const [text, setText] = useState("");

  const handleTextChange = (e: any) => {
    if (e.target.value.length > 50) return;
    setText(e.target.value);
  };

  return (
    <Stack
      spacing={2}
      sx={{
        width: "100%",
        paddingBottom: "24px",
      }}
    >
      <Stack spacing={2} sx={{ width: "100%", paddingBottom: "24px" }}>
        <Stack spacing={1}>
          <MainTextField
            title={t("createreservationpage.guestname")}
            onChange={() => ""}
            value={""}
            placeholder={t("createreservationpage.addguestname")}
          />
        </Stack>
        <Stack spacing={1}>
          <MainTextField
            title={t("createreservationpage.prefferedguestname")}
            onChange={() => ""}
            value={""}
            placeholder={t("createreservationpage.addprefferedguestname")}
          />
        </Stack>
        <Stack spacing={1}>
          <MainTextField
            title={t("createreservationpage.guestphonenumber")}
            type="tel"
            onChange={() => ""}
            value={""}
            placeholder={t("createreservationpage.addguestphonenumber")}
          />
        </Stack>
        <Stack spacing={1}>
          <MainTextField
            title={t("createreservationpage.guestmail")}
            onChange={() => ""}
            value={""}
            placeholder={t("createreservationpage.addguestmail")}
          />
        </Stack>
        <Stack>
          <Stack
            spacing={1}
            sx={{
              position: "relative",
            }}
          >
            <MainTextField
              title={t("createreservationpage.note")}
              value={text}
              onChange={handleTextChange}
              placeholder={t("createreservationpage.thebestplacenearthelake")}
              multiline
            />
            <button className="onboarding-step3-second-textfield-button">
              <img
                src={AiIcon}
                alt="AI"
                className="onboarding-step3-textfield-button-icon"
              />
              <p className="onboarding-step3-textfield-button-text">AI</p>
            </button>
          </Stack>
        </Stack>
        <p className="character-counter">{`${text.length}/50`}</p>
      </Stack>
      <Stack
        direction={isMobile ? "column" : "row"}
        justifyContent={"space-between"}
        spacing={isMobile ? 1 : 0}
      >
        <Button
          className="B-16-16-600"
          style={{
            backgroundColor: "#FFFFFF",
            color: "#3F4254",
            border: "1px solid var(--Gray-Gray-800, #3F4254)",
            padding: isMobile ? "0" : "16px 24px",
            height: "48px",
            borderRadius: "50px",
            alignItems: "center",
            marginTop: isMobile ? "12px" : "40px",
            justifyContent: "center",
            alignSelf: "flex-end",
            width: isMobile ? "100%" : "auto",
          }}
          onClick={() => setStep(1)}
        >
          <Stack direction={"row"} spacing={1}>
            <img src={ArrowsBack} alt="back-arrows"></img>
            <p>{t("createreservationpage.back")}</p>
          </Stack>
        </Button>
        <Stack
          direction={isMobile ? "column" : "row"}
          spacing={isMobile ? 1 : 2}
          mt={isMobile ? 2 : 0}
          width={isMobile ? "100%" : "auto"}
        >
          <CustomButton
            backgroundColor="#FFFFFF"
            color="#3F4254"
            border="1px solid var(--Gray-Gray-800, #3F4254)"
            onClick={() => ""}
            buttonText={t("createreservationpage.createconfirmbooking")}
            buttonWidth={isMobile ? "100%" : "auto"}
          />
          <CustomButton
            backgroundColor="#0065E0"
            color="#FFFFFF"
            onClick={() => ""}
            buttonText={t("createreservationpage.creatependingbooking")}
            buttonWidth={isMobile ? "100%" : "auto"}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}

export default StepTwo;
