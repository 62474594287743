import { useTranslation } from "react-i18next";
import DetailsInputArea from "../../../../../../../../components/shared-components/DetailsInputArea";
import ListingStatus from "../../../../components/modals/ListingStatus";
import Internet from "../../../../components/modals/Internet";
import AirCondition from "../../../../components/modals/AirCondition";
import Heating from "../../../../components/modals/Heating";
import WashingMachine from "../../../../components/modals/WashingMachine";
import Parking from "../../../../components/modals/Parking";
import SwimmingPool from "../../../../components/modals/SwimmingPool";

interface Props {
  setSubMenu: React.Dispatch<React.SetStateAction<string>>;
}
function Facilities(props: Props) {
  const { setSubMenu } = props;

  const { t } = useTranslation();
  const data = [
    {
      title: t("listingDetails.internet"),
      subtitle: t("listingDetails.notset"),
      component: <Internet />,
      isSelect: true,
      width: "464",
    },
    {
      title: t("listingDetails.airconditioning"),
      subtitle: t("listingDetails.notset"),
      component: <AirCondition />,
      isSelect: true,
      width: "464",
    },
    {
      title: t("listingDetails.heating"),
      subtitle: t("listingDetails.notset"),
      component: <Heating />,
      isSelect: true,
      width: "464",
    },
    {
      title: t("listingDetails.washingmachine"),
      subtitle: t("listingDetails.notset"),
      component: <WashingMachine />,
      isSelect: true,
      width: "464",
    },
    {
      title: t("listingDetails.parking"),
      subtitle: t("listingDetails.notset"),
      component: <Parking />,
      isSelect: true,
      width: "464",
    },
    {
      title: t("listingDetails.swimmingpool"),
      subtitle: t("listingDetails.notset"),
      component: <SwimmingPool />,
      isSelect: true,
      width: "464",
    },
    {
      title: t("listingDetails.otheressentials"),
      subtitle: t("listingDetails.notset"),
      component: <ListingStatus showTitle={true} />,
      isSelect: false,
      subMenu: true,
      onClick: () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        setSubMenu("otherEssentials");
      },
      fontWeight: 400
    },
  ];
  return (
    <>
      <DetailsInputArea
        title={t("listingDetails.facilities")}
        data={data}
        onClick={() => { }}
        id={"facilities"}
      />
    </>
  );
}

export default Facilities;
