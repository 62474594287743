import {
  Box,
  Modal,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import CloseModalButton from "../../../../../components/shared-components/CloseModalButton";
import CustomModalMobile from "../../../../../components/shared-components/CustomModalMobile";
import ErrorIcon from "../../../../../assets/images/icons/error.svg";

interface DeclineModalProps {
  open: boolean;
  handleClose: () => void;
  handleSave: () => void;
}

const DeclineModal: React.FC<DeclineModalProps> = ({
  open,
  handleClose,
  handleSave,
}) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"));

  const modalContent = (
    <>
      <Stack
        spacing={4}
        pb={2}
        sx={{
          alignItems: "center",
          alignSelf: "stretch",
          minWidth: "100%",
        }}
      >
        <Stack spacing={2} justifyContent={"center"} alignItems={"center"}>
          <img
            src={ErrorIcon}
            alt="error-icon"
            style={{ width: "64px", height: "64px" }}
          />
          <span className="H-24">Are you sure you want decline?</span>
          <span
            className="B-14-20-500"
            style={{ color: "var(--Gray-500, #667085)" }}
          >
            If you decline that it will be deleted
          </span>
        </Stack>
        <Stack
          direction={isMobile ? "column" : "row"}
          spacing={2}
          sx={{
            justifyContent: "space-between",
            alignItems: "flex-start",
            alignSelf: "stretch",
            height: "48px",
          }}
        >
          <button
            onClick={handleSave}
            style={{
              display: "flex",
              height: "48px",
              width: "100%",
              padding: "16px 20px",
              justifyContent: "center",
              gap: "10px",
              alignItems: "center",
              cursor: "pointer",
              borderRadius: "50px",
              border: "var(--none, 1px) solid var(--Gray-500, #667085)",
              background: "transparent",
              fontFamily: "Inter",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "20px" /* 107.692% */,
              color: "color: var(--Gray-800, #1D2939)",
            }}
          >
            Don't decline
          </button>
          <button
            onClick={handleSave}
            style={{
              display: "flex",
              height: "48px",
              width: "100%",
              padding: "16px 20px",
              justifyContent: "center",
              gap: "10px",
              alignItems: "center",
              cursor: "pointer",
              border: "none",
              borderRadius: "50px",
              background: "var(--Blue-light, #0065E0)",
              fontFamily: "Inter",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "20px" /* 107.692% */,
              color: "var(--white-dark-white, #FFF)",
            }}
          >
            Decline
          </button>
        </Stack>
      </Stack>
    </>
  );

  return isMobile ? (
    <CustomModalMobile
      open={open}
      handleClose={handleClose}
      handleSave={handleSave}
      title=""
      showFooter={false}
    >
      <Stack spacing={2} sx={{ width: "100%" }} pb={8}>
        {modalContent}
      </Stack>
    </CustomModalMobile>
  ) : (
    <Modal open={open} onClose={handleClose}>
      <div onClick={handleClose}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            right: 0,
            bottom: 0,
            width: "100%",
            height: "100%",
          }}
        >
          <Box
            sx={{
              display: "inline-flex",
              flexDirection: "column",
              alignItems: "flex-start",
              padding: "32px",
              gap: "16px",
              backgroundColor: "background.paper",
              background: "#FFF",
              boxShadow: "0px 16px 32px 0px rgba(0, 0, 0, 0.12)",
              borderRadius: "12px",
              minWidth: "560px",
            }}
            onClick={(event) => event.stopPropagation()}
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-end",
                alignItems: "flex-start",
                alignSelf: "stretch",
              }}
            >
              <CloseModalButton show={true} onClick={handleClose} />
            </Box>
            {modalContent}
          </Box>
        </Box>
      </div>
    </Modal>
  );
};

export default DeclineModal;
