import React from "react";
import { Modal, Box, Typography, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import CloseModalButton from "./CloseModalButton";
import CustomButton from "./CustomButton";

interface CustomModalMobileProps {
  open: boolean;
  handleClose: () => void;
  handleSave: () => void;
  title: string;
  description?: string;
  children: React.ReactNode;
  showFooter?: boolean;
  height?: string;
  disabled?: boolean;
}

const CustomModalMobile: React.FC<CustomModalMobileProps> = ({
  open,
  handleClose,
  handleSave,
  title,
  description,
  children,
  showFooter = true,
  height,
  disabled,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="menu-modal-title"
      aria-describedby="menu-modal-description"
      sx={{
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxHeight: "80vh",
          minHeight: "3-0px",
          height: height,
          padding: "16px",
          borderRadius: "24px 24px 0px 0px",
          background: "#FFF",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <Stack spacing={1}>
          <Stack
            sx={{
              paddingBottom: "24px",
              paddingTop: "16px",
            }}
          >
            <Stack
              justifyContent={"space-between"}
              direction={"row"}
              alignItems={"center"}
            >
              <Typography
                id="menu-modal-title"
                style={{
                  color: "var(--Gray-Gray-900, #181C32)",
                  fontSize: "18px",
                  fontFamily: "Inter",
                  fontWeight: 600,
                  fontStyle: "normal",
                  lineHeight: "18px",
                  letterSpacing: "-0.18px",
                }}
              >
                {title}
              </Typography>
              <CloseModalButton show={true} onClick={handleClose} />
            </Stack>
            <Stack
              spacing={2}
              maxWidth={412}
              width={"100%"}
              justifyContent={"flex-start"}
              textAlign={"left"}
              alignSelf={"center"}
              sx={{ width: "100%" }}
            >
              <p className="B-14">{description}</p>
            </Stack>
          </Stack>
        </Stack>
        <Box
          sx={{
            flex: 1,
            overflowY: "auto",
            width: "100%",
            overflowX: "hidden",
            scrollbarWidth: "none", // Firefox için
            msOverflowStyle: "none", // IE ve Edge için
            "&::-webkit-scrollbar": {
              display: "none", // Chrome, Safari ve Opera için
            },
          }}
        >
          {children}
        </Box>
        {showFooter && (
          <Box
            sx={{
              display: "flex",
              padding: "8px 0px",
              justifyContent: "space-between",
              alignItems: "center",
              borderTop: "1px solid #e0e0e0",
              paddingTop: "16px",
            }}
          >
            <CustomButton
              backgroundColor="transparent"
              color="var(--Gray-Gray-900, #181C32)"
              onClick={handleClose}
              buttonText={t("customModal.cancel")}
              marginTop={0}
              buttonWidth="auto"
              textDecoration="underline"
            />
            <CustomButton
              backgroundColor="#0065E0"
              color="#FFFFFF"
              onClick={handleSave}
              buttonText={t("customModal.save")}
              marginTop={0}
              buttonWidth="auto"
              disable={disabled}
            />
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default CustomModalMobile;
