import { Box, Chip, CircularProgress, Stack } from "@mui/material";
import "./ListingTable.scss"
import React, { useEffect, useState } from "react";
import MessagesSearchTextfield from "../../../../components/core-components/inputs/TextField/MessagesSearchTextfield";
import ReservationImage from "../../../../assets/images/reservation-img-1.jpeg";
import PdfIcon from "../../../../assets/images/docs-icon.svg";
import TagIcon from "../../../../assets/images/icons-tag.svg";
import CloseIconWhite from "../../../../assets/images/icons/close-icon-white.svg";
import imageIcon from "../../../../assets/images/image-icon.svg";
import GridData from "../../../../components/shared-components/GridData";
import { GridColDef } from "@mui/x-data-grid";
import { useAuth0 } from "@auth0/auth0-react";
import CustomTooltip from "../../../../components/shared-components/CustomTooltip";
import CustomModal from "../../../../components/shared-components/CustomModal";
import UploadAttachModal from "./UploadAttachModal";
import PlusIcon from "../../../../assets/images/icons/plus-arrows.svg";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store/store";
import { useDispatch } from "react-redux";
import { fetchPropertyTags } from "../../../../store/slices/onboardSlice";

const ReviewImportedListings = () => {
  const [open, setOpen] = useState(false);
  const [modalFiles, setModalFiles] = useState<
    { name: string; tags: string[]; size?: number; isImage?: boolean }[]
  >([]);
  const [selectedPropertyId, setSelectedPropertyId] = useState<string>("");
  const [selectedRowId, setSelectedRowId] = useState<string | null>(null);
  const [searchText, setSearchText] = React.useState("");
  const { getAccessTokenSilently } = useAuth0();

  const {
    properties,
    propertiesLoading
  } = useSelector((state: RootState) => state.onboard)

  const dispatch: AppDispatch = useDispatch()


  const handleClose = () => {
    //setOpenPopover(false);
  };

  useEffect(() => {
    const fetchTags = async () => {
      const accessToken = await getAccessTokenSilently()
      dispatch(fetchPropertyTags({ accessToken }))
    }
    fetchTags()
  }, [])

  const handleOpen = (rowId: string) => {
    setSelectedRowId(rowId);
    const selectedRow = properties.find((row) => row.Id === rowId);

    if (selectedRow) {
      const existingDocuments = selectedRow.attachDocument || [];
      if (existingDocuments[0] !== null) {
        const documentsWithSize = existingDocuments.map(
          (doc: { name: string; tags: string[]; isImage?: boolean }) => ({
            ...doc,
            size: 500 * 1024,
            tags: doc.tags || [],
            isImage: doc.isImage || false,
          })
        );
        setModalFiles(documentsWithSize);
      } else {
        setModalFiles([]);
      }
      setSelectedPropertyId(selectedRow.Id);
    }
    setOpen(true);
  };



  const handleCloseModal = () => {
    setOpen(false);
    setSelectedPropertyId("");
  }

  const handleSave = (_: Array<{ file: File; tags: string[] }>) => {
    if (selectedRowId !== null) {
      // setRows((prev) => {
      //   const newRows = [...prev];
      //   const rowIndex = newRows.findIndex((row) => row.Id === selectedRowId);

      //   if (rowIndex !== -1) {
      //     if (files.length === 0) {
      //       newRows[rowIndex].attachDocument = [null];
      //     } else {
      //       const updatedDocuments = files.map((file) => ({
      //         name: file.file.name,
      //         tags: file.tags,
      //         isImage:
      //           file.file.name
      //             .toLowerCase()
      //             .match(/\.(jpg|jpeg|png|gif|webp)$/) !== null,
      //       }));
      //       newRows[rowIndex].attachDocument = updatedDocuments;
      //     }
      //   }
      //   return newRows;
      // });
    }
  };

  const handleDeleteFile = (_: number, __: string) => {
    //  setRows((prevRows) => {
    //    const newRows = [...prevRows];
    //    const rowIndex = newRows.findIndex((row) => row.Id === selectedRowId);

    //    if (rowIndex !== -1) {
    //      const currentDocuments = newRows[rowIndex].attachDocument;

    //      if (currentDocuments.length === 1) {
    //        newRows[rowIndex].attachDocument = [null];
    //      } else {
    //        newRows[rowIndex].attachDocument = currentDocuments.filter(
    //          (doc: { name: string }) => doc?.name !== fileName
    //        );
    //      }
    //    }
    //    return newRows;
    //  });

    //  setModalFiles((prevFiles) =>
    //    prevFiles.filter((file) => file.name !== fileName)
    //  );
  };

  const filteredRows = properties.filter(
    (row) =>
      row.listingName.toLowerCase().includes(searchText.toLowerCase()) ||
      row.nickname.toLowerCase().includes(searchText.toLowerCase()) ||
      row.city.toLowerCase().includes(searchText.toLowerCase()) ||
      row.address.toLowerCase().includes(searchText.toLowerCase()) ||
      row.tags.some(
        (tag: any) =>
          typeof tag === "string" &&
          tag.toLowerCase().includes(searchText.toLowerCase())
      )
  );

  const getColumns = (): GridColDef<any[number]>[] => [
    {
      field: "listingName",
      headerName: "Listing Name ",
      disableColumnMenu: true,
      flex: 3.5,
      align: "center",
      sortable: false,
      renderCell: (params) => (
        <Stack
          direction={"row"}
          spacing={1}
          alignItems={"center"}
          sx={{ height: "100%" }}
        >
          <img
            src={ReservationImage}
            alt="reservation"
            style={{ width: "40px", height: "40px", borderRadius: "6px" }}
          />
          <p
            className="TextSmMedium"
            style={{
              wordWrap: "break-word",
              whiteSpace: "normal",
              display: "flex",
              alignItems: "center",
              maxWidth: "200px",
              wordBreak: "break-all",
              textAlign: "left",
              color: "#3F4254",
            }}
          >
            {params.row.listingName}
          </p>
        </Stack>
      ),
    },
    {
      field: "nickname",
      headerName: "Nickname",
      disableColumnMenu: true,
      flex: 1.5,
      align: "left",
      sortable: false,
      renderCell: (params) => (
        <Stack alignItems={"flex-start"}>
          <p
            className="TextSmMedium"
            style={{
              wordWrap: "break-word",
              whiteSpace: "normal",
              display: "flex",
              alignItems: "center",
              maxWidth: "200px",
              wordBreak: "break-all",
              textAlign: "left",
              color: "#3F4254",
            }}
          >
            {params.row.nickname}
          </p>
        </Stack>
      ),
    },
    {
      field: "typeOfUnit",
      headerName: "Type of unit",
      flex: 1.5,
      align: "left",
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => (
        <Stack alignItems={"flex-start"}>
          <p
            className="TextSmMedium"
            style={{
              wordWrap: "break-word",
              whiteSpace: "normal",
              display: "flex",
              alignItems: "center",
              maxWidth: "200px",
              wordBreak: "break-all",
              textAlign: "left",
              color: "#3F4254",
            }}
          >
            {params.row.typeOfUnit}
          </p>
        </Stack>
      ),
    },
    {
      field: "city",
      headerName: "City",
      disableColumnMenu: true,
      align: "left",
      flex: 1.5,
      sortable: false,
      renderCell: (params) => (
        <Stack alignItems={"flex-start"}>
          <p
            className="TextSmMedium"
            style={{
              wordWrap: "break-word",
              whiteSpace: "normal",
              display: "flex",
              alignItems: "center",
              maxWidth: "200px",
              wordBreak: "break-all",
              textAlign: "left",
              color: "#3F4254",
            }}
          >
            {params.row.city}
          </p>
        </Stack>
      ),
    },
    {
      field: "address",
      headerName: "Address",
      disableColumnMenu: true,
      align: "left",
      flex: 3,
      sortable: false,
      renderCell: (params) => (
        <Stack alignItems={"flex-start"}>
          <p
            className="TextSmMedium"
            style={{
              wordWrap: "break-word",
              whiteSpace: "normal",
              display: "flex",
              alignItems: "center",
              maxWidth: "200px",
              wordBreak: "break-all",
              textAlign: "left",
              color: "#3F4254",
            }}
          >
            {params.row.address}
          </p>
        </Stack>
      ),
    },
    {
      field: "tags",
      headerName: "Tags",
      disableColumnMenu: true,
      align: "left",
      width: 300,
      sortable: false,
      renderCell: (params) => (
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          {params.row.tags.slice(0, 2).map((tag: string, index: number) => (
            <Chip
              key={index}
              label={tag}
              className="tag"
              style={{
                color: "#FFFFFF",
                backgroundColor: "#3E97FF",
                margin: "2px",
              }}
            />
          ))}
          {params.row.tags.length > 2 && (
            <Chip
              label={`+${params.row.tags.length - 2}`}
              className="tag"
              style={{
                color: "#3F4254",
                backgroundColor: "#FFFFFF",
                margin: "2px",
              }}
            />
          )}
        </Stack>
      ),
    },
    {
      field: "attachDocument",
      headerName: "Attach Document",
      disableColumnMenu: true,
      flex: 8,
      align: "left",
      sortable: false,
      renderCell: (params) => {
        const documents = params.row.attachDocument || [null];
        const displayedDocuments = documents[0] === null ? [] : documents.slice(0, 2);
        const extraDocsCount = documents[0] === null ? 0 : documents.length - 2;

        return (
          <Stack direction={"row"} spacing={2} alignItems={"center"}>
            {documents[0] === null ? (
              <Chip
                icon={
                  <img
                    src={PlusIcon}
                    alt="plus-icon"
                    style={{ paddingLeft: 4 }}
                  />
                }
                onClick={() => handleOpen(params.row.Id)}
                label="Attach document"
                className="tag"
                style={{
                  color: "#5E6278",
                  backgroundColor: "transparent",
                  border: "1px solid #D8D8E5",
                }}
              />
            ) : (
              <>
                {displayedDocuments.map((document: any, index: number) => (
                  <Stack
                    key={index}
                    direction={"column"}
                    spacing={1}
                    alignItems={"flex-start"}
                  >
                    <CustomTooltip
                      title="Click to edit or add tags"
                      offset={[0, 40]}
                      minWidth={150}
                    >
                      <Stack
                        direction={"row"}
                        spacing={"4px"}
                        alignItems={"center"}
                        padding={"4px 8px"}
                        border={"1px solid #D8D8E5"}
                        borderRadius={50}
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          handleOpen(params.row.Id);
                        }}
                      >
                        <img
                          src={document.isImage ? imageIcon : PdfIcon}
                          alt="file-icon"
                          style={{ width: "16px", height: "16px" }}
                        />
                        <p
                          className="TextSmMedium"
                          style={{
                            color: "#3F4254",
                            wordWrap: "break-word",
                            whiteSpace: "normal",
                            display: "flex",
                            alignItems: "center",
                            maxWidth: "200px",
                            wordBreak: "break-all",
                            textAlign: "left",
                          }}
                        >
                          {document.name}
                        </p>
                        <img
                          src={TagIcon}
                          alt="tag-icon"
                          style={{ width: "16px", height: "16px" }}
                        />
                      </Stack>
                    </CustomTooltip>
                    {document.tags && document.tags[0] && (
                      <Stack direction="row" spacing={1} flexWrap="wrap">
                        {document.tags
                          .slice(0, 2)
                          .map((tag: string, tagIndex: number) => (
                            <Chip
                              key={tagIndex}
                              label={tag}
                              className="tag"
                              style={{
                                color: "#FFFFFF",
                                backgroundColor: "#3E97FF",
                                margin: "2px",
                              }}
                              deleteIcon={
                                <img
                                  src={CloseIconWhite}
                                  alt="close-icon"
                                  style={{
                                    width: "16px",
                                    height: "16px",
                                    marginRight: "12px",
                                  }}
                                />
                              }
                              onDelete={() => {
                                // setRows((prev) => {
                                //   const newRows = [...prev];
                                //   const rowIndex = newRows.findIndex(
                                //     (row) => row.Id === params.row.Id
                                //   );
                                //   if (
                                //     rowIndex !== -1 &&
                                //     newRows[rowIndex].attachDocument[index].tags
                                //   ) {
                                //     newRows[rowIndex].attachDocument[
                                //       index
                                //     ].tags = newRows[rowIndex].attachDocument[
                                //       index
                                //     ].tags.filter(
                                //       (_: any, i: number) => i !== tagIndex
                                //     );
                                //   }
                                //   return newRows;
                                // });
                              }}
                            />
                          ))}
                        {document.tags.length > 2 && (
                          <Chip
                            label={`+${document.tags.length - 2}`}
                            className="tag"
                            style={{
                              color: "#3F4254",
                              backgroundColor: "#FFFFFF",
                              margin: "2px",
                            }}
                          />
                        )}
                      </Stack>
                    )}
                  </Stack>
                ))}
                {extraDocsCount > 0 && (
                  <Chip
                    label={`+${extraDocsCount}`}
                    className="tag"
                    onClick={() => handleOpen(params.row.Id)}
                    style={{
                      color: "#5E6278",
                      backgroundColor: "transparent",
                      border: "1px solid #D8D8E5",
                      cursor: "pointer",
                    }}
                  />
                )}
              </>
            )}
          </Stack>
        );
      },
    },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   disableColumnMenu: true,
    //   align: "left",
    //   flex: 1.5,
    //   sortable: false,
    //   renderCell: (params) => (
    //     <Stack alignItems={"flex-start"}>
    //       <Chip
    //         label={params.row.status}
    //         className="chip"
    //         style={{
    //           backgroundColor:
    //             params.row.status === "Active" ? "#E8FFF3" : "#FFF5F8",
    //           color: params.row.status === "Active" ? "#47BE7D" : "#F1416C",
    //         }}
    //       />
    //     </Stack>
    //   ),
    // },
  ];

  return (
    <div className="listing-table-main">
      <div className="header">
        <p className="header-title">Review Your Imported Properties</p>
        <p className="header-description">
          Review and confirm the details of your imported properties to ensure
          accuracy
        </p>
      </div>
      <Stack spacing={2}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <p className="listingTitle">Listings ({filteredRows.length})</p>
          <Stack direction={"row"} spacing={3}>
            <MessagesSearchTextfield
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              height="38px"
            />
            {/*           <CustomSecondSelect
              value={value}
              placeholder="Sort by"
              openPopover={openPopover}
              anchorEl={anchorEl}
              handleClick={handleClick}
              handleClose={handleClose}
              setSearchText2={setSearchText}
              dataList={displayedOptions}
              setValue={setValue}
              isSearchable={false}
            />*/}
          </Stack>
        </Stack>
        <Box
          sx={{
            width: "100%",
            height: "calc(100vh - 300px)",
            overflow: "auto",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            "-ms-overflow-style": "none",
            "scrollbar-width": "none",
            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#888",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#555",
            },
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
              padding: "16px 8px",
              alignItems: "center",
              height: "100% !important",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#F9F9F9",
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              marginTop: "39px !important",
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
            },
            "& .MuiDataGrid-row": {
              margin: "8px 0",
              backgroundColor: "#FFFFFF",
              borderRadius: "6px",
              minHeight: "80px !important",
              "&:hover": {
                backgroundColor: "#F5F8FA",
              },
            },
          }}
        >
          {propertiesLoading ?
            <Stack
              justifyContent="center"
              alignItems="center"
              height="calc(100vh - 300px)"
            >
              <CircularProgress />
            </Stack>
            :
            <GridData
              checkboxSelection={false}
              rows={filteredRows.length > 0 ? filteredRows : []}
              columns={getColumns()}
              setPage={() => { }}
              total={getColumns().length}
              page={1}
              hideFooter={true}
            />
          }
        </Box>
      </Stack>
      <CustomModal
        open={open}
        handleClose={handleClose}
        handleSave={() => { }}
        title="Upload and attach files"
        description="Supported format: pdf, csv, xlsx"
        showFooter={false}
      >
        <UploadAttachModal
          handleClose={handleCloseModal}
          handleSave={handleSave}
          existingFiles={modalFiles}
          propertyId={selectedPropertyId}
          onDeleteFile={handleDeleteFile}
        />
      </CustomModal>
    </div>
  );
}

export default ReviewImportedListings;
