import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import HeaderTitle from "../components/HeaderTitle";
import CustomCheckbox from "../../../../components/core-components/inputs/Checkbox/CustomCheckbox";
import MainTextField from "../../../../components/core-components/inputs/TextField/MainTextField";
import PlusIcon from "../../../../assets/images/icons/plus.svg";
import TrashIcon from "../../../../assets/images/icons/trash-icon.svg";
import CustomSelect from "../../../../components/core-components/inputs/Select/CustomSelect";
import MessageCreator from "../components/MessageCreator";
import PercentageIcon from "../../../../assets/images/icons/percantage-icon.svg";
import MobileHeader from "../../../../components/shared-components/MobileHeader";
import { useNavigate } from "react-router-dom";

interface Message {
  title: string;
  messageText: string;
  isDiscountCheckboxChecked?: boolean;
  discountPercentage?: number;
  minute?: string | number | null;
}

const InquiryWinBack = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isSwitchChecked, setIsSwitchChecked] = useState(true);
  const [isSecondCheckboxChecked, setIsSecondCheckboxChecked] = useState(false);
  const [isThirdCheckboxChecked, setIsThirdCheckboxChecked] = useState(false);
  const [sendMessage, setSendMessage] = useState<string | null>(null);
  const [maxLength, setMaxLength] = useState<string | number | null>(null);
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate("/menu");
  };
  const [messageBlocks, setMessageBlocks] = useState<
    { id: number; messages: Message[] }[]
  >([
    {
      id: 0,
      messages: [
        {
          title: "",
          messageText: `
            Hi {guest_first_name},<br /><br />
            Just wanted to let you know we have some extra availability in case you wanted to {checkin_or_extend_stay} at a {discount_percentage} discount. Here are the details:<br /><br />     
            {offer_details}<br /><br />   
            Let me know if you’re interested and look forward to hosting you!
          `,
          isDiscountCheckboxChecked: false,
          minute: null,
        },
      ],
    },
  ]);

  const addMessageBlock = () => {
    setMessageBlocks([
      ...messageBlocks,
      {
        id: messageBlocks.length,
        messages: [
          {
            title: "",
            messageText: `
              Hi {guest_first_name},<br /><br />
              Just wanted to let you know we have some extra availability in case you wanted to {checkin_or_extend_stay} at a {discount_percentage} discount. Here are the details:<br /><br />     
              {offer_details}<br /><br />   
              Let me know if you’re interested and look forward to hosting you!
            `,
            isDiscountCheckboxChecked: false,
          },
        ],
      },
    ]);
  };

  const removeMessageBlock = (id: number) => {
    setMessageBlocks(messageBlocks.filter((block) => block.id !== id));
  };

  const updateMessages = (
    id: number,
    newMessages: React.SetStateAction<Message[]>
  ) => {
    setMessageBlocks((prevBlocks) =>
      prevBlocks.map((block) =>
        block.id === id
          ? {
            ...block,
            messages:
              typeof newMessages === "function"
                ? newMessages(block.messages)
                : newMessages,
          }
          : block
      )
    );
  };

  const chipLabels = [
    "{guest_first_name}",
    "{guest_surname}",
    "{host_first_name}",
    "{host_surname}",
    "{listing_name}",
    "{listing_address}",
    "{listing_location}",
    "{length_of_stay}",
    "{check_in_time}",
    "{check_in_date}",
    "{check_out_time}",
    "{check_out_date}",
    "{guest_booking_link}",
    "{guest_email}",
    "{guest_phone}",
    "{guest_phone_last_4_digits}",
    "{guest_phone_last_5_digits}",
    "{guest_phone_last_6_digits}",
    "{number_of_guests}",
    "{booking_site_name}",
    "{wifi_name}",
    "{wifi_password}",
    "{arrival_time}",
    "{departure_time}",
    "{todays_date}",
    "{direct_booking_website}",
    "{multi_unit_name}",
    "{lock_code}",
  ];

  return (
    <Stack
      width={"100%"}
      className={
        !isMobile
          ? "acc-users-box-multiple-title"
          : "acc-users-box-mobile-multiple-title"
      }
      pb={20}
      gap={3}
    >
      {isMobile && (
        <MobileHeader
          title="Inquiry Win Back"
          description="Followup with guests making an inquiry to remind them to book."
          handleBackClick={handleBackClick}
          isSwitchShown={true}
          switchChecked={isSwitchChecked}
          onSwitchChange={() => {
            setIsSwitchChecked(!isSwitchChecked);
          }}
        />
      )}
      {!isMobile && (
        <Stack
          justifyContent="flex-start"
          alignSelf={"flex-start"}
          alignItems="center"
          width={"100%"}
          maxWidth={isMobile ? "100%" : "812px"}
        >
          <HeaderTitle
            title="Inquiry Win Back"
            subtitle="Followup with guests making an inquiry to remind them to book."
            isSwitchShown={true}
            switchChecked={isSwitchChecked}
            onSwitchChange={() => setIsSwitchChecked(!isSwitchChecked)}
          />
        </Stack>
      )}

      <Box
        alignSelf={"flex-start"}
        pt={isMobile ? 0 : 3}
        width={"100%"}
        maxWidth={isMobile ? "100%" : "812px"}
      >
        <Stack
          spacing={3}
          paddingBottom={5}
          borderBottom={"1px solid #EBEBEB"}
          maxWidth={isMobile ? "100%" : "858px"}
        >
          <CustomCheckbox
            position="left"
            label="Auto-preapprove Airbnb inquiries"
            inputName="Auto-preapprove Airbnb inquiries"
            onChange={() => { }}
          />
          <CustomCheckbox
            position="left"
            label="Send message on Airbnb inquiry expiration"
            inputName="Send message on Airbnb inquiry expiration"
            onChange={() =>
              setIsSecondCheckboxChecked(!isSecondCheckboxChecked)
            }
          />

          {isSecondCheckboxChecked && (
            <MainTextField
              title=""
              onChange={(event: any) => setSendMessage(event.target.value)}
              value={sendMessage || ""}
              placeholder="Hey {guest_name}! Just noticed the pre-approval expired. Let me know if you’re still interested in booking for these dates :)"
              multiline
              row={2}
            />
          )}
          <CustomCheckbox
            position="left"
            label="Maximum reservation inquiry length days"
            inputName="Maximum reservation inquiry length days"
            onChange={() => setIsThirdCheckboxChecked(!isThirdCheckboxChecked)}
          />

          {isThirdCheckboxChecked && (
            <MainTextField
              title={""}
              placeholder={"Enter maximum reservation inquiry length days"}
              type={"number"}
              value={maxLength || ""}
              onChange={(event: any) => setMaxLength(event.target.value)}
            />
          )}
        </Stack>
      </Box>
      <Stack
        spacing={5}
        width={"100%"}
        maxWidth={isMobile ? "100%" : "812px"}
        alignSelf={"flex-start"}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <p className="B-18-18-600">Set Message settings for inquiry</p>
          <button
            type="button"
            className="set-message-button"
            onClick={addMessageBlock}
          >
            <img src={PlusIcon} alt="plus" />
          </button>
        </Stack>
        {messageBlocks.map((block) => (
          <Stack
            key={block.id}
            spacing={3}
            sx={{
              padding: "24px 26px 68px 24px",
              borderRadius: "16px",
              border: "1px solid var(--Gray-Gray-400, #D8D8E5)",
              background: "#FFF",
            }}
          >
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <p className="B-18-18-600">{`Message ${block.id + 1}`}</p>
              <button
                type="button"
                className="set-message-button"
                onClick={() => removeMessageBlock(block.id)}
              >
                <img src={TrashIcon} alt="trash-icon" />
              </button>
            </Stack>

            <Stack
              direction={isMobile ? "column" : "row"}
              spacing={"12px"}
              alignItems={isMobile ? "flex-start" : "center"}
              justifyContent={"flex-start"}
              alignContent={"flex-start"}
              alignSelf={"flex-start"}
              width={"100%"}
            >
              <p className="B-14-24-500" style={{ color: "#181C32" }}>
                Send message
              </p>
              <Stack width={isMobile ? "100%" : undefined}>
                <CustomSelect
                  value={block.messages[0].minute || ""}
                  pagetype="selectWithParagraphHeight"
                  onChange={(event: any) => {
                    const newMessages = [...block.messages];
                    newMessages[0].minute = event.target.value;
                    updateMessages(block.id, newMessages);
                  }}
                  label="1"
                  options={[
                    { value: "3", label: "3" },
                    { value: "5", label: "5" },
                    { value: "10", label: "10" },
                    { value: "15", label: "15" },
                    { value: "20", label: "20" },
                    { value: "30", label: "30" },
                    { value: "60", label: "60" },
                  ]}
                />
              </Stack>
              <p className="B-14-24-500" style={{ color: "#181C32" }}>
                minutes after last host message
              </p>
            </Stack>

            <CustomCheckbox
              position="left"
              label="Offer discount"
              inputName="Offer discount"
              onChange={() => {
                const newMessages = [...block.messages];
                newMessages[0].isDiscountCheckboxChecked =
                  !newMessages[0].isDiscountCheckboxChecked;
                updateMessages(block.id, newMessages);
              }}
            />

            {block.messages[0].isDiscountCheckboxChecked && (
              <Stack spacing={"12px"}>
                <p className="B-14-24-500" style={{ color: "#181C32" }}>
                  Discount percentage
                </p>
                <MainTextField
                  title={""}
                  placeholder={"100"}
                  type={"number"}
                  value={block.messages[0].discountPercentage || ""}
                  onChange={(event: any) => {
                    const newMessages = [...block.messages];
                    newMessages[0].discountPercentage = event.target.value;
                    updateMessages(block.id, newMessages);
                  }}
                  endAdornment={
                    <img src={PercentageIcon} alt="Percentage Icon" />
                  }
                />
              </Stack>
            )}
            <MessageCreator
              chipLabels={chipLabels}
              messages={block.messages}
              setMessages={(newMessages) =>
                updateMessages(block.id, newMessages)
              }
            />
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export default InquiryWinBack;
