import React from "react";

interface ShowMoreButtonProps {
  onClick: () => void;
  children: React.ReactNode;
}

const ShowMoreButton: React.FC<ShowMoreButtonProps> = ({
  onClick,
  children,
  ...props
}) => {
  return (
    <button className="show-more-button" onClick={onClick} {...props}>
      {children}
    </button>
  );
};

export default ShowMoreButton;
