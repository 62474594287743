import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import "./listing-details.scss";
import Menu from "./components/menu";
import DetailHeader from "./components/detail-header";
import ListingDetailLayout from "../../../../layouts/ListingDetailLayout";
import { useState } from "react";
import MobileMenu from "./components/mobile-menu";


function Detail() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [subMenu, setSubMenu] = useState("");

  return (
    <>
      <Box
        className={!isMobile ? "container" : ""}
        sx={{
          width: { xs: "100%", sm: "auto" },
          margin: "auto",
          overflowY: "hidden",
          overflowX: "hidden",
        }}
        padding={isMobile ? 2 : 0}
        pb={isMobile ? 20 : 0}
      >
        <Box className="listing-details-container" mt={isMobile ? 0 : 5}>
          <>
            {!isMobile && <Menu subMenu={subMenu} setSubMenu={setSubMenu} />}
            <Box className="listing-details-layout-box">
              <DetailHeader subMenu={subMenu} setSubMenu={setSubMenu} />
              {isMobile && (
                <MobileMenu subMenu={subMenu} setSubMenu={setSubMenu} />
              )}
              {/*      {loading ? (
                <Stack
                  sx={{
                    width: "100%",
                    gap: "44px",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    display: "flex",
                  }}
                >
                  <ListingDetailsPageLoading />
                </Stack>
              ) : ( */}
              <Stack sx={{ width: "100%", gap: "44px" }}>
                <ListingDetailLayout
                  setSubMenu={setSubMenu}
                  subMenu={subMenu}
                />
              </Stack>
              {/*  )} */}
            </Box>
          </>
        </Box>
      </Box>
    </>
  );
}

export default Detail;
