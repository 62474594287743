import React, { useState } from "react";
import Box from "@mui/material/Box";
import HeaderTitle from "../components/HeaderTitle";
import BookingSettings from "../components/BookingSettings";
import TitleBoxH3 from "../../../../components/shared-components/TitleBoxH3";
import {
  TextField,
  InputAdornment,
  IconButton,
  Snackbar,
  Stack,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { SnackbarCloseReason } from "@mui/material/Snackbar";
import Copy from "../../../../assets/images/icons/copy.svg";
import Fishing from "../../../../assets/images/fishing.jpeg";
import CustomSwitch from "../../../../components/shared-components/CustomSwitch";
import { useTranslation } from "react-i18next";
import HeroContentModal from "../components/HeroContentModal";
import FaviconUploadModal from "../components/FaviconUploadModal";
import LogoUploadModal from "../components/LogoUploadModal";
import FaqsModal from "../components/FaqsModal";
import ExternalAccountsModal from "../components/ExternalAccountsModal";
import MobileHeader from "../../../../components/shared-components/MobileHeader";
import { useNavigate } from "react-router-dom";

function BookingSite() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const [landingPageSwitch, setLandingPageSwitch] = React.useState(true);
  const [trustSafetySwitch, setTrustSafetySwitch] = React.useState(true);
  const [openHeroContentModal, setOpenHeroContentModal] = useState(false);
  const [openFaviconUploadModal, setOpenFaviconUploadModal] = useState(false);
  const [openLogoUploadModal, setOpenLogoUploadModal] = useState(false);
  const [openFaqsModal, setOpenFaqsModal] = useState(false);
  const [openExternalAccountsModal, setOpenExternalAccountsModal] =
    useState(false);
  const [textFieldValue, setTextFieldValue] = React.useState("");
  const [copySuccess, setCopySuccess] = useState(false);
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate("/menu");
  };

  const handleOpenHeroContentModal = () => {
    setOpenHeroContentModal(true);
  };

  const handleCloseHeroContentModal = () => {
    setOpenHeroContentModal(false);
  };

  const handleOpenFaviconUploadModal = () => {
    setOpenFaviconUploadModal(true);
  };

  const handleCloseFaviconUploadModal = () => {
    setOpenFaviconUploadModal(false);
  };

  const handleOpenLogoUploadModal = () => {
    setOpenLogoUploadModal(true);
  };

  const handleCloseLogoUploadModal = () => {
    setOpenLogoUploadModal(false);
  };

  const handleOpenFaqsModal = () => {
    setOpenFaqsModal(true);
  };

  const handleCloseFaqsModal = () => {
    setOpenFaqsModal(false);
  };

  const handleOpenExternalAccountsModal = () => {
    setOpenExternalAccountsModal(true);
  };

  const handleCloseExternalAccountsModal = () => {
    setOpenExternalAccountsModal(false);
  };

  const handleLandingPageSwitchChange = () => {
    setLandingPageSwitch((prevState) => !prevState);
  };

  const handleTrustSafetySwitchChange = () => {
    setTrustSafetySwitch((prevState) => !prevState);
  };

  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTextFieldValue(event.target.value);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(textFieldValue);
    setCopySuccess(true);
  };

  const handleClose = (
    event: React.SyntheticEvent<any, Event> | Event,
    reason: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setCopySuccess(false);
  };

  const brandingBoxData = [
    {
      titleKey: "settingspage.bookingsite.siteNameTitle",
      subtitleKey: "settingspage.bookingsite.siteNameSubtitle",
      buttonKey: "settingspage.bookingsite.createButton",
      buttonAction: () => {},
    },
    {
      titleKey: "settingspage.bookingsite.logoTitle",
      subtitleKey: "settingspage.bookingsite.logoSubtitle",
      buttonKey: "settingspage.bookingsite.addButton",
      buttonAction: handleOpenLogoUploadModal,
    },
    {
      titleKey: "settingspage.bookingsite.faviconTitle",
      subtitleKey: "settingspage.bookingsite.faviconSubtitle",
      buttonKey: "settingspage.bookingsite.addButton",
      buttonAction: handleOpenFaviconUploadModal,
    },
  ];

  const contentMethodsBoxes = [
    {
      title: t("settingspage.bookingsite.landingPageTitle"),
      subtitle: t("settingspage.bookingsite.landingPageSubtitle"),
      children: (
        <CustomSwitch
          checked={landingPageSwitch}
          onChange={handleLandingPageSwitchChange}
        />
      ),
    },
    {
      title: t("settingspage.bookingsite.aboutUsTitle"),
      subtitle: t("settingspage.bookingsite.aboutUsSubtitle"),
      children: (
        <button className="pms-body-box-top-btn B-13-13-600">
          {t("settingspage.bookingsite.addButton")}
        </button>
      ),
    },
    {
      title: t("settingspage.bookingsite.faqsTitle"),
      subtitle: t("settingspage.bookingsite.faqsSubtitle"),
      children: (
        <button
          className="pms-body-box-top-btn B-13-13-600"
          onClick={handleOpenFaqsModal}
        >
          {t("settingspage.bookingsite.addButton")}
        </button>
      ),
    },
    {
      title: t("settingspage.bookingsite.contactMethodsTitle"),
      subtitle: t("settingspage.bookingsite.contactMethodsSubtitle"),
      children: (
        <button className="pms-body-box-top-btn B-13-13-600">
          {t("settingspage.bookingsite.addButton")}
        </button>
      ),
    },
    {
      title: t("settingspage.bookingsite.externalAccountsTitle"),
      subtitle: t("settingspage.bookingsite.externalAccountsSubtitle"),
      children: (
        <button
          className="pms-body-box-top-btn B-13-13-600"
          onClick={handleOpenExternalAccountsModal}
        >
          {t("settingspage.bookingsite.addButton")}
        </button>
      ),
    },
    {
      title: t("settingspage.bookingsite.trustSafetyTitle"),
      subtitle: t("settingspage.bookingsite.trustSafetySubtitle"),
      children: (
        <CustomSwitch
          checked={trustSafetySwitch}
          onChange={handleTrustSafetySwitchChange}
        />
      ),
    },
  ];

  const analyticsTitleBoxes = [
    {
      title: t("settingspage.bookingsite.googleAnalyticsTitle"),
      subtitle: t("settingspage.bookingsite.googleAnalyticsSubtitle"),
    },
    {
      title: t("settingspage.bookingsite.facebookPixelTitle"),
      subtitle: t("settingspage.bookingsite.facebookPixelSubtitle"),
    },
    {
      title: t("settingspage.bookingsite.metaTitle"),
      subtitle: t("settingspage.bookingsite.metaSubtitle"),
    },
    {
      title: t("settingspage.bookingsite.metaDescriptionTitle"),
      subtitle: t("settingspage.bookingsite.metaDescriptionSubtitle"),
    },
  ];

  return (
    <Stack
      width={"100%"}
      className={
        !isMobile
          ? "acc-users-box-multiple-title"
          : "acc-users-box-mobile-multiple-title"
      }
      pb={20}
      gap={3}
    >
      {isMobile && (
        <MobileHeader
          title="Booking Site"
          description="Customize your booking site"
          handleBackClick={handleBackClick}
          isSwitchShown={false}
          switchChecked={false}
          onSwitchChange={() => {}}
        />
      )}
      {!isMobile && (
        <Stack
          justifyContent="flex-start"
          alignSelf={"flex-start"}
          alignItems="center"
          width={"100%"}
          maxWidth={isMobile ? "100%" : "812px"}
        >
          <HeaderTitle
            title={t("settingspage.bookingsite.brandingTitle")}
            subtitle={t("settingspage.bookingsite.brandingSubtitle")}
          />
        </Stack>
      )}

      <Stack alignSelf={"flex-start"} width={"100%"}>
        <Stack spacing={3} paddingBottom={5} width={"100%"}>
          <Box className="booking-site-box">
            {brandingBoxData.map((box, index) => (
              <TitleBoxH3
                key={index}
                title={t(box.titleKey)}
                subtitle={t(box.subtitleKey)}
              >
                <button
                  className="pms-body-box-top-btn B-13-13-600"
                  onClick={box.buttonAction}
                >
                  {t(box.buttonKey)}
                </button>
              </TitleBoxH3>
            ))}

            <LogoUploadModal
              open={openLogoUploadModal}
              onClose={handleCloseLogoUploadModal}
            />
            <FaviconUploadModal
              open={openFaviconUploadModal}
              onClose={handleCloseFaviconUploadModal}
            />

            <Box className="booking-settings-box-with-bottom-item">
              <Box className="booking-settings-box-with-bottom-item-top">
                <BookingSettings
                  title={t("settingspage.bookingsite.siteDomainTitle")}
                  subtitle={t("settingspage.bookingsite.siteDomainSubtitle")}
                />
                <button className="pms-body-box-top-btn B-13-13-600">
                  {t("settingspage.bookingsite.addButton")}
                </button>
              </Box>
              <TextField
                value={textFieldValue}
                onChange={handleTextFieldChange}
                fullWidth
                placeholder="https://www.example.com"
                className="booking-site-input B-14-16-400"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleCopy}
                        style={{
                          display: "flex",
                          width: "48px",
                          height: "100%",
                          padding: "0px",
                          justifyContent: "center",
                          alignItems: "center",
                          flexShrink: 0,
                          alignSelf: "stretch",
                          borderRadius: "0px 6px 6px 0px",
                          background: "var(--Gray-Gray-100, #F9F9F9)",
                        }}
                      >
                        <img className="copy-icon" src={Copy} alt="copy" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Snackbar
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                open={copySuccess}
                autoHideDuration={1000}
                onClose={handleClose}
                message={t("settingspage.bookingsite.copiedMessage")}
              />
            </Box>
            <Box className="booking-settings-box-with-bottom-item">
              <Box className="booking-settings-box-with-bottom-item-top">
                <BookingSettings
                  title={t("settingspage.bookingsite.siteDomainTitle")}
                  subtitle={t("settingspage.bookingsite.siteDomainSubtitle")}
                />
                <button className="pms-body-box-top-btn B-13-13-600">
                  {t("settingspage.bookingsite.updateButton")}
                </button>
              </Box>
              <Box className="booking-site-color-box">
                <Box className="booking-site-color-box-left"></Box>
                <p className="booking-site-color-box-right B-13-14-600">
                  #FFAAD89
                </p>
              </Box>
            </Box>
          </Box>
          <Box alignSelf={"flex-start"}>
            <Box
              mt={8}
              width={"100%"}
              maxWidth={isMobile ? "100%" : "812px"}
              className="acc-users-box-header"
              pb={2}
            >
              <HeaderTitle
                title={t("settingspage.bookingsite.contentTitle")}
                subtitle={t("settingspage.bookingsite.contentSubtitle")}
              />
            </Box>
          </Box>
          {contentMethodsBoxes.length > 0 && (
            <TitleBoxH3
              key={0}
              title={contentMethodsBoxes[0].title}
              subtitle={contentMethodsBoxes[0].subtitle}
            >
              {contentMethodsBoxes[0].children}
            </TitleBoxH3>
          )}
          <Box className="booking-settings-box-with-bottom-item">
            <Box className="booking-settings-box-with-bottom-item-top">
              <BookingSettings
                title={t("settingspage.bookingsite.heroContentTitle")}
                subtitle={t("settingspage.bookingsite.heroContentSubtitle")}
              />
              <button
                className="pms-body-box-top-btn B-13-13-600"
                onClick={handleOpenHeroContentModal}
              >
                {t("settingspage.bookingsite.addButton")}
              </button>
            </Box>
            <Box className="booking-site-header-content-box">
              <img src={Fishing} alt="hero-content" />
            </Box>
          </Box>
          {contentMethodsBoxes.slice(1).map((box, index) => (
            <TitleBoxH3 key={index} title={box.title} subtitle={box.subtitle}>
              {box.children}
            </TitleBoxH3>
          ))}
          <HeroContentModal
            open={openHeroContentModal}
            onClose={handleCloseHeroContentModal}
          />
          <FaqsModal open={openFaqsModal} onClose={handleCloseFaqsModal} />
          <ExternalAccountsModal
            open={openExternalAccountsModal}
            onClose={handleCloseExternalAccountsModal}
          />
          <Box alignSelf={"flex-start"} width={"100%"}>
            <Box
              mt={8}
              width={"100%"}
              maxWidth={isMobile ? "100%" : "812px"}
              className="acc-users-box-header"
              pb={2}
            >
              <HeaderTitle
                title={t("settingspage.bookingsite.analyticsTitle")}
                subtitle={t("settingspage.bookingsite.analyticsSubtitle")}
              />
            </Box>
            {analyticsTitleBoxes.map((box, index) => (
              <TitleBoxH3 key={index} title={box.title} subtitle={box.subtitle}>
                <button className="pms-body-box-top-btn B-13-13-600">
                  {t("settingspage.bookingsite.addButton")}
                </button>
              </TitleBoxH3>
            ))}
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default BookingSite;
