import arrows from "../../assets/images/icons/arrows.svg";

interface CloseButtonProps {
  show: boolean;
  onClick: () => void;
}

const CloseModalButton: React.FC<CloseButtonProps> = ({ show, onClick }) => {
  if (!show) {
    return null;
  }

  return (
    <button
      onClick={onClick}
      type={"button"}
      style={{
        width: "24px",
        height: "24px",
        display: "flex",
        padding: "6.695px 6.705px",
        justifyContent: "center",
        alignItems: "center",
        border: "none",
        background: "transparent",
        cursor: "pointer",
      }}
    >
      <img
        style={{ width: "10.591px", height: "10.611px", flexShrink: 0 }}
        src={arrows}
        alt="close"
      />
    </button>
  );
};

export default CloseModalButton;
