import React from "react";
import { useTranslation } from "react-i18next";
import HeaderTitle from "../components/HeaderTitle";
import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import CopyButton from "../../../../assets/images/icons/design.svg";
import Delete from "../../../../assets/images/icons/delete.svg";
import { GridColDef } from "@mui/x-data-grid";
import GridData from "../../../../components/shared-components/GridData";
import PromoAddBanner from "../components/PromoAddBanner";
import MobileGridData from "../../../../components/shared-components/MobileGridData";
import PlusIcon from "../../../../assets/images/icons/plus-icon.svg";
import CustomModalMobile from "../../../../components/shared-components/CustomModalMobile";
import AddPromoCode from "../components/AddPromoCode";
import MobileHeader from "../../../../components/shared-components/MobileHeader";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../../components/shared-components/CustomButton";

interface promoData {
  codeName: string;
  discount: string;
  Id: number;
  title: string;
  validity: string;
  numberOfUses: string;
  status: boolean;
}

function PromoCodes() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const [isMobileModalOpen, setIsMobileModalOpen] = React.useState(false);
  const handleOpenMobileModal = () => setIsMobileModalOpen(true);
  const handleCloseMobileModal = () => setIsMobileModalOpen(false);
  const handleMobileModalSave = () => {
    setIsMobileModalOpen(false);
  };
  const [isPromoCodeMobileModalOpen, setIsPromoCodeMobileModalOpen] =
    React.useState(false);
  const handleOpenPromoCodeMobileModal = () =>
    setIsPromoCodeMobileModalOpen(true);
  const handleClosePromoCodeMobileModal = () =>
    setIsPromoCodeMobileModalOpen(false);
  const handlePromoCodeMobileModalSave = () => {
    setIsPromoCodeMobileModalOpen(false);
  };
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate("/menu");
  };

  const [rows, setRows] = React.useState<promoData[]>([
    {
      Id: 1,
      title: "Villa Mersin",
      codeName: "234567",
      discount: "-$200",
      validity: "03/25/24 - 03/31/24",
      numberOfUses: "0/10times",
      status: true,
    },
    {
      Id: 2,
      title: "Villa Mersin",
      codeName: "234567",
      discount: "-$200",
      validity: "03/25/24 - 03/31/24",
      numberOfUses: "0/10times",
      status: true,
    },
    {
      Id: 3,
      title: "Villa Mersin",
      codeName: "234567",
      discount: "-$200",
      validity: "03/25/24 - 03/31/24",
      numberOfUses: "0/10times",
      status: true,
    },
    {
      Id: 4,
      title: "Villa Mersin",
      codeName: "234567",
      discount: "-$200",
      validity: "03/25/24 - 03/31/24",
      numberOfUses: "0/10times",
      status: true,
    },
    {
      Id: 5,
      title: "Villa Mersin",
      codeName: "234567",
      discount: "-$200",
      validity: "03/25/24 - 03/31/24",
      numberOfUses: "0/10times",
      status: true,
    },
    {
      Id: 6,
      title: "Villa Mersin",
      codeName: "234567",
      discount: "-$200",
      validity: "03/25/24 - 03/31/24",
      numberOfUses: "0/10times",
      status: true,
    },
    {
      Id: 7,
      title: "Villa Mersin",
      codeName: "234567",
      discount: "-$200",
      validity: "03/25/24 - 03/31/24",
      numberOfUses: "0/10times",
      status: true,
    },
    {
      Id: 8,
      title: "Villa Mersin",
      codeName: "234567",
      discount: "-$200",
      validity: "03/25/24 - 03/31/24",
      numberOfUses: "0/10times",
      status: true,
    },
    {
      Id: 9,
      title: "Villa Mersin",
      codeName: "234567",
      discount: "-$200",
      validity: "03/25/24 - 03/31/24",
      numberOfUses: "0/10times",
      status: true,
    },
    {
      Id: 10,
      title: "Villa Mersin",
      codeName: "234567",
      discount: "-$200",
      validity: "03/25/24 - 03/31/24",
      numberOfUses: "0/10times",
      status: true,
    },
    {
      Id: 11,
      title: "Villa Mersin",
      codeName: "234567",
      discount: "-$200",
      validity: "03/25/24 - 03/31/24",
      numberOfUses: "0/10times",
      status: true,
    },
  ]);
  const togglePromoStatus = (id: number) => {
    setRows((prevRows) =>
      prevRows.map((row) =>
        row.Id === id ? { ...row, status: !row.status } : row
      )
    );
  };

  const columns: GridColDef<(typeof rows)[number]>[] = [
    {
      field: "codeName",
      headerName: t("settingspage.promoCodesPage.codeName"),
      align: "center",
      flex: 1,
      disableColumnMenu: true,
      sortable: false,

      renderCell: (params) => (
        <Stack
          gap={2}
          ml={2}
          mt={2}
          alignItems={"flex-end"}
          flexDirection={"row"}
          justifyContent={"flex-start"}
          spacing={2}
        >
          <img
            src={CopyButton}
            alt="reservation"
            style={{ width: "21.5px", height: "21.5px" }}
          />
          <div className="containerPromoTitle">
            <span className="B-12-12-600">{params.row.title}</span>
            <span className="helperText B-12-12-500">
              {params.row.codeName}
            </span>
          </div>
        </Stack>
      ),
    },
    {
      field: "discount",
      headerName: t("settingspage.promoCodesPage.discount"),
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "validity",
      headerName: t("settingspage.promoCodesPage.validity"),
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "numberOfUses",
      headerName: t("settingspage.promoCodesPage.numberOfUses"),
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "status",
      headerName: t("settingspage.promoCodesPage.status"),
      flex: 1,
      disableColumnMenu: true,
      sortable: false,

      renderCell: (params) => (
        <Stack
          gap={2}
          alignItems={"center"}
          height={"100%"}
          flexDirection={"row"}
          justifyContent={"flex-start"}
        >
          <div
            className={
              params.row.status ? "toggle-button active" : "toggle-button"
            }
            onClick={() => togglePromoStatus(params.row.Id)}
          >
            <div
              className={params.row.status ? "round-part active" : "round-part"}
            ></div>
          </div>
        </Stack>
      ),
    },
    {
      field: "delete",
      headerName: "",
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => (
        <img
          src={Delete}
          alt="reservation"
          style={{ width: "18.017px", height: "21.5px" }}
        />
      ),
    },
  ];

  const mobileColumns: GridColDef[] = [
    {
      field: "codeName",
      headerName: t("settingspage.promoCodesPage.codeName"),
      align: "center",
      headerAlign: "center",
      flex: 1.5,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => (
        <Stack
          gap={2}
          ml={2}
          mt={1}
          alignItems={"flex-end"}
          flexDirection={"row"}
          justifyContent={"flex-start"}
          spacing={2}
        >
          <img
            src={CopyButton}
            alt="reservation"
            style={{ width: "21.5px", height: "21.5px" }}
          />
          <div className="containerPromoTitle">
            <span className="B-12-12-600">{params.row.title}</span>
            <span className="helperText B-12-12-500">
              {params.row.codeName}
            </span>
          </div>
        </Stack>
      ),
    },
    {
      field: "status",
      headerName: "",
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => (
        <Stack
          gap={2}
          alignItems={"center"}
          height={"100%"}
          flexDirection={"row"}
          justifyContent={"flex-start"}
        >
          <div
            className={
              params.row.status ? "toggle-button active" : "toggle-button"
            }
            onClick={() => togglePromoStatus(params.row.Id)}
          >
            <div
              className={params.row.status ? "round-part active" : "round-part"}
            ></div>
          </div>
        </Stack>
      ),
    },
    {
      field: "details",
      headerName: "",
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => (
        <Stack
          alignItems={"center"}
          height={"100%"}
          justifyContent={"flex-start"}
          mt={3}
        >
          <button
            style={{
              fontFamily: "Inter",
              marginTop: 0,
            }}
            className="details-button B-12-12-600"
            onClick={handleOpenPromoCodeMobileModal}
          >
            {t("reservationpage.details")}
          </button>
        </Stack>
      ),
    },
    {
      field: "delete",
      headerName: "",
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => (
        <Stack
          gap={2}
          alignItems={"center"}
          height={"100%"}
          flexDirection={"row"}
          justifyContent={"flex-start"}
        >
          <button style={{ background: "transparent", border: "none" }}>
            <img
              src={Delete}
              alt="reservation"
              style={{ width: "18.017px", height: "21.5px" }}
            />
          </button>
        </Stack>
      ),
    },
  ];

  return (
    <>
      <Stack
        width={"100%"}
        className={!isMobile ? "acc-users-box" : "acc-users-box-mobile"}
        pb={isMobile ? 20 : 0}
        height={isMobile ? "100%" : "calc(100vh - 80px)"}
        gap={isMobile ? 4 : 3}
      >
        <Box
          width={"100%"}
          className="acc-users-box-header"
          flexDirection={isMobile ? "column" : "row"}
        >
          {isMobile && (
            <MobileHeader
              title={t("settingspage.promoCodesPage.title")}
              description={t("settingspage.promoCodesPage.subtitle")}
              handleBackClick={handleBackClick}
            />
          )}
          {!isMobile && (
            <HeaderTitle
              title={t("settingspage.promoCodesPage.title")}
              subtitle={t("settingspage.promoCodesPage.subtitle")}
            />
          )}
          {isMobile && (
            <CustomButton
              icon={PlusIcon}
              backgroundColor="#0065E0"
              color="#FFFFFF"
              onClick={handleOpenMobileModal}
              buttonText="Add"
              marginTop={16}
              buttonWidth={isMobile ? "100%" : "120px"}
            />
          )}
        </Box>

        <Box
          className="acc-users-box-content"
          maxWidth={isMobile ? "100%" : "812px"}
          alignContent={"flex-start"}
          alignItems={"flex-start"}
          alignSelf={"flex-start"}
        >
          {!isMobile && <PromoAddBanner />}

          {isMobile ? (
            <Box sx={{ width: "100%" }}>
              <MobileGridData
                checkboxSelection={true}
                rows={rows}
                columns={mobileColumns}
                setPage={() => { }}
                total={rows.length}
                page={1}
                rowHeight={110}
              />
            </Box>
          ) : (
            <Box sx={{ width: "100%", marginTop: "20px" }}>
              <GridData
                rows={rows}
                checkboxSelection={false}
                columns={columns}
                hideFooter={true}
              />
            </Box>
          )}
        </Box>
      </Stack>
      <CustomModalMobile
        open={isMobileModalOpen}
        handleClose={handleCloseMobileModal}
        handleSave={handleMobileModalSave}
        title="Add promo code"
        showFooter={true}
      >
        <Stack sx={{ width: "100%", maxHeight: "800px", minHeight: "450px" }}>
          <AddPromoCode />
        </Stack>
      </CustomModalMobile>
      <CustomModalMobile
        open={isPromoCodeMobileModalOpen}
        handleClose={handleClosePromoCodeMobileModal}
        handleSave={handlePromoCodeMobileModalSave}
        title="Promo code details"
        showFooter={false}
      >
        <Stack spacing={3} mt={2} sx={{ width: "100%" }}>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <span
              className="B-18-18-600"
              style={{ color: "var(--Gray-Gray-700, #5E6278)" }}
            >
              Number of uses
            </span>
            <span className="B-18-18-600">0/10 times</span>
          </Stack>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <span
              className="B-18-18-600"
              style={{ color: "var(--Gray-Gray-700, #5E6278)" }}
            >
              Discount
            </span>
            <span className="B-18-18-600">-$200</span>
          </Stack>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <span
              className="B-18-18-600"
              style={{ color: "var(--Gray-Gray-700, #5E6278)" }}
            >
              Validity
            </span>
            <span className="B-18-18-600">03/25/24 - 03/31/24</span>
          </Stack>
        </Stack>
      </CustomModalMobile>
    </>
  );
}

export default PromoCodes;
