import React from "react";
import DetailsInputArea from "../../../../../../../../components/shared-components/DetailsInputArea";
import PreferredPolicy from "../forms/PreferredPolicy";
import AdditionalPolicy1 from "../forms/AdditionalPolicy1";
import AdditionalPolicy2 from "../forms/AdditionalPolicy2";
import { useTranslation } from "react-i18next";

function GuestBookingOptions() {
  const { t } = useTranslation();
  const data = [
    {
      title: t("detailsPages.policies.preferredPolicy"),
      subtitle: t("listingDetails.notset"),
      component: <PreferredPolicy />,
      isSelect: false,
    },
    {
      title: t("detailsPages.policies.additionalPolicy1"),
      subtitle: t("listingDetails.notset"),
      component: <AdditionalPolicy1 />,
      isSelect: false,
    },
    {
      title: t("detailsPages.policies.additionalPolicy2"),
      subtitle: t("listingDetails.notset"),
      component: <AdditionalPolicy2 />,
      isSelect: false,
    },
  ];
  return (
    <>
      <DetailsInputArea
        title={t("detailsPages.policies.guestBookingOptions")}
        data={data}
        onClick={() => {}}
        id={"guest-booking-options"}
      />
    </>
  );
}

export default GuestBookingOptions;
