import axios from "axios";
import { BASE_API_URL, INTEGRATION_API_URL } from ".";

const API_BASE_URL = BASE_API_URL

export interface FetchAllProperties {
  accessToken: string;
}

export const fetchAllPropertiesApi = async ({ accessToken }: FetchAllProperties) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/Property/GetAll`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return response
  } catch (error: any) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch the message."
    );
  };
}


export interface FetchPropertyTagsParams {
  accessToken: string;
  searchText?: string;
}

export const fetchPropertyTagsApi = async ({ accessToken, searchText }: FetchPropertyTagsParams) => {
  try {
    const params: Record<string, any> = {
      Relation: "propertyfile"
    };

    if (searchText) {
      params.Tag = searchText;
    }

    const response = await axios.get(`${API_BASE_URL}/Tag/Get`, {
      params,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response
  } catch (error: any) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch the message."
    );
  };
}



export interface PostPropertyDetailsParams {
  accessToken: string;
  customerId: string
  selectedProviderType: number
}

export const postPropertyDetailsApi = async ({ accessToken, customerId, selectedProviderType }: PostPropertyDetailsParams) => {
  try {
    await axios.post(INTEGRATION_API_URL + "/Ingtegration/PropertyDetailAllSync",
      {
        CustomerId: customerId,
        ProviderType: selectedProviderType,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  } catch (error: any) {
    throw new Error(
      error.response?.data?.message || "Failed to post property details ."
    );
  };
}

export interface PostReservationDetailsParams {
  accessToken: string;
  customerId: string
  selectedProviderType: number
}

export const postReservationDetailsApi = async ({ accessToken, customerId, selectedProviderType }: PostPropertyDetailsParams) => {
  try {
    await axios.post(INTEGRATION_API_URL + "/Ingtegration/ReservationAllSync",
      {
        CustomerId: customerId,
        ProviderType: selectedProviderType,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  } catch (error: any) {
    throw new Error(
      error.response?.data?.message || "Failed to post reservation details ."
    );
  };


}

export interface PostSelectedPropertyListParams {
  accessToken: string;
  selectedPropertyIds: string[]
}

export const postSelectedPropertyListApi = async ({ accessToken, selectedPropertyIds }: PostSelectedPropertyListParams) => {
  try {
    await axios.put(`${API_BASE_URL}/Property/UpdateStatus`, {
      properties: selectedPropertyIds,
      status: true,
    },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    console.log("Property Done ");
  } catch (error: any) {
    throw new Error(
      error.response?.data?.message || "Failed to post property list ."
    );
  };
}
