import dubleLeft from "../../../../../assets/images/icons/duble-left.svg";
import dubleRight from "../../../../../assets/images/icons/duble-right.svg";
import left from "../../../../../assets/images/icons/left.svg";
import right from "../../../../../assets/images/icons/right.svg";

export const rowsData = [
  {
    title: dubleLeft,
    day: -14,
  },
  {
    title: left,
    day: -7,
  },
  {
    title: right,
    day: 7,
  },
  {
    title: dubleRight,
    day: 14,
  },
];
