import axios from "axios";
import { BASE_API_URL } from ".";

const API_BASE_URL = BASE_API_URL

export interface GetAllPropertiesApiParams {
  accessToken: string;
  PageIndex?: string;
  PageSize?: string
}

export const getAllPropertiesApi = async ({
  accessToken,
  PageSize,
  PageIndex
}: GetAllPropertiesApiParams) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/Property/GetAll`,
      {
        params: {
          PageIndex,
          PageSize
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return response
  } catch (error: any) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch the message."
    );
  };
}



