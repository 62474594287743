import { useState } from "react";
import DetailsContentTitle from "../../../../../../components/shared-components/DetailsContentTitle";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import AiIcon from "../../../../../../assets/images/icons/technologies.svg";
import MainTextField from "../../../../../../components/core-components/inputs/TextField/MainTextField";

function Headline() {
  const { t } = useTranslation();
  const [text, setText] = useState("");

  const handleTextChange = (e: any) => {
    if (e.target.value.length > 50) return;
    setText(e.target.value);
  };

  return (
    <>
      <DetailsContentTitle
        title={`${t("listingDetails.headline")}`}
        info={`${t("listingDetails.headlineurplace")}`}
      />
      <Box
        mt={3}
        sx={{
          position: "relative",
        }}
      >
        <MainTextField
          title={""}
          placeholder={t("detailsPages.details.writeYourHeadline")}
          value={text}
          onChange={handleTextChange}
          isMandatory={false}
          multiline={true}
          row={4}
          maxLength={50}
          textareaWidth="75%"
        />
        <button className="onboarding-step3-second-textfield-button">
          <img
            src={AiIcon}
            alt="AI"
            className="onboarding-step3-textfield-button-icon"
          />
          <p className="onboarding-step3-textfield-button-text">AI</p>
        </button>
      </Box>
      <p className="character-counter">{`${text.length}/50`}</p>
    </>
  );
}

export default Headline;
