import { useState } from "react";
import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import AiIcon from "../../../../assets/images/icons/technologies.svg";
import MainTextField from "../../../../components/core-components/inputs/TextField/MainTextField";
import CustomModalMobile from "../../../../components/shared-components/CustomModalMobile";
import CustomModal from "../../../../components/shared-components/CustomModal";

interface FaqsModalProps {
  open: boolean;
  onClose: () => void;
}

function FaqsModal({ open, onClose }: FaqsModalProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [subheadline, setSubheadline] = useState("");
  const [answer, setAnswer] = useState("");

  const handleSubheadline = (e: any) => {
    if (e.target.value.length > 106) return;
    setSubheadline(e.target.value);
  };

  const handleAnswer = (e: any) => {
    if (e.target.value.length > 106) return;
    setAnswer(e.target.value);
  };

  const modalContent = (
    <Box width="100%">
      <Stack
        spacing={3}
        sx={{ padding: isMobile ? "0px 0px 24px 0px" : "0 24px 0 0" }}
        width={"100%"}
      >
        <Stack spacing={1} width={"100%"}>
          <p className="B-14" style={{ color: "#3F4254" }}>
            Answer
          </p>
          <Box
            mt={3}
            sx={{
              position: "relative",
            }}
            width={"100%"}
          >
            <MainTextField
              title={""}
              placeholder={t("detailsPages.details.writeYourSubHeadline")}
              value={subheadline}
              onChange={handleSubheadline}
              isMandatory={false}
              multiline={true}
              row={4}
              maxLength={106}
              textareaWidth="90%"
            />
            <button className="onboarding-step3-second-textfield-button">
              <img
                src={AiIcon}
                alt="AI"
                className="onboarding-step3-textfield-button-icon"
              />
              <p className="onboarding-step3-textfield-button-text">AI</p>
            </button>
          </Box>
          <p className="character-counter">{`${subheadline.length}/106`}</p>
        </Stack>
        <Stack spacing={1} width={"100%"}>
          <p className="B-14" style={{ color: "#3F4254" }}>
            Answer
          </p>
          <Box
            mt={3}
            sx={{
              position: "relative",
            }}
            width={"100%"}
          >
            <MainTextField
              title={""}
              placeholder={t("detailsPages.details.writeYourSubHeadline")}
              value={answer}
              onChange={handleAnswer}
              isMandatory={false}
              multiline={true}
              row={4}
              maxLength={106}
              textareaWidth="90%"
            />
            <button className="onboarding-step3-second-textfield-button">
              <img
                src={AiIcon}
                alt="AI"
                className="onboarding-step3-textfield-button-icon"
              />
              <p className="onboarding-step3-textfield-button-text">AI</p>
            </button>
          </Box>
          <p className="character-counter">{`${answer.length}/106`}</p>
        </Stack>
      </Stack>
    </Box>
  );

  return isMobile ? (
    <CustomModalMobile
      open={open}
      handleClose={onClose}
      title="Faqs"
      description="Set your business apart with eye-catching content at the top of your landing page"
      showFooter={true}
      handleSave={onClose}
    >
      {modalContent}
    </CustomModalMobile>
  ) : (
    <CustomModal
      open={open}
      handleClose={onClose}
      title="Faqs"
      description="Set your business apart with eye-catching content at the top of your landing page"
      showFooter={true}
      handleSave={onClose}
    >
      {modalContent}
    </CustomModal>
  );
}

export default FaqsModal;
