import React, { SetStateAction, useState } from "react";
import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import HeaderTitle from "../components/HeaderTitle";
import Botel from "../../../../assets/images/icons/botel.png";
import GoogleTravel from "../../../../assets/images/icons/google-travel.svg";
import CustomSwitch from "../../../../components/shared-components/CustomSwitch";
import { useTranslation } from "react-i18next";
import MainSelectSelection from "../../../../components/core-components/inputs/Select/MainSelectSelection";
import MobileHeader from "../../../../components/shared-components/MobileHeader";
import { useNavigate } from "react-router-dom";
import CustomMobileSelectMenu from "../../../../components/core-components/inputs/Select/CustomMobileSelectMenu";

function PmsChannels() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate("/menu");
  };

  const [channelManagementSwitch, setChannelManagementSwitch] =
    React.useState(true);

  const handleChannelManagementSwitch = () => {
    setChannelManagementSwitch((prevState) => !prevState);
  };

  const [selectedValue, setSelectedValue] = useState("");

  return (
    <Stack
      width={"100%"}
      className={
        !isMobile
          ? "acc-users-box-multiple-title"
          : "acc-users-box-mobile-multiple-title"
      }
      pb={20}
      gap={3}
    >
      {isMobile && (
        <MobileHeader
          title="PMS Integrations"
          description={t("settingspage.pmsSubtitle")}
          handleBackClick={handleBackClick}
          isSwitchShown={false}
          switchChecked={false}
          onSwitchChange={() => {}}
        />
      )}
      {!isMobile && (
        <Stack
          justifyContent="flex-start"
          alignSelf={"flex-start"}
          alignItems="center"
          width={"100%"}
          maxWidth={isMobile ? "100%" : "812px"}
        >
          <HeaderTitle
            title={t("settingspage.pmsTitle")}
            subtitle={t("settingspage.pmsSubtitle")}
          />
        </Stack>
      )}

      <Stack alignSelf={"flex-start"} width={"100%"}>
        <Stack spacing={3} paddingBottom={5} width={"100%"}>
          <Box className="pms-body-box">
            <Box className="pms-body-box-top">
              <Box className="pms-body-box-top-logo">
                <img src={Botel} alt="botel_logo" />
              </Box>
              <Box className="pms-body-box-top-texts">
                <p
                  className={
                    isMobile
                      ? "pms-body-box-top-title B-14-24-500"
                      : "pms-body-box-top-title B-16"
                  }
                >
                  Botel
                </p>
                <p
                  className={
                    isMobile
                      ? "pms-body-box-top-subtitle B-12-16-400"
                      : "pms-body-box-top-subtitle B-14-20-400"
                  }
                >
                  {t("settingspage.booklySubtitle")}
                </p>
              </Box>
              <button className="pms-body-box-top-btn B-13-13-600">
                {t("settingspage.booklyViewButton")}
              </button>
            </Box>
            <Box className="pms-body-box-bottom">
              <p
                className={
                  isMobile
                    ? "pms-body-box-top-title B-14-24-500"
                    : "pms-body-box-bottom-title B-14-22-500"
                }
              >
                {t("settingspage.importListings")}
              </p>
              <Stack sx={{ width: "100%" }}>
                {isMobile ? (
                  <CustomMobileSelectMenu
                    title="Import your listings"
                    options={[
                      {
                        value: "Berkant",
                        label: `Berkant`,
                      },
                      {
                        value: "No",
                        label: `No`,
                      },
                    ]}
                    multiple={false}
                    selectedValues={[selectedValue]}
                    setSelectedValues={(
                      values: string[] | SetStateAction<string[]>
                    ) => {
                      if (Array.isArray(values)) {
                        setSelectedValue(values[0]);
                      }
                    }}
                  />
                ) : (
                  <MainSelectSelection
                    value={selectedValue}
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                      setSelectedValue(event.target.value as string)
                    }
                    label={"Aliyeva"}
                    options={[
                      {
                        value: "Berkant",
                        label: `Berkant`,
                      },
                      {
                        value: "No",
                        label: `No`,
                      },
                    ]}
                  />
                )}
              </Stack>
            </Box>
          </Box>
          <Box
            className="pms-bottom-box"
            pt={isMobile ? 0 : 6}
            alignSelf={"flex-start"}
          >
            <HeaderTitle
              title={t("settingspage.channelManagementTitle")}
              subtitle={t("settingspage.channelManagementSubtitle")}
            />
          </Box>
          <Box className="pms-body-box">
            <Box className="pms-body-box-top">
              <Box className="pms-body-box-bottom-logo">
                <img src={GoogleTravel} alt="google-logos" />
              </Box>
              <Box className="pms-body-box-top-texts">
                <p
                  className={
                    isMobile
                      ? "pms-body-box-top-title B-14-24-500"
                      : "pms-body-box-bottom-title B-14-22-500"
                  }
                >
                  Botel
                </p>
                <p
                  className={
                    isMobile
                      ? "pms-body-box-top-subtitle B-12-16-400"
                      : "pms-body-box-top-subtitle B-14-20-400"
                  }
                >
                  {t("settingspage.googleVacationRentalsInfo")}
                </p>
              </Box>
              <CustomSwitch
                checked={channelManagementSwitch}
                onChange={handleChannelManagementSwitch}
              />
            </Box>
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default PmsChannels;
