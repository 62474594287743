import { Stack } from "@mui/material";
import DetailsContentTitle from "../../../../../../../../components/shared-components/DetailsContentTitle";
import MainTextField from "../../../../../../../../components/core-components/inputs/TextField/MainTextField";
import HelperText from "../../../../../../../../components/shared-components/HelperText";
import { useTranslation } from "react-i18next";
import { useState } from "react";

function CheckInInstructions() {
  const { t } = useTranslation();
  const [text, setText] = useState("");

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  return (
    <Stack
      sx={{ width: "100%", maxWidth: "560px" }}
      component={"form"}
      spacing={2}
    >
      <DetailsContentTitle
        title={t("detailsPages.policies.checkInInstructions")}
        info={t(
          "detailsPages.policies.writeEasyToFollowCheckInInstructionsAndChooseWhenYouWantThemToBeSharedWithTheGuests"
        )}
      />
      <Stack gap={"8px"}>
        <MainTextField
          title={""}
          placeholder={t("detailsPages.policies.writeInstructions")}
          value={text}
          onChange={handleTextChange}
          isMandatory={false}
          multiline={true}
          maxLength={4000}
        />
        <HelperText content={`${text.length}/4000 `} />
      </Stack>
    </Stack>
  );
}

export default CheckInInstructions;
