import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import HeaderTitle from "../components/HeaderTitle";
import MobileHeader from "../../../../components/shared-components/MobileHeader";
import { useNavigate } from "react-router-dom";

const NotificationSettings = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isSwitchChecked, setIsSwitchChecked] = useState(true);
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate("/menu");
  };

  return (
    <Stack
      width={"100%"}
      className={
        !isMobile
          ? "acc-users-box-multiple-title"
          : "acc-users-box-mobile-multiple-title"
      }
      pb={20}
      gap={3}
    >
      {isMobile && (
        <MobileHeader
          title="Notification Settings"
          description={
            "Customize your notification settings to suit your property's needs. Use these settings to automatically notify guests about early check-in options, whether there is a same-day checkout or not. Adjust the triggers and messages to suit your property's needs."
          }
          handleBackClick={handleBackClick}
          isSwitchShown={true}
          switchChecked={isSwitchChecked}
          onSwitchChange={() => {
            setIsSwitchChecked(!isSwitchChecked);
          }}
        />
      )}
      {!isMobile && (
        <Box className="acc-users-box-header">
          <HeaderTitle
            title="Notification Settings"
            subtitle="Customize your notification settings to suit your property's needs. Use these settings to automatically notify guests about early check-in options, whether there is a same-day checkout or not. Adjust the triggers and messages to suit your property's needs."
            isSwitchShown={true}
            switchChecked={isSwitchChecked}
            onSwitchChange={() => {
              setIsSwitchChecked(!isSwitchChecked);
            }}
          />
        </Box>
      )}

      <Box className="acc-users-box-content"></Box>
    </Stack>
  );
};

export default NotificationSettings;
