import React from "react";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import TrashSvg from "../../../../../../../../assets/images/icons/trash-icon.svg";
import { useTranslation } from "react-i18next";

interface OptionalExtraBoxProps {
  title: string;
  price: string;
  onEdit: () => void;
  onDelete: () => void;
}

const OptionalExtra: React.FC<OptionalExtraBoxProps> = ({
  title,
  price,
  onEdit,
  onDelete,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const truncateTitle = (title: string) => {
    if (title.length > 15) {
      return title.substring(0, 15) + "...";
    } else {
      return title;
    }
  };

  return (
    <Stack
      spacing={3}
      className="optional-extra-box"
      sx={{
        border: "1px solid var(--stroke-colors, #EBEBEB)",
        padding: "12px 16px",
        borderRadius: "12px",
        width: "241px",
        height: "124px",
      }}
      width={isMobile ? "100%" : "241px"}
    >
      <Stack direction="row" justifyContent={"space-between"}>
        <Stack justifyContent={"space-between"} spacing={1}>
          <p
            className="B-14-14-700"
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
            title={title}
          >
            {truncateTitle(title)}
          </p>
          <p className="B-14-14-500">{price}</p>
        </Stack>
        <Stack>
          <button
            onClick={onDelete}
            style={{
              borderRadius: "50px",
              border: "1px solid var(--Gray-Gray-500, #A1A5B7)",
              padding: "8px",
              width: "36px",
              height: "36px",
              backgroundColor: "transparent",
              cursor: "pointer",
            }}
          >
            <img
              src={TrashSvg}
              alt="trash-icon"
              style={{
                width: "15.014px",
                height: "17.917px",
              }}
            />
          </button>
        </Stack>
      </Stack>
      <Stack direction="row" spacing={9} justifyContent="flex-end">
        <Stack>
          <button
            onClick={onEdit}
            style={{
              borderRadius: "50px",
              border: "1px solid var(--Gray-Gray-800, #3F4254)",
              padding: "9px 12px",
              backgroundColor: "transparent",
              cursor: "pointer",
            }}
          >
            <p className="B-12-12-600">{t("settingspage.edit")}</p>
          </button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default OptionalExtra;
