import DetailsInputArea from "../../../../../../../../components/shared-components/DetailsInputArea";
import AdditionalRules from "../forms/AdditionalRules";
import EventsAllowed from "../forms/EventsAllowed";
import Pets from "../forms/Pets";
import SmokingAllowed from "../forms/SmokingAllowed";
import { useTranslation } from "react-i18next";

function HouseRules() {
  const { t } = useTranslation();
  const data = [
    {
      title: t("detailsPages.policies.eventsOrPartiesAllowed"),
      subtitle: "",
      component: <EventsAllowed />,
      isSelect: false,
      quickSelect: true,
    },
    {
      title: t("detailsPages.policies.smokingAllowed"),
      subtitle: "",
      component: <SmokingAllowed />,
      isSelect: false,
      quickSelect: true,
    },
    {
      title: t("detailsPages.policies.pets"),
      subtitle: t("detailsPages.pricing.notSet"),
      component: <Pets />,
      isSelect: false,
    },
    {
      title: t("detailsPages.policies.additionalRules"),
      subtitle: t("detailsPages.pricing.notSet"),
      component: <AdditionalRules />,
      isSelect: false,
    },
  ];

  return (
    <>
      <DetailsInputArea
        title={t("detailsPages.policies.houseRules")}
        data={data}
        onClick={() => { }}
        id={"house-rules"}
      />
    </>
  );
}

export default HouseRules;
