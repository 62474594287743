import PriceOfNights from "../modals/PriceOfNights";
import DetailsInputArea from "../../../../../../../../components/shared-components/DetailsInputArea";
import CheckInCheckOut from "../modals/CheckInCheckOut";
import Stays from "../modals/Stays";
import { useTranslation } from "react-i18next";
import Guests from "../modals/Guests";

function BasicRates() {
  const { t } = useTranslation();
  const labels = [
    t("detailsPages.pricing.allDays"),
    t("detailsPages.pricing.monday"),
    t("detailsPages.pricing.tuesday"),
    t("detailsPages.pricing.wednesday"),
    t("detailsPages.pricing.thursday"),
    t("detailsPages.pricing.friday"),
    t("detailsPages.pricing.saturday"),
    t("detailsPages.pricing.sunday"),
  ];
  const totalBoxes = 8;
  const boxesPerRow = 3;
  const data = [
    {
      title: t("detailsPages.pricing.basicNights"),
      subtitle: "Hisaronu Mah. Degirmenyani Cayirova, Marmaris, Mugla, Turkey",
      component: (
        <PriceOfNights
          title={t("detailsPages.pricing.basicNights")}
          showInfoBox={true}
        />
      ),
      isSelect: false,
    },
    {
      title: t("detailsPages.pricing.weekendNights"),
      subtitle: t("detailsPages.pricing.notSet"),
      component: (
        <PriceOfNights
          title={t("detailsPages.pricing.weekendNights")}
          subtitle={t("detailsPages.pricing.friAndSatNights")}
          showInfoBox={true}
        />
      ),
      isSelect: false,
    },
    {
      title: t("detailsPages.pricing.guests"),
      subtitle: "",
      component: <Guests />,
      isSelect: false,
      quickSelect: true,
    },
    {
      title: t("detailsPages.pricing.minAndMaxStay"),
      subtitle: t("detailsPages.pricing.notSet"),
      component: <Stays />,
      isSelect: false,
    },
    {
      title: t("detailsPages.pricing.checkInDays"),
      subtitle: t("detailsPages.pricing.notSet"),
      component: (
        <CheckInCheckOut
          boxesPerRow={boxesPerRow}
          labels={labels}
          totalBoxes={totalBoxes}
          title={t("detailsPages.pricing.checkInDays")}
        />
      ),
      isSelect: false,
    },
    {
      title: t("detailsPages.pricing.checkOutDays"),
      subtitle: t("detailsPages.pricing.notSet"),
      component: (
        <CheckInCheckOut
          boxesPerRow={boxesPerRow}
          labels={labels}
          totalBoxes={totalBoxes}
          title={t("detailsPages.pricing.checkOutDays")}
        />
      ),
      isSelect: false,
    },
  ];

  return (
    <>
      <DetailsInputArea
        title={t("detailsPages.pricing.basicRates")}
        data={data}
        onClick={() => { }}
        id={"basic-rates"}
      />
    </>
  );
}

export default BasicRates;
