import React from "react";
import DetailsInputArea from "../../../../../../../../components/shared-components/DetailsInputArea";
import AdvanceNoticeForm from "../forms/AdvanceNoticeForm";
import { useTranslation } from "react-i18next";

function AdvanceNotice() {
  const { t } = useTranslation();
  const data = [
    {
      title: t("detailsPages.policies.advanceNotice"),
      subtitle: t("detailsPages.pricing.notSet"),
      component: <AdvanceNoticeForm />,
      isSelect: false,
    },
  ];

  return (
    <>
      <DetailsInputArea
        title={""}
        data={data}
        onClick={() => {}}
        id={"advance-notice"}
      />
    </>
  );
}

export default AdvanceNotice;
