import React from "react";
import { dataGridStyles } from "../../views/private/listing/DatagridStyles";
import {
  DataGrid,
  GridRowId,
  GridRowSelectionModel,
  GridPaginationModel,
} from "@mui/x-data-grid";

interface GridDataProps {
  rows: any[];
  columns: any[];
  checkboxSelection?: boolean;
  setPage?: (value: number) => void;
  total?: number;
  page?: number;
  hideFooter?: boolean;
  maxSelection?: number;
  disableVirtualization?: boolean;
  autoHeight?: boolean;
  sx?: any;
  rowSelectionModel?: GridRowId[];
  onRowSelectionModelChange?: (newSelection: any) => void;
}

const GridData: React.FC<GridDataProps> = ({
  rows,
  columns,
  setPage,
  checkboxSelection = false,
  total = 0,
  page = 1,
  hideFooter = false,
  maxSelection = Infinity,
  disableVirtualization = false,
  autoHeight = true,
  sx = {},
  rowSelectionModel,
  onRowSelectionModelChange,
}) => {
  const handlePaginationModelChange = (newModel: GridPaginationModel) => {
    if (setPage) {
      setPage(newModel.page + 1);
    }
  };

  const pageSize = 3;
  const [internalSelectionModel, setInternalSelectionModel] = React.useState<
    GridRowId[]
  >([]);

  const effectiveSelectionModel = rowSelectionModel ?? internalSelectionModel;

  const handleSelectionModelChange = (
    newSelectionModel: GridRowSelectionModel
  ) => {
    if (newSelectionModel.length > maxSelection) {
      alert(
        `You can only select up to ${maxSelection} rows. If you want to select more, please choose payment plan.`
      );
      return;
    }

    if (!rowSelectionModel) {
      setInternalSelectionModel([...newSelectionModel]);
    }

    if (onRowSelectionModelChange) {
      onRowSelectionModelChange(newSelectionModel);
    }
  };

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      autoHeight={autoHeight}
      rowHeight={100}
      sx={{
        ...dataGridStyles.root,
        ...sx,
      }}
      paginationMode="server"
      rowCount={total}
      pageSizeOptions={[pageSize]}
      checkboxSelection={checkboxSelection}
      getRowId={(row) => row.Id}
      disableRowSelectionOnClick
      onPaginationModelChange={handlePaginationModelChange}
      paginationModel={{
        page: page - 1,
        pageSize: pageSize,
      }}
      hideFooter={hideFooter}
      disableVirtualization={disableVirtualization}
      rowSelectionModel={effectiveSelectionModel}
      onRowSelectionModelChange={handleSelectionModelChange}
    />
  );
};

export default GridData;
