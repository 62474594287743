import { FormControl, RadioGroup, Stack } from "@mui/material";
import CustomRadioButton from "../../../../../../components/core-components/inputs/RadioButton/CustomRadioButton";
import React from "react";
import { useTranslation } from "react-i18next";

interface dataTypes {
  label: string | JSX.Element;
  value: string;
}

function Heating() {
  const { t } = useTranslation();
  const data: dataTypes[] = [
    {
      label: t("listingDetails.centralheating"),
      value: "centralheating",
    },
    {
      label: t("listingDetails.electricheating"),
      value: "electricheating",
    },
    {
      label: t("listingDetails.gasheating"),
      value: "gasheating",
    },
    {
      label: t("listingDetails.noheating"),
      value: "noheating",
    },
  ];
  const [value, setValue] = React.useState("noheating");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <Stack sx={{ width: "100%" }} spacing={2}>
      <p
        style={{
          paddingLeft: "6px",
        }}
        className="listing-details-layout-box-content-title"
      >
        {t("listingDetails.heating")}
      </p>
      <FormControl fullWidth component="fieldset">
        <RadioGroup
          aria-label="listing-status"
          name="listing-status"
          value={value}
          onChange={handleChange}
        >
          {data.map((item) => (
            <CustomRadioButton label={item.label} value={item.value} />
          ))}
        </RadioGroup>
      </FormControl>
    </Stack>
  );
}

export default Heating;
