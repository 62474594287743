import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Stack, Checkbox, ListItemText, Button } from "@mui/material";
import CustomModalMobile from "../../../../components/shared-components/CustomModalMobile";
import CustomButton from "../../../../components/shared-components/CustomButton";
import checked from "../../../../assets/images/icons/checkedIcon.svg";
import unchecked from "../../../../assets/images/icons/Checkbox.svg";
import ArrowDown from "../../../../assets/images/icons/arrow-down-messages.svg";

interface Option {
  label: string;
  value: string;
}

interface CustomMobileSelectMenuProps {
  title: string;
  options: Option[];
  multiple?: boolean;
  selectedValues: string[];
  setSelectedValues: React.Dispatch<React.SetStateAction<string[]>>;
  onBlur?: (e: React.FocusEvent<HTMLButtonElement>) => void;
  errorMessage?: string | null;
}

const CustomMobileSelectMenu: React.FC<CustomMobileSelectMenuProps> = ({
  title,
  options,
  multiple,
  selectedValues,
  setSelectedValues,
  onBlur,
  errorMessage = null,
}) => {
  const [open, setOpen] = useState(false);
  const [tempSelectedValues, setTempSelectedValues] =
    useState<string[]>(selectedValues);

  const checkedIcon = <img src={checked} alt="checked" />;
  const uncheckedIcon = <img src={unchecked} alt="unchecked" />;

  const handleToggleOption = (value: string) => {
    if (multiple) {
      if (tempSelectedValues.includes(value)) {
        setTempSelectedValues((prev) => prev.filter((item) => item !== value));
      } else {
        setTempSelectedValues((prev) => [...prev, value]);
      }
    } else {
      setTempSelectedValues([value]);
    }
  };

  const handleSave = () => {
    setSelectedValues(tempSelectedValues);
    setOpen(false);
  };

  const handleOpen = () => {
    setTempSelectedValues(selectedValues);
    setOpen(true);
  };

  const selectedLabels = options
    .filter((option) => selectedValues.includes(option.value))
    .map((option) => option.label);

  const displayedText =
    selectedLabels.length === 0
      ? "Select"
      : multiple
        ? selectedLabels.length > 2
          ? `${selectedLabels.slice(0, 2).join(", ")} +${selectedLabels.length - 2
          }`
          : selectedLabels.join(", ")
        : selectedLabels[0];

  return (
    <>
      <Button
        onClick={handleOpen}
        onBlur={onBlur}
        sx={{
          fontSize: "14px",
          fontWeight: "500",
          lineHeight: "20px",
          padding: "7px 10px",
          borderRadius: "6px",
          border: "1px solid #a1a5b7",
          color: "var(--Gray-500, #667085)",
          minWidth: "100px",
          width: "100%",
          height: "50px",
          justifyContent: "space-between",
          backgroundColor: "#FFFFFF",
        }}
        endIcon={<img src={ArrowDown} alt="arrow-down" />}
      >
        {displayedText}
      </Button>
      {errorMessage && (
        <p className="error-message" style={{ marginLeft: "14px" }}>
          {errorMessage}
        </p>
      )}

      <CustomModalMobile
        open={open}
        handleClose={() => setOpen(false)}
        title={title}
        showFooter={false}
        handleSave={handleSave}
      >
        <Stack>
          {options.map((option) => (
            <Box
              component="li"
              key={option.value}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => handleToggleOption(option.value)}
            >
              <Stack
                direction={"row"}
                spacing={1}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: tempSelectedValues.includes(option.value)
                    ? "var(--Primary-Primary-Light, #EEF6FF)"
                    : "transparent",
                  padding: "12px",
                  borderRadius: "8px",
                  width: "100%",
                }}
              >
                {multiple && (
                  <Checkbox
                    icon={uncheckedIcon}
                    checkedIcon={checkedIcon}
                    checked={tempSelectedValues.includes(option.value)}
                    onChange={(event) => {
                      event.stopPropagation();
                      handleToggleOption(option.value);
                    }}
                    sx={{ marginRight: 2 }}
                  />
                )}
                <ListItemText primary={option.label} />
              </Stack>
            </Box>
          ))}
          <Stack width={"100%"} pt={4}>
            <CustomButton
              backgroundColor="#0065E0"
              color="#FFFFFF"
              buttonText="Save"
              marginTop={0}
              onClick={handleSave}
              buttonWidth="100%"
            />
          </Stack>
        </Stack>
      </CustomModalMobile>
    </>
  );
};

export default CustomMobileSelectMenu;
