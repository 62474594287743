import React, { useState } from "react";
import QuickSelectCounterButtons from "../../../../../../../../components/core-components/inputs/RadioButton/QuickSelectCounterButtons";

function Guests() {
  const [itemValue, setItemValue] = useState(0);

  const handleMinusClick = () => {
    setItemValue((prevValue: number) => {
      const newValue = prevValue > 0 ? prevValue - 1 : 0;
      return newValue;
    });
  };

  const handlePlusClick = () => {
    setItemValue((prevValue: number) => {
      return prevValue + 1;
    });
  };

  return (
    <>
      <QuickSelectCounterButtons
        itemValue={itemValue}
        handlePlusClick={handlePlusClick}
        handleMinusClick={handleMinusClick}
      />
    </>
  );
}

export default Guests;
