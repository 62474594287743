import {
  Box,
  IconButton,
  LinearProgress,
  LinearProgressProps,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import UploadPhotoArea from "../../settings/components/UploadPhotoArea";
import CustomButton from "../../../../components/shared-components/CustomButton";
import DocsIcon from "../../../../assets/images/docs-icon.svg";
import imageIcon from "../../../../assets/images/image-icon.svg";
import CloseIcon from "../../../../assets/images/icons/arrows.svg";
import TagIcon from "../../../../assets/images/icons-tag.svg";
import axios from "axios";
import AutocompleteTagComponent from "../../../../components/shared-components/AutocompleteTagComponent";
import { useAuth0 } from "@auth0/auth0-react";

const isImageFile = (fileName: string) => {
  const extension = fileName.toLowerCase().split(".").pop();
  return ["jpg", "jpeg", "png", "gif", "webp"].includes(extension || "");
};

function formatFileSize(size: number | undefined): string {
  if (size === undefined) {
    return "Size unavailable";
  }
  const i = Math.floor(Math.log(size) / Math.log(1024));
  const sizes = ["B", "KB", "MB", "GB", "TB"];
  return `${(size / Math.pow(1024, i)).toFixed(1)} ${sizes[i]}`;
}

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number; message?: string }
) {
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress
            variant="determinate"
            {...props}
            sx={{
              height: "8px",
              backgroundColor: "white",
              border: "1px solid #3E97FF",
              "& .MuiLinearProgress-bar": {
                backgroundColor: "#3E97FF",
              },
              borderRadius: "30px",
            }}
          />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <span className="TextXsRegular">{`${Math.round(props.value)}%`}</span>
        </Box>
      </Box>
      {props.message && (
        <Typography
          className="TextXsRegular"
          sx={{
            textAlign: "center",
            color: "#3E97FF",
            mt: 0.5,
          }}
        >
          {props.message}
        </Typography>
      )}
    </Box>
  );
}

interface TaggedFile {
  file: File;
  tags: string[];
  isImage?: boolean;
}

interface UploadAttachModalProps {
  handleClose: () => void;
  handleSave: (files: Array<TaggedFile>) => void;
  existingFiles?: {
    name: string;
    tags: string[];
    size?: number;
    isImage?: boolean;
  }[];
  propertyId: string;
  onDeleteFile?: (rowId: number, fileName: string) => void;
}

const UploadAttachModal: React.FC<UploadAttachModalProps> = ({
  handleClose,
  handleSave,
  existingFiles = [],
  propertyId,
  onDeleteFile,
}) => {
  const [files, setFiles] = useState<TaggedFile[]>([]);
  const [progress, setProgress] = React.useState<number[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [processingMessages, setProcessingMessages] = useState<string[]>([]);
  const [isUploading, setIsUploading] = useState(false);

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    console.log("PropertyId in useEffect:", propertyId);
  }, [propertyId]);

  const onFilesChange = (newFiles: File[]) => {
    const processedFiles = newFiles.map((file) => ({
      file,
      tags: [],
      isImage: isImageFile(file.name),
    }));

    setFiles((prevFiles) => {
      const existingFileNames = prevFiles.map((f) => f.file.name);
      const filteredNewFiles = processedFiles.filter(
        (newFile) => !existingFileNames.includes(newFile.file.name)
      );
      return [...prevFiles, ...filteredNewFiles];
    });

    setProgress((prevProgress) => [...prevProgress, ...newFiles.map(() => 0)]);
    setProcessingMessages((prevMessages) => [
      ...prevMessages,
      ...newFiles.map(() => "Preparing upload..."),
    ]);
    setError(null);

    handleUpload(processedFiles);
  };

  const handleUpload = async (filesToUpload = files) => {
    if (!propertyId) {
      setError("Property ID is missing. Please try again.");
      return;
    }

    if (filesToUpload.length === 0) {
      return;
    }

    try {
      setIsUploading(true);
      setProcessingMessages(filesToUpload.map(() => "Processing..."));

      const formData = new FormData();
      formData.append("PropertyId", propertyId);

      filesToUpload.forEach((file, index) => {
        formData.append("files", file.file);
        formData.append(
          "typeCode",
          file.isImage ? "PROPERTY_IMAGE" : "PROPERTY_DOCUMENT"
        );
        formData.append("displayPriority", index.toString());
        formData.append("caption", file.file.name);
        formData.append("type", file.file.type);
        formData.append("size", file.file.size.toString());
        if (file.isImage) {
          formData.append("width", "0");
          formData.append("height", "0");
        }
      });
      const API_BASE_URL = process.env.REACT_APP_API_URL;
      const accessToken = await getAccessTokenSilently();
      await axios.post(
        `${API_BASE_URL}/Property/UploadMultiPart`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const percent = Math.round(
              (progressEvent.loaded * 100) / (progressEvent.total || 100)
            );
            setProgress((prevProgress) => prevProgress.map(() => percent));

            if (percent < 30) {
              setProcessingMessages((prev) =>
                prev.map(() => "Starting upload...")
              );
            } else if (percent < 70) {
              setProcessingMessages((prev) =>
                prev.map(() => "Uploading files...")
              );
            } else if (percent < 100) {
              setProcessingMessages((prev) =>
                prev.map(() => "Almost there...")
              );
            } else {
              setProcessingMessages((prev) =>
                prev.map(() => "Upload complete!")
              );
              handleSave(filesToUpload);
              setIsUploading(false);
            }
          },
        }
      );
    } catch (error) {
      console.error("Upload error:", error);
      setError("Failed to upload files. Please try again.");
      setProcessingMessages([]);
      setIsUploading(false);
    }
  };

  useEffect(() => {
    if (existingFiles.length > 0) {
      setFiles(
        existingFiles.map((file) => ({
          file: new File([], file.name),
          tags: file.tags || [],
          isImage: file.isImage || false,
        }))
      );
      setProgress(existingFiles.map(() => 100));
    }
  }, [existingFiles]);

  const getFileIcon = (file: File) => {
    return isImageFile(file.name) ? imageIcon : DocsIcon;
  };

  React.useEffect(() => {
    const timers = progress.map((_, fileIndex) =>
      setInterval(() => {
        setProgress((prevProgress) => {
          const newProgress = [...prevProgress];
          if (newProgress[fileIndex] >= 100) {
            clearInterval(timers[fileIndex]);
            return newProgress;
          }
          newProgress[fileIndex] += 10;
          return newProgress;
        });
      }, 500)
    );

    return () => {
      timers.forEach((timer) => clearInterval(timer));
    };
  }, [files]);

  const removeFile = (index: number) => {
    const fileName = files[index].file.name;

    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setProgress((prevProgress) => prevProgress.filter((_, i) => i !== index));
    setProcessingMessages((prevMessages) =>
      prevMessages.filter((_, i) => i !== index)
    );

    if (onDeleteFile && propertyId) {
      onDeleteFile(parseInt(propertyId), fileName);
    }
  };

  const handleTagsChange = (selectedTags: string[], fileIndex: number) => {
    setFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles[fileIndex].tags = selectedTags;
      return updatedFiles;
    });
  };

  return (
    <>
      <Box
        sx={{
          maxHeight: "65vh",
          overflow: "auto",
          gap: "24px",
          width: "100%",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        }}
      >
        <UploadPhotoArea
          isInvalid={false}
          isTitleShown={false}
          docType={[
            "application/pdf",
            "text/csv",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          ]}
          onFilesChange={onFilesChange}
        />

        {error && <Typography color="error">{error}</Typography>}

        <Stack spacing={2} width="100%" mt={3}>
          {files.map(({ file, tags }, index) => (
            <Stack
              key={index}
              spacing={3}
              sx={{
                display: "flex",
                alignItems: "flex-start",
                width: "100%",
                border: "var(--none, 1px) solid var(--Previous-300, #E1E3EA)",
                borderRadius: "12px",
                padding: "16px 12px",
              }}
            >
              <Stack spacing={"12px"} width={"100%"}>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  width={"100%"}
                >
                  <Stack direction={"row"} spacing={2}>
                    <img
                      src={getFileIcon(file)}
                      alt={isImageFile(file.name) ? "image-icon" : "pdf-icon"}
                      style={{ width: "40px", height: "40px" }}
                    />
                    <Stack spacing={"4px"}>
                      <p className="listingSubtitle">{file.name}</p>
                      <span className="TextXsRegular">
                        {formatFileSize(file.size)}
                      </span>
                    </Stack>
                  </Stack>

                  <IconButton
                    onClick={() => removeFile(index)}
                    sx={{ width: "24px", height: "24px" }}
                  >
                    <img src={CloseIcon} alt="close-icon" />
                  </IconButton>
                </Stack>
                <Box sx={{ width: "100%" }}>
                  <LinearProgressWithLabel
                    value={progress[index]}
                    message={processingMessages[index]}
                  />
                </Box>
              </Stack>
              <Stack
                spacing={3}
                direction={"row"}
                width={"100%"}
                alignContent={"center"}
                alignItems={"center"}
                maxHeight={"150px"}
                height={"100%"}
              >
                <Stack
                  direction={"row"}
                  spacing={"4px"}
                  alignContent={"center"}
                  alignItems={"center"}
                  height={"100%"}
                >
                  <img
                    src={TagIcon}
                    alt="tag-icon"
                    style={{ width: "20px", height: "20px" }}
                  />
                  <p className="TextSmRegular">Tags</p>
                </Stack>
                <AutocompleteTagComponent
                  initialTags={tags}
                  onTagsChange={(selectedTags) =>
                    handleTagsChange(selectedTags, index)
                  }
                  relation="propertyfile"
                  freeSolo={false}
                />
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Box>
      <Stack
        direction={"row"}
        sx={{ width: "100%" }}
        justifyContent={"flex-end"}
        alignItems={"flex-start"}
      >
        <CustomButton
          backgroundColor="#0065E0"
          color="#FFFFFF"
          onClick={handleClose}
          buttonText="Close"
          marginTop={24}
          disable={isUploading}
        />
      </Stack>
    </>
  );
};

export default UploadAttachModal;
