import { Button } from "@mui/material";

interface SocialMediaButtonProps {
  iconSrc: string;
  altText: string;
  onClick?: () => void;
}

const SocialMediaButton: React.FC<SocialMediaButtonProps> = ({
  iconSrc,
  altText,
  onClick,
}) => (
  <Button
    onClick={onClick}
    sx={{
      borderRadius: "60px",
      border: "1px solid #D8D8E5",
    }}
  >
    <img
      src={iconSrc}
      alt={altText}
      style={{ width: "32px", height: "32px" }}
    />
  </Button>
);

export default SocialMediaButton;
